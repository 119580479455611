<template>
  <div>
    <div class="form-group row" v-if="!multiple && !isFixGuestEnabled">
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-8 my-auto" v-if="choice == 'yes_no'">
        <select
          v-if="!confirm"
          v-model="inputData[c.id]"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('yes_no')" :value="n.value">
            {{ n.value }}
          </option>
        </select>
        <p v-if="confirm" class="mb-0">
          {{ getChoice("yes_no", inputData[c.id]) }}
        </p>
      </div>
      <div class="col-sm-8 my-auto" v-if="choice == 'choices2'">
        <select
          v-if="!confirm"
          v-model="inputData[c.id]"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('choices2')" :value="n">
            {{ n }}
          </option>
        </select>
        <p v-if="confirm" class="mb-0">
          {{ getChoice("choices2", inputData[c.id]) }}
        </p>
      </div>
      <!-- For data aggregation -->
      <template v-if="companions.length > 0 && !confirm">
        <template v-for="(companion, index) in companions">
          <label
            v-if="!confirm && canBeCopy.includes(c.system_name)"
            class="form-check-label mt-1 col-sm-8 offset-sm-4"
            @click="
              copyFromMain(
                c.id,
                companion.other_forms,
                { type: 'visitor', obj: visitor_forms[c.id] },
                $event
              )
            "
          >
            {{ $t("visit-information-form.copy-from-main") }}
          </label>
          <label class="col-sm-4 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-8" v-if="choice == 'yes_no'">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('yes_no')" :value="n.value">
                {{ n.value }}
              </option>
            </select>
          </div>
          <div class="col-sm-8" v-if="choice == 'choices2'">
            <select
              v-if="!confirm"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('choices2')" :value="n">
                {{ n }}
              </option>
            </select>
          </div>
        </template>
      </template>
    </div>

    <div class="form-group row" v-else-if="!multiple && isFixGuestEnabled">
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>

      <div class="col-sm-8 my-auto" v-if="choice == 'yes_no'">
        <select
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('yes_no')" :value="n.value">
            {{ n.value }}
          </option>
        </select>
        <p v-if="confirm" class="mb-0">
          {{ getChoice("yes_no", visitor_forms[c.id]) }}
        </p>
      </div>

      <div class="col-sm-8 my-auto" v-if="choice == 'choices2'">
        <select
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('choices2')" :value="n">
            {{ n }}
          </option>
        </select>
        <p v-if="confirm" class="mb-0">
          {{ getChoice("choices2", visitor_forms[c.id]) }}
        </p>
      </div>

      <template v-if="fixGuestCount > 1 && !confirm">
        <template v-for="(companion, index) in companions">
          <label
            v-if="!confirm && canBeCopy.includes(c.system_name)"
            class="form-check-label mt-1 col-sm-8 offset-sm-4"
            @click="
              copyFromMain(
                c.id,
                companion.other_forms,
                { type: 'visitor', obj: visitor_forms[c.id] },
                $event
              )
            "
          >
            {{ $t("visit-information-form.copy-from-main") }}
          </label>
          <label class="col-sm-4 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-8" v-if="choice == 'yes_no'">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('yes_no')" :value="n.value">
                {{ n.value }}
              </option>
            </select>
          </div>
          <div class="col-sm-8" v-if="choice == 'choices2'">
            <select
              v-if="!confirm"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('choices2')" :value="n">
                {{ n }}
              </option>
            </select>
          </div>
        </template>
      </template>

    </div>

    <!-- Multiple Room -->
    <div class="form-group row" v-else>
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-8" v-if="choice == 'yes_no'">
        <select
          v-if="!confirm"
          v-model="multiple_data[bd_index].visitor_forms[c.id]"
          :required="
            multiple_data[bd_index].filled_in_by_other
              ? false
              : c.required
              ? true
              : false
          "
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('yes_no')" :value="n.value">
            {{ n.value }}
          </option>
        </select>
        <p v-if="confirm">
          {{ getChoice("yes_no", multiple_data[bd_index].visitor_forms[c.id]) }}
        </p>
      </div>
      <div class="col-sm-8" v-if="choice == 'choices2'">
        <select
          v-if="!confirm"
          v-model="multiple_data[bd_index].visitor_forms[c.id]"
          :required="
            multiple_data[bd_index].filled_in_by_other
              ? false
              : c.required
              ? true
              : false
          "
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in changeChoices('choices2')" :value="n">
            {{ n }}
          </option>
        </select>
        <p v-if="confirm">
          {{
            getChoice("choices2", multiple_data[bd_index].visitor_forms[c.id])
          }}
        </p>
      </div>
      <!-- For data aggregation -->

      <template
        v-if="multiple_data[bd_index].companions.length > 0 && !confirm"
      >
        <template
          v-for="(companion, index) in multiple_data[bd_index].companions"
        >
          <label
            v-if="!confirm && canBeCopy.includes(c.system_name)"
            class="form-check-label mt-1 col-sm-8 offset-sm-4"
            @click="
              copyFromMain(
                c.id,
                companion.other_forms,
                {
                  type: 'visitor',
                  obj: multiple_data[bd_index].visitor_forms[c.id],
                },
                $event
              )
            "
          >
            {{ $t("visit-information-form.copy-from-main") }}
          </label>
          <label class="col-sm-4 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown",
                  true,
                  bd_index
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-8" v-if="choice == 'yes_no'">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('yes_no')" :value="n.value">
                {{ n.value }}
              </option>
            </select>
          </div>
          <div class="col-sm-8" v-if="choice == 'choices2'">
            <select
              v-if="!confirm"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-1"
            >
              <option value="">--</option>
              <option v-for="n in changeChoices('choices2')" :value="n">
                {{ n }}
              </option>
            </select>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { main_form_methods } from "../../../../utils/mockup";
import { mapState, mapActions } from "vuex";
import { checkinFormTypeWithMemorization, loadMemorizedData } from "../../../../utils/helpers";
import guestWatcherMixin from "../../../../../../mixins/guestWatcherMixin";

const { showLabelOrPlaceholder, changeChoices, getChoice, copyFromMain, populateCompanionsFields, isFixGuestEnabled } =
  main_form_methods;

export default {
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    companions: {
      type: Array,
      default: [],
    },
    different: {
      type: Array,
      default: [],
    },
    questions: {
      type: Array,
      default: [],
    },
    choice: null,
    canBeCopy: {
      type: Array,
      default: [],
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room

    // checkin form type
    checkin_form_type: "",
    reservation_no: null,
    fixGuestCount: {
      type: Number,
      default: null
    },
    fixGuestSetting: {
      type: Object,
      default: {}
    }
  },
  mixins: [guestWatcherMixin],
  data() {
    return {
      inputData: {},
      formType: "",
    };
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
    isFixGuestEnabled
  },
  updated() {
    this.handleFormType();
  },
  async mounted() {
    await this.initializeForm();
  },
  methods: {
    ...mapActions("memorizedInputs", [
              "saveInput", 
              "saveWalkInInputs",
              "saveReservationNumber",
              "checkIfCheckinFormType"
            ]),

    handleFormType() {
      this.checkin_form_type === "others"
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },
    async initializeForm() {

      const rid = this.$route.query.rid
      const guestCountSetting = await this.guestCountSetting
      checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);

      this.inputData = await loadMemorizedData(
        this,
        this.memorizedInputs,
        this.memorizedWalkInInputs,
        this.checkin_form_type,
        this.visitor_forms
      );

      this.populateCompanionsFields();
    },
    showLabelOrPlaceholder,
    populateCompanionsFields,
    changeChoices,
    getChoice,
    copyFromMain,
  },
};
</script>

<style scoped>
.form-check-label {
  text-decoration: underline;
  cursor: pointer;
}
</style>
