<template>
  <div>

    <div class="card-header py-3">
      {{ $t("quick_service_setting.header") }}
    </div>
    <div class="card-body mt-2">
      <form @submit-prevent="submit">
        <div v-if="success" class="alert alert-success" role="alert">
          {{ $t("quick_service_setting.success_msg") }}
        </div>

        <div class="row mb-3">
          <div class="col">
            <div class="row">
              <div class="col-2">
                {{ $t("quick_service_setting.switch_label") }}
              </div>
              <div class="col-8">
                <toggle-button
                    v-model="quick_service_setting.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="quick_service_setting"
                    :sync="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="justify-content-left">
          {{ $t("quick_service_setting.note_1") }}
        </div>
        <br />
        <div
            class="justify-content-left"
            v-html="$t('quick_service_setting.note_2').join('\n')"
        ></div>

        <div
            class="justify-content-left pl-3"
            v-html="$t('quick_service_setting.note_3').join('\n')"
        ></div>

        <div class="d-flex mt-5">
          <div class="col">
            <div class="mr-1">
              {{ $t("quick_service_setting.link_label") }}
              <a
                  :href="
                  $router.resolve({ name: 'checkin-facility-quick-service' })
                    .href
                "
                  target="_blank"
              >{{
                  $router.resolve({ name: "checkin-facility-quick-service" })
                      .href
                }}</a
              >
            </div>

            <div class="mr-3 row">
              <div class="col-3">
                {{ $t("quick_service_setting.qr_label") }}
              </div>
              <div class="col">
                <qrcode-vue :value="qrServiceLink" level="M" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 mt-5" align="center">
            <button
                @click.prevent="
                $router.push({ name: 'checkin-facility-dashboard-home' })
              "
                class="btn btn-danger ml-3"
            >
              {{ $t("quick_service_setting.back") }}
            </button>
            <button
                class="btn-primary btn ml-3"
                @click="$bvModal.show('modal-confirmation')"
                type="button"
            >
              {{ $t("quick_service_setting.confirm") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <confirmation-modal-component
        @click-handler="submit"
    ></confirmation-modal-component>
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    ConfirmationModalComponent,
    QrcodeVue,
  },
  data() {
    return {
      facility: {},
      quick_service_setting: {
        facility_id: 0,
        name: "quick_service_setting",
        value: {
          visibility: false,
        },
      },
      error: false,
      sendForm: false,
      success: false,
      qrServiceLink: "",
    };
  },

  async mounted() {
    this.qrServiceLink = `${window.location.origin}${
        this.$router.resolve({ name: "checkin-facility-quick-service" }).href
    }`;

    this.facility = window._app_facility;

    await this.setServiceSetting();
  },

  methods: {
    async setServiceSetting() {
      this.quick_service_setting.facility_id = this.facility.id;

      let quickServiceSetting = await this.$lionheart
          .get("/v2/facility-settings", {
            params: {
              name: this.quick_service_setting.name,
              facility_id: this.quick_service_setting.facility_id,
              paginate: 0,
            }
          })
          .then((r) => r.data.data);

      if (quickServiceSetting.length == 0) return;

      quickServiceSetting = quickServiceSetting[0];

      this.quick_service_setting.value.visibility =
          quickServiceSetting.value.visibility;
    },
    async submit() {
      const serviceSetting = {
        ...this.quick_service_setting,
      };

      const resService = await this.$lionheart
          .post("/v2/facility-settings", { ...serviceSetting })
          .then((r) => r.data);

      this.success = resService.success == true;
    },
  },
};
</script>