<template>
   <div align="center">
      <div class="d-flex mt-5 justify-content-center" v-if="!facility.has_pms">
         <div>
            <qrcode-vue :value="`${value}checkin-core`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-checkin") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkin-core`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkin-core`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
      </div>

      <div class="d-flex mt-5 justify-content-between" v-if="facility.has_pms">
         <div>
            <qrcode-vue :value="`${value}checkin-core`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-precheckin") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkin-core`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkin-core`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
         <div>
            <qrcode-vue :value="`${value}checkin-core/touch-down`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-td") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkin-core/touch-down`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkin-core/touch-down`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
      </div>
      <div class="d-flex mt-5 justify-content-between" v-if="facility.has_pms">
         <div>
            <!-- QR for tablet -->
            <qrcode-vue :value="`${value}checkin-core/touch-down?tab=1`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-td-tab") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkin-core/touch-down?tab=1`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkin-core/touch-down?tab=1`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
         <div>
            <qrcode-vue :value="`${value}checkin-core/touch-down?ref=no-others`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-td-no-others") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkin-core/touch-down?ref=no-others`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkin-core/touch-down?ref=no-others`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
      </div>
      
      <div class="d-flex mt-5" :class="facility.my_page && Object.keys(facility.my_page).length > 0 ? 'justify-content-between' : 'justify-content-center'" v-if="facility.has_pms">
         <div>
            <qrcode-vue :value="`${value}checkout-core`" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-checkout") }}</div>
            <div class="mt-2">
               <a :href="`${value}checkout-core`" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(`${value}checkout-core`)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
         <div v-if="facility.my_page">
            <qrcode-vue :value="my_page" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-my-page") }}</div>

            <div class="mt-2">
               <a :href="my_page" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(my_page)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
      </div>

      <!-- QR code for facility info -->
      <div class="d-flex mt-5" :class="facility.my_page && Object.keys(facility.my_page).length > 0 ? 'justify-content-between' : 'justify-content-center'" v-if="facility.has_pms && facility.my_page">
         <div>
            <qrcode-vue :value="my_page_fac_info" :size="size" level="H" />
            <div class="mt-5">{{ $t("account-page-qr.qr-my-page-fac-info") }}</div>

            <div class="mt-2">
               <a :href="my_page_fac_info" target="_blank" class="btn btn-default border mr-2">{{ $t("account-page-qr.open") }}</a>
               <button @click="copy(my_page_fac_info)" type="button" class="btn btn-default border ml-2">{{ $t("account-page-qr.copy") }}</button>
            </div>
         </div>
      </div>
      <div class="no-print mt-5 mb-5">
         <p>{{ $t("account-page-qr.message1") }}</p>
         <a href="/dashboard">{{ $t("account-page-qr.back-to-dashboard") }}</a>
      </div>
   </div>
</template>

<style scope scoped>
@media print {
   .no-print,
   .no-print * {
      display: none !important;
   }
}
</style>
<script>
import QrcodeVue from "qrcode.vue";

export default {
   components: {
      QrcodeVue,
   },
   data() {
      const facility = window._app_facility;
      return {
         facility: facility,
         value: `${window.location.origin}/${facility.slug_name}/`,
         size: 450,
         my_page: `${process.env.VUE_APP_MY_PAGE_BASE_URL}/${facility.slug_name}/my-page/home`,
         my_page_fac_info: `${process.env.VUE_APP_MY_PAGE_BASE_URL}/${facility.slug_name}/my-page/guest/categories?sample=true`
      };
   },
   mounted() {
      console.log(this.my_page);
   },
   methods: {
      async copy(url) {
         await navigator.clipboard.writeText(url);
      },
   },
};
</script>
