import Home from './home/home.vue'
import Vue from "vue";





export const verifyAuth = async (next) => {
	if (!Vue.$storage.has('auth_admin')) {
		return next({name: 'checkin-admin-login'})
	}

	next()
}



export default [
	{  path: `/`, name: 'checkin-admin-home', component: Home, beforeEnter:  async (to, from, next) => verifyAuth(next)},
	{  path: `login`, name: 'checkin-admin-login', component: () => import('./login/form.vue'),
		beforeEnter: (to, from, next) => {
			if (Vue.$storage.has('auth_admin')) return next({name: 'checkin-admin-home'})
			next()
		}
	},
	{  path: `home`, name: 'checkin-admin-home', component: Home , beforeEnter:  async (to, from, next) => verifyAuth(next)},
	{  path: `facilities`, name: 'checkin-admin-facilities',     component: () => import('./facility/index.component.vue'), beforeEnter:  async (to, from, next) => verifyAuth(next)},
	{  path: `traction/accounts`, name: 'checkin-admin-traction-accounts',     component: () => import('./traction-account/index.component.vue'), beforeEnter:  async (to, from, next) => verifyAuth(next)},
	{  path: `traction/engagements`, 
	   name: 'checkin-admin-traction-engagements',     
	   component: () => import('./traction-engagement/index.component.vue'),
	   beforeEnter:  async (to, from, next) => verifyAuth(next),
	},
	{  path: `payments`, name: 'checkin-admin-payments',     component: () => import('./payment/index.component.vue'), beforeEnter:  async (to, from, next) => verifyAuth(next)},
	{  path: `traction/engagements/pci-emails`, 
	   name: 'checkin-admin-traction-pci-emails', 
	   component: () => import('./traction-engagement/pci-emails.component.vue'), 
	   beforeEnter: async (to, from, next) => verifyAuth(next)
	},
	{  path: `traction/engagements/my-page`, 
	   name: 'checkin-admin-traction-my-page', 
	   component: () => import('./traction-engagement/my-page.component.vue'), 
	   beforeEnter: async (to, from, next) => verifyAuth(next)
	},
]
