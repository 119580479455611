<template>
  <div class="styled-form">
    <div class="mt-3">
      <div class="wrapper fadeInDown">
        <div class="form-content">
          <form class="p-4" @submit="submitForm">
            <div id ="error_msg" v-if="error_msg" class="alert alert-danger" role="alert">{{ error_msg }}</div>
            <div id ="not_exist_msg" v-if="not_exists_msg" class="alert alert-warning" role="alert">{{ $t('landing.non-exists-email') }}</div>
            <h2 class="text-left w-100">{{ $t('landing.please-reset-password')}}</h2>
            <p class="text-left px-2">{{ $t('landing.reset-note-1')}}</p>
            <hr >

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('landing.email-label')}}</label>
              <div class="col-sm-9">
                <input v-model="data.email" type="email" required class="form-control" :placeholder="$t('landing.email-label')">
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12" align="center">
                <button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t('landing.reset-password-btn')}}</button>
              </div>
            </div>
          </form>

          <div id="formFooter">
            <router-link link :to="{name: 'checkin-facility-login'}" class="underlineHover" >{{ $t('account-registration-form.return-to-signin')}}</router-link>
            <br />
            <router-link link :to="{name: 'checkin-facility-email-verify'}" class="underlineHover" >{{ $t('account-registration-form.create-new-account') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.styled-form .form-content {
  max-width: 786px !important;
}
</style>

<script>
export default {
  data() {
    return {
      submit_btn: '',
      error_msg: '',
      not_exists_msg: '',
      confirm: false,
      data: {
          email : ''
      }
    }
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()

      if (this.submit_btn == 'submit') {

        const reset = await this.$lionheart.post('/auth/generate-reset-password', this.data)
          .then(r => this.catcher(r.data, 'status', r => r))
          .catch(e => this.catcher(e))

        if (reset.error.has_error) return this.errorDisplay(reset)

        if (reset.status.exists == false) {
          return this.not_exists_msg = '1'
        }

        this.not_exists_msg = ''
        this.error_msg = ''

        this.$router.push({'name' : 'checkin-facility-password-reset-success'})
      }
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    }
  }
}
</script>