<template>
  <div>
    <div id="buttons" className="ui grey three item inverted bottom fixed menu transition hidden">
    </div>
    <div v-html="data" className="remove-all-styles">

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      data: '',
      filter: {}
    }
  },
  mounted() {

    this.show(process.env['VUE_APP_TAC_ID_' + this.$i18n.locale.toUpperCase()])

  },
  methods: {
    async show(id) {
      const response = await axios.get('https://checkinherenow.com/wp-json/wp/v2/pages/284')
      // const response = await axios.get('/wp-json/wp/v2/pages/' + id)
      this.data = response.data.content.rendered
    },
  }
}
</script>
<style lang="scss">
/*
* NOTE: Please don't pollute global css scope from inside a non root component
*/

// html {

//   .modal-body {
//     padding: 5% 5%;
//     .remove-all-styles {
//       all: initial;
//       text-align: justify;

//       p {
//         margin-bottom: 0.5rem;
//       }
//     }


//     #header {
//       background-color: initial !important;
//     }

//     ol {
//       list-style-type: decimal !important;
//       padding-left: 1rem;

//       li {
//         font: initial !important;
//         margin-bottom: 8px !important;
//         color: #0d0d0d !important;

//         h4 {
//           margin-top: 20px;
//         }

//         p {
//           padding-left: 0px !important;
//           padding-top: 5px !important;
//         }

//         span {
//           position: relative !important;
//         }
//       }
//     }
//   }

// }
</style>
