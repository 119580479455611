// store/modules/guests.js
export default {
    namespaced: true,
    state: {
      numberOfGuest: null,
      guestCountSetting: {
        fix_guest_count: {
          status: false
        }
      }
    },
    mutations: {
      SAVE_NUMBER_OF_GUEST(state, payload) {
        state.numberOfGuest = payload
      },

      GET_GUEST_COUNT_SETTING(state, payload) {
        state.guestCountSetting = {...payload}
      }
    },
    actions: {
      saveNumberOfGuest({commit}, payload) {
        commit('SAVE_NUMBER_OF_GUEST', payload)
      },

      getGuestCount({commit}, payload) {
        commit('GET_GUEST_COUNT_SETTING', payload)
      },
    },
  };
  