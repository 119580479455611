<template>
   <!-- call tab and message list component -->
   <div id="dashboard_wrapper">
      <div class="card-body">

         <b-card>
         <div class="row">

            <div class="col-3 border-right">
               <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                  <a class="nav-link active py-4 border mb-2" id="v-pills-messages-tab" data-toggle="pill" href="#messages" role="tab" aria-controls="v-pills-messages" aria-selected="true" name="v-pills-messages-tab">
                     {{ $t('facility_my_page_message_board.tab_title_message_board') }}
                  </a>
                  <!-- hide for now -->
                  <!-- <a class="nav-link py-4 border" id="v-pills-announcement-tab" style="pointer-events: none; color: #b4b4b4;" data-toggle="pill" href="#announcement" role="tab" aria-controls="v-pills-announcement" aria-selected="false" name="v-pills-announcement-tab">
                     {{ $t('facility_my_page_message_board.tab_title_announcement') }}
                  </a> -->
               </div>
            </div>
            <div class="col-9">
                  <div class="tab-content" id="v-pills-tabContent">
                     <div class="tab-pane fade show active" id="messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <b-card-text>
                           <MessageListComponent />
                        </b-card-text>
                     </div>
                     <div class="tab-pane fade" id="announcement" role="tabpanel" aria-labelledby="v-pills-announcement-tab">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum asperiores necessitatibus deleniti debitis impedit veritatis autem nobis pariatur molestiae totam laudantium saepe sapiente dolore fugiat dolorum voluptates, repellat a facilis!
                     </div>
                  </div>
            </div>
         </div>
      </b-card>
      </div>
   </div>
</template>
<script>
import MessageListComponent from "../components/messaging/MessagesListComponent";

export default {
   components: { MessageListComponent },
   // data() {
   //    return {

   //    }
   // },
   async mounted() {
      await this.checkIfMyPageEnabled();
      
   },
   methods: {
      async checkIfMyPageEnabled() {
         let response = await this.$lionheart.get('/v2/facility-settings', {
            params: {
               name: 'my-page-setting',
               facility_id: window._app_facility.id,
               paginate: 0
            }
         })
         .then(response => response.data.data);

         if (!response[0].value.active) {
            this.$router.push({ name: 'checkin-facility-dashboard-home' });
         }
      },
      async changeTab() {
         // api call for change tab
         console.log(this.$refs.tab,'tab ref');
      }
   },
};
</script>

<style>
/* #dashboard_wrapper .nav-tabs .nav-link.active, #dashboard_wrapper .nav-tabs .nav-item.show .nav-link {
   border-color: black;
   border-right: none;
} */
</style>