<template>
  <div class="table-responsive-lg">
    <table class="table table-bordered font-res">
      <th colspan="2">{{ $t("touch-down.contact") }}</th>
      <template v-if="!is_other">
        <tr v-for="contact in contacts" :key="contact.id">
          <td>{{ getLabel(contact.facility_questionnaire[0]) }}</td>

          <td v-if="contact.facility_questionnaire[0].system_name == 'gov_id'">
            <load-image :url="contact.value" :facility_id="facility_id" />
          </td>
          <td v-else-if="contact.facility_questionnaire[0].system_name == 'signature'">
            <load-image :url="contact.value" :facility_id="facility_id" />
          </td>
          <td
            v-else-if="contact.facility_questionnaire[0].label == 'Nationality'"
          >
            {{ getNationality(contact.value) }}
          </td>
          <td v-else>{{ contact.value }}</td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="contact in contacts" :key="contact.id">
          <td>{{ getLabel(contact.facility_questionnaire) }}</td>

          <td v-if="contact.facility_questionnaire.system_name == 'gov_id'">
            <load-image :url="contact.value" :facility_id="facility_id" />
          </td>
          <td
            v-else-if="
              contact.facility_questionnaire.system_name == 'nationality' &&
              contact.value != ''
            "
          >
            {{ getNationality(contact.value) }}
          </td>
          <td v-else>{{ contact.value }}</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import t from "typy";
import {
  core_questions,
  nationalities,
} from "@/modules/checkin-facility/utils/mockup";
import LoadImage from "./LoadImage";

export default {
  name: "Contact",

  props: {
    contacts: {
      type: Array,
      required: true,
    },
    is_other: {
      type: Boolean,
      required: false,
    },
    facility_id: {
      type: Number,
      required: true,
    },
  },

  components: {
    LoadImage,
  },

  methods: {
    getLabel(obj) {
      if (t(core_questions, this.$i18n.locale).isDefined == false)
        return obj.label;

      return core_questions[this.$i18n.locale].labels[obj.system_name];
    },

    getNationality(code) {
      let label = nationalities[this.$i18n.locale].find(
        (obj) => obj.CountryCode == code
      );

      if (label) {
        label = label.Nationality;
        return label;
      } else {
        label = nationalities[this.$i18n.locale].find(
          (obj) => obj.Nationality == code
        );
        if (label) return label.CountryCode;
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
$font-size-base: 1rem;

.font-res {
  font-size: $font-size-base;
}

@media (max-width: 1200px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 768px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 425px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 375px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 320px) {
  .font-res {
    font-size: $font-size-base * 0.48;
  }
}
</style>
