<template>
  <div>
    <!-- CONTACT -->
    <div class="mb-2 mt-n2">
        <div class="row" v-if="payment_action == 'payment_link'">
            <div class="col-lg-12">
                <div class="alert alert-success alert-dismissible fade show my-3" role="alert">
                    {{ $t("visit-information-form.manual-check-in-not-send-payment-link-success") }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="payment_action = ''">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
      <div class="panel-header">
        <b>{{ $t("visitor_log_detail_main.sub_heading_contact") }}</b>
        <div>
          <b-button
            v-if="!contact.editing"
            variant="primary"
            size="sm"
            class="btn-w-md"
            @click.prevent="editContact"
          >
            {{ $t("visitor_log_detail_main.button_edit") }}
          </b-button>
        </div>
      </div>
      <div class="panel-body">
        <b-alert
          variant="success" :show.sync="contact.showSuccess" class="mb-1 p-2" fade
        >
          <small>{{ $t("account_page_details.success_msg") }}</small>
        </b-alert>
        <table class="table table-sm table-bordered w-100 mb-0 two-col">
          <tr v-if="hasReservation">
            <td>
              {{
                hasPms ?
                $t("visit-information-form.pre-checkin-date") :
                $t("visit-information-form.checkin-date")
              }}
            </td>
            <td>{{ visitorLog.pre_checkin_date ? formMethods.formatDateHMA(visitorLog.pre_checkin_date) : '--' }}

                <div class="float-right" v-if="visitorLog.booking_details.booking_status_id == booking_statuses.pre_check_in ">
                    <button type="button" @click="$refs.mci.init(visitorLog)" class="btn btn-primary btn-sm" >
                        {{   $t("visit-information-form.manual-check-in") }}
                    </button>
                </div>

            </td>
          </tr>
          <tr v-if="hasPms">
            <td>{{ $t("visit-information-form.checkin-date") }}</td>
            <td>
              <template>
                {{
                  visitorLog.checkin_date ? formMethods.formatDateHMA(visitorLog.checkin_date) : '--'
                }}
              </template>
              <pms-badge v-if="isPmsCheckin" is-pms-checkin />
            </td>
          </tr>
          <!-- Loop through form logs -->
          <tr v-for="(item, index) in contact.originData" :key="`vflc_${index}`">
            <template v-if="item.input_type == 'image_file_upload'">
              <td>{{ getLabel(item) }}</td>
              <td>
                <div v-if="contact.editing">
                  <div>
                    <input
                      :id="`file_input_${index}`"
                      type="file"
                      accept="image/*"
                      class="d-none"
                      @change="changeImageInput($event, index)"
                    />
                    <label
                      :for="`file_input_${index}`"
                      class="d-inline-block btn btn-primary btn-sm mb-0"
                    >
                      <i class="fa fa-camera mr-1" />
                      {{ $t("account_page_details.select-an-image") }}
                    </label>
                  </div>
                  <img
                    v-show="contact.editData[index].value"
                    class="img-thumbnail img-fluid mt-2"
                    :src="contact.editData[index].value"
                  />
                </div>
                <load-image v-show="!contact.editing" :url="item.value" />
              </td>
            </template>
            <template v-else-if="index == 'dob'">
              <td>{{ getLabel(item) }}</td>
              <td>
                <date-dropdown
                  v-if="contact.editing"
                  v-model="contact.editData[index].value"
                  :default="(dateTemp) ? formatFormDate(dateTemp) : '01.01.1980'"
                  min="1900"
                  :max="Date('Y')"
                />
                <template v-else>
                  {{ item.value }}
                </template>
              </td>
            </template>
            <template v-else-if="item.system_name == 'email'">
              <td>{{ getLabel(item) }}</td>
              <template v-if="contact.editing">
                <td>
                  <input type="text" class="form-control" v-model="contact.editData[index].value" />
                </td>
              </template>
              <template v-else>
                <td>{{ getMaskedEmail(item.value) }}</td>
              </template>
            </template>
            <template v-else-if="item.system_name == 'nationality'">
              <td>{{ getLabel(item) }}</td>
              <td>
              <template v-if="contact.editing">
                <nationality-dropdown v-model="contact.editData[index].value" />
              </template>
              <template v-else>
                {{ getNationality(item.value) || "--" }}
              </template>
              </td>
            </template>
            <template v-else>
              <td>{{ getLabel(item) }}</td>
              <td>
                <template v-if="contact.editing">
                  <input type="text" class="form-control" v-model="contact.editData[index].value" />
                </template>
                <template v-else>
                  {{ item.value || "--" }}
                </template>
              </td>
            </template>
          </tr>
          <!-- Show edit buttons -->
          <tr v-if="contact.editing" class="buttons-row">
            <td colspan="2" align="right" class="p-2">
              <b-button
                variant="outline-danger"
                size="sm"
                class="btn-w-md"
                @click.prevent="contact.editing=false"
              >
                {{ $t("account_page_details.button_cancel") }}
              </b-button>
              &nbsp;
              <b-button variant="primary" size="sm" class="btn-w-md"  @click.prevent="saveContact">
                {{ $t("account_page_details.button_save") }}
              </b-button>
            </td>
          </tr>
        </table>

      </div>
    </div>
    <!-- SANITATION -->
    <div v-if="visitorFormLog.sanitation && visitorFormLog.sanitation.length > 0" class="mb-2">
      <div class="panel-header">
        <b>{{ $t("visitor_log_detail_main.sub_heading_sanitation") }}</b>
        <a href="#"></a>
      </div>
      <div class="panel-body">
        <table class="table table-sm table-bordered w-100 mb-0 two-col">
          <tr v-for="(vfl, index) in visitorFormLog.sanitation" :key="`vfls_${index}`">
            <template>
              <td>{{ getLabel(vfl) }}</td>
              <td>{{ vfl.value || "--" }}</td>
            </template>
          </tr>
        </table>
      </div>
    </div>
    <!-- VISIT INFO -->
    <div v-if="visitorFormLog.visit_info && visitorFormLog.visit_info.length > 0" class="mb-2">
      <div class="panel-header">
        <b>{{ $t("visitor_log_detail_main.sub_heading_guest_info") }}</b>
        <a href="#"></a>
      </div>
      <div class="panel-body">
        <table class="table table-sm table-bordered w-100 mb-0 two-col">
          <tr v-for="(vfl, index) in visitorFormLog.visit_info" :key="`vflv_${index}`">
            <template>
              <td>{{ getLabel(vfl) }}</td>
              <td>{{ vfl.value || "--" }}</td>
            </template>
          </tr>
        </table>
      </div>
    </div>
    <!-- OTHERS -->
    <div v-if="visitorFormLog.optional && visitorFormLog.optional.length > 0">
      <div class="panel-header">
        <b>{{ $t("visitor_log_detail_main.sub_heading_others") }}</b>
        <a href="#"></a>
      </div>
      <div class="panel-body">
        <table class="table table-sm table-bordered w-100 mb-0 two-col">
          <tr v-for="(vfl, index) in visitorFormLog.optional" :key="`vflo_${index}`">
            <template v-if="vfl.facility_questionnaire[0].system_name == 'terms_conditions'">
              <td>{{ $t("visit-information-form.terms_and_condition") }}</td>
              <td>{{ vfl.value || "--" }}</td>
            </template>
            <template v-else-if="vfl.facility_questionnaire[0].system_name == 'vaccine_card'">
              <td>{{ vfl.facility_questionnaire[0].label }}</td>
              <td><load-image :url="vfl.value" /></td>
            </template>
            <template v-else>
              <td>{{ vfl.facility_questionnaire[0].label }}</td>
              <td>{{ vfl.value || "--" }}</td>
            </template>
          </tr>
        </table>
      </div>
    </div>

    <!-- COMPANIONS -->
    <template v-for="(companion, index) in companionFormLog">
      <div :key="`companion_${index}`" class=" mt-3">
        <b-button
          variant="primary"
          v-b-toggle="`companion_details_${index}`"
          class="panel-toggle-btn header-on-print"
        >
          <div>
            <i class="fa fa-user mr-1" />
            {{$t('visit-information-form.companion')}} # {{ index + 1 }}
          </div>
          <div>
            <i class="fa fa-chevron-down collapse-icon" />
          </div>
        </b-button>
        <b-collapse :id="`companion_details_${index}`" class="panel-toggle-body">
          <!-- Contact -->
          <div v-if="companion.fco.contact && companion.fco.contact.length > 0" class="m-2">
            <div class="panel-header">
              <b>{{ $t("visitor_log_detail_main.sub_heading_contact") }}</b>
            </div>
            <div class="panel-body">
              <table class="table table-sm table-bordered w-100 mb-0 two-col">
                <tr v-for="(c_item, c_index) in companion.fco.contact" :key="`fco_${c_index}`">
                  <td>{{ getLabel(c_item, true) }}</td>
                  <td>
                    <template v-if="getInputType(c_item, true) == 'image_file_upload'">
                      <load-image :url="c_item.value" />
                    </template>
                    <template v-else-if="getSysName(c_item, true) == 'nationality'">
                      {{ getNationality(c_item.value) || "--" }}
                    </template>
                    <template v-else>
                      {{ c_item.value }}
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Sanitation -->
          <div v-if="companion.fco.sanitation && companion.fco.sanitation.length > 0" class="m-2">
            <div class="panel-header">
              <b>{{ $t("visitor_log_detail_main.sub_heading_sanitation") }}</b>
            </div>
            <div class="panel-body">
              <table class="table table-sm table-bordered w-100 mb-0 two-col">
                <tr v-for="(c_item, c_index) in companion.fco.sanitation" :key="`fco_${c_index}`">
                  <td>{{ getLabel(c_item, true) }}</td>
                  <td>
                    <template v-if="getInputType(c_item, true) == 'image_file_upload'">
                      <load-image :url="c_item.value" />
                    </template>
                    <template v-else-if="getSysName(c_item, true) == 'nationality'">
                      {{ getNationality(c_item.value) || "--" }}
                    </template>
                    <template v-else>
                      {{ c_item.value }}
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Others -->
          <div v-if="companion.fco.optional && companion.fco.optional.length > 0" class="m-2">
            <div class="panel-header">
              <b>{{ $t("visitor_log_detail_main.sub_heading_others") }}</b>
            </div>
            <div class="panel-body">
              <table class="table table-sm table-bordered w-100 mb-0 two-col">
                <tr v-for="(c_item, c_index) in companion.fco.optional" :key="`fco_${c_index}`">
                  <td>{{ getLabel(c_item, true) }}</td>
                  <td>
                    <template v-if="getInputType(c_item, true) == 'image_file_upload'">
                      <load-image :url="c_item.value" />
                    </template>
                    <template v-else-if="getSysName(c_item, true) == 'nationality'">
                      {{ getNationality(c_item.value) || "--" }}
                    </template>
                    <template v-else>
                      {{ c_item.value }}
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </b-collapse>
      </div>
    </template>
      <manual-check-in-confirmation ref="mci" @click-handler="checkin"/>
  </div>
</template>

<script>
import moment from "moment-timezone";
import loadImage from "../loadImage";
import DateDropdown from "vue-date-dropdown";
import NationalityDropdown from "../form/NationalityDropdown.vue";
import PmsBadge from "../PmsBadge";
import t from "typy";
import _ from "lodash";
import {
  core_questions as coreQuestions,
  nationalities,
  main_form_methods as formMethods,
} from "@/modules/checkin-facility/utils/mockup";
import ManualCheckInConfirmation from "./ManualCheckInConfirmation";
import {booking_status, payment_gateway} from "../../../../utils/mockup";
import { maskedDummyEmails } from "../../../../utils/helpers";

export default {
  components: { loadImage, DateDropdown, NationalityDropdown, PmsBadge,ManualCheckInConfirmation  },
  data() { return {
    t,
    formMethods,
    timer: null,
    dateTemp: null,
    specials: ['age', 'dob', 'gender', 'nationality', 'gov_id'],
    contact: {
      editing: false,
      originData: {},
      editData: {},
      showSuccess: false,
    },
      booking_statuses: booking_status,
      payment_action: "paid"
  }},
  props: {
    visitorLog: {
      type: Object,
      default: () => {}
    },
    visitorFormLog: {
      type: Object,
      default: () => {}
    },
    companionFormLog: {
      type: Array,
      default: () => []
    },
    hasPms: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bookingDetails() {
      return this.visitorLog.booking_details;
    },
    isPmsCheckin() {
      return this.visitorLog.booking_details !== null &&
      this.visitorLog.booking_details.hasOwnProperty('condition_metadata') &&
      this.visitorLog.booking_details.condition_metadata !== null &&
      this.visitorLog.booking_details.condition_metadata.is_pms_checkin === 1
    },
    hasReservation() {
      if (t(this.visitorLog, 'booking_details.parsed.reservation_number').isDefined) {
        return this.visitorLog.booking_details.parsed.reservation_number;
      }
      return false;
    }
  },
  mounted() {
    this.buildContactFormAnswers(this.visitorFormLog.contact);
  },
  methods: {
    getMaskedEmail(email) {
      return maskedDummyEmails(email)
    },
    changeImageInput(e, dataIndex) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result;
        this.$set(this.contact.editData[dataIndex], "value", dataUrl);
      }
      reader.readAsDataURL(file);
    },
    editContact() {
      clearTimeout(this.timer);
      this.contact.showSuccess = false;
      const resetData = {};
      for (const key of Object.keys(this.contact.originData)) {
        const item = this.contact.originData[key];
        resetData[item.system_name] = {
          id: item.id,
          label: item.label,
          input_type: item.input_type,
          system_name: item.system_name,
          rank: item.rank,
          required: item.required,
          value: item.input_type == 'image_file_upload' ? null : item.value,
        }
      }
      this.$set(this.contact, 'editData', resetData);
      this.contact.editing = true;
    },
    formatDatTime(date) {
      // const timezone = "utc";
      const timezone = moment.tz.guess();
      return moment.tz(date, timezone).format("YYYY-MM-DD hh:mm:ss A");
    },
    formatFormDate(date) {
        if (date == null) return "";
        const [y, m, d] = date.split(".");
        date = `${d}.${m}.${y}`;
        const timezone = moment.tz.guess();
        return moment.tz(date, timezone).format("MM/DD/YYYY");
    },
    getLabel(obj, companion) {
    
      // if(obj.system_name == 'email') return maskedDummyEmails(obj.value);
      
      let question = t(obj.facility_questionnaire).isArray
                        ? obj.facility_questionnaire[0]
                        : obj;

      if (companion) question = obj.facility_questionnaire;

      if (t(coreQuestions, this.$i18n.locale).isDefined == false) return question.label;

      return t(coreQuestions[this.$i18n.locale].labels, question.system_name)
        .isDefined
        ? coreQuestions[this.$i18n.locale].labels[question.system_name]
        : question.label;
    },
    getSysName(obj, companion) {
      if (companion) return obj.facility_questionnaire.system_name;
      return obj.facility_questionnaire[0].system_name;
    },
    getInputType(obj, companion) {
      if (companion) return obj.facility_questionnaire.input_type;
      return obj.facility_questionnaire[0].input_type;
    },
    getNationality(code) {
      let label = nationalities[this.$i18n.locale].find((obj) => obj.CountryCode == code);

      if (label) {
        label = label.Nationality;
        return label;
      } else {
        label = nationalities[this.$i18n.locale].find((obj) => obj.Nationality == code);
        if (label) return label.CountryCode;
      }

      return "";
    },
    getContactDefaultValue(sysName) {
      if (this.contact.editData[sysName].value) return this.contact.editData[sysName].value;
      return this.contact.originData[sysName].value;
    },
    async saveContact() {
      const data = {
        facility_id: window._app_facility.id,
        email: null,
        other_forms: []
      }

      for (const key of Object.keys(this.contact.editData)) {
        const item = this.contact.editData[key];
        if (item.system_name == 'email') {
          data.email = item.value
        } else {
          data.other_forms.push({
            id: item.id,
            system_name: item.system_name,
            rank: item.rank,
            required: item.required,
            value: item.input_type == 'image_file_upload'
                   ? this.getContactDefaultValue(item.system_name)
                   : item.value
          });
        }
      }
      
      const response = await this.$lionheart.put(`/log/visitor/${this.visitorLog.id}`, data);
      this.contact.editing = false;
      this.contact.showSuccess = true;
      this.timer = setTimeout(() => {
        this.contact.showSuccess = false;
      }, 3000);
      this.$emit('updated');
    },
    buildContactFormAnswers(inputData) {
      const keyedAnswers = {}
      let index = 0;
      const x = ['reservation_no', 'occupancy'];
      for(const data of inputData) {
        const item = data.facility_questionnaire[0];
        if (!x.includes(item.system_name)) {
          const formattedData = {
            id: data.id,
            label: item.label,
            input_type: item.input_type,
            system_name: item.system_name,
            rank: item.rank,
            required: item.required,
            value: data.value,
          };
          keyedAnswers[item.system_name] = formattedData;
          if (item.system_name == 'dob') this.dateTemp = data.value;
          if (index == 1) {
            const emailValue = this.visitorLog.visitor.user.email.includes('@dummys.com') ?
              '--' : this.visitorLog.visitor.user.email;
            keyedAnswers['email'] = {
              id: null,
              label: 'email',
              input_type: null,
              system_name: 'email',
              rank: 1,
              required: 1,
              value: emailValue
            }
          }
          index++;
        }
      };
      const originData = _.cloneDeep(keyedAnswers);
      const editData = _.cloneDeep(keyedAnswers);
      if (editData['gov_id']) editData['gov_id'].value = null;
      this.$set(this.contact, "originData", originData);
      this.$set(this.contact, "editData", editData);
    },
      async checkin($event){


        if(['paid','payment_link', 'payment_hotel'].includes($event)){


            let data = {   
                code: `${this.visitorLog.visitor.user.email}|${this.visitorLog.booking_details.pass}`,
                pms_code: this.visitorLog.booking_details.pms_code,
                facility_id: this.visitorLog.facility_id,
                payment_method: $event == 'payment_hotel' ? payment_gateway.reception : null
            }
            
            await this.$lionheart.post(`v2/checkin/f/checkin`, data).then(() => {
                this.payment_action = $event;
                this.$emit('updated');
            });
        }

      }
  },
  watch: {
    visitorFormLog(changedData) {
      this.buildContactFormAnswers(changedData.contact);
    }
  }
}
</script>