<template>
  <div>
    <template v-if="!multiple && !isFixGuestEnabled">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
        </label>
        <div class="col-sm-10 my-auto" v-if="loadDob">
          <dropdown-datepicker
            v-model="inputData[c.id]"
            :defaultDate="defaultFormat(inputData[c.id])"
            :required="c.required ? true : false"
            :wrapperClass="!confirm ? 'd-flex' : 'd-none'"
            dropdownClass="form-control w-auto mr-1"
            defaultDateFormat="dd.mm.yyyy"
            submitFormat="dd.mm.yyyy"
            :daySuffixes="false"
            :allowFuture="false"
            :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
            :dayLabel="$t('visit-information-form.day')"
            :monthLabel="$t('visit-information-form.month')"
            :yearLabel="$t('visit-information-form.year')"
            :monthLongValues="months"
            :minYear="minYear"
          />
          <p v-if="confirm" class="mb-0">{{ formatDate(inputData[c.id]) }}</p>
        </div>
      </div>
      <!-- For data aggregation -->
      <div
        :class="companions.length > 0 && !confirm ? 'form-group row' : 'd-none'"
      >
        <template v-for="(companion, index) in companions">
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <dropdown-datepicker
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              :wrapperClass="
                !confirm && different.includes(c.system_name)
                  ? 'd-flex mt-3'
                  : 'd-none'
              "
              dropdownClass="form-control w-auto mr-1"
              defaultDateFormat="dd.mm.yyyy"
              submitFormat="dd.mm.yyyy"
              :daySuffixes="false"
              :allowFuture="false"
              :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
              :dayLabel="$t('visit-information-form.day')"
              :monthLabel="$t('visit-information-form.month')"
              :yearLabel="$t('visit-information-form.year')"
              :monthLongValues="months"
              :minYear="minYear"
            />
          </div>
        </template>
      </div>
    </template>

    <template v-else-if="!multiple && isFixGuestEnabled">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
        </label>
        <div class="col-sm-10 my-auto" v-if="loadDob">
          <dropdown-datepicker
            v-model="visitor_forms[c.id]"
            :defaultDate="defaultFormat(visitor_forms[c.id])"
            :required="c.required ? true : false"
            :wrapperClass="!confirm ? 'd-flex' : 'd-none'"
            dropdownClass="form-control w-auto mr-1"
            defaultDateFormat="dd.mm.yyyy"
            submitFormat="dd.mm.yyyy"
            :daySuffixes="false"
            :allowFuture="false"
            :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
            :dayLabel="$t('visit-information-form.day')"
            :monthLabel="$t('visit-information-form.month')"
            :yearLabel="$t('visit-information-form.year')"
            :monthLongValues="months"
            :minYear="minYear"
          />
          <p v-if="confirm" class="mb-0">{{ formatDate(visitor_forms[c.id]) }}</p>
        </div>
      </div>

      <div
        :class="fixGuestCount > 1 && !confirm ? 'form-group row' : 'd-none'"
      >
        <template v-for="(companion, index) in companions">
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <dropdown-datepicker
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              :wrapperClass="
                !confirm && different.includes(c.system_name)
                  ? 'd-flex mt-3'
                  : 'd-none'
              "
              dropdownClass="form-control w-auto mr-1"
              defaultDateFormat="dd.mm.yyyy"
              submitFormat="dd.mm.yyyy"
              :daySuffixes="false"
              :allowFuture="false"
              :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
              :dayLabel="$t('visit-information-form.day')"
              :monthLabel="$t('visit-information-form.month')"
              :yearLabel="$t('visit-information-form.year')"
              :monthLongValues="months"
              :minYear="minYear"
            />
          </div>
        </template>
      </div>

    </template>

    <!-- Multiple Room -->

    <template v-else>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
        </label>
        <div class="col-sm-10 my-auto" v-if="loadDob">
          <dropdown-datepicker
            :wrapperClass="!confirm ? 'd-flex' : 'd-none'"
            v-model="multiple_data[bd_index].visitor_forms[c.id]"
            :required="
              multiple_data[bd_index].filled_in_by_other
                ? false
                : c.required
                ? true
                : false
            "
            :defaultDate="
              multiple_data[bd_index].visitor_forms[c.id] ??
              formatDate(multiple_data[bd_index].visitor_forms[c.id])
            "
            dropdownClass="form-control w-auto mr-1"
            defaultDateFormat="dd.mm.yyyy"
            submitFormat="dd.mm.yyyy"
            :daySuffixes="false"
            :allowFuture="false"
            :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
            :dayLabel="$t('visit-information-form.day')"
            :monthLabel="$t('visit-information-form.month')"
            :yearLabel="$t('visit-information-form.year')"
            :monthLongValues="months"
            :minYear="minYear"
          />
          <p v-if="confirm" class="mb-0">
            {{ formatDate(multiple_data[bd_index].visitor_forms[c.id]) }}
          </p>
        </div>
      </div>
      <!-- For data aggregation -->
      <div
        :class="
          multiple_data[bd_index].companions.length > 0 && !confirm
            ? 'form-group row'
            : 'd-none'
        "
      >
        <template
          v-for="(companion, index) in multiple_data[bd_index].companions"
        >
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown",
                  true,
                  bd_index
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <dropdown-datepicker
              :wrapperClass="
                !confirm && different.includes(c.system_name)
                  ? 'd-flex mt-3'
                  : 'd-none'
              "
              v-model="companion.other_forms[c.id]"
              :defaultDate="
                companion.other_forms[c.id] ??
                formatDate(companion.other_forms[c.id])
              "
              :required="c.required ? true : false"
              dropdownClass="form-control w-auto mr-1"
              defaultDateFormat="dd.mm.yyyy"
              submitFormat="dd.mm.yyyy"
              :daySuffixes="false"
              :allowFuture="false"
              :displayFormat="form_language == 'en' ? 'mdy' : 'ymd'"
              :dayLabel="$t('visit-information-form.day')"
              :monthLabel="$t('visit-information-form.month')"
              :yearLabel="$t('visit-information-form.year')"
              :monthLongValues="months"
              :minYear="minYear"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import DropdownDatepicker from "vue-dropdown-datepicker";
import { main_form_methods } from "../../../../utils/mockup";
import { mapState, mapActions } from "vuex";
import { checkinFormTypeWithMemorization, loadMemorizedData } from "../../../../utils/helpers";
import guestWatcherMixin from "../../../../../../mixins/guestWatcherMixin";

const { showLabelOrPlaceholder, formatDate, populateCompanionsFields, isFixGuestEnabled } = main_form_methods;
export default {
  components: {
    DropdownDatepicker,
  },
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    companions: {
      type: Array,
      default: [],
    },
    different: {
      type: Array,
      default: [],
    },
    questions: {
      type: Array,
      default: [],
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room
    form_language: {
      type: String,
      default: "en",
    },
    // checkin form type
    checkin_form_type: "",
    reservation_no: null,
    fixGuestCount: {
      type: Number,
      default: null
    },
    fixGuestSetting: {
      type: Object,
      default: {}
    }
  },
  mixins: [guestWatcherMixin],
  data() {
    return {
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      loadDob: false,
      minYear: 1900,
      inputData: {},
      formType: "",
      defaultCompanions: [...this.companions]
    };
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
    isFixGuestEnabled
  },
  updated() {
    this.handleFormType();
  },
  async mounted() {
    await this.initializeForm();
  },
  methods: {
    ...mapActions("memorizedInputs", [
            "saveInput", 
            "saveWalkInInputs",
            "saveReservationNumber",
            "checkIfCheckinFormType"
    ]),

    showLabelOrPlaceholder,
    formatDate,
    populateCompanionsFields,
    async initializeForm() {

      const rid = this.$route.query.rid
      const guestCountSetting = await this.guestCountSetting
      checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);

      this.inputData = await loadMemorizedData(
        this,
        this.memorizedInputs,
        this.memorizedWalkInInputs,
        this.checkin_form_type,
        this.visitor_forms
      );

      this.populateCompanionsFields();

    },
    handleFormType() {
      this.checkin_form_type === "others" 
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },
    defaultFormat(inputDate) {
      let formattedDate = null;

      if (inputDate) {
        const dateParts = inputDate.split(".");
        formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
      }

      return formattedDate;
    },
    
  },
  watch: {
    questions: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.loadDob = false;
        setTimeout(() => {
          this.loadDob = true;
        }, 300);
      },
    },
  },
};
</script>