<template>
   <div>
      <b-card v-if="showBookingDetails" style="background-color:whitesmoke" class="mb-2 p-0">
         <table style="width:100%; font-size: 13px; text-align:left">
            <tr v-show="this.isDisplayPassOn" class="p-2">
            <td> {{ $t('visitor-info-thank-you.pass') }}</td>
            <td class="booking-value">
               <span>{{ bookingDetails.pass }}</span>
            </td>
            </tr>
            <tr v-show="this.isDisplayPassOn">
               <td colspan="2">
                  <span class="password-legend">{{ $t('visitor-info-thank-you.label_pass') }}</span>
               </td>
            </tr>
            
            <tr v-if="bookingDetails.checkin_date">
               <td style="white-space: nowrap;"> {{ $t('visitor-info-thank-you.check_in') }}</td>
               <td class="booking-value" style="white-space: nowrap;">
                  <span class="date_value">
                     {{ bookingDetails.checkin_date }}
                  </span>
               </td>
            </tr>
            <tr v-if="bookingDetails.checkout_date">
               <td style="white-space: nowrap;"> {{ $t('visitor-info-thank-you.check_out') }}</td>
               <td class="booking-value" style="white-space: nowrap;">
                  <span class="date_value">
                     {{ bookingDetails.checkout_date }}
                  </span>
               </td>
            </tr>
         </table>
      </b-card>

      <!-- digital pin details -->
      <b-card style="background-color:whitesmoke" class="mb-2" v-if="bookingDetails.pin.length > 0">
         <table style="width:100%; font-size: 13px; text-align:left">
            <tbody style="font-weight: 500;">
                  <tr v-for="(pin, i) in bookingDetails.pin" :key="i" >
                     <td>

                        <tr>
                           <td style="width: 100%"> 
                                 <span> {{ $t('visitor-info-thank-you.room_number') }}</span>
                           </td>
                           <td class="booking-value">{{  pin.room_name }}</td>
                        </tr>

                        <tr>
                           <td style="width: 100%"> 
                              <span> {{ $t('visitor-info-thank-you.pin') }} <span v-if="bookingDetails.pin.length > 1">{{  i+1 }}</span></span>
                           </td>
                           <td class="booking-value">{{  pin.pin }}</td>
                        </tr>
                        
                        <hr v-if="!isLastItem(i, bookingDetails.pin)" class="m-1" />
                     </td>
                  </tr>        
            </tbody>
         </table>
      </b-card>
   </div>
</template>

<script>
   import t from "typy";
   export default{
      
      props:{
         bookingDetails:{
            type: Object,
            default: null,
         },
      },

      data() {
         return {
            loading: false,
            facility: {
               name: "...",
            },
            isDisplayPassOn: false,
         }
      },

      async mounted() {
         this.facility = await this.$lionheart
            .post("/facility/list", { slug_name: this.$route.params.slug_name })
            .then((r) => this.catcher(r.data, "data[0].id", (r) => r.data[0]))
            .catch((e) => this.catcher(e));

         if(this.facility.error.has_error) return; // Silent API error catch   
         
         const displayPass = await this.$lionheart
         .get("/v2/facility-settings", {
            params: {
               name: 'display_pass_email',
               facility_id: this.facility.id,
               paginate: 0
            }
         })
         .then(res => res.data.data);
         this.isDisplayPassOn = displayPass[0]?.value.visibility ?? true;
      },
      
      methods: {
         isLastItem(index, array) {
            return index === array.length - 1
         },
         
      },
      computed: {
         showBookingDetails() {
            return ((t(this.bookingDetails, 'checkin_date').isDefined && t(this.bookingDetails, 'checkout_date').isDefined ) || this.isDisplayPassOn === true)
         }
      }
   }
</script>

<style lang="scss" scoped>
.password-legend {
    display: block;
    font-size: 10px;
    opacity: 0.5;
}
.booking-value {
    font-weight: 300;
    text-align: right;
    padding-left: 70px;
}
.date_value {
    font-size: 13px;
}

</style>
