<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          {{ $t("checkout_details.title") }}
        </h6>
      </div>
      <div class="card-body">
        <div
          id="error_msg"
          v-if="error_msg"
          class="alert alert-danger"
          role="alert"
        >
          {{ error_msg }}
        </div>
        <div
          id="success_msg"
          v-if="success_msg"
          class="alert alert-success"
          role="alert"
        >
          {{ success_msg }}
        </div>
        <div class="row mb-2">
          <div class="col-sm-4">
            {{ $t("checkout_details.checkout-date") }}:
          </div>
          <div class="col-sm-8">
            {{ checkoutDate() }}
            <pms-badge v-if="isPmsCheckout" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4">
            {{ $t("checkout_details.booking_status") }}:
          </div>
          <div class="col-sm-8">
            {{ $t(`booking_statuses.${booking.booking_status.code}`) }}
          </div>
        </div>
        <div class="row d-none">
          <div class="col-sm-4">
            {{ $t("checkout_details.confirmed-checkout") }}:
          </div>
          <div class="col-sm-8 d-flex align-items-center">
            <div class="d-flex align-items-center">
              <span class="mr-2">
                {{ $t("checkout_details.confirmed") }}
              </span>
              <input
                type="checkbox"
                v-model="confirmed"
                required
                :disabled="booking.booking_status_id != 8"
              />
            </div>
            <button
              :disabled="booking.booking_status_id != 8"
              :class="booking.booking_status_id != 8 ? 'btn-dark' : 'btn-primary'"
              @click="checkConfirmation"
              type="button"
              class="btn px-5 py-0 ml-5"
            >
              {{ $t("checkout_details.save") }}
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-4">
            {{ $t("checkout_details.receipt") }}:
          </div>
          <div class="col-sm-8">
            <template
              v-if="
                [
                  payment_status.paid_authorized,
                  payment_status.paid_captured,
                ].includes(booking.payment_status_id) &&
                booking.booking_status_id == booking_status.checkout_completed
              "
            >
              <a href="#" @click.prevent="viewReceipt">
                {{ $t("checkout_details.view_receipt") }}
              </a>
            </template>
            <template v-else>
              {{ $t("checkout_details.not_available") }}
            </template>
          </div>
        </div>
      </div>
    </div>
    <confirmation-modal-component @click-handler="submit"/>
    <pdf-viewer v-if="receipt.src" :src="receipt.src" />
  </div>
</template>

<script>
import _ from "lodash";
// import t from "typy";
import moment from "moment-timezone";
import ConfirmationModalComponent from "./confirmation-modal.component";
import PdfViewer from "./PdfViewer";
import { booking_status, payment_status } from "../../../utils/mockup";
import PmsBadge from "./PmsBadge";

export default {
  components: {
    ConfirmationModalComponent,
    PdfViewer,
    PmsBadge
  },
  props: {
    vl: null,
  },
  data() {
    return {
      //Data
      facility: null,
      pms_code: null,
      visitor: null,
      payment_status,
      booking_status,
      booking: {
        booking_status: {
          name: "---",
        },
        booking_status_id: 0,
        checkout_date: moment().format("YYYY/MM/DD"),
        checkout_time: moment().format("HH:mm"),
      },
      //Form
      error_msg: "",
      success_msg: "",
      confirmed: false,
      receipt: {
        src: "",
      },
    };
  },
  computed: {
    bookingLabel () {
      if (this.booking.booking_status_id == 0) return "---";

      return this.$t(`booking_statuses.${this.booking.booking_status.code}`);
    },
    isPmsCheckout () {
      return this.booking.hasOwnProperty('condition_metadata') && 
        this.booking.condition_metadata !== null && 
        this.booking.condition_metadata.is_pms_checkout === 1
    }
  },
  async mounted() {
    this.facility = window._app_facility;
    const pms_settings = await this.$lionheart.post("/facility-setting/list", {
      facility_id: window._app_facility.id,
      name: "pms_settings",
      paginate: false,
    });

    if (pms_settings.data.total != 0)
      this.pms_code = pms_settings.data.data[0].value.pms_code;
    else return this.$router.push("/404");

    const booking = await this.$lionheart.post("/facility-key/f/booking", {
      search: this.vl.id,
      mode: "log_id",
      pms_code: this.pms_code,
      facility_id: this.facility.id,
    });

    if (booking.data.total != 0) this.booking = booking.data.data;
  },
  methods: {
    async checkConfirmation () {
      if (this.confirmed) {
        this.$bvModal.show("modal-confirmation");
      }
    },
    async viewReceipt () {
      const data = {
        facility_id: this.facility.id,
        facility_booking_id: this.booking.id,
      };

      const receipt = await this.$lionheart.get(
        "/facility-key/f/checkout/receipt",
        { params: data }
      );
      if (receipt.data && receipt.data.data) {
        this.receipt.src = receipt.data.data;
        setTimeout(() => this.$bvModal.show("modal-pdf-viewer"), 300);
      }
    },
    async submit () {
      this.error_msg = "";
      this.success_msg = "";
      if (this.vl.visitor.user.email) {
        const data = {
          search: this.booking.checkout_key,
          mode: "key",
          pms_code: this.pms_code,
          facility_id: this.facility.id,
        };

        const checkout = await this.$lionheart
          .post("/facility-key/f/checkout", data)
          .then((r) => this.catcher(r.data, "data[0].id", (r) => r.data[0]))
          .catch((e) => this.catcher(e));

        console.log(checkout);
        if (checkout.error.has_error) {
          return this.errorDisplay(checkout);
        } else {
          this.confirmed = false;
          this.booking = checkout;
          return this.successDisplay("Checkout success");
        }
      }
    },
    errorDisplay (d) {
      this.error_msg = d.error.msg;
      return;
    },
    successDisplay (d) {
      this.success_msg = d;
      return;
    },
    checkoutDate () {
      const co_statuses = [
        this.booking_status["checked_out"],
        this.booking_status["checkout_completed"],
      ];
      if (co_statuses.includes(this.booking.booking_status_id)) {
        return this.formatDate(
          `${this.booking.checkout_date} ${this.booking.checkout_time}`
        );
      } else {
        return "---";
      }
    },
    checkedOutBy () {
      return this.booking.booking_status.name;
    },
    formatDate (date) {
      return moment(date).format("MM/DD/YY hh:mm A");
    },
  },
};
</script>