import _ from "lodash";
import t from "typy";
import draggable from "vuedraggable";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	components: {
		draggable,
		LanguageButtonComponent,
		ConfirmationModalComponent,
	},
	data() {
		return {
			lang: "",
			submit_btn: "",
			error_msg: "",
			add_msg: "",
			updated_msg: "",
			remove_msg: "",
			mode: "create",
			facility: {},
			facility_questions: [],
			optional_question: {},
			optional_questions: [],
			config: {
				question_delete_id: 0,
			},
			temp_questions: [],
			localized_filters: {},
			is_localized: false,
		};
	},
	async mounted() {
		this.facility = window._app_facility;
		await this.$lionheart.post("/facility-question/init", { facility_id: this.facility.id });
		await this.loadQuestions();
		this.config = { save_button: 0 };
	},
	methods: {
		setInitialQuestionObj() {
			this.optional_question = {
				language: this.lang,
				facility_id: this.facility.id,
			};
		},
		async deleteQuestion(id) {
			await this.$lionheart.delete("/facility-optional-questions/" + this.optional_question.id).then((r) => {
				const key = this.temp_questions.map((el) => el.id).indexOf(this.optional_question.id);
				if (key >= 0) {
					this.$delete(this.temp_questions, key);
				}
				this.loadQuestions(this.optional_question.language);
				jQuery("#modalQDelete").modal("toggle");
			});
		},
		openQuestionModal(id, obj) {
			this.optional_question = {};
			if (id) {
				this.optional_question = Object.assign({}, obj);
			} else {
				this.setInitialQuestionObj();
			}
			if (!this.optional_question.metadata) {
				this.optional_question.metadata = {
					options: [],
				};
			}
			if (!this.optional_question.metadata.options) {
				this.optional_question.metadata.options = [];
			}
			this.$bvModal.show("modal-update-question");
		},
		async btnSaveQuestion() {
			var default_value = {
				input_type: "string",
				section: "optional",
				visibility: 1,
				is_core: 0,
				system_name: "optional_question",
				id: new Date().getTime() + "_new",
			};
			const language = this.optional_question.language;
			this.temp_questions.push({ ...this.optional_question, ...default_value });

			jQuery("#modalQuestion").modal("toggle");
			await this.populate_questions();
			this.config.save_button = 1;
			this.setInitialQuestionObj();
		},
		async batchSavingQuestion() {
			const questions = Object.assign(this.temp_questions, this.facility_questions);
			await this.$lionheart.put("/facility-optional-questions/" + this.facility.id, questions).then((success) => {
				this.temp_questions = [];
				this.config.save_button = 0;
				this.loadQuestions(this.lang);
			});
		},
		async populate_questions() {
			this.optional_questions = [];
			this.facility_questions.forEach((value, key) => {
				if (this.temp_questions) {
					var q = this.temp_questions.find((el) => el.id == value.id);
					if (q) {
						this.facility_questions[key] = q;
					}
				}
			});

			this.temp_questions.forEach((value, key) => {
				const k = this.optional_questions.map((el) => el.id).indexOf(value.id);
				if (k >= 0) {
					this.optional_questions[k] = value;
				} else if (value.id.toString().indexOf("_new") !== -1) {
					this.optional_questions.push(value);
				}
			});
		},

		async loadQuestions(language, from) {
			if (from && from == "language-button") this.temp_questions = [];
			if (!language) language = this.$i18n.locale == "ja" ? "ja" : "en";

			this.lang = language;

			const facility_questions = await this.$lionheart
				.post("/facility-question/f/list", {
					facility_id: this.facility.id,
					language: language,
					paginate: false,
				})
				.then((r) => this.catcher(r.data, "data[0].id", (r) => r))
				.catch((e) => this.catcher(e));

			this.facility_questions = facility_questions.data;
			await this.localizedFilters();
			await this.populate_questions();
		},

		async updateQuestion(fq, obj, method = null, l_f_index) {
			if (method == "localized") {
				// Localized Filter
				fq.has_many_localized_filter[l_f_index].status = obj.value ? 1 : 0;
				this.saveFilterToAll(fq.system_name, fq.has_many_localized_filter[l_f_index].facilities_localized_category_id, fq.has_many_localized_filter[l_f_index].status);
			} else {
				if (method == "switch") {
					fq.visibility = obj.value ? 1 : 0;
				}
				if (method == "required") {
					fq.required = obj.value ? 1 : 0;
				}
				const key = this.temp_questions.map((el) => el.id).indexOf(fq.id);
				if (key >= 0) {
					this.temp_questions[key] = fq;
				} else {
					this.temp_questions.push({ ...fq });
				}
				this.$set(
					this.facility_questions,
					this.facility_questions.findIndex((el) => el.id == fq.id),
					fq
				);
				this.$bvModal.hide("modal-update-question");

				this.config.save_button = 1;
				this.populate_questions();
			}
		},
		errorDisplay(d) {
			this.error_msg = d.error.msg;
			setTimeout(() => window.scrollTo(0, $("#error_msg").position().top - 10), 300);
			return;
		},
		async addOption() {
			this.optional_question.metadata.options.push({
				name: "Option " + (this.optional_question.metadata.options.length + 1),
			});
		},
		async localizedFilters() {
			const response = await this.$lionheart.get("/localized-filters/" + this.facility.id);
			if (response.data.data != null) {
				if (response.data.data.value.visibility == true && response.data.data.facility_localized_category.length > 0) {
					this.localized_filters = response.data.data.facility_localized_category;
					this.is_localized = true;
				} else {
					this.is_localized = false;
				}
			} else {
				this.is_localized = false;
			}
		},
		async saveFilterToAll(system_name, id, status) {
			await this.$lionheart.post("/facility-question/filter/update", {
				facility_id: this.facility.id,
				system_name: system_name,
				facilities_localized_category_id: id,
				status: status,
			});
		},
		isValueLocalized(filter, questions) {
			let status = false;
			console.log(questions);
			let exist = questions.find((el) => el.facilities_localized_category_id == filter.id && el.status == 1);
			if (exist) {
				status = true;
			}
			return status;
		},
	},
};
