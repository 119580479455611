<template>
  <div>
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">
        {{ $t("account-page-payment.payment_option_note") }}
      </h6>
    </div>
    <div class="card-body pt-0">
      <div class="col-lg-12" v-if="success">
        <div class="alert alert-success mt-3">
          {{ $t("account-page-facility-language-option.success_save") }}
        </div>
      </div>
      <form class="p-3" @submit="submit">
        <div class="form-group row">
          <b>{{ $t("account-page-payment.payment_option_title") }}</b>
        </div>

        <div class="form-group row">
          <div class="form-check form-check-inline">
            <input
              v-model="payment_option.checkin"
              required
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              :value="1"
              @change="setOption(['checkout', 'deposit'], 0)"
              :disabled="opt_settings.value['waivePay']"
            />
            <label class="form-check-label ml-4"
              >{{ $t("account-page-payment.payment_option_form_checkin") }}
              <p class="mt-2">
                {{
                  $t("account-page-payment.payment_option_form_checkin_note")
                }}
              </p></label
            >
          </div>
        </div>

        <div class="form-group row">
          <div class="form-check form-check-inline">
            <input
              v-model="payment_option.checkout"
              required
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              :value="1"
              @change="setOption(['checkin', 'deposit'], 0)"
              :disabled="opt_settings.value['waivePay']"
            />
            <label class="form-check-label ml-4"
              >{{ $t("account-page-payment.payment_option_form_checkout") }}
              <p class="mt-2">
                {{
                  $t("account-page-payment.payment_option_form_checkout_note")
                }}
              </p></label
            >
          </div>
        </div>

        <div class="form-group row">
          <div class="form-check form-check-inline">
            <input
              v-model="payment_option.deposit"
              required
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              :value="1"
              @change="setOption(['checkin', 'checkout'], 0)"
              :disabled="opt_settings.value['waivePay']"
            />
            <label class="form-check-label ml-4"
              >{{ $t("account-page-payment.payment_option_form_deposit") }}
              <p class="mt-2">
                {{
                  $t("account-page-payment.payment_option_form_deposit_note")
                }}
              </p></label
            >
          </div>
        </div>

        <hr />
        <div class="form-group row">
          <b>{{ $t("account-page-payment.payment_option_opt_title") }}</b>
        </div>

        <div :class="
            `form-group row ml-4 ` +
            (opt_settings.value['waivePay'] ? 'text-secondary' : '')
          ">
          <div class="form-check form-check-inline">
            <label class="form-check-label"
              >{{ $t("account-page-payment.payment_option_pah_note") }}
            </label>
          </div>
          <div class="row mt-2">
            <div class="col">
              {{ $t("account-page-payment.payment_option_pah_label") }}
            </div>
            <div class="col-2">
              <toggle-button
                :disabled="opt_settings.value['waivePay']"
                v-model="opt_settings.value['payHotelVisibility']"
                :labels="{ checked: 'On', unchecked: 'Off' }"
                :value="opt_settings.value['payHotelVisibility']"
                :sync="true"
              />
            </div>
          </div>
        </div>

        <div
          :class="
            `form-group row ml-4 ` +
            (payment_option['checkout'] == 1 || opt_settings.value['waivePay'] ? 'text-secondary' : '')
          "
        >
          <div class="form-check form-check-inline">
            <label class="form-check-label"
              >{{ $t("account-page-payment.payment_option_pay_later_note") }}
            </label>
          </div>
          <div class="row mt-2">
            <div class="col">
              {{ $t("account-page-payment.payment_option_pay_later_label") }}
            </div>
            <div class="col-2">
              <toggle-button
                :disabled="payment_option['checkout'] == 1 || opt_settings.value['waivePay']"
                v-model="opt_settings.value['payLaterVisibility']"
                :labels="{ checked: 'On', unchecked: 'Off' }"
                :value="opt_settings.value['payLaterVisibility']"
                :sync="true"
              />
            </div>
          </div>
        </div>

        <div class="form-group row ml-4">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              {{ $t("account-page-payment.payment_option_waive_note") }}
            </label>
          </div>
          <div class="row mt-2">
            <div class="col">
              {{ $t("account-page-payment.payment_option_waive_label") }}
            </div>
            <div class="col-2">
              <toggle-button
                @input="waiveOnChange($event)"
                v-model="opt_settings.value['waivePay']"
                :labels="{ checked: 'On', unchecked: 'Off' }"
                :value="opt_settings.value['waivePay']"
                :sync="true"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12" align="center">
            <router-link
              class="btn btn-danger ml-3"
              type="button"
              :to="{ name: 'checkin-facility-dashboard-home' }"
              >{{
                $t("account-page-payment.payment_option_back_btn")
              }}</router-link
            >
            <button
              @click="$bvModal.show('modal-confirmation')"
              type="button"
              class="btn btn-primary ml-3"
            >
              {{ $t("account-page-payment.payment_option_confirm_btn") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <confirmation-modal-component @click-handler="submit" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmationModalComponent from "../confirmation-modal.component.vue";
const name = "payment_option";
let origPayLater = null;
let origPayHotel = null;

export default {
  name: "PaymentOption",
  components: {
    ConfirmationModalComponent,
  },
  data() {
    return {
      success: false,
      submit_btn: null,
      confirm: false,
      payment_option: {
        checkin: 1,
        checkout: 0,
        deposit: 0,
      },
      opt_settings: {
        facility_id: 0,
        name: "payment_timing_opts",
        value: {
          payHotelVisibility: false,
          payLaterVisibility: false,
          waivePay: false,
        },
      },
    };
  },
  computed: {
    ...mapState("checkin-facility", ["facility"]),
  },
  async mounted() {
    await this.setPaymentOption();
    await this.setOptSetting();
  },
  methods: {
    async setPaymentOption() {
      let res = await this.$lionheart
        .get("/v2/facility-settings", {
          params: {
            facility_id: this.facility.id,
            name,
            paginate: 0
          }
        })
        .then((r) =>
          r.data.data
        )

      if (res.length == 0) return;
      res = res[0];

      this.payment_option = res.value;
    },
    async setOptSetting() {
      origPayLater = _.cloneDeep(this.opt_settings.value["payLaterVisibility"]); // Copy as non-reactive
      origPayHotel = _.cloneDeep(this.opt_settings.value["payHotelVisibility"]); // Copy as non-reactive
      this.opt_settings.facility_id = this.facility.id;

      let optSettings = await this.$lionheart
        .get("/v2/facility-settings", {
          params: {
            name: this.opt_settings.name,
            facility_id: this.opt_settings.facility_id,
            paginate: 0
          }
        })
        .then((r) => r.data.data);

      optSettings.length == 0 ? 
        this.opt_settings.value["payHotelVisibility"] = true :
        this.opt_settings.value["payHotelVisibility"] = false

      optSettings = optSettings[0];

      this.opt_settings.value = optSettings.value;
      // origPayLater = _.cloneDeep(this.opt_settings.value["payLaterVisibility"]); // Copy as non-reactive
      // origPayHotel = _.cloneDeep(this.opt_settings.value["payHotelVisibility"]); // Copy as non-reactive
    },
    async submit() {
      const data = {
        paymentOption: {
          facility_id: this.facility.id,
          name,
          value: this.payment_option,
        },
        optSetting: this.opt_settings,
      };

      const resPaymentOption = await this.$lionheart
        .post("/v2/facility-settings", { ...data.paymentOption })
        .then((r) => r.data);

      const resOptSetting = await this.$lionheart
        .post("/v2/facility-settings", { ...data.optSetting })
        .then((r) => r.data);

      this.success =
        resPaymentOption.success == true &&
        resOptSetting.success == true;

      // origPayLater =
      //   resOptSetting.error.has_error == false
      //     ? _.cloneDeep(this.opt_settings.value["payLaterVisibility"])
      //     : origPayLater;

      // origPayHotel =
      //   resOptSetting.error.has_error == false
      //     ? _.cloneDeep(this.opt_settings.value["payHotelVisibility"])
      //     : origPayHotel;
    },
    setOption(types, value) {
      for (let key in types) {
        this.payment_option[types[key]] = value;
      }

      this.opt_settings.value["payHotelVisibility"] = false;
      this.opt_settings.value["payLaterVisibility"] = false;

      // // When deposit; PAH should be off
      // this.opt_settings.value["payHotelVisibility"] =
      //   this.payment_option["deposit"] == 1
      //     ? false
      //     : this.opt_settings.value["payHotelVisibility"];

      // // When checkout; pay later should be off
      // this.opt_settings.value["payLaterVisibility"] =
      //   this.payment_option["checkout"] == 1
      //     ? false
      //     : this.opt_settings.value["payLaterVisibility"];

      // // When checkin; get reverted pay later setting
      // this.opt_settings.value["payLaterVisibility"] =
      //   this.payment_option["checkin"] == 1
      //     ? origPayLater
      //     : this.opt_settings.value["payLaterVisibility"];

      // // When not deposit; get reverted pay hotel setting
      // this.opt_settings.value["payHotelVisibility"] =
      //   this.payment_option["deposit"] == 0
      //     ? origPayHotel
      //     : this.opt_settings.value["payHotelVisibility"];
    },

    waiveOnChange(status) {
      if (status) {
        this.opt_settings.value['payHotelVisibility'] = false
        this.opt_settings.value['payLaterVisibility'] = false
      }
    },
  },
};
</script>
