import Vue from 'vue'
import Vuex from 'vuex'
import payment from './modules/payment'
import memorizedInputs from './modules/memorizedInputs'
import guest from './modules/guest'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        payment,
        memorizedInputs,
        guest
    }
})