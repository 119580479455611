<template>
   <div>
      <!-- STARTS Single room display -->
      <div v-if="!multiple">

         <!-- CONTROLS -->
         <div class="form-group row">
            <label class="col-md-2 col-form-label">
               {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            </label>
            <div class="col-md-10">
               <!-- initial camera button in page -->
               <camera-capture-button
                  :show="!confirm"
                  :mini="images.length > 0"
                  @click="$bvModal.show('allow_camera')"
               />
               
               <div :class="{ 'text-center' : (images.length <= 0 && !confirm) }">
                  <p class="mb-0" v-if="!confirm">
                     {{ $t("visit-information-form.capture_or_upload_file_text") }}
                  </p>
               </div>

               <!-- :mini = show large or small icon -->
               <ButtonBrowseFileUploadComponent
                  :showSelection="!confirm"
                  :mini="images.length > 0"
                  @clickToShowModal="showModalOfBrowseFileParentEvent"
               />

               <!-- preview of main guest picture when confirm -->
               <template v-if="typy(visitor_forms[c.id], 'dataUrl').isDefined && confirm">
                  <img class="img-thumbnail" :src="visitor_forms[c.id].dataUrl" />
               </template>
            </div>

            <!-- modal -->
            <allow-camera-component @ok="showCamera" />

            <!-- main camera component  -->
            <camera-component
               :shots="guestCount"
               :question_id="c.id"
               @image-captured="setVisitorImage($event)"
            />

            <!-- this component show the picture preview and tag the owner of image -->
            <visitor-image-component
               :images="images"
               :visitor_forms="visitor_forms"
               :companions="companions"
               :questions="questions"
               :allowDynamicAdding="allowAddCompanion"
               :allowRemoveCompanion="allowRemoveCompanion"
               @save-images="saveImages($event)"
               @retake-image="retakeImage($event)"
            />

            <BrowseFileModalComponent
               :guestCount="guestCount"
               :question_id="c.id"
               @triggerImageUpload="setVisitorImageFilesParentEvent($event)"
            />

            <PreviewImageFileModalComponent 
               :imageFiles="images"
               :visitor_forms="visitor_forms"
               :companions="companions"
               :questions="questions"
               :allowDynamicAdding="allowAddCompanion"
               :allowRemoveCompanion="allowRemoveCompanion"
               @saveImagesFiles="saveImagesFilesParentEvent($event)"
               @reUploadImageFiles="reUploadImageFilesParentEvent($event)"
            />
         </div>

         <!-- PREVIEW -->
         <!-- Main guest preview of picture in page -->
         <div
            class="form-group row"
            v-if="saved && !confirm && typy(visitor_forms[c.id], 'dataUrl').isDefined"
         >
            <label class="col-md-2 col-form-label">
               {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
               <div>({{ getName() }})</div>
            </label>

            <!-- preview when capture image choose -->
            <div class="col-md-10" v-if="viaCamera">
               <camera-capture-box
                  :capturing="isCameraSeen[0]"
                  :video-id="'main'"
                  :canvas-id="'main-canvas'"
                  :src="visitor_forms[c.id].dataUrl"
                  @re-take="() => { showCameraRetake('main', 0) }"
                  @capture="() => { captureImageRetake('main', 0, c.id) }"
                  @cancel="() => { hideCameraRetake('main', 0) }"
                  @toggle-camera="() => { toggleCamera('main', 0, c.id) }"
                  :disabledCapturing.sync="disabledCapturing[0]"
                  :index="0"
               />
            </div>

            <!-- preview when browse file choose -->
            <div class="col-md-10" v-else>
               <ImagePreviewBoxComponent
                  :uploading="displayUploadFile[0]"
                  :src="visitor_forms[c.id].dataUrl"
                  :disabledUploadBox="disabledUploadBox[0]"
                  @reUploadImage="() => { showUploadFileBoxInGovIDComponent(0) }"
                  @uploadImage="reUploadSingleImageInGovIDComponent(0, $event, c.id)"
                  :index="0"
               />
            </div>
         </div>

         <!-- name input -->
         <!-- Companion preview on page -->
         <template v-for="(companion, index) in companions">
            <div
               class="form-group row"
               v-if="saved && !confirm && typy(companion.other_forms[c.id], 'dataUrl').isDefined"
               :key="`vc_${index}`"
            >
               <label class="col-md-2 col-form-label">
                  {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
                  <div>
                     ({{
                        showLabelOrPlaceholder(
                        $t("visit-information-form.companion"),
                        index,
                        "",
                        "dropdown"
                        )
                     }})
                  </div>
               </label>

               <!-- preview when COMPANION capture image choose -->
               <div class="col-md-10" v-if="viaCamera">
                  <camera-capture-box
                     :capturing="isCameraSeen[index + 1]"
                     :video-id="`companion-${index + 1}`"
                     :canvas-id="`companion-${index + 1}-canvas`"
                     :src="companion.other_forms[c.id].dataUrl"
                     @re-take="() => { showCameraRetake('companion', index + 1) }"
                     @capture="() => { captureImageRetake('companion', index + 1, c.id) }"
                     @cancel="() => { hideCameraRetake('companion', index + 1) }"
                     @toggle-camera="() => { toggleCamera('companion', index + 1, c.id) }"
                     :disabledCapturing.sync="disabledCapturing[index + 1]"
                     @removeCompanion="handleRemoveCompanionInGovIDComponent(index + 1)"
                     :index="index + 1"
                     allowRemoveCompanion
                  />
               </div>
               <!-- preview when COMPANION browse file choose -->
               <div class="col-md-10" v-else>
                  <ImagePreviewBoxComponent
                     :uploading="displayUploadFile[index + 1]"
                     :src="companion.other_forms[c.id].dataUrl"
                     :disabledUploadBox="disabledUploadBox[index + 1]"
                     @reUploadImage="() => { showUploadFileBoxInGovIDComponent(index + 1) }"
                     @uploadImage="reUploadSingleImageInGovIDComponent(index + 1, $event, c.id)"
                     @removeCompanion="handleRemoveCompanionInGovIDComponent(index + 1)"
                     :index="index + 1"
                  />
               </div>
            </div>
         </template>
      </div>

      <!-- STARTS Multiple rooms display -->
      <div v-else>
         <div class="form-group row">
            
            <label class="col-sm-2 col-form-label">
               {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            </label>

            <div class="col-sm-10">
               <camera-capture-button
                  :show="!confirm"
                  :mini="typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined"
                  @click="$bvModal.show('allow_camera-' + bd_index)"
               />
               <div :class="{ 'text-center' : (typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isUndefined && !confirm) }">
                  <p class="mb-0" v-if="!confirm">
                     {{ $t("visit-information-form.capture_or_upload_file_text") }}
                  </p>
               </div>
               <ButtonBrowseFileUploadComponent
                  :showSelection="!confirm"
                  :mini="typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined"
                  @clickToShowModal="showModalOfBrowseFileParentEvent(bd_index)"
               />

               <!-- preview of main guest picture when confirm -->
               <template v-if="typy(visitor_forms[c.id], 'dataUrl').isDefined && confirm">
                  <img class="img-thumbnail" :src="visitor_forms[c.id].dataUrl" />
               </template>
            </div>
         </div>

         <allow-camera-component :id="'allow_camera-' + bd_index" @ok="showCamera" />

         <camera-component
            :id="'camera-' + bd_index"
            :shots="multiple_data[bd_index].guestCount"
            :question_id="c.id"
            @image-captured="setVisitorImage($event)"
         />

         <BrowseFileModalComponent
            :id="'browse-file-modal-' + bd_index"
            :guestCount="multiple_data[bd_index].guestCount"
            :question_id="c.id"
            @imageUpload="setVisitorImageFilesParentEvent($event)"
         />

         <visitor-image-component
            :id="'visitor-image-' + bd_index"
            :images="mult_images"
            :visitor_forms="multiple_data[bd_index].visitor_forms"
            :companions="multiple_data[bd_index].companions"
            :questions="questions"
            :allowDynamicAdding="allowAddCompanion"
            :allowRemoveCompanion="allowRemoveCompanion"
            @save-images="saveImages($event)"
            @retake-image="retakeImage($event)"
         />
         <PreviewImageFileModalComponent
            :id="'preview-image-files-' + bd_index"
            :imageFiles="mult_images"
            :visitor_forms="multiple_data[bd_index].visitor_forms"
            :companions="multiple_data[bd_index].companions"
            :questions="questions"
            :allowDynamicAdding="allowAddCompanion"
            :allowRemoveCompanion="allowRemoveCompanion"
            @saveImagesFiles="saveImagesFilesParentEvent($event)"
            @reUploadImageFiles="reUploadImageFilesParentEvent($event)"
         />

         <!-- Main guest preview of picture in page -->
         <div
            class="form-group row"
            v-if="saved &&
            !confirm &&
            typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined"
         >
            <label class="col-md-2 col-form-label">
               {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
               <div>({{ getName() }})</div>
            </label>

            <!-- preview when capture image choose -->
            <div class="col-md-10" v-if="viaCamera">
               <camera-capture-box
                  :capturing="isCameraSeen[0]"
                  :video-id="'main-' + bd_index"
                  :canvas-id="'main-canvas-' + bd_index"
                  :src="multiple_data[bd_index].visitor_forms[c.id].dataUrl"
                  @re-take="() => { showCameraRetake('main-' + bd_index, 0) }"
                  @capture="() => { captureImageRetake('main-' + bd_index, 0, c.id) }"
                  @cancel="() => { hideCameraRetake('main-' + bd_index, 0) }"
                  :disabledCapturing.sync="disabledCapturing[0]"
                  :index="0"
                  :allowDynamicAdding="allowAddCompanion"
                  :allowRemoveCompanion="allowRemoveCompanion"
               />
            </div>
            <!-- preview when browse file choose -->
            <div class="col-md-10" v-else>
               <ImagePreviewBoxComponent
                  :uploading="displayUploadFile[0]"
                  :src="multiple_data[bd_index].visitor_forms[c.id].dataUrl"
                  :disabledUploadBox="disabledUploadBox[0]"
                  @reUploadImage="() => { showUploadFileBoxInGovIDComponent(0) }"
                  @uploadImage="reUploadSingleImageInGovIDComponent(0, $event, c.id)"
                  :index="0"
               />
            </div>
         </div>

         <!-- Companion preview on page -->
         <template v-for="(companion, index) in multiple_data[bd_index].companions">
            <div
               class="form-group row"
               v-if="
               saved && 
               !confirm &&
               typy(companion.other_forms[c.id], 'dataUrl').isDefined
               "
               :key="`vc_${index}`"
            >
               <label class="col-md-2 col-form-label">
                  {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
                  <div>
                     ({{
                        showLabelOrPlaceholder(
                        $t("visit-information-form.companion"), index, "", "dropdown", multiple, bd_index
                        )
                     }})
                  </div>
               </label>

               <!-- preview when capture image choose -->
               <div class="col-md-10" v-if="viaCamera">
                  <camera-capture-box
                     :capturing="isCameraSeen[index + 1]"
                     :video-id="`companion-${bd_index}-${index + 1}`"
                     :canvas-id="`companion-${bd_index}-${index + 1}-canvas`"
                     :src="companion.other_forms[c.id].dataUrl"
                     @re-take="() => { showCameraRetake('companion-' + bd_index, index + 1) }"
                     @capture="() => { captureImageRetake('companion-' + bd_index, index + 1, c.id) }"
                     @cancel="() => { hideCameraRetake('companion-' + bd_index, index + 1) }"
                     :disabledCapturing.sync="disabledCapturing[index + 1]"
                     @removeCompanion="handleRemoveCompanionInGovIDComponent(index + 1)"
                     :index="index + 1"
                     :allowDynamicAdding="allowAddCompanion"
                     :allowRemoveCompanion="allowRemoveCompanion"
                  />
               </div>

               <!-- preview when browse file choose -->
               <div class="col-md-10" v-else>
                  <ImagePreviewBoxComponent
                     :uploading="displayUploadFile[index + 1]"
                     :src="companion.other_forms[c.id].dataUrl"
                     :disabledUploadBox="disabledUploadBox[index + 1]"
                     @reUploadImage="() => { showUploadFileBoxInGovIDComponent(index + 1) }"
                     @uploadImage="reUploadSingleImageInGovIDComponent(index + 1, $event, c.id)"
                     @removeCompanion="handleRemoveCompanionInGovIDComponent(index + 1)"
                     :index="index + 1"
                  />
               </div>
            </div>
         </template>
      </div>
   </div>
</template>

<script>
import { eventBus } from "@/main";
import t from "typy"
import CameraComponent from "./CameraComponent"
import CameraCaptureButton from "@/modules/checkin-facility/pages/checkin/hotel/form-components/CameraCaptureButton"
import ButtonBrowseFileUploadComponent from "@/modules/checkin-facility/pages/checkin/hotel/form-components/ButtonBrowseFileUploadComponent"
import BrowseFileModalComponent from "@/modules/checkin-facility/pages/checkin/hotel/form-components/BrowseFileModalComponent"
import PreviewImageFileModalComponent from "@/modules/checkin-facility/pages/checkin/hotel/form-components/PreviewImageFileModalComponent"
import CameraCaptureBox from "../../components/CameraCaptureBox"
import VisitorImageComponent from "./VisitorImageComponent"
import AllowCameraComponent from "./AllowCameraComponent"
import ImagePreviewBoxComponent from "@/modules/checkin-facility/pages/checkin/components/ImagePreviewBoxComponent"
import {
  main_form_methods,
  startCameraStream,
  stopCameraStream,
  captureImage,
  convertImageToBase64
} from "@/modules/checkin-facility/utils/mockup"
import { mapState } from "vuex";
const { showLabelOrPlaceholder } = main_form_methods

export default {
   props: {
      c: null,
      confirm: false,
      visitor_forms: {
         type: Object,
         default: {}
      },
      companions: {
         type: Array,
         default: []
      },
      questions: {
         type: Array,
         default: []
      },
      guestCount: {
         type: Number,
         default: 0
      },
      images: {
      required: false,
         type: Array
      },
      // Multiple room
      multiple: {
         type: Boolean
      },
      multiple_data: {
         type: Array
      },
      bd_index: null
      // End of multiple room
   },
   components: {
      CameraComponent,
      CameraCaptureButton,
      CameraCaptureBox,
      VisitorImageComponent,
      AllowCameraComponent,
      // additionals
      ButtonBrowseFileUploadComponent,
      BrowseFileModalComponent,
      PreviewImageFileModalComponent,
      ImagePreviewBoxComponent,


   },
   data() {
      return {
         typy: t,
         saved: false,
         isCameraSeen: [],
         displayUploadFile: [],
         mult_images: [], // for multiple rooms,
         viaCamera: true,
         allowAddCompanion: true,
         allowRemoveCompanion: true,
         disabledUploadBox: [],
         disabledCapturing: [],
         currentFacingMode: 'environment', // default to rear camera,
      }
   },
   computed: {
      ...mapState("guest", [
         "numberOfGuest",
         "guestCountSetting"
    ])
   },
   mounted() {
      this.init()
   },
   methods: {
      showLabelOrPlaceholder,
      // for multiple room
      async init(num) {
         this.setAllowAddCompanion();
         this.disabledCapturing = []
         const isCameraSeen = []
         const displayUploadFile = []
         const disabledUploadBox = []
         const disabledCapturing = []

         let length = num != undefined ? length : this.companions.length
         
         isCameraSeen.push(false)
         displayUploadFile.push(false)
         disabledUploadBox.push(false)
         disabledCapturing.push(false)

         for (let i = 0; i < length; i++) {
            isCameraSeen.push(false)
            displayUploadFile.push(false)
            disabledUploadBox.push(false)
            disabledCapturing.push(false) // on add disabled the capture
         }

         this.isCameraSeen = isCameraSeen
         this.displayUploadFile = displayUploadFile
         this.disabledUploadBox = disabledUploadBox
         this.disabledCapturing = disabledCapturing
      },

      // allow-camera-component
      showCamera() {
         this.saved = false // To hide captured image/s
         this.viaCamera = true;
         if (!this.multiple) {
            this.$bvModal.show("camera")
         } else {
            this.$bvModal.show(`camera-${this.bd_index}`)
         }
      },

      // check if fix guest status is enabled
      async setAllowAddCompanion(){
         const fixGuestCountEnabled = await this.guestCountSetting?.fix_guest_count?.status ?? false;
         this.allowAddCompanion = !fixGuestCountEnabled;
      },

      // camera-component
      setVisitorImage(images) {

         if (!this.multiple) {
            this.$emit("image-captured", images)
            this.$bvModal.show("visitor-image")
         } else {
            this.mult_images = images
            this.$bvModal.show(`visitor-image-${this.bd_index}`)
         }
      },

      // visitor-image-component
      saveImages(event) {
         this.saved = true // To show captured image/s
         this.viaCamera = true;
         let moveToTopActivated = true;

         if (!this.multiple) {
            this.$emit("save-images", event, moveToTopActivated)
         } else {
            this.init(this.multiple_data[this.bd_index].companions.length)
         }
      },
      retakeImage(event) {
         this.viaCamera = true;
         if (!this.multiple) {
            this.$emit("retake-image", event)
         } else {
            this.mult_images[event.index].dataUrl = event.dataUrl
         }
      },

      getName() {
         let name = this.questions.find(x => x.system_name == "first_name")
         if (name != undefined) {
         if (!this.multiple) {
            if (t(this.visitor_forms[name.id]).isDefined) {
            if (t(this.visitor_forms[name.id]).isEmptyString == false) {
               return `${_.truncate(this.visitor_forms[name.id], {
                  length: 10
               })}`
            }
            }
         } else {
            if (
            t(this.multiple_data[this.bd_index].visitor_forms[name.id])
               .isDefined
            ) {
            if (
               t(this.multiple_data[this.bd_index].visitor_forms[name.id])
                  .isEmptyString == false
            ) {
               return `${_.truncate(
                  this.multiple_data[this.bd_index].visitor_forms[name.id],
                  {
                  length: 10
                  }
               )}`
            }
            }
         }
         } else {
         name = this.questions.find(x => x.system_name == "full_name")
         if (!this.multiple) {
            if (t(this.visitor_forms[name.id]).isDefined) {
            if (t(this.visitor_forms[name.id]).isEmptyString == false) {
               return `${_.truncate(this.visitor_forms[name.id], {
                  length: 10
               })}`
            }
            }
         } else {
            if (
            t(this.multiple_data[this.bd_index].visitor_forms[name.id])
               .isDefined
            ) {
            if (
               t(this.multiple_data[this.bd_index].visitor_forms[name.id])
                  .isEmptyString == false
            ) {
               return `${_.truncate(
                  this.multiple_data[this.bd_index].visitor_forms[name.id],
                  {
                  length: 10
                  }
               )}`
            }
            }
         }
         }

         return "n/a"
      },

      // camera-capture-box
      showCameraRetake(guest, index) {
         this.$set(this.isCameraSeen, index, true)
         this.startCameraStream(guest, index)
         this.$set(this.disabledCapturing, index, false) //set the upload box to disabled per index
      },
      hideCameraRetake(guest, index) {
         this.$set(this.isCameraSeen, index, false)
         this.stopCameraStream(guest, index)
      },
      captureImageRetake(guest, index, question_id) {
         let canvasId = ""
         let cameraId = ""
         if (guest == "main" && index === 0) {
            cameraId = "main"
            canvasId = "main-canvas"
         } else if (guest == `main-${this.bd_index}` && index === 0) {
            cameraId = `main-${this.bd_index}`
            canvasId = `main-canvas-${this.bd_index}`
         } else if (guest == `companion-${this.bd_index}-${index}`) {
            cameraId = `companion-${this.bd_index}-${index}`
            canvasId = `companion-${this.bd_index}-${index}-canvas`
         } else {
            cameraId = `${guest}-${index}`
            canvasId = `${guest}-${index}-canvas`
         }

         let image = {
            dataUrl: captureImage(canvasId, cameraId),
            index,
            question_id
         }

         if (!this.multiple) {
            this.$emit("retake-gov-id", image)
         } else {
            if (index === 0) {
               this.multiple_data[this.bd_index].visitor_forms[question_id] = {
               dataUrl: image.dataUrl
               }
            } else {
               this.multiple_data[this.bd_index].companions[index - 1].other_forms[
               question_id
               ] = {
               dataUrl: image.dataUrl
               }
            }
         }
         this.hideCameraRetake(guest, index)
      },

      // showCameraRetake method
      async startCameraStream(guest, index, facingMode = this.currentFacingMode) {
         let camera = ""
         if (guest == "main" && index === 0) {
            camera = "main"
         } else if (guest == `main-${this.bd_index}` && index === 0) {
            camera = `main-${this.bd_index}`
         } else if (guest == `companion-${this.bd_index}-${index}`) {
            camera = `companion-${this.bd_index}-${index}`
         } else {
            camera = `${guest}-${index}`
         }

         const constraints = {
            video: {
               audio: false,
               facingMode: facingMode // uses the specified camera
            }
         }

         startCameraStream(camera, constraints)
      },
      // hideCameraRetake method
      stopCameraStream(guest, index) {
         let camera = ""
         if (guest == "main" && index === 0) {
            camera = "main"
         } else if (guest == `main-${this.bd_index}` && index === 0) {
            camera = `main-${this.bd_index}`
         } else if (guest == `companion-${this.bd_index}-${index}`) {
            camera = `companion-${this.bd_index}-${index}`
         } else {
            camera = `${guest}-${index}`
         }
         stopCameraStream(camera)
      },
      // switch to front and back camera method
      toggleCamera(guest, index) {
         this.currentFacingMode = this.currentFacingMode === 'environment' ? 'user' : 'environment'
         this.stopCameraStream(guest, index)
         this.startCameraStream(guest, index)
      },

      // NEW implementations
      // PreviewImageFileModalComponent
      saveImagesFilesParentEvent(event) {
         this.saved = true // To show captured image/s
         this.viaCamera = false;
         let moveToTopActivated = true;

         if (!this.multiple) {
            this.$emit("save-images", event, moveToTopActivated)
         } else {
            this.init(this.multiple_data[this.bd_index].companions.length)
         }
      },
      reUploadImageFilesParentEvent(images) { //param is object
         if (!this.multiple) {
            this.$emit("retake-image", images) // emit to main form
         } else {
            this.mult_images[images.index].dataUrl = images.dataUrl
         }
      },

      // ButtonBrowseFileUploadComponent
      showModalOfBrowseFileParentEvent(bookingDataIndex) {
         this.saved = false; //hide the captured image in page
         if (!this.multiple) {
            this.$bvModal.show("browse-file-modal")
         } else {
         console.log(`browse-file-modal-${bookingDataIndex}`);
            this.$bvModal.show(`browse-file-modal-${bookingDataIndex}`);
         }
      },

      // BrowseFileModalComponent
      setVisitorImageFilesParentEvent(imageFiles) {
         if (!this.multiple) {
            this.$emit("image-captured", imageFiles)
            this.$bvModal.show("preview-image-files")
         } else {
            this.mult_images = imageFiles;
            console.log('multi image', this.mult_images);
            this.$bvModal.show(`preview-image-files-${this.bd_index}`)
         }
      },

      // FUNCTIONS FOR PREVIEW IN MAIN PAGE
      // ImagePreviewBoxComponent
      async reUploadSingleImageInGovIDComponent(index, event, question_id) {
         let imageProcessed = await convertImageToBase64(event[0]);

         let imageObject = {
            dataUrl: imageProcessed,
            index,
            question_id
         }

         if (!this.multiple) {
            this.$emit("retake-gov-id", imageObject)
         } else {
            if (index === 0) {
               this.multiple_data[this.bd_index].visitor_forms[question_id] = {
               dataUrl: imageObject.dataUrl
               }
            } else {
               this.multiple_data[this.bd_index].companions[index - 1].other_forms[
               question_id
               ] = {
               dataUrl: imageObject.dataUrl
               }
            }
         }

         this.$set(this.displayUploadFile, index, false); //hide uploadFileBox after uploading images
      },

      showUploadFileBoxInGovIDComponent(index) {
         this.$set(this.disabledUploadBox, index, false) //show the upload file box again
         this.$set(this.displayUploadFile, index, true) //show the upload file box again
      },

      handleRemoveCompanionInGovIDComponent(companionIndex) {
         let newCompanionIndex = companionIndex - 1; //need to start in 0 because it has main guest involved
         this.$emit('removeCompanionInMainForm',newCompanionIndex)
         // eventBus.$emit('eventBusRemoveCompanion',newCompanionIndex);
      }
   }
}
</script>