import { eventBus } from "@/main";
import { nationalities } from "@/modules/checkin-facility/utils/mockup";
import _ from "lodash";
import moment from "moment-timezone";
import t from "typy";
import { getPaymentStatus } from "../../../utils/helpers";
import { booking_status, main_form_methods, payment_status } from "../../../utils/mockup";
import HelpComponent from "./../../../components/help.component";
import PmsBadge from "./../components/PmsBadge";
import BasicDetailsModal from "./../components/detail/BasicDetailsModal";
import CustomDetailsModal from "./../components/detail/CustomDetailsModal";
import TimezoneNotice from "./../components/timezone-notice";
import VisitorArchiveRestore from "./../components/visitor-archive-restore";
import { maskedDummyEmails } from "../../../utils/helpers";

const default_time = {
  from_time: "00:00",
  to_time: "23:59",
};
// const {
//   formatDateHMA
// } = main_form_methods;

import formatters from '@/mixins/formatters.js';

export default {
  components: {
    BasicDetailsModal,
    CustomDetailsModal,
    HelpComponent,
    PmsBadge,
    TimezoneNotice,
    VisitorArchiveRestore
  },
  data() {
    return {
      config: { show_search_tool: false,      title: "title" },
      error_msg: "",

      visitor_paginate: {
        paginate: true,
        per_page: 50,
        page: 1,
      },
      visitor_logs: {
        data: [],
        total: 0,
      },
      filter: {
        facility_id: window._app_facility.id,
        ...default_time,
        from_date: '',
        to_date: '',
        pms_reservation_no: '',
        not_paid: false,
        paid: false,
        pay_at_reception: false,
        paid_authorized: false,
        paid_captured: false,
        refund: false,
        pre_check_in: false,
        checked_in: false,
        checked_out: false,
        credit_card_as_deposit: false,
        paid_no_charge_on_deposit: false,
        dateDropdownLabel: '',
        dateDropdownMode: '',
        conditionsFilterVariant: 'light',
        dateFilterVariant: 'btn-light',
        trash_only: false
      },
      advanceSearch: false,
      selecting: {
        selected: [],
        mode: null,
        showSelecting: false,
      },
      deleting: {
        confirming: true,
      },
      archive_restore:{
        confirming: false
      },
      columnView: {
        displaySpecs: [],
        fieldSettings: {},
        hiddenFields: [],
      },
      columnSelector: {
        groupedFields: [],
        fieldSettings: {},
        fieldSettingSnapshot: {},
        hiddenFields: ['checkin_date', 'checkout_date'],
      },
      facility: {},
      detailModal: {
        show: false,
        itemIndex: 0,
      },
      loadDetailComponent: false
    };
  },
  async mounted() {
    this.facility = window._app_facility;
    this.initHiddenColumns();
    this.applyColumnView();
    // Commenting this to remove applying today's date
    // this.setDate(0, '', true); //mounting not to trigger search
    this.paginate(1);
    //Listener
    eventBus.$on("changeLang", (data) => {
      this.applyColumnView();
      this.setDropdownLabel();
    });
    this.loadDetailComponent = true;
  },
  computed: {
    isDeletion: function () {
      return this.selecting.mode == "deleting" && this.selecting.showSelecting;
    },
    pms_reservation_no() {
      return this.filter.pms_reservation_no;
    },
    name() {
      return this.filter.name;
    },
    phone() {
      return this.filter.phone;
    },
    conditionsFilterNum() {
      let arr = [
        this.filter.not_paid,
        this.filter.paid,
        this.filter.pay_at_reception,
        this.filter.paid_authorized,
        this.filter.paid_captured,
        this.filter.refund,
        this.filter.pre_check_in,
        this.filter.checked_in,
        this.filter.checked_out,
        this.filter.credit_card_as_deposit,
        this.filter.paid_no_charge_on_deposit,
      ];
      return arr.filter(item => item == true).length;
    },
    dateDropdownMode() {
      return this.filter.dateDropdownMode;
    },
    disableOK() {
      if (this.filter.from_date == '' || this.filter.to_date == '') {
        return true;
      }
      return false;
    },
    enableClearFilter() {
      return this.filter.conditionsFilterVariant == 'warning' || this.filter.dateFilterVariant == 'btn-warning';
    },
    currentLogIdDetail() {
      const index = this.detailModal.itemIndex;
      if (!t(this.visitor_logs.data, String(index)).isDefined) return 0;
      else return this.visitor_logs.data[index].id;
    }
  },
  methods: {
    emailMasked(email) {
      return maskedDummyEmails(email);
    },
    async applyColumnView() {
      const facility = window._app_facility;
      this.columnSelector.groupedFields = [];
      this.columnView.fieldSettings = {};
      const columns = 2;

      const template = facility.has_pms ? 'guest_list' : 'guest_list_basic';
      // Fetch & set data template references.
      const templateRequest = await this.$lionheart
        .get(`facility-setting/data-template/${facility.slug_name}/${template}`, {params: {parse: true}});
      this.columnView.displaySpecs = templateRequest.data.value.specs;

      // Set keyed saved field settings
      const savedSettings = {}
      const settingsRequest = await this.$lionheart
        .post("/facility-setting/list", {
          facility_id: facility.id,
          name: "visitor_columns",
          paginate: false,
        })
        .then((r) => this.catcher(r.data, "data", (r) => r));
      if (settingsRequest.data.length == 0) {
        for (let field of this.columnView.displaySpecs.list) {
          savedSettings[field.system_name] = { label: field.key, system_name: field.system_name, column_view: true };
        }
      } else {
        const settings = settingsRequest.data[0].value;
        try {
          for (let field of settings) {
            savedSettings[field.system_name] = {
              label: field.key, system_name: field.system_name, column_view: field.column_view
            };
          }
        } catch (e) {
          for (let field of this.columnView.displaySpecs.list) {
            savedSettings[field.system_name] = { label: field.key, system_name: field.system_name, column_view: true };
          }
        }
      }

      // Finalize keyed settings.
      const fieldSettings = {};
      this.columnView.displaySpecs.list.forEach((obj) => {
        const setting = { label: obj.key, system_name: obj.system_name }
        if (Object.keys(savedSettings).length == 0) {
          // Existing user that don't have settings yet: enable display by default
          setting.column_view = true;
        } else {
          const columnSetting = savedSettings[obj.system_name];
          setting.column_view = columnSetting ? columnSetting.column_view : true;
        }
        fieldSettings[obj.system_name] = setting;
      });

      // Sub divide fields into specified number of column for selector display.
      const fieldNames = Object.keys(fieldSettings).filter(
        v => !this.columnSelector.hiddenFields.includes(v)
      );
      const chunkSize = Math.ceil(fieldNames.length / columns);
      const groupedFields = _.chunk(fieldNames, chunkSize);

      // Set final values for column display references.
      this.columnView.fieldSettings = fieldSettings;
      this.columnSelector.groupedFields = groupedFields;
      this.columnSelector.fieldSettings = fieldSettings;
      this.columnSelector.fieldSettingSnapshot = _.cloneDeep(fieldSettings);
    },
    extractValue(field, obj) {
      try {
        let objMap = field.value.split('.').reduce((a, i) => a[i], obj);
        if (Number.isInteger(objMap) && !objMap) return false;
        if (!objMap) return '--';
        // if (field.system_name == 'payment_status') return getPaymentStatus(this.$t(`payment_statuses.${objMap}`), obj.booking_details.pms_reservation_no);
        if (field.system_name == 'payment_status') return this.$t(`payment_statuses.${objMap}`);
        
        if (field.system_name == 'booking_status') return this.$t(`booking_statuses.${objMap}`);
        if (['checkin_date', 'checkout_date', 'pre_checkin_date'].includes(field.system_name)) {

        // if(field.system_name == "checkin_date" && (obj.booking_details && obj.booking_details.oldest_created_at)){
        //   objMap =   obj.booking_details.checkin_date + " " +obj.booking_details.checkin_time;
        // }
          return objMap ? this.formatDateTime(objMap) : '--';
        }
        if (field.system_name == 'nationality') return this.getNationality(objMap);
        // if (field.system_name == 'email') {
        //   if (objMap.includes('@dummys.com')) return false;
        // }
        // if(field.system_name == 'pre_checkin_date'){
        //   if(obj.booking_details && (obj.booking_details.pre_check_in_created_at == null)){
        //     return '--'
        //   }
        //   return this.formatDateTime(obj.created_at)
        // }

        if(field.system_name === 'email') {
          return maskedDummyEmails(objMap)
        }

        return objMap;
      } catch (e) { return false; }
    },
    updateColumns($fq, target) {
      $fq.column_view = target.value;
    },
    isColumnActive(sysName) {
      // const fieldSetting = this.columnView.fieldSettings[sysName];
      const fieldSetting = this.columnSelector.fieldSettings[sysName];
      return fieldSetting ? fieldSetting.column_view : true;
    },
    isNotColumnHidden(sysName) {
      return !this.columnView.hiddenFields.includes(sysName);
    },
    isCheckinDateField (sysName) {
      return sysName === 'checkin_date'
    },
    isCheckoutDateField (sysName) {
      return sysName === 'checkout_date'
    },
    isPmsCheckin (booking_details) {
      return booking_details !== null &&
        booking_details.hasOwnProperty('condition_metadata') && 
        booking_details.condition_metadata !== null && 
        booking_details.condition_metadata.is_pms_checkin === 1
    },
    isPmsCheckout (booking_details) {
      return booking_details !== null &&
        booking_details.hasOwnProperty('condition_metadata') && 
        booking_details.condition_metadata !== null && 
        booking_details.condition_metadata.is_pms_checkout === 1
    },
    showColumnSelector() {
      this.$refs['modal-column-change-confirmation'].show();
    },
    hideColumnSelector() {
      const snapshotSettings = this.columnSelector.fieldSettingSnapshot;
      for(const settingName of Object.keys(snapshotSettings)) {
        const show = snapshotSettings[settingName].column_view;
        this.columnSelector.fieldSettings[settingName].column_view = show;
      }
      this.$refs['modal-column-change-confirmation'].hide();
    },
    toggleDelete() {
      this.selecting.mode = "deleting";
      this.selecting.showSelecting = !this.selecting.showSelecting;
      this.selecting.selected = [];
      //
      this.deleting.confirming = true;
      this.$refs["modal-delete-confirmation"].hide();
    },
    toggleArchiveRestore(mode){
      this.selecting.mode = mode;
      this.selecting.selected = [];

      if(['archive_list','checkin_list'].includes(mode)){
        this.visitor_paginate.page = 1;
        this.filter.trash_only = mode == "archive_list" ? true : false;
        this.list();
      }

      if(['restore','archive'].includes(mode)){
        this.selecting.showSelecting = !this.selecting.showSelecting;
        this.archive_restore.confirming = !this.archive_restore.confirming;
      }

    },
    toggleSearchTool() {
      var cond = true;
      if (this.config.show_search_tool) {
        cond = false;
      }
      this.config.show_search_tool = cond;
    },
    toggleAdvanceSearch() {
      this.advanceSearch = !this.advanceSearch;
    },
    async saveColumnSettings() {
      let data = {
        value: Object.values(this.columnSelector.fieldSettings),
        facility_id: window._app_facility.id,
        name: "visitor_columns",
      };

      // Save
      await this.$lionheart
        .post("facility-setting/store", { ...data, })
        .then((r) => this.catcher(r.data, "data", (r) => r));

      this.applyColumnView(); //Refresh settings
      this.$refs["modal-column-change-confirmation"].hide();
    },
    async deleteCheckin() {
      const facility = window._app_facility;
      const ids = this.selecting.selected.map((item) => item.id);

      const res = await this.$lionheart
        .post("checkin/remove", {
          facility_id: facility.id,
          ids,
        })
        .then((r) => this.catcher(r.data, "data", (r) => r))
        .catch((e) => this.catcher(e));

      if (res.error.has_error) return this.errorDisplay(res);

      this.deleting.confirming = false;
      this.visitor_paginate.page = 1;
      this.list();
    },
    async setDate(numberOfDays = 0, mode, mounted = false) {
      this.filter.dateDropdownMode = mode;
      let to_date = moment();
      let from_date = moment();

      if ([0, 1].includes(numberOfDays)) {
        to_date.subtract(numberOfDays, 'days');
        from_date.subtract(numberOfDays, 'days');
      } else if (numberOfDays > 1) {
        from_date.subtract(numberOfDays, 'days');
      } else {
        return;
      }

      this.filter.from_date = from_date.format('YYYY-MM-DD');
      this.filter.to_date = to_date.format('YYYY-MM-DD');
      this.filter.from_time = "00:00";
      this.filter.to_time = "23:59";

      await this.setDropdownLabel();
      await this.saveFilter();

      if (mounted) return; // From mounted

      await this.search();
    },
    async clearFilter(mode = 'all_data') {
      this.$storage.remove("visitor_filter_" + window._app_facility.id);
      this.visitor_paginate.per_page = 50;
      this.filter = {
        trash_only: this.filter.trash_only,
        facility_id: window._app_facility.id,
        ...default_time,
        from_date: '',
        to_date: '',
        pms_reservation_no: '',
        not_paid: false,
        paid: false,
        pay_at_reception: false,
        paid_authorized: false,
        paid_captured: false,
        refund: false,
        pre_check_in: false,
        checked_in: false,
        checked_out: false,
        credit_card_as_deposit: false,
        paid_no_charge_on_deposit: false,
        dateDropdownLabel: '',
        dateDropdownMode: mode,
        conditionsFilterVariant: 'light',
        dateFilterVariant: 'btn-light',
      };
      await this.setDropdownLabel();
      await this.saveFilter();
      await this.paginate(1);
    },

    async saveFilter() {
      delete this.filter.trash_only;
      this.$storage.set(
        "visitor_filter_" + window._app_facility.id,
        this.filter
      );
      // this.paginate(1);
    },
    getFilter(id) {
      var filter = this.$storage.get("visitor_filter_" + id);
      var response = {};
      if (filter) {
        response = filter;
      }


      return _.isEmpty(response) ? this.filter : response;
    },

    async list() {
      const visitors = await this.$lionheart
        .post("/log/get/facility-visitor", {
          ...this.visitor_paginate,
          ...this.filter,
          timezone: moment.tz.guess()
        })
        .then((r) =>
          this.catcher(r.data, "data", (r) =>
            this.visitor_paginate.paginate ? r.data : r
          )
        )
        .catch((e) => this.catcher(e));

      this.visitor_paginate.per_page = parseInt(visitors.per_page);
      this.visitor_logs = visitors;
      this.visitor_logs.total = visitors.total == 0 ? 0 : visitors.total;
    },
    async search() {
      const facility = window._app_facility;
      this.visitor_paginate.page = 1;
      await this.list(facility);
    },
    async paginate(page) {
      const facility = window._app_facility;

      this.visitor_paginate.page = page;
      this.filter = this.getFilter(facility.id);
      if (_.isEmpty(this.filter.dateDropdownMode)) {
        this.filter.dateDropdownMode = 'all_data';
      }
      await this.setDropdownLabel();
      await this.list(facility);
    },
    async exportExcel() {
      const facility = window._app_facility;
      const d = await this.$lionheart
        .post(
          "/logs/checkin",
          {
            facility_id: facility.id,
            get_data_only: 0,
            ...this.filter,
            timezone: moment.tz.guess()
          },
          { responseType: "arraybuffer" }
        )
        .then((r) => {
          const file = moment().format("YYYYMMDD");

          let blob = new Blob([r.data], { type: "application/xls" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = downloadUrl;
          link.setAttribute("download", `${file}_logs.xlsx`); //any other extension

          document.body.appendChild(link);

          link.click();
          link.remove();

          this.error_msg = "";

          return {
            error: {
              has_error: false,
            },
          };
        })
        .catch((e) => this.catcher(e));

      if (d.error.has_error) return this.errorDisplay(d);
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg;
      setTimeout(
        () => window.scrollTo(0, $("#error_msg").position().top - 10),
        300
      );
      return;
    },
    // Must be move to mixins
    getNationality(code) {
      let label = nationalities[this.$i18n.locale].find(
        (obj) => obj.CountryCode == code
      );

      if (label) {
        label = label.Nationality;
        return label;
      } else {
        label = nationalities[this.$i18n.locale].find(
          (obj) => obj.Nationality == code
        );
        if (label) return label.CountryCode;
      }

      return "";
    },
    async setDropdownLabel() {
      this.filter.dateDropdownLabel = await this.$t(`account-page-visitor.${this.filter.dateDropdownMode}`);
    },
    async handleCustomSearch() {
      this.$bvModal.hide('date-filter-modal');
      this.filter.dateDropdownMode = 'custom';
      await this.setDropdownLabel();
      await this.saveFilter();
      await this.search();
    },
    initHiddenColumns() {
      if (!this.facility.has_pms) {
        this.columnSelector.hiddenFields.push('booking_status','payment_status');
        this.columnView.hiddenFields.push('checkout_date','booking_status','payment_status');
      }
    },
    viewDetails(index) {
      this.detailModal.itemIndex = index;
      this.detailModal.show = true;
    },
    viewNextDetails() {
      const itemIndex = parseInt(this.detailModal.itemIndex);
      const itemTotal = parseInt(this.visitor_logs.total) - 1;
      if (itemIndex < itemTotal) this.detailModal.itemIndex = itemIndex + 1;
    },
    viewPrevDetails() {
      const itemIndex = parseInt(this.detailModal.itemIndex);
      if (itemIndex > 0) this.detailModal.itemIndex = itemIndex - 1;
    },
    timezone($event){

      this.facility = {
        ...this.facility,
        timezone: $event
      }
    },
    isDeleteRestoreButton(){

      return this.archive_restore.confirming || this.isDeletion ? false : true;
    },
    getPrecheckIndateTime(preCheckIndateTime, createdAt){
      if(preCheckIndateTime != null){
        return createdAt
      }
      return '--'
    }
  },
  watch: {
    async pms_reservation_no(val) {
      if (!val) {
        await this.search();
      }
    },
    async name(val) {
      if (!val) {
        await this.search();
      }
    },
    async phone(val) {
      if (!val) {
        await this.search();
      }
    },
    async conditionsFilterNum(val) {
      if (val > 0) {
        this.filter.conditionsFilterVariant = 'warning';
      } else {
        this.filter.conditionsFilterVariant = 'light';
      }
      await this.saveFilter();
    },
    async dateDropdownMode(val) {
      if (val == 'all_data') {
        this.filter.dateFilterVariant = 'btn-light';
      } else {
        this.filter.dateFilterVariant = 'btn-warning';
      }
      await this.saveFilter();
    }
  },
  mixins: [formatters],
};
