<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          {{ $t("account_page_details.tab_qr_pass") }}
        </h6>
      </div>
      <div class="card-body">
        <div v-if="vl.booking_details" class="row">
          <div class="col-sm-4">
            {{ $t("visitor_log_detail_qr_pass.pass") }}
          </div>
          <div class="col-sm-8">
            {{ vl.booking_details.pass }}
          </div>
          <div class="col-sm-4">
            {{ $t("visitor_log_detail_qr_pass.qr_code") }}
          </div>
          <div ref="qr_code" class="col-sm-8">
            <img :src='src' height="128" width="128"/>
          </div>
          <div class="col-sm-4">

          </div>
          <div class="col-sm-8 pt-3">
            {{ $t("visitor_log_detail_qr_pass.note") }}
          </div>
        </div>
        <div v-else class="row">
          <div class="col-sm-12">
            {{ $t("visitor_log_detail_qr_pass.qr_not_available") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue
  },
  props: ['vl'],
  data() {
    return {
      config: {
        api_base_url: process.env.VUE_APP_LIONHEART_API_URL
      },
      src: null
    }
  },
  mounted() {
    this.src = `${this.config.api_base_url}/api/checkin/quick-qr?e=${this.vl.visitor.user.email}&k=${this.vl.booking_details.pass}`;
  },
  methods: {
    get_canvas() {
      if (this.vl.booking_details) {

        let content = '<img height="128" width="128" src="' + this.src + '">';
        var print = window.open('', '_blank');
        print.document.open()
        print.document.write('<html><head><style @media="print">' +
            '@page\n' +
            '{\n' +
            '  size: auto;   \n' +
            '  margin: 0mm;  \n' +
            '}' +
            '' +
            '@media print {\n' +
            '    #qr{\n' +
            ' padding: 1.5rem; \n' +
            ' border: 1px solid black; \n' +
            '    }\n' + '   ' +
            ' body{\n' +
            ' width: 180px; \n' +
            '    }\n' +

            '}</style></head><body onload="window.print()"><div id="qr">' + content + '</div></html>');
        print.document.close();
      }
    }
  }
};
</script>