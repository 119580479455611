<template>
    <div>
        <template v-if="checkin">
            <a class="btn btn-link dropdown-toggle" data-toggle="dropdown" @click.prevent="">
                {{ $t(`visitor-language-button.${language.toLowerCase()}`) }}
            </a>
            <ul class="dropdown-menu lang-checkin-btn">
                <li v-for="el in enabled_languages" style="cursor: pointer;">
                    <a style="width: 100%" @click.prevent="selectLang(el.code)" >{{ $t(`${checkin == true ? 'visitor-language-button.' : 'language-button.'}${el.name}`) }}</a>
                </li>
            </ul>
        </template>
        <template v-else>
            <ul class="nav nav-tabs mb-4">
                <li class="nav-item" v-for="el in enabled_languages">
                    <a :class="`nav-link ${(language == el.code)? 'active' : ''}`" @click.prevent="selectLang(el.code)" href="javascript:void(0)">
                        {{ $t(`language-button.${el.name}`) }}
                    </a>
                </li>
            </ul>
        </template>
    </div>
</template>
<script>
    import _ from 'lodash'; 
    import t from 'typy';

    export default {
        props: {
            facility_id: 0,
            checkin: null
        },
        components: {
        },
        data() {
            return {
                enabled_languages: [],
                language: this.$i18n.locale
            }
        },
        async mounted() {
        },
        computed: {
        },
        watch: {
            facility_id: async function(val) {
                if (val > 0)  {
                    const el = await this.$lionheart.post('/facility-setting/list', {
                        facility_id: this.facility_id,
                        name: 'language',
                        paginate: false
                    });
                    if(el.data.total != 0)
                        this.enabled_languages = el.data.data[0].value
                    else
                        this.enabled_languages.push({code: 'en', name: 'en'}, {code: 'ja', name: 'ja'})
                }
            }
        },
        methods: {
            async selectLang(lang) {
                this.language = lang
                this.$emit('select-lang', lang, 'language-button');
            },
        }
    }
</script>
<style lang="scss" scoped>
    .dropdown-toggle {
        font-family: "Poppins", sans-serif;
        color: #4d4d4d !important;
        font-weight: 600 !important;
        &:hover {
            color: #4d4d4d !important;
        }
        &::after {
            margin-left: 13px;
        }
    }
    .lang-dashboard-btn {
        background: #013070 !important;
    }
    .lang-checkin-btn {
        font-family: "Poppins", sans-serif;
        background: #fff;
        li a {
            font-family: "Poppins", sans-serif;
            color: #4d4d4d !important;
            font-weight: 600 !important;
            &:hover {
                color: #fff;
                background: #1aa8de;
            }
        }
    }
</style>