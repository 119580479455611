import { main_form_methods } from "../../../utils/mockup";
import _ from "lodash";
import t from "typy";
import HelpComponent from "./../../../components/help.component";
import PmsBadge from "./../components/PmsBadge";
import BasicDetailsModal from "./../components/detail/BasicDetailsModal";
import CustomDetailsModal from "./../components/detail/CustomDetailsModal";
import ReservationArchiveRestore from "./../components/reservation-archive-restore";
import { maskedDummyEmails } from "../../../utils/helpers";

const default_time = {
  from_time: "00:00",
  to_time: "23:59",
};
const {
   formatDateFacilityTimezoned
} = main_form_methods;

import formatters from '@/mixins/formatters.js';

export default {
   components: {
      BasicDetailsModal,
      CustomDetailsModal,
      HelpComponent,
      PmsBadge,
      ReservationArchiveRestore
   },
   data() {
      return {
         config: { show_search_tool: false,      title: "title" },
         error_msg: "",

         visitor_paginate: {
            paginate: true,
            per_page: 50,
            page: 1,
         },
         visitor_logs: {
            data: [],
            total: 0,
         },

         // filter items
         filter: {
            facility_id: window._app_facility.id,
            pms_reservation_no: '',
            booking_status_id: 1,
            trash_only: false         
         },
         selecting: {
            selected: [],
            mode: null,
            showSelecting: false,
         },
         deleting: {
            confirming: true,
         },
         archive_restore: {
            confirming: false
         },
         columnView: {
            displaySpecs: [],
            fieldSettings: {},
            hiddenFields: [],
         },
         columnSelector: {
            groupedFields: [],
            fieldSettings: {},
            fieldSettingSnapshot: {},
            hiddenFields: ['checkin_date', 'checkout_date'],
         },
         facility: {},
         detailModal: {
            show: false,
            itemIndex: 0,
            pmsReservationNumber: '',
            visitorPass: '',
         },
         loadDetailComponent: false
      };
   },
   async mounted() {
      this.facility = window._app_facility;
      this.paginate(1);
      this.loadDetailComponent = true;
   },
   computed: {
      isDeletion: function () {
      return this.selecting.mode == "deleting" && this.selecting.showSelecting;
      },
      pms_reservation_no() {
      return this.filter.pms_reservation_no;
      },
      name() {
      return this.filter.name;
      },
      phone() {
      return this.filter.phone;
      },
      disableOK() {
      if (this.filter.from_date == '' || this.filter.to_date == '') {
         return true;
      }
      return false;
      },
      enableClearFilter() {
         return this.filter.conditionsFilterVariant == 'warning' || this.filter.dateFilterVariant == 'btn-warning';
      },
      currentLogIdDetail() {
         const index = this.detailModal.itemIndex;
         if (!t(this.visitor_logs.data, String(index)).isDefined) return 0;
         else {
            console.log('currentLogIdDetail ',this.visitor_logs.data[index].id);
            return this.visitor_logs.data[index].id;
         }
      }
   },
   methods: {
      formatDateFacilityTimezoned,
      dummyEmailMasked(email) {
         return maskedDummyEmails(email);
      },
      async applyColumnView() {
         const facility = window._app_facility;
         this.columnSelector.groupedFields = [];
         this.columnView.fieldSettings = {};
         const columns = 2;

         const template = facility.has_pms ? 'guest_list' : 'guest_list_basic';
         // Fetch & set data template references.
         const templateRequest = await this.$lionheart
            .get(`facility-setting/data-template/${facility.slug_name}/${template}`, {params: {parse: true}});
         this.columnView.displaySpecs = templateRequest.data.value.specs;
         console.log(this.columnView);

         // Set keyed saved field settings
         const savedSettings = {}
         const settingsRequest = await this.$lionheart
            .post("/facility-setting/list", {
               facility_id: facility.id,
               name: "visitor_columns",
               paginate: false,
            })
            .then((r) => this.catcher(r.data, "data", (r) => r));

         if (settingsRequest.data.length == 0) {
            for (let field of this.columnView.displaySpecs.list) {
               savedSettings[field.system_name] = { label: field.key, system_name: field.system_name, column_view: true };
            }
         } else {
            const settings = settingsRequest.data[0].value;
            try {
               for (let field of settings) {
               savedSettings[field.system_name] = {
                  label: field.key, system_name: field.system_name, column_view: field.column_view
               };
               }
            } catch (e) {
               for (let field of this.columnView.displaySpecs.list) {
               savedSettings[field.system_name] = { label: field.key, system_name: field.system_name, column_view: true };
               }
            }
         }

         // Finalize keyed settings.
         const fieldSettings = {};
         this.columnView.displaySpecs.list.forEach((obj) => {
            const setting = { label: obj.key, system_name: obj.system_name }
            if (Object.keys(savedSettings).length == 0) {
               // Existing user that don't have settings yet: enable display by default
               setting.column_view = true;
            } else {
               const columnSetting = savedSettings[obj.system_name];
               setting.column_view = columnSetting ? columnSetting.column_view : true;
            }
            fieldSettings[obj.system_name] = setting;
         });

         // Sub divide fields into specified number of column for selector display.
         const fieldNames = Object.keys(fieldSettings).filter(
            v => !this.columnSelector.hiddenFields.includes(v)
         );
         const chunkSize = Math.ceil(fieldNames.length / columns);
         const groupedFields = _.chunk(fieldNames, chunkSize);

         // Set final values for column display references.
         this.columnView.fieldSettings = fieldSettings;
         this.columnSelector.groupedFields = groupedFields;
         this.columnSelector.fieldSettings = fieldSettings;
         this.columnSelector.fieldSettingSnapshot = _.cloneDeep(fieldSettings);
      },
      async paginate(page) {
         const facility = window._app_facility;

         this.visitor_paginate.page = page;
         await this.list();
      },

      // get filter in local storage for reference 
      getFilter(id) {
         var filter = this.$storage.get("visitor_filter_" + id);
         var response = {};
         if (filter) {
            response = filter;
         }
         return _.isEmpty(response) ? this.filter : response;
      },
      isColumnActive(sysName) {
         const fieldSetting = this.columnSelector.fieldSettings[sysName];
         return fieldSetting ? fieldSetting.column_view : true;
      },
      isNotColumnHidden(sysName) {
      return !this.columnView.hiddenFields.includes(sysName);
      },
      // get all the list of reserve guest
      async list() {
         const visitors = await this.$lionheart
            .post("/v2/facility-booking/reservation-list", {
               ...this.visitor_paginate,
               ...this.filter,
               // timezone: moment.tz.guess()
            })
            .then((r) =>
               this.catcher(r.data, "data", (r) =>
               this.visitor_paginate.paginate ? r.data : r
               )
            )
            .catch((e) => this.catcher(e));
         this.visitor_paginate.per_page = parseInt(visitors.per_page);
         this.visitor_logs = visitors;
         this.visitor_logs.total = visitors.total == 0 ? 0 : visitors.total;
      },

      // Search function
      async search() {
         this.visitor_paginate.page = 1;
         await this.list();
      },

      showColumnSelector() {
         this.$refs['modal-column-change-confirmation'].show();
      },
      hideColumnSelector() {
         const snapshotSettings = this.columnSelector.fieldSettingSnapshot;
         for(const settingName of Object.keys(snapshotSettings)) {
            const show = snapshotSettings[settingName].column_view;
            this.columnSelector.fieldSettings[settingName].column_view = show;
         }
         this.$refs['modal-column-change-confirmation'].hide();
      },
      toggleDelete() {
         this.$refs["modal-delete-confirmation"].hide();

         setTimeout(() => {
            this.selecting.mode = "deleting";
            this.selecting.showSelecting = !this.selecting.showSelecting;
            this.selecting.selected = [];
            //
            this.deleting.confirming = true;
         }, 1000)
      },
      toggleArchiveRestore(mode){
         this.selecting.mode = mode;
         this.selecting.selected = [];

         if(['archive_list','checkin_list'].includes(mode)){
            this.visitor_paginate.page = 1;
            this.filter.trash_only = mode == "archive_list" ? true : false;
            this.list();
         }

         if(['restore','archive'].includes(mode)){
            this.selecting.showSelecting = !this.selecting.showSelecting;
            this.archive_restore.confirming = !this.archive_restore.confirming;
         }
      },
      isDeleteRestoreButton(){
         return this.archive_restore.confirming || this.isDeletion ? false : true;
       },
      // Control for viewing the details of guest..
      viewDetails(indexOfVisitorLog, visitorLogDetails) {
         this.detailModal.itemIndex = indexOfVisitorLog;
         this.detailModal.pmsReservationNumber = visitorLogDetails.pms_reservation_no;
         this.detailModal.visitorPass = visitorLogDetails.pass;
         this.detailModal.show = true;
      },
      viewNextDetails() {
         // where i can get the nxt reservation?
         const itemIndex = parseInt(this.detailModal.itemIndex);
         const itemTotal = parseInt(this.visitor_logs.total) - 1;
         if (itemIndex < itemTotal) {
            this.detailModal.itemIndex = itemIndex + 1;

            const visitorData = this.visitor_logs.data.find((item) => item.id === this.currentLogIdDetail);
            this.detailModal.pmsReservationNumber = visitorData.pms_reservation_no;
            this.detailModal.visitorPass = visitorData.pass;
         }
      },
      viewPrevDetails() {
         const itemIndex = parseInt(this.detailModal.itemIndex);
         if (itemIndex > 0) {
            this.detailModal.itemIndex = itemIndex - 1;
            const visitorData = this.visitor_logs.data.find((item) => item.id === this.currentLogIdDetail);
            this.detailModal.pmsReservationNumber = visitorData.pms_reservation_no;
            this.detailModal.visitorPass = visitorData.pass;
         }
      },

      extractValue(field, obj) {
         try {
            let objMap = field.value.split('.').reduce((a, i) => a[i], obj);
            if (Number.isInteger(objMap) && !objMap) return false;
            if (!objMap) return '--';
            if (field.system_name == 'payment_status') return this.$t(`payment_statuses.${objMap}`);
            
            if (field.system_name == 'booking_status') return this.$t(`booking_statuses.${objMap}`);
            if (['checkin_date', 'checkout_date', 'pre_checkin_date'].includes(field.system_name)) {
               return objMap ? this.formatDateTime(objMap) : '--';
            }
            if (field.system_name == 'nationality') return this.getNationality(objMap);
            return objMap;
         } catch (e) { return false; }
      },

      async deleteReservation() {
         const facility = window._app_facility;
         const ids = this.selecting.selected.map((item) => item.id);

         const res = await this.$lionheart
           .post("/v2/facility-booking/reservation-list/multiple/delete", {
             facility_id: facility.id,
             ids,
           })
           .then((r) => this.catcher(r.data, "data", (r) => r))
           .catch((e) => this.catcher(e));
   
         if (res.error.has_error) return this.errorDisplay(res);
   
         this.deleting.confirming = false;
         this.visitor_paginate.page = 1;
         this.list();
       },

      // Clear the text in filter input
      async clearFilter(mode = 'all_data') {
         this.visitor_paginate.per_page = 50;
         this.filter = {
            pms_reservation_no: '',
            facility_id: window._app_facility.id,
            booking_status_id: 1
         };
         await this.paginate(1);
      },
      
      errorDisplay(d) {
         this.error_msg = d.error.msg;
         setTimeout(
            () => window.scrollTo(0, $("#error_msg").position().top - 10),
            300
         );
         return;
      },

      getNationality(code) {
         let label = nationalities[this.$i18n.locale].find(
           (obj) => obj.CountryCode == code
         );
   
         if (label) {
           label = label.Nationality;
           return label;
         } else {
           label = nationalities[this.$i18n.locale].find(
             (obj) => obj.Nationality == code
           );
           if (label) return label.CountryCode;
         }
   
         return "";
       },
   },
   watch: {
      async pms_reservation_no(val) {
         if (!val) {
            await this.search();
         }
      },
      async name(val) {
         if (!val) {
            await this.search();
         }
      },
      async phone(val) {
         if (!val) {
            await this.search();
         }
      },
   },
   mixins: [formatters],
};
