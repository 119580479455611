<template>
  <div style="position: relative">
    <div class="img-thumbnail media-box">
      <div v-show="!uploading" class="content-mode">
        <img class="img-fluid w-100" :src="src" />

        <div class="overlay">
          <div class="top-right">
            <div class="action-button">
               <button @click.prevent="$emit('reUploadImage')" class="btn btn-sm btn-primary btn-w-md">
                  <span class="file-icon-small">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
                     {{ $t("visit-information-form.browse_file_change_photo_text") }}
                  </span>
               </button>
               <button v-if="index != 0" @click="removeCompanion" class="btn btn-sm btn-danger btn-w-md ml-2">
                  {{ $t("visit-information-form.browse_file_remove_companion_photo_text") }}
               </button>
            </div>
          </div>
        </div>

      </div>
      <div v-show="uploading" class="capture-mode">
         <div v-if="index != 0" class="remove-companion-button-holder">
            <button @click="removeCompanion">
               <i class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 48a208 208 0 1 1 0 416a208 208 0 1 1 0-416zm0 464a256 256 0 1 0 0-512a256 256 0 1 0 0 512zm-81-337c-9.4 9.4-9.4 24.6 0 33.9l47 47l-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47l47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47l47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47l-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
               </i>
            </button>
         </div>

         <div class="upload-image-holder" :class="{ disabledClass : disabledUploadBox }">
            <div class="upload-label">
               <i class="icon">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1408"><path fill="currentColor" d="M1280 736q0-14-9-23L919 361q-9-9-23-9t-23 9L522 712q-10 12-10 24q0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5V768h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5T1536 1408H448q-185 0-316.5-131.5T0 960q0-130 70-240t188-165q-2-30-2-43q0-212 150-362T768 0q156 0 285.5 87T1242 318q71-62 166-62q106 0 181 75t75 181q0 76-41 138q130 31 213.5 135.5T1920 1024z"/></svg> -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
               </i>
               <label for="upload-image">
                  {{ $t("visit-information-form.browse_file_upload_photo_text") }}
               </label>
            </div>
            <input type="file" accept="image/*" name="file" @change="handleChooseFile" ref="fileInput" class="input-file" >
         </div>

         <div v-if="showInput && uploading" class="companion-name-form">
            <input type="text" class="name-input" :class="{ 'errorInput apply-shake' : showEmptyNameError}" :placeholder="$t('visit-information-form.take_pictures_input_name_note')" v-model="name[index]">
            <b-button variant="success" class="btn-w-lg" @click.prevent="saveName">
               {{ $t("visit-information-form.browse_file_save_name_text") }}
            </b-button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraScanOverlay from "./CameraScanOverlay"

export default {
   name: "ImagePreviewBoxComponent",
   components: { CameraScanOverlay },
   props: {
      uploading: {
         type: Boolean,
         default: false
      },
      showInput: {
         type: Boolean,
         default: false
      },
      src: {
         type: String,
         default: ''
      },
      disabledUploadBox: {
         type: Boolean,
         default: true
      },
      index: {
         type: Number
      }
   },
   data() {
      return {
         name: [],
         showEmptyNameError: false
      }
   },
   methods: {
      async handleChooseFile(event) {
         const files = event.target.files;
         this.$emit("uploadImage",files);
      },
      saveName() {
         if(this.name.length <= 0) {
            setTimeout(() => {
               this.showEmptyNameError = true; 
            })
            this.showEmptyNameError = false;
            return;
         }
         this.showEmptyNameError = false;
         this.$emit('saveName',this.name);
      },
      removeCompanion (e) {
         this.$emit('removeCompanion',e);
      }

   }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
.disabledClass {
   background: #d9d9e1;
   pointer-events: none;
}
.errorInput{
   border: solid $danger 2px;
}

@keyframes shake {
   10%,
   90% {
      transform: translate3d(-1px, 0, 0);
   }

   20%,
   80% {
      transform: translate3d(2px, 0, 0);
   }

   30%,
   50%,
   70% {
      transform: translate3d(-4px, 0, 0);
   }

   40%,
   60% {
      transform: translate3d(4px, 0, 0);
   }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.file-icon-small {
   display: flex;
   width: 100%;
   svg {
      width: 20px;
      margin-right: 5px;
   }
}

.media-box {
   position: relative;
   .content-mode {
   // img {
   //    max-height: 300px;
   //    object-fit: contain;
   // }
      &:hover {
      .label { opacity: 1; }
      }
   }
   .capture-mode {
      position: relative;
      .remove-companion-button-holder {
         position: absolute;
         width: 100%;
         z-index: 1;
         top: -15px;
         right: -15px;
         display: flex;
         justify-content: flex-end;
         button {
            border-radius: 50px;
            width: 8%;
            padding: 0;
            border: none;
            outline: none;
            // background-color: $danger;
            .icon {
               display: flex;
               justify-content: center;
               align-content: center;
               width: 100%;
               height: 100%;
               svg {
                  color: $danger;
               }
            }
         }
      }
      .companion-name-form {
         padding: 5px;
         margin-top: 10px;
         display: flex;
         .name-input {
            margin-right: 5px;
            border-radius: 5px;
            width: 100%;
            padding: 8px;
         }
      }
   }
   .label {
      color: white;
      background: $primary;
      padding: 2px 10px;
      border-radius: 2px;
      font-size: 0.8rem;
      opacity: 0.8;
   }
   .top-right {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      .action-button {
         display: flex;
         justify-content: space-between;
         .remove-companion-button-1 {
            max-width: 50px;
         }
      }
   }
}
.disabled {
   background: #ccc;
}
.upload-image-holder {
   outline: 2px dashed grey;
   outline-offset: -10px;
   min-height: 150px;
   // background: lightcyan;
   padding: 10px;
   cursor: pointer;
   display: flex;
   justify-content: center;
   position: relative;

   .upload-label {
      margin: auto;
      
      .icon svg {
         width: 100%;
         height: 45px;
         color: $secondary;
      }
      label {
         text-align: center;
         width: 100%;
         font-size: 0.875em;
         font-weight: 400;
         color: gray;
      }
   }

   &:hover {
      .icon svg {
         color: $primary;
         transform: scale(1.09);
      }
      label {
         text-decoration: underline;
      }
   }

   .input-file {
      opacity: 0;
      width: 100%;
      cursor: pointer;
      position: absolute;
      height: 150px;
   }
}
</style>