import moment from "moment-timezone";

const defaultLang = "ja"; // ISO 639-2

export default {
  methods: {
    formatDate(dateTime, format) {
      let formatter = 'MM/DD/YYYY';
      if (!format) formatter = this.$i18n.locale == defaultLang ? 'YYYY/MM/DD' : formatter;
      const dateHandle = moment(dateTime);
      return dateHandle.isValid() ? dateHandle.format(formatter) : '--';
    },
    formatDateTime(dateTime, format, timezone) {
      let formatter = 'MM/DD/YYYY hh:mm A';
      if (!format) formatter = this.$i18n.locale == defaultLang ? 'YYYY/MM/DD hh:mm A' : formatter;
      const tz = timezone ? timezone : moment.tz.guess();
      return moment.tz(dateTime, tz).format(formatter);
    },
    formatAmount(amount) {
      const number = Math.round(parseFloat(amount)).toFixed(0);
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  }
}