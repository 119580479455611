<template>

  <b-card class="mt-4" v-if="this.stripe_config.initialize">

    <Payment ref="payment" :currency="currency" />

    <div
      v-if="!pStatus.status"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      {{ pStatus.error.msg }}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <template #header>
      <b>{{ $t("visit-information-form.payment") }}</b>
    </template>

    <div class="form-group row" v-if="!confirm">
      <div class="col-sm-12 pl-3 ml-1">
        <input
          v-model="payment.gateway"
          :value="payment_gateway.stripe"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payStripe"
          name="payment_method"
        />
        <label
          class="form-check-label ml-2 text-decoration-none"
          for="payHotel"
        >
          {{ $t("visit-information-form.card_payment") }}
        </label>
      </div>
    </div>

    <div v-show="payment.gateway == stripe_config.name && !confirm">
      <div v-if="challengeError" class="alert alert-warning" role="alert">{{ $t('visit-information-form.card_payment_challenge_error') }}</div>
      <div class="form-group row ml-1" v-if="booking_details.data[0].parsed.net_charge > 0">
        <label
          for="exampleInputEmail1"
          class="col-sm-4 col-form-label font-weight-bold"
          >{{ $t("pre_checkin_amatch.net_charge") }}:</label
        >
        <div class="col-sm-8 py-2 font-weight-bold">
          {{ booking_details.data[0].parsed.net_charge }} {{ currency }}
        </div>
      </div>
      <div class="form-group row ml-0 mb-2" style="max-width: 100%">
        <div class="col-lg-12 col-lg-12 ml-1 pl-0 pr-0">
          <div
            id="card-element"
            class="form-control stripe-input border-0"
          ></div>
        </div>
      </div>

      <div class="form-group row" v-if="isDeposit">
        <div class="col-sm-12 mx-2">
          <small class="form-text text-muted"
            >*
            {{ $t("visit-information-form.deposit_note_1") }}
          </small>
        </div>
      </div>

      <div class="form-group row" v-if="stripe_config.error.code">
        <div class="col-sm-12 mx-2">
          <small style="font-size: 15px" class="form-text text-danger">
            {{ $t("visit-information-form.invalid_number") }}
          </small>
        </div>
      </div>
    </div>

    <div class="form-group row" v-if="!confirm">
      <div
        class="col-sm-12 pl-3 ml-1"
        v-if="payment_timing_opt.payHotelVisibility"
      >
        <input
          v-model="payment.gateway"
          :value="payment_gateway.reception"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payHotel"
          name="payment_method"
        />
        <label
          class="form-check-label ml-2 text-decoration-none"
          for="payHotel"
        >
          {{ $t("visit-information-form.pay_at_reception") }}
        </label>
        <p
          v-if="payment.gateway == payment_gateway.reception"
          class="mb-0"
          style="color: #1a5dab"
        >
          {{ $t("visit-information-form.pay_at_reception_note") }}
        </p>
      </div>
    </div>

    <div class="form-group row" v-if="!confirm">
      <div class="col-sm-12 pl-3 ml-1" v-if="isPayLater">
        <input
          v-model="payment.gateway"
          :value="payment_gateway.payLater"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payLater"
          name="payment_method"
        />
        <label
          class="form-check-label ml-2 text-decoration-none"
          for="payLater"
        >
          {{ $t("visit-information-form.pay_later") }}
        </label>
        <p
          v-if="payment.gateway == payment_gateway.payLater"
          style="color: #1a5dab"
        >
          {{ $t("visit-information-form.pay_later_note") }}
        </p>
      </div>
    </div>

    <template v-if="confirm">
      <template v-if="payment.gateway == payment_gateway.stripe">
        <div class="form-group row">
          <label for="exampleInputEmail1" class="col-sm-4 col-form-label"
            >{{ $t("visit-information-form.payment_card_number") }}</label
          >
          <div class="col-sm-8 py-2">
            <template v-for="key in 4">●</template>
            <template v-for="key in 4">●</template>
            <template v-for="key in 4">●</template>
            {{ stripe_config.card_details.last4 }}
          </div>
        </div>

        <div class="form-group row">
          <label for="exampleInputEmail1" class="col-sm-4 col-form-label"
            >{{ $t("visit-information-form.payment_expiration") }}</label
          >
          <div class="col-sm-8 py-2">
            {{ stripe_config.card_details.exp_month }}/{{
              stripe_config.card_details.exp_year
            }}
          </div>
        </div>

        <div class="form-group row" v-if="isDeposit">
          <div class="col-sm-12 mx-2">
            <small
              class="form-text text-muted"
              >*
              {{ $t("visit-information-form.deposit_note_1") }}
            </small>
          </div>
        </div>
      </template>
      <div
        v-if="payment.gateway == payment_gateway.reception"
        class="form-group row"
      >
        <label for="exampleInputEmail1" class="col-12 col-form-label">
          {{ $t("visit-information-form.pay_at_reception") }}
        </label>
      </div>
      <div
        v-if="payment.gateway == payment_gateway.payLater"
        class="form-group row"
      >
        <label for="exampleInputEmail1" class="col-12 col-form-label">
          {{ $t("visit-information-form.pay_later") }}
        </label>
      </div>
    </template>

  </b-card> 

</template>

<script>
import _typy from "typy";
import { payment_status, payment_gateway } from "../../../utils/mockup";
import Payment from "./Payment";

const pStatus = {
  error: {},
  status: true,
};

export default {
  name: "StripePayment",
  components: {
    Payment,
  },
  props: [
    "booking_details",
    "facility",
    "confirm",
    "page",
    "currency",
    "touchdown",
  ],
  data() {
    return {
      show_payment: true,
      pStatus,
      payment: {
        gateway: payment_gateway.stripe,
        type: "card",
      },
      challengeError: false,
      payment_gateway,
      stripe_config: {
        token: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        stripe: "",
        elements: "",
        card: "",
        data: {},
        intent: {},
        card_details: {},
        empty: true,
        name: payment_gateway.stripe,
        // name: 'manual', // quick fix; disable stripe payment
        initialize: false,
        error: {},
      },
      payment_timing_opt: {
        payHotelVisibility: true,
        payLaterVisibility: false,
        waivePay: false,
      },
      isDeposit: false,
    };
  },
  async mounted() {
    this.init();
  },
  computed: {
    isPayLater() {
      return (
        this.payment_timing_opt.payLaterVisibility &&
        this.page == "checkin" &&
        this.touchdown === undefined
      );
    },
  },
  methods: {
    async init() {
      await this.showPaymentTimingOption();
      await this.showPayment();

      if (!this.show_payment) return;

      await this.validatePayment();

      if (this.stripe_config.initialize) {
        this.includeStripe(
          "js.stripe.com/v3/",
          function () {
            this.configureStripe();
          }.bind(this)
        );
      }
    },
    async showPaymentTimingOption() {
      const optSettings = await this.$lionheart
        .post("/facility-setting/list", {
          name: "payment_timing_opts",
          facility_id: this.facility.id,
          paginate: false,
        })
        .then((r) =>
          this.catcher(r, "data.total", (r) =>
            r.data.total > 0 ? r.data.data[0] : r.data
          )
        )
        .catch((e) => this.catcher(e));

      if (optSettings.error.has_error) return; // Silent API error catch

      if (optSettings.total == 0) return;

      this.payment_timing_opt = optSettings.value;
    },
    async showPayment() {
      // No show payment if waivePay is true
      if(this.payment_timing_opt.waivePay) return (this.show_payment = false);

      const res = await this.$lionheart
        .post("/facility-setting/list", {
          facility_id: this.facility.id,
          name: "payment_option",
          paginate: false,
        })
        .then((r) =>
          this.catcher(r, "data.total", (r) =>
            r.data.total > 0 ? r.data.data[0] : r.data
          )
        )
        .catch((e) => this.catcher(e));

      if (res.error.has_error) {
        // Silent API error catch
        if (this.page != "checkin") return (this.show_payment = false);

        return; // Default to show to checkin
      }

      if (res.total == 0) {
        if (this.page != "checkin") return (this.show_payment = false);

        return; // Default to show to checkin
      }

      let showPayment = _.find(res.value, (val, key) => {
        return ["checkin", "deposit"].includes(key) && val == 1;
      });

      if (this.page == "checkout") showPayment = res.value[this.page];

      this.isDeposit = res.value["deposit"] == 1;

      return (this.show_payment = showPayment);
    },
    reInit() {
      if (!this.show_payment) return;

      // Stripe is already loaded
      this.validatePayment();

      if (this.stripe_config.initialize) this.configureStripe();
    },
    async makePayment(messageToComponent) {
      if (this.stripe_config.initialize) {
        if (this.payment_gateway.payLater == this.payment.gateway) return true; // For pay later

        if (this.isDeposit && this.payment_gateway.stripe == this.payment.gateway) { // Deposit and stripe
          // TODO: make a function
          // Do a setup intent and save a facility booking
          const saveCard = await this.$lionheart.post(
            "/payment/pms/save-card",
            {
              ...this.payment,
              facility_id: this.facility.id,
              payment_method: this.stripe_config.intent.paymentMethod.id,
              account:
                this.booking_details.data[0].payment_metadata.stripe_account,
              pms_reservation_no:
                this.booking_details.data[0].pms_reservation_no,
            }
          );

          if (!messageToComponent) return saveCard;

          this.pStatus = saveCard;

          return saveCard.status;
        }

        let cardConfirmStatus = null;
        const cardResponse = this.$store.getters["checkin-site/confirmCardResponse"];
        if (cardResponse) {
          if (_typy(cardResponse.paymentIntent).isDefined) {
            cardConfirmStatus  = cardResponse.paymentIntent.status;
          }
        }
        
        const res = await this.$refs.payment.createPayment({
          ...this.payment,
          ...this.stripe_config.intent,
          pms_reservation_no: this.booking_details.data[0].pms_reservation_no,
          account: this.booking_details.data[0].payment_metadata.stripe_account,
          facility_id: this.facility.id,
          cardConfirmStatus
        });

        if (!messageToComponent) return res;

        this.pStatus = res;

        return res.status;
      }

      return true;
    },
    async createPaymentMethod() {
      if (!this.stripe_config.initialize) return true;
      const paymentOtherOptions = [
        payment_gateway.reception,
        payment_gateway.payLater,
      ];

      if (paymentOtherOptions.includes(this.payment.gateway)) return true;

      let status = false;
      const cpm = await this.stripe_config.stripe.createPaymentMethod({
        type: "card",
        card: this.stripe_config.card,
        billing_details: {
          name: this.stripe_config.data.name,
        },
      });

      if (cpm.error) {
        this.stripe_config.error = cpm.error;
        return status;
      }
      this.stripe_config.error = ''; // reset the error if the correct card is input

      if (this.isDeposit) {
        //Deposit; create payment method only attached on setup intent upon submission of payment
        this.stripe_config.error = {};
        this.stripe_config.intent = cpm;
        this.stripe_config.card_details = cpm.paymentMethod.card;
        return true;
      }

      const response = await this.$lionheart.post(
        "/payment/stripe/create-payment-intent/FacilityBooking",
        {
          payment_method: cpm.paymentMethod.id,
          currency: this.currency,
          id: this.booking_details.data[0].pms_reservation_no,
          account: this.booking_details.data[0].payment_metadata.stripe_account,
          account_id: this.facility.id,
        }
      );
      this.$store.commit("checkin-site/confirmCardResponse", null); //Reset state
      
      let confirmCardPayment  = await this.stripe_config.stripe.confirmCardPayment(response.data.data.client_secret);
      if (_typy(confirmCardPayment.error).isDefined) {
        this.challengeError = true;
        return false;
      }

      if (response.data.data.status) {
        status = true;
        this.stripe_config.intent = response.data.data;
        this.stripe_config.card_details = cpm.paymentMethod.card;
        this.challengeError = false;
        this.$store.commit("checkin-site/confirmCardResponse", confirmCardPayment);
      }

      return status;
    },
    async validatePayment() {
      const paid_statuses = [
        payment_status.pay_at_reception,
        payment_status.paid_authorized,
        payment_status.paid_captured,
        payment_status.paid,
        payment_status.credit_card_as_deposit,
      ];

      if (this.booking_details.total == 0) {
        this.stripe_config.initialize = false;
      } else {
        if (
          paid_statuses.includes(this.booking_details.data[0].payment_status_id)
        ) {
          if (
            payment_status.paid ==
              this.booking_details.data[0].payment_status_id &&
            this.isDeposit
          ) {
            // Exception: Set true since even "paid" status still need to have deposit
            this.stripe_config.initialize = true;
          } else {
            this.stripe_config.initialize = false;
          }
        } else {
          this.stripe_config.initialize = true;
        }
      }
    },

    async configureStripe() {
      this.pStatus = pStatus;
      this.stripe_config.empty = true;
      this.stripe_config.stripe = Stripe(this.stripe_config.token, {
        stripeAccount:
          this.booking_details.data[0].payment_metadata.stripe_account,
      });
      this.stripe_config.elements = this.stripe_config.stripe.elements();
      this.stripe_config.card = this.stripe_config.elements.create("card", {
        hidePostalCode: true,
      });
      this.stripe_config.card.mount("#card-element");
      this.stripe_config.card.on("change", (event) => {
        this.stripe_config.empty = event.empty;
        this.stripe_config.error = '';
      });
    },
    async includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async changePaymentMethod($event) {
      if (!$event.target.checked) this.configureStripe();
    },
  },
  watch: {
    show_payment: function (val) {
      this.$emit("updateShowPayment", val);
    },
    isDeposit: function (val) {
      this.$emit("isDeposit", val);
    },
  },
};
</script>
<style>
#card-element {
  background: #f6f6f6;
  padding-top: 11px;
}
</style>
