import { render, staticRenderFns } from "./CheckoutDetails.vue?vue&type=template&id=80bf1254&scoped=true&"
import script from "./CheckoutDetails.vue?vue&type=script&lang=js&"
export * from "./CheckoutDetails.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutDetails.vue?vue&type=style&index=0&id=80bf1254&prod&lang=css&"
import style1 from "./CheckoutDetails.vue?vue&type=style&index=1&id=80bf1254&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80bf1254",
  null
  
)

export default component.exports