<template>
	<div class="styled-form">
		<div class="mt-3">
			<div class="wrapper">
				<div class="form-content">
					<form class="p-4" >

						<div v-if="!myPage" class="alert alert-success" role="alert">{{ $t('visitor-info-thank-you.thank-you-very-much') }}</div>
                  <div v-if="myPage">
                     <div class="mb-1" role="alert">{{ $t('visitor-info-thank-you.thank-you-very-much') }}</div>
                     <button type="button" class="btn btn-primary btn-lg" style="width: 200px;" @click="goToMyPage">{{ $t('visitor-info-thank-you.go_to_my_page') }}</button>
                  </div>

                  <CheckinDetails v-if="booking_details" :bookingDetails="booking_details" class="mt-2"/>
						<hr >
                  
						<div v-html="custom_text_setting.footer"></div>

						<p align="center" class="font-weight-bold">{{ facility_name }}</p>
					</form>
				</div>
			</div>
		</div>

		<ShareableLinkModal v-if="showLinkModal" :facility="facility" :td="td" :rsv_no="rsv_no"/>
	</div>
</template>

<style lang="scss" scoped>
.styled-form .form-content {
	max-width: 768px;
}
</style>

<script>
import ShareableLinkModal from "./../components/shareableLinkModal";
import CheckinDetails from "../components/CheckinInformation/CheckinDetails";
import t from "typy";
export default {
   components: {
      ShareableLinkModal,
      CheckinDetails,
   },
   data() {
      return {
         facility: {
            name: "...",
         },
         facility_name: "...",
         custom_text_setting: {},
         is_custom: false,
         td: false,
         showLinkModal: false,
         rsv_no: null,
         myPage: false,
         booking_details: {
            pin: [

            ],
         }
      };
   },
   async mounted() {
      this.facility = await this.$lionheart
         .post("/facility/list", { slug_name: this.$route.params.slug_name })
         .then((r) => this.catcher(r.data, "data[0].id", (r) => r.data[0]))
         .catch((e) => this.catcher(e));

      const myPage = await this.$lionheart
         .get("/v2/facility-settings", {
            params: {
               name: 'my-page-setting',
               facility_id: this.facility.id,
               paginate: 0
            }
         })
         .then(res => res.data.data);

      this.myPage = myPage[0]?.value.active ?? false;

      if (this.facility.error.has_error) return this.$router.push("/404");

      this.is_custom = this.facility.facility_setting.length > 0 ? true : false;
      let touchdown  = (this.$route.query.ref == "td" || this.$route.query.ref == "tdf");
      if (this.$route.query.ref && touchdown) this.td = true;

      const fac_name = this.facility.facility_name.find( (obj) => obj.language == this.$i18n.locale );
      const fac_name_en = this.facility.facility_name.find( (obj) => obj.language == "en" );
         
      if (fac_name === undefined) {
         if (fac_name_en) {
            this.facility_name = fac_name_en.name;
            
         } else {
            //fallback
            this.facility_name = this.facility.name;
            
         }
      } else {
         this.facility_name = fac_name.name;
         
      }

      this.loadCustomTextSetting(this.$i18n.locale, this.facility.id);
      if (this.$route.params.show_shareable_link) this.showLinkModal = true

      if (t(this.$route.query, 'rsv_no').isDefined) this.rsv_no = this.$route.query.rsv_no
      if(this.$route.query.tab && this.$route.query.tab == 1) return; // do nothing when in tablet mode
      if (! this.rsv_no){ //if no reservation means walkins
         this.booking_details.pass = this.$route.query.pass
         this.booking_details.email = this.$route.query.email
      }else {
         await this.getBookingDetails()
      }
      this.trackCompletedPCIForm(this.facility.id);
   },
   methods: {
      closeWindow() {
         this.$router.push("/");
      },
      async loadCustomTextSetting(lang, id) {
         let params;

         if (this.is_custom && this.td == false) {
            params = {
               name: `message-precheckin-form-${lang}`,
               fallback: "message-precheckin-form-en",
            };
         } else {
            params = {
               name: `message-checkin-form-${lang}`,
               fallback: "message-checkin-form-en",
            };
         }

         const response = await this.$lionheart.get("/custom-messages/" + id, {
            params: params,
         });
         this.custom_text_setting = response.data.data.value;
      },

      goToMyPage() {
         if(this.$route.query.tCode != undefined && this.$route.query.tCode) {
            window.location = `${process.env.VUE_APP_MY_PAGE_BASE_URL}/${this.facility.slug_name}/my-page?e=${this.$route.query.email}&p=${this.$route.query.pass}&t=${this.$route.query.tCode}&ref=1`;
         } else {
            window.location = `${process.env.VUE_APP_MY_PAGE_BASE_URL}/${this.facility.slug_name}/my-page?e=${this.$route.query.email}&p=${this.$route.query.pass}&ref=1`;
         }

      },
      async getBookingDetails() {
         const details = await this.$lionheart.get('/v2/facility-bookings/f/get-booking-lock', {
            params: {
               facility_id: this.facility.id,
               reservation_number: this.rsv_no
            }
         })
         .then((res) => {
            if(res.data.length <= 0) return this.booking_detail = [];
            this.booking_details = res.data
         })
      },

      async trackCompletedPCIForm(facilityId) {
         if(t(this.$route.query, 'tCode').isDefined) {
            await this.$lionheart.post("/v2/track/guest-activity", { trackingCode: this.$route.query.tCode, facilityId, action: 'completed_pci_form', metadata: null });
         }
      },
   }
};
</script>