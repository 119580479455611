<template>
	<div class="styled-form">
		<div class="mt-3">
			<div class="wrapper fadeInDown">
				<div class="form-content">
					<form class="p-4" @submit="submitForm" v-if="!sent">
						<div id ="error_msg" v-if="error_msg" class="alert alert-danger" role="alert">{{ error_msg }}</div>
						<h2>{{ $t('account-verification-email-form.title') }}</h2>
						<hr >
						<div class="form-group">
							<h2 class="register_h2">{{ $t('account-verification-email-form.title2') }}</h2>
							<small class="form-text text-muted">{{ $t('account-verification-email-form.title3') }}</small>
						</div>

						<div class="form-group row">
							<label for="exampleInputPassword1" class="col-sm-2 col-form-label">{{ $t('account-verification-email-form.email') }}</label>
							<div class="col-sm-10">
								<input v-if="!confirm" v-model="data.email" v-debounce:500ms="checkEmail" type="email" required class="form-control" :placeholder="$t('account-verification-email-form.email-placeholder')">
								<p v-if="confirm">{{data.email}}</p>
								<small style="font-size: 15px;" v-if="email_exists" class="form-text text-danger">{{ $t('account-verification-email-form.email-exist', {email: data.email}) }}</small>
							</div>
						</div>

						<div class="form-group row" v-if="!confirm">
							<label for="exampleInputPassword1" class="col-sm-2 col-form-label"></label>
							<div class="col-sm-10">
								<input v-model="agree" :value="true" type="checkbox" required class="form-check-input" >
								<label class="form-check-label" for="exampleCheck1">
									<a @click.prevent="showTerms()" style="text-decoration: underline; cursor: pointer;" >{{ $t('account-verification-email-form.terms-label') }}</a>
								</label>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-12" align="center">
								<button v-if="!confirm" :disabled="!agree" @click="submit_btn = 'confirm'" class="btn btn-primary">{{ $t('account-verification-email-form.confirm') }}</button>
								<template v-if="confirm">
									<button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t('account-verification-email-form.submit') }}</button>
									<button @click="submit_btn = 'edit'" class="btn btn-primary ml-3">{{ $t('account-verification-email-form.edit') }}</button>
								</template>
							</div>
						</div>
					</form>

					<div class="p-4" v-if="sent">
						<div class="alert alert-success">{{ $t('account-verification-email-form.email-sent-txt', {email: data.email}) }}</div>
					</div>

					<div id="formFooter">
						<router-link link :to="{name: 'checkin-facility-login'}" class="underlineHover" >{{ $t('account-verification-email-form.return-to-signin') }}</router-link>
					</div>
				</div>
			</div>
		</div>

		<b-modal id="modal-terms" size="lg" scrollable centered :hide-header="true" :hide-footer="true">
			<terms-component :data="terms" />
		</b-modal>
	</div>
</template>

<style scoped>
  .styled-form .form-content,
  #modal-terms > .modal-dialog {
    max-width: 768px;
  }
</style>

<script>
import TermsComponent from '@/modules/checkin-site/components/terms'

export default {
  components: { TermsComponent },
  data() {
    return {
      terms: {},
      agree: false,
      error_msg: '',
      submit_btn: '',
      confirm: false,
      sent: false,
      email_exists: false,
      data: {
      email: '',
      }
    }
  },
  methods: {
    async checkEmail(e) {
      if (this.data.email == '') return

      const email = await this.$lionheart.post('/user/exists', this.data)
      .then(r => this.catcher(r.data,'data.exist', r => r.data))
      .catch(e => this.catcher(e))

      if (email.error.has_error) return this.errorDisplay(email)

      this.email_exists = email.exist
    },
    async submitForm(e) {
      e.preventDefault()
      
      if (this.submit_btn == 'confirm' 
      && this.email_exists == false) {
      return this.confirm = true
      }

      if (this.submit_btn == 'edit') {
      return this.confirm = false
      }

      if (this.submit_btn == 'submit' && this.email_exists == false) {
      //Send email
      const email = await this.$lionheart.post('/user/send-email-verification', this.data)
          .then(r => this.catcher(r.data, 'sent', r => r))
          .catch(e => this.catcher(e))

      if (email.error.has_error) return this.errorDisplay(email)
      if (! email.sent) {
          email.error.msg = 'Email sent failed. Please try again.'
          return this.errorDisplay(email)
      }

      this.sent = true
      }
    },
    showTerms() {
      if (this.$i18n.locale == 'en') this.terms.src = '/f/terms/terms_en.pdf'
      if (this.$i18n.locale == 'ja') this.terms.src = '/f/terms/terms_ja.pdf'

      this.$bvModal.show('modal-terms')
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    }
  }
}
</script>