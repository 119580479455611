<!-- this component can be reusable in other component -->
<template>
   <div style="height: 570px; overflow-y: auto" id="scrollbar" class="w-100">
      <div class="px-2">
         <div class="d-flex flex-column text-dark mb-4" v-for="message in messageLists" :key="message.id">
            <b-card @click="checkMessageDetails(message)" style="cursor: pointer">
               <div class="d-flex flex-row justify-content-between">
                  <div class="d-flex flex-row" style="gap: 30px">
                     <p>{{ formatDateHMSA(message.created_at, "YYYY/MM/DD HH:mm", timeZone) }}</p>
                     <!-- <p class="font-weight-bold">{{ message.full_name }}</p> -->
                     <p class="font-weight-bold">{{ fullName(message.full_name) }}</p>
                  </div>
                  <div class="d-flex flex-row">
                     <p class="mr-2">{{ message.is_seen === 1 ? $t("facility_my_page_message_board.message_seen_text") : "" }}</p>
                     <p><i class="fa fa-comment"></i></p>
                  </div>
               </div>

               <div class="d-flex flex-row">
                  <div class="d-flex flex-row" style="gap: 40px">
                     <p>
                        <span>{{ $t("facility_my_page_message_board.message_reservation_text") }}:</span>
                        <span class="font-weight-bold"> {{ message.pms_reservation_no ?? 'N/A' }}</span>
                     </p>
                     <p>
                        <span> {{ $t("facility_my_page_message_board.message_date_text") }}:</span>
                        <span class="font-weight-bold"> {{ get_date(message, "checkin_date") }}</span>
                        <span v-if="get_date(message, 'checkout_date')" class="font-weight-bold"> - {{ get_date(message, "checkout_date") }}</span>
                     </p>
                     <p>
                        <span> {{ $t("facility_my_page_message_board.message_status_text") }}: </span>
                        <span class="font-weight-bold">
                           {{ $t(`booking_statuses.${message.latest_booking.booking_status.code}`) }} /
                           {{ $t(`payment_statuses.${message.latest_booking.payment_status.code}`) }}
                        </span>
                     </p>
                  </div>
               </div>

               <div>
                  <p class="text-break">{{ message.body }}</p>
               </div>
            </b-card>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import t from "typy";

export default {
   data() {
      return {
         facility: window._app_facility,
         moment,
      };
   },
   computed: {
      ...mapGetters("checkin-facility", {
         messageLists: "gettersFacilityListOfMessages",
      }),
      timeZone() {
         return this.facility.timezone?.value ?? "Asia/Tokyo";
      }
   },
   async mounted() {
      let pms_settings = await this.$lionheart
         .get("/v2/facility-settings", {
            params: {
               name: "pms_settings",
               facility_id: window._app_facility.id,
               paginate: 0,
            },
         })
         .then((res) => res.data.data);

      let params = {
         pms_code: pms_settings[0].value.pms_code,
         facility_id: window._app_facility.id,
      };
      this.getFacilityMessages(params);
   },
   methods: {

      fullName(fullName) {
         return t(fullName,'value').isDefined ? fullName.value : '--' ;
      },
      ...mapActions("checkin-facility", {
         getFacilityMessages: "actionGetFacilityListOfMessages",
      }),

      // GO TO DETAILS OF EACH MESSAGE
      async checkMessageDetails(message) {
         console.log(message, "message");
         let requiredData = {
            // for message list
            p: message.pass,
            rsvNo: message.pms_reservation_no,
            pCode: message.pms_code,

            // for status
            bs: message.latest_booking.booking_status.code,
            ps: message.latest_booking.payment_status.code,

            // for booking list
            em: message.latest_booking.metadata ? message.latest_booking.metadata.email_address : message.email_address,
            e: message.email_address,
            fname: this.fullName(message.full_name),
         };

         this.$router.push({ name: "checkin-facility-dashboard-my-page-message-details", query: { ...requiredData } });
      },
      get_date(data, prop) {
         let date = null;
         if (data.latest_booking.metadata) {
            date = moment(data.latest_booking.metadata[prop], "YYYY-MM-DD").format("MM/DD");
         } else {
            date = data.latest_booking[prop] ? moment(data.latest_booking[prop], "YYYY-MM-DD").format("MM/DD") : null;
         }

         return date;
      },
   },
};
</script>
<style>
/* width */
#scrollbar::-webkit-scrollbar {
   width: 10px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
   background: #f1f1f1;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
   background: #888;
   border-radius: 100px;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
   background: #555;
}
</style>
