<template>
    <div id="dashboard_wrapper">
        <div class="card-body">
            <h4 class="card-title"> {{ $t("account-page-facility-my-page-announcement.title") }}</h4>
            <p> {{ $t("account-page-facility-my-page-announcement.description") }}​</p>

            <hr>

            <div class="row">
                <div class="col-lg-12 mb-3 text-right">
                    <button type="button" class="btn btn-primary" @click="$refs.form.init_form('create')"> {{
                        $t("account-page-facility-my-page-announcement.new") }}</button>
                </div>
                <div class="col-lg-12 mb-3 table-responsive">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col"> {{ $t("account-page-facility-my-page-announcement.created_date") }}</th>
                                <th scope="col"> {{ $t("account-page-facility-my-page-announcement.subject") }}</th>
                                <th scope="col"> {{ $t("account-page-facility-my-page-announcement.valid_dates") }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in data">
                                <td>{{ formatDateHMSA(value.created_at, "YYYY-MM-DD") }}</td>
                                <td>{{ value.subject }}</td>
                                <td>{{ value.from_date }} - {{ value.to_date }}</td>
                                <td class="text-center"> <button type="button" class="btn btn-success btn-sm"
                                        @click="$refs.form.init_form('edit', value.id)"> {{
                                            $t("account-page-facility-my-page-announcement.edit") }}</button></td>
                            </tr>

                        </tbody>
                    </table>
                    <pagination v-model="filter.page" :options="{ theme: 'bootstrap4', texts: { count: '' } }"
                        :per-page="filter.per_page" :records="filter.total" @paginate="init_data" />
                </div>
                <div class="col-lg-12 text-center">
                    <button @click.prevent="$router.push({ name: 'checkin-facility-dashboard-home' })"
                        class="btn btn-danger"> {{ $t("account-page-facility-my-page-announcement.back") }}</button>
                </div>
            </div>

        </div>
        <SelfFrom ref="form"  @click-handler="init_data()"  />
    </div>
</template>

<script>
import SelfFrom from "./form.vue"
export default {
    components: { SelfFrom },
    name: "form",
    data() {
        return {
            data: [],
            filter: {
                facility_id: window._app_facility.id,
                page: 1,
                per_page: 10,
                total: 0
            }
        };
    },
    async mounted() {

        await this.init_data();
    },
    methods: {

        async init_data() {
            let response = await this.$lionheart
                .get("/v2/my-page/announcements", {
                    params: this.filter,
                })
                .then((r) => r.data);
            this.data = response.data;
            this.filter.total = response.meta.total;
        }
    },
};
</script>

