<template>

    <b-modal id="message-sample" centered hide-footer :title='$t("account-page-facility-my-page-message.face_icon_sample")' modal-class="message-sample">
        <div class="row mx-1">
            <div class=" col-lg-12  px-0">
                <div class="px-3 py-3 my-2 rounded border " v-for="(value,key) in messages">
                    <div class="chat-message-left  ">
                        <div>

                            <img v-if="icon" :src="icon" alt="Sharon Lessman"
                                  width="50" height="50" class="rounded-circle mr-1">

                            <img v-else src="../../../../assets/img/my-page/hotel_staff_face_icon.png" alt="Sharon Lessman"
                                  width="50" height="50" class="rounded-circle mr-1">

                        </div>
                        <div class="flex-shrink-1 rounded px-3 ">
                            <div class="font-weight-bold">{{facility.name}}</div>
                            <div class="small">
                                {{
                                    formatDateHMSA(value.created_at, 'YYYY/MM/DD hh:MM A', facility.timezone.value)
                                }}
                            <span
                                class="font-weight-bold" v-if="value.is_seen">{{      $t("account-page-details-my-page-message.seen") }}</span></div>
                            {{ $t("account-page-facility-my-page-message.sample_text") }}
                            <template v-if="value.attachments.length >= 1">
                                <div class="small mt-2 font-weight-bold">{{ $t("account-page-details-my-page-message.attachments") }}</div>
                                <ul class="small pl-4">
                                    <li class="cursor-pointer text-info"
                                        v-for="(at,k) in value.attachments">{{ at.display_name }}
                                    </li>
                                </ul>

                            </template>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "message-sample",
    props: ['icon'],
    data() {
        return {
            facility: window._app_facility,
            messages: [
                {
                    created_at: "2020-05-01 08:59:00",
                    attachments: [],
                    from: {
                        name: "Grand Hotel Acomo"
                    },
                    is_seen: false,

                },
                {
                    created_at: "2020-05-01 09:59:00",
                    attachments: [],
                    from: {
                        name: "Grand Hotel Acomo"
                    },
                    is_seen: false,

                },
                {
                    created_at: "2020-05-01 10:59:00",
                    attachments: [],
                    from: {
                        name: "Grand Hotel Acomo"
                    },
                    is_seen: true,

                },
                {
                    created_at: "2020-05-01 11:59:00",
                    attachments: [
                        {
                            display_name: "sample.png"
                        }
                    ],
                    from: {
                        name: "Grand Hotel Acomo"
                    },
                    is_seen: true,

                },
            ]
        }
    }
}
</script>
<style lang="scss">


.message-sample {


    .chat-message-left {
        display: flex;
        flex-shrink: 0;
        margin-right: auto
    }

    .attachment {
        width: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
    }

}
</style>