<template>
    <b-modal size="md" id="manual-checkin-confirm" centered :no-enforce-focus="true">
        <div class="row text-center">
            <div class="col-lg-12">{{ $t("visit-information-form.manual-check-in-confirm_title") }}</div>
            <div class="col-lg-12">{{ $t("visit-information-form.manual-check-in-confirm_subtitle") }}</div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-12 table-responsive">
                <table class="table table-bordered">
                    <tr>
                        <th>{{ $t("visit-information-form.manual-check-in-full-name") }}</th>
                        <th>{{ $t("visit-information-form.manual-check-in-pre-check-in-date") }}</th>
                    </tr>
                    <tr>
                        <td>{{data.full_name }}</td>
                        <td>{{ formMethods.formatDateHMA(data.pre_checkin_date)}}</td>
                    </tr>
                </table>
            </div>
        </div>
        
        <div class="row mt-3" v-if="paymentOptionsShow()">            
            <div class="col-lg-12 text-center">
                {{ $t("visit-information-form.manual-check-in-not-paid-note") }}
            </div>
            <div class="col-lg-12 mt-2">
                <ul class="px-4">
                    <li>
                        <input class="form-check-input" type="radio" v-model="payment_action" name="payment" :value="'payment_link'" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                            {{ $t("visit-information-form.manual-check-in-not-send-payment-link") }}
                        </label>
                        <br>
                        <small v-if="payment_action == 'payment_link'" class="text-danger">        {{ $t("visit-information-form.manual-check-in-not-send-payment-link-note") }}</small>
                    </li>

                    <li class="mt-2">
                        <input class="form-check-input" type="radio" v-model="payment_action" name="payment"  :value="'payment_hotel'" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                            {{ $t("visit-information-form.manual-check-in-not-pay-hotel") }}
                        </label>
                        <br><small  v-if="payment_action == 'payment_hotel'" class="text-danger">                {{ $t("visit-information-form.manual-check-in-not-pay-hotel-note") }}</small>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-6">
                <button class="btn btn-secondary btn-w-md btn-block" @click="hideMe()">
                    {{ $t('confirmation_modal.cancel') }}
                </button>
            </div>
            <div class="col-lg-6">
                <button class="btn btn-primary btn-w-md btn-block" @click="confirm()" :disabled="isConfirmDisabled">
                    {{ $t('confirmation_modal.confirm') }}
                </button>
            </div>
        </div>
        
        <template #modal-footer>
            <div class="mx-n1">


            </div>
        </template>
    </b-modal>
</template>

<script>
import {
    main_form_methods as formMethods,
} from "@/modules/checkin-facility/utils/mockup";
import {payment_status, paid_payment_status} from "../../../../utils/mockup";
export default 
{
    props: {
        id: {type: String, default: 'manual-checkin-confirm'},
        message: {type: String},
        cancel: {type: String},
        okay: {type: String},
    },
    data(){
        return {
            formMethods,
            data: {
               booking_details: {}
            },
            searcher:{},
            settingsData:{},
            fetchOptions:{},
            payment_action: "paid",
            payment_statuses: payment_status,
            isCheckoutPayment: false,
            isWaiveOn: false,
            facility:{},
            payment_timing_opts: {
                facility_id: 0,
                name: 'payment_timing_opts',
                value: {
                    payHotelVisibility: true,
                    paylaterVisibility: false,
                    waivePay: false,
                }
            },
            payment_option: {
                facility_id: 0,
                name: 'payment_option',
                value: {
                    checkin: 1,
                    checkout: 0,
                    deposit: 0,
                }
            }
        }
    },
    methods: {
        paymentOptionsShow() {

            let showOptions = false;
            if (this.isWaiveOn === false && this.isNotPaid === true && this.isCheckoutPayment === false) {
                showOptions = true;
            } 

            return showOptions
 
        },      
        
        async fetchPaymentTimingOpts() {
            this.facility = window._app_facility
            this.payment_timing_opts.facility_id = this.facility.id
            this.searcher = {
                name: this.payment_timing_opts.name,
                facility_id: this.payment_timing_opts.facility_id,
                paginate: false
            }
            this.settingsData = {
                ...this.payment_timing_opts
            }

            await this.fetchData();

            if(this.fetchOptions.name == 'payment_timing_opts') {
                this.isWaiveOn = this.fetchOptions.value.waivePay;
            };
        },  
        
        async fetchPaymentTiming() {
            this.facility = window._app_facility
            this.payment_option.facility_id = this.facility.id
            this.searcher = {
                name: this.payment_option.name,
                facility_id: this.payment_option.facility_id,
                paginate: false
            }
            this.settingsData = {
                ...this.payment_option
            }

            await this.fetchData();

            if(this.fetchOptions.name == 'payment_option') {
                this.isCheckoutPayment = Boolean(this.fetchOptions.value.checkout);
            };
        },  

        async fetchData (){
            this.fetchOptions = await this.$lionheart.post('/facility-setting/list', {
                ...this.searcher
            })
            .then(r => this.catcher(r, 'data.total', r => r.data.total > 0 ? r.data.data[0] : r.data))
            .catch(e => this.catcher(e));

            if(this.fetchOptions.error.has_error) return; // Silent API error catch

            if(this.fetchOptions.name == undefined) { // If Payment options are missing, initialize default values
                const data = {
                    ...this.settingsData
                }
                  
                const res = await this.$lionheart.post('/facility-setting/store', {...data})
                .then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
                .catch(e => this.catcher(e))

                this.success = res.error.has_error == false;
            };
        },
        
        confirm() {
            this.$bvModal.hide('manual-checkin-confirm');
            this.$emit('click-handler', this.payment_action);
        },
        async init(data){
            this.data = data;
            await this.fetchPaymentTimingOpts();
            await this.fetchPaymentTiming();
            this.paymentOptionsShow();
            this.$bvModal.show('manual-checkin-confirm');
        },
        hideMe() {
            this.$bvModal.hide(this.id);
            this.payment_action = "paid";
        }

    },
    computed: {
        isNotPaid() {
            let paid_statuses = [payment_status.pay_at_reception];
            
            return paid_statuses.concat(paid_payment_status).includes(this.data.booking_details.payment_status_id) === false;
        },
        isConfirmDisabled() {
            if (this.isNotPaid && this.isCheckoutPayment === false && (this.isWaiveOn === false || this.isWaiveOn === null)) {
                return ['paid'].includes(this.payment_action) === true;
            }

            return false;
        }
    }
}
</script>