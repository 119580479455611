<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-2">
        <div class="d-flex">
          <div class="mr-auto align-self-center">
            <h6 class="m-0 font-weight-bold text-primary">
              {{ $t("payment_details.payment_details_title") }}
            </h6>
          </div>
          <div>
            <button
              type="button"
              @click.prevent="$bvModal.show('modal-payment-additional-modal')"
              class="btn btn-primary"
              :disabled="
                payment_details.payment_status.id !=
                payment_statuses.credit_card_as_deposit
              "
            >
              {{ $t("payment_details.cad_edit_btn") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <template v-if="!payment_details.payment_status.id">
          <div class="row my-4 text-center">
            <div class="col-sm-12">
              {{ $t("payment_details.paid_description") }}
            </div>
          </div>
        </template>
        <template v-if="payment_details.payment_status.id">
          <div class="row">
            <div for="exampleInputEmail1" class="col-sm-4">
              {{ $t("payment_details.paid_date_and_time") }}:
            </div>
            <div class="col-sm-8">
              {{
                [payment_statuses.paid_authorized, payment_statuses.paid_captured].includes(payment_details.payment_status.id) ?
                convertTimeZone(payment_details.payment_date) :
                '---'
              }}
            </div>
          </div>
          <div class="row">
            <div for="exampleInputEmail1" class="col-sm-4">
              {{ $t("payment_details.amount") }}:
            </div>
            <div class="col-sm-8">
              {{
                [payment_statuses.paid_authorized, payment_statuses.paid_captured].includes(payment_details.payment_status.id) ?
                `${payment_details.total_price} ${currency}` :
                '---'
              }}
            </div>
          </div>
          <div class="row">
            <div for="exampleInputEmail1" class="col-sm-4">
              {{ $t("payment_details.payment_status") }}:
            </div>
            <div class="col-sm-8">
              {{
                getPaymentStatus(
                  $t(`payment_statuses.${payment_details.payment_status.code}`),
                  payment_details.pms_reservation_no
                )
              }}
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-4">
              <b-button
              @click="$bvModal.show('authorize-modal')"
              v-if="payment_details.payment_status.id == payment_statuses.credit_card_as_deposit"
              :disabled="grand_total == 0"
              variant="success"
              block>
                {{ $t("payment_details.cad_authorize_btn") }}
              </b-button>
            </div>
            <div class="col-sm-4 offset-sm-4 d-flex justify-content-end">
              <template v-if="[payment_statuses.paid_authorized, payment_statuses.paid_captured].includes(payment_details.payment_status.id)">
                <button
                  type="button"
                  :disabled="
                    payment_details.payment_status.id !=
                    payment_statuses.paid_authorized
                  "
                  class="btn px-5"
                  :class="{
                    'btn-primary':
                      payment_details.payment_status.id ==
                      payment_statuses.paid_authorized,
                    'btn-dark':
                      payment_details.payment_status.id ==
                      payment_statuses.paid_captured,
                  }"
                  @click="$bvModal.show('modal-confirmation')"
                >
                  {{ $t("payment_details.refund") }}
                </button>
              </template>

              <b-button
              @click="$bvModal.show('discard-modal')"
              v-if="payment_details.payment_status.id == payment_statuses.credit_card_as_deposit"
              :disabled="grand_total > 0"
              variant="danger"
              class="px-5">
                {{ $t("payment_details.cad_discard_btn") }}
              </b-button>
            </div>
          </div>

        </template>

        <template
          v-if="payment_details.payment_status.id == payment_statuses.paid"
        >
          <div class="row my-4 text-center">
            <div class="col-sm-12">
              {{ $t("payment_details.paid_description") }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <b-modal
      size="md"
      id="modal-confirmation"
      centered
      title="BootstrapVue"
      hide-footer
      hide-header
      no-close-on-esc
      hide-header-close
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col-md-12 my-5 text-center">
          <span class="h4">{{ $t("payment_details.are_you_sure") }}</span>
        </div>
        <div class="col-md-6">
          <button
            @click="refund"
            type="button"
            class="btn btn-primary btn-block"
            data-dismiss="modal"
          >
            {{ $t("payment_details.confirm") }}
          </button>
        </div>
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-secondary btn-block"
            @click="$bvModal.hide('modal-confirmation')"
          >
            {{ $t("payment_details.cancel") }}
          </button>
        </div>
      </div>
    </b-modal>

    <payment-additional-modal
      id="modal-payment-additional-modal"
      :visitor_log_id="$route.params.id"
      :currency="currency"
      @grandTotal="grand_total = $event"
    />

    <confirmation-modal
    @click-handler="authorizeAmount"
    :id="'authorize-modal'"
    :title="$t('payment_details.cad_authorize_note')"/>

    <confirmation-modal
    :id="'discard-modal'"
    @click-handler="discardAmount"
    :title="$t('payment_details.cad_discard_note')"/>

    <b-modal
    size="md"
    id="stripe-error-modal"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop>
        <div class="row">
            <div class="col-md-12 my-5 text-center">
                <span class="h4" v-html="$t('payment_details.cad_error_note')"></span>
            </div>
            <div class="col-md-12 text-center">
                <button type="button" class="btn btn-danger" @click="$bvModal.hide('stripe-error-modal')">
                    {{ $t('confirmation_modal.cancel') }}
                </button>
            </div>
        </div>
    </b-modal>
  
  </div>
</template>

<script>
import moment from "moment-timezone";

import { payment_status } from "../../../utils/mockup";
import { getPaymentStatus } from "../../../utils/helpers";
import paymentAdditionalModal from "./payment_additional/paymentAdditionalModal.vue";
import ConfirmationModal from "./confirmation-modal.component";

export default {
  components: {
    paymentAdditionalModal,
    ConfirmationModal
  },
  data() {
    return {
      payment_details: {
        payment_status: {},
      },
      payment_statuses: payment_status,
      facility: {},
      currency: "",
      grand_total: 0
    };
  },
  async mounted() {
    this.facility = window._app_facility;

    await this.getPayment();
  },
  methods: {
    async refund() {
      let result = await this.$lionheart.post(
        "/payment/stripe/cancel-payment-intent",
        {
          transaction_id: this.payment_details.transaction_id,
          stripe_account: this.payment_details.payment_metadata.stripe_account,
          pms_reservation_no: this.payment_details.pms_reservation_no,
          facility_id: this.facility.id,
        }
      );
      if (result.data.success) {
        this.getPayment();
        this.$bvModal.hide("modal-confirmation");
      }
    },
    async createBookingLog(data) {
      return await this.$lionheart.post("/payment/pms", data);
    },
    async getPayment() {
      const payment_details = await this.$lionheart
        .get("/payment/pms", {
          params: { facility_visitor_log_id: this.$route.params.id },
        })
        .then((r) => this.catcher(r.data, "data", (r) => r))
        .catch((e) => this.catcher(e));

      if (payment_details.status) {
        this.payment_details = payment_details.data;
      }

      if (payment_details.data.payment_metadata) {
        this.currency = payment_details.data.payment_metadata.hasOwnProperty(
          "currency"
        )
          ? payment_details.data.payment_metadata.currency
          : "JPY";
      }
    },
    convertTimeZone(date_time) {
      var timezone = moment.utc(date_time).tz(moment.tz.guess());
      return timezone.format("YYYY-MM-DD hh:mm A");
    },
    getPaymentStatus,
    async authorizeAmount() {
      const response = await this.$lionheart
        .post(`payment/pms/manual-payment-authorize`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.$route.params.id,
        })
        .then((r) => this.catcher(r, "data", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (!response.error.has_error) {
        this.$bvModal.hide('authorize-modal')
        this.getPayment()
      } else {
        this.$bvModal.hide('authorize-modal')
        this.$bvModal.show('stripe-error-modal')
      }
    },
    async discardAmount() {
      const response = await this.$lionheart
        .post(`payment/pms/discard-payment`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.$route.params.id,
        })
        .then((r) => this.catcher(r, "data", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (!response.error.has_error) {
        this.$bvModal.hide('discard-modal')
        this.getPayment()
      } else {
        this.$bvModal.hide('discard-modal')
        this.$bvModal.show('stripe-error-modal')
      }
    },
  },
};
</script>