<template>
  <b-modal
    body-class="p-0"
    size="lg"
    :id="id"
    centered
    :title="$t('payment_details.cad_edit_title')"
    hide-footer
  >
    <form @submit="submitForm" v-if="booking">
      <div slot="modal-body" class="modal-body pt-3">
        <div
          v-if="success"
          class="alert alert-success"
          role="alert"
          id="success_msg"
        >
          {{ $t("account_page_details.success_msg") }}
        </div>
        <div class="d-flex">
          <h5>{{ $t("payment_details.cad_current_charge_title") }}</h5>
        </div>
        <hr style="marign-top: 0" />
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_room") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['room_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_meal") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['meal_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_other") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['other_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_service") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['service_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_vat") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['vat_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_city_tax") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['city_tax']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_deduct_amount") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['deduct_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            {{ $t("payment_details.cad_net_charge") }}
          </label>
          <div class="col">
            <input
              type="text"
              readonly
              v-model="booking['parsed']['net_charge']"
              class="form-control"
            />
          </div>
        </div>
        <div class="d-flex mt-5">
          <h5>{{ $t("payment_details.cad_additional_charge_title") }}</h5>
        </div>
        <div class="d-flex">
          <small>({{ $t("payment_details.cad_input_charge_note") }})</small>
        </div>
        <hr style="marign-top: 0" />
        <div class="form-group row">
          <label class="col col-form-label">
            *{{ $t("payment_details.cad_charges") }}
          </label>
          <div class="col">
            <input
              required
              type="number"
              min="0"
              step=".01"
              v-model="paymentAdditionals[0].total_price"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            *{{ $t("payment_details.cad_vat") }}
          </label>
          <div class="col">
            <input
              required
              type="number"
              min="0"
              step=".01"
              v-model="paymentAdditionals[0].metadata['vat_amount']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            *{{ $t("payment_details.cad_note_to_guest") }}</label
          >
          <div class="col">
            <textarea
              required
              type="text"
              v-model="paymentAdditionals[0].metadata['notes']"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col col-form-label">
            *{{ $t("payment_details.cad_grand_total") }}
          </label>
          <div class="col">
            <input
              readonly
              type="text"
              v-model="grandTotal"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <!-- custom buttons -->

      <div class="d-flex justify-content-center modal-footer">
        <button @click="$bvModal.hide(id)" type="button" class="btn btn-danger">
          {{ $t("payment_details.cad_back_btn") }}
        </button>
        <button class="btn btn-primary mr-3">
          {{ $t("payment_details.cad_confirm_btn") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<style>
</style>
<script>
import { mapState } from "vuex";
export default {
  props: {
    id: {
      type: Number | String,
      required: true,
    },
    visitor_log_id: {
      type: Number | String,
      required: true,
    },
    currency: {
      type: String,
    },
  },
  data() {
    return {
      booking: {
        parsed: {
          net_charge: 0,
        },
      },
      paymentAdditionals: [
        {
          id: null,
          facility_visitor_log_id: 0,
          charge_type: "Other",
          pass: "",
          total_price: 0,
          metadata: {
            vat_amount: 0,
          },
        },
      ],
      email: "",
      success: false,
    };
  },
  computed: {
    ...mapState("checkin-facility", ["facility"]),
    grandTotal() {
      const charges = this.paymentAdditionals
        .map((e) => parseFloat(e.metadata.vat_amount) + parseFloat(e.total_price))
        .reduce((prev, next) => prev + next);

      const total = charges + this.booking["parsed"]["net_charge"];

      if (Number.isNaN(total) == false) return total;

      return this.booking["parsed"]["net_charge"];
    },
  },
  mounted() {
    this.init(); // On mount only

    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (this.id == modalId) this.init();
    });

    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (this.id == modalId) {
        this.init();
      }
    });
  },
  methods: {
    async init() {
      this.success = false;
      this.paymentAdditionals[0].facility_visitor_log_id = this.visitor_log_id;

      const booking = await this.$lionheart
        .post(`facility-booking/f/list`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.visitor_log_id,
        })
        .then((r) => this.catcher(r, "data.total", (r) => r.data))
        .catch((e) => this.catcher(e));

      this.booking = booking.data;

      if (this.booking == null) return;

      this.paymentAdditionals[0].pass = this.booking.pass;

      const paymentAdditionals = await this.$lionheart
        .get(`payment-additional`, {
          params: {
            facility_id: this.facility.id,
            log_id: this.visitor_log_id,
          },
        })
        .then((r) => this.catcher(r, "data.total", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (paymentAdditionals.total == 0) return;

      this.paymentAdditionals = paymentAdditionals.data;
    },
    async submitForm(e) {
      e.preventDefault();

      const response = await this.$lionheart
        .post(`payment-additional`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.visitor_log_id,
          charge_type: this.paymentAdditionals[0].charge_type,
          vat: this.paymentAdditionals[0].metadata["vat_amount"],
          currency: this.currency,
          total_price: this.paymentAdditionals[0].total_price,
          notes: this.paymentAdditionals[0].metadata["notes"],
          pass: this.paymentAdditionals[0].pass,
          id: this.paymentAdditionals[0].id,
        })
        .then((r) => this.catcher(r, "data.total", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (response.total > 0) {
        this.paymentAdditionals[0].id = response.data[0].id;
        this.success = true;
        setTimeout(
          () => document.getElementById("success_msg").scrollIntoView(),
          500
        );
      }
    },
  },
   watch: {
    grandTotal: function (newVal, oldVal) {
      this.$emit("grandTotal", newVal);
    }
   }
};
</script>