import PrintDetail from "./components/detail/detail.vue";
import Downloads from "./downloads/downloads.vue";
import Filter from "./filter/index.vue";
import Faq from "./help/faq.vue";
import StartGuide from "./help/start-guide.vue";
import Home from "./home/home.vue";
import MrSetting from "./mr-setting/index.vue";
import PreEmail from "./pre-email/form.component.vue";
import Qrcode from "./qrcode/qrcode.vue";
import Questions from "./questions/question.vue";
import QcheckoutInSetting from "./quick-checkout-in-setting/index.vue";
// import QServiceSetting from "./quick-service-setting/index.vue";
import MessageSetting from "./setting/setting-mail.vue";
import Setting from "./setting/setting.vue";
import TAC from "./terms/form.component.vue";
import VisitorDetail from "./visitor/detail.vue";
import VisitorDetail2 from "./visitor/v2/detail.vue";
import Visitors from "./visitor/visitor.vue";
// Notif Control
import NotifControlPrecheckinSchedule from "./notif-control/child-pages/pre.checkin.schedule.vue";
import NotifControlOtherSettings from "./notif-control/child-pages/other.settings.vue";
import NotifControl from "./notif-control/index.vue";
// Form Control
import FormControl from "./form-control/index.vue";
import MessageForm from "./message-form/form.component.vue";
import PreCheckinForm from "./precheckin-form/form.component.vue";
// checkin filter form
import CheckinFilterForm from "./check-in-filter-form/form.component.vue";
import CheckoutForm from "./checkout-form/form.component.vue";

import Payment from "./payment/payment.vue";
import Traction from "./traction/index.vue";
import VisitorPaymentPayout from "./visitor-payment/payout/index.vue";
import VisitorPaymentSettlement from "./visitor-payment/settlement/index.vue";

// my page
import MyPageAnnouncement from "./my-page/announcement/list";
import MyPageCategory from "./my-page/category/form";
import MyPage from "./my-page/index";
import MyPageSetting from "./my-page/setting/form";
import MyPageTemplate from "./my-page/template/form";
// MY PAGE MESSAGE BOARD
import MyPageMessageDetails from "./message-board/details.vue";
import MyPageMessageBoard from "./message-board/index";

import ReservationList from "@/modules/checkin-facility/pages/dashboard/reserve-list/index.vue";

export default [{
      path: `home`,
      name: "checkin-facility-dashboard-home",
      component: Visitors
   },
   {
      path: `reservation-list`,
      name: "checkin-facility-dashboard-reservation-list",
      component: ReservationList
   },
   {
      path: `faq`,
      name: "checkin-facility-dashboard-faq",
      component: Faq
   },
   {
      path: `start-guide`,
      name: "checkin-facility-dashboard-start-guide",
      component: StartGuide
   },
   {
      path: `setting`,
      name: "checkin-facility-dashboard-setting",
      component: Setting,
   },
   {
      path: `visitors`,
      name: "checkin-facility-dashboard-visitor",
      component: Visitors,
   },
   {
      path: `tac`,
      name: "checkin-facility-dashboard-toc",
      component: TAC
   },
   {
      path: `filter`,
      name: "checkin-facility-dashboard-filter",
      component: Filter,
   },
   {
      path: `mr-setting`,
      name: "checkin-facility-dashboard-mr-setting",
      component: MrSetting,
   },
   {
      path: `quick-checkout-in-setting`,
      name: "checkin-facility-dashboard-quick-checkout-in-setting",
      component: QcheckoutInSetting,
   },
   // {
   //    path: `quick-service-setting`,
   //    name: "checkin-facility-dashboard-quick-setting",
   //    component: QServiceSetting,
   // },
   {
      path: `downloads`,
      name: "checkin-facility-dashboard-downloads",
      component: Downloads,
   },
   {
      path: `questions`,
      name: "checkin-facility-dashboard-questions",
      component: Questions,
   },
   {
      path: `visitor/:id/detail`,
      name: "checkin-facility-dashboard-visitor-detail",
      component: VisitorDetail,
   },
   {
      path: `visitor/:id/v2/detail`,
      name: "checkin-facility-dashboard-visitor-v2-detail",
      component: VisitorDetail2,
   },
   {
      path: `qrcode`,
      name: "checkin-facility-dashboard-qrcode",
      component: Qrcode,
      meta: {
         show: false,
      },
   },
   {
      path: `print/:id/detail/:lang`,
      name: "checkin-facility-dashboard-print-detail",
      component: PrintDetail,
      meta: {
         show: false,
      },
   },
   // Email Control
   {
      path: `notif-control`,
      name: "checkin-facility-dashboard-notif-control",
      component: NotifControl,
   },
   {
      path: `schedule-pre-checkin-notice`,
      name: "checkin-facility-dashboard-schedule-pre-checkin-notice",
      component: NotifControlPrecheckinSchedule,
   },
   {
      path: `other-settings`,
      name: "checkin-facility-dashboard-other-settings",
      component: NotifControlOtherSettings,
   },
   {
      path: `pre-email`,
      name: "checkin-facility-dashboard-pre-email",
      component: PreEmail,
   },
   {
      path: `message`,
      name: "checkin-facility-dashboard-message",
      component: MessageSetting,
   },
   // Form Control
   {
      path: `form-control`,
      name: "checkin-facility-dashboard-form-control",
      component: FormControl,
   },
   {
      path: `precheckin-message-form`,
      name: "checkin-facility-dashboard-precheckin-message-form",
      component: PreCheckinForm,
   },
   {
      path: `message-in-form`,
      name: "checkin-facility-dashboard-message-form",
      component: MessageForm,
   },
   //  checkin filter form
   {
      path: `checkin-filter-message-form`,
      name: "checkin-facility-dashboard-checkin-filter-message-form",
      component: CheckinFilterForm,
   },
   {
      path: `checkout-message-form`,
      name: "checkin-facility-dashboard-checkout-message-form",
      component: CheckoutForm,
   },
   // Payment
   {
      path: `payment`,
      name: "checkin-facility-dashboard-payment",
      component: Payment,
   },
   {
      path: `visitor-payments/settlement`,
      name: "checkin-facility-dashboard-visitor-payments-settlement",
      component: VisitorPaymentSettlement,
   },
   {
      path: `visitor-payments/payout`,
      name: "checkin-facility-dashboard-visitor-payments-payout",
      component: VisitorPaymentPayout,
   },
   {
      path: `traction`,
      name: "checkin-facility-dashboard-visitor-traction",
      component: Traction,
   },
   // MyPage
   {
      path: `my-page`,
      name: "checkin-facility-dashboard-my-page",
      component: MyPage,
   },
   {
      path: `my-page/setting`,
      name: "checkin-facility-dashboard-my-page-setting",
      component: MyPageSetting,
   },
   {
      path: `my-page/templates`,
      name: "checkin-facility-dashboard-my-page-template",
      component: MyPageTemplate,
   },
   {
      path: `my-page/categories`,
      name: "checkin-facility-dashboard-my-page-categories",
      component: MyPageCategory,
   },
   {
      path: `my-page/announcements`,
      name: "checkin-facility-dashboard-my-page-announcements",
      component: MyPageAnnouncement,
   },
   //  MY PAGE MESSAGE BOARD
   {
      path: `message-board`,
      name: "checkin-facility-dashboard-my-page-message-board-index",
      component: MyPageMessageBoard,
   },
   {
      path: `message-details`,
      name: "checkin-facility-dashboard-my-page-message-details",
      component: MyPageMessageDetails,
   },
];