<template>

    <component ref="cardPayment" 
        :is="currentCardPaymentComponent" 
        :facility="facility" 
        :booking_details="booking_details" 
        :confirm="confirm" 
        :currency="currency" 
        :touchdown="touchdown" 
        :page="page"
        :settings="configs"
    >
    </component>

</template>

<script>
import StripePayment from './stripePayment.vue'
import FincodePayment from './fincodePayment.vue'

export default {
  name: "GatewayPayment",
  components:  {
    StripePayment,
    FincodePayment,
  },
  props: [
    "booking_details",
    "facility",
    "confirm",
    "page",
    "currency",
    "touchdown"
  ],
  data() {
    return {
      currentCardPaymentComponent: "",
      configs: {}
    };
  },
  async mounted() {
    await this.loadPaymentGateway();
  },
  methods: {
    async loadPaymentGateway() {

      let cardGateway = await this.$lionheart.get("/v2/facility-settings/card-gateway", {
          params: {
            facility_id: this.facility.id
          }
      });

      let gateway = cardGateway.data?.data.gateway;
      switch(gateway) {
        case 'stripe':
          this.currentCardPaymentComponent = 'StripePayment'
          break;
        case 'fincode':
          this.currentCardPaymentComponent = 'FincodePayment'
          this.configs = cardGateway.data?.data;
          break;
        default:
          this.currentCardPaymentComponent = 'StripePayment'
      }
    },
    doCall() {
      return this.$refs.cardPayment;
    }
  },
};
</script>
