<template>
  <div class="mt-n2">
    <!-- Payment Details -->
    <div class="panel-header">
      <b>{{ $t("payment_details.payment_details_title") }}</b>
    </div>

    <div class="panel-body">
      <table class="table table-sm table-bordered w-100 mb-0">

        <template>
          <tr>
            <td>{{ $t("payment_details.paid_date_and_time") }}</td>
            <td> -- </td>
          </tr>
          <tr>
            <td>{{ $t("payment_details.amount") }}</td>
            <td>
              {{ isPaid() ? '--' : `${paymentDetails.data.total_price} JPY` }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("payment_details.payment_status") }}</td>
            <td>{{ paymentStatusTextComputed || '--' }}</td>
          </tr>
        </template>
        
      
      </table>
    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";
import t from "typy";
import _ from "lodash";
import {
  paid_payment_status as paidStatuses,
} from "../../../../utils/mockup";

export default {
   props: {
      logId: {
         type: Number,
         default: 0
      },
      currentTab: {
         type: String,
         default: 'payment'
      },
      mode: {
         type: String,
         default: ''
      },
      pmsReservationNo: {
         type: String,
         default: ''
      },
      visitorPass: {
         type: String,
         default: ''
      },
   },
   computed: {
      ...mapState("checkin-facility", ["facility"]),
      paymentStatusTextComputed() {
         if (this.paymentDetails.data.pms_reservation_no !== null) {
            return this.$t(`payment_statuses.${this.paymentDetails.data.payment_status.code}`);
         }
         return false;
      },
      
   },
   data() {
      return {
         t,
         paymentDetails: {
            payment_status: {},
         },
      }
   },
   mounted() {
      this.getPaymentDetails();
   },
   methods: {
      async getPaymentDetails() {
         const facility = window._app_facility;
         const paymentDetailsApi = await this.$lionheart
         .post('/v2/facility-booking/reservation-list', {
            facility_id:        facility.id,
            pms_reservation_no: this.pmsReservationNo,
            pass:               this.visitorPass,
            mode:               this.mode,
         })
         .then(({data}) => {
            if(!t(data,'data[0]').isDefined) {
               return { data: [], total: 0 }
            }

            return {
               data: data.data[0],
               total: data.data.length
            }
         })

         this.paymentDetails = await paymentDetailsApi;
      },
      isPaid() {
         const id = t(this.paymentDetails,'data.payment_status_id').isDefined ? this.paymentDetails.data.payment_status_id  : 1 ;
         return paidStatuses.includes(id);
      },
   },
   watch: {
      async currentTab(tabname) {
         if (tabname == 'payment') {
            // this.bookingStatusId = this.visitorLog.booking_details.booking_status.id;
            this.getPaymentDetails();
         }
      },
      async logId(v) {
         this.getPaymentDetails();
      },
   }
};
</script>