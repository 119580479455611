<template>
  <div >
    <!-- DataTales Example -->
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.contact') }}</h6>
      </div>
      <div class="card-body">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.last_name') }}:</div>
              <div class="col-sm-8">{{vl.visitor.last_name || '--'}}</div>
            </div>

            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.first_name') }}:</div>
              <div class="col-sm-8">{{vl.visitor.first_name || '--'}}</div>
            </div>

            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.phone') }}:</div>
              <div class="col-sm-8">{{vl.visitor.phone_number || '--'}}</div>
            </div>

            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.address') }}:</div>
              <div class="col-sm-8">{{vl.visitor.address || '--'}}</div>
            </div>

            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.email') }}:</div>
              <div class="col-sm-8">{{vl.visitor.user.email}}</div>
            </div>

            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.nationality') }}:</div>
              <div class="col-sm-8">{{ getNationality(vl.visitor.nationality) || '--' }}</div>
            </div>

            <template v-for="vo in vlo.contact">
            <div class="row" v-if="vo.facility_questionnaire[0].system_name == 'gov_id'">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(vo.facility_questionnaire[0]) }}:</div>
              <div class="col-sm-8"><load-image :url="vo.value" /></div>
            </div>

            <div class="row" v-if="!['gov_id', 'first_name', 'last_name', 'phone', 'address', 'nationality'].includes(vo.facility_questionnaire[0].system_name)">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(vo.facility_questionnaire[0]) }}:</div>
              <div class="col-sm-8">{{ vo.value }}</div>
            </div>
            </template>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.sanitation') }}</h6>
      </div>
      <div class="card-body">
            <template v-for="vo in vlo.sanitation">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(vo.facility_questionnaire[0]) }}:</div>
              <div class="col-sm-8">{{ vo.value }}</div>
            </div>
            </template>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.visit-info') }}</h6>
      </div>
      <div class="card-body">
            <template v-for="vo in vlo.visit_info">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(vo.facility_questionnaire[0]) }}:</div>
              <div class="col-sm-8">{{ vo.value }}</div>
            </div>
            </template>
      </div>
    </div>

    <template v-if="tp(vlo, 'optional').isDefined">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.other') }}</h6>
      </div>
      <div class="card-body">
            <template v-for="vo in vlo.optional">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4" v-if="vo.facility_questionnaire[0].system_name == 'terms_conditions'">{{ $t('visit-information-form.terms_and_condition') }}:</div>
                <div for="exampleInputEmail1" class="col-sm-4" v-if="vo.facility_questionnaire[0].system_name != 'terms_conditions'">{{ vo.facility_questionnaire[0].label }}:</div>
              <div class="col-sm-8">{{ vo.value }}</div>
            </div>
            </template>
      </div>
    </div>
    </template>

    <div class="card shadow mb-4" v-if="vlc.length > 0">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.other-companion-note') }}</h6>
      </div>
      <div class="card-body">
        <template v-for="c in vlc">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.contact') }}</h6>
          </div>
          <div class="card-body">
            <template v-for="co in c.fco.contact">
            <div class="row" v-if="co.facility_questionnaire.system_name == 'gov_id'">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(co.facility_questionnaire) }}:</div>
              <div class="col-sm-8"><load-image :url="co.value" /></div>
            </div>
            <div class="row" v-if="!['gov_id'].includes(co.facility_questionnaire.system_name)">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(co.facility_questionnaire) }}:</div>
              <div class="col-sm-8">{{ co.value }}</div>
            </div>
            </template>
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ $t('visit-information-form.other-companion-nationality') }}:</div>
              <div class="col-sm-8">{{ getNationality(c.nationality) || '--' }}</div>
            </div>
          </div>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.sanitation') }}</h6>
          </div>
          <div class="card-body">
            <template v-for="co in c.fco.sanitation">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(co.facility_questionnaire) }}:</div>
              <div class="col-sm-8">{{ co.value }}</div>
            </div>
            </template>
          </div>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.visit-info') }}</h6>
          </div>
          <div class="card-body">
            <template v-for="co in c.fco.visit_info">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ getLabel(co.facility_questionnaire) }}:</div>
              <div class="col-sm-8">{{ co.value }}</div>
            </div>
            </template>
          </div>
          <template v-if="tp(c.fco, 'optional').isDefined">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">{{ $t('visit-information-form.other') }}</h6>
          </div>
          <div class="card-body">
            <template v-for="co in c.fco.optional">
            <div class="row">
              <div for="exampleInputEmail1" class="col-sm-4">{{ co.facility_questionnaire.label }}:</div>
              <div class="col-sm-8">{{ co.value }}</div>
            </div>
            </template>
          </div>
          </template>
        <hr />
        </template>
      </div>
    </div>


  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import t from 'typy'
  import _ from 'lodash'; 
  import moment from 'moment-timezone'
  import { core_questions, nationalities } from '@/modules/checkin-facility/utils/mockup'

  import loadImage from '../loadImage'

  export default {
    components: {
      loadImage
    },
    data() {
      return {
        tp: t,
        vl: {
          visitor: {
            user: {}
          }
        },
        vlo: {
          data: []
        },
        vlc: {
          data: []
        }
      }
    },
    computed: {
        ...mapState('checkin-facility', ['imageLoaderCounter'])
    },
    watch: {
      imageLoaderCounter(newValue, oldValue) {
        if (newValue == 0) {
          setTimeout(() => window.print(), 500)
        }
      }
    },
    async mounted() {
      this.$i18n.locale = this.$route.params.lang

      const facility = window._app_facility

      const vl = await this.$lionheart.post('/log/get/facility-visitor', {
        paginate: false,
        id: this.$route.params.id,
        facility_id: facility.id 
      }).then(r => this.catcher(r.data, 'data[0]', r => r.data[0])).catch(e => this.catcher(e))

      if (vl.error.has_error) return

      this.vl = vl
      this.vlo = await this.$lionheart.post('/log/get/facility-visitor-other-form', {
        paginate: false,
        facility_visitor_log_id: this.vl.id,
        facility_id: facility.id,
        version: 2
      }).then(r => this.catcher(r.data, 'data[0].id', r => r)).catch(e => this.catcher(e))

      let vlo = _.cloneDeep(this.vlo.data)
      this.vlo = _.groupBy(vlo, obj => obj.facility_questionnaire[0].section)

      let vlc = await this.$lionheart.post('/log/get/facility-visitor-companion', {
        paginate: false,
        facility_visitor_log_id: this.vl.id,
        facility_id: facility.id
      }).then(r => this.catcher(r.data, 'data', r => r)).catch(e => this.catcher(e))
      vlc = _.cloneDeep(vlc.data)

      vlc.map(obj => {
        obj.fco = _.groupBy(obj.facility_companion_other_form_log, obj => obj.facility_questionnaire.section)
      })

      this.vlc = vlc

      this.fakeImageloaderCounter()
    },
    methods: {
      fakeImageloaderCounter() {
        this.$store.commit('checkin-facility/imageLoaderIncrement')

        setTimeout(() => {
          this.$store.commit('checkin-facility/imageLoaderDecrement')
        }, 500)

      },
      getLabel(obj) {
        if (t(core_questions, this.$i18n.locale).isDefined == false) return obj.label

        return core_questions[this.$i18n.locale].labels[obj.system_name]
      },
      getNationality(code) {
        let label = nationalities[this.$i18n.locale].find(obj => obj.CountryCode == code)

        if (label) {
          label = label.Nationality
          return label
        } else {
          label = nationalities[this.$i18n.locale].find(obj => obj.Nationality == code)
          if (label) return label.CountryCode
        }

        return ''
      }
    }
  }
</script>