<template>
  <b-modal ref="detailModal" dialog-class="modal-mod modal-fluid-lg" @hide="close" :no-enforce-focus="true">
    <template #modal-header>
      <div class="d-flex justify-content-between w-100">
        <b>{{ $t("account_page_details.title_details") }} [{{ itemIndex + 1 }}/{{ itemTotal }}]</b>
        
        <div class="controls">
            <template v-if="hasReservation()">
                <button @click="$refs.message.init_modal({...vl.booking_details, visitor: vl.visitor})" >
                    <i class="fa fa-comment" />
                </button>
            </template>

            <button @click="$emit('prev')" :disabled="!hasPrev">
            <i class="fa fa-arrow-up" />
          </button>
          <button class="ml-1" @click="$emit('next')" :disabled="!hasNext">
            <i class="fa fa-arrow-down" />
          </button>
          <button class="ml-1" @click="print"><i class="fa fa-print" /></button>
          <button class="ml-1" @click="close"><i class="fas fa-times" /></button>
        </div>

      </div>
    </template>

    <b-tabs @input="tabChanged">
      <b-tab :title="$t('account_page_details.tab_main')" active :disabled="reservationOnly">
        <div class="tab-panel-body">
          <main-details-widget
            :visitor-log="vl"
            :visitor-form-log="vfl"
            :companion-form-log="vlc"
            :hasPms="true"
            @updated="refresh"
            v-if="!reservationOnly"
          />
        </div>
      </b-tab>
      <b-tab :title="$t('account_page_details.tab_qr_pass')" :disabled="reservationOnly">
        <div class="tab-panel-body">
          <qr-details-widget :visitor-log="vl" :current-tab="currentTab" v-if="!reservationOnly" />
        </div>
      </b-tab>

      <!-- Reservation details -->
      <b-tab :title="$t('account_page_details.tab_reservation')">
         <div class="tab-panel-body">
            <ReservationOnlyDetailsWidget
               :log-id="logId"
               :visitor-log="vl"
               :current-tab="currentTab"
               mode="pass"
               :pmsReservationNo.sync="reservationNo"
               :visitorPass.sync="visitorPass"
               v-if="reservationOnly"
            />
            <reservation-details-widget
               :log-id="logId"
               :visitor-log="vl"
               :current-tab="currentTab"
               v-else
            />
         </div>
      </b-tab>

      <!-- Payment details -->
      <b-tab :title="$t('account_page_details.tab_payment')">
         <div class="tab-panel-body">
            <ReservePaymentDetailsWidget
               v-if="reservationOnly"
               :log-id="logId"
               :current-tab="currentTab"
               mode="pass"
               :pmsReservationNo.sync="reservationNo"
               :visitorPass.sync="visitorPass"
            />
            <payment-details-widget v-else :log-id="logId" :visitor-log="vl" :current-tab="currentTab" />

         </div>
      </b-tab>

      <b-tab :title="$t('account_page_details.digital_key')" :disabled="reservationOnly">
        <div class="tab-panel-body">
          <digital-key-widget :visitor-log="vl" :current-tab="currentTab" v-if="!reservationOnly" />
        </div>
      </b-tab>
      <b-tab :title="$t('account_page_details.tab_checkout')" :disabled="reservationOnly">
        <div class="tab-panel-body">
          <checkout-details-widget :visitor-log="vl" :current-tab="currentTab" v-if="!reservationOnly" />
        </div>
      </b-tab>
    </b-tabs>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <div />
        <div>
          <b-button variant="primary" class="btn-w-md" @click="close">
            Ok
          </b-button>
        </div>
      </div>
    </template>

   <message ref="message"/>
  </b-modal>
</template>

<script>
import t from "typy";
import _ from "lodash";
import moment from "moment-timezone";
import {
  core_questions,
  nationalities,
  main_form_methods,
} from "@/modules/checkin-facility/utils/mockup";
const { changeChoices, getChoice, formatDate } = main_form_methods;
import MainDetailsWidget from "./MainDetailsWidget.vue";
import QrDetailsWidget from "./QrDetailsWidget.vue";
import ReservationDetailsWidget from "./ReservationDetailsWidget.vue";
import PaymentDetailsWidget from "./PaymentDetailsWidget.vue";
import CheckoutDetailsWidget from "./CheckoutDetailsWidget.vue";
import DigitalKeyWidget from "./DigitalKeyWidget.vue";
import Message from "./Message";
import ReservationOnlyDetailsWidget from "./ReservationOnlyDetailsWidget.vue";
import ReservePaymentDetailsWidget from "./ReservePaymentDetailsWidget.vue";

export default {
   components: {
      CheckoutDetailsWidget,
      MainDetailsWidget,
      QrDetailsWidget,
      ReservationDetailsWidget,
      PaymentDetailsWidget,
      DigitalKeyWidget,
      Message,
      ReservationOnlyDetailsWidget,
      ReservePaymentDetailsWidget
   },
   props: {
      show: {
      type: Boolean,
      default: false
   },
      logId: {
         type: Number,
         default: 0
      },
      itemIndex: {
         type: Number | String,
         default: 0
      },
      itemTotal: {
         type: Number | String,
         default: 0
      },
      reservationOnly: {
         type: Boolean,
         default: false
      },
      reservationNo: {
         type: Number | String,
         default: 0
      },
      visitorPass: {
         type: String,
         default: ''
      },
   },
   data() { return {
      currentTab: "main",
      facility: {},
      vl: {
         visitor: { user: {}, },
      },
      vfl: {
      data: [],
      },
      vlc: {
      data: [],
      },
   }},
   async mounted() {
      this.facility = window._app_facility;
   },
   methods: {
      changeChoices,
      getChoice,
      formatDate,
      async getVisitorLog() {
         const visitorLog = await this.$lionheart
            .post("/log/get/facility-visitor", {
               paginate: false,
               id: this.logId,
               timezone: moment.tz.guess(),
               facility_id: this.facility.id,
            })
            .then((r) => this.catcher(r.data, "data[0]", (r) => r.data[0]))
            .catch((e) => this.catcher(e));

         if (visitorLog.error.has_error) return;

         this.vl = visitorLog;
      },
      async getCompanionLog() {
         let vlc = await this.$lionheart
            .post("/log/get/facility-visitor-companion", {
               paginate: false,
               facility_visitor_log_id: this.vl.id,
               facility_id: this.facility.id,
            })
            .then((r) => this.catcher(r.data, "data", (r) => r))
            .catch((e) => this.catcher(e));
         vlc = _.cloneDeep(vlc.data);

         vlc.map((obj) => {
            obj.fco = _.groupBy(
               obj.facility_companion_other_form_log,
               (obj) => obj.facility_questionnaire.section
            );
         });

         this.vlc = vlc;
      },
      async getVisitorFormLog() {
         this.vfl = await this.$lionheart
            .post("/log/get/facility-visitor-other-form", {
               paginate: false,
               facility_visitor_log_id: this.vl.id,
               facility_id: this.facility.id,
               version: 2,
            })
            .then((r) => this.catcher(r.data, "data[0].id", (r) => r))
            .catch((e) => this.catcher(e));

         let vfl = _.cloneDeep(this.vfl.data);
         this.vfl = _.groupBy(vfl, (obj) => obj.facility_questionnaire[0].section);

         this.populateEditData()
      },
      getEditLabel(system_name) {
         if (t(core_questions, this.$i18n.locale).isDefined == false)
            return system_name;

         return core_questions[this.$i18n.locale].labels[system_name];
      },
      async checkEmail(e) {
         if (this.edit_form.email == "") return;

         await this.validateEmail();
      },
      async validateEmail() {
         const data = {
            email: this.email,
            id: this.vl.visitor_id,
            type: "visitor",
            module: "checkin",
         };
         const email = await this.$lionheart
            .post("/user/exists", data)
            .then((r) => this.catcher(r.data, "data.exist", (r) => r.data))
            .catch((e) => this.catcher(e));

         this.email_exists = email.exist;
      },
      async submitForm(e) {
         e.preventDefault()
         if(!this.email_exists) {
            this.submitted = true
            this.$bvModal.hide('edit-contact-details-modal')

            const formData = _.cloneDeep(this.edit_form)
            let govIdChanged = false

            const gov_id_index = formData.findIndex(x => x.system_name === 'gov_id');
            if(gov_id_index != -1) {
               if (this.tp(formData[gov_id_index].value, 'dataUrl').isDefined) {
               govIdChanged = true
               formData[gov_id_index].value = formData[gov_id_index].value.dataUrl
               }
            }

            const dob_index = formData.findIndex(x => x.system_name === 'dob');
            if(dob_index != -1) {
               formData[dob_index].value = moment(this.formatDate(formData[dob_index].value)).format("YYYY-MM-DD")
            }
            const response = await this.$lionheart.put(`/log/visitor/${this.vl.id}`, {
               other_forms: formData,
               email: this.email,
               facility_id: this.facility.id
            });

            if(response.data.success) {
               this.submitted = false
               await this.getVl()
               await this.getvfl()
               if(!govIdChanged) this.showSuccess()
            }
         }
      },
      async populateEditData() {
         this.edit_form = []
         this.email = this.vl.visitor.user.email
         const x = ['reservation_no', 'occupancy']
         this.vfl.contact.forEach((data, key) => {
            if (!x.includes(data.facility_questionnaire[0].system_name)) {
               this.edit_form.push({
               system_name: data.facility_questionnaire[0].system_name,
               rank: data.facility_questionnaire[0].rank,
               required: data.facility_questionnaire[0].required,
               id: data.id,
               value: data.value,
               })
               if (data.facility_questionnaire[0].system_name == 'dob') this.temp_date = data.value
            }
         });
      },
      changeNationality() {
         if (t(nationalities, this.$i18n.locale).isDefined) return nationalities[this.$i18n.locale];
         this.$i18n.locale = "en";
         return nationalities[this.$i18n.locale];
      },
      showSuccess() {
         this.success = true
         setTimeout(() => {
            this.success = false
         }, 5000);
      },
      formatFormDate(date) {
         if (date == null) return "";
         const [y, m, d] = date.split(".");
         date = `${d}.${m}.${y}`;
         const timezone = moment.tz.guess();
         return moment.tz(date, timezone).format("MM/DD/YYYY");
      },
      close() {
         this.$emit('update:show', false);
         this.$refs['detailModal'].hide();
      },
      async refresh() {
         await this.getVisitorLog();
         await this.getVisitorFormLog();
         this.$emit('refresh', true);
      },
      print() {
         const page = window.open('#', '_blank');
         const pageTitle = this.$t("account_page_details.title_details");
         let tabContent = '';
         const hasQrElem = document.querySelector('.tab-pane.active #qr_for_print'); // Isolate QR case
         if (hasQrElem) tabContent = hasQrElem.innerHTML;
         else tabContent = document.querySelector(".tab-pane.active .tab-panel-body").innerHTML;
         page.document.write(`
         <!DOCTYPE html>
         <html>
         <head>
            <title>${pageTitle}</title>
            <style>
            .tab-pane {padding: 15px;}
            .tab-content {max-width: 800px;}
            .link-btn, .buttons-row, button:not(.panel-toggle-btn) {display: none;}
            .header-on-print {display: block; width: 100%; margin: 5px auto; font-size: 1.1rem;}
            .panel-toggle-body {display: block !important;}
            .panel-header {margin-bottom: 5px;}
            table {border-collapse: collapse; margin-bottom: 15px;}
            table, img {width: 100%;}
            td, .header-on-print {border: 1px solid #dee2e6; padding: 0.3rem;}
            td:first-child {min-width: 100px;}
            </style>
         </head>
         <body>
            <div class="tab-content">${tabContent}</div>
            <script>window.print()<\/script>
         </body>
         </html>
         `);
         page.document.close();
      },
      tabChanged(index) {
         const tabNames = ["main", "qr", "reservation", "payment", "checkout"];
         this.currentTab = tabNames[index];
      },
      hasReservation(){
         let status = false;
         let booking = this.vl.booking_details;
         if(booking && this.facility.my_page){
               status = true;
         }
         return status;
      },
   },
   computed: {
      editFormByRank () {
         return _.orderBy(this.edit_form, 'rank')
      },
      isPmsCheckin () {
         return this.vl.booking_details !== null &&
            this.vl.booking_details.hasOwnProperty('condition_metadata') &&
            this.vl.booking_details.condition_metadata !== null &&
            this.vl.booking_details.condition_metadata.is_pms_checkin === 1
      },
      hasPrev() {
         return Number(this.itemIndex) > 0;
      },
      hasNext() {
         return (Number(this.itemIndex) + 1) < Number(this.itemTotal);
      },
   },
   watch: {
      async show(v) {
         if (v) {
            if(!this.reservationOnly) {
               await this.getVisitorLog();
               await this.getVisitorFormLog();
               this.getCompanionLog();
            }
            this.$refs['detailModal'].show();
         }
      },
      async itemIndex() {
         if(!this.reservationOnly) {
            await this.getVisitorLog();
            await this.getVisitorFormLog();
            this.getCompanionLog();
         }
      }
   }
}
</script>