<template>
   <div id="dashboard_wrapper">
      <div class="card-body">
         <h4 class="card-title">
            {{ $t("account-page-facility-my-page-message.name") }}
         </h4>
         <br />
         <div class="alert alert-success" role="alert" v-if="loading">
            {{ $t("account-page-facility-my-page-message.success") }}
         </div>
         <hr />
         <div class="row">
            <div class="col-lg-12">
               <div class="d-flex flex-row">
                  <div>
                     {{ $t("account-page-facility-my-page-message.face_icon") }}
                  </div>
                  <div class="pl-2">
                     <toggle-button v-model="face_icon.value.status" :labels="{ checked: 'On', unchecked: 'Off' }" :value="face_icon.value.status" :sync="true" />
                  </div>
               </div>

               <p class="small">
                  {{ $t("account-page-facility-my-page-message.face_icon_description") }}
               </p>
               <button :disabled="!face_icon.value.status" type="button" class="btn btn-outline-info btn-sm" @click="$bvModal.show('message-sample')">
                  {{ $t("account-page-facility-my-page-message.face_icon_sample") }}
               </button>
            </div>
            <div class="col-lg-12 mt-3">
               <template v-if="!face_icon.value.base64">
                  <input type="file" :disabled="!face_icon.value.status" @change="upload($event)" accept="image/png, image/gif, image/jpeg" />
                  <br />
                  <small> {{ $t("account-page-facility-my-page-message.face_icon_suggested_size") }}</small>
                  <br />
                  <small class="text-danger" v-if="error.face_icon"> {{ $t("account-page-facility-my-page-message.face_icon_max_file_size") }}</small>
               </template>
               <template v-else>
                  <div class="position-relative">
                     <a class="text-danger position-absolute p-2" style="top: 5px; left: 5px" href="javascript:void(0)" @click="face_icon.value.base64 = ''">
                        <i class="fa fa-times fa-2x"></i
                     ></a>
                     <img :src="face_icon.value.base64" width="100" height="100" />
                  </div>
               </template>
            </div>
         </div>
         <hr />
         <div class="row">
            <div class="col-lg-12">
               <div class="d-flex flex-row">
                  <div>
                     {{ $t("account-page-facility-my-page-message.message") }}
                  </div>
               </div>
               <p class="small">
                  {{ $t("account-page-facility-my-page-message.message_description") }}<br />
                  <!--                        {{ $t("account-page-facility-my-page-message.message_tags") }}: <b>{[full_name]},-->
                  <!--                        {[reservation_number]}, {[checkin_date]}, {[checkout_date]}</b>-->
               </p>
            </div>
            <div class="col-lg-12">
               <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" v-for="(value, key) in template.value">
                     <a class="nav-link" :class="{ active: key == config.active_language }" :href="`#tab-${value.code}`" @click="init_language(key)">{{
                        $t(`account-page-facility-language-option.${value.name}`)
                     }}</a>
                  </li>
               </ul>
               <table class="table table-bordered">
                  <thead>
                     <tr>
                        <th scope="col">{{ $t("account-page-facility-my-page-message.template") }}</th>
                        <th scope="col" width="60%" class="border-right-0">{{ $t("account-page-facility-my-page-message.body") }}</th>
                        <th scope="col" width="5%" class="text-center border-left-0"></th>
                     </tr>
                  </thead>
                  <tbody v-if="template.value[config.active_language]">
                     <tr v-for="(value, key) in template.value[config.active_language].data">
                        <th class="align-middle"><input type="text" class="form-control" v-model="value.title" /></th>
                        <td class="border-right-0"><textarea class="form-control" v-model="value.value"></textarea></td>
                        <td v-if="template.value[config.active_language]" class="align-middle text-center border-left-0">
                           <!--                                @click="$delete(template.value[config.active_language].data,key)"-->
                           <a @click="$bvModal.show('delete-modal'), (config.deletable_key = key)" type="button" class="text-danger"
                              ><i class="fa fa-minus-circle"></i
                           ></a>
                        </td>
                     </tr>
                     <template v-if="template.value[config.active_language]">
                        <tr v-if="template.value[config.active_language].data.length < 5">
                           <td colspan="3" class="text-center">
                              <button type="button" class="btn btn-info" @click="template.value[config.active_language].data.push({ title: '', value: '' })">
                                 + {{ $t("account-page-facility-my-page-message.add_more") }}
                              </button>
                           </td>
                        </tr>
                     </template>
                  </tbody>
               </table>
            </div>
         </div>

         <div class="row mt-4">
            <div class="col-lg-12" align="center">
               <button
                  @click.prevent="
                     $router.push({
                        name: 'checkin-facility-dashboard-my-page',
                     })
                  "
                  class="btn btn-danger ml-3"
               >
                  {{ $t("account-page-facility-my-page-message.back") }}
               </button>
               <button @click="$bvModal.show('modal-confirmation')" type="button" class="btn btn-primary ml-3">
                  {{ $t("account-page-facility-my-page-message.confirm") }}
               </button>
            </div>
         </div>
      </div>
      <confirmation-modal-component @click-handler="submit"></confirmation-modal-component>

      <confirmation-modal-component
         id="delete-modal"
         @click-handler="$delete(template.value[config.active_language].data, config.deletable_key)"
      ></confirmation-modal-component>
      <message-sample :icon="face_icon.value.base64" />
      <upload ref="upload" @event="upload" />
   </div>
</template>

<script>
import ConfirmationModalComponent from "../../components/confirmation-modal.component";
import MessageSample from "./message-sample";
import Upload from "./upload";
export default {
   components: { ConfirmationModalComponent, MessageSample, Upload },
   name: "form",
   data() {
      return {
         loading: false,
         template: {
            name: "my-page-message-templates",
            value: [],
            facility_id: window._app_facility.id,
         },
         language_filter: {
            paginate: 0,
            name: "language",
            facility_id: window._app_facility.id,
         },
         face_icon: {
            name: "my-page-message-face-icon",
            value: {
               base64: "",
            },
            facility_id: window._app_facility.id,
         },
         config: {
            active_language: null,
            deletable_key: null,
         },
         languages: [
            { code: "en", name: "en" },
            { code: "ja", name: "ja" },
         ],
         error: {
            face_icon: false,
         },
      };
   },
   async mounted() {
      await this.show();
      await this.init_languages(this.language_filter);
      await this.populate_language(this.language_filter);
      await this.init_language(0);
   },
   methods: {
      async submit() {
         await this.$lionheart.post("/v2/facility-settings", { ...this.template });
         await this.$lionheart.post("/v2/facility-settings", { ...this.face_icon });
         this.loading = true;

         setTimeout(() => {
            this.loading = false;
         }, 3000);
      },
      async init_languages(query) {
         const response = await this.$lionheart.get("/v2/facility-settings", { params: query });

         if (response.data.data && response.data.data[0]) {
            this.languages = response.data.data[0].value;
         }
      },
      async populate_language() {
         this.languages.forEach((value, key) => {
            let exist = this.template.value.find((el) => el.code == value.code);
            if (!exist) {
               let input = {
                  code: value.code,
                  data: [],
                  name: value.name,
               };
               this.template.value.push(input);
            }
         });

         console.log(this.template.value);
      },
      async upload($event) {
         if ($event.is_cropped) {
            this.face_icon.value.base64 = $event.value;
            return;
         }

         let file = $event.target.files[0];

         if (file.size > 1000000) {
            // 1 MiB for bytes.
            this.error.face_icon = true;
            setTimeout(() => {
               this.error.face_icon = false;
            }, 5000);
            $event.target.value = null;
            return;
         }
         this.$refs.upload.init_upload(await this.toBase64(file));
         this.face_icon.value.file_name = file.name;
         $event.target.value = null;
      },
      async init_language(key) {
         this.config.active_language = key;
      },
      async show() {
         let result_template = await this.$lionheart
            .get("/v2/facility-settings", {
               params: {
                  facility_id: window._app_facility.id,
                  name: "my-page-message-templates",
                  paginate: 0,
               },
            })
            .then((r) => r.data.data);
         if (result_template.length > 0) {
            this.template = result_template[0];
         }

         let result_face = await this.$lionheart
            .get("/v2/facility-settings", {
               params: {
                  facility_id: window._app_facility.id,
                  name: "my-page-message-face-icon",
                  paginate: 0,
               },
            })
            .then((r) => r.data.data);
         if (result_face.length > 0) {
            this.face_icon = result_face[0];
         }
      },
   },
};
</script>

<style scoped></style>
