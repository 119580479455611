<template>
  <div class="styled-form">
    <div id="login_wrapper" class="mt-3">
      <div class="wrapper">
        <div id="link_expired" v-if="! link_okay" class="alert alert-warning" role="alert">{{ $t('landing.newpassword-link-expired') }}</div>

        <div class="form-content" v-if="link_okay && password_set_msg == ''"> 
          <form class="p-4" @submit="submitForm">
            <div id="error_msg" v-if="error_msg" class="alert alert-danger" role="alert">{{ error_msg }}</div>
            <div id="newpassword_not_same_msg" v-if="newpassword_not_same_msg" class="alert alert-warning" role="alert">{{ $t('landing.newpassword-not-same-msg') }}</div>
            <h2 class="text-left w-100">{{ $t('landing.newpassword-title')}}</h2>
            <hr />

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('landing.newpassword-pass-1')}}</label>
              <div class="col-sm-9">
                <input v-model="data.password" type="password" minlength="6" required class="form-control" :placeholder="$t('landing.newpassword-pass-1')">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('landing.newpassword-pass-2')}}</label>
              <div class="col-sm-9">
                <input v-model="data.confirm_password" type="password" minlength="6" required class="form-control" :placeholder="$t('landing.newpassword-pass-2')">
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12" align="center">
                <button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t('landing.reset-password-btn')}}</button>
              </div>
            </div>
          </form>
        </div>

        <template v-if="password_set_msg">
          <div class="form-content p-5">
            <div class="row mb-4">
              <h3>{{ $t('landing.newpassword-complete')}}</h3>
            </div>
            <div class="row">
              <router-link link :to="{name: 'checkin-facility-login'}" class="underlineHover" >{{ $t('account-registration-form.return-to-signin')}}</router-link>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.styled-form .form-content {
  max-width: 768px !important;
}
</style>

<script>
export default {
  data() {
    return {
      submit_btn: '',
      error_msg: '',
      password_set_msg: '',
      newpassword_not_same_msg: '',
      link_okay: true,
      data: {}
    }
  },
  async mounted() {
    Object.assign(this.data, this.$route.query)

    const verify = await this.$lionheart.post('/auth/verify-reset-password', this.$route.query )
        .then(r => this.catcher(r.data, 'status', r => r))
        .catch(e => this.catcher(e))
        
    if (verify.error.has_error) return this.link_okay = false

    if (verify.status.success == false) return this.link_okay = false
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()

      if (this.submit_btn == 'submit') {
        if (this.data.password != this.data.confirm_password) return this.newpassword_not_same_msg = '1'

        let data = Object.assign({}, this.data)
        delete data.confirm_password

        const pass = await this.$lionheart.post('/auth/change-password', data )
          .then(r => this.catcher(r.data, 'status', r => r))
          .catch(e => this.catcher(e))
        
        if (pass.error.has_error) return this.errorDisplay(pass)

        this.password_set_msg = '1'
        this.newpassword_not_same_msg = ''
        this.error_msg = ''

        if (pass.status.success == false) {
          this.link_okay = false
          this.password_set_msg = ''
        }

        this.data.password = ''
        this.data.confirm_password = ''
      }
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    }
  }
}
</script>