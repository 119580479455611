<template>
   <div id="dashboard_wrapper">
      <div class="card-body">
         <h4 class="card-title">
            {{ $t("account-page-facility-my-page.name") }}
         </h4>
         <br />
         <div class="alert alert-success" role="alert" v-if="loading">
            {{ $t("account-page-facility-my-page.success") }}
         </div>
         <div class="row">
            <div class="col-lg-12">
               <div class="d-flex flex-row">
                  <div>
                     {{ $t("account-page-facility-my-page.enable") }}
                  </div>
                  <div class="pl-2">
                     <toggle-button
                        v-model="data.value.active"
                        :labels="{ checked: 'On', unchecked: 'Off' }"
                        :value="data.value.active"
                        :sync="true"
                        @change="enable_my_page"
                     />
                  </div>
               </div>
               <p class="small">
                  {{ $t("account-page-facility-my-page.enable_description") }}
               </p>
            </div>
         </div>
         <hr />
         <div class="row">
            <div class="col-lg-12">
               <div class="d-flex flex-row">
                  <div>
                     {{ $t("account-page-facility-my-page.express_checkout") }}
                  </div>
                  <div class="pl-2">
                     <toggle-button
                        v-model="data.value.express_checkout"
                        :labels="{ checked: 'On', unchecked: 'Off' }"
                        :value="data.value.express_checkout"
                        :sync="true"
                        :disabled="!data.value.active"
                     />
                  </div>
               </div>

               <p class="small">
                  {{ $t("account-page-facility-my-page.express_checkout_description") }}
               </p>
            </div>
         </div>
         <hr />
         <div class="row">
            <div class="col-lg-12">
               <p class="text-left form-label">
                  {{ $t("account-page-facility-my-page.map_setting") }}
               </p>
               <p class="small text-left">
                  {{ $t("account-page-facility-my-page.map_setting_description") }}
               </p>
               <textarea class="form-control" v-model="data.value.map" :disabled="!data.value.active"></textarea>

               <button type="button" class="btn mt-2 btn-outline-primary btn-block" v-if="data.value.map" @click="$refs.location.init(data.value.map)">
                  {{ $t("account-page-facility-my-page.map_setting_display") }}
               </button>
            </div>
         </div>
         <hr />
         <div class="row">
            <div class="col-lg-12">
               <label class="form-label">
                  {{ $t("account-page-facility-my-page.background") }}
               </label>

               <p class="small">
                  {{ $t("account-page-facility-my-page.background_description") }}
               </p>
               <div class="row">
                  <div class="col-sm-6">
                     <label class="w-100">
                        <input type="file" class="d-none" ref="upload" @change="upload($event)" />
                        <button
                           :disabled="!data.value.active"
                           type="button"
                           class="btn btn-outline-secondary btn-block"
                           @mouseover="config.is_hover_upload = true"
                           @mouseleave="config.is_hover_upload = false"
                           @click="$refs.upload.click()"
                        >
                           <template v-if="!data.value.background_name">
                              {{ $t("account-page-facility-my-page.background_upload") }}
                           </template>
                           <template v-else>
                              <span>
                                 {{
                                    config.is_hover_upload
                                       ? $t("account-page-facility-my-page.background_upload")
                                       : `${$t("account-page-facility-my-page.background_file_name")}: ${data.value.background_name}`
                                 }}
                              </span>
                           </template>
                        </button>
                     </label>
                     <small v-if="error.background_path" class="text-danger"> {{ $t("account-page-facility-my-page.background_max_file_size") }}</small>
                  </div>
                  <div class="col-sm-6">
                     <button
                        type="button"
                        class="btn btn-primary btn-block text-center"
                        @click="$refs.background.init(data.value.background_path)"
                        :disabled="!data.value.background_name"
                     >
                        {{ $t("account-page-facility-my-page.background_display") }}
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-4">
            <div class="col-lg-12" align="center">
               <button
                  @click.prevent="
                     $router.push({
                        name: 'checkin-facility-dashboard-my-page',
                     })
                  "
                  class="btn btn-danger ml-3"
               >
                  {{ $t("account-page-facility-my-page.back") }}
               </button>
               <button @click="$bvModal.show('modal-confirmation')" type="button" class="btn btn-primary ml-3">
                  {{ $t("account-page-facility-my-page.confirm") }}
               </button>
            </div>
         </div>
      </div>
      <confirmation-modal-component @click-handler="submit"></confirmation-modal-component>
      <background ref="background"></background>
      <location ref="location"></location>
   </div>
</template>
<script>
import ConfirmationModalComponent from "./../../components/confirmation-modal.component";
import Background from "./background";
import Location from "./location";

export default {
   components: {
      ConfirmationModalComponent,
      Background,
      Location,
   },
   data: () => ({
      config: {
         is_hover_upload: false,
      },
      data: {
         facility_id: window._app_facility.id,
         name: "my-page-setting",
         value: {
            active: false,
            express_checkout: false,
            background_path: null,
            background_name: null,
            map: null,
         },
      },
      loading: false,
      error: {
         background_path: false,
      },
   }),
   async mounted() {
      await this.show();
   },
   methods: {
      async submit() {
         await this.$lionheart.post("/v2/facility-settings", { ...this.data });
         this.loading = true;

         setTimeout(() => {
            this.loading = false;
         }, 3000);
      },
      async enable_my_page() {
         if (!this.data.value.active) {
            this.data.value.express_checkout = false;
         }
      },
      async upload($event) {
         let file = $event.target.files[0];

         if (file.size > 1000000) {
            this.error.background_path = true;
            setTimeout(() => {
               this.error.background_path = false;
            }, 5000);
            return;
         }
         const toBase64 = (file) =>
            new Promise((resolve, reject) => {
               const reader = new FileReader();
               reader.readAsDataURL(file);
               reader.onload = () => resolve(reader.result);
               reader.onerror = (error) => reject(error);
            });

         this.data.value.background_name = file.name;
         this.data.value.background_path = await toBase64(file);

         console.log(this.data.value.background_name);
      },
      async show() {
         let result = await this.$lionheart
            .get("/v2/facility-settings", {
               params: {
                  facility_id: window._app_facility.id,
                  name: "my-page-setting",
                  paginate: 0,
               },
            })
            .then((r) => r.data.data);
         if (result.length > 0) {
            this.data = result[0];
         }
      },
   },
};
</script>
