<template>
    <b-modal size="lg" id="background" centered   :title="$t('account-page-facility-my-page.background')">
 
        <div class="row">
            <div class="col-lg-12">
                <img :src="src" class="img-fluid">
            </div>
        </div>
        <template #modal-footer>
            <div class="w-100">
                <button class="btn btn-outline-danger btn-block" @click="$bvModal.hide('background')">
                    {{$t("account-page-facility-my-page.close")}}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "background.vue",
    data(){
        return {
            src: null
        }
    },
    methods: {
        async init(data){
            this.src = data;
            this.$bvModal.show("background");
        }
    }
}
</script>

<style scoped>

</style>