<template>
  <div>


    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" :class="{'active':show == 'cio'}" @click="show = 'cio'">
          {{ $t("quick_checkout_in_setting.nav_checkin_out") }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" :class="{'active':show == 'pass'}" @click="show = 'pass'">
          {{ $t("quick_checkout_in_setting.nav_pass") }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" :class="{'active':show == 'pattern'}" @click="show = 'pattern'">
          {{ $t("quick_checkout_in_setting.nav_pattern") }}</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <checkin-out v-if="show == 'cio'"/>
      <pass v-if="show == 'pass'" />
      <qr-setting v-if="show == 'pattern'" />

    </div>
  </div>
</template>

<script>
import CheckinOut from "./checkin_out.vue";
import QrSetting from "./qr-setting.vue";
import Pass from "./pass.vue";
export default {
  components: {
    CheckinOut,
    QrSetting,
    Pass
  },
  data() {
    return {
      show: "cio"
    };
  },
  async mounted() {
  },
  methods: {},
};
</script>