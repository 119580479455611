<template>
  <div class="form-group row" ref="topCorner">
    <label class="col-sm-2 col-form-label">
      {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
    </label>
    <div class="col-sm-10 my-auto">
      <template v-if="c.input_type == 'string'">
        <div v-if="!multiple && !isFixGuestEnabled">
          <div class="d-flex align-items-center">
            <input
              v-if="!confirm"
              v-model="inputData[c.id]"
              type="text"
              :required="c.required ? true : false"
              class="form-control w-100"
              :placeholder="c.placeholder"
            />

            <div
              :title="$t('visit-information-form.other-companion-btn')"
              v-if="c.system_name == 'full_name' && !confirm && !isFixGuestEnabled"
              @click="addCompanion"
              class="my-auto ml-2"
            >
              <i class="add-companion fa fa-plus"></i>
            </div>

          </div>
          <!-- For data aggregation -->
          <template v-if="companions.length > 0 && !confirm">
            <div
              v-for="(companion, index) in companions"
              :key="`c_${index}`"
              class="mt-3"
            >
              <label
                v-if="!confirm && canBeCopy.includes(c.system_name)"
                class="form-check-label"
                @click="
                  copyFromMain(
                    c.id,
                    companion.other_forms,
                    { type: 'visitor', obj: inputData[c.id] },
                    $event
                  )
                "
              >
                {{ $t("visit-information-form.copy-from-main") }}
              </label>
              <div class="d-flex align-items-center">
                <input
                  v-if="different.includes(c.system_name)"
                  v-model="companion.other_forms[c.id]"
                  type="text"
                  :required="c.required ? true : false"
                  class="form-control w-100"
                  :placeholder="
                    showLabelOrPlaceholder(
                      $t('visit-information-form.companion'),
                      index,
                      c.placeholder,
                      'placeholder'
                    )
                  "
                />
                <div
                  :title="
                    $t('visit-information-form.other-companion-remove-btn')
                  "
                  v-if="c.system_name == 'full_name'"
                  @click.prevent="removeCompanion(index)"
                  class="my-auto ml-2"
                >
                  <i class="remove-companion fa fa-minus"></i>
                </div>
              </div>
            </div>
          </template>
        </div>


        <!-- fix number of guest enabled  -->

        <div v-else-if="!multiple && isFixGuestEnabled">
            <div class="d-flex align-items-center mb-3"> 
              <input
                v-if="!confirm"
                v-model="visitor_forms[c.id]"
                type="text"
                :required="c.required ? true : false"
                class="form-control w-100"
                :placeholder="c.placeholder"
              />            
            </div>

            <template v-if="fixGuestCount > 1 && !confirm">
            <div
              v-for="(companion, index) in companions"
              :key="`c_${index}`"
              class="mt-3"
            >
              <label
                v-if="!confirm && canBeCopy.includes(c.system_name)"
                class="form-check-label"
                @click="
                  copyFromMain(
                    c.id,
                    companion.other_forms,
                    { type: 'visitor', obj: visitor_forms[c.id] },
                    $event
                  )
                "
              >
                {{ $t("visit-information-form.copy-from-main") }}
              </label>
              <div class="d-flex align-items-center"  v-if="different.includes(c.system_name)">
                <input
                  v-model="companion.other_forms[c.id]"
                  type="text"
                  :required="c.required ? true : false"
                  class="form-control w-100"
                  :placeholder="
                    showLabelOrPlaceholder(
                      $t('visit-information-form.companion'),
                      index,
                      c.placeholder,
                      'placeholder'
                    )
                  "                
                  />
              </div>
            </div>
          </template>

        </div>

        <!-- Multiple Room -->
        <div v-else>
          <div class="d-flex align-items-center">
            <input
              v-if="!confirm"
              v-model="multiple_data[bd_index].visitor_forms[c.id]"
              type="text"
              :required="
                multiple_data[bd_index].filled_in_by_other
                  ? false
                  : c.required
                  ? true
                  : false
              "
              class="form-control w-100"
              :placeholder="c.placeholder"
            />
            <div
              :title="$t('visit-information-form.other-companion-btn')"
              v-if="c.system_name == 'full_name' && !confirm"
              @click="addCompanion"
              class="my-auto ml-2"
            >
              <i class="add-companion fa fa-plus"></i>
            </div>
          </div>
          <!-- For data aggregation -->

          <template
            v-if="multiple_data[bd_index].companions.length > 0 && !confirm"
          >
            <div
              v-for="(companion, index) in multiple_data[bd_index].companions"
              :key="`mc_${index}`"
              class="mt-3"
            >
              <label
                v-if="!confirm && canBeCopy.includes(c.system_name)"
                class="form-check-label"
                @click="
                  copyFromMain(
                    c.id,
                    companion.other_forms,
                    {
                      type: 'visitor',
                      obj: multiple_data[bd_index].visitor_forms[c.id],
                    },
                    $event
                  )
                "
              >
                {{ $t("visit-information-form.copy-from-main") }}
              </label>
              <div class="d-flex align-items-center">
                <input
                  v-if="different.includes(c.system_name)"
                  v-model="companion.other_forms[c.id]"
                  type="text"
                  :required="c.required ? true : false"
                  class="form-control w-100"
                  :placeholder="
                    showLabelOrPlaceholder(
                      $t('visit-information-form.companion'),
                      index,
                      c.placeholder,
                      'placeholder',
                      true,
                      bd_index
                    )
                  "
                />
                <div
                  :title="
                    $t('visit-information-form.other-companion-remove-btn')
                  "
                  v-if="c.system_name == 'full_name'"
                  @click.prevent="removeCompanion(index)"
                  class="my-auto ml-2"
                >
                  <i class="remove-companion fa fa-minus"></i>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>

      <template v-if="c.input_type == 'choices'">
        <div class="row" v-if="!confirm">
          <div
            class="col-lg-4"
            v-for="(value, key) in c.metadata.options"
            :key="`mo_${key}`"
          >
            <div class="form-check">
              <input
                v-model="form_choice_temp.ids['visitor_' + c.id + '_' + key]"
                @click="
                  concatAnswer(
                    'visitor_forms',
                    c.id,
                    value.name,
                    'visitor_forms_' + c.id
                  )
                "
                class="form-check-input"
                type="checkbox"
                :id="'main-' + key + '-' + c.id"
                :true-value="value.name"
              />
              <label
                class="form-check-label text-decoration-none"
                :for="'main-' + key + '-' + c.id"
              >
                {{ value.name }}
              </label>
            </div>
          </div>
        </div>
      </template>

      <template v-if="c.input_type == 'dropdown'">
        <select
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="(value, key) in c.metadata.options" :key="`mo_${key}`">
            {{ value.name }}
          </option>
        </select>
      </template>

      <template v-if="c.input_type == 'date'">
        <input
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          type="date"
          :required="c.required ? true : false"
          class="form-control"
          :placeholder="c.placeholder"
        />
      </template>

      <p v-if="confirm" class="text-break mb-0">
        {{
          !multiple
            ? inputData[c.id]
            : multiple_data[bd_index].visitor_forms[c.id]
        }}
      </p>
    </div>
  </div>
</template>

<script>
import t from "typy";
import _ from "lodash";
import { main_form_methods } from "../../../../utils/mockup";
import { mapState, mapActions } from "vuex";
import { checkinFormTypeWithMemorization, loadMemorizedData } from '../../../../utils/helpers'
import guestWatcherMixin from "../../../../../../mixins/guestWatcherMixin";

const {
  showLabelOrPlaceholder,
  populateCompanionsFields,
  changeChoices,
  getChoice,
  copyFromMain,
  loadAgeDropdown,
  isFixGuestEnabled,
} = main_form_methods;
export default {
  mixins: [guestWatcherMixin],
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    companions: {
      type: Array,
      default: [],
    },
    different: {
      type: Array,
      default: [],
    },
    questions: {
      type: Array,
      default: [],
    },
    canBeCopy: {
      type: Array,
      default: [],
    },
    form_choice_temp: {
      type: Object,
      default: {
        ids: {},
      },
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room

    // checkin form type
    checkin_form_type: "",
    reservation_no: null,
    fixGuestCount: {
      type: Number,
      default: null
    },
    fixGuestSetting: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      inputData: {},
      formType: "",
    };
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
    isFixGuestEnabled
  },
  updated() {
    this.handleFormType();
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    ...mapActions("memorizedInputs", [
        "saveInput",
        "saveWalkInInputs",
        "saveReservationNumber",
        "checkIfCheckinFormType",
  ]),

    async initializeForm() {
      const rid = this.$route.query.rid
      checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);

        this.inputData = await loadMemorizedData(
        this,
        this.memorizedInputs,
        this.memorizedWalkInInputs,
        this.checkin_form_type,
        this.visitor_forms
      );
      
      this.populateCompanionsFields();
    },
    handleFormType() {
    
      this.checkin_form_type === "others"
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },
    showLabelOrPlaceholder,
    changeChoices,
    getChoice,
    copyFromMain,
    populateCompanionsFields,
    loadAgeDropdown,
    addCompanion(e) {
      e.preventDefault();

      if (!this.multiple) {
        this.companions.push({
          nationality: "",
          other_forms: {},
        });

        this.$emit("add-guest");

        setTimeout(() => {
          const age = this.questions.find((obj) => {
            return obj.system_name == "age";
          });

          if (age) {
            if (
              this.$refs["ddAge" + this.companions.length] &&
              this.$refs["ddAge" + this.companions.length][0]
            ) {
              this.loadAgeDropdown(
                this.companions[this.companions.length - 1].other_forms,
                this.$refs["ddAge" + this.companions.length][0].getAttribute(
                  "data-key"
                )
              );
            }
          }
        }, 1000);
      } else {
        this.multiple_data[this.bd_index].guestCount++;
        this.multiple_data[this.bd_index].companions.push({
          nationality: "",
          other_forms: {},
        });
      }
    },
    removeCompanion(i) {
      if (!this.multiple) {
        this.companions.splice(i, 1);
        this.$emit("remove-guest");
      } else {
        this.multiple_data[this.bd_index].guestCount--;
        this.multiple_data[this.bd_index].companions.splice(i, 1);
      }
    },

    concatAnswer(model, id, answer, unique) {
      if (!this.form_choice_temp[unique]) {
        this.form_choice_temp[unique] = [];
      }
      var index = this.form_choice_temp[unique].findIndex((x) => x == answer);
      if (index === -1) {
        this.form_choice_temp[unique].push(answer);
      } else {
        this.$delete(this.form_choice_temp[unique], index);
      }
      const concat_answer = this.form_choice_temp[unique].join(", ");
      if (model == "visitor_forms") {
        this[model][id] = concat_answer;
      } else {
        this.companions[model].other_forms[id] = concat_answer;
      }
    },
    // moveToTop() { // commented this for now will based on testing after
    //    this.$refs.topCorner.scrollIntoView();
    // }
  },
};
</script>

<style lang="scss" scoped>
.form-check-label {
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 5px;
}

.add-companion {
  padding: 9px 11px;
  font-size: 18px;
  background-color: #28a745 !important;
  border-radius: 100% !important;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #218838 !important;
  }
}

.remove-companion {
  padding: 9px 11px;
  font-size: 18px;
  background-color: #dc3545 !important;
  border-radius: 100% !important;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #c82333 !important;
  }
}
</style>