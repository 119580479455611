<template>
  <div>
    <b-modal
      size="lg"
      :id="id"
      centered
      :title="$t('pre_email.preview')"
      hide-footer
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div v-html="$t(trans_key)"></div>
            <span class="no-margin" v-html="html"></span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.no-margin p {
  margin-bottom: 0;
}
</style>
<script>
import t from 'typy'
export default {
  props: ["data", "trans_key", "id"],
  computed: {
    html() {
      return t(this.data.value, 'html').isDefined ? this.data.value.html : this.data.value;
    },
  },
};
</script>