<template>
  <b-modal
    id="modal-pdf-viewer" dialog-class="modal-fluid-xl" centered hide-header
  >
    <div style="height: 85vh">
      <iframe height="100%" width=100% :src="src"></iframe>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <div />
        <b-button variant="primary" class="btn-w-md" @click="$bvModal.hide('modal-pdf-viewer')">
          Ok
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "PDFViewer",
  props: ['src']
}
</script>
