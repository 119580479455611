<template>
   <b-modal :id="id" @hidden="init()">
      <template #modal-header="{ close }">
         <span v-if="isForVaccine">
            {{ $t("visit-information-form.vaccine_card_upload_photo_text") }}
         </span>
         <span v-else>
            {{ $t("visit-information-form.passport_or_gov_id_upload_photo_text") }}
         </span>
         <button class="close" @click="close()">
            <span aria-hidden="true">&times;</span>
         </button>
      </template>

      <div class="picture-holder">
         <div class="upload-image-holder">
            <div class="upload-label">
               <i class="icon">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1408"><path fill="currentColor" d="M1280 736q0-14-9-23L919 361q-9-9-23-9t-23 9L522 712q-10 12-10 24q0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5V768h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5T1536 1408H448q-185 0-316.5-131.5T0 960q0-130 70-240t188-165q-2-30-2-43q0-212 150-362T768 0q156 0 285.5 87T1242 318q71-62 166-62q106 0 181 75t75 181q0 76-41 138q130 31 213.5 135.5T1920 1024z"/></svg> -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/></svg>
               </i>
               <label for="upload-image">
                  {{ $t("visit-information-form.browse_file_upload_photo_text") }}
               </label>
            </div>

            <input type="file" multiple accept="image/*" name="file" @change="handleChooseFile" ref="fileInput" class="input-file">
         </div>

         <!-- show this if the selected picture does not meet the guest count -->
         <div class="error-message" v-if="errorMessage">
            <p>{{errorMessage}}</p>
         </div>
      </div>
      <div class="image-box-foot-note">
         <p>* {{ this.$t("visit-information-form.browse_file_required_to_upload_photo_text") }} </p> <strong> ({{ guestCount }})</strong>
      </div>

      <template #modal-footer>
      <div class="d-block w-100 text-center">
      </div>
      </template>
   </b-modal>
</template>

<script>
export default {
   name: "BrowseFileModalComponent",
   props: {
      id: {
         required: false,
         type: String,
         default: "browse-file-modal"
      },
      guestCount: {
         required: true,
         type: Number,
      },
      question_id: {
         type: Number,
         default: 0
      },
      isForVaccine: {
         type: Boolean,
         default: false
      }
   },
   data() {
      return {
         imagesToUpload: [],
         errorMessage: ''
      }
   },
   methods: {
      init() {
         this.imagesToUpload = [];
      },
      handleChooseFile(event) {
         const files = event.target.files;

         if (
            files.length > this.guestCount || 
            files.length < this.guestCount
         ) return this.errorMessage =  this.$t("visit-information-form.browse_file_error_message_upload_photo_text");

         // loop the images then push to images array
         for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            reader.addEventListener(
               "load",
               () => {
                  // convert image file to base64 string
                  let imagesToPush = {
                     dataUrl: reader.result,
                     question_id: this.question_id
                  };

                  this.imagesToUpload.push(imagesToPush);
               },

               false
            );

            // required to call this to read the file
            if (files[i]) {
               reader.readAsDataURL(files[i]);
               setTimeout(() => {
                  this.$emit("triggerImageUpload", this.imagesToUpload)
                  this.$bvModal.hide(this.id)
               }, 1000)
            }
         }
      }
   }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
.img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.error-message {
   padding: 10px;
   font-size: 12px;
   color: red;
}
.preview-image-holder {
   min-height: 250px;
   position: relative;
   img {
      width: 100%;
      object-fit: contain;
      height: 250px;
   }
   .top-right {
      position: absolute;
      top: 0;
      right: 10px;
   }
}
.upload-image-holder {
   outline: 2px dashed grey;
   outline-offset: -10px;
   min-height: 150px;
   // background: lightcyan;
   padding: 10px;
   cursor: pointer;
   display: flex;
   justify-content: center;

   .upload-label {
      margin: auto;

      .icon svg {
         width: 100%;
         height: 45px;
         color: $secondary;
      }

      label {
         text-align: center;
         width: 100%;
         font-size: 0.875em;
         font-weight: 400;
         color: gray;
      }
   }

   &:hover {
      .icon svg {
         color: $primary;
         transform: scale(1.09);
      }
      label {
         text-decoration: underline;
      }
   }
   .input-file {
      opacity: 0;
      width: 100%;
      cursor: pointer;
      position: absolute;
      height: 150px;
   }
}

.image-box-foot-note{
   display: flex;
   justify-content: flex-end;
   column-gap: 2px;
   p, strong {
      font-size: 14px;
      color: red;
      font-style: italic;
   }
}

/* .overlay {
  --border-style: 3px solid red;
  --border-space: 20px;

  position: absolute;
  width: 100%;
  height: 100%;
}

.overlay-helper {
  position: relative;
  width: 90%;
  height: 56%;
}
.overlay-helper-2 {
  position: relative;
  width: 90%;
  height: 30%;
}

.overlay-element {
  padding: 20px;
  width: 150x;
  height: 15px;
  position: absolute;
}

.overlay-text {
  font-size: 1.75em;
  color: white;
  font-family: monospace;
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

#overlay-bottom-left-text {
  position: absolute;
  bottom: var(--border-space);
  left: var(--border-space);
}

#overlay-bottom-right-text {
  position: absolute;
  bottom: var(--border-space);
  right: var(--border-space);
} */
</style>