import moment from 'moment-timezone'
import _ from 'lodash'
import t from 'typy'
import {VueEditor} from "vue2-editor";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";

export default {
    components: {
        VueEditor,
        ConfirmationModalComponent,
        LanguageButtonComponent
    },
    data() {
        return {
            data: {
                metadata: {}
            },
            customToolbar: [
                ["bold", "italic", "underline", "link"],
                [{list: "ordered"}, {list: "bullet"}]
            ],
            config: {
                saved: false
            }
        }
    },
    async mounted() {


        this.facility_id = window._app_facility.id

        this.show(this.$i18n.locale);
    },
    methods: {
        async show(lang) {
            var label;
            switch(lang) {
                case "en":
                    label = 'Terms and Condition'
                    break;
                case "ja":
                    label = '利用規約'
                    break;
                case "ko":
                    label = '이용 약관'
                    break;
                case "zh-TW":
                    label = '服務條款'
                    break;
                case "zh-CN":
                    label = '服务条款'
                    break;
                default:
                    break;
            }

            const $terms = await this.$lionheart.get('/v2/terms-and-conditions/' + this.facility_id + '?', {
                params: {
                    lang: lang,
                    system_name: 'terms_conditions'
                }
            }).then(r => r.data.data);

            if (Object.keys($terms).length !== 0) {
                this.data = $terms;
                this.data.export_sort = 28;
                this.config.saved = false;
            } else {
                this.data = {
                    language: lang,
                    visibility: false,
                    metadata: {
                        content: ''
                    },
                    input_type: "string",
                    section: "optional",
                    is_core: 0,
                    label: label,
                    system_name: "terms_conditions",
                    id: new Date().getTime() + '_new',
                    facility_id: window._app_facility.id,
                    export_sort: 28,
                    rank: 27,
                    required: 1
                }
            }
        },
        async submit(data) {

          if(data){
              const response = await this.$lionheart.post('/v2/terms-and-conditions', {...this.data}).then(r => r.data);

              this.config.saved = response.success;
              window.scrollTo(0,0);
          }


        },
    }
}
