<template>
  <div class="mt-n2">
    <template v-if="isLoaded && isDataLoaded && digital_keys.length > 0">
      <div class="mb-3" v-for="x in digital_keys">
        <div class="panel-header">
          <b>{{ $t("visitor_log_detail_digital_key.header") }}</b>
        </div>
        <div class="panel-body">
          <table class="table table-sm table-bordered w-100 mb-0">
            <tr>
              <td>{{ $t("visitor_log_detail_digital_key.code") }}</td>
              <td>
                <code>{{ x.pin }}</code>
              </td>
            </tr>
            <tr>
              <td>{{ $t("visitor_log_detail_digital_key.room_name") }}</td>
              <td>
                {{ x.room_name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("visitor_log_detail_digital_key.room_id") }}</td>
              <td>
                {{ x.room_number }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("visitor_log_detail_digital_key.validity") }}</td>
              <td>
                {{ `${x.validity_start} - ${x.validity_end}` }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
    <p v-else-if="isLoaded && digital_keys.length === 0">
      {{ $t("visitor_log_detail_digital_key.not_available") }}
    </p>
  </div>
</template>

<style scoped>
td:first-child {
  min-width: 100px;
}
.qr-wrapper {
  border: 1px solid #dee2e6;
  float: left;
  margin-right: 5px;
  clear: both;
}
</style>

<script>
import _ from "lodash";

export default {
  props: {
    visitorLog: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      digital_keys: [],
      isLoaded: false, // track digital key loading state
      isDataLoaded: false // track if data is successfully loaded
    };
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() { // wait for digital key endpoint to finish loading
      try {
        await this.fetchData();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoaded = true;
      }
    },
    async fetchData() {
      if(this.visitorLog.booking_details["pms_reservation_no"]) {
        const facility = window._app_facility;

        const response = await this.$lionheart.get(
          "/v2/facility-bookings/f/digital-key",
          {
            params: {
              pms_code: this.visitorLog.booking_details["pms_code"],
              pass: this.visitorLog.booking_details["pass"],
              pms_reservation_no:
                this.visitorLog.booking_details["pms_reservation_no"],
              facility_id: facility.id,
              room_number: this.visitorLog.booking_details["room_number"],
            },
          }
        );
        this.digital_keys = response.data.data.data;
        if (response.status === 200) {
          this.isDataLoaded = true;
        }
      }
    },
  },
};
</script>
