<template>
  <div :class="td ? '' : 'mt-2 mb-4'">
    <b-card class="mb-1" no-body>
      <template #header>
        <b>{{ $t("pre_checkin_amatch.booking_details_title") }}</b>
      </template>
      <div class="table-responsive mb-0">
        <table class="table table-bordered table-inner-border font-res">
          <tr v-for="(map, index) in template_specs.list" :key="`m_${index}`">
            <td>{{ map.key }}:</td>
            <td>{{ extractValue(map, booking_details.data[0]) || '--' }}</td>
          </tr>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { paid_payment_status } from "@/modules/checkin-facility/utils/mockup";
// guest state store
import { mapState, mapActions } from "vuex";

export default {
  props: {
    facility: Object,
    booking_details: Object,
    questions: Array,
    noQuestions: Boolean,
    td: Boolean,
    currency: String,
    lang: String,
  },
  data() {
    return {
      paid_payment_status,
      moment,
      template_specs: {
        list: []
      }
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  async mounted() {
    await this.getTemplate();
    if (!this.noQuestions) this.populate();
  },
  methods: {
    // map actions
    ...mapActions("guest", [
        "saveNumberOfGuest"
    ]),

    async getTemplate() {
      this.$emit("template-loaded", true);
      const fid = this.facility.id;
      const response = await this.$lionheart
        .get(`/facility-setting/data-template/${fid}/pci_booking_detail`, { params: { parse: true }})
        .then((r) =>
          this.catcher(r, "data.value.specs", (r) => r.data.value.specs)
        )
        .catch((e) => this.catcher(e));
      this.template_specs = response;
      this.$emit("template-loaded", false);
    },
    populate() {
      const mappings = {
        address: this.booking_details.data[0].parsed.full_address,
        first_name: this.booking_details.data[0].parsed.full_name,
        phone: this.booking_details.data[0].guest_contact_no,
        full_name: this.booking_details.data[0].parsed.full_name,
      };

      const populates = {
        forms: this.questions
          .filter((obj) => Object.keys(mappings).includes(obj.system_name))
          .map((obj) =>
            Object.assign(obj, { answer: mappings[obj.system_name] })
          ),
        email: this.booking_details.data[0].parsed.email_address,
      };

      // save the number of guest
      this.saveNumberOfGuest(this.booking_details.data[0].parsed.total_number_of_guests);

      this.$emit("populate", populates);
    },
    extractValue(map, obj) {
      try {
        const obj_value = map.value.split('.').reduce((a, i) => a[i], obj);
        if (!Number.isInteger(obj_value) && !obj_value) return false;
        if (['checkin_date', 'checkout_date'].includes(map.system_name)) {
          return moment(obj_value, "YYYYMMDD").format("YYYY-MM-DD");
        }
        if (['total_charge', 'net_charge'].some(v => v === map.system_name)) {
          return this.paid_payment_status.includes(obj.payment_status_id)
                ? this.$t("pre_checkin_amatch.paid") : obj_value;
        }

        return obj_value;
      } catch(e) { return false; }
    },
  },
  watch: {
    async locale() {
      await this.getTemplate();
    }
  }
};
</script>

<style lang="scss" scoped>
$font-size-base: 1rem;

.font-res {
  font-size: $font-size-base;
}

@media (max-width: 1200px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 768px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 425px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 375px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 320px) {
  .font-res {
    font-size: $font-size-base * 0.48;
  }
}
</style>