var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.multiple && !_vm.isFixGuestEnabled)?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-sm-8 my-auto"},[(!_vm.confirm)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputData[_vm.c.id]),expression:"inputData[c.id]"}],staticClass:"form-control",attrs:{"type":"number","step":"0.1","required":_vm.c.required ? true : false,"placeholder":_vm.c.placeholder},domProps:{"value":(_vm.inputData[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputData, _vm.c.id, $event.target.value)}}}):_vm._e(),(_vm.confirm)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.inputData[_vm.c.id]))]):_vm._e(),(_vm.companions.length > 0 && !_vm.confirm)?[_vm._l((_vm.companions),function(companion,index){return [(!_vm.confirm && _vm.different.includes(_vm.c.system_name))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(companion.other_forms[_vm.c.id]),expression:"companion.other_forms[c.id]"}],staticClass:"form-control mt-3",attrs:{"type":"number","step":"0.1","required":_vm.c.required ? true : false,"placeholder":_vm.showLabelOrPlaceholder(
                _vm.$t('visit-information-form.companion'),
                index,
                _vm.c.placeholder,
                'placeholder'
              )},domProps:{"value":(companion.other_forms[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(companion.other_forms, _vm.c.id, $event.target.value)}}}):_vm._e()]})]:_vm._e()],2)]):(!_vm.multiple && _vm.isFixGuestEnabled)?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-sm-8 my-auto"},[(!_vm.confirm)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.visitor_forms[_vm.c.id]),expression:"visitor_forms[c.id]"}],staticClass:"form-control",attrs:{"type":"number","step":"0.1","required":_vm.c.required ? true : false,"placeholder":_vm.c.placeholder},domProps:{"value":(_vm.visitor_forms[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.visitor_forms, _vm.c.id, $event.target.value)}}}):_vm._e(),(_vm.confirm)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.visitor_forms[_vm.c.id]))]):_vm._e(),(_vm.fixGuestCount > 1 && !_vm.confirm)?[_vm._l((_vm.companions),function(companion,index){return [(!_vm.confirm && _vm.different.includes(_vm.c.system_name))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(companion.other_forms[_vm.c.id]),expression:"companion.other_forms[c.id]"}],staticClass:"form-control mt-3",attrs:{"type":"number","step":"0.1","required":_vm.c.required ? true : false,"placeholder":_vm.showLabelOrPlaceholder(
                _vm.$t('visit-information-form.companion'),
                index,
                _vm.c.placeholder,
                'placeholder'
              )},domProps:{"value":(companion.other_forms[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(companion.other_forms, _vm.c.id, $event.target.value)}}}):_vm._e()]})]:_vm._e()],2)]):_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-sm-8"},[(!_vm.confirm)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id]),expression:"multiple_data[bd_index].visitor_forms[c.id]"}],staticClass:"form-control",attrs:{"type":"number","step":"0.1","required":_vm.multiple_data[_vm.bd_index].filled_in_by_other
            ? false
            : _vm.c.required
            ? true
            : false,"placeholder":_vm.c.placeholder},domProps:{"value":(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.multiple_data[_vm.bd_index].visitor_forms, _vm.c.id, $event.target.value)}}}):_vm._e(),(_vm.confirm)?_c('p',[_vm._v(_vm._s(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id]))]):_vm._e(),(_vm.multiple_data[_vm.bd_index].companions.length > 0 && !_vm.confirm)?[_vm._l((_vm.multiple_data[_vm.bd_index].companions),function(companion,index){return [(!_vm.confirm && _vm.different.includes(_vm.c.system_name))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(companion.other_forms[_vm.c.id]),expression:"companion.other_forms[c.id]"}],staticClass:"form-control mt-3",attrs:{"type":"number","step":"0.1","required":_vm.c.required ? true : false,"placeholder":_vm.showLabelOrPlaceholder(
                _vm.$t('visit-information-form.companion'),
                index,
                _vm.c.placeholder,
                'placeholder',
                true,
                _vm.bd_index
              )},domProps:{"value":(companion.other_forms[_vm.c.id])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(companion.other_forms, _vm.c.id, $event.target.value)}}}):_vm._e()]})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }