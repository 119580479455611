<template>
    <div id="precheckin-custom" :class="`pre-checkin ` + (is_loading ? 'd-none' : '')">
        <div id="pcc_error_msg" v-if="error_msg" class="alert alert-danger" role="alert">
            {{ error_msg }}
        </div>
        <div id="pcc_error_msg_2" v-if="booking_error_msg" class="alert alert-danger" role="alert">
            {{ $t("pre_checkin_bmatch.not_found_msg") }}
        </div>
        <div id="pcc_error_msg_3" v-if="duplicate_error.status" class="alert alert-danger" role="alert">{{ $t("pre_checkin_bmatch.duplicate_" + duplicate_error.mode) }}</div>
        <div class="choices container">
            <ul class="nav nav-pills mb-3 for_desk row" id="pills-tab" role="tablist">
                <!-- Phone START -->
                <li class="nav-item p-1 col-12" role="button">
                    <a
                    class="nav-link text-center"
                    :class="{ active: tabActive == 'phone' }"
                    @click="tabActive = 'phone'"
                    v-b-toggle.accordion-phone>
                        <i class="fas fa-phone-alt mr-2" />
                        <div>
                            {{ $t("visit-information-form.phone") }}
                        </div>
                    </a>
                </li>
                <b-collapse id="accordion-phone" class="w-100" accordion="my-accordion" role="tabpanel">
                   <form @submit.prevent="submitByPhone" class="p-3 p-sm-4">
                        <div class="form-group">
                            <label class="phone-info">
                                {{ $t("pre_checkin_bmatch.wo_phone_label") }}
                                <i class="fas fa-info-circle text-primary" v-b-tooltip.hover :title="$t('pre_checkin_bmatch.wo_phone_tips')" />
                            </label>
                            <vue-tel-input mode="international" :validCharactersOnly="true" @country-changed="phoneCountryChange" @input="phoneInput" class="mt-2" v-model="phone" required :placeholder="$t('pre_checkin_bmatch.wo_phone_placeholder')" />
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-primary mb-2" :disabled="!phone">
                                {{ $t("visit-information-form.confirm") }}
                            </button>
                        </div>
                    </form>
                </b-collapse>
                <!-- Phone END -->
                <!-- Email START -->
                <li class="nav-item p-1 col-12" role="button">
                    <a
                    class="nav-link text-center"
                    :class="{ active: tabActive == 'email' }"
                    @click="tabActive = 'email'"
                    v-b-toggle.accordion-email>
                        <i class="fas fa-envelope mr-2" />
                        <div>
                            {{ $t("pre_checkin_bmatch.email_label") }}
                        </div>
                    </a>
                </li>
                <b-collapse id="accordion-email" class="w-100" accordion="my-accordion" role="tabpanel">
                   <form @submit.prevent="submitByEmail" class="p-3 p-sm-4">
                        <div class="form-group">
                            <label class="email-info">
                                {{ $t("pre_checkin_bmatch.email_label") }}
                                <i class="fas fa-info-circle text-primary" v-b-tooltip.hover :title="$t('pre_checkin_bmatch.email_tips')" />
                            </label>
                            <input v-model="email" required="required" type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-primary mb-2" :disabled="!email">
                                {{ $t("visit-information-form.confirm") }}
                            </button>
                        </div>
                    </form>
                </b-collapse>
                <!-- Email END -->
                <!-- Reservation START -->
                <li class="nav-item p-1 col-12" role="button">
                    <a
                    class="nav-link text-center"
                    :class="{ active: tabActive == 'rsv' }"
                    @click="tabActive = 'rsv'"
                    v-b-toggle.accordion-rsv>
                        <i class="fas fa-calendar-alt mr-2" />
                        <div>
                            {{ $t("visit-information-form.reservation-number") }}
                        </div>
                    </a>
                </li>
                <b-collapse id="accordion-rsv" :visible="this.$route.query.rid ? true : false" class="w-100" accordion="my-accordion" role="tabpanel">
                    <div class="row p-3 p-sm-4">
                        <div class="col-xl-5 col-sm-12 my-3 my-xl-0">
                            <div class="custom-control custom-checkbox">
                                <input v-model="rsvMode" required="required" type="radio" class="custom-control-input" id="customCheck1" name="check-in-code" value="check-in-email" data-title="Pre Check-in Email" />
                                <label class="custom-control-label" for="customCheck1">
                                    {{ $t("visit-information-form.pre-checkin-email") }}
                                </label>
                            </div>

                            <div class="custom-control custom-checkbox">
                                <input v-model="rsvMode" required="required" type="radio" class="custom-control-input" id="customCheck2" name="check-in-code" value="booking-site" data-title="Booking Site" />
                                <label class="custom-control-label" for="customCheck2">
                                    {{ $t("visit-information-form.booking-site") }}
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-7 col-sm-12">
                            <form @submit.prevent="handleRsvNumSubmit(rsvMode)">
                                <div class="form-group">
                                    <label for="exampleInputEmail1" class="rsrvat-title">
                                        {{ $t(`visit-information-form.${rsvLabel}`) }}
                                    </label>
                                    <input v-model="rsvNum" id="reservation-id-input" required="required" class="form-control" type="text" :placeholder="$t('visit-information-form.type-reservation-number')" />
                                </div>
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary mb-2" :disabled="!rsvNum">
                                        {{ $t("visit-information-form.confirm") }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </b-collapse>
                <!-- Reservation END -->
                <li class="nav-item p-1 col-12" v-if="td">
                    <a class="nav-link text-center" id="pills-others-tab" href="javascript:void(0)" role="tab" aria-controls="pills-others" aria-selected="false" @click="emittedData">
                        <i class="fas fa-ellipsis-h mr-2" />
                        <div>
                            {{ $t("visit-information-form.other") }}
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/custom.scss";

.pre-checkin {
    font-family: "Poppins", sans-serif;

    .choices {
        .nav-item {
            a.nav-link {
                height: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0;
                border: 2px solid #1489ff;
                color: #1489ff;
                font-size: 14px;
            }
            a.nav-link.active {
                border: none;
                background: #1489ff;
                color: #fff !important;
            }
        }
        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: none;
            border-radius: 0 !important;
            border-bottom: 1px solid #ced4da;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: unset;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: #cccccc;
                font-size: 12px;
            }
        }
        .vue-tel-input {
            border: none;
            border-bottom: 1px solid #dee2e6 !important;
            &:focus-within {
                box-shadow: unset;
            }
        }
        label {
            font-size: 14px;
            color: #4d4d4d;
        }
        .btn {
            border: none;
            border-radius: 20px;
            padding: 8px 50px;
            font-size: 14px;
            transition: all 0.5s ease-in-out;
        }
        .btn-primary {
            background: #1aa8de !important;
        }
    }
}

#precheckin-custom {
    $btn-color: #636363;

    .img-fluid {
        width: 300px;
        @extend .img-fluid;
    }

    .img-fluid-s {
        width: 200px;
        @extend .img-fluid;
    }

    .btn-custom {
        color: $btn-color;
    }

    .form-control {
        @extend .m-0, .mt-1;
    }

    .btn-top {
        @extend .btn-custom;
        font-size: calc(30px + (30 - 10) * ((100vw - 300px) / (1600 - 300)));
    }

    .btn-sub {
        @extend .btn-custom;
        font-size: calc(20px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
    }

    .btn-header {
        border: 1px solid #517ac4;
        background: transparent;
    }

    .accordion > .card,
    .card-header {
        border: none;
        background: transparent;
    }
    .btn:not(.collapsed) > .when-closed,
    .btn.collapsed > .when-open {
        display: none;
    }

    .highlight:not(.collapsed) {
        background-color: blue;
    }
    .highlight.collapsed {
        background-color: #1489ff;
    }

    .tip {
        color: #65abff;
        font-size: 20px;
    }
}
</style>

<script>
import { booking_status } from "./../../../utils/mockup";

export default {
    props: ["booking_details", "facility", "is_localized", "mr_visible"],

    data() {
        return {
            reservation_no: "",
            reservation_no_query: "",
            ota_number: "",
            phone: "",
            email: "",
            phoneSettings: {
                number: {
                    input: "",
                },
                possible: false,
            },
            error_msg: "",
            booking_error_msg: false,
            duplicate_error: {
                status: false,
                mode: "",
            },
            td: false,
            rsvMode: "check-in-email",
            rsvNum: "",
            rsvLabel: "pre-checkin-email-reservation-number",
            tabActive: null,
            is_loading: false,
        };
    },

    watch: {
        rsvMode(val) {
            if (val == "check-in-email") {
                this.rsvLabel = "pre-checkin-email-reservation-number";
            }
            if (val == "booking-site") {
                this.rsvLabel = "booking-site-reservation-number";
            }
        },
    },

    mounted() {
        if (this.$route.query.rid) {
            this.is_loading = true;
            this.$emit('update-is_loading', true);
            this.rsvNum = this.$route.query.rid;
            this.tabActive = 'rsv'
            document.getElementById("reservation-id-input").scrollIntoView({ behavior: "smooth", block: "center" });
            this.handleRsvNumSubmit(this.rsvMode); // auto confirm
        }

        if (this.$route.query.ref && this.$route.query.ref == "td") this.td = true;
    },
    methods: {
  
        submitReservation() {
            const search = this.reservation_no;
            this.getBooking({ mode: "reservation", search });
        },

        submitByPhone() {
            const dial_code = this.phoneSettings.country.dialCode;
            const phone_input = this.phoneSettings.number.input;
            let search = phone_input
                .replace(/ /g, "")
                .replace(`+${dial_code}`, "")
                .replace(/^(02|0|2)/g, "");

            search = `${dial_code}|${search}`;

            this.getBooking({ mode: "phone", search });
        },

        submitByOta() {
            const search = this.ota_number;

            this.getBooking({ mode: "ota", search });
        },

        submitByEmail() {
            const search = this.email;

            this.getBooking({ mode: "email", search });
        },

        async getBooking(obj) {
            // API call to MR settings
            await this.getMulipleRoomSetting();

            let booking_details = await this.$lionheart
                .post("/facility-booking/list", {
                    facility_id: this.facility.id,
                    mode: obj.mode,
                    search: obj.search,
                })
                .then((r) => this.catcher(r.data, "data", (r) => r))
                .catch((e) => this.catcher(e));

            if (booking_details.error.has_error) this.errorDisplay(booking_details);

            booking_details.data = booking_details.data.filter((item) => {
                return item.booking_status_id == booking_status.reserved;
            });
            booking_details.total = booking_details.data.length;

            if (booking_details.total > 1 && !this.mr_visible) {
                this.is_loading = false;
                this.$emit('update-is_loading', false);
                this.checkinErrorDuplicateDisplay("#pcc_error_msg_3");
                this.duplicate_error.mode = obj.mode;
                return (this.duplicate_error.status = true);
            }

            if (booking_details.total == 0) {
                this.is_loading = false;
                this.$emit('update-is_loading', false);
                this.checkinErrorDisplay("#pcc_error_msg_2");
                return (this.booking_error_msg = true);
            }

            // success result
            if (booking_details.total > 0) {
                if (booking_details.data[0].booking_status_id != booking_status.reserved) {
                    this.is_loading = false;
                    this.$emit('update-is_loading', false);
                    this.checkinErrorDisplay("#pcc_error_msg_2");
                    return (this.booking_error_msg = true);
                }

                this.$emit("update-booking", booking_details);
                this.$emit("update-next", this.is_localized ? "localized-filters" : "select-option");
                if (!this.is_localized) this.$emit("update-multiple-data", true);
                this.$emit("update-rsv-no", this.reservation_no);
                let reservation_no = booking_details.data[0].pms_reservation_no ?? null;
                this.$emit("update-rsv-no-query", reservation_no);
                this.is_loading = false;
                this.$emit('update-is_loading', false);
            }
        },

        async getMulipleRoomSetting() {
            const mrSetting = await this.$lionheart
                .post("/facility-setting/list", {
                    facility_id: this.facility.id,
                    name: "mr_setting",
                    paginate: false,
                })
                .then((r) => this.catcher(r.data, "data", (r) => r))
                .catch((e) => this.catcher(e));

            if (mrSetting.error.has_error) {
                this.errorDisplay(mrSetting);
                throw "Error on MR switch fetch";
            }

            if (mrSetting.total > 0) {
                this.$emit("update-mr_visible", mrSetting.data[0].value.visibility);
            }
        },

        phoneInput(str, obj) {
            this.phoneSettings = obj;
        },

        phoneCountryChange(obj) {
            if (this.phoneSettings.number.input != "") this.phone = "";
        },

        errorDisplay(d) {
            this.error_msg = d.error.msg;
            setTimeout(() => window.scrollTo(0, $("#pcc_error_msg").position().top - 10), 300);
            return;
        },

        checkinErrorDisplay(ref) {
            setTimeout(() => window.scrollTo(0, $(`${ref}`).position().top - 10), 300);

            setTimeout(() => (this.booking_error_msg = false), 3000);
        },
        checkinErrorDuplicateDisplay(ref) {
            setTimeout(() => window.scrollTo(0, $(`${ref}`).position().top - 10), 300);

            setTimeout(() => (this.duplicate_error.status = false), 5000);
        },
        imageLoader(name) {
            try {
                return require(`@/modules/checkin-facility/assets/img/${this.$i18n.locale}/${name}`);
            } catch (e) {
                return require(`@/modules/checkin-facility/assets/img/en/${name}`);
            }
        },

        handleRsvNumSubmit(rsvMode) {
            if (rsvMode == "check-in-email") {
                this.reservation_no = this.rsvNum;
                this.reservation_no_query = this.rsvNum;
                this.$emit('checkin-form-type', 'reservation')
                this.submitReservation();
            }
            if (rsvMode == "booking-site") {
                this.ota_number = this.rsvNum;
                this.reservation_no_query = this.rsvNum;
                this.$emit('checkin-form-type', 'reservation')
                this.submitByOta();
            }
        },
         //emit form type click
        emittedData() {
            this.$emit('update-next', this.is_localized ? 'localized-filters' : 'checkin-form')
            this.$emit('checkin-form-type', 'others');

        },
    },
};
</script>
