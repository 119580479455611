<template>
    <div class="mt-3 mb-5">
        <div class="wrapper mx-5" v-if="display">
            <div :class="$i18n.locale == 'ja' ? 'd-block' : 'd-none'">
                <h3 class="text-center">FAQ</h3>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h4 class="mb-4">施設側操作 </h4>
                        <div class="accordion" role="tablist">
                            <!-- Facility Start -->
                            <template>
                                <!-- System Requirements Start -->
                                <h6 class="mb-3">動作環境</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-ja-1
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">古いデスクトップ/ノートPCを使用しています。システムの動作に影響はありますか？施設で使用できるコンピュータの機種に制限はありますか？</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-1" visible accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        古いデスクトップ/ノートPCをお使いの場合でも問題ありません。また、機種やOSの制限もありません。ただし、Google ChromeとMicrosoft Edgeの最新版をご利用いただくことをお勧めします。
                                    </div>
                                </b-collapse>
                                <!-- System Requirements End -->
                                

                                <!-- Check-ins Start -->
                                <h6 class="mb-3 mt-4">チェックイン</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-ja-2
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">一日にチェックインできるゲスト数の制限はありますか？</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-2" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        いいえありません。ゲストはチェックインフォームや施設のロビーにあるQRコードを読み取ることができれば制限なくチェックインすることができます。 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-3
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">チェックインリストは何年分のチェックインデータまで保存できますか？</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-3" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        本システムで収集されたデータは無制限で保存できます。 
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="facility.has_pms"
                                block
                                v-b-toggle.accordion-ja-4
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">予約ステータスでゲストのチェックイン状況についておしえてください。</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-4" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        ゲストの予約ステータスは以下の3つで表示されています。 
                                        <ul>
                                            <li>「事前チェックイン」の場合　：ゲストが既に事前チェックインをしている状態</li>
                                            <li>「チェックイン済」の場合　　：ゲストが施設に到着しチェックインが完了している状態 </li>
                                            <li>「チェックアウト完了」の場合：ゲストがチェックアウトしている状態</li>
                                        </ul>
                                        予約ステータスのご説明はスタートガイドにも記載されておりますのでご覧ください。 
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="facility.has_pms"
                                block
                                v-b-toggle.accordion-ja-5
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">支払いステータスでゲストの支払い方法が既に支払いが済んでいるQTA支払いや施設WEBサイト支払いなのか、未支払いで現地現金支払い、現地クレジット支払いなのかが分かりません。表示についておしえてください。 </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-5" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        それぞれの支払いステータスは以下の状況を示しています。
                                        <ul>
                                            <li>「未払い」の場合 <br> 支払いがまだの状態 </li>
                                            <li>「支払済み」の場合 <br> ゲストが既に予約サイトで予約時にクレジット、または他の方法で支払い済 </li>
                                            <li>「支払い承認済み」の場合：<br> ゲストがクレジットカードで施設への支払いを選択し承認が済んでいる状態 </li>
                                            <li>「支払い確定済み」の場合：<br> ゲストがクレジットカードで施設への支払いを選択し承認が確定している状態 <br> (承認から確定に移行する期間は5日程になります。) </li>
                                            <li>「現地フロントで支払う」の場合：<br> ゲストがお支払い方法を現地支払に選択している状態 <br> (支払いのタイミングはチェックイン、チェックアウト時が選択可能です。施設ごとに設定できます。)</li>
                                            <li>「返金済み」の場合：<br> ゲストへの返金は済んでいる状態 </li>
                                        </ul>
                                        支払いステータスのご説明はスタートガイドにも記載されておりますのでご覧ください。 
                                    </div>
                                </b-collapse>
                                <!-- Check-ins End -->
                                
                                <!-- Layout Start -->
                                <h6 class="mb-3 mt-4">設定</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-ja-6
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">チェックインフィルタの設定はどのようにしたらよいでしょうか？</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-6" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        チェックインフィルタの設定目的は、「国内居住」「海外居住」がほとんどです。 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-7
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">ゲストのチェックインフォームの質問項目は決まった内容から選択でき、任意の質問も作成できますか? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-7" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        はい、作成できます。<br> 本システムには初期設定として連絡先、衛生に関する質問、移動に関する質問などの実装しており、それらの質問を取捨選択していただくことができます。また独自の質問項目を作成いただくことも可能です。  
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-8
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">表示したいゲストの情報やデータが、チェックインリストにありません。どうすれば表示できますか？ </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-8" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        現在、チェックインリスト画面に表示できる項目は以下の7つです。 
                                        <ul>
                                            <li>氏名 </li>
                                            <li>住所 </li>
                                            <li>電話 </li>
                                            <li>Eメール </li>
                                            <li>国籍 </li>
                                            <li v-if="facility.has_pms">支払ステータス </li>
                                            <li v-if="facility.has_pms">予約ステータス </li>
                                        </ul>
                                        ダッシュボードのチェックインリスト画面で、チェックインリストの右端にある下矢印をクリックし、「表示編集」を選択します。ダッシュボードに表示することのできる項目が表示されます。表示したい項目はOn、隠したい項目はOffに設定してください。万が一リストにないデータの表示をご希望の場合はご相談ください。 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-9
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">利用規約の追加方法と追加後にどのようにゲスト画面表示されるのでしょうか？ </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-9" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        はじめにダッシュボード（施設側管理画面）の規約を選択し、表示をONにしてください。規約内容を入力フォームに記入してください。その後、ダッシュボード（施設側管理確認画面）のQRコード内、到着フォームをご自身のスマートフォンやタブレット端末で読み込んでいただき、チェックイン画面を表示させてください。画面の「いまからチェックインを行う方」→「その他」の順で選択し、チェックイン情報入力を進めてください。規約は通常のチェックインが完了する前の確認のページの最後に「利用規約+」として表示されます。内容は規約横の「+」を押すと表示されますのでここでご確認ください。 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-10
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">ダッシュボードに施設ロゴの追加や背景色などの表示方法を変更することはできますか？  </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-10" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        ダッシュボード自体の表示方法の変更はできません。チェックインリスト画面のデータの表示方法のみ変更は可能となります。データ表示の変更については <a href="javascript:void(0)" v-b-toggle.accordion-ja-8>「表示したいゲストの情報～」</a> を参照してください。 
                                    </div>
                                </b-collapse>
                                <!-- Layout End -->
                                
                                <!-- Others Start -->
                                <h6 class="mb-3 mt-4">その他 </h6>
                                <b-button
                                block
                                v-b-toggle.accordion-ja-11
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">ゲストの証明書のダウンロードをする場合、設定が一日単位になっていますが月単位でそれぞれ日付に分けてダウンロードすることはできますか？ </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-11" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        ダウンロードの日付設定は1日単位での設定にしています。理由はダウンロードをする際、画像データ数が日によって異なり容量の大きさや、ネット通信環境によりダウンロードが途中で止まってしまう可能性があるためです。
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="!facility.has_pms"
                                block
                                v-b-toggle.accordion-ja-12
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">カスタマイズ版 (PMSとの連携) の費用はどのくらいかかりますか? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-12" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        問い合わせください。
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="!facility.has_pms"
                                block
                                v-b-toggle.accordion-ja-13
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">導入から実際に使えるまでの期間はどのくらいかかりますか? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-13" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        問い合わせください。
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-ja-14
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">アコモで取り扱う個人情報の取り扱いに関しておしえてください? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-14" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        当社で取り扱う個人情報は当社のプライバシーポリシーをご確認ください。なお、来場者の個人情報に関してはマーケティングや販売など当社で活用することはありません。
                                    </div>
                                </b-collapse>
                                <!-- Others End -->
                            </template>
                            <!-- Facility End -->
                            
                            <!-- Guest Start -->
                            <template>
                                <h4 class="mb-4 mt-5">ゲスト </h4>

                                <!-- Phone/System Requirements Start -->
                                <h6 class="mb-3 mt-4">利用可能端末</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-ja-15
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">古い携帯電話を使っています。チェックインの利用に問題はありませんか？  </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-ja-15" accordion="my-accordion-ja" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        チェックインのご利用の端末にシステム上の制約はありません。QRコードを読み取る機能があり、インターネットに接続できれば古い機種でも問題ありません。この機能がない場合は、ホテルのフロントでチェックインを行ってください。 
                                    </div>
                                </b-collapse>
                                <!-- Phone/System Requirements End -->
                                
                                <template v-if="facility.has_pms">
                                    <!-- Check-ins Start -->
                                    <h6 class="mb-3 mt-4">チェックイン時エラー</h6>
                                    <b-button
                                    block
                                    v-b-toggle.accordion-ja-16
                                    variant="outline-dark"
                                    class="text-left mb-2 d-flex justify-content-between">
                                        <div class="pr-3">クイックチェックインができません、どうすればよいでしょうか？</div>
                                        <span class="when-opened">
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </span>
                                        <span class="when-closed">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </span>
                                    </b-button>
                                    <b-collapse id="accordion-ja-16" accordion="my-accordion-ja" role="tabpanel">
                                        <div class="p-3 mb-3">
                                            <ul>
                                                <li>この現象には以下の3点が考えられます。 </li>
                                                <li>1.施設に設置してあるQRスキャナーが正常に動作していない可能性があります。QRスキャナーがパソコン/タブレットに接続されているかをお確かめいただき、その後ゲストのQRコードが読み取りを実施してください。 </li>
                                                <li>2.お客様が事前チェックインをされていない可能性があります。ホテルからお送りしている事前チェックインメールにアクセスしていただきチェックインを行ってください。チェックインが完了するとお客様の端末にクイックチェックイン用のQRコードが送付されますのでこちらを使ってクイックチェックインを行ってください。 </li>
                                                <li>3.システムエラーが発生している可能性があります。その場合はお手数ですがお問い合わせください。 </li>
                                            </ul>
                                        </div>
                                    </b-collapse>

                                    <b-button
                                    block
                                    v-b-toggle.accordion-ja-17
                                    variant="outline-dark"
                                    class="text-left mb-2 d-flex justify-content-between">
                                        <div class="pr-3">ゲストのスマホでQRコードが読み込めない場合はどうしたらよいですか? </div>
                                        <span class="when-opened">
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </span>
                                        <span class="when-closed">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </span>
                                    </b-button>
                                    <b-collapse id="accordion-ja-17" accordion="my-accordion-ja" role="tabpanel">
                                        <div class="p-3 mb-3">
                                            施設に専用のタブレット端末を数台ご準備いただくか、万が一エラーが発生する場合は従来通りの紙ベースでのご対応をお願いいたします。 
                                        </div>
                                    </b-collapse>
                                    <!-- Check-ins End -->
                                </template>

                            </template>
                            <!-- Guest End -->

                        </div>
                    </div>
                </div>
            </div>
            <div :class="$i18n.locale != 'ja' ? 'd-block' : 'd-none'">
                <h3 class="text-center">FAQ</h3>
                <div class="row mt-4">
                    <div class="col-lg-12">
                        <h4 class="mb-4">FACILITY USER</h4>
                        <div class="accordion" role="tablist">
                            <!-- Facility Start -->
                            <template>
                                <!-- System Requirements Start -->
                                <h6 class="mb-3">System Requirements</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-en-1
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">I am using an old desktop/laptop. Will this affect the system’s performance? Are there any restrictions on the computer models that the facility can use?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-1" visible accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        There is no problem if you are using an old desktop/laptop. Also, there are no restrictions on models or operating systems. However, we encourage you to use the latest versions of Google Chrome and Microsoft Edge.
                                    </div>
                                </b-collapse>
                                <!-- System Requirements End -->
                                

                                <!-- Check-ins Start -->
                                <h6 class="mb-3 mt-4">Check-ins</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-en-2
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">Is there a limit to the guest check-ins we can make in a day?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-2" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        None. Guests can check-in as long as they have the link to the check-in form or scan the QR code on the facility’s lobby.
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-3
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">Up to how many years of check-in data can be stored in the check-in list?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-3" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        The system can store unlimited check-in data.
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="facility.has_pms"
                                block
                                v-b-toggle.accordion-en-4
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">What are the check-in statuses of guests in the reservation status field?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-4" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        The guest's reservation status is indicated by the following three items. 
                                        <ul>
                                            <li>Pre Checked-in: Guest has already checked in in advance.</li>
                                            <li>Checked in: Guest has already arrived at the facility and is checking in.</li>
                                            <li>Checkout completed: Guest has already checked out.</li>
                                        </ul>
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="facility.has_pms"
                                block
                                v-b-toggle.accordion-en-5
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">We don’t know whether the guest’s payment method in the payment status is made thru QTA payment, facility website payment, local cash payment, or local credit payment. Please tell me about the display.</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-5" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        The guest's payment status is indicated by the following.
                                        <ul>
                                            <li>Not paid: Payment has not yet been made</li>
                                            <li>Paid: Guest has already paid by credit card or other means at the time of booking on the reservation site.</li>
                                            <li>Paid Authorized: Guest has paid for the accommodation with a credit card and has been authenticated.</li>
                                            <li>Paid Captured: Guest has paid for the accommodation by credit card and the payment has been confirmed. (The transition period from approval to finalization will be 5 days.)</li>
                                            <li>Pay at Hotel Reception: Guest has selected the payment method as local payment. (The timing of local payment varies depending on the facility's setup.)</li>
                                            <li>Refunded: Guest’s payment has been refunded.</li>
                                        </ul>
                                    </div>
                                </b-collapse>
                                <!-- Check-ins End -->
                                
                                <!-- Layout Start -->
                                <h6 class="mb-3 mt-4">Setting</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-en-6
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">How should I set up the check-in filter? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-6" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        The purpose of setting the check-in filter is mostly "domestic residence" and "overseas residence. 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-7
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">The questions on the guest check-in form can be selected from a set of questions, can I also create optional questions? </div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-7" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        Yes, you can create it. The system comes with a set of default questions, such as contact information, hygiene questions, and mobility questions, which can be selected by the user. It is also possible to create your own questions. 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-8
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">The guest information/data I like to show is not in the check-in list dashboard. How do I show it?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-8" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        By default, you can display the following guest information in the check-in list dashboard: 
                                        <ul>
                                            <li>Full Name</li>
                                            <li>Address</li>
                                            <li>Phone</li>
                                            <li>Email</li>
                                            <li>Nationality</li>
                                            <li v-if="facility.has_pms">Payment Status</li>
                                            <li v-if="facility.has_pms">Booking Status</li>
                                        </ul>
                                        You can switch on or off the display for each. Just click down the arrow in the right side of the check-in list and select “Edit Column View”. A pop-up box will appear, showing all data that you can display in your dashboard. Switch ON the data you would like to show and switch OFF if you like to hide it from the dashboard.<br><br>
                                        If the data you want to show is not in the list, please contact us for more info. 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-9
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">How do I add Terms & Conditions in the form? And how will the guest see this?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-9" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        To add a Terms & Condition in the form, click “Terms” in the sidebar. Then fill-out the text field. You can write in different languages. You also have the option to switch ON or OFF for certain languages.<br><br>
                                        Once you have set the Terms & Condition, you can see how it looks by checking-in as a guest using the online form. Fill out the form until you reach the confirmation page. In the end of the form, click the + sign. The terms and conditions will be shown as a pop-up.
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-10
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">Can I change the way my dashboard looks (e.g., add a logo, change the color, etc)?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-10" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        You can only change the data displayed in the dashboard. To do this, <a href="javascript:void(0)" v-b-toggle.accordion-en-8>please refer to this question.</a>
                                    </div>
                                </b-collapse>

                            
                                <!-- Layout End -->
                                
                                <!-- Others Start -->
                                <h6 class="mb-3 mt-4">Others</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-en-11
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">When downloading guest images, the setting is set to daily, but is it possible to download them on certain range of dates for each month?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-11" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        The date setting for downloads is set to only one day at a time. This is to easily download the pictures and avoid any error when an interruption in downloading occurs. The size of the image data varies from day to day.
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="!facility.has_pms"
                                block
                                v-b-toggle.accordion-en-12
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">How much does it cost if I want it to integrate with the PMS I am using?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-12" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        Please contact us for more info.
                                    </div>
                                </b-collapse>

                                <b-button
                                v-if="!facility.has_pms"
                                block
                                v-b-toggle.accordion-en-13
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">How long does it take from installation to actual use?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-13" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        Please contact us for more info. 
                                    </div>
                                </b-collapse>

                                <b-button
                                block
                                v-b-toggle.accordion-en-14
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">How does Acomo handle personal information?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-14" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        Please check our privacy policy for personal information handled by us. The personal information of visitors will not be used by us for marketing or sales activities.
                                    </div>
                                </b-collapse>
                                <!-- Others End -->
                            </template>
                            <!-- Facility End -->
                            
                            <!-- Guest Start -->
                            <template>
                                <h4 class="mb-4 mt-5">GUEST</h4>

                                <!-- Phone/System Requirements Start -->
                                <h6 class="mb-3 mt-4">Phone/System Requirements</h6>
                                <b-button
                                block
                                v-b-toggle.accordion-en-15
                                variant="outline-dark"
                                class="text-left mb-2 d-flex justify-content-between">
                                    <div class="pr-3">I am using an old phone. Will I have a problem using the online check-in form?</div>
                                    <span class="when-opened">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                </b-button>
                                <b-collapse id="accordion-en-15" accordion="my-accordion-en" role="tabpanel">
                                    <div class="p-3 mb-3">
                                        There is no system requirement in using the online check-in form. It is okay to use an old phone model as long as it has a camera to scan QR code and with an internet connection. Without this, guest can only check-in from the hotel reception.
                                    </div>
                                </b-collapse>
                                <!-- Phone/System Requirements End -->
                                
                                <template v-if="facility.has_pms">
                                    <!-- Check-ins Start -->
                                    <h6 class="mb-3 mt-4">Check-ins</h6>
                                    <b-button
                                    block
                                    v-b-toggle.accordion-en-16
                                    variant="outline-dark"
                                    class="text-left mb-2 d-flex justify-content-between">
                                        <div class="pr-3">A guest cannot do quick check-in. What should the guest do? </div>
                                        <span class="when-opened">
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </span>
                                        <span class="when-closed">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </span>
                                    </b-button>
                                    <b-collapse id="accordion-en-16" accordion="my-accordion-en" role="tabpanel">
                                        <div class="p-3 mb-3">
                                            <ul>
                                                <li>Case 1. Your QR scanner might not be working properly. Please check your QR scanner if it is connected to your computer/tablet and try to scan the guest’s QR code again. </li>
                                                <li>Case 2. The guest did not do precheck-in. To use quick check-in, a guest should provide a QR code which is generated upon pre-check-in. Please ask the guest to fill-out the arrival form instead. </li>
                                                <li>Case 3. There is an error in the system. Please contact us for more info. </li>
                                            </ul>
                                        </div>
                                    </b-collapse>

                                    <b-button
                                    block
                                    v-b-toggle.accordion-en-17
                                    variant="outline-dark"
                                    class="text-left mb-2 d-flex justify-content-between">
                                        <div class="pr-3">What should I do if I cannot read the QR code on my guest's phone?</div>
                                        <span class="when-opened">
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </span>
                                        <span class="when-closed">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </span>
                                    </b-button>
                                    <b-collapse id="accordion-en-17" accordion="my-accordion-en" role="tabpanel">
                                        <div class="p-3 mb-3">
                                            Please prepare several dedicated tablet terminals at your facility, or if errors occur, please continue to use the conventional paper-based system.
                                        </div>
                                    </b-collapse>
                                    <!-- Check-ins End -->
                                </template>

                            </template>
                            <!-- Guest End -->

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                facility: {},
                display: false,
            }
        },
        mounted() {
            this.facility = window._app_facility;
            this.display = true
        },
        methods: {
        },
    }
</script>
<style>
    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>