<template>
  <div>
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">
        {{ $t("account-page-payment.currency_note") }}
      </h6>
    </div>
    <div class="card-body">
      <div class="col-lg-12" v-if="success">
        <div class="alert alert-success">
          {{ $t("account-page-payment.currency_success") }}
        </div>
      </div>
      <form class="p-4">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">
            {{ $t("account-page-payment.currency_title") }}
          </label>
          <div class="col-sm-6">
            <select v-model="selectedCurrency" class="form-control">
              <option
                v-for="currency in currencies"
                :key="currency.code"
                :value="currency.code"
              >
                {{ currency.code }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12" align="center">
            <router-link
              class="btn btn-danger ml-3"
              type="button"
              :to="{ name: 'checkin-facility-dashboard-home' }"
            >
              {{ $t("account-page-payment.payment_option_back_btn") }}
            </router-link>         
            <button
              @click="$bvModal.show('modal-confirmation')"
              type="button"
              class="btn btn-primary ml-3"
            >
              {{ $t("account-page-payment.payment_option_confirm_btn") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <confirmation-modal-component @click-handler="submit" />
  </div>
</template>

<script>
import currencies from "currency-codes/data";
import ConfirmationModalComponent from "./confirmation-modal.component.vue";

export default {
  name: "Currency",
  components: {
    ConfirmationModalComponent,
  },
  props: {
    facility: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      success: false,
      currencies,
      selectedCurrency: "",
    };
  },
  async mounted() {
    await this.getCurrency();
    this.currencies = this.currencies.filter(
      (currency) => currency.code == "JPY"
    );
  },
  methods: {
    async submit() {
      
        const data = await this.$lionheart.post(
          `/v2/facilities/${this.facility.id}/currency`,
          { code: this.selectedCurrency }
        ).then(r => r.data);

        if (data.success) {
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 5000);
        }
    },
    async getCurrency() {
        const data = await this.$lionheart.get(
          `/v2/facilities/${this.facility.id}/currency`
        ).then(r => r.data.data);

        this.selectedCurrency = data.value;
    },
  },
};
</script>

<style>
</style>