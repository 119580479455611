<template />

<script>
export default {
  name: "Payment",
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  methods: {
    async createPayment(data) {
      data.currency = this.currency;
      const response = await this.$lionheart.post('/payment/pms', data)
        .then(success => {
            return {status: true, data: success.data.data}
        }).catch(error => {
            return {error: {msg: this.$t(`visit-information-form.${error.response.data.code}`)}, status: false}
        });

      if (!response.status) {
          return response;
      }

      return response;
    }
  }
};
</script>
