<template>
  <b-modal
    :id="id"
    @show="startCameraStream()"
    @close="stopCameraStream()"
    @hide="stopCameraStream()"
    @hidden="initialize"
  >
    <template #modal-header="{ close }">
        <span v-if="isForVaccine">
          {{ $t("visit-information-form.vaccine_card_take_photo") }}
        </span>
        <span v-else>
          {{ $t("visit-information-form.take_photo") }}
        </span>
      <button class="close" @click="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>

    <template #default>
      <div class="media-box">
        <video ref="camera" class="camera" muted playsinline autoplay />
        <canvas v-show="false" ref="canvas" />
        <div v-show="showBorder" class="overlay"><camera-scan-overlay /></div>
      </div>
      <div class="button-container mt-2">
         <button 
            @click="toggleCamera" 
            class="camera-switch-button"
         >
            <span class="fa-stack">
               <i class="fa fa-camera fa-stack-2x"></i>
               <i class="fa fa-sync-alt fa-stack-1x fa-inverse lower-icon"></i>
            </span>
         </button>
      </div>
      <div class="mt-2">
        <code v-if="isForVaccine">
          {{ $t("visit-information-form.vaccine_card_camera_label") }}​
        </code>
        <code v-else>
          {{ $t("visit-information-form.camera-label") }}​
        </code>
      </div>
      
    </template>

    <template #modal-footer>
      <div class="d-block w-100 text-center">
        <b-button
          variant="primary"
          class="btn-w-lg"
          @click="captureImage"
          :disabled="shotLimit()"
        >
          {{ $t("visit-information-form.shot") }} {{ shotCount }}/{{ shots }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CameraScanOverlay from "../../components/CameraScanOverlay"
import { mapState } from "vuex";

export default {
   name: "CameraComponent",
   components: { CameraScanOverlay },
   props: {
      id: {
         default: "camera",
         required: false,
         type: String || Number
      },

      shots: {
         required: true,
         type: Number
      },

      question_id: {
         required: true,
         type: Number
      },

      isForVaccine: {
         type: Boolean,
         default: false
      }
   },
   watch: {
      numberOfGuest() {},
      guestCountSetting() {},
      shots() {},
   },
   data() {
      return {
      images: [],
      shotCount: 0,
      showBorder: false,
      fixGuestCountEnabled: false,
      currentFacingMode: 'environment', // default to rear camera
      }
   },
   computed: {
      ...mapState("guest", [
         "numberOfGuest",
         "guestCountSetting"
      ]),
   },
   methods: {
      async startCameraStream(facingMode = this.currentFacingMode) {
         const constraints = {
            video: {
               audio: false,
               facingMode: facingMode // uses the specified camera
            }
         }

         // initialize shotcount
         this.getShotCount();

         try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints)

            window.stream = stream // make stream available to browser console

            if(this.$refs.camera) {
               this.$refs.camera.srcObject = stream
               this.showBorder = true
            }

         } catch (err) {
            console.log(err)
            alert("Cannot get camera devices.")
         }
      },

      stopCameraStream() {
         let tracks = this.$refs.camera.srcObject.getTracks()

         tracks.forEach(track => {
            if (track.readyState == "live") {
               track.stop()
            }
         })
         this.showBorder = false
      },
      // switch to front and back camera method
      toggleCamera() {
         this.currentFacingMode = this.currentFacingMode === 'environment' ? 'user' : 'environment'
         this.stopCameraStream()
         this.startCameraStream()
      },

      async captureImage() {
         const canvas = this.$refs.canvas;
         canvas.width = this.$refs.camera.videoWidth;
         canvas.height = this.$refs.camera.videoHeight;
         const context = canvas.getContext("2d");
         context.imageSmoothingEnabled = true;
         context.imageSmoothingQuality = "high";

         // Capture the image from the camera
         context.drawImage(this.$refs.camera, 0, 0);
         this.shotCount++;

         let img = {
            dataUrl: canvas.toDataURL("image/jpeg", 0.5),
            question_id: this.question_id,
         };

         this.images.push(img);

         await this.stopCameraStream();
         await this.startCameraStream();

         
         if (this.shotCount === this.shots) {
            setTimeout(() => {
               this.$emit("image-captured", this.images);
               this.$bvModal.hide(this.id);
            }, 1000);
         }

      },

      shotLimit() {
         return this.shotCount == this.shots
      },

      getShotCount() {
         const fixGuestEnabled = this.guestCountSetting?.fix_guest_count?.status ?? false;

         if(fixGuestEnabled) {
            if(this.numberOfGuest != null && this.numberOfGuest != undefined && this.numberOfGuest !== 0) {
               this.shots = this.numberOfGuest
            } else {
               this.shots = 1;
            }
         } 
      },

      initialize() {
         this.images = []
         this.shotCount = 0  
      }
   }
}
</script>

<style scoped>
.camera {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.media-box {
  position: relative;
  line-height: 0px;
}
.camera-switch-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
}
.button-container {
  display: flex;
  justify-content: center;
}
.lower-icon {
  position: relative;
  top: 0.1em;
}
</style>
