<template>
  <div style="position: relative">
    <div class="img-thumbnail media-box">

      <!-- preview mode -->
      <div v-show="!capturing" class="content-mode">
        <img class="img-fluid w-100" :src="src" />
        <div class="overlay">
          <div class="top-right">
            <button @click.prevent="$emit('re-take')" class="btn btn-sm btn-primary btn-w-md">
              <i class="fa fas-camera" />
              {{ $t("visit-information-form.retake") }}
            </button>
            <!-- ADDED props to not affect in vaccine photo commands -->
            <button v-if="index != 0 && allowRemoveCompanion" @click="removeCompanion" class="btn btn-sm btn-danger btn-w-md ml-2">
               {{ $t("visit-information-form.take_pictures_remove_companion_photo_text") }}
            </button>
          </div>
        </div>
      </div>

      <!-- CAPTURING PHOTO -->
      <div v-show="capturing" class="capture-mode">
        <video class="img-fluid w-100" :id="videoId" muted playsinline autoplay />
        <canvas class="d-none" :id="canvasId" />
        <div class="overlay"><camera-scan-overlay /></div>
      </div>

      <!-- CONTROL BUTTONS -->
      <div v-show="(capturing && disabledCapturing && src.length <= 0) && allowDynamicAdding" class="text-center mt-2 mb-1">
         <button
            class="btn btn-sm btn-outline-danger btn-w-md mx-1"
            @click.prevent="$emit('cancel')"
         >
            <span class="px-1">
               {{ $t("visit-information-form.take_pictures_remove_companion_photo_text") }}
            </span>
         </button>
      </div>
      <div v-show="capturing && !disabledCapturing" class="text-center mt-2 mb-1">
         <div class="button-container mt-2">
            <button 
               class="camera-switch-button"
               @click.prevent="$emit('toggle-camera')"
            >
               <span class="fa-stack">
                  <i class="fa fa-camera fa-stack-2x"></i>
                  <i class="fa fa-sync-alt fa-stack-1x fa-inverse lower-icon"></i>
               </span>
            </button>
         </div>
         <button
            class="btn btn-sm btn-outline-danger btn-w-md mx-1"
            @click.prevent="$emit('cancel')"
         >
            <span class="px-1">
            {{ $t("confirmation_modal.cancel") }}
            </span>
         </button>
         <button
            class="btn btn-sm btn-primary btn-w-md mx-1"
            @click.prevent="$emit('capture')"
         >
            <span class="px-1">
            {{ $t("visit-information-form.shot") }}
            </span>
         </button>
      </div>

      <!-- INPUT FOR NAME -->
      <div v-if="(showInput && capturing) && allowDynamicAdding" class="companion-name-form">
         <input type="text" class="name-input" :class="{ 'errorInput apply-shake' : showEmptyNameError}" :placeholder="$t('visit-information-form.take_pictures_input_name_note')" v-model="name[index]">
         <b-button variant="success" class="btn-w-lg" @click.prevent="saveCompanionName">
            {{ $t("visit-information-form.take_pictures_save_name_text") }}
         </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CameraScanOverlay from "./CameraScanOverlay"
import { eventBus } from "@/main";

export default {
  name: "CameraCaptureBox",
  components: { CameraScanOverlay },
  emits: ['re-take', 'capture', 'cancel', 'toggle-camera'],
   props: {
      videoId: {
         type: String,
         default: 'camvideo_id'
      },
      canvasId: {
         type: String,
         default: 'camcanvas_id'
      },
      capturing: {
         type: Boolean,
         default: false
      },
      src: {
         type: String,
         default: ''
      },
      showInput: {
         type: Boolean,
         default: false
      },
      disabledCapturing: {
         type: Boolean,
         default: true
      },
      index: {
         type: Number
      },
      allowRemoveCompanion: {
         type: Boolean,
         default: false
      },
      allowDynamicAdding: {
         type: Boolean,
         default: false
      },
      isForVaccine: {
         type: Boolean,
         default: false
      }
   },
   data() {
      return {
         name: [],
         showEmptyNameError: false
      }
   },
   mounted(){
      eventBus.$on('setNameOfGuest', (nameOfGuest,index) => {
         // this.$set(this.name, nameOfGuest, index);
         this.name[index] = nameOfGuest;
         console.log('array of names ',this.name)
      })
   },
   methods: {
      saveCompanionName() {
         if(this.name.length <= 0) {
            setTimeout(() => {
               this.showEmptyNameError = true; 
            })
            this.showEmptyNameError = false;
            return;
         }
         this.showEmptyNameError = false;
         this.$emit('saveName',this.name);
      },
      removeCompanion (e) {
         this.$emit('removeCompanion',e);
      }
   }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
.media-box {
   position: relative;
   margin-bottom: 10px;
   .content-mode {
      &:hover {
      .label { opacity: 1; }
      }
   }
   .capture-mode {
      position: relative;
      line-height: 0;
   }
   .companion-name-form {
      margin-top: 10px;
      display: flex;
      .name-input {
         margin-right: 5px;
         border-radius: 5px;
         width: 100%;
         padding: 8px;
      }
   }
   .label {
      color: white;
      background: $primary;
      padding: 2px 10px;
      border-radius: 2px;
      font-size: 0.8rem;
      opacity: 0.8;
   }
   .top-right {
      position: absolute;
      top: 10px;
      right: 10px;
   }
}

.errorInput {
   border: solid $danger 2px;
}

@keyframes shake {
   10%,
   90% {
      transform: translate3d(-1px, 0, 0);
   }

   20%,
   80% {
      transform: translate3d(2px, 0, 0);
   }

   30%,
   50%,
   70% {
      transform: translate3d(-4px, 0, 0);
   }

   40%,
   60% {
      transform: translate3d(4px, 0, 0);
   }
}
.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.camera-switch-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
}
</style>