export default {
  namespaced: true,
  state: {
    pay_at_hotel: false,
    card_number: '',
    expiration_date: ''
  },
  mutations: {
    setPayAtHotel(state, pay_at_hotel) {
      state.pay_at_hotel = pay_at_hotel;
    },
    setCardNumber(state, card_number) {
      state.card_number = card_number;
    },
    setExpirationDate(state, expiration_date) {
      state.expiration_date = expiration_date;
    }
  }
}