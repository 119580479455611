export default {
	namespaced: true,
	state: {
		imageLoaderCounter: 0,
		facility: null
  	},
	getters: {},
	mutations: {
		imageLoaderIncrement(state) {
			state.imageLoaderCounter++
		},
		imageLoaderDecrement(state) {
			state.imageLoaderCounter--
		}
    },
	actions: {
		initialize ({ dispatch }) {
			console.info('Site initializing...')
			console.info('Site initialized.')
		},
		setFacility({commit, state}, payload){
			state.facility = payload
		}
	}
}