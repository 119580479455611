<template>
  <div>
    <div class="card-header py-3">
      {{ $t("quick_checkout_in_setting.header") }}
    </div>
    <div class="card-body mt-2">
      <form @submit-prevent="submit">
        <div v-if="success" class="alert alert-success" role="alert">
          {{ $t("quick_checkout_in_setting.success_msg") }}
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="row">
              <div class="col-6">
                {{ $t("quick_checkout_in_setting.checkin_switch_label") }}
              </div>
              <div class="col-6">
                <toggle-button
                    v-model="quick_checkin_setting.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="quick_checkin_setting"
                    :sync="true"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-6">
                {{ $t("quick_checkout_in_setting.checkout_switch_label") }}
              </div>
              <div class="col-6">
                <toggle-button
                    v-model="quick_checkout_setting.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="quick_checkout_setting"
                    :sync="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="justify-content-left">
          {{ $t("quick_checkout_in_setting.note_1") }}
        </div>
        <br />
        <div
            class="justify-content-left"
            v-html="$t('quick_checkout_in_setting.note_2').join('\n')"
        ></div>

        <div
            class="justify-content-left pl-3"
            v-html="$t('quick_checkout_in_setting.note_3').join('\n')"
        ></div>

        <div class="d-flex mt-5">
          <div class="col">
            <div class="mr-1">
              {{ $t("quick_checkout_in_setting.checkin_link_label") }}
              <a
                  :href="
                  $router.resolve({ name: 'checkin-facility-quick-checkin' })
                    .href
                "
                  target="_blank"
              >{{
                  $router.resolve({ name: "checkin-facility-quick-checkin" })
                      .href
                }}</a
              >
            </div>

            <div class="mr-3 row">
              <div class="col-8">
                {{ $t("quick_checkout_in_setting.qr_label") }}
              </div>
              <div class="col-2">
                <qrcode-vue :value="qrCheckinLink" level="M" />
              </div>
            </div>
          </div>

          <div class="col">
            <div class="mr-1">
              {{ $t("quick_checkout_in_setting.checkout_link_label") }}
              <a
                  :href="
                  $router.resolve({ name: 'checkin-facility-quick-checkout' })
                    .href
                "
                  target="_blank"
              >{{
                  $router.resolve({ name: "checkin-facility-quick-checkout" })
                      .href
                }}</a
              >
            </div>

            <div class="mr-3 row">
              <div class="col-8">
                {{ $t("quick_checkout_in_setting.qr_label") }}
              </div>
              <div class="col-2">
                <qrcode-vue :value="qrCheckoutLink" level="M" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 mt-5" align="center">
            <button
                @click.prevent="
                $router.push({ name: 'checkin-facility-dashboard-home' })
              "
                class="btn btn-danger ml-3"
            >
              {{ $t("quick_checkout_in_setting.back") }}
            </button>
            <button
                class="btn-primary btn ml-3"
                @click="$bvModal.show('modal-confirmation')"
                type="button"
            >
              {{ $t("quick_checkout_in_setting.confirm") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <confirmation-modal-component
        @click-handler="submit"
    ></confirmation-modal-component>
  </div>
</template>

<script>
import _ from "lodash";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    ConfirmationModalComponent,
    QrcodeVue,
  },
  data() {
    return {
      facility: {},
      quick_checkout_setting: {
        facility_id: 0,
        name: "quick_checkout_setting",
        value: {
          visibility: false,
        },
      },
      quick_checkin_setting: {
        facility_id: 0,
        name: "quick_checkin_setting",
        value: {
          visibility: false,
        },
      },
      error: false,
      sendForm: false,
      success: false,
      qrCheckoutLink: "",
      qrCheckinLink: "",
    };
  },

  async mounted() {
    this.qrCheckoutLink = `${window.location.origin}${
        this.$router.resolve({ name: "checkin-facility-quick-checkout" }).href
    }`;

    this.qrCheckinLink = `${window.location.origin}${
        this.$router.resolve({ name: "checkin-facility-quick-checkin" }).href
    }`;

    this.facility = window._app_facility;

    await this.setCheckoutSetting();
    await this.setCheckinSetting();
  },

  methods: {
    async setCheckoutSetting() {
      this.quick_checkout_setting.facility_id = this.facility.id;

      let quickCheckoutSetting = await this.$lionheart
          .get("/v2/facility-settings", {
            params: {
              name: this.quick_checkout_setting.name,
              facility_id: this.quick_checkout_setting.facility_id,
              paginate: 0
            }
          })
          .then((r) => r.data.data);

      if (quickCheckoutSetting.length == 0) return;

      quickCheckoutSetting = quickCheckoutSetting[0];

      this.quick_checkout_setting.value.visibility =
          quickCheckoutSetting.value.visibility;
    },
    async setCheckinSetting() {
      this.quick_checkin_setting.facility_id = this.facility.id;

      let quickCheckinSetting = await this.$lionheart
          .get("/v2/facility-settings", {
            params: {
              name: this.quick_checkin_setting.name,
              facility_id: this.quick_checkin_setting.facility_id,
              paginate: 0,
            }
          })
          .then((r) => r.data.data);

      if (quickCheckinSetting.length == 0) return;

      quickCheckinSetting = quickCheckinSetting[0];

      this.quick_checkin_setting.value.visibility =
          quickCheckinSetting.value.visibility;
    },
    async submit() {
      const checkout = {
        ...this.quick_checkout_setting,
      };

      const checkin = {
        ...this.quick_checkin_setting,
      };

      const resCheckout = await this.$lionheart
          .post("/v2/facility-settings", { ...checkout })
          .then((r) => r.data);

      const resCheckin = await this.$lionheart
          .post("/v2/facility-settings", { ...checkin })
          .then((r) => r.data)


      this.success =
          resCheckout.success == true &&
          resCheckin.success == true
    },
  },
};
</script>