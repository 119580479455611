import moment from 'moment-timezone'

export default {
	data() {
		return {
			vl: {
				visitor: {
					user: {}
				}
			},
			vlo: {
				data: []
			},
			vlc: {
				data: []
			}
		}
	},
	async mounted() {
		let is_v2 = false
		const facility = window._app_facility

		const vl = await this.$lionheart.post('/log/get/facility-visitor', {
			paginate: false,
			id: this.$route.params.id,
			facility_id: facility.id 
		}).then(r => this.catcher(r.data, 'data[0]', r => r.data[0])).catch(e => this.catcher(e))

		if (vl.error.has_error) return

		this.vl = vl
		this.vlo = await this.$lionheart.post('/log/get/facility-visitor-other-form', {
			paginate: false,
			facility_visitor_log_id: this.vl.id,
			facility_id: facility.id
		}).then(r => this.catcher(r.data, 'data', r => r)).catch(e => this.catcher(e))

		let vlc = await this.$lionheart.post('/log/get/facility-visitor-companion', {
			paginate: false,
			facility_visitor_log_id: this.vl.id,
			facility_id: facility.id
		}).then(r => this.catcher(r.data, 'data', r => r)).catch(e => this.catcher(e))

		if (this.vlo.data.length > 0) {
			if (this.vlo.data[0].label == '') is_v2 = true
		}

		if (vlc.data.length > 0) {
			if (vlc.data[0]['facility_companion_other_form_log'].length > 0) is_v2 = true
		}

		if (is_v2) {
			return this.$router.push({
				name : 'checkin-facility-dashboard-visitor-v2-detail',
				params : { id: this.$route.params.id }
			})
		}

		this.vlc = await this.$lionheart.post('/log/get/facility-visitor-companion', {
			paginate: false,
			facility_visitor_log_id: this.vl.id,
			facility_id: facility.id
		}).then(r => this.catcher(r.data, 'data', r => r)).catch(e => this.catcher(e))
	},
	methods: {
		formatDate(date) {
			const timezone = moment.tz.guess()
			return moment.tz(date, timezone).format('MM/DD/YY hh:mm:ss A')
		}
	}
}