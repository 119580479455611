<template>
  <b-modal :id="id">
    <template #modal-header="{ close }">
      {{ $t(`visit-information-form.device_required`) }}
      <button class="close" @click="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="text-center">
      <b-icon-camera class="mb-2" font-scale="6" />
      <h4 class="mb-3">{{ $t("visit-information-form.allow_camera") }}</h4>
    </div>
    <template #modal-footer>
      <div class="w-100 text-center">
        <b-button class="btn-w-lg" variant="primary" @click="handleOK">
          {{ $t("visit-information-form.ok") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BIcon, BIconCamera } from "bootstrap-vue";

export default {
  name: "AllowCameraComponent",

  props: {
    id: {
      default: "allow_camera",
      required: false,
      type: String,
    },
  },

  components: {
    BIcon,
    BIconCamera,
  },

  methods: {
    handleOK() {
      this.$emit("ok");
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style>
</style>