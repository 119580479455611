import axios from "axios";
import Vue from "vue";
import i18n from "@/i18n";
import Store from "@/services/store";
import Router from '@/services/router';

Vue.use(Vue2Storage);
const devHeaders = process.env.VUE_APP_DEV_HEADER;
const lionheart = axios.create({
   //TODO get from env
   baseURL: `${process.env.VUE_APP_LIONHEART_API_URL}/api`,
   withCredentials: true,
});

let auth_key = "auth",
   login_path = "/login";

if (location.href.includes("admin")) {
   auth_key = "auth_admin";
   login_path = "/admin/login";
}
lionheart.interceptors.request.use(
   (config) => {
      Store.commit("checkin-site/loading", true);
      config.headers["Lang"] = `${i18n.locale}`;

      if (devHeaders) {
         config.headers[devHeaders] = true;
      }

      if (Vue.$storage.has(auth_key)) {
         config.headers["Authorization"] = `Bearer ${Vue.$storage.get(auth_key).token}`;
      }

      return config;
   },

   (error) => {
      return Promise.reject(error);
   }
);

// Response
lionheart.interceptors.response.use(
   (response) => {
      try {
         // if (response.data && response.data.data.access_token) {
         //     //auto refresh auth token when change on each request
         //     localStorage.setItem('auth', JSON.stringify(response.data.data));
         // }
      } catch (e) {
         console.info(e);
      }
      Store.commit("checkin-site/loading", false);
      return response;
   },
   (error) => {
      let status;
      try {
         status = error.response.status;
      } catch (e) {
         status = error;
      }

      if (status === 503) {
         return (window.location = "/maintenance");
      }

      if (status === 401) {
         Vue.$storage.remove(auth_key);

         return (window.location = "/login");
      }
      
      const settlementRoutes = [
         "checkin-facility-dashboard-home",
         "checkin-facility-dashboard-reservation-list",
         "checkin-facility-dashboard-visitor-payments-settlement",
         "checkin-facility-dashboard-visitor-payments-payout",
         "checkin-facility-dashboard-downloads",
         "checkin-facility-dashboard-visitor-traction"
      ]
      const currentRouteName = Router.currentRoute.name;
      let errorMessage = i18n.t("error-messages.default-message");
      if ( settlementRoutes.includes(currentRouteName) ){
         errorMessage = i18n.t("error-messages.load-list");
      }
      
      if (Number.isInteger(status) === false || status >= 500) {
         Store.commit("checkin-site/loading", false);
         Vue.$toast.error(`${errorMessage} Status: [${status}]`, {
            position: "top",
            duration: 3000,
         });
      
      }

      return Promise.reject(error);
   }
);

Vue.prototype.$lionheart = lionheart;
export { lionheart };
