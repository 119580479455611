<template>
    <div>
        <!-- Sidebar -->
        <ul style="height: 100%" ref="sideBar" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard/home">
                <div class="sidebar-brand-text mx-3">{{ $t("account-page.self-checkin-system") }}</div>
            </a>

            <!-- Divider -->
            <hr class="sidebar-divider mb-3" />

            <!-- Nav Item - Dashboard -->
            <li :class="{ active: ['checkin-facility-dashboard-home', 'checkin-facility-dashboard-visitor-v2-detail'].includes($route.name) }" class="nav-item">
                <a class="nav-link py-1" href="/dashboard/home">
                    <i class="fas fa-fw fa-chart-bar"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.visitor-list-tip')">
                        {{ $t("account-page.check-in-list-title") }}
                    </span>
                </a>
            </li>
            <!-- RESERVE customer list -->
            <li v-if="facility.has_pms" :class="{ active: $route.path == '/dashboard/reservation-list' }" class="nav-item">
                <router-link class="nav-link py-1" to="/dashboard/reservation-list">
                    <i class="fas fa-fw fa-calendar-check"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.reserve_list_tip')">
                        {{ $t("account-page.reserve_list_title") }}
                    </span>
                </router-link>
            </li>
            <li v-if="facility.has_pms" :class="{ active: $route.path == '/dashboard/message-board' }" class="nav-item">
                <router-link class="nav-link py-1" to="/dashboard/message-board">
                    <i class="fas fa-fw fa-comments"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.message_board_tip')">
                        {{ $t("account-page.message_board_title") }}
                    </span>
                </router-link>
            </li>

            <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/traction' }">
                <router-link class="nav-link py-1" to="/dashboard/traction">
                    <i class="fas fa-fw fa-chart-line"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page-analytics.tooltip')">
                        {{ $t("account-page-analytics.title") }}
                    </span>
                </router-link>
            </li>
            <li v-if="facility.has_pms && !isFincode" class="nav-item" :class="{ active: $route.path == '/dashboard/visitor-payments/settlement' }">
                <router-link class="nav-link py-1" to="/dashboard/visitor-payments/settlement">
                    <i class="fas fa-fw fa-handshake"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page-visitor-payments.tooltip')">
                        {{ $t("account-page.settlement") }}
                     </span>
                </router-link>
            </li>

            <hr class="sidebar-divider mt-3" />

            <div class="sidebar-heading mb-2">General</div>

            <li class="nav-item">
                <a class="nav-link py-1" href="/dashboard/qrcode">
                    <i class="fas fa-fw fa-qrcode"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.qr-code-tip')">
                        {{ $t("account-page.qr-code") }}
                    </span>
                </a>
            </li>

            <li class="nav-item" :class="{ active: $route.path == '/dashboard/questions' }">
                <router-link class="nav-link py-1" to="/dashboard/questions">
                    <i class="fas fa-fw fa-question-circle"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.questions-tip')">
                        {{ $t("account-page.questions-title") }}
                    </span>
                </router-link>
            </li>

            <li class="nav-item" :class="{ active: $route.path == '/dashboard/downloads' }">
                <router-link class="nav-link py-1" to="/dashboard/downloads">
                    <i class="fas fa-fw fa-download"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.downloads-tip')">
                        {{ $t("account-page-downloads.title") }}
                    </span>
                </router-link>
            </li>

            <li class="nav-item" :class="{ active: ['/dashboard/notif-control', '/dashboard/pre-email', '/dashboard/message'].includes($route.path) }">
                <router-link class="nav-link py-1" to="/dashboard/notif-control">
                    <i class="fas fa-fw fa-envelope"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page-notif-control.tip')">
                        {{ $t("account-page-notif-control.title") }}
                    </span>
                </router-link>
            </li>

            <li class="nav-item" :class="{ active: ['/dashboard/form-control', '/dashboard/precheckin-message-form', '/dashboard/message-in-form', '/dashboard/checkout-message-form'].includes($route.path) }">
                <router-link class="nav-link py-1" to="/dashboard/form-control">
                    <i class="fas fa-fw fa-edit"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page-form-control.tip')">
                        {{ $t("account-page-form-control.title") }}
                    </span>
                </router-link>
            </li>

            <li class="nav-item" :class="{ active: $route.path == '/dashboard/filter' }">
                <router-link class="nav-link py-1" to="/dashboard/filter">
                    <i class="fas fa-fw fa-filter"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.filter-tip')">
                        {{ $t("account-page.filter-title") }}
                    </span>
                </router-link>
            </li>

            <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/mr-setting' }">
                <router-link class="nav-link py-1" to="/dashboard/mr-setting">
                    <i class="fas fa-fw fa-door-open"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.mr-tip')">
                        {{ $t("account-page.mr-title") }}
                    </span>
                </router-link>
            </li>

            <li class="nav-item" :class="{ active: $route.path == '/dashboard/tac' }">
                <router-link class="nav-link py-1" to="/dashboard/tac">
                    <i class="fas fa-fw fa-info-circle"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.terms_tip')">
                        {{ $t("account-page.terms") }}
                    </span>
                </router-link>
            </li>

            <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/payment' }">
                <router-link class="nav-link py-1" to="/dashboard/payment">
                    <i class="fas fa-fw fa-cash-register"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page-payment.tip')">
                        {{ $t("account-page-payment.title") }}
                    </span>
                </router-link>
            </li>
          <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/my-page' }">
            <router-link class="nav-link py-1" to="/dashboard/my-page">
              <i class="fas fa-fw fa-globe"></i>
              <span v-b-tooltip.hover.right :title="$t('account-page.my-page-tip')">
                              {{ $t("account-page.my-page") }}
                    </span>
            </router-link>
          </li>

            <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/quick-checkout-in-setting' }">
                <router-link class="nav-link py-1" to="/dashboard/quick-checkout-in-setting">
                    <i class="fas fa-fw fa-sign-out-alt"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.quick-checkout-in-switch-tip')">
                        {{ $t("account-page.quick-checkout-in-switch-title") }}
                    </span>
                </router-link>
            </li>

<!--            <li v-if="facility.has_pms" class="nav-item" :class="{ active: $route.path == '/dashboard/quick-service-setting' }">-->
<!--                <router-link class="nav-link py-1" to="/dashboard/quick-service-setting">-->
<!--                    <i class="fas fa-fw fa-ticket-alt"></i>-->
<!--                    <span v-b-tooltip.hover.right :title="$t('quick_service_setting.tip')">-->
<!--                        {{ $t("quick_service_setting.title") }}-->
<!--                    </span>-->
<!--                </router-link>-->
<!--            </li>-->

            <li class="nav-item" :class="{ active: $route.path == '/dashboard/setting' }">
                <router-link class="nav-link py-1" to="/dashboard/setting">
                    <i class="fas fa-fw fa-users-cog"></i>
                    <span v-b-tooltip.hover.right :title="$t('account-page.setting-tip')">
                        {{ $t("account-page.setting-title") }}
                    </span>
                </router-link>
            </li>

            <hr class="sidebar-divider mt-3" />

            <li class="nav-item">
                <router-link to="/dashboard/start-guide" class="nav-link pt-1 pb-1">
                    <i class=""></i>
                    <span> {{ $t("account-page.start-guide") }}</span></router-link
                >
            </li>

            <li class="nav-item">
                <router-link to="/dashboard/faq" class="nav-link pt-1 pb-1">
                    <i class=""></i>
                    <span> {{ $t("account-page.faq") }}</span></router-link
                >
            </li>

            <li class="nav-item">
                <a target="_blank" class="nav-link pb-1 pt-1" href="https://docs.google.com/forms/d/1BE73quw1CYVcBPe2RtLL-LciKaHypg45V3RE6KnnEKE/viewform?edit_requested=true">
                    <i class=""></i>
                    <span>{{ $t("account-page.feedback-title") }}</span></a
                >
            </li>

            <li class="nav-item">
                <a target="_blank" class="nav-link pt-1 pb-1" :href="`${landingUrl}/${$i18n.locale}/terms-and-conditions`">
                    <i class=""></i>
                    <span>{{ $t("account-page.terms-and-condition") }}</span></a
                >
            </li>

            <li class="nav-item">
                <a target="_blank" class="nav-link pt-1" :href="`${landingUrl}/${$i18n.locale}/privacy-policy`">
                    <i class=""></i>
                    <span>{{ $t("account-page.policy") }}</span></a
                >
            </li>

            <!-- Sidebar Toggler (Sidebar) -->
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div>
        </ul>
        <!-- End of Sidebar -->
    </div>
</template>
<script>
const landingUrl = process.env.VUE_APP_LANDING_PAGE_URL ? process.env.VUE_APP_LANDING_PAGE_URL : ''
export default {
    data() {
        return {
            facility: {},
            landingUrl: '',
            isFincode: null,
        };
    },
    async mounted() {
        this.landingUrl = landingUrl;
        this.facility = window._app_facility;
        await this.checkIfFincodePayment();

        if (this.$route.name == "checkin-facility-dashboard-questions") {
            this.adjustSideNavVh("100vh");
        }
        if (this.$route.name == "checkin-facility-dashboard-message-form") {
            this.adjustSideNavVh("120vh");
        }
    },
    methods: {
        async checkIfFincodePayment() {
            if(this.facility.id) {
                let cardGateway = await this.$lionheart.get("/v2/facility-settings/card-gateway", {
                params: {
                    facility_id: this.facility.id
                    }
                });
                const gateway = cardGateway.data.data.gateway;
                this.isFincode = gateway === 'fincode' ? true : false
            }
        },
        adjustSideNavVh(height) {
            this.$refs.sideBar.style.height = height;
        },
    },
    watch: {
        $route(to, from) {
            if (to.name == "checkin-facility-dashboard-questions") {
                this.adjustSideNavVh("140vh");
            }
            if (to.name == "checkin-facility-dashboard-message-form") {
                this.adjustSideNavVh("120vh");
            }
        },
    },
};
</script>
