var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.multiple)?_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-button',{attrs:{"slot":"upload-label","show":!_vm.confirm,"mini":_vm.typy(_vm.visitor_forms[_vm.c.id], 'dataUrl').isDefined},on:{"click":function($event){return _vm.$bvModal.show('allow_camera_vaccine')}},slot:"upload-label"}),(_vm.typy(_vm.visitor_forms[_vm.c.id], 'dataUrl').isDefined && _vm.confirm)?_c('p',[_c('img',{staticClass:"img-thumbnail",attrs:{"src":_vm.visitor_forms[_vm.c.id].dataUrl}})]):_vm._e()],1),_c('allow-camera-component',{attrs:{"id":"allow_camera_vaccine"},on:{"ok":_vm.showCamera}}),_c('camera-component',{attrs:{"id":"vaccine-card-camera","shots":_vm.guestCount,"question_id":_vm.c.id,"is-for-vaccine":""},on:{"image-captured":function($event){return _vm.setVisitorImage($event)}}}),_c('visitor-image-component',{attrs:{"id":"visitor-vaccine-image","images":_vm.images,"visitor_forms":_vm.visitor_forms,"companions":_vm.companions,"questions":_vm.questions,"allowDynamicAdding":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"save-images":function($event){return _vm.saveImages($event)},"retake-image":function($event){return _vm.retakeImage($event)}}})],1),(
        _vm.saved && !_vm.confirm && _vm.typy(_vm.visitor_forms[_vm.c.id], 'dataUrl').isDefined
      )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',[_vm._v("("+_vm._s(_vm.getName())+")")])]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-box',{attrs:{"capturing":_vm.isCameraSeen[0],"video-id":`main-vaccine`,"canvas-id":`main-vaccine-canvas`,"src":_vm.visitor_forms[_vm.c.id].dataUrl,"disabledCapturing":_vm.disabledCapturing[0],"index":0,"allowDynamicAdding":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"re-take":() => { _vm.showCameraRetake('main-vaccine', 0) },"capture":() => { _vm.captureImageRetake('main-vaccine', 0, _vm.c.id) },"cancel":() => { _vm.hideCameraRetake('main-vaccine', 0) },"toggle-camera":() => { _vm.toggleCamera('main-vaccine', 0, _vm.c.id) },"update:disabledCapturing":function($event){return _vm.$set(_vm.disabledCapturing, 0, $event)},"update:disabled-capturing":function($event){return _vm.$set(_vm.disabledCapturing, 0, $event)}}})],1)]):_vm._e(),_vm._l((_vm.companions),function(companion,index){return [(
          _vm.saved &&
          !_vm.confirm &&
          _vm.typy(companion.other_forms[_vm.c.id], 'dataUrl').isDefined
        )?_c('div',{key:`c_${index}`,staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',[_vm._v(" ("+_vm._s(_vm.showLabelOrPlaceholder( _vm.$t("visit-information-form.companion"), index, "", "dropdown" ))+") ")])]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-box',{attrs:{"capturing":_vm.isCameraSeen[index + 1],"video-id":`companion-vaccine-${index + 1}`,"canvas-id":`companion-vaccine-${index + 1}-canvas`,"src":companion.other_forms[_vm.c.id].dataUrl,"disabledCapturing":_vm.disabledCapturing[index + 1],"index":index + 1,"allowDynamicAdding":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"re-take":() => { _vm.showCameraRetake('companion-vaccine', index + 1) },"capture":() => { _vm.captureImageRetake('companion-vaccine', index + 1, _vm.c.id) },"cancel":() => { _vm.hideCameraRetake('companion-vaccine', index + 1) },"toggle-camera":() => { _vm.toggleCamera('companion-vaccine', index + 1, _vm.c.id) },"update:disabledCapturing":function($event){return _vm.$set(_vm.disabledCapturing, index + 1, $event)},"update:disabled-capturing":function($event){return _vm.$set(_vm.disabledCapturing, index + 1, $event)}}})],1)]):_vm._e()]})],2):_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-button',{attrs:{"slot":"upload-label","show":!_vm.confirm,"mini":_vm.typy(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id], 'dataUrl').isDefined},on:{"click":function($event){return _vm.$bvModal.show('allow_camera_vaccine-' + _vm.bd_index)}},slot:"upload-label"}),(
            _vm.typy(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id], 'dataUrl').isDefined && _vm.confirm
          )?_c('p',[_c('img',{staticClass:"img-thumbnail",attrs:{"src":_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id].dataUrl}})]):_vm._e()],1)]),_c('allow-camera-component',{attrs:{"id":'allow_camera_vaccine-' + _vm.bd_index},on:{"ok":_vm.showCamera}}),_c('camera-component',{attrs:{"id":'vaccine-card-camera-' + _vm.bd_index,"shots":_vm.multiple_data[_vm.bd_index].guestCount,"question_id":_vm.c.id,"is-for-vaccine":""},on:{"image-captured":function($event){return _vm.setVisitorImage($event)}}}),_c('visitor-image-component',{attrs:{"id":'visitor-vaccine-image-' + _vm.bd_index,"images":_vm.mult_images,"visitor_forms":_vm.multiple_data[_vm.bd_index].visitor_forms,"companions":_vm.multiple_data[_vm.bd_index].companions,"questions":_vm.questions,"allowAddCompanion":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"save-images":function($event){return _vm.saveImages($event)},"retake-image":function($event){return _vm.retakeImage($event)}}}),(
        _vm.saved &&
        !_vm.confirm &&
        _vm.typy(_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id], 'dataUrl').isDefined
      )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',[_vm._v("("+_vm._s(_vm.getName())+")")])]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-box',{attrs:{"capturing":_vm.isCameraSeen[0],"video-id":`main-vaccine-${_vm.bd_index}`,"canvas-id":`main-vaccine-canvas-${_vm.bd_index}`,"src":_vm.multiple_data[_vm.bd_index].visitor_forms[_vm.c.id].dataUrl,"disabledCapturing":_vm.disabledCapturing[0],"index":0,"allowDynamicAdding":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"re-take":() => { _vm.showCameraRetake('main-vaccine-' + _vm.bd_index, 0) },"capture":() => { _vm.captureImageRetake('main-vaccine-' + _vm.bd_index, 0, _vm.c.id) },"cancel":() => { _vm.hideCameraRetake('main-vaccine-' + _vm.bd_index, 0) },"toggle-camera":() => { _vm.toggleCamera('main-vaccine-' + _vm.bd_index, 0, _vm.c.id) },"update:disabledCapturing":function($event){return _vm.$set(_vm.disabledCapturing, 0, $event)},"update:disabled-capturing":function($event){return _vm.$set(_vm.disabledCapturing, 0, $event)}}})],1)]):_vm._e(),_vm._l((_vm.multiple_data[_vm.bd_index].companions),function(companion,index){return [(
          _vm.saved &&
          !_vm.confirm &&
          _vm.typy(companion.other_forms[_vm.c.id], 'dataUrl').isDefined
        )?_c('div',{key:`c_${index}`,staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-form-label"},[_vm._v(" "+_vm._s(_vm.c.label)),(_vm.c.required)?_c('small',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',[_vm._v(" ("+_vm._s(_vm.showLabelOrPlaceholder( _vm.$t("visit-information-form.companion"), index, "", "dropdown", _vm.multiple, _vm.bd_index ))+") ")])]),_c('div',{staticClass:"col-md-10"},[_c('camera-capture-box',{attrs:{"capturing":_vm.isCameraSeen[index + 1],"video-id":`companion-vaccine-${_vm.bd_index}-${index + 1}`,"canvas-id":`companion-vaccine-${_vm.bd_index}-${index + 1}-canvas`,"src":companion.other_forms[_vm.c.id].dataUrl,"disabledCapturing":_vm.disabledCapturing[index + 1],"index":index + 1,"allowDynamicAdding":_vm.allowAddCompanion,"allowRemoveCompanion":_vm.allowRemoveCompanion},on:{"re-take":function($event){return _vm.showCameraRetake('companion-vaccine-' + _vm.bd_index, index + 1)},"capture":function($event){return _vm.captureImageRetake('companion-vaccine-' + _vm.bd_index, index + 1, _vm.c.id)},"cancel":function($event){return _vm.hideCameraRetake('companion-vaccine-' + _vm.bd_index, index + 1)},"toggle-camera":() => { _vm.toggleCamera('companion-vaccine-' + _vm.bd_index, index + 1, _vm.c.id) },"update:disabledCapturing":function($event){return _vm.$set(_vm.disabledCapturing, index + 1, $event)},"update:disabled-capturing":function($event){return _vm.$set(_vm.disabledCapturing, index + 1, $event)}}})],1)]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }