import _ from "lodash";
import t from "typy";
import { VueEditor } from "vue2-editor";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import preCheckinPreview from "../components/preCheckinPreview";
import sendTestEmail from "../components/sendTestEmail";

export default {
  components: {
    VueEditor,
    ConfirmationModalComponent,
    LanguageButtonComponent,
    preCheckinPreview,
    sendTestEmail
  },
  data() {
    return {
      data: {
        value: {},
      },
      checkout: {
        value: {
          html: "",
        },
      },
      precomplete: {
        value: "",
      },
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      availableLanguages: [],
      config: {
        saved: false,
      },
      lang: '',
    };
  },
  async mounted() {
    this.data.facility_id = window._app_facility.id;
    this.checkout.facility_id = window._app_facility.id;
    this.precomplete.facility_id = window._app_facility.id;
    this.show(this.$i18n.locale);
  },
  methods: {
    async show(lang) {
      this.lang = lang;

      // Complete pre-checkin
      this.precomplete.name = `pre-complete-email-${lang}`;
      this.precomplete.value = "";

      const precompleteResponse = await this.$lionheart
        .get(`/custom-messages/${this.precomplete.facility_id}`, {
          params: {
            name: this.precomplete.name,
            hide_default: true,
          },
        })
        .then((r) => this.catcher(r.data, "data", (r) => r))
        .catch((e) => this.catcher(e));

      this.precomplete = Object.assign(this.precomplete, precompleteResponse.data);

      // Checkin complete message
      this.data.name = "message-template-1-" + lang;
      const response = await this.$lionheart.get(
        "/custom-messages/" + this.data.facility_id,
        { params: { name: this.data.name } }
      );
      this.data = response.data.data;
      this.data.facility_id = window._app_facility.id;

      // Checkout complete message
      this.checkout.name = "message-checkout-1-" + lang;
      const res2 = await this.$lionheart.get(
        "/custom-messages/" + this.checkout.facility_id,
        {
          params: { name: this.checkout.name, hide_default: true },
        }
      );
      const checkout = res2.data.data;

      Object.assign(checkout, {
        facility_id: window._app_facility.id,
        name: this.checkout.name,
        value: {
          html: t(checkout.value, "html").isDefined ? checkout.value.html : "",
        },
      });
      this.checkout = checkout;

      this.config.saved = false;
    },
    async submit(data) {
      if (data) {
        const response = await this.$lionheart.post("/custom-messages", {
          ...this.data,
        });
        this.data = response.data.data;

        if (this.$parent.facility.has_pms) {
          const res2 = await this.$lionheart.post("/custom-messages", {
            ...this.checkout,
          });
          this.checkout = res2.data.data;

          const res3 = await this.$lionheart
            .post("/custom-messages", { ...this.precomplete })
            .then((r) => this.catcher(r.data, "data", (r) => r))
            .catch((e) => this.catcher(e));

          if (res3.error.has_error) return this.errorDisplay(res3);
          this.precomplete = res3.data;
        }

        this.config.saved = true;
        window.scrollTo(0, 0);
      }
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg;
      setTimeout(
        () => window.scrollTo(0, $("#error_msg").position().top - 10),
        300
      );
      return;
    },
  },
};
