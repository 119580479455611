<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          {{ $t("reservation_details.booking_details_title") }}
        </h6>
      </div>
      <div class="card-body">
        <div v-if="booking_details.total > 0">
          <div v-for="(map, index) in data_map" class="row" :key="`m_${index}`">
            <div class="col-sm-4">
              {{ map.key }}:
            </div>
            <div class="col-sm-8">{{ extractValue(map, booking_details.data) || '--' }}</div>
          </div>
        </div>
        <div v-if="booking_details.total == 0">
          {{ $t("reservation_details.other_reservation_note") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  data() {
    return {
      data_map: [],
      booking_details: {
        total: 0,
      },
      moment,
      currency: "",
    };
  },
  async mounted() {
    const facility = window._app_facility;
    // Fetch data template first.
    const template = await this.fetchDataTemplate();
    // Fetch associated booking details.
    const booking_details = await this.$lionheart
      .post("/facility-booking/f/list", {
        facility_id: facility.id,
        facility_visitor_log_id: this.$route.params.id,
      })
      .then((r) => this.catcher(r.data, "data", (r) => r))
      .catch((e) => this.catcher(e));

    this.booking_details = booking_details;
    this.currency = booking_details.data.payment_metadata.hasOwnProperty('currency')
      ? booking_details.data.payment_metadata.currency 
      : 'JPY';
  },
  methods: {
    async fetchDataTemplate() {
      const facility = window._app_facility;
      const response = await this.$lionheart
        .get(
          `/facility-setting/data-template/${facility.id}/guest_reservation_detail`,
          { params: { parse: true } }
        );
      if(response.data.hasOwnProperty('value')) {
        const value = response.data.value;
        if (value.hasOwnProperty('specs')) {
          this.data_map = value.specs.list;
        }
      }
    },
    extractValue(map, obj) {
      try {
        const obj_value = map.value.split('.').reduce((a, i) => a[i], obj);
        if (!obj_value) return false;
        if (['checkin_date', 'checkout_date'].includes(map.system_name)) {
          if (!this.isValidFormat(obj_value)) {
            return this.formatCSVDate(obj_value) 
          }
          return obj_value
        }
        return obj_value;
      } catch(e) { return false; }
    },
    formatCSVDate(date) {
      return moment.tz(date, "utc").format("YYYY-MM-DD");
    },
  },
  watch: {
    locale (v) {
      this.fetchDataTemplate();
    }
  }
};
</script>