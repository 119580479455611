<template>
   <div>
      <div v-if="images.length > 0">

         <div class="img-holder d-flex flex-wrap justify-content-center" style="max-height: 250px;">
            <div
               v-for="(image, index) in images"
               :key="index"
               @click="() => showImg(index)"
               >
               <div class="px-2 mb-2">
                  <img :src="image.src" class="mr-2 rounded img-thumbnail" style="height: 200px; max-width: 250px; object-fit: contain; cursor: pointer;">
               </div>
            </div>
         </div>
         <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="images"
            :index="index"
            @hide="handleHide"
         ></vue-easy-lightbox>
      </div>
      
      <div v-else>
         <p>{{ $t('gallery.not_available') }}</p>
      </div>
   </div>
</template>

<style>
.img-holder {
   overflow-y :auto;
}
/* for easy lightbox */
.vel-img {
  background-color: white !important;
}
</style>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
   components: {
      VueEasyLightbox
   },
   props: {
      images: {
         type: Array,
         default: () => {
            return []
         }
      }
   },
   data() {
      return {
         visible: false,
         index: 0
      }
   },
   methods: {
      showImg (index) {
         this.index = index
         this.visible = true
      },
      handleHide() {
         this.visible = false;
      }
   }
}
</script>