export default {
  namespaced: true,
  state: {
    memorizedInputs: JSON.parse(localStorage.getItem('memorized-inputs')) || {},
    lastInputTime: null,
    timeoutId: null,
    memorizedWalkInInputs: JSON.parse(localStorage.getItem('memorized-others-form-inputs')) || {},
    lastWalkInInputTime: null,
    timeoutWalkInId: null,
    memorizedEmail: localStorage.getItem('memorized-email') || null,
    rid: localStorage.getItem('rid') || null,
    checkinFormType: localStorage.getItem('checkin-form-type') || ""
  },
  mutations: {

    saveInput(state, payload) {
      state.memorizedInputs = { ...state.memorizedInputs, ...payload };
      state.lastInputTime = new Date().getTime();
      localStorage.setItem('memorized-inputs', JSON.stringify(state.memorizedInputs));
    },

    saveReservationNumber(state, payload) {
      state.rid = payload
      localStorage.setItem('rid', state.rid)
    },

    // memorized the checkin form type

    saveCheckinFormType(state, payload) {
      state.checkinFormType = payload
      localStorage.setItem('checkin-form-type', payload)
    },

    // save walk-in guest inputs

    saveWalkInInputs(state, payload) {
      state.memorizedWalkInInputs = { ...state.memorizedWalkInInputs, ...payload };
      state.lastWalkInInputTime = new Date().getTime();
      localStorage.setItem('memorized-others-form-inputs', JSON.stringify(state.memorizedWalkInInputs))
    },

    saveEmail(state, payload) {
      state.memorizedEmail = payload
      state.lastWalkInInputTime = new Date().getTime();
      localStorage.setItem('memorized-email', state.memorizedEmail)
    },
    
    clearLocalStorage(state) {
      state.memorizedInputs = {};
      state.lastInputTime = null;
      state.memorizedEmail = null;
      localStorage.removeItem('memorized-inputs');
      localStorage.removeItem('memorized-email')
    },

    // clear walk in local Storage

    clearWalkInLocalStorage(state) {
      state.memorizedWalkInInputs = {}
      state.lastWalkInInputTime = null
      state.memorizedEmail = null
      localStorage.removeItem('memorized-others-form-inputs')
      localStorage.removeItem('memorized-email')
    },


    setTimeoutId(state, timeoutId) {
      state.timeoutId = timeoutId;
    },

    setWalkInTimeoutId(state, timeoutWalkInId) {
      state.timeoutWalkInId = timeoutWalkInId
    },

  },
  actions: {

    // if checkin form type change clear local storage
    checkIfCheckinFormType({ commit, state }, payload) {
      if(state.checkinFormType === 'email' && (payload === 'reservation' || payload === 'others')) {
        commit('clearLocalStorage')
        commit('clearWalkInLocalStorage')
      }

      if(state.checkinFormType === 'reservation' && (payload === 'email' || payload === 'email')) {
        commit('clearLocalStorage')
        commit('clearWalkInLocalStorage')
      }

      if(state.checkinFormType === 'others' && (payload === 'email' || payload === 'reservation')) {
        commit('clearLocalStorage')
        commit('clearWalkInLocalStorage')
      }

      commit('saveCheckinFormType', payload)
    },

    saveReservationNumber({ commit, state }, payload) {
      if(state.rid !== payload) {
        commit('clearLocalStorage'); 
      }
      commit('saveReservationNumber', payload)
    },

    saveInput({ commit, dispatch }, payload) {
      commit('saveInput', payload);
      dispatch('startTimeout');
    },

    // save the walk in guest inputs

    saveWalkInInputs({ commit, dispatch }, payload) {
      commit('saveWalkInInputs', payload)
      dispatch('startWalkInTimeout')
    },

    saveEmail({ commit, dispatch }, payload) {
      commit('saveEmail', payload)
      dispatch('startWalkInTimeout')
    },

    startTimeout({ commit, state }) {
      if (state.timeoutId) {
        clearTimeout(state.timeoutId);
      }
      const timeoutId = setTimeout(() => {
        commit('clearLocalStorage');
      }, 15 * 60 * 1000); // 15 minutes timeout for deleting local storage
      commit('setTimeoutId', timeoutId);
    },

    // walk in dispatch time

    startWalkInTimeout({ commit, state }) {
      if(state.timeoutWalkInId) {
        clearTimeout(state.timeoutWalkInId)
      }

      const timeoutId = setTimeout(() => {
        commit('clearWalkInLocalStorage')
      }, 15 * 60 * 1000)
      commit('setWalkInTimeoutId', timeoutId)
    },

    clearLocalStorage({ commit }) {
      console.log('Main guest storage cleared...');
      commit('clearLocalStorage');
      commit('setTimeoutId', null);
    },

    // walk in clear local storage
    clearWalkInLocalStorage({ commit }) {
      console.log('Walk in storage cleared...')
      commit('clearWalkInLocalStorage')
      commit('setWalkInTimeoutId', null)
    }
  },
};
