<template>
   <div>
      <!-- Information of guest -->
      <div class="row w-100 border mx-0 py-3">
         <div class="col-sm-6 col-md-3">
            <span class="font-weight-bold"> {{ $t("account-page-details-my-page-message.name") }}</span
            >:

            {{ name }}
         </div>

         <div class="col-sm-6 col-md-3">
            <span class="font-weight-bold"> {{ $t("account-page-details-my-page-message.reservation_number") }}</span
            >:
            {{ bookingDetails[0].pms_reservation_no ?? "N/A" }}
         </div>

         <div class="col-sm-6 col-md-2">
            <span class="font-weight-bold"> {{ $t("account-page-details-my-page-message.date") }}</span
            >:
            {{ booking_date }}
         </div>

         <div class="col-sm-6 col-md-4">
            <span class="font-weight-bold"> {{ $t("account-page-details-my-page-message.status") }}</span
            >: {{ $t(`booking_statuses.${parameter.bs}`) }} /
            {{ $t(`payment_statuses.${parameter.ps}`) }}
         </div>
      </div>

      <!-- Message list -->
      <div class="row mt-3 mx-0 my-1 mt-3 mb-sm-5 mb-lg-5 my-md-4 msg-container border">
         <div
            v-if="config.show_in_lowest_view_point"
            class="border"
            ref="scrollTo"
            style="overflow-y: auto"
            :style="!config.show_template ? 'height: 18rem' : 'height: 24rem'"
            :class="{ 'col-md-7': config.show_template, 'col-lg-12': !config.show_template }"
         >
            <div class="px-3 py-3 rounded border mt-3" v-for="(value, key) in messageLists" :class="{ 'mb-3': messageLists.length == key + 1 }">
               <div class="chat-message-left">
                  <!-- message icon -->
                  <div>
                     <template v-if="has_icon(value.from.my_page_message_icon_setting)">
                        <img
                           v-if="value.from.my_page_message_icon_setting.value.base64"
                           :src="value.from.my_page_message_icon_setting.value.base64"
                           alt="Sharon Lessman"
                           width="50"
                           height="50"
                           class="rounded-circle mr-1"
                        />
                        <img
                           v-else
                           src="../../../../../assets/img/my-page/hotel_staff_face_icon.png"
                           alt="Sharon Lessman"
                           width="50"
                           height="50"
                           class="rounded-circle mr-1"
                        />
                     </template>
                  </div>

                  <div class="flex-shrink-1 rounded px-3">
                     <div iv class="font-weight-bold">{{ value.from.name }}</div>
                     <div class="small">
                        {{ formatDateHMSA(value.created_at, "HH:mm", timeZone) }}
                        <span class="font-weight-bold" v-if="value.is_seen"> {{ $t("account-page-details-my-page-message.seen") }}</span>
                     </div>

                     <p>{{ value.body }}</p>

                     <!-- attachments -->
                     <template v-if="value.attachments.length >= 1">
                        <div class="small mt-2 font-weight-bold">
                           {{ $t("account-page-details-my-page-message.attachments") }}
                        </div>
                        <ul class="small pl-4">
                           <li class="text-info py-1" style="cursor: pointer" @click="load_file(at)" v-for="(at, k) in value.attachments">{{ at.display_name }}</li>
                        </ul>
                     </template>
                  </div>
               </div>
            </div>
         </div>

         <!-- div for templates beside of the message -->
         <template v-if="config.show_template">
            <div class="col-md-5 border" style="overflow-y: auto" :style="!config.show_template ? 'height: 18rem' : 'height: 24rem'">
               <div class="row my-3">
                  <div class="col-md-10">
                     <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" v-for="(value, key) in languages">
                           <a
                              class="nav-link text-uppercase text-secondary font-weight-bold cursor-pointer"
                              @click="get_template(value.code), (config.current_template = value.code)"
                              :class="{ active: value.code == config.current_template }"
                           >
                              {{ $t(`account-page-facility-language-option.${value.name}`) }}
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-2">
                     <button type="button" class="btn btn-outline-secondary btn-block" @click="view_template(false)">X</button>
                  </div>
               </div>

               <!-- list of templates -->
               <div
                  @click="set_template(value.value)"
                  class="row mb-3 pb-3"
                  :class="{ 'border-bottom': templates.length != key + 1, 'cursor-pointer': value.value }"
                  v-for="(value, key) in templates"
               >
                  <div class="col-lg-5 font-weight-bold" :class="{ 'text-secondary': !value.value }">
                     <i class="fa fa-paperclip" aria-hidden="true"></i> {{ value.title }}
                  </div>
                  <div class="col-lg-7 small">{{ value.value }}</div>
               </div>
            </div>
         </template>
      </div>

      <div class="row mx-0">
         <div class="col-lg-12 px-0 mb-3" v-if="!config.show_template">
            <div class="row mx-0" v-if="config.show_template">
               <div class="col-lg-12 py-3 mt-1"></div>
            </div>

            <div class="row mx-0">
               <!-- dropdown for selecting languages -->
               <div class="col-1 col-lg-1 px-0">
                  <select class="form-control" v-model="config.current_template" @change="get_template(config.current_template)">
                     <option v-for="(value, key) in languages" :value="value.code">
                        {{ $t(`account-page-facility-language-option.${value.name}`) }}
                     </option>
                  </select>
               </div>

               <!-- control arrow for selecting template -->
               <div class="col-7 col-lg-9">
                  <div class="row">
                     <div class="col-1 pr-0">
                        <i @click="move_left" @touchstart="move_left" class="fa fa-angle-left fa-2x cursor-pointer" v-if="config.is_angle_right_click"></i>
                     </div>

                     <div class="col-9 col-sm-10 py-0 px-0">
                        <div class="scrollmenu" v-if="templates.length >= 0">
                           <button class="btn btn-primary mr-2 border" @click="set_template(value.value)" v-for="(value, key) in templates" :disabled="!value.value">
                              {{ value.title }}
                           </button>
                        </div>
                     </div>
                     <div class="col-1 text-right pl-0">
                        <i v-if="is_template_scroll()" class="fa fa-angle-right fa-2x cursor-pointer" @click="move_right" @touchstart="move_right"></i>
                     </div>
                  </div>
               </div>

               <!-- TRIGGER in details of template -->
               <div class="col-4 col-lg-2 px-0">
                  <button type="button" @click="view_template(true)" class="btn btn-block btn-outline-info">
                     {{ $t("account-page-details-my-page-message.more_details") }}
                  </button>
               </div>
            </div>
         </div>

         <div class="col-lg-12 px-0">
            <div class="row px-2">
               <div class="col-10">
                  <!-- form for sending text -->
                  <b-input-group>
                     <b-input-group-prepend>
                        <a href="#" @click="$bvModal.show('file-upload')" class="btn btn-secondary h-100 attachment pt-2 px-3">
                           <i class="fa fa-paperclip fa-2x"></i>
                        </a>
                     </b-input-group-prepend>

                     <b-form-textarea
                        class="form-control shadow-none position-relative"
                        style="min-height: 50px; height: 50px"
                        v-model="data.body"
                        required
                     ></b-form-textarea>
                     <small class="badge badge-danger rounded-0 position-absolute" style="width: 15px; z-index: 3" v-if="data.attachments.length >= 1"
                        >{{ data.attachments.length }}
                     </small>
                  </b-input-group>
               </div>

               <!-- send button -->
               <div class="col-2 px-0">
                  <button
                     type="button"
                     :disabled="!data.body && data.attachments.length === 0"
                     @click="$bvModal.show('message-confirm')"
                     class="btn btn-block btn-outline-primary h-100"
                  >
                     {{ $t("account-page-details-my-page-message.send") }}
                  </button>
               </div>
            </div>
         </div>
      </div>

      <message-file-upload ref="message_file_upload" @event="refresh_attachment" />
      <confirmation-modal-component id="message-confirm" @click-handler="sendMessage" :message="$t('account-page-details-my-page-message.confirmation_message')" />
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import MessageFileUpload from "../MessageFileUpload";
import ConfirmationModalComponent from "@/modules/checkin-facility/pages/dashboard/components/confirmation-modal.component";

export default {
   name: "Message",
   components: {
      MessageFileUpload,
      ConfirmationModalComponent,
   },
   props: {
      parameter: {
         type: Object,
         default: () => {},
      },
      visitor: {
         type: Object,
         default: () => {},
      },
   },
   data() {
      return {
         filter: {},
         config: {
            show_template: false,
            current_template: this.$i18n.locale,
            is_angle_right_click: false,
            is_loaded: false,
            show_in_lowest_view_point: true,
         },
         data: {
            attachments: [],
         },
         error: {},
         languages: [
            { code: "en", name: "en" },
            { code: "ja", name: "ja" },
         ],
         template: {
            name: "my-page-message-templates",
            value: [],
            facility_id: window._app_facility.id,
         },
         templates: [],
         default: {},
         facility: window._app_facility,
         booking: {
            parsed: {},
            booking_status: {},
            payment_status: {},
            visitor: {
               user: {},
            },
         },
      };
   },
   computed: {
      ...mapGetters("checkin-facility", {
         messageLists: "gettersGuestListOfMessages",
         bookingDetails: "gettersBookingDetails",
      }),
      booking_date() {
         let checkinDate = this.bookingDetails[0].parsed.checkin_date,
            checkoutDate = this.bookingDetails[0].parsed.checkout_date;
         if (checkinDate && this.bookingDetails[0].parsed.checkout_date) {
            checkinDate = moment(checkinDate, "YYYY/MM/DD").format("MM/DD");
            checkoutDate = moment(checkoutDate, "YYYY/MM/DD").format("MM/DD");
         } else {
            checkinDate = moment(this.bookingDetails[0].checkin_date, "YYYY-MM-DD").format("MM/DD");
            checkoutDate = this.bookingDetails[0].checkoutDate ? moment(this.bookingDetails[0].checkoutDate, "YYYY-MM-DD").format("MM/DD") : null;
         }
         return `${checkinDate}  ${checkoutDate ? " - " + checkoutDate : ""}`;
      },

      name() {
         let name = null,
            fullName = this.bookingDetails[0].parsed.full_name;
         if (fullName) {
            name = fullName;
         } else {
            name = this.visitor.full_name;
         }
         return name;
      },
      timeZone() {
         return this.facility.timezone?.value ?? "Asia/Tokyo";
      },
   },

   async mounted() {
      await this.init_languages();
      await this.init_templates();
      await this.get_template(this.config.current_template);

      await this.callApi();
   },

   methods: {
      ...mapActions("checkin-facility", {
         getGuestMessages: "actionGetGuestListOfMessages",
         getBookingDetails: "actionGetBookingDetails",
      }),
      resetForm() {
         this.default = {
            pass: this.parameter.p,
            pms_code: this.parameter.pCode,
            pms_reservation_no: this.parameter.rsvNo,
            attachments: [],
            body: "",
            from_id: window._app_facility.id,
         };
         this.init_data(Object.assign({}, this.default));
      },

      async callApi() {
         this.resetForm();
         let params = {
            pass: this.parameter.p,
            pms_reservation_no: this.parameter.rsvNo,
            pms_code: this.parameter.pCode,
            sort: "asc",
         };
         await this.getGuestMessages(params);
         this.scrollToLatestMessage();
         this.config.is_loaded = true;
      },
      async init_languages() {
         const response = await this.$lionheart.get("/v2/facility-settings", {
            params: {
               paginate: 0,
               name: "language",
               facility_id: window._app_facility.id,
            },
         });

         if (response.data.data.length > 0) {
            let languages = response.data.data[0].value;
            this.languages = languages.length > 0 ? languages : this.languages;
         }
      },
      async init_templates() {
         await this.$lionheart
            .get("/v2/facility-settings", {
               params: {
                  facility_id: window._app_facility.id,
                  name: "my-page-message-templates",
                  paginate: 0,
               },
            })
            .then(async (r) => {
               let result = r.data.data;
               if (result.length > 0) {
                  this.template = result[0];
               }
            });
      },

      async get_template(lang) {
         this.templates = [];

         let result = this.template.value.find((el) => el.code == lang);
         if (result) {
            this.templates = result.data;
         }
         this.resetForm();
      },

      // async listAllMessages(params) {
      //    const response = await this.$lionheart.get('/v2/my-page/messages', {
      //       params: params
      //    });

      //    this.messagesList = response.data.data;
      // },

      async sendMessage() {
         let facilityName = window._app_facility.name;
         let nameByLang = window._app_facility.facility_name.find(el => el.language == this.$i18n.locale);
         if(nameByLang){
            facilityName = nameByLang.name;
         }
         let email_notice = {
            pass: this.bookingDetails[0].pass,
            email: this.parameter.e || this.bookingDetails[0].parsed.email_address,
            slug: window._app_facility.slug_name,
            guest_name: this.bookingDetails[0].parsed.full_name,
            facility_name:facilityName,
         };
         await this.$lionheart.post("/v2/my-page/messages", { ...this.data, email_notice: email_notice });
         this.callApi();
         this.resetForm();
         this.scrollToLatestMessage();
         await this.$refs.message_file_upload.clear();
      },

      // METHOD FOR REFRESH ATTACHMENT
      async refresh_attachment($event) {
         this.data.attachments = $event;
      },

      async load_file(data) {
         let result = await this.$lionheart.get("/v2/file/get-temporary-url", { params: data });

         const link = document.createElement("a");
         link.target = "_blank";
         link.href = result.data.data;
         link.download = data.display_name;
         link.click();
      },
      async set_template(text) {
         if (text) {
            let template = text;

            for (var key in this.booking.parsed) {
               if (this.booking.parsed.hasOwnProperty(key)) {
                  let tag = `{[${key}]}`;
                  template = template.replaceAll(tag, this.booking.parsed[key]);
               }
            }
            this.data.body = template;
         }
      },
      async move_left() {
         this.config.is_angle_right_click = true;
         let content = document.querySelector(".scrollmenu");
         content.scrollLeft -= 150;
      },
      async move_right() {
         this.config.is_angle_right_click = true;
         let content = document.querySelector(".scrollmenu");
         content.scrollLeft += 150;
      },
      async view_template(status) {
         this.config.show_in_lowest_view_point = true;
         this.config.show_template = status;
         this.scrollToLatestMessage();
         // if(this.config.show_template){
         //       if (window.innerWidth < 767){
         //          this.config.show_in_lowest_view_point  = false;
         //       }
         // }
      },

      async init_data(data) {
         this.data = data;
      },

      scrollToLatestMessage() {
         this.$nextTick(function () {
            var container = this.$refs.scrollTo;
            container.scrollTop = container.scrollHeight + 120;
         });
      },

      async set_modal_size() {
         let mc_p = this.get_percentage_by_scale();
         let body_height = window.innerHeight - (20 / 100) * window.innerHeight;
         let mc_height = body_height - (mc_p / 100) * body_height;

         let modal_content = document.getElementsByClassName("message")[0].firstChild.querySelector(".modal-content");

         let modal_body = modal_content.querySelector(".modal-body");
         let message_container = modal_body.querySelector(".msg-container");
         message_container.style.height = `${mc_height}px`;
      },
      is_template_scroll() {
         let status = true;
         let result = document.getElementsByClassName("scrollmenu");
         if (this.config.is_loaded && result[0]) {
            if (result[0].scrollWidth <= result[0].clientWidth) {
               status = false;
            }
         }
         return status;
      },

      has_icon(data) {
         let result = false;

         if (data) {
            if (data.value.status) {
               result = true;
            }
         }

         return result;
      },

      get_percentage_by_scale() {
         let scale = window.innerHeight;
         let mc_p = 0;
         if (scale > 600) {
            mc_p = 65;
         }
         if (scale > 640) {
            mc_p = 60;
         }
         if (scale > 780) {
            mc_p = 45;
         }
         if (scale > 960) {
            mc_p = 33;
         }
         if (scale > 1300) {
            mc_p = 20;
         }
         return mc_p;
      },
   },
};
</script>

<style lang="scss">
@media (min-width: 1200px) {
   .message {
      .modal-xl {
         max-width: 1450px !important;
      }
   }
}

.scrollmenu {
   overflow: hidden;
   white-space: nowrap;
}

.scrollmenu button {
   display: inline-block;
   text-align: center;
   padding: 0.3rem 1rem;
   text-decoration: none;
   color: white;
   border-radius: 50px;
}

.cursor-pointer {
   cursor: pointer;
}

.of-y-scroll {
   overflow-y: scroll;
   overflow-x: hidden;
}

.message {
   //  div.scrollmenu {
   //      overflow: hidden;
   //      white-space: nowrap;
   //  }

   //  div.scrollmenu button {
   //      display: inline-block;
   //      text-align: center;
   //      padding: 0.3rem 1rem;
   //      text-decoration: none;
   //      color: white;
   //      border-radius: 50px;
   //  }

   .modal-footer {
      padding-top: 1rem;
      padding-bottom: 1rem;
   }

   .overflow-x {
      overflow-x: hidden;
   }

   .chat-message-left {
      display: flex;
      flex-shrink: 0;
      margin-right: auto;
   }

   //  .of-y-scroll {
   //      overflow-y: scroll;
   //      overflow-x: hidden;
   //  }

   .attachment {
      width: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
   }

   .text-message {
      padding-left: 5rem;
   }
}
</style>
