<template>
  <div>
    <!-- Reservation -->
    <pre-checkin-booking-details
      :booking_details="booking_details"
      :facility="facility"
      :currency="currency"
      no-questions
      td
      @template-loaded="templateLoading($event)"
    />

    <div class="mt-3">
      <!-- Checkin -->
      <checkin-information
        :facility_visitor_log_id="
          booking_details.data[0].facility_visitor_log_id
        "
        :facility_id="facility.id"
      />

      <!-- Payment -->
      <GatewayPayment
        ref="gatewayPayment"
        :facility="facility"
        :booking_details="booking_details"
        :confirm="confirm"
        :currency="currency"
        page="checkin"
        touchdown=true
        @updateShowPayment="isPaid"
      />

      <div class="row mt-4">
        <div class="col-2"></div>
        <!-- Confirm Button -->
        <div class="col-8" v-if="!confirm">
          <div class="d-flex justify-content-center">
            <!-- FinishPreCheckin if payment status is paid -->
            <button
              v-if="paid"
              class="btn btn-primary"
              @click="finishPreCheckin()"
            >
              {{ $t("touch-down.confirm") }}
            </button>

            <!-- confirmPreCheckin if payment status is not paid -->
            <button v-else class="btn btn-primary" @click="confirmPreCheckin">
              {{ $t("touch-down.confirm") }}
            </button>
          </div>
        </div>
        <template v-if="confirm">
          <div class="col-8">
            <div class="d-flex justify-content-center">
              <button @click="unConfirmPreCheckin()" class="btn btn-danger mr-3">
                {{ $t("touch-down.back") }}
              </button>
              <button @click="finishPreCheckin()" class="btn btn-primary">
                {{ $t("touch-down.submit") }}
              </button>
            </div>
          </div>
        </template>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { payment_status } from "@/modules/checkin-facility/utils/mockup";
import PreCheckinBookingDetails from "./../preCheckinBookingDetails";
import CheckinInformation from "./../CheckinInformation";
import GatewayPayment from "./../gatewayPayment.vue";

export default {
  props: {
    booking_details: Object,
    facility: Object,
    currency: String,
  },
  components: {
    PreCheckinBookingDetails,
    CheckinInformation,
    GatewayPayment,
  },
  data() {
    return {
      paid: true,
      confirm: false,
      checkin_information: {},
      paid_statuses: [
        payment_status.pay_at_reception,
        payment_status.paid_authorized,
        payment_status.paid_captured,
        payment_status.paid,
      ],
      is_loading: false,
    };
  },
  mounted() {
    this.isPaid();
  },
  methods: {
    async confirmPreCheckin() {
      const status = await this.$refs.gatewayPayment.doCall().createPaymentMethod();
      if (status) {
        this.confirm = true;
        window.scrollTo(0, 10);
      }
    },

    unConfirmPreCheckin() {
      this.confirm = false;
      setTimeout(() => this.$refs.gatewayPayment.doCall.reInit(), 300);
      window.scrollTo(0, 10);
    },

    isPaid(showPayment) {
      if (showPayment == 0) return (this.paid = true);

      this.paid = this.paid_statuses.includes(
        this.booking_details.data[0].payment_status_id
      );
    },

    async finishPreCheckin() {
      const payment = await this.$refs.gatewayPayment.doCall().makePayment(true);

      if (!payment) return;

      let data = {
        pass: this.booking_details.data[0].pass,
        pms_code: this.booking_details.data[0].pms_code,
      };

      let res = await this.$lionheart.post(
        "/facility-booking/finish-precheckin",
        data
      );

      const booking = this.booking_details.data[0];
      const params = {
         facilityId: booking.facility_setting_pms.facility_id,
         reservationNo: booking.pms_reservation_no
      }

      this.$emit("checkin-completion", params);
    },

    templateLoading(e) {
      this.$emit("template-loaded", e);
    },
  },
};
</script>
