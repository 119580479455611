export default {
    watch: {
        fixGuestCount(newVal) {
          if (newVal !== null && this.fixGuestSetting?.fix_guest_count?.status) {
            this.populateCompanionsFields();
          }
        },
        fixGuestSetting(newVal) {
          if (newVal && newVal.fix_guest_count?.status) {
            this.populateCompanionsFields();
          }
        }, 
        visitor_forms() {},
    },
  };
  