import moment from "moment-timezone";
import formatters from '@/mixins/formatters.js';

export default {
    name: "show",
    data() {
        return {
            facility_booking: {

                charge: {
                    payment_method_details: {
                        card:{}
                    }
                }
            }
        }
    },
    methods: {

        async view(payment_id,facility_id) {
            let data = await this.$lionheart.get('facility-booking/list/payments/'+payment_id,{params: {facility_id: facility_id,   timezone: moment.tz.guess()}}).then((success) => success.data.data);
         this.$data.facility_booking = data

            this.$bvModal.show('show-modal')
        }
    },
    mixins: [formatters],
}