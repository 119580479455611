<template>
  <b-modal ref="detailModal" dialog-class="modal-mod modal-fluid-lg" @hide="$emit('update:show', false)" :no-enforce-focus="true">
    <template #modal-header>
      <div class="d-flex justify-content-between w-100">
        <b>{{ $t("account_page_details.title_details") }} [{{ itemIndex + 1 }}/{{ itemTotal }}]</b>
        <div class="controls">

          <button @click="$emit('prev')" :disabled="!hasPrev">
            <i class="fa fa-arrow-up" />
          </button>
          <button class="ml-1" @click="$emit('next')" :disabled="!hasNext">
            <i class="fa fa-arrow-down" />
          </button>
          <button class="ml-1" @click="print"><i class="fa fa-print" /></button>
          <button class="ml-1" @click="close"><i class="fas fa-times" /></button>
        </div>
      </div>
    </template>
    <div class="px-2 pt-2" id="basic-details-body">
      <main-details-widget :visitor-log="vl" :visitor-form-log="vfl" @updated="refresh" />

    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <div />
        <div>
          <b-button variant="primary" class="btn-w-md" @click="close">
            Ok
          </b-button>
        </div>
      </div>
    </template>
      <message ref="message"/>
  </b-modal>
</template>

<script>
import t from "typy";
import _ from "lodash";
import moment from "moment-timezone";
import {
  core_questions,
  nationalities,
  main_form_methods,
} from "@/modules/checkin-facility/utils/mockup";
const { changeChoices, getChoice, formatDate } = main_form_methods;
import MainDetailsWidget from "./MainDetailsWidget.vue";
import Message from "./Message";

export default {
  components: { MainDetailsWidget, Message},
  props: {
    show: { type: Boolean, default: false },
    logId: { type: Number, default: 0 },
    itemIndex: { type: Number | String, default: 0 },
    itemTotal: { type: Number | String, default: 0 },
  },
  data() { return {
    currentTab: "main",
    facility: {},
    vl: {
      visitor: { user: {}, },
    },
    vfl: {
      data: [],
    },
    vlc: {
      data: [],
    },
  }},
  async mounted() {
      this.facility = window._app_facility;
  },
  methods: {
    changeChoices,
    getChoice,
    formatDate,
    async getVisitorLog() {
      const visitorLog = await this.$lionheart
        .post("/log/get/facility-visitor", {
          paginate: false,
          id: this.logId,
          facility_id: this.facility.id,
        })
        .then((r) => this.catcher(r.data, "data[0]", (r) => r.data[0]))
        .catch((e) => this.catcher(e));

      if (visitorLog.error.has_error) return;

      this.vl = visitorLog;
    },
    async getCompanionLog() {
      let vlc = await this.$lionheart
        .post("/log/get/facility-visitor-companion", {
          paginate: false,
          facility_visitor_log_id: this.vl.id,
          facility_id: this.facility.id,
        })
        .then((r) => this.catcher(r.data, "data", (r) => r))
        .catch((e) => this.catcher(e));
      vlc = _.cloneDeep(vlc.data);

      vlc.map((obj) => {
        obj.fco = _.groupBy(
          obj.facility_companion_other_form_log,
          (obj) => obj.facility_questionnaire.section
        );
      });

      this.vlc = vlc;
    },
    async getVisitorFormLog() {
      this.vfl = await this.$lionheart
        .post("/log/get/facility-visitor-other-form", {
          paginate: false,
          facility_visitor_log_id: this.vl.id,
          facility_id: this.facility.id,
          version: 2,
        })
        .then((r) => this.catcher(r.data, "data[0].id", (r) => r))
        .catch((e) => this.catcher(e));

      let vfl = _.cloneDeep(this.vfl.data);
      this.vfl = _.groupBy(vfl, (obj) => obj.facility_questionnaire[0].section);

      this.populateEditData()
    },
    getEditLabel(system_name) {
      if (t(core_questions, this.$i18n.locale).isDefined == false)
        return system_name;

      return core_questions[this.$i18n.locale].labels[system_name];
    },
    async checkEmail(e) {
      if (this.edit_form.email == "") return;

      await this.validateEmail();
    },
    async validateEmail() {
      const data = {
        email: this.email,
        id: this.vl.visitor_id,
        type: "visitor",
        module: "checkin",
      };
      const email = await this.$lionheart
        .post("/user/exists", data)
        .then((r) => this.catcher(r.data, "data.exist", (r) => r.data))
        .catch((e) => this.catcher(e));

      this.email_exists = email.exist;
    },
    async submitForm(e) {
      e.preventDefault()
      if(!this.email_exists) {
        this.submitted = true
        this.$bvModal.hide('edit-contact-details-modal')

        const formData = _.cloneDeep(this.edit_form)
        let govIdChanged = false

        const gov_id_index = formData.findIndex(x => x.system_name === 'gov_id');
        if(gov_id_index != -1) {
          if (this.tp(formData[gov_id_index].value, 'dataUrl').isDefined) {
            govIdChanged = true
            formData[gov_id_index].value = formData[gov_id_index].value.dataUrl
          }
        }

        const dob_index = formData.findIndex(x => x.system_name === 'dob');
        if(dob_index != -1) {
          formData[dob_index].value = moment(this.formatDate(formData[dob_index].value)).format("YYYY-MM-DD")
        }
        const response = await this.$lionheart.put(`/log/visitor/${this.vl.id}`, {
          other_forms: formData,
          email: this.email,
          facility_id: this.facility.id
        });

        if(response.data.success) {
          this.submitted = false
          await this.getVl()
          await this.getvfl()
          if(!govIdChanged) this.showSuccess()
        }
      }
    },
    async populateEditData() {
      this.edit_form = []
      this.email = this.vl.visitor.user.email
      const x = ['reservation_no', 'occupancy']
      this.vfl.contact.forEach((data, key) => {
        if (!x.includes(data.facility_questionnaire[0].system_name)) {
          this.edit_form.push({
            system_name: data.facility_questionnaire[0].system_name,
            rank: data.facility_questionnaire[0].rank,
            required: data.facility_questionnaire[0].required,
            id: data.id,
            value: data.value,
          })
          if (data.facility_questionnaire[0].system_name == 'dob') this.temp_date = data.value
        }
      });
    },
    changeNationality() {
      if (t(nationalities, this.$i18n.locale).isDefined) return nationalities[this.$i18n.locale];
      this.$i18n.locale = "en";
      return nationalities[this.$i18n.locale];
    },
    showSuccess() {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000);
    },
    formatFormDate(date) {
        if (date == null) return "";
        const [y, m, d] = date.split(".");
        date = `${d}.${m}.${y}`;
        const timezone = moment.tz.guess();
        return moment.tz(date, timezone).format("MM/DD/YYYY");
    },
    close() {
      this.$refs['detailModal'].hide();
    },
    async refresh() {
      await this.getVisitorFormLog();
    },
    print() {
      const page = window.open('#', '_blank');
      const pageTitle = this.$t("account_page_details.title_details");
      let tabContent = document.querySelector("#basic-details-body").innerHTML;
      page.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>${pageTitle}</title>
        <style>
        .tab-pane {padding: 15px;}
        .tab-content {max-width: 800px;}
        .link-btn, .buttons-row, button:not(.panel-toggle-btn) {display: none;}
        .header-on-print {display: block; width: 100%; margin: 5px auto; font-size: 1.1rem;}
        .panel-toggle-body {display: block !important;}
        .panel-header {margin-bottom: 5px;}
        table {border-collapse: collapse; margin-bottom: 15px;}
        table, img {width: 100%;}
        td, .header-on-print {border: 1px solid #dee2e6; padding: 0.3rem;}
        td:first-child {min-width: 100px;}
        </style>
      </head>
      <body>
        <div class="tab-content">${tabContent}</div>
        <script>window.print()<\/script>
      </body>
      </html>
      `);
      page.document.close();
    },
  },
  computed: {
    editFormByRank () {
      return _.orderBy(this.edit_form, 'rank')
    },
    isPmsCheckin () {
      return this.vl.booking_details !== null &&
        this.vl.booking_details.hasOwnProperty('condition_metadata') &&
        this.vl.booking_details.condition_metadata !== null &&
        this.vl.booking_details.condition_metadata.is_pms_checkin === 1
    },
    hasPrev() {
      return Number(this.itemIndex) > 0;
    },
    hasNext() {
      return (Number(this.itemIndex) + 1) < Number(this.itemTotal);
    },
  },
  watch: {
    async show(v) {
      if (v) {
        await this.getVisitorLog();
        await this.getVisitorFormLog();
        this.$refs['detailModal'].show();
      }
    },
    async itemIndex() {
      await this.getVisitorLog();
      await this.getVisitorFormLog();
    }
  }
}
</script>