<template>
  <b-modal :id="id" @show="init()">
    <div v-for="(img, imageIndex) in imageFiles" :key="imageIndex">
      <ImagePreviewBoxComponent
        :uploading.sync="displayUploadFileBox[imageIndex]"
        :src.sync="img.dataUrl"
        @reUploadImage="() => { showUploadFileBox(imageIndex, true) }"
        @removeCompanion="handleRemoveCompanion(imageIndex)"
        @uploadImage="uploadSingleImage(imageIndex, $event)"
        @saveName="saveName(imageIndex, $event)"
        :showInput.sync="showInput[imageIndex]"
        :disabledUploadBox.sync="disabledUploadBox[imageIndex]"
        :index="imageIndex"
      />

      <!-- dropdown for tagging the owner of the pic -->
      <b-dropdown
         id="dropdown-1"
         :text="truncate(dropDownTexts[imageIndex].value, 15)"
         block
         variant="primary"
         class="mb-3 mt-1"
         v-if="showDropdown[imageIndex]"
      >
         <template v-for="q in questions">

            <template v-if="isFirstName(q.system_name)">
               <template v-for="r in questions">

                  <template v-if="isLastName(r.system_name)">
                     <b-dropdown-item :key="r.id"
                     @click="
                        setDDText(`${mainGuest[q.id]} ${mainGuest[r.id]} `, imageIndex)
                        setMainImage(img)
                     "
                     >
                     {{ truncate(`${mainGuest[q.id]} ${mainGuest[r.id]} `) }}
                     </b-dropdown-item>

                     <b-dropdown-item
                     v-for="(escort, i) in escorts"
                     :key="i"
                     @click="
                        setDDText(
                           `${escort.other_forms[q.id]} ${escort.other_forms[r.id]}`,
                           imageIndex
                        )
                        setCompanionImage(img, i)
                        "
                     >
                     {{
                        truncate(
                           `${escort.other_forms[q.id]} ${escort.other_forms[r.id]}`
                        )
                     }}
                     </b-dropdown-item>
                  </template>
               </template>
            </template>

            <template v-else-if="isFullName(q.system_name)">
               <!-- main guest -->
               <b-dropdown-item :key="q.id"
                  @click="
                     setDDText(`${mainGuest[q.id]}`,0, imageIndex)
                     setMainImage(img)
                  "
               >
                  {{ truncate(`${mainGuest[q.id]}`, 15,0) }}
               </b-dropdown-item>

               <!-- companion -->
               <b-dropdown-item
                  v-for="(escort, escortIndex) in escorts"
                  :key="escortIndex"
                  @click="
                     setDDText(`${escort.other_forms[q.id]}`,escortIndex + 1, imageIndex)
                     setCompanionImage(img, escortIndex)
                  "
               >
                  {{ truncate(`${escort.other_forms[q.id]}`, 15,escortIndex + 1) }}
               </b-dropdown-item>
            </template>
            
         </template>
      </b-dropdown>

      <hr v-if="!checkifsLastItem(imageIndex, imageFiles)" />
    </div>
   <div class="d-flex justify-content-end mt-4" v-if="allowDynamicAdding">
      <b-button variant="primary" class="btn-w-lg" @click="addNewPicture">
         {{ $t("visit-information-form.browse_file_add_photo_text") }}
      </b-button>
   </div>
   <!-- Save function -->
   <template #modal-footer>
      <div class="d-block w-100 text-center">
         <b-button
            variant="primary"
            class="btn-w-lg mr-4"
            :disabled="!hasImagesSet()"
            @click="saveUploadImages"
         >
            {{ $t("visit-information-form.browse_file_save_photo_text") }}
         </b-button>
         <b-button
            v-if="escorts.length > 0"
            variant="danger"
            class="btn-w-lg"
            @click="cancelUploadImages"
         >
            {{ $t("visit-information-form.browse_file_cancel_photo_text") }}
         </b-button>
      </div>
   </template>
  </b-modal>
</template>

<script>
import _ from "lodash"
import typy from "typy"
import { eventBus } from "@/main";
import { convertImageToBase64 } from "@/modules/checkin-facility/utils/mockup"
import ImagePreviewBoxComponent from "@/modules/checkin-facility/pages/checkin/components/ImagePreviewBoxComponent"

export default {
   name: "PreviewImageFileModalComponent",
   components: { ImagePreviewBoxComponent },
   props: {
      id: {
         required: false,
         type: String,
         default: "preview-image-files"
      },
      imageFiles: {
         type: Array,
         default: []
      },
      visitor_forms: {
         type: Object,
         default: {}
      },
      companions: {
         type: Array,
         default: []
      },
      questions: {
         type: Array,
         default: []
      },
      allowDynamicAdding: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         mainGuest: {},
         escorts: [],
         dropDownTexts: [],
         displayUploadFileBox: [],
         imgs: [],
         showInput: [],
         showDropdown: [],
         disabledUploadBox: [],
         isNameSet: []
      }
   },
   computed: {
      imagesLenMoreOne() {
         return this.imageFiles.length > 1
      }
   },
   methods: {
      init() {
         // reset the values when on load
         this.mainGuest = this.visitor_forms
         this.escorts = this.companions
         this.imgs = []

         this.showInput = []
         this.showDropdown = []
         this.disabledUploadBox = []

         this.isNameSet = []

         const dropDownTexts = []
         const displayUploadFileBox = []
         const imgs = []
         const showInput = []
         const showDropdown = []
         const disabledUploadBox = []
         const defaultNameObject = {nameIndex:  null, value: this.$t("visit-information-form.names") }

         dropDownTexts[0] = defaultNameObject
         displayUploadFileBox[0] = false
         imgs[0] = false
         showInput[0] = false
         showDropdown[0] = true
         disabledUploadBox[0] = true

         for (let i = 0; i < this.escorts.length; i++) {
            dropDownTexts[i + 1] = defaultNameObject
            displayUploadFileBox[i + 1] = false
            imgs[i + 1] = false
            showInput[i + 1] = false
            showDropdown[i + 1] = true
            disabledUploadBox[i + 1] = true // on add disabled the upload
         }

         this.dropDownTexts = dropDownTexts
         this.displayUploadFileBox = displayUploadFileBox
         this.imgs = imgs
         this.showInput = showInput
         this.showDropdown = showDropdown
         this.disabledUploadBox = disabledUploadBox
      },
      // will get the id of gov id for checking if the object is exist in companion array
      extractFullNameIdAndGovid() {
         let fullNameId = null;
         let govId = null;

         this.questions.filter((item) => {
            if(item.system_name === "full_name") { // full name
               fullNameId = item.id;
            }

            if(item.system_name === "gov_id") { // dataUrl
               govId = item.id;
            }
         });

         return {
            fullNameId, govId
         }
      },
      setMainImage(img) {
         this.mainGuest[img.question_id] = {
            dataUrl: img.dataUrl
         }
      },
      setCompanionImage(imgObject, companion_index) {
         this.escorts[companion_index].other_forms[imgObject.question_id] = {
            dataUrl: imgObject.dataUrl
         }
      },
      setDDText(text, nameIndex, toIndex) {
         let objectToSet = {nameIndex: nameIndex, value: text};
         const existing = this.dropDownTexts.filter((item, key) => item.nameIndex === nameIndex)
         
         if(existing.length > 0) {
            let fromDdTextIndex = null;
            fromDdTextIndex = this.dropDownTexts.findIndex((item) => item.nameIndex === nameIndex);

            this.$set(this.dropDownTexts, fromDdTextIndex, {nameIndex: null, value: null});
            this.$set(this.dropDownTexts,toIndex, objectToSet)

            this.imgs[toIndex] = true
            this.imgs[fromDdTextIndex] = false
         } else {
            if(typeof(undefined) === text) {
               objectToSet = {
                  nameIndex: nameIndex,
                  value: `${this.$t('visit-information-form.default_name_text')} ${nameIndex}`
               }
            }
            this.$set(this.dropDownTexts,toIndex, objectToSet);
            this.imgs[toIndex] = true
         }
      },

      // save to main component
      saveUploadImages() {
         let guests = {
            visitor_forms: this.mainGuest,
            companions: this.escorts,
         }

         this.$emit("saveImagesFiles", guests)
         this.$bvModal.hide(this.id)
      },
      cancelUploadImages() {
         this.$bvModal.hide(this.id)
      },
      truncate(text, length = 10, index) {
         const defaultName = `${this.$t('visit-information-form.default_name_text')} ${index}`
         if(text === undefined) return  _.truncate((defaultName), { length: length })
         return _.truncate(text, { length: length })
      },
      isFirstName(system_name) {
         return system_name == "first_name"
      },
      isLastName(system_name) {
         return system_name == "last_name"
      },
      isFullName(system_name) {
         return system_name == "full_name"
      },
      checkifsLastItem(index, array) {
         return index === array.length - 1
      },
      hasImagesSet() {
         for (let i = 0; i < this.imgs.length; i++) {
            if (!this.imgs[i]) {
               return false
            }
         }
         return true
      },

      // START HERE of modification
      async uploadSingleImage(index, event) {
         let imageProcessed = await convertImageToBase64(event[0]);
         const question_id = this.imageFiles[0].question_id;

         let imageObject = {
            dataUrl: imageProcessed,
            index,
            question_id
         }
         
         if (index === 0) {
            this.setMainImage(imageObject);
         } else {
            let companionIndex = index - 1;
            this.setCompanionImage(imageObject,companionIndex);
         }

         this.imgs[index] = true;
         this.$emit("reUploadImageFiles", imageObject);
         this.$set(this.displayUploadFileBox, index, false); //hide uploadFileBox after uploading images
         this.$set(this.disabledUploadBox, index, true); //hide uploadFileBox after uploading images
      },

      addNewPicture(eventToFireInAddCompanion) {
         let index = this.imageFiles.length;
         const question_id = this.imageFiles[0].question_id;

         let newPicture = {
            dataUrl: '',
            question_id: question_id
         }

         this.imageFiles.push(newPicture);
         this.showUploadFileBox(index);
         eventBus.$emit('eventBusAddCompanion',eventToFireInAddCompanion);
      },

      showUploadFileBox(index, retake = false) {
         this.$set(this.imgs, index, false) //set imgs to false to disabled the button of save
         this.$set(this.displayUploadFileBox, index, true) //show the upload file box
         if(!retake) {
            this.$set(this.showInput, index, true) //show the upload file box
            this.$set(this.showDropdown, index, false) //set to false to hide dropdown when adding new companion
            this.$set(this.disabledUploadBox, index, true); //set the upload box disabled to require enter name of guest
            return;
         }
         this.$set(this.disabledUploadBox, index, false); //set the upload box disabled
      },

      saveName(index,names) {
         let fullName = names[index];
         
         // prepare the object of companion - supply the gov id
         let emptyObject = {};

         const { govId, fullNameId } = this.extractFullNameIdAndGovid()
         emptyObject[fullNameId] = fullName; // sample { 8889: test }
         emptyObject[govId] = { dataUrl: '' }; // sample { 61: imageUrl }

         // determine if the main guest
         if(index === 0) {
            this.mainGuest[fullNameId] = fullName
         } else {
            this.escorts[index - 1].other_forms = emptyObject;
         }

         this.setDDText(fullName, index, index) //set the name in dropdown text after typing name
         this.$set(this.showInput, index, false); //hide input after typing name
         this.$set(this.showDropdown, index, true) //show dropdown with name assigned
         setTimeout(() => {
            this.$set(this.disabledUploadBox, index, false); //enable upload input
         },300);
      },

      handleRemoveCompanion(companionIndex) {
         let newCompanionIndex = companionIndex - 1;

         this.isNameSet.splice(companionIndex,1);
         this.dropDownTexts.splice(companionIndex,1);
         this.displayUploadFileBox.splice(companionIndex,1);
         this.imgs.splice(companionIndex,1);
         this.showInput.splice(companionIndex,1);
         this.showDropdown.splice(companionIndex,1);
         this.disabledUploadBox.splice(companionIndex,1);
         this.imageFiles.splice(companionIndex,1);
         eventBus.$emit('eventBusRemoveCompanion',newCompanionIndex);
      }
      
   }
}
</script>

<style scoped>
.img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>