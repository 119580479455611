<template>

    <b-modal size="md" id="modal-guide" centered title="BootstrapVue" hide-footer hide-header no-close-on-esc
             hide-header-close no-close-on-backdrop>
        <div class="row ">
            <div class="col-lg-12">
                <button  @click="$bvModal.hide('modal-guide')" type="button" class="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="col-lg-12 text-center pb-3">
                <h4 class="text-center">{{ $t('help.troubleshooting') }}</h4>
            </div>

            <div class="col-lg-12 mb-4" v-for="(value,key) in $t('help.troubleshoot_aq')">
                <h6 class="font-weight-bold mb-3">{{value.question}}</h6>
                <p v-html="value.answer"></p>

            </div>

        </div>

    </b-modal>
</template>
<script>


    export default {
        data() {
            return {}
        },
        mounted() {

        },
        methods: {}
    }
</script>
