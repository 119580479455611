<template>
   <div id="dashboard_wrapper">
      <!-- Page Heading -->
      <h1 class="h3 mb-4 text-gray-800">My Page</h1>

      <div class="row">

         <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                     <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                           <router-link to="/dashboard/my-page/setting">
                              {{ $t("account-page-facility-my-page.setting") }}
                           </router-link>
                        </div>
                     </div>
                     <div class="col-auto">
                        <i class="fas fa-check fa-2x text-gray-300"></i>
                     </div>
                  </div>
                  <div class="row no-gutters">
                     <span class="text-muted small">
                        {{ $t("account-page-facility-my-page.setting_description") }}
                     </span>
                  </div>
               </div>
            </div>
         </div>

         <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                     <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                           <router-link to="/dashboard/my-page/templates">
                              {{ $t("account-page-facility-my-page-message.setting") }}
                           </router-link>
                        </div>
                     </div>
                     <div class="col-auto">
                        <i class="fas fa-comment fa-2x text-gray-300"></i>
                     </div>
                  </div>
                  <div class="row no-gutters">
                     <span class="text-muted small">
                        {{ $t("account-page-facility-my-page-message.setting_description") }}
                     </span>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                     <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                           <router-link to="/dashboard/my-page/categories">
                              {{ $t("account-page-facility-my-page-category.page-title") }}
                           </router-link>
                        </div>
                     </div>
                     <div class="col-auto">
                        <i class="fas fa-info-circle fa-2x text-gray-300"></i>
                     </div>
                  </div>
                  <div class="row no-gutters">
                     <span class="text-muted small">
                        {{ $t("account-page-facility-my-page-category.page-description") }}
                     </span>
                  </div>
               </div>
            </div>
         </div>


         <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
               <div class="card-body">
                  <div class="row no-gutters align-items-center">
                     <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                           <router-link to="/dashboard/my-page/announcements">
                              {{ $t("account-page-facility-my-page.announce") }}
                           </router-link>
                        </div>
                     </div>
                     <div class="col-auto">
                        <i class="fas fa-bell fa-2x text-gray-300"></i>
                     </div>
                  </div>
                  <div class="row no-gutters">
                     <span class="text-muted small">
                        {{ $t("account-page-facility-my-page.announce_description") }}
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         facility: window._app_facility,
      };
   },
};
</script>
