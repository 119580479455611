<template>
  <div class="table-responsive-lg">
    <table class="table table-bordered font-res">
      <th colspan="2">{{ $t("touch-down.sanitation") }}</th>
      <template v-if="!is_other">
        <tr v-for="sanitation in sanitations" :key="sanitation.id">
          <td>{{ getLabel(sanitation.facility_questionnaire[0]) }}</td>
          <td style="width: 35%">
            {{ sanitation.value }}
          </td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="sanitation in sanitations" :key="sanitation.id">
          <td>{{ getLabel(sanitation.facility_questionnaire) }}</td>
          <td style="width: 35%">
            {{ sanitation.value }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import t from "typy";
import { core_questions } from "@/modules/checkin-facility/utils/mockup";

export default {
  name: "Sanitation",

  props: {
    sanitations: {
      type: Array,
      required: true,
    },
    is_other: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    getLabel(obj) {
      if (t(core_questions, this.$i18n.locale).isDefined == false)
        return obj.label;

      return core_questions[this.$i18n.locale].labels[obj.system_name];
    },
  },
};
</script>

<style lang="scss" scoped>
$font-size-base: 1rem;

.font-res {
  font-size: $font-size-base;
}

@media (max-width: 1200px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 768px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 425px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 375px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 320px) {
  .font-res {
    font-size: $font-size-base * 0.48;
  }
}
</style>