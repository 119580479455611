<template>
    <b-modal id="form" class="modal-xxl" centered hide-footer size="xl">
        <form @submit.prevent="pre_submit" class="px-4">
            <div class="row">

                <div class="col-lg-12" v-if="config.loading">
                    <div class="alert alert-success" role="alert">
                        {{ $t("account-page-facility-my-page-announcement.form.success") }}
                    </div>
                </div>
                <div class="col-lg-12 form-group">
                    <label for="exampleInputEmail1">{{ $t("account-page-facility-my-page-announcement.form.subject")
                    }}*</label>
                    <input type="text" class="form-control" v-model="data.subject" required>
                    <small id="emailHelp" class="form-text text-muted">{{
                        $t("account-page-facility-my-page-announcement.form.subject_note") }}.</small>
                </div>

                <div class="col-lg-12 form-group">
                    <label for="exampleInputEmail1">{{
                        $t("account-page-facility-my-page-announcement.form.effective_dates") }}*</label>
                    <div class="row">
                        <div class="col-lg-2 col-form-label">
                            <label for="exampleInputEmail1">{{
                                $t("account-page-facility-my-page-announcement.form.from") }}</label>
                        </div>
                        <div class="col-lg-5 ">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">{{
                                    $t("account-page-facility-my-page-announcement.form.date") }}</label>
                                <div class="col-sm-10">
                                    <input type="date" v-model="data.from_date" class="form-control" required>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 ">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">{{
                                    $t("account-page-facility-my-page-announcement.form.time") }}</label>
                                <div class="col-sm-10">
                                    <input type="time" v-model="data.from_time" class="form-control" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2 col-form-label">
                            <label for="exampleInputEmail1">{{
                                $t("account-page-facility-my-page-announcement.form.to") }}</label>
                        </div>
                        <div class="col-lg-5 ">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">{{
                                    $t("account-page-facility-my-page-announcement.form.effective_dates") }}</label>
                                <div class="col-sm-10">
                                    <input type="date" v-model="data.to_date" class="form-control" required>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 ">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-2 col-form-label">{{
                                    $t("account-page-facility-my-page-announcement.form.time") }}</label>
                                <div class="col-sm-10">
                                    <input type="time" v-model="data.to_time" class="form-control" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 form-group">
                    <ul class="nav nav-tabs">
                        <li class="nav-item " v-if="languages.length" v-for="(value, key) in languages">
                            <a class="nav-link " :class="{ 'active': value.code == config.curent_language }"
                                href="javascript:void(0)" @click="change_lang(value.code)">{{
                                    $t(`account-page-facility-language-option.${value.name}`)
                                }}</a>
                        </li>
                    </ul>
                </div>
                <template v-if="data.body[config.curent_language]">
                    <div class="col-lg-12 form-group">
                        <label for="exampleInputEmail1">{{
                            $t("account-page-facility-my-page-announcement.form.title") }}*</label>
                        <input type="text" class="form-control" required v-model="data.body[config.curent_language].title">
                        <small id="emailHelp" class="form-text text-muted">{{
                            $t("account-page-facility-my-page-announcement.form.title_note") }}. </small>
                    </div>
                    <div class="col-lg-12 form-group">
                        <small id="emailHelp" class="form-text text-muted">
                            {{
                                $t("account-page-facility-my-page-announcement.form.content_note") }}</small>
                        <vue-editor v-model="data.body[config.curent_language].content" :editor-toolbar="config.toolbar" />
                    </div>
                </template>
                <div align="center" class="col-lg-12 form-group">
                    <button type="button" class="btn btn-danger ml-3" @click="close"> {{
                        $t("account-page-facility-my-page-announcement.form.close") }}</button>
                    <button type="submit" class="btn ml-3 btn-primary"> {{
                        $t("account-page-facility-my-page-announcement.form.confirm") }} </button>
                </div>
            </div>
            <confirmation-modal-component :message="config.message"
                @click-handler="config.mode == 'create' ? submit() : update()"></confirmation-modal-component>
        </form>
    </b-modal>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ConfirmationModalComponent from "../../components/confirmation-modal.component";
export default {
    components: { VueEditor, ConfirmationModalComponent },
    data() {
        return {
            data: {
                body: {}
            },
            language_filter: {
                paginate: 0,
                name: "language",
                facility_id: window._app_facility.id,
            },
            languages: [],
            facility: window._app_facility,
            config: {
                curent_language: "en",
                toolbar: [
                    ["bold", "italic", "underline", "link"],
                    [{ list: "ordered" }, { list: "bullet" }],
                ],
                mode: "create",
                loading: false,
                message: null
            }
        };
    },
    async mounted() {
        await this.init_languages(this.language_filter);
        // await this.show();

    },
    methods: {
        change_lang(language) {

            this.config.curent_language = language;
            this.data.body[language].content = this.data.body[language].content ? this.data.body[language].content : " ";
            console.log(this.data.body[language]);
        },
        async pre_submit() {
            let message = this.$t("account-page-facility-my-page-announcement.form.are_you_sure");
            let status = true;

            this.languages.forEach((value, key) => {
                if (this.data.body[value.code]) {
                    if (!this.data.body[value.code].title || !this.data.body[value.code].content) {
                        status = false
                    }
                }

            });

            if (!status) {
                message = message + " " + this.$t("account-page-facility-my-page-announcement.form.are_you_sure_extra");
            }
            this.config.message = message;
            this.$bvModal.show('modal-confirmation')
        },
        async submit() {

            await this.$lionheart
                .post("/v2/my-page/announcements", this.data).then(async (response) => {
                    console.log(response.data.data);
                    this.config.loading = true;
                    this.data = {
                        body: {},
                        facility_id: this.facility.id
                    }

                    this.init_form("edit", response.data.data.id)
                    await this.init_data();
                    setTimeout(() => {
                        this.config.loading = false;
                    }, 5000);
                    this.$emit("click-handler");
                })
        },
        async update() {

            await this.$lionheart
                .put("/v2/my-page/announcements/" + this.data.id, this.data).then(async () => {
                    this.config.loading = true;
                    await this.show(this.data.id);
                    await this.init_data();
                    setTimeout(() => {
                        this.config.loading = false;
                    }, 5000);
                    this.$emit("click-handler");
                })
        },
        async show(id) {

            this.data = await this.$lionheart
                .get("/v2/my-page/announcements/" + id)
                .then((r) => r.data.data);

        },
        async init_form(mode = "create", id = null) {
            this.data = {
                body: {},
                facility_id: this.facility.id
            }
            this.config.mode = mode;
            if (mode == "edit") {
                this.config.mode = mode;
                await this.show(id);
            }

            await this.init_data();
            this.$bvModal.show("form");

        },
        async init_data() {

            console.log(this.languages);
            this.languages.forEach((value, key) => {
                if (!this.data.body[value.code]) {
                    this.data.body[value.code] = {
                        title: "",
                        content: ""
                    }
                }

            });
        },
        async init_languages(query) {
            const response = await this.$lionheart.get("/v2/facility-settings", { params: query });

            if (response.data.data && response.data.data[0]) {

                this.languages = response.data.data[0].value;

            }

        },
        async close() {
            this.data = {
                body: {},
                facility_id: this.facility.id
            }
            await this.init_data();
            this.$bvModal.hide("form");
        }

    },
};
</script>

<style lang="scss">
#form {
    .modal-xl {
        max-width: 1440px;
    }
}
</style>