<template>
  <div>
    <b-card no-body class="mb-1" v-if="!is_loading">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b>{{ $t("pre_checkout.title") }}:</b>
      </b-card-header>

      <div
        id="accordion-1"
        class="collapse show"
        data-parent="#accordionExample"
      >
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered font-res"
              style="table-layout: fixed"
            >
              <tr v-for="(map, index) in data_map" :key="`map_${index}`">
                <td style="width: 35%">{{ map.key }}:</td>
                <td>
                  {{ extractValue(map, booking_details.data[0]) }}
                </td>
              </tr>
            </table>
          </div>
          <div
            class="d-flex"
            v-if="
              payment_status.credit_card_as_deposit ==
              booking_details.data[0].payment_status_id
            "
          >
            {{ $t("visit-information-form.deposit_note_2") }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<style>
.table td:nth-child(1) {
  word-wrap: break-word;
}
</style>

<script>
import moment from "moment-timezone";
import {
  paid_payment_status,
  payment_status,
} from "@/modules/checkin-facility/utils/mockup";

export default {
  props: {
    facility: Object,
    booking_details: Object,
    currency: String,
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
  },
  data() {
    return {
      data_map: {},
      paid_payment_status,
      moment,
      payment_status,
      is_loading: true
    };
  },
  async mounted() {
    await this.fetchDataTemplate();
  },
  methods: {
    async fetchDataTemplate() {
      this.is_loading = true;
      this.$emit('checkout-is_loading', true);
      const facility = this.facility;
      const response = await this.$lionheart.get(
        `/facility-setting/data-template/${facility.id}/guest_checkout_detail`,
        { params: { parse: true } }
      );
      if (response.data.hasOwnProperty("value")) {
        const value = response.data.value;
        if (value.hasOwnProperty("specs")) {
          this.data_map = value.specs.list;
        }
      }
      this.is_loading = false;
      this.$emit('checkout-is_loading', false);
    },
    extractValue(map, obj) {

      try {
        const obj_value = map.value.split(".").reduce((acc, key) => {
            if (acc[key] && acc[key]['email_address']) {
                acc[key]['email_address'] = this.booking_details.data[0].facility_visitor_log.visitor.user.email;
            }
            return acc[key];
        }, obj);


        const isEmpty = !Number.isInteger(obj_value) && !obj_value;
        if (isEmpty) return '--'; // Fix for 0 value.
        else if (["checkin_date", "checkout_date"].includes(map.system_name)) {
          return moment(obj_value, "YYYYMMDD").format("YYYY-MM-DD");
        } else if (["total_charge", "net_charge"].includes(map.system_name)) {
          const cadStatus = payment_status.credit_card_as_deposit;
          const payStatus = obj.payment_status_id;
          if (cadStatus == payStatus) return obj_value;
          else if (paid_payment_status.includes(obj.payment_status_id)) {
            return this.$t("pre_checkout.paid");
          }
        }

        // update the email

        return !isEmpty ? obj_value : '--';
      } catch (e) {
        return '--';
      }
    },
  },
  watch: {
    language() {
      this.fetchDataTemplate();
    },
  },
};
</script>

<style lang="scss" scoped>
$font-size-base: 1rem;

.font-res {
  font-size: $font-size-base;
}

@media (max-width: 1200px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 768px) {
  .font-res {
    font-size: $font-size-base;
  }
}

@media (max-width: 425px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 375px) {
  .font-res {
    font-size: $font-size-base * 0.65;
  }
}

@media (max-width: 320px) {
  .font-res {
    font-size: $font-size-base * 0.48;
  }
}
</style>