<template>
	<div>
      <input type="hidden" :value="changeTitle()" />
  		<router-view/>
		<div v-if="! cs.disableLoader">
			<loading :active.sync="cs.isLoading" 
				:can-cancel="false" 
				:is-full-page="true"
				loader="bars"
				:width="70"
				:height="70"
				color="#50a4f2" />
		</div>
  	</div>
</template>

<script>
	import { mapState } from 'vuex';
	// Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

	export default {
		components: {
            Loading
    },
    methods: {
      changeTitle() {
        const title = {
          en: 'QR Code and Self Check-In',
          ja: 'QRコード x セルフチェックインシステム',
          ko: 'QR Code and Self Check-In',
          "zh-CN": 'QR Code and Self Check-In',
          "zh-TW": 'QR Code and Self Check-In',
        }

        document.title = title[this.$i18n.locale]
      }
    },
		computed: {
			...mapState('checkin-site', {
				cs : state => state
			})
		}
	}
</script>