<template>
    <b-modal id="file-upload" hide-header-close hide-footer no-close-on-backdrop centered
             :title='$t("account-page-details-my-page-message.upload_files")'>

        <div class="row">
            <div class="col-lg-12 text-center">
                <a href="#" class="cursor-pointer text-secondary text-decoration-none">
                    <label>
                        <i class="fa fa-upload fa-2x "></i>
                        <input type="file" @change="upload($event)" name="myfile" ref="file_input" style="display:none">
                    </label>
                    <br>
                    <small
                        @click="$refs.file_input.click()">{{ $t("account-page-details-my-page-message.upload_choose") }}</small>
                </a>
                <br>
                <small class="text-success"
                       v-if="config.success"> {{ $t("account-page-details-my-page-message.upload_success") }}</small>
            </div>
        </div>
        <hr>
        <div class="row">

            <div class="col-lg-12 table-responsive">
                <table class="table table-bordered">
                    <tr v-for="(value,key) in data">
                        <td class="w-25 text-center" style="  vertical-align: middle;">

                            <button @click="remove(key)" type="button" class="btn-danger btn rounded-0"><i
                                class="fa fa-times"></i></button>

                        </td>
                        <td style="  vertical-align: middle;" class="w-75">{{ value.display_name }}</td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-12 pb-2">
                <button type="button" @click="$bvModal.hide('file-upload')" class="btn btn-danger btn-block">{{ $t("account-page-details-my-page-message.back") }}</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "MessageFileUpload",
    data() {
        return {
            data: [],
            config: {
                success: false,
            }
        }
    },
    mounted() {

    },
    methods: {
        async upload($event) {


            let file = $event.target.files[0];

            this.data.push({
                name: `${Date.now()}_${file.name.replaceAll(' ', '_').toLowerCase()}`,
                base64: await this.toBase64(file),
                display_name: file.name,
            });

            this.config.success = true;
            this.$emit('event', this.data);
            setTimeout(() => {
                this.config.success = false;
            }, 4000);

        },
        async remove(key) {
            this.$delete(this.data, key);
            this.$emit('event', this.data);
        },
        async clear() {
            this.data = [];
        }

    }
}
</script>

<style scoped>

.cursor-pointer {

}
</style>