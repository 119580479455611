<template>
    <div>
      <div v-for="(room, index) in multiple_data" :class="(confirm && room.filled_in_by_other) ? 'hide' : ''">
        <div class="d-flex align-items-center mb-3">
            <div>
              <b-button
                v-b-toggle="'collapse-question-' + index"
                :variant="room.filled_in_by_other ? 'dark' : 'primary'"
                :disabled="room.filled_in_by_other"
                @click="toggleRoom(index)"
                class="text-nowrap btn-w-lg"
              >
                <span class="when-opened">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
                <span class="when-closed">
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </span>
                <span class="ml-2">{{ $t('visit-information-form.room') }} {{ index + 1 }}</span>
              </b-button>
            </div>
            <div 
              class="d-flex align-items-center ml-3"
              v-if="index != 0 && 
                  !room.show && 
                  !confirm &&
                  selected_option == 'separately'" 
            >
              <label class="checkbox-container">
                <input 
                    type="checkbox"
                    checked="checked"
                    v-model="room.filled_in_by_other"
                    v-b-toggle="'collapse-question-' + index"
                />
                <span class="checkmark"></span>
              </label>
              <div class="ml-2">
                {{ $t('visit-information-form.filled-in-by-other') }}
              </div>
            </div>
        </div>

        <b-collapse :id="'collapse-question-' + index" :visible="selected_option == 'all' ? true : index == 0 ? true : false">
          <div class="my-3">
            <hr>
            <template v-for="c in questions">
              <default-component
                v-if="!specials.includes(c.system_name)"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms="{}"
                :companions="[]"
                :different="different"
                :questions="questions"
                :canBeCopy="canBeCopy"
                :form_choice_temp.sync="form_choice_temp"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <nationality-component
                v-if="c.system_name == 'nationality'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms="{}"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <email-component
                v-if="c.system_name == 'email'"
                :c="c"
                :confirm.sync="confirm"
                :user.sync="user"
                :visitor.sync="visitor"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <age-component
                v-if="c.system_name == 'age'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms="visitor_forms"
                :companions="companions"
                :different="different"
                :questions="questions"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <gender-component
                v-if="c.system_name == 'gender'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <dob-component
                v-if="c.system_name == 'dob'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
                :form_language.sync="form_language"
              />

              <body-temperature-component
                v-if="c.system_name == 'body_temperature'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <core-choices-component
                v-if="yesNo.includes(c.system_name)"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"
                :choice="'yes_no'"
                :canBeCopy="canBeCopy"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <core-choices-component
                v-if="choices2.includes(c.system_name)"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"
                :choice="'choices2'"
                :canBeCopy="canBeCopy"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />

              <relationship-component
                v-if="c.system_name == 'relationship_with_main_visitor'"
                :c="c"
                :confirm.sync="confirm"
                :visitor_forms.sync="visitor_forms"
                :companions.sync="companions"
                :different="different"
                :questions="questions"

                :multiple="true"
                :multiple_data.sync="multiple_data"
                :bd_index="index"
              />
          </template>

          <!-- (Camera Integration) -->
          <template v-for="c in questions">
            <gov-id-component
              v-if="c.system_name == 'gov_id'"
              :c="c"
              :confirm.sync="confirm"
              :visitor_forms.sync="visitor_forms"
              :companions.sync="companions"
              :questions="questions"
              
              multiple
              :multiple_data.sync="multiple_data"
              :bd_index="index"

              @image-captured="setVisitorImage($event)"
              @save-images="saveImages($event)"
              @retake-image="retakeImage($event)"
              @retake-gov-id="retakeGovId($event)"
            />
            <vaccine-card-component
              v-if="c.system_name == 'vaccine_card'"
              :c="c"
              :confirm.sync="confirm"
              :visitor_forms.sync="visitor_forms"
              :companions.sync="companions"
              :questions="questions"

              multiple
              :multiple_data.sync="multiple_data"
              :bd_index="index"

              @image-captured="setVisitorVaccineCardImages($event)"
              @save-images="saveImages($event)"
              @retake-image="retakeVisitorVaccineCardImage($event)"
              @retake-gov-id="retakeGovId($event)"
            />
            <signature-component
              v-if="c.system_name == 'signature'"
              :c="c"
              :confirm.sync="confirm"
              :visitor_forms.sync="visitor_forms"
              :companions.sync="companions"
              :different="different"
              :questions="questions"

              :multiple="true"
              :multiple_data.sync="multiple_data"
              :bd_index="index"
              :filled_in_by_other.sync="room.filled_in_by_other"
            />
          </template>

          <div class="form-group mt-3" v-if="confirm">
            <h5 class="mt-2">
              {{ $t("visit-information-form.other-companion-note") }}
            </h5>
            <p v-if="room.companions.length == 0">
              {{ $t("visit-information-form.companion-confirmation") }}
            </p>
          </div>
          <!-- Confirmation ui for companions -->
          <template v-for="(c, i) in room.companions" v-if="confirm">
            <div class="form-group-row">
              <div class="col-lg-12 alert alert-secondary py-2">
              <div class="d-flex justify-content-between">
                <div>{{ $t("visit-information-form.companion") }} {{ i + 1 }}</div>
                <a href="javascript:void(0)" v-b-toggle="`companion-${i}`" class="text-dark"><i class="fa fa-chevron-down"></i></a>
              </div>
              </div>
            </div>
            <b-collapse :id="`companion-${i}`" visible class="mt-2">
              <template v-for="qc in questions">
              <div 
                class="form-group row"
                v-if="
                  !sameToAll.includes(qc.system_name)
                  && qc.system_name != 'gov_id'
                  && qc.system_name != 'vaccine_card'
                "
              >
                <label class="col-auto col-form-label">
                {{ qc.label }}<small v-if="qc.required" class="text-danger">*</small>
                </label>
                <div class="col-auto" v-if="!specials.includes(qc.system_name)">
                <p class="text-break">{{ c.other_forms[qc.id] }}</p>
                </div>
                <div class="col-auto" v-if="qc.system_name == 'age'">
                <p>{{ c.other_forms[qc.id] }}</p>
                </div>
                <div class="col-auto" v-if="qc.system_name == 'gender'">
                <p>{{ getChoice("genders", c.other_forms[qc.id]) }}</p>
                </div>
                <div class="col-auto" v-if="qc.system_name == 'dob'">
                <p>{{ formatDate(c.other_forms[qc.id]) }}</p>
                </div>
                <div class="col-auto" v-if="qc.system_name == 'body_temperature'">
                <p>{{ c.other_forms[qc.id] }}</p>
                </div>
                <div class="col-auto" v-if="yesNo.includes(qc.system_name)">
                <p>{{ getChoice("yes_no", c.other_forms[qc.id]) }}</p>
                </div>
                <div class="col-auto" v-if="choices2.includes(qc.system_name)">
                <p>{{ getChoice("choices2", c.other_forms[qc.id]) }}</p>
                </div>
                <div class="col-auto" v-if="qc.system_name == 'relationship_with_main_visitor'">
                <p>{{ getChoice("relationships", c.other_forms[qc.id]) }}</p>
                </div>
              </div>
              </template>
              <template v-for="qc in questions">
                <div class="form-group row" v-if="qc.system_name == 'gov_id'">
                  <label class="col-auto col-form-label">
                  {{ qc.label }}<small v-if="qc.required" class="text-danger">*</small>
                  </label>
                  <div class="col-auto">
                  <p v-if="typy(c.other_forms[qc.id], 'dataUrl').isDefined">
                    <img class="img-thumbnail" :src="c.other_forms[qc.id].dataUrl"/>
                  </p>
                  </div>
                </div>
                <div class="form-group row" v-if="qc.system_name == 'vaccine_card'">
                  <label class="col-auto col-form-label">
                  {{ qc.label }}<small v-if="qc.required" class="text-danger">*</small>
                  </label>
                  <div class="col-auto">
                  <p v-if="typy(c.other_forms[qc.id], 'dataUrl').isDefined">
                    <img class="img-thumbnail" :src="c.other_forms[qc.id].dataUrl"/>
                  </p>
                  </div>
                </div>
              </template>
            </b-collapse>
            <hr />
          </template>

          </div>
        </b-collapse>

        <hr v-if="confirm">
      </div>
    </div>
</template>
<script>
  import t from "typy";
  import _ from "lodash";
  import NationalityComponent from "./NationalityComponent";
  import EmailComponent from "./EmailComponent";
  import AgeComponent from "./AgeComponent";
  import GenderComponent from "./GenderComponent";
  import DobComponent from "./DobComponent";
  import BodyTemperatureComponent from "./BodyTemperatureComponent";
  import CoreChoicesComponent from "./CoreChoicesComponent";
  import RelationshipComponent from "./companion/RelationshipComponent";
  import DefaultComponent from "./DefaultComponent";
  import GovIdComponent from "./GovIdComponent";
  import SignatureComponent from "./SignatureComponent";
  import VaccineCardComponent from "./VaccineCardComponent";
  import { main_form_methods } from "../../../../utils/mockup";
  const {
      getChoice,
      formatDate,
  } = main_form_methods;
  export default {
    components: {
      NationalityComponent,
      EmailComponent,
      AgeComponent,
      GenderComponent,
      DobComponent,
      BodyTemperatureComponent,
      CoreChoicesComponent,
      RelationshipComponent,
      DefaultComponent,
      GovIdComponent,
      SignatureComponent,
      VaccineCardComponent
    },
    props: {
      confirm: false,
      booking_details: {
        type: Object,
        default: {}
      },
      questions: {
        type: Array,
        default: []
      },
      multiple_data: {
        type: Array,
        default: []
      },
      visitor_forms: {
        type: Object,
        default: {}
      },
      specials: {
        type: Array,
        default: []
      },
      companions: {
        type: Array,
        default: []
      },
      different: {
        type: Array,
        default: []
      },
      canBeCopy: {
        type: Array,
        default: []
      },
      form_choice_temp: {
        type: Object,
        default: {
          ids: {}
        }
      },
      visitor: {
        type: Object,
        default: {}
      },
      user: {
        type: Object,
        default: {}
      },
      yesNo: {
        type: Array,
        default: []
      },
      choices2: {
        type: Array,
        default: []
      },
      sameToAll: {
        type: Array,
        default: []
      },
      selected_option: null,
      form_language: {
        type: String,
        default: 'en'
      }
    },
    data() {
      return {
        typy: t,
      }
    },
    methods: {
      getChoice,
      formatDate,
      toggleRoom(index) {
        if(index > 0) {
          this.multiple_data[index].show = (this.multiple_data[index].show == false) ? true : false
        }
      }
    }
  }
</script>
<style>
  #login_wrapper label {
    color: #6c757d;
  }
  .hide {
    display: none;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>

<style lang="scss" scoped>
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
