<template>
  <div>
    <div class="card-header py-3">
      {{ $t("quick_checkout_in_setting.qr_code_title") }}
    </div>
    <div class="card-body mt-2">
      <form @submit-prevent="submit">
        <div v-if="success" class="alert alert-success" role="alert">
          {{ $t("quick_checkout_in_setting.success_msg") }}
        </div>
        <div class="mb-3 font-weight-bold"> {{ $t("quick_checkout_in_setting.qr_code_sub_title") }}</div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-6">
                {{ $t("quick_checkout_in_setting.reservation_no_qr_code") }}
              </div>
              <div class="col-6">
                <toggle-button
                    v-model="checkin_by_rsv_number.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="true"
                    :sync="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="justify-content-left">
          {{ $t("quick_checkout_in_setting.qr_code_description") }}
        </div>


        <div class="row">
          <div class="col-lg-12 mt-5" align="center">
            <button
                @click.prevent="
                $router.push({ name: 'checkin-facility-dashboard-home' })
              "
                class="btn btn-danger ml-3"
            >
              {{ $t("quick_checkout_in_setting.back") }}
            </button>
            <button
                class="btn-primary btn ml-3"
                @click="$bvModal.show('modal-confirmation')"
                type="button"
            >
              {{ $t("quick_checkout_in_setting.confirm") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <confirmation-modal-component
        @click-handler="submit"
    ></confirmation-modal-component>
  </div>
</template>

<script>
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import QrcodeVue from "qrcode.vue";

export default {

  components: {
    ConfirmationModalComponent,
    QrcodeVue,
  },
  data() {
    return {
      checkin_by_rsv_number: {
        facility_id: window._app_facility.id,
        name: "checkin_by_rsv_number",
        value: {
          visibility: false,
        },
      },
      error: false,
      sendForm: false,
      success: false
    };
  },

  async mounted() {

    await this.init_settings();
  },

  methods: {
    async init_settings() {

      let setting = await this.$lionheart
          .get("/v2/facility-settings", {
            params: {
              name: this.checkin_by_rsv_number.name,
              facility_id: this.checkin_by_rsv_number.facility_id,
              paginate: 0,
            }
          })
          .then((r) => r.data.data);

      if (setting.length == 0) return;
      this.checkin_by_rsv_number = setting[0];

    },
    async submit() {
      const settings = await this.$lionheart
          .post("/v2/facility-settings", {...this.checkin_by_rsv_number})
          .then((r) => r.data)
      this.success = settings;
      this.init_settings();
    },
  }
};
</script>