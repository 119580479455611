<template>
    <b-modal
    size="md"
    id="modal-shareable-link"
    title="Share"
    centered
    hide-footer
    no-close-on-esc>
        <div class="row ">
            <div class="col-lg-12 text-center p-3">
                <h5>{{ $t('visit-information-form.share-this-link') }}</h5>
                <div class="d-flex align-items-center mt-5">
                    <input id="input-link" type="text" class="form-control link" :value="link" disabled>
                    <a
                    href="javascript:void(0)"
                    class="copy-to-clipboard"
                    v-b-tooltip.hover
                    :title="copy"
                    @click.stop.prevent="copyToClipboard(link)">
                        <i class="fa fa-copy"></i>
                    </a>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import t from 'typy';
    export default {
        props: {
            facility: {
                type: Object,
                default: {}
            },
            td: false,
            rsv_no: null,
        },
        data(){
            return {
                copy: '',
                link: '',
            }
        },
        mounted() {
            this.copy = this.$t('visit-information-form.copy-to-clipboard')
            this.$bvModal.show('modal-shareable-link');
            if(this.rsv_no) {
                this.link = `${window.location.origin}/${this.facility.slug_name}/checkin-core?rid=${this.rsv_no}`
            } else {
                this.link = `${window.location.origin}/${this.facility.slug_name}/checkin-core`
            }
        },
        methods: {
            async copyToClipboard(text) {
                let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

                // let inputLink = document.querySelector('input-linkj')
                this.copy = this.$t('visit-information-form.copied-to-clipboard')
                setTimeout(()=> {
                    this.copy = this.$t('visit-information-form.copy-to-clipboard')
                }, 1000)

                if (iOS) return this.iosCopyToClipboard(text);

                var copyText = document.createElement('textarea');
                copyText.innerHTML = text;
                document.body.appendChild(copyText);

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */
                copyText.focus();

                var result = document.execCommand('copy');
                document.body.removeChild(copyText);

                return result;
            },
            iosCopyToClipboard(text) {
                let range, selection;

                let textArea = document.createElement('textarea');

                textArea.readOnly = true;
                textArea.contentEditable = true;
                textArea.value = text;
                document.body.appendChild(textArea);

                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);

                document.execCommand('copy');
                document.body.removeChild(textArea);
            }
        },
    }
</script>
<style scoped>
    .copy-to-clipboard {
        background: #003e80;
        border-radius: 20px;
        padding: 8px 14px;
        margin-left: 15px;
        color: #fff;
    }
    .link {
        font-size: 13px;
    }
</style>
