<template>
  <div>
    <div v-if="!multiple">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
        </label>
        <div class="col-md-10">
          <camera-capture-button
            slot="upload-label"
            :show="!confirm"
            :mini="typy(visitor_forms[c.id], 'dataUrl').isDefined"
            @click="$bvModal.show('allow_camera_vaccine')"
          />
          <p v-if="typy(visitor_forms[c.id], 'dataUrl').isDefined && confirm">
            <img class="img-thumbnail" :src="visitor_forms[c.id].dataUrl" />
          </p>
        </div>

        <allow-camera-component id="allow_camera_vaccine" @ok="showCamera" />

        <camera-component
          id="vaccine-card-camera"
          :shots="guestCount"
          :question_id="c.id"
          is-for-vaccine
          @image-captured="setVisitorImage($event)"
        />

        <visitor-image-component
          id="visitor-vaccine-image"
          :images="images"
          :visitor_forms="visitor_forms"
          :companions="companions"
          :questions="questions"
          :allowDynamicAdding="allowAddCompanion"
          :allowRemoveCompanion="allowRemoveCompanion"
          @save-images="saveImages($event)"
          @retake-image="retakeImage($event)"
        />
      </div>

      <div
        class="form-group row"
        v-if="
          saved && !confirm && typy(visitor_forms[c.id], 'dataUrl').isDefined
        "
      >
        <label class="col-md-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
          <div>({{ getName() }})</div>
        </label>
        <div class="col-md-10">
          <camera-capture-box
            :capturing="isCameraSeen[0]"
            :video-id="`main-vaccine`"
            :canvas-id="`main-vaccine-canvas`"
            :src="visitor_forms[c.id].dataUrl"
            @re-take="() => { showCameraRetake('main-vaccine', 0) }"
            @capture="() => { captureImageRetake('main-vaccine', 0, c.id) }"
            @cancel="() => { hideCameraRetake('main-vaccine', 0) }"
            @toggle-camera="() => { toggleCamera('main-vaccine', 0, c.id) }"
            :disabledCapturing.sync="disabledCapturing[0]"
            :index="0"
            :allowDynamicAdding="allowAddCompanion"
            :allowRemoveCompanion="allowRemoveCompanion"
          />
        </div>
      </div>

      <template v-for="(companion, index) in companions">
        <div
          class="form-group row"
          v-if="
            saved &&
            !confirm &&
            typy(companion.other_forms[c.id], 'dataUrl').isDefined
          "
          :key="`c_${index}`"
        >
          <label class="col-md-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
            </div>
          </label>
          <div class="col-md-10">
            <camera-capture-box
               :capturing="isCameraSeen[index + 1]"
               :video-id="`companion-vaccine-${index + 1}`"
               :canvas-id="`companion-vaccine-${index + 1}-canvas`"
               :src="companion.other_forms[c.id].dataUrl"
               @re-take="() => { showCameraRetake('companion-vaccine', index + 1) }"
               @capture="() => { captureImageRetake('companion-vaccine', index + 1, c.id) }"
               @cancel="() => { hideCameraRetake('companion-vaccine', index + 1) }"
               @toggle-camera="() => { toggleCamera('companion-vaccine', index + 1, c.id) }"
               :disabledCapturing.sync="disabledCapturing[index + 1]"
               :index="index + 1"
               :allowDynamicAdding="allowAddCompanion"
               :allowRemoveCompanion="allowRemoveCompanion"
            />
          </div>
        </div>
      </template>
    </div>

   <!-- MULTIPLE -->
    <div v-else>
      <div class="form-group row">
        <label class="col-md-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
        </label>
        <div class="col-md-10">
          <camera-capture-button
            slot="upload-label"
            :show="!confirm"
            :mini="typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined"
            @click="$bvModal.show('allow_camera_vaccine-' + bd_index)"
          />
          <p
            v-if="
              typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined && confirm
            "
          >
            <img
              class="img-thumbnail"
              :src="multiple_data[bd_index].visitor_forms[c.id].dataUrl"
            />
          </p>
        </div>
      </div>

      <allow-camera-component
        :id="'allow_camera_vaccine-' + bd_index"
        @ok="showCamera"
      />

      <camera-component
        :id="'vaccine-card-camera-' + bd_index"
        :shots="multiple_data[bd_index].guestCount"
        :question_id="c.id"
        is-for-vaccine
        @image-captured="setVisitorImage($event)"
      />

      <visitor-image-component
        :id="'visitor-vaccine-image-' + bd_index"
        :images="mult_images"
        :visitor_forms="multiple_data[bd_index].visitor_forms"
        :companions="multiple_data[bd_index].companions"
        :questions="questions"
        :allowAddCompanion="allowAddCompanion"
        :allowRemoveCompanion="allowRemoveCompanion"
        @save-images="saveImages($event)"
        @retake-image="retakeImage($event)"
      />

      <!-- Main guest -->
      <div
        class="form-group row"
        v-if="
          saved &&
          !confirm &&
          typy(multiple_data[bd_index].visitor_forms[c.id], 'dataUrl').isDefined
        "
      >
        <label class="col-md-2 col-form-label">
          {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
          <div>({{ getName() }})</div>
        </label>
        <div class="col-md-10">
          <camera-capture-box
            :capturing="isCameraSeen[0]"
            :video-id="`main-vaccine-${bd_index}`"
            :canvas-id="`main-vaccine-canvas-${bd_index}`"
            :src="multiple_data[bd_index].visitor_forms[c.id].dataUrl"
            @re-take="() => { showCameraRetake('main-vaccine-' + bd_index, 0) }"
            @capture="() => { captureImageRetake('main-vaccine-' + bd_index, 0, c.id) }"
            @cancel="() => { hideCameraRetake('main-vaccine-' + bd_index, 0) }"
            @toggle-camera="() => { toggleCamera('main-vaccine-' + bd_index, 0, c.id) }"
            :disabledCapturing.sync="disabledCapturing[0]"
            :index="0"
            :allowDynamicAdding="allowAddCompanion"
            :allowRemoveCompanion="allowRemoveCompanion"
          />
        </div>
      </div>

      <template v-for="(companion, index) in multiple_data[bd_index].companions">
        <div
          class="form-group row"
          v-if="
            saved &&
            !confirm &&
            typy(companion.other_forms[c.id], 'dataUrl').isDefined
          "
          :key="`c_${index}`"
        >
          <label class="col-md-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown",
                  multiple,
                  bd_index
                )
              }})
            </div>
          </label>
          <div class="col-md-10">
            <camera-capture-box
               :capturing="isCameraSeen[index + 1]"
               :video-id="`companion-vaccine-${bd_index}-${index + 1}`"
               :canvas-id="`companion-vaccine-${bd_index}-${index + 1}-canvas`"
               :src="companion.other_forms[c.id].dataUrl"
               @re-take="showCameraRetake('companion-vaccine-' + bd_index, index + 1)"
               @capture="captureImageRetake('companion-vaccine-' + bd_index, index + 1, c.id)"
               @cancel="hideCameraRetake('companion-vaccine-' + bd_index, index + 1)"
               @toggle-camera="() => { toggleCamera('companion-vaccine-' + bd_index, index + 1, c.id) }"
               :disabledCapturing.sync="disabledCapturing[index + 1]"
               :index="index + 1"
               :allowDynamicAdding="allowAddCompanion"
               :allowRemoveCompanion="allowRemoveCompanion"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import t from "typy"
import CameraComponent from "./CameraComponent"
import CameraCaptureButton from "./CameraCaptureButton"
import CameraCaptureBox from "../../components/CameraCaptureBox"
import VisitorImageComponent from "./VisitorImageComponent"
import AllowCameraComponent from "./AllowCameraComponent"
import {
  main_form_methods,
  startCameraStream,
  stopCameraStream,
  captureImage
} from "../../../../utils/mockup"
const { showLabelOrPlaceholder } = main_form_methods

export default {
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {}
    },
    companions: {
      type: Array,
      default: []
    },
    questions: {
      type: Array,
      default: []
    },
    guestCount: {
      type: Number,
      default: 0
    },
    images: {
      required: false,
      type: Array
    },
    // Multiple room
    multiple: {
      type: Boolean
    },
    multiple_data: {
      type: Array
    },
    bd_index: null
    // End of multiple room
  },
  components: {
    CameraComponent,
    CameraCaptureButton,
    CameraCaptureBox,
    VisitorImageComponent,
    AllowCameraComponent
  },
  data() {
    return {
      typy: t,
      saved: false,
      isCameraSeen: [],
      mult_images: [], // for multiple rooms
      allowAddCompanion: false,
      allowRemoveCompanion: false,
      disabledCapturing: [],
      currentFacingMode: 'environment' // default to rear camera
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    showLabelOrPlaceholder,
    init(num) {
      this.disabledCapturing = []
      const isCameraSeen = []
      const disabledCapturing = []
      let length = num != undefined ? length : this.companions.length
      isCameraSeen.push(false)
      disabledCapturing.push(false)

      for (let i = 0; i < length; i++) {
        isCameraSeen.push(false)
        disabledCapturing.push(false)
      }

      this.isCameraSeen = isCameraSeen
      this.disabledCapturing = disabledCapturing
    },
    showCamera() {
      this.saved = false // To hide captured image/s
      if (!this.multiple) {
        this.$bvModal.show("vaccine-card-camera")
      } else {
        this.$bvModal.show(`vaccine-card-camera-${this.bd_index}`)
      }
    },
    setVisitorImage(images) {
      if (!this.multiple) {
        this.$emit("image-captured", images)
        this.$bvModal.show("visitor-vaccine-image")
      } else {
        this.mult_images = images
        this.$bvModal.show(`visitor-vaccine-image-${this.bd_index}`)
      }
    },
    saveImages(event) {
      this.saved = true // To show captured image/s
      if (!this.multiple) {
        this.$emit("save-images", event)
      } else {
        this.init(this.multiple_data[this.bd_index].companions.length)
      }
    },
    retakeImage(event) {
      if (!this.multiple) {
        this.$emit("retake-image", event)
      } else {
        this.mult_images[event.index].dataUrl = event.dataUrl
      }
    },
    getName() {
      let name = this.questions.find(x => x.system_name == "first_name")
      if (name != undefined) {
        if (!this.multiple) {
          if (t(this.visitor_forms[name.id]).isDefined) {
            if (t(this.visitor_forms[name.id]).isEmptyString == false) {
              return `${_.truncate(this.visitor_forms[name.id], {
                length: 10
              })}`
            }
          }
        } else {
          if (
            t(this.multiple_data[this.bd_index].visitor_forms[name.id])
              .isDefined
          ) {
            if (
              t(this.multiple_data[this.bd_index].visitor_forms[name.id])
                .isEmptyString == false
            ) {
              return `${_.truncate(
                this.multiple_data[this.bd_index].visitor_forms[name.id],
                {
                  length: 10
                }
              )}`
            }
          }
        }
      } else {
        name = this.questions.find(x => x.system_name == "full_name")
        if (!this.multiple) {
          if (t(this.visitor_forms[name.id]).isDefined) {
            if (t(this.visitor_forms[name.id]).isEmptyString == false) {
              return `${_.truncate(this.visitor_forms[name.id], {
                length: 10
              })}`
            }
          }
        } else {
          if (
            t(this.multiple_data[this.bd_index].visitor_forms[name.id])
              .isDefined
          ) {
            if (
              t(this.multiple_data[this.bd_index].visitor_forms[name.id])
                .isEmptyString == false
            ) {
              return `${_.truncate(
                this.multiple_data[this.bd_index].visitor_forms[name.id],
                {
                  length: 10
                }
              )}`
            }
          }
        }
      }

      return "n/a"
    },
    showCameraRetake(guest, index) {
      this.$set(this.isCameraSeen, index, true)
      this.startCameraStream(guest, index)
      this.$set(this.disabledCapturing, index, false)
    },
    hideCameraRetake(guest, index) {
      this.$set(this.isCameraSeen, index, false)
      this.stopCameraStream(guest, index)
    },
    async startCameraStream(guest, index, facingMode = this.currentFacingMode) {
      let camera = ""
      if (guest == "main-vaccine" && index === 0) {
        camera = "main-vaccine"
      } else if (guest == `main-vaccine-${this.bd_index}` && index === 0) {
        camera = `main-vaccine-${this.bd_index}`
      } else if (guest == `companion-vaccine-${this.bd_index}-${index}`) {
        camera = `companion-vaccine-${this.bd_index}-${index}`
      } else {
        camera = `${guest}-${index}`
      }

      const constraints = {
        video: {
          audio: false,
          facingMode: facingMode // uses the specified camera
        }
      }

      startCameraStream(camera, constraints)
    },
    stopCameraStream(guest, index) {
      let camera = ""
      if (guest == "main-vaccine" && index === 0) {
        camera = "main-vaccine"
      } else if (guest == `main-vaccine-${this.bd_index}` && index === 0) {
        camera = `main-vaccine-${this.bd_index}`
      } else if (guest == `companion-vaccine-${this.bd_index}-${index}`) {
        camera = `companion-vaccine-${this.bd_index}-${index}`
      } else {
        camera = `${guest}-${index}`
      }

      stopCameraStream(camera)
    },
    // switch to front and back camera method
    toggleCamera(guest, index) {
         this.currentFacingMode = this.currentFacingMode === 'environment' ? 'user' : 'environment'
         this.stopCameraStream(guest, index)
         this.startCameraStream(guest, index)
    },
    captureImageRetake(guest, index, question_id) {
      let canvasId = ""
      let cameraId = ""
      if (guest == "main-vaccine" && index === 0) {
        cameraId = "main-vaccine"
        canvasId = "main-vaccine-canvas"
      } else if (guest == `main-vaccine-${this.bd_index}` && index === 0) {
        cameraId = `main-vaccine-${this.bd_index}`
        canvasId = `main-vaccine-canvas-${this.bd_index}`
      } else if (guest == `companion-vaccine-${this.bd_index}-${index}`) {
        cameraId = `companion-vaccine-${this.bd_index}-${index}`
        canvasId = `companion-vaccine-${this.bd_index}-${index}-canvas`
      } else {
        cameraId = `${guest}-${index}`
        canvasId = `${guest}-${index}-canvas`
      }

      let image = {
        dataUrl: captureImage(canvasId, cameraId),
        index,
        question_id
      }

      if (!this.multiple) {
        this.$emit("retake-gov-id", image)
      } else {
        if (index === 0) {
          this.multiple_data[this.bd_index].visitor_forms[question_id] = {
            dataUrl: image.dataUrl
          }
        } else {
          this.multiple_data[this.bd_index].companions[index - 1].other_forms[
            question_id
          ] = {
            dataUrl: image.dataUrl
          }
        }
      }
      this.hideCameraRetake(guest, index)
    }
  }
}
</script>