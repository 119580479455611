<template>
  <div id="dashboard_wrapper">
    <!-- Page Heading -->
    <h1 class="h3 mb-4 text-gray-800">
      {{ $t("account-page-notif-control.title") }}
    </h1>

    <div class="row">
      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/pre-email">
                    {{ $t("account-page-notif-control.precheckin_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-check fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{ $t("account-page-notif-control.precheckin_tip") }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/message">
                    {{ $t("account-page-notif-control.confirm_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-envelope fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-notif-control.confirm_tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/schedule-pre-checkin-notice">
                    {{ $t("account-page-notif-control.schedule_pre_checkin_notice.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-bell fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-notif-control.schedule_pre_checkin_notice.short_description")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/other-settings">
                    {{ $t("account-page-notif-control.other_settings.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-hammer fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-notif-control.other_settings.short_description")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      facility: window._app_facility,
    };
  },
};
</script>