<template>
  <div id="dashboard_wrapper">
    <!-- Page Heading -->
    <h1 class="h3 mb-4 text-gray-800">{{ $t('account-page.dashboard-title') }}</h1>

    <div class="row">

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">

                    <router-link to="/admin/facilities">
                        Accounts
                    </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-user fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">List of Facilities</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">

                    <router-link to="/admin/traction/accounts">
                        Traction - Accounts
                    </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">List of Account</span>

            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    <router-link to="/admin/traction/engagements">
                        Traction - Engagement
                    </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-percent fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">List of Engagement</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">

                    <router-link to="/admin/payments">
                        Payments
                    </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-money-bill-alt fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">Booking Payments</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    async mounted() {

      
    }
  }
</script>
