<template>
  <div>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <!-- Sidebar Toggle (Topbar) -->
      <button type="button" class="btn btn-primary" @click.prevent="navigateToOrderSystem" v-if="enabledOrderSystem">
        <span class="order-system-button">
         <!-- {{ $t('account-page.order_system_text') }} -->
         Order System
        </span>
      </button>
      <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
      </button>

      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 d-none d-lg-inline text-gray-600 small font-weight-bold">
              <img :src="this.$i18n.locale == 'ja' ? flags.ja : flags.en" class="border mr-2">
              {{ this.$i18n.locale == 'ja' ? 'Japanese' : 'English' }}
              </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a class="dropdown-item" href="javascript:void(0)" @click.prevent="changeLang('en')">
              <img :src="flags.en" class="border mr-2">
              English
            </a>
            <a class="dropdown-item" href="javascript:void(0)" @click.prevent="changeLang('ja')">
              <img :src="flags.ja" class="border mr-2">
              Japanese
            </a>
          </div>
        </li>

        <div class="topbar-divider d-none d-sm-block"></div>

        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 d-none d-lg-inline text-gray-600 small font-weight-bold">{{ facility.name }}</span>
          </a>
          <!-- Dropdown - User Information -->
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a @click.prevent="logout" class="dropdown-item" data-toggle="modal" data-target="#logoutModal">
              <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              {{ $t('account-page.logoff') }}
            </a>
          </div>
        </li>

      </ul>
      
    </nav>
  </div>
</template>

<script>
   import { eventBus } from '@/main';
import { flags } from "../../../utils/mockup";
   export default {
      data() {
         return {
            facility: {},
            flags,
            enabledOrderSystem: false
         }
      },
      mounted() {
      this.facility = window._app_facility
      },
      methods: {
         changeLang(lang) {
            this.$i18n.locale = lang
            eventBus.$emit('changeLang', lang);
         },
         logout() {
            this.$storage.remove('auth');
            this.$storage.remove('auth_only_help_'+this.facility.id);
            this.$router.push({ name: 'checkin-facility-login'})
         },

         async navigateToOrderSystem() {

            let data = await this.$lionheart
            .get(`/generate-token-facility/${this.facility.id}`)
            .then((r) => r.data)
            .catch((e) => this.catcher(e));

            if (data.length <= 0 ) return;
            window.open(`${process.env.VUE_APP_ORDER_SYSTEM_BASE_URL}/auth/sso?token=${data.token}`)
         }

      }
   }
</script>