import _ from 'lodash'
import {VueEditor} from "vue2-editor";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";

export default {
    components: {
        VueEditor,
        ConfirmationModalComponent,
        LanguageButtonComponent
    },
    data() {
        return {
            data: {
                value: {}
            },
            customToolbar: [
                ["bold", "italic", "underline", "link"],
                [{'align' : []}],
                [{list: "ordered"}, {list: "bullet"}]
            ],
            config: {
                saved: false
            }
        }
    },
    async mounted() {
        this.data.facility_id = window._app_facility.id

        this.show(this.$i18n.locale);
    },
    methods: {
        async show(lang) {

            this.data.name = "message-precheckin-form-" + lang;
            const response = await this.$lionheart.get('/custom-messages/' + this.data.facility_id, {
                params: {name: this.data.name, fallback: "message-precheckin-form-en" }});
            this.data = response.data.data;
            this.data.facility_id = window._app_facility.id;
            this.config.saved = false;


        },
        async submit(data) {

            if (data) {
                const response = await this.$lionheart.post('/custom-messages', {...this.data});
                this.config.saved = true;
                this.data = response.data.data;
                window.scrollTo(0, 0);
            }


        },
        errorDisplay(d) {
            this.error_msg = d.error.msg
            setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
            return
        }
    }
}