<template>
  <b-card no-body>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b>{{ $t("touch-down.other-companion-note") }}:</b>
    </b-card-header>

    <div class="container mt-3">
      <div v-for="(companion, index) in other_companions" :key="index">
        <contact
          v-if="formatData(companion, 'contact')"
          :facility_id="facility_id"
          :contacts="formatData(companion, 'contact')"
          is_other
        />

        <sanitation
          v-if="formatData(companion, 'sanitation')"
          :sanitations="formatData(companion, 'sanitation')"
          class="mt-4"
          is_other
        />

        <visit-info
          v-if="formatData(companion, 'visit_info')"
          :visit_infos="formatData(companion, 'visit_info')"
          class="mt-4 mb-4"
          is_other
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import Contact from "./Contact";
import Sanitation from "./Sanitation";
import VisitInfo from "./VisitInfo";
import _ from "lodash";

export default {
  name: "OtherCompanion",

  props: {
    other_companions: {
      type: Array,
      required: false,
    },
    facility_id: {
      type: Number,
      required: true,
    },
  },

  components: {
    Contact,
    Sanitation,
    VisitInfo,
  },

  methods: {
    formatData(val, opt) {
      let checkin_info = _.groupBy(
        val.facility_companion_other_form_log,
        (obj) => obj.facility_questionnaire.section
      );

      let data = {
        value: val.nationality,
        facility_questionnaire: {
          label: "Nationality",
          system_name: "nationality",
        },
      };

      if (data.value == "--" || data.value == "") {
        checkin_info.contact.splice(5, 0);
      } else {
        checkin_info.contact.splice(5, 0, data);
      }

      switch (opt) {
        case "contact":
          return checkin_info.contact;
          break;
        case "sanitation":
          return checkin_info.sanitation;
          break;
        case "visit_info":
          return checkin_info.visit_info;
          break;
      }
    },
  },
};
</script>
