import moment from 'moment-timezone'
import _ from 'lodash'
import t from 'typy'
import Datepicker from 'vuejs-datepicker'

export default {
	components: {
		Datepicker
	},
	data() {
		return {
			submit_btn: '',
			error_msg: '',
			facility_id: null,
			selected: Date()
		}
	},
	async mounted() {
		this.facility_id = window._app_facility.id
	},
	methods: {
		async submitForm(e) {
			e.preventDefault()

			if (this.submit_btn == 'submit') {
				// console.log(moment.parseZone(this.selectedDate).utc())
				const timezone = moment.tz.guess()


				const d = await this.$lionheart.post('/log/ids-downloads', {
					created_at: moment(this.selected).format('YYYY-MM-DD HH:mm:ss'),
					facility_id: this.facility_id
				}, {responseType: 'arraybuffer'}).then(r => {
					const file = moment.tz(this.selected, timezone).format('YYYYMMDD')

			      	let blob = new Blob([r.data], { type: 'application/zip' })
			        const downloadUrl = window.URL.createObjectURL(blob)
			        const link = document.createElement('a');

			        link.href = downloadUrl;
			        link.setAttribute('download', `${file}.zip`); //any other extension

			        document.body.appendChild(link);

			        link.click();
			        link.remove();

			        return {
			        	error: {
			        		has_error: false
			        	}
			        }
				}).catch(e => this.catcher(e))
				
				if (d.error.has_error) return this.errorDisplay(d)
			}
		},
		errorDisplay(d) {
			this.error_msg = d.error.msg
			setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
			return
		}
	}
}