<template>
  <div>
    <!-- Sidebar -->
    <ul  ref="sideBar" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

      <!-- Sidebar - Brand -->
      <a class="sidebar-brand d-flex align-items-center justify-content-center" href="#">
        <div class="sidebar-brand-text mx-3">Internal {{ $storage.has('auth_admin') ? 'Dashboard' : 'Login'}}</div>
      </a>

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline" >
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

        <router-link v-if="$storage.has('auth_admin')"  tag="a" class="sidebar-brand d-flex align-items-center justify-content-center" :to="{name: 'checkin-admin-home'}">
            <div class="sidebar-brand-text mx-3"><i class="fas fa-home fa-2x"></i></div>
        </router-link>



    </ul>
    <!-- End of Sidebar -->
  </div>
</template>
<script>

  export default {
      components: {},
    data() {
      return { }
    },
    mounted() {
      if(this.$route.name == "checkin-admin-traction-accounts"){
        this.adjustSideNavVh( "130vh");
      }

      if(this.$route.name == "checkin-admin-traction-engagements"){
        this.adjustSideNavVh( "155vh");
      }
    },
    methods: {
      adjustSideNavVh(height){
        this.$refs.sideBar.style.height = height;
      }
    },
    watch:{
      $route (to, from){

        if(to.name == "checkin-admin-traction-accounts"){
          this.adjustSideNavVh( "130vh");
        }
        if(to.name == "checkin-admin-traction-engagements"){
          this.adjustSideNavVh( "155vh");
        }
      }
    }
  }
</script>