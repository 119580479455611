import formatters from '@/mixins/formatters.js';

export default {
    name: "show",
    data() {
        return {
            datum: {},
            data: [],
        };
    },
    methods: {
        async view(datum) {
            this.data = await this.$lionheart.get("v2/payment/pms/payout/transactions/" + datum.id, { params: { facility_id: datum.facility_id } }).then((success) => success.data.data);

            this.datum = datum;
            this.$bvModal.show("show-modal");
        },
    },
    mixins: [formatters],
};
