<template>
  <div class="form-group row">
    <label for="exampleInputEmail1" class="col-sm-2 col-form-label">
      {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
    </label>
    <div class="col-sm-10 my-auto" v-if="!multiple">
      <select
        v-if="!confirm"
        v-model="inputData[c.id]"
        :required="c.required ? true : false"
        class="form-control"
      >
        <option value="">--</option>
        <option v-for="n in changeNationality()" :value="n.CountryCode">
          {{ n.Nationality }}
        </option>
      </select>
      <p v-if="confirm" class="mb-0">
        {{ getNationality(inputData[c.id]) }}
      </p>
    </div>

    <!-- Multiple Room -->
    <div class="col-sm-10 my-auto" v-else>
      <select
        v-if="!confirm"
        v-model="multiple_data[bd_index].visitor_forms[c.id]"
        :required="
          multiple_data[bd_index].filled_in_by_other
            ? false
            : c.required
            ? true
            : false
        "
        class="form-control"
      >
        <option value="">--</option>
        <option v-for="n in changeNationality()" :value="n.CountryCode">
          {{ n.Nationality }}
        </option>
      </select>
      <p v-if="confirm" class="mb-0">
        {{ getNationality(multiple_data[bd_index].visitor_forms[c.id]) }}
      </p>
    </div>
  </div>
</template>

<script>
import t from "typy";
import _ from "lodash";
import { nationalities } from "../../../../utils/mockup";
import { mapActions, mapState } from "vuex";
import { checkinFormTypeWithMemorization, loadMemorizedData } from '../../../../utils/helpers'
export default {
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room

    // checkin form type
    checkin_form_type: "",
    reservation_no: null
  },
  data() {
    return {
      inputData: {},
      formType: ""
    };
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
  },
  updated() {
    this.handleFormType();
  },
  async mounted() {
    const rid = this.$route.query.rid
    checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);

    this.inputData = await loadMemorizedData(
      this,
      this.memorizedInputs,
      this.memorizedWalkInInputs,
      this.checkin_form_type,
      this.visitor_forms
    );
  },
  methods: {
    ...mapActions("memorizedInputs", [
        "saveInput",
        "saveWalkInInputs",
        "saveReservationNumber",
        "checkIfCheckinFormType"
      ]),

    handleFormType() {
      this.checkin_form_type === "others"
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },
    changeNationality() {
      if (t(nationalities, this.$i18n.locale).isDefined)
        return nationalities[this.$i18n.locale];
      // this.$i18n.locale = "en"; <-- Finding this bug cost me some hairs. (Mike)
      return nationalities["en"];
    },
    getNationality(code) {
      let label = nationalities[this.$i18n.locale].find(
        (obj) => obj.CountryCode == code
      );
      if (label) {
        label = label.Nationality;
        return label;
      } else {
        label = nationalities[this.$i18n.locale].find(
          (obj) => obj.Nationality == code
        );
        if (label) return label.CountryCode;
      }
      return "";
    },
  },
};
</script>

<style>
#login_wrapper label {
  color: #6c757d;
}
</style>
