<template>
    <div>
        <div class="card-header py-3">
            <p>{{ $t('account-page-facility-language-option.intro') }}</p>
        </div>
        <div class="card-body">            
            <form @submit.prevent="submit">
                <div class="col-lg-12" v-if="saved">
                    <div class="alert alert-success">{{ $t('account-page-facility-language-option.success_save') }}</div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>{{ $t('account-page-facility-language-option.languages') }}</th>
                                <th>{{ $t('account-page-facility-language-option.option') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(l, i) in languages" :key="i">
                                <td>{{ $t(`account-page-facility-language-option.${l.name}`) }}</td>
                                <td>
                                    <toggle-button
                                    @change="toggleOption"
                                    v-model="languages[i].enable"
                                    :disabled="l.required"
                                    :labels="{ checked: 'On', unchecked: 'Off' }"
                                    :value="l.enable"
                                    :sync="true"/>
                                    <span v-if="l.required"> ({{ $t('account-page-facility-language-option.required') }})</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12" align="center">
                        <button
                        @click.prevent="$router.push({name: 'checkin-facility-dashboard-home'})"
                        class="btn btn-danger ml-3">
                            {{ $t('account-page-facility-language-option.back') }}
                        </button>
                        <button
                        @click="$bvModal.show('modal-confirmation')"
                        type="button"
                        class="btn btn-primary ml-3">
                            {{ $t('account-page-facility-language-option.confirm') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <confirmation-modal-component @click-handler="submit"></confirmation-modal-component>
    </div>
</template>

<script>
    import _ from 'lodash'; 
    import t from 'typy';
    import ConfirmationModalComponent from "../components/confirmation-modal.component";

    export default {
        components: {
            ConfirmationModalComponent
        },
        data() {
            return {
                languages: [
                    {
                        name : 'en',
                        code : 'en',
                        enable: true,
                        required: true
                    },
                    {
                        name : 'ja',
                        code : 'ja',
                        enable: false,
                        required: false
                    },
                    {
                        name : 'ko',
                        code : 'ko',
                        enable: false,
                        required: false
                    },
                    {
                        name : 'zh-cn',
                        code : 'zh-CN',
                        enable: false,
                        required: false
                    },
                    {
                        name : 'zh-tw',
                        code : 'zh-TW',
                        enable: false,
                        required: false
                    }
                ],
                data: {
                    name: 'language',
                    value: []
                },
                saved: false
            }
        },
        async mounted() {
            this.data.facility_id = window._app_facility.id
            const response = await this.$lionheart.get('/v2/facility-settings', { params: {
                facility_id: this.data.facility_id,
                name: 'language',
                paginate: 0
            }}).then(r => r.data.data);

            if(response.length > 0) {
                response[0].value.forEach((value, key) => {
                    var index = this.languages.findIndex(x => x.name == value.name);
                    this.languages[index].enable = true
                });
            } else {
                this.languages[1].enable = true
            }
        },
        computed: {
        },
        methods: {
            async toggleOption() {
                this.data.value = []
                this.languages.forEach((value, key) => {
                    if(value.enable === true) {
                        this.data.value.push({
                            name: this.languages[key].name,
                            code: this.languages[key].code
                        })
                    }
                });
            },
            async submit() {
                if (this.data.value.length != 0) {
                    await this.$lionheart.post('/v2/facility-settings', {...this.data});
                    this.saved = true
                } else {
                    this.toggleOption()
                    this.submit()
                }
            }
        }
    }
</script>