import { visitor_payments_booking_statuses_filter, visitor_payments_date_filter, visitor_payments_payment_statuses_filter } from "../../../../utils/mockup";
import moment from "moment-timezone";
import Show from "./show.vue";
import formatters from '@/mixins/formatters.js';

export default {
    components: { Show },
    name: "index",
    data() {
        return {
            data: {
                data: [],
                current_page: 1,
                total: 0,
            },
            facility: window._app_facility,
            date_filter: visitor_payments_date_filter,
            payment_statuses: visitor_payments_payment_statuses_filter,
            booking_statuses: visitor_payments_booking_statuses_filter,
            config: {
                is_date_option_selected: false,
                is_status_option_selected: false,
                clear: false,
            },
            custom_filter: {
                payment_statuses: {},
                payment_statuses_values: [],
                booking_statuses: {},
                booking_statuses_values: [],
                deduct_number_of_days: 7,
                selected_date_option_key: 0,
                date_from: null,
                date_to: null,
            },
            filter: {
                date_to: null,
                date_from: null,
                per_page: 50,
                page: 1,
                timezone: moment.tz.guess(),
            },
        };
    },
    async mounted() {
        await this.init_filter();
        await this.list({ ...this.filter, page: 1 });
    },
    methods: {
        async list(data) {
            this.data = await this.$lionheart.get("facility-booking/list/payments", { params: data }).then((success) => success.data.data);
        },
        async paginate(page) {
            await this.list({ ...this.filter, page: page });
        },
        async search() {
            this.store_filter();
            await this.list(this.filter);
        },
        async init_filter() {
            this.filter.facility_id = this.facility.id;
            var filter = this.$storage.get(`visitor_payments_filter_${this.facility.id}`);
            if (filter) {
                Object.assign(this.$data, filter);
            } else {
                await this.populate_date(this.custom_filter.deduct_number_of_days);
            }
        },
        async download() {
            await this.$lionheart.get("facility-booking/list/payments", { params: { ...this.filter, download: 1 }, responseType: "arraybuffer" }).then((success) => {
                let data = success.data;
                const file = moment().format("YYYYMMDD");
                let blob = new Blob([data], { type: "application/xls" });
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `${file}_payments.xlsx`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        async store_filter() {
            this.$storage.set(`visitor_payments_filter_${this.facility.id}`, {
                filter: this.filter,
                config: this.config,
                custom_filter: this.custom_filter,
            });
        },
        async clear_filter() {
            this.config = {
                is_date_option_selected: false,
                is_status_option_selected: false,
                clear: false,
            };
            this.custom_filter = {
                payment_statuses: {},
                payment_statuses_values: [],
                booking_statuses: {},
                booking_statuses_values: [],
                deduct_number_of_days: 7,
                selected_date_option_key: 0,
            };
            this.filter = {
                date_to: null,
                date_from: null,
                per_page: 50,
                page: 1,
                facility_id: this.facility.id,
                timezone: moment.tz.guess(),
            };

            await this.store_filter();
            await this.list({ ...this.filter, page: 1 });
        },
        async populate_date(number_of_days) {
            var from = new Date();
            from.setDate(from.getDate() - number_of_days);
            this.filter.date_from = moment(from).format("YYYY-MM-DD");
            this.filter.date_to = moment(new Date()).format("YYYY-MM-DD");
        },
        async handle_custom_date() {
            this.filter.date_from = this.custom_filter.date_from;
            this.filter.date_to = this.custom_filter.date_to;
            this.$bvModal.hide("date-filter-modal");
            this.custom_filter.selected_date_option_key = this.custom_filter.is_custom_selected_date_option_key;
            this.config.is_date_option_selected = true;
            await this.store_filter();
            await this.list({ ...this.filter, page: 1 });
        },
        async select_date_option(data, key) {
            if (!data.modal) {
                this.custom_filter.selected_date_option_key = key;
                this.config.is_date_option_selected = true;
                this.custom_filter.deduct_number_of_days = data.number_of_days;
                await this.populate_date(this.custom_filter.deduct_number_of_days);
                await this.list({ ...this.filter, page: 1 });
            } else {
                this.custom_filter.is_custom_selected_date_option_key = key;
                this.$bvModal.show(data.target);
            }
            await this.store_filter();
        },
        async select_statuses($event, model, code, ids) {
            delete this.filter["payment_status_id"];
            delete this.filter["booking_status_id"];
            this.config.is_status_option_selected = false;
            this.custom_filter[`${model}_values`] = this.custom_filter[`${model}_values`].filter((value, index) => !ids.includes(value));

            if (this.custom_filter[model][code]) {
                this.custom_filter[`${model}_values`] = [...this.custom_filter[`${model}_values`], ...ids];
            }
            if (this.custom_filter.payment_statuses_values.length) {
                this.config.is_status_option_selected = true;
                this.filter.payment_status_id = this.custom_filter.payment_statuses_values.toString();
            }

            if (this.custom_filter.booking_statuses_values.length) {
                this.config.is_status_option_selected = true;
                this.filter.booking_status_id = this.custom_filter.booking_statuses_values.toString();
            }

            await this.store_filter();
            await this.list({ ...this.filter, page: 1 });
        },
    },
    mixins: [formatters],
};
