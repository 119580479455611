<template>
    <div class="row">
        <div class="col-lg-12 alert alert-warning" v-if="!facility.timezone && !config.updated">
            <div class="row">
                <div class="col-lg-12"> {{ $t("account-page-visitor.timezone_title") }}
                    <button type="button" class="btn btn-primary btn-sm" @click="update">
                        {{ $t("account-page-visitor.timezone_update_now") }}
                    </button>
                </div>
                <div class="col-lg-12 "> {{ $t("account-page-visitor.timezone_current") }}: {{ data.value }}</div>
            </div>
        </div>

        <div class="col-lg-12 alert alert-success" v-if="facility.timezone && config.updated">
            <div class="row">
                <div class="col-lg-12"> {{ $t("account-page-visitor.timezone_success") }}
                    <router-link to="/dashboard/setting" tag="a" class="btn btn-primary btn-sm">    {{ $t("account-page-visitor.timezone_go_setting") }}</router-link>
                </div>
                <div class="col-lg-12 "> {{ $t("account-page-visitor.timezone_set") }}: {{ data.value }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {

    data() {
        return {
            data: {
                name: "timezone",
                value: moment.tz.guess(),
                facility_id: window._app_facility.id
            },
            config: {
                updated: false
            },
            facility: {
                timezone: null
            }
        }
    },
    mounted() {
        this.facility = window._app_facility;


    },
    methods: {
        async update() {
            await this.$lionheart.post('/v2/facility-settings', this.data);
            this.config.updated = true;
            this.$emit('event', this.data);
            this.facility = {
                ...this.facility,
               timezone: this.data
            }

        }
    }
}
</script>

<style scoped>

</style>