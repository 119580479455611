<template>
  <b-modal :id="id" @show="init()">
    <div v-for="(img, imageIndex) in images" :key="imageIndex">
      <camera-capture-box
        :capturing="isCameraSeen[imageIndex]"
        :video-id="`camera-${imageIndex}`"
        :canvas-id="`canvas-${imageIndex}`"
        :src.sync="img.dataUrl"
        @re-take="() => { showCamera(imageIndex,true) }"
        @capture="() => { captureImage(imageIndex) }"
        @cancel="() => { hideCamera(imageIndex) }"
        @toggle-camera="() => { toggleCamera(imageIndex) }"
        @saveName="saveName(imageIndex, $event)"
        @removeCompanion="handleRemoveCompanion(imageIndex)"
        :showInput.sync="showInput[imageIndex]"
        :disabledCapturing.sync="disabledCapturing[imageIndex]"
        :index="imageIndex"
        :allowRemoveCompanion="allowRemoveCompanion"
        :allowDynamicAdding="allowDynamicAdding"
      />
      <!-- dropdown for tagging the owner of the pic -->
      <b-dropdown
        id="dropdown-1"
        :text="truncate(ddTexts[imageIndex].value, 15, imageIndex)"
        block
        variant="primary"
        class="mb-3 mt-1"
        v-if="showDropdown[imageIndex]"
      >
        <template v-for="q in questions">

          <template v-if="isFirstName(q.system_name)">
            <template v-for="r in questions">
              <template v-if="isLastName(r.system_name)">
                <b-dropdown-item
                  @click="
                    setDDText(`${main[q.id]} ${main[r.id]} `, imageIndex)
                    setMainImage(img)
                    setDisable(0)
                  "
                  :disabled="disabled[0]"
                >
                  {{ truncate(`${main[q.id]} ${main[r.id]} `) }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-for="(escort, i) in escorts"
                  :key="i"
                  @click="
                    setDDText(
                      `${escort.other_forms[q.id]} ${escort.other_forms[r.id]}`,
                      imageIndex
                    )
                    setCompanionImage(img, i)
                    setDisable(i + 1)
                  "
                  :disabled="disabled[i + 1]"
                >
                  {{
                    truncate(
                      `${escort.other_forms[q.id]} ${escort.other_forms[r.id]}`
                    )
                  }}
                </b-dropdown-item>
              </template>
            </template>
          </template>

          <!-- full name -->
          <template v-else-if="isFullName(q.system_name)">
            <!-- main guest -->
            <b-dropdown-item :key="q.id"
              @click="
                setDDText(`${mainGuest[q.id]}`, 0, imageIndex)
                setMainImage(img)
              "
            >
              {{ truncate(`${mainGuest[q.id]}`, 15,0) }}
            </b-dropdown-item>

            <!-- companions -->
            <b-dropdown-item
              v-for="(escort, escortIndex) in escorts"
              :key="escortIndex"
              @click="
                setDDText(`${escort.other_forms[q.id]}`, escortIndex + 1, imageIndex)
                setCompanionImage(img, escortIndex)
              "
            >
              {{ truncate(`${escort.other_forms[q.id]}`, 15, escortIndex + 1) }}
            </b-dropdown-item>
            
          </template>
        </template>
      </b-dropdown>
      <hr v-if="!isLastItem(imageIndex, images)" />
    </div>
   <div class="d-flex justify-content-end mt-4" v-if="allowDynamicAdding">
      <b-button variant="primary" class="btn-w-lg" @click="addNewPicture">
         {{ $t("visit-information-form.take_pictures_add_text") }} 
      </b-button>
   </div>
    <template #modal-footer>
      <div class="d-block w-100 text-center">
        <b-button
          variant="primary"
          class="btn-w-lg mr-4"
          :disabled="!hasImagesSet()"
          @click="saveImages"
        >
          {{ $t("checkout_details.save") }}
        </b-button>
        <b-button
            v-if="escorts.length > 0"
            variant="danger"
            class="btn-w-lg"
            @click="cancelCaptureImages"
         >
            {{ $t("visit-information-form.browse_file_cancel_photo_text") }}
         </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash"
import { eventBus } from "@/main";
import {
  startCameraStream,
  stopCameraStream,
  captureImage
} from "@/modules/checkin-facility/utils/mockup"

import CameraCaptureBox from "@/modules/checkin-facility/pages/checkin/components/CameraCaptureBox"
import t from 'typy';
import ConfirmDialogVue from '../../../../components/ConfirmDialog.vue';

export default {
   name: "VisitorImageComponent",
   components: { CameraCaptureBox },
   props: {
      id: {
         required: false,
         type: String,
         default: "visitor-image"
      },
      images: {
         type: Array,
         default: []
      },
      visitor_forms: {
         type: Object,
         default: {}
      },
      companions: {
         type: Array,
         default: []
      },
      questions: {
         type: Array,
         default: []
      },
      allowDynamicAdding: {
         type: Boolean,
         default: false
      },
      allowRemoveCompanion: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         mainGuest: {},
         escorts: [],
         ddTexts: [],
         isCameraSeen: [],
         imgs: [],
         disabled: [],

         showInput: [],
         showDropdown: [],
         disabledCapturing: [],
         isNameSet: [],

         currentFacingMode: 'environment' // default to rear camera
      }
   },
   computed: {
      imagesLenMoreOne() {
         return this.images.length > 1
      }
   },
   methods: {
      // trigger this when the modal show
      init() {

         this.mainGuest = this.visitor_forms
         this.escorts = this.companions
         this.imgs = []
         this.disabled = []

         this.showInput = []
         this.showDropdown = []
         this.disabledCapturing = []
         this.isNameSet = []

         const ddTexts = []
         const isCameraSeen = []
         const imgs = []
         const disabled = []

         const showInput = []
         const showDropdown = []
         const disabledCapturing = []
         const defaultNameObject = {nameIndex:  null, value: this.$t("visit-information-form.names") }

         ddTexts[0] = defaultNameObject
         imgs[0] = false
         disabled[0] = false
         isCameraSeen[0] = false

         showInput[0] = false
         showDropdown[0] = true
         disabledCapturing[0] = false

         for (let i = 0; i < this.escorts.length; i++) {
            ddTexts[i + 1] = defaultNameObject
            isCameraSeen[i + 1] = false
            imgs[i + 1] = false
            disabled[i + 1] = false

            showInput[i + 1] = false
            showDropdown[i + 1] = true
            disabledCapturing[i + 1] = true // on add disabled the capture
         }


         this.ddTexts = ddTexts
         this.isCameraSeen = isCameraSeen
         this.imgs = imgs
         this.disabled = disabled

         this.showInput = showInput
         this.showDropdown = showDropdown
         this.disabledCapturing = disabledCapturing
      },

      //array Modifiers 
      setDDText(text, nameIndex, toIndex) {
         let objectToSet = {nameIndex: nameIndex, value: text};

         // const isExists = this.isNameSet.filter((item, key) => item == nameIndex);
         // console.log('existing data ',isExists);

         // #WORKING!!!!1
         // if(isExists.length > 0) {
         //    const fromDdTextNameIndex = isExists[0];
      
         //    let isDdTextIndex = null;
         //    this.ddTexts.map((item, key) => {
         //       if(item.nameIndex === fromDdTextNameIndex) isDdTextIndex = key 
         //    })

         //    this.$set(this.ddTexts, toIndex, objectToSet);
         //    this.$set(this.ddTexts, isDdTextIndex, {nameIndex: null, value: null});

         //    let dataCollected = []
         //    this.ddTexts.map((item, key) => {
         //       if(item.nameIndex !== null) dataCollected.push(item.nameIndex)
         //    });

         //    this.isNameSet = dataCollected

         // } else {
         //    if(typeof(undefined) === text) {
         //       objectToSet = {
         //          nameIndex: nameIndex,
         //          value: `${this.$t('visit-information-form.default_name_text')} ${nameIndex + 1}`
         //       }
         //    }
         //    this.$set(this.ddTexts,toIndex, objectToSet);
         //    this.isNameSet.push(nameIndex);
         // }

         // logic of setting names
         
         const existing = this.ddTexts.filter((item, key) => item.nameIndex === nameIndex)
         if(existing.length > 0) {
            let fromDdTextIndex = null;
            fromDdTextIndex = this.ddTexts.findIndex((item) => item.nameIndex === nameIndex);

            this.$set(this.ddTexts, fromDdTextIndex, {nameIndex: null, value: null});// reset the where the name set previously
            this.$set(this.ddTexts,toIndex, objectToSet) // set the name to new array
            
            // set the value of imgs array this will determine whether the save button enabled/disabled
            this.imgs[toIndex] = true;
            this.imgs[fromDdTextIndex] = false;
         } else {
            if(typeof(undefined) === text) {
               objectToSet = {
                  nameIndex: nameIndex,
                  value: `${this.$t('visit-information-form.default_name_text')} ${nameIndex}`// no +1 because only companion is the one who will be null value
               }
            }
            this.$set(this.ddTexts,toIndex, objectToSet);
            this.imgs[toIndex] = true
         }
      },
      truncate(text, length = 10, index) {
         const defaultName = `${this.$t('visit-information-form.default_name_text')} ${index}`
         if(typeof(undefined) === text) return  _.truncate((defaultName), { length: length })
         return _.truncate(text, { length: length })
      },
      isFirstName(system_name) {
         return system_name == "first_name"
      },
      isLastName(system_name) {
         return system_name == "last_name"
      },
      isFullName(system_name) {
         return system_name == "full_name"
      },
      isLastItem(index, array) {
         return index === array.length - 1
      },

      // CAMERA functions
      showCamera(index, retake = false) {
         this.$set(this.isCameraSeen, index, true) //enable camera
         this.$set(this.imgs, index, false) //set imgs to false to disabled again the button of save
         
         if(retake) {// if this true it will not show the input for names
            this.$set(this.disabledCapturing, index, false) //set the camera box to enabled per index
         } else {
            this.$set(this.showDropdown, index, false) //set showDropdown to true to prevent selecting again the name
            this.$set(this.disabledCapturing, index, true) //set the camera box to enabled per index
            this.$set(this.showInput, index, true) //show the input of name again

         }
         this.startCameraStream(index)
      },
      hideCamera(index) { //related to cancel function
         this.$set(this.isCameraSeen, index, false)
         this.stopCameraStream(index)

         // for accidentally click add the guest will click the remove companion
         // check if the image is existing before removing the companion
         if(index === 0) {
            return;
         } else {
            const { govId } = this.extractFullNameIdAndGovid();
            let hasExistingImage = t(this.escorts[index - 1].other_forms, `[${govId}].dataUrl`).isDefined;
            if(!hasExistingImage && this.allowDynamicAdding) return this.handleRemoveCompanion(index); // no existing image so it will remove
         }
      },
      async startCameraStream(index, facingMode = this.currentFacingMode) {
         
         const camera = `camera-${index}`
         const constraints = {
            video: {
               audio: false,
               facingMode: facingMode // uses the specified camera
            }
         }
         startCameraStream(camera, constraints)
      },
      stopCameraStream(index) {
         const camera = `camera-${index}`
         stopCameraStream(camera)
      },
      // switch to front and back camera method
      toggleCamera(index) {
         this.currentFacingMode = this.currentFacingMode === 'environment' ? 'user' : 'environment'
         this.stopCameraStream(index)
         this.startCameraStream(index)
      },
      captureImage(index) {
         const canvasId = `canvas-${index}`
         const cameraId = `camera-${index}`
         const question_id = this.images[0].question_id

         let imageObject = {
            dataUrl: captureImage(canvasId, cameraId),
            index,
            question_id
         }

         if (index === 0) {
            this.setMainImage(imageObject);
         } else {
            let companionIndex = index - 1;
            this.setCompanionImage(imageObject,companionIndex);
         }

         this.imgs[index] = true;
         this.$emit("retake-image", imageObject)
         this.hideCamera(index)
      },
      // end CAMERA functions

      hasImagesSet() {
         for (let i = 0; i < this.imgs.length; i++) {
            if (!this.imgs[i]) {
               return false
            }
         }
         return true
      },

      addNewPicture(eventToFireInAddCompanion) {
         let index = this.images.length;
         const question_id = this.images[0].question_id;
         let newPicture = {
            dataUrl: '',
            question_id: question_id
         }

         const objectToSet = { nameIndex: index, value: this.$t("visit-information-form.names") };
         this.ddTexts.push(objectToSet);
         this.images.push(newPicture);
         // this is the start of event needed to trigger 
         // the e.preventDefault needed in adding new companion
         // this.$emit('addNewGuest',eventToFireInAddCompanion);
         eventBus.$emit('eventBusAddCompanion',eventToFireInAddCompanion);
         this.showCamera(index);
      },

      // Remove companion when taking pictures
      handleRemoveCompanion(companionIndex) {

         this.ddTexts.splice(companionIndex,1); // normal index
         this.imgs.splice(companionIndex,1); //determine if the save button will enable or disabled
         // this.disabled.splice(companionIndex,1); //used in dropdown to enable disabled selecting the name of the companion or main guest
         this.showInput.splice(companionIndex,1); // determine if the showing of input of name enable or disabled
         this.showDropdown.splice(companionIndex,1); //to show the dropdown for each guest
         this.disabledCapturing.splice(companionIndex,1); //when the input is enabled it manage the capturing of each index
         this.isCameraSeen.splice(companionIndex,1); //used to enable disabled camera showing
         this.images.splice(companionIndex,1); // image array

         // this emit location is on main_form.js on onMounted
         eventBus.$emit('eventBusRemoveCompanion',companionIndex - 1);
      },

      
      setMainImage(img) {
         this.mainGuest[img.question_id] = {
            dataUrl: img.dataUrl
         }
      },
      
      setCompanionImage(img, companion_index) {
         this.escorts[companion_index].other_forms[img.question_id] = {
            dataUrl: img.dataUrl
         }
      },

      // will get the id of gov id for checking if the object is exist in companion array
      extractFullNameIdAndGovid() {
         let fullNameId = null;
         let govId = null;
         let vaccineId = null;

         this.questions.filter((item) => {
            if(item.system_name === "full_name") { // full name
               fullNameId = item.id;
            }

            if(item.system_name === "gov_id") { // dataUrl
               govId = item.id;
            }
            if(item.system_name === "vaccine_card") { // dataUrl
               vaccineId = item.id;
            }
         });

         return {
            fullNameId, govId, vaccineId
         }
      },

      saveName(index,names) {
         let fullName = names[index];
         
         // prepare the object of companion - supply the gov id
         let emptyObject = {};
         const toIndex = index;
         let nameIndex = null;

         const { govId, fullNameId } = this.extractFullNameIdAndGovid()

         emptyObject[fullNameId] = fullName; // sample { 8889: test }
         emptyObject[govId] = { dataUrl: '' }; // sample { 61: imageUrl }

         // main guest
         if(index === 0) {
            nameIndex = index;
            let hasExistingImage = t(this.mainGuest, `[${govId}].dataUrl`).isDefined;
            // when saving the name it should persist the old picture when already captured - to avoid issue when the guest cancel retake image
            if(hasExistingImage){
               let oldImage = {
                  dataUrl: this.mainGuest[govId].dataUrl,
                  question_id: govId
               }
               this.setMainImage(oldImage);
            }

         // companion
         } else {
            nameIndex = index;
            const escortIndex = index - 1;
            let hasExistingImage = t(this.escorts[escortIndex].other_forms, `[${govId}].dataUrl`).isDefined;

            if(hasExistingImage){ // if existing reassign again the image
               let oldImage = {
                  dataUrl: this.escorts[escortIndex].other_forms[govId].dataUrl,
                  question_id: govId
               }

               this.setCompanionImage(oldImage,escortIndex);
            } else { // if not existing make it null the image..
               this.escorts[escortIndex].other_forms = emptyObject;
            }
         }
         this.setDDText(fullName, nameIndex, toIndex) //call the function to set the name im ddtext array
         this.$set(this.showInput, index, false); //hide input
         this.$set(this.showDropdown, index, true) //show dropdown with name assigned
         setTimeout(() => {
            this.$set(this.disabledCapturing, index, false); //enable upload input
         },300);
      },

      // Function to SAVE the images
      saveImages() {
         let guests = {
            visitor_forms: this.mainGuest,
            companions: this.escorts,
         }

         this.$emit("save-images", guests)
         this.$bvModal.hide(this.id)
      },

      cancelCaptureImages() {
         this.$bvModal.hide(this.id)
      },
   }
}
</script>

<style scoped>
.img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/* .overlay {
  --border-style: 3px solid red;
  --border-space: 20px;

  position: absolute;
  width: 100%;
  height: 100%;
}

.overlay-helper {
  position: relative;
  width: 90%;
  height: 56%;
}
.overlay-helper-2 {
  position: relative;
  width: 90%;
  height: 30%;
}

.overlay-element {
  padding: 20px;
  width: 150x;
  height: 15px;
  position: absolute;
}

.overlay-text {
  font-size: 1.75em;
  color: white;
  font-family: monospace;
}

.overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

.overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
  text-align: left;
}

.overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
  text-align: right;
}

#overlay-bottom-left-text {
  position: absolute;
  bottom: var(--border-space);
  left: var(--border-space);
}

#overlay-bottom-right-text {
  position: absolute;
  bottom: var(--border-space);
  right: var(--border-space);
} */
</style>