<template>
   <b-modal :id="id" centered hide-footer>
      <div class="row">
         <div class="col-lg-12">
            <cropper ref="cropper" :src="data.src" backgroundClass="bg-white" class="cropper" />
         </div>
      </div>
      <div class="row mt-3">
         <div class="col-sm-6">
            <button class="btn btn-block btn-outline-danger" @click="$bvModal.hide(id)">{{ $t("account-page-facility-my-page-message.cancel") }}</button>
         </div>
         <div class="col-sm-6">
            <button class="btn btn-block btn-outline-primary" @click="crop">{{ $t("account-page-facility-my-page-message.crop") }}</button>
         </div>
      </div>
   </b-modal>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
   props: {
      id: { type: String, default: "cropper" },
   },
   components: { Cropper },
   data() {
      return {
         data: {
            src: null,
         },
         metadata: {},
      };
   },
   methods: {
      crop() {
         const result = this.$refs.cropper.getResult();

         let input = {
            value: result.canvas.toDataURL("image/jpeg"),
            is_cropped: true,
         };
         this.$emit("event", input, this.metadata);

         this.$bvModal.hide(this.id);
      },
      init_upload(data, metadata = {}) {
         this.$bvModal.show(this.id);
         this.data.src = data;
         this.metadata = metadata;
      },
   },
};
</script>
