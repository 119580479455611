<template>
    <b-modal
    size="md"
    id="modal-select-option"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop>
        <div class="row ">
            <div class="col-lg-12 text-center pb-3">
                <h5>{{ $t('visit-information-form.select-way-to-checkin') }}</h5>
            </div>
            <div class="col-lg-12 mb-4">
                <div class="d-flex">
                    <label class="radio-container">
                        <input type="radio" v-model="type" value="all" class="mx-3 mt-1 radio-lg">
                        <span class="checkmark"></span>
                    </label>
                    <div>
                        <h6 class="font-weight-bold mb-3">{{ $t('visit-information-form.for-all-rooms') }}</h6>
                        <p>{{ $t('visit-information-form.for-all-rooms-description') }}</p>
                    </div>
                </div>
                <div class="d-flex">
                    <label class="radio-container">
                        <input type="radio" v-model="type" value="separately" class="mx-3 mt-1 radio-lg">
                        <span class="checkmark"></span>
                    </label>
                    <div>
                        <h6 class="font-weight-bold mb-3">{{ $t('visit-information-form.part-of-my-group') }}</h6>
                        <p class="mb-0">{{ $t('visit-information-form.part-of-my-group-description') }}</p>
                        <u>{{ $t('visit-information-form.part-of-my-group-second-description') }}</u>
                    </div>
                </div>
                <div class="text-center mt-5">
                    <button
                    :disabled="type == '' ? true : false"
                    :class="type == '' ? 'btn-dark' : 'btn-primary'"
                    @click="continueToForm"
                    type="button"
                    class="btn">
                        {{ $t('visit-information-form.continue') }}
                    </button>
                </div>
            </div>

        </div>
    </b-modal>
</template>

<script>
    export default {
        data(){
            return {
                show: 0,
                facility: {},
                type: ''
            }
        },
        mounted() {
            this.facility = window._app_facility;
            this.$bvModal.show('modal-select-option');
        },
        methods: {
            async continueToForm() {
                this.$emit("selected-option", this.type);
                this.$emit("update-next", 'checkin-form');
                if(this.type == 'separately') this.$emit("update-multiple-filled", 'checkin-form');
                this.$bvModal.hide('modal-select-option');
            }
        },
    }
</script>

<style lang="scss" scoped>
/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
</style>