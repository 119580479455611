<template>

    <b-modal size="md"  id="modal-help" centered title="BootstrapVue" hide-footer hide-header no-close-on-esc
             hide-header-close no-close-on-backdrop>
      <div class="row ">
          <div class="col-lg-12">
              <button @click="close(false)" type="button" class="close" >
                                          <span aria-hidden="true">&times;</span>
                  </button>
          </div>
          <div class="col-lg-12 text-center pb-3">
              <h5>{{$t('help.thank_you_to_register')}}</h5>
              <p class="my-3">{{$t('help.set_up')}}</p>
              <a @click="close(true)" href="#" class="text-primary"><u>{{$t('help.help_page')}}</u></a>
              <div class="row mt-3">
                  <div class="col-lg-12">
                      <div class="form-check">
                          <input v-model="show" class="form-check-input" type="checkbox" :true-value="1" id="defaultCheck1">
                          <label class="form-check-label" for="defaultCheck1">
                              {{$t('help.dont_show')}}
                          </label>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </b-modal>
</template>

<script>


    export default {
        data(){
            return {
                show: 0,
                facility: {}
            }
        },
        mounted() {
            this.facility = window._app_facility;
            if(!this.$storage.get('help_'+this.facility.id)){
                if(!this.$storage.get('auth_only_help_'+this.facility.id)) {
                    this.$bvModal.show('modal-help');
                }
            }
        },
        methods: {
            close(redirect){

                this.$storage.set('auth_only_help_'+this.facility.id,true);
                if(this.show == 1){
                    this.$storage.set('help_'+this.facility.id,true);
                }

                this.$bvModal.hide('modal-help')
                if(redirect){
                    this.$router.push('/dashboard/start-guide');
                }
            }

        }

    }
</script>
