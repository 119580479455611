<template>
    <b-modal
    size="md"
    id="modal-checkout-guide"
    centered
    title="BootstrapVue"
    hide-footer
    hide-header
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop>
        <div class="row ">
            <div class="col-lg-12 mb-2">
                <button  @click="$bvModal.hide('modal-checkout-guide')" type="button" class="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="col-lg-12 mb-2" v-for="(value,key) in $t('help.checkout')">
                <h6 class="font-weight-bold mb-1">{{ value.header }}</h6>
                <p v-html="value.description"></p>
            </div>
        </div>
    </b-modal>
</template>
<script>
    export default {
        data() {
            return {}
        },
        mounted() {
        },
        methods: {}
    }
</script>
