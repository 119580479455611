import { visitor_payments_booking_statuses_filter, visitor_payments_date_filter, visitor_payments_payment_statuses_filter } from "../../../../utils/mockup";
import moment from "moment-timezone";
import Show from "./show.vue";
import formatters from '@/mixins/formatters.js';

export default {
    components: { Show },
    name: "index",
    data() {
        return {
            data: {},
            facility: window._app_facility,

            config: {},
            custom_filter: {},
            filter: {
                facility_id: window._app_facility.id,
            },
        };
    },
    async mounted() {
        await this.list(this.filter);
    },
    methods: {
        async list(query) {
            this.data = await this.$lionheart.get("v2/payment/pms/payout/list", { params: query }).then((success) => success.data.data);
        },

        async search() {
            console.log(this.populateArrivalDate());
            await this.list({ facility_id: window._app_facility.id, ...this.populateArrivalDate() });
        },
        populateArrivalDate() {
            if (!isNaN(this.toUnixTS(this.filter.date_to)) && !isNaN(this.toUnixTS(this.filter.date_from))) {
                return {
                    lte: this.toUnixTS(this.filter.date_to),
                    gte: this.toUnixTS(this.filter.date_from),
                };
            }
            return {};
        },
        toUnixTS(date) {
            return Math.floor(new Date(date).getTime() / 1000);
        },
        async download() {
            await this.$lionheart
                .get("v2/payment/pms/payout/export", {
                    params: {
                        facility_id: window._app_facility.id,
                        ...this.populateArrivalDate(),
                    },
                    responseType: "arraybuffer",
                })
                .then((success) => {
                    let data = success.data;
                    const file = moment().format("YYYYMMDD");
                    let blob = new Blob([data], { type: "application/xls" });
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute("download", `${file}_payouts.xlsx`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        },
    },
    mixins: [formatters],
};
