<template>
  <div>
    <b-modal id="modal-message" hide-footer centered size="xl" no-close-on-backdrop modal-class="message"
             :no-enforce-focus="true">
      <template #modal-title>
        {{ $t("account-page-details-my-page-message.message") }}
      </template>
      <Messages :parameter="parameter" :visitor="visitor"/>
    </b-modal>
  </div>
</template>

<script>
import {booking_status} from "../../../../utils/mockup";
import {mapActions, mapGetters} from "vuex";
import Messages from "@/modules/checkin-facility/pages/dashboard/components/messaging/content/Messages";

export default {
  name: "Message",
  components: {Messages},
  data() {
    return {
      loadedState: false,
      parameter: {},
      visitor: {},
      tries: 0
    };
  },
  computed: {
    ...mapGetters("checkin-facility", {
      bookingDetails: "gettersBookingDetails",
    }),
  },
  methods: {
    ...mapActions("checkin-facility", {
      getBookingDetails: "actionGetBookingDetails",
    }),
    async init_modal(data) {
      this.visitor = data.visitor;
      this.parameter = {
        // for message list
        p: data.pass,
        rsvNo: data.pms_reservation_no,
        pCode: data.pms_code,

        // for status
        bs: data.booking_status.code,
        ps: data.payment_status.code,

        // for booking list
        em: data.parsed.email_address,
        e: data.visitor.user.email,
      };

      let params = {
        email:this.parameter.em,
        pass: this.parameter.p,
        facility_id: window._app_facility.id,
        mode: "pre-checkin",
      };
      this.init_booking(params,this.parameter);
    },
    async init_booking(params,opt) {
      if (this.tries <= 2) {
        await this.getBookingDetails(params);
        if (this.bookingDetails.length == 0) {
          params.email = opt.e
          this.init_booking(params);
          this.tries++;
        }else{
          this.$bvModal.show("modal-message");
          this.tries = 0;
        }
      }
    }
  },
};
</script>

<style lang="scss">
@media (min-width: 1200px) {
  .message {
    .modal-xl {
      max-width: 1450px !important;
    }
  }
}

.message {
  .cursor-pointer {
    cursor: pointer;
  }

  div.scrollmenu {
    overflow: hidden;
    white-space: nowrap;
  }

  div.scrollmenu button {
    display: inline-block;
    text-align: center;
    padding: 0.3rem 1rem;
    text-decoration: none;
    color: white;
    border-radius: 50px;
  }

  .modal-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .overflow-x {
    overflow-x: hidden;
  }

  .chat-message-left {
    display: flex;
    flex-shrink: 0;
    margin-right: auto;
  }

  .of-y-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .attachment {
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
  }

  .text-message {
    padding-left: 5rem;
  }
}
</style>
