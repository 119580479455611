import { mapState } from "vuex";
import PaymentOption from "../components/payment/PaymentOption.vue";
import Currency from "./../components/Currency.vue";

export default {
  components: {
    PaymentOption,
    Currency,
  },
  data() {
    return {
      navs: {
        paymentOption: 1,
      },
    };
  },
  computed: {
    ...mapState("checkin-facility", ["facility"]),
  },
  async mounted() {
    if (!this.facility.has_pms)
      return this.$router.push({
        name: "checkin-facility-dashboard-home",
      });
  },
  methods: {
    selectTab(name) {
      Object.keys(this.navs).map((obj) => (this.navs[obj] = 0));
      this.navs[name] = 1;
    },
  },
};
