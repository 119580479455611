<template>
   <b-modal v-model="showLocalModal" centered scrollable id="show-guest-detail" size="lg" :no-enforce-focus="true" hide-footer>

      <template #modal-title>
         {{ $t('facility_my_page_message_board.view_details_modal_text_title') }}
      </template>

      <MainDetailsWidget 
         v-if="loadedState"
         :visitor-log="vl"
         :visitor-form-log="vfl"
         :companion-form-log="vlc"
         :hasPms="true"
         @updated="refresh"
      />
   </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import MainDetailsWidget from '@/modules/checkin-facility/pages/dashboard/components/detail/MainDetailsWidget';
import moment from 'moment-timezone';

export default {
   components: { MainDetailsWidget },
   props: {
      showModal: {
         type: Boolean,
         default: false
      },
      visitor_log_id: {
         type: Number,
         default: 0
      }
   },
   data() {
      return {
         loadedState: false,
         vl: {
            visitor: { user: {}, },
         },
         vfl: {
            data: [],
         },
         vlc: {
            data: [],
         },
         facility: window._app_facility,
      }
   },
   computed: {
      ...mapGetters('checkin-facility', {
         bookingDetails: 'gettersBookingDetails'
      }),
      showLocalModal: {
         get() {
            return this.showModal
         },
         set(value) {
            this.$emit('toggle', value) //emit to parent toggle value show/hide (boolean)
         }
      }
   },
   watch: {
      async showModal(newValue) {
         if (newValue) {
            this.loadedState = false; // prevent mounting the main details widget

            await this.loadMainDetail();
         }
      }
   },
   methods: {
      async loadMainDetail() {
         await this.getVisitorLog();
         await this.getVisitorFormLog();
         await this.getCompanionLog();

         this.loadedState = true;
      },
      async getVisitorLog() {
         const visitorLog = await this.$lionheart
            .post("/log/get/facility-visitor", {
               paginate: false,
               id: this.bookingDetails[0].facility_visitor_log_id,
               timezone: moment.tz.guess(),
               facility_id: this.facility.id,
            })
            .then((r) => r.data.data)

         if (visitorLog.length <= 0) return;

         this.vl = visitorLog[0];
      },
      async getVisitorFormLog() {
         this.vfl = await this.$lionheart
            .post("/log/get/facility-visitor-other-form", {
               paginate: false,
               facility_visitor_log_id: this.bookingDetails[0].facility_visitor_log_id,
               facility_id: this.facility.id,
               version: 2,
            })
            .then((r) => r.data)

            // console.log(this.vfl.data, 'vfl data');

         let vfl = _.cloneDeep(this.vfl.data);
         this.vfl = _.groupBy(vfl, (obj) => obj.facility_questionnaire[0].section);

         this.populateEditData()
      },
      async getCompanionLog() {
         let vlc = await this.$lionheart
            .post("/log/get/facility-visitor-companion", {
               paginate: false,
               facility_visitor_log_id: this.bookingDetails[0].facility_visitor_log_id,
               facility_id: this.facility.id,
            })
            .then((r) => r.data)

         vlc = _.cloneDeep(vlc.data);

         vlc.map((obj) => {
            obj.fco = _.groupBy(
               obj.facility_companion_other_form_log,
               (obj) => obj.facility_questionnaire.section
            );
         });

         this.vlc = vlc;
      },

      async populateEditData() {
         this.edit_form = []
         this.email = this.vl.visitor.user.email
         const x = ['reservation_no', 'occupancy']

         this.vfl.contact.forEach((data, key) => {
            if (!x.includes(data.facility_questionnaire[0].system_name)) {
               this.edit_form.push({
               system_name: data.facility_questionnaire[0].system_name,
               rank: data.facility_questionnaire[0].rank,
               required: data.facility_questionnaire[0].required,
               id: data.id,
               value: data.value,
               })
               if (data.facility_questionnaire[0].system_name == 'dob') this.temp_date = data.value
            }
         });
      },
      async refresh() {
         this.loadedState = false; //destroy the main detail widget
         await this.loadMainDetail();
      },
      closeModal() {
         this.$emit('hideModal');
      }
   }
}
</script>