import _ from "lodash";
import moment from "moment-timezone";
import t from "typy";
import Vue from "vue";

import {
   main_form_methods, payment_gateway,
   payment_status, sys_choices
} from "../../../utils/mockup";

import { eventBus } from "@/main";
import DateDropdown from "vue-date-dropdown";
import Datepicker from "vuejs-datepicker";
import { mapActions, mapState } from "vuex";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import GuideComponent from "./../../../components/guide.component";
import TermsComponent from "./../../../components/terms.component";
import ConfirmationSeparatelyModal from "./../components/confirmationSeparatelyModal";
import localizedFilter from "./../components/localizedFilter";
import preCheckinBookingDetails from "./../components/preCheckinBookingDetails";
import preCheckinCustomForm from "./../components/preCheckinCustomForm";
import SelectOptionModal from "./../components/selectOptionModal";
import AgeComponent from "./form-components/AgeComponent";
import BodyTemperatureComponent from "./form-components/BodyTemperatureComponent";
import CoreChoicesComponent from "./form-components/CoreChoicesComponent";
import DefaultComponent from "./form-components/DefaultComponent";
import DobComponent from "./form-components/DobComponent";
import EmailComponent from "./form-components/EmailComponent";
import GenderComponent from "./form-components/GenderComponent";
import GovIdComponent from "./form-components/GovIdComponent";
import MultipleRoomComponent from "./form-components/MultipleRoomComponent";
import NationalityComponent from "./form-components/NationalityComponent";
import SignatureComponent from "./form-components/SignatureComponent";
import VaccineCardComponent from "./form-components/VaccineCardComponent";
import RelationshipComponent from "./form-components/companion/RelationshipComponent";
import GatewayPayment from "./../components/gatewayPayment.vue";


const {
  getChoice,
  formatDate,
  loadAgeDropdown
} = main_form_methods;

const sections = ["visit_info", "sanitation", "others"];
const specials = [
  "gender",
  "email",
  "nationality",
  "age",
  "dob",
  "gov_id",
  "internation_travel",
  "domestic_travel",
  "foreign_travel",
  "close_contact_corona_patient",
  "relationship_with_main_visitor",
  "body_temperature",
  "coughing",
  "doctor_certificate",
  "pass_affection",
  "terms_conditions",
  "signature",
  "vaccine_card"
];
const different = [
  "full_name",
  "first_name",
  "last_name",
  "age",
  "dob",
  "gender",
  "occupancy",
  "gov_id_number",
  "body_temperature",
  "address",
  "coughing",
  "doctor_certificate",
  "pass_affection",
  "prev_destination",
  "next_destination",
  "transportation",
  "internation_travel",
  "domestic_travel",
  "signature",
  "vaccine_card"
];
const canBeCopy = [
  "address",
  "coughing",
  "doctor_certificate",
  "pass_affection",
  "prev_destination",
  "next_destination",
  "transportation",
  "internation_travel",
  "domestic_travel",
  "foreign_travel",
  "close_contact_corona_patient"
];
const sameToAll = [
  "phone",
  "nationality",
  "email",
  "reservation_no",
  'terms_conditions',
  'signature'
];
const yesNo = [
  'coughing',
  'doctor_certificate',
  'pass_affection',
  'internation_travel',
  'domestic_travel',
];
const choices2 = [
  'foreign_travel',
  'close_contact_corona_patient'
];

export default {
  components: {
    DateDropdown,
    Datepicker,
    TermsComponent,
    GuideComponent,
    preCheckinCustomForm,
    preCheckinBookingDetails,
    LanguageButtonComponent,
    localizedFilter,
    NationalityComponent,
    EmailComponent,
    AgeComponent,
    GenderComponent,
    DobComponent,
    GovIdComponent,
    BodyTemperatureComponent,
    CoreChoicesComponent,
    RelationshipComponent,
    DefaultComponent,
    SelectOptionModal,
    MultipleRoomComponent,
    ConfirmationSeparatelyModal,
    SignatureComponent,
    VaccineCardComponent,
    GatewayPayment
  },
  data() {
    return {
      test: "",
      typy: t,
      page: 1,
      submit_btn: "",
      error_msg: "",
      checkin_msg: false,
      confirm: false,
      email_exists: false,
      form_language: "",
      facility_names: [],
      mode: "create",
      specials,
      c_specials: specials.concat([
        "phone",
        "address",
        "prev_destination",
        "next_destination",
        "transportation",
      ]),
      facility: {
        name: "...",
      },
      facility_name: "...",
      user: {
        email: "",
      },
      visitor: {
        visitor_id: null,
        first_name: null,
        last_name: null,
        address: null,
        phone_number: null,
        nationality: null,
      },
      visitor_forms: {},
      companions: [],
      questions: [],
      gov_id_exist: true,
      gov_id_companion: {
        exist: true,
      },
      custom_text_setting: {
        title: '',
        header: '',
      },
      form_choice_temp: {
        ids: {},
      },
      booking_details: {
        data: {},
        total: 0,
      },
      is_custom: false,
      next: "",
      enabled_languages: [],
      is_localized: false,
      localized_filters: [],
      selected_localized_category: null,
      td: false,
      noQuestions: false,
      different,
      canBeCopy,
      sameToAll,
      yesNo,
      choices2,
      guestCount: 1,
      images: [],
      selected_option: '',
      multiple_data: [],
      reservation_no: null,
      reservation_no_query: null,
      mr_visible: false,
      currency: "",
      signature_exist: true,
      signature_written: false,
      vaccine_card_images: [],
      vaccine_card_exist: true,
      vaccine_card_companion: {
        exist: true,
      },
      timer: null,
      // For not displaying forms on initial load
      is_loading: false,
      template_is_loading: false,
      checkin_form_type: "",
      showPaymentGateway: false
    };
  },
  async mounted() {

    await this.checkBrowser(); // in app browser

    this.facility = await this.$lionheart
      .post("/facility/list", { slug_name: this.$route.params.slug_name })
      .then((r) => this.catcher(r.data, "data[0].id", (r) => r.data[0]))
      .catch((e) => this.catcher(e));
    if (this.facility.error.has_error) return this.$router.push("/404");

    const el = await this.$lionheart.post("/facility-setting/list", {
      facility_id: this.facility.id,
      name: "language",
      paginate: false,
    });

    if (el.data.total != 0)
      el.data.data[0].value.forEach((value, key) => {
        this.enabled_languages.push(value.code);
      });
    else this.enabled_languages.push("en", "ja");

    await this.$lionheart.post("/facility-question/init", {
      facility_id: this.facility.id,
    });

    await this.loadQuestions();

    if (this.$storage.has("visitor")) {
      this.visitor.visitor_id = this.$storage.get("visitor").id;
      await this.loadVisitor();
    }
    if (this.$refs.ddAge0 && this.$refs.ddAge0[0]) {
      this.loadAgeDropdown(
        this.visitor_forms,
        this.$refs.ddAge0[0].getAttribute("data-key")
      );
    }

    let touchdown = (this.$route.query.ref == "td" || this.$route.query.ref == "tdf");
    if (this.$route.query.ref && touchdown) this.td = true;

    this.is_custom = this.facility.facility_setting.length > 0 ? true : false;
    await this.checkLocalizedFilter();

    // onmounted
    this.loadCustomTextSetting(this.$i18n.locale, this.facility.id);
    // Update from grand child
    this.$root.$on('update_email_exist', (status) => {
      this.email_exists = status;
    })

    await this.getCurrency();
    this.trackOpenedPCIForm(this.facility.id); 

    await this.getFixedGuestCountSetting(this.facility.id)

    //  for trigerring via refs the add companion
    eventBus.$on('eventBusAddCompanion', (clickEvent) => {
      this.addNewCompanionEventBus(clickEvent);
    });
    eventBus.$on('eventBusRemoveCompanion', (companionId) => {
      this.removeCompanionEventBus(companionId)
    });

    this.loadToTop(); // after all loaded
  },
  computed: {
    ...mapState('memorizedInputs', ['memorizedInputs', 'memorizedWalkInInputs', 'memorizedEmail']),
    ...mapState("guest", ["numberOfGuest", "guestCountSetting"])
  },
  methods: {
    ...mapActions('memorizedInputs', ['clearLocalStorage', 'clearWalkInLocalStorage']),
    ...mapActions('guest', ['getGuestCount']),

    getChoice,
    formatDate,
    loadAgeDropdown,
    // fetch the checkin form type 
    getCheckinFormType(value) {
      this.checkin_form_type = value !== 'email' ? value : 'email'
    },

    //fetch the fixed_guest_count settings 
    async getFixedGuestCountSetting(facilityId){

      if(facilityId) {
        try {
          const response = await this.$lionheart.get("/v2/facility-settings/fix-guest-count", { 
            params: {
              facility_id: facilityId
            }
          })

          this.getGuestCount(response.data.data)

        } catch(error) {
          console.error('Error fetching fixed guest count setting:', error);
        }
      }
    },

    async trackOpenedPCIForm(facilityId) {

      if(t(this.$route.query, 'tCode').isDefined) {
         if(this.$route.query.tCode != 'undefined') {
            await this.$lionheart.post("/v2/track/guest-activity", { trackingCode: this.$route.query.tCode, facilityId, action: 'clicked_link_pci_guide', metadata: null });
         }
      }

    },
    // move to the top 
    loadToTop() {
      this.$refs.loadToTop.scrollIntoView({ 
        behavior: 'smooth' 
      })
    },

    // check if in-app browser
    async checkBrowser() {
      if(this.$route.query.ref) return;

      const hostname = window.location.hostname;
      const slug_name = this.$route.params.slug_name;
      const rid = this.$route.query.rid

      if (navigator.userAgent.match(/Android/i)) {
          const intentUrl = `intent://${hostname}/${slug_name}/checkin-core?rid=${rid}&ref=1#Intent;scheme=https;action=android.intent.action.VIEW;end`;
          window.location.href = intentUrl;
      } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          window.open(`https://${hostname}/${slug_name}/checkin-core?rid=${this.$route.query.rid}&ref=1`);
      }
  },
    async loadQuestions() {
      if (this.form_language == "") {
        const language = this.enabled_languages.find((item) => {
          if (item == this.$i18n.locale) return true;
          return false;
        }, this);
        this.form_language = language === undefined ? "en" : language;
      }

      const fac_name = this.facility.facility_name.find(
        (obj) => obj.language == this.$i18n.locale
      );
      const fac_name_en = this.facility.facility_name.find(
        (obj) => obj.language == "en"
      );

      if (this.$i18n.locale == "ja") {
        this.facility_name = this.facility_name = this.facility.name;
      } else if (fac_name === undefined) {
        if (fac_name_en) {
          this.facility_name = fac_name_en.name;
        } else {
          //fallback
          this.facility_name = this.facility.name;
        }
      } else {
        this.facility_name = fac_name.name;
      }
      if (this.selected_localized_category == null) {
        this.questions = await this.$lionheart
          .post("/facility-question/list", {
            facility_id: this.facility.id,
            language: this.form_language,
          })
          .then((r) => r.data.data);
      } else {
        this.selectFilter(this.selected_localized_category, true);
      }

      return true;
    },
    async loadVisitor() {
      const visitor_id = this.visitor.visitor_id;
      const ve = ["created_at", "updated_at", "id", "user_id", "user"];
      const ue = [
        "created_at",
        "email_verified_at",
        "id",
        "updated_at",
        "name",
      ];
      let visitor = await this.$lionheart
        .post("/visitor/show", { id: visitor_id })
        .then((r) => r.data.data);
      let user = visitor.user;
      ve.map((obj) => delete visitor[obj]);
      ue.map((obj) => delete user[obj]);
      this.visitor.visitor_id = visitor_id;
      this.user = user;
    },
    async validateEmail() {
      const data = {
        email: this.user.email,
        id: this.visitor.visitor_id,
        type: "visitor",
        module: "checkin",
      };
      const email = await this.$lionheart
        .post("/user/exists", data)
        .then((r) => this.catcher(r.data, "data.exist", (r) => r.data))
        .catch((e) => this.catcher(e));
      if (email.error.has_error) return this.errorDisplay(email);
      this.email_exists = email.exist;
      this.$root.$emit('email_exists', email.exist); //Check email component
    },
    async submitForm(e) {

      e.preventDefault();
      if (this.booking_details.total <= 1) {
        // Passport image validation
        if (
          this.questions.find(
            (el) => el.system_name == "gov_id" && el.required == 1
          )
        ) {
          this.gov_id_companion.exist = true;
          this.gov_id_exist = true;
          if (!this.hasPassportImage(this.visitor_forms)) {
            this.gov_id_exist = false;
            this.submit_btn = "";
            return false;
          }

          this.companions.forEach((value, key) => {
            if (
              !this.hasPassportImage(value.other_forms)
            ) {
              this.gov_id_companion = {
                exist: false,
                key: key,
              };
              this.submit_btn = "";
            }
          });

          if (!this.gov_id_companion.exist) {
            this.submit_btn = "";
            return false;
          }
        }

        // Vaccine card validation
        if (
          this.questions.find(
            (el) => el.system_name == "vaccine_card" && el.required == 1
          )
        ) {
          this.vaccine_card_companion.exist = true;
          this.vaccine_card_exist = true;
          if (!this.hasVaccineCard(this.visitor_forms)) {
            this.vaccine_card_exist = false;
            this.submit_btn = "";
            return false;
          }

          this.companions.forEach((value, key) => {
            if (
              !this.hasVaccineCard(value.other_forms)
            ) {
              this.vaccine_card_companion = {
                exist: false,
                key: key,
              };
              this.submit_btn = "";
            }
          });

          if (!this.vaccine_card_companion.exist) {
            this.submit_btn = "";
            return false;
          }
        }

        // Signature validation
        const sigElem = this.questions.find((el) => el.system_name == "signature");
        if (sigElem && sigElem.required == 1) {
          this.signature_exist = true;
          if (!this.hasSignature(this.visitor_forms)) {
            this.signature_exist = false;
            this.submit_btn = "";
            return false;
          }
        }
      }

      if (this.booking_details.total > 1) {
        this.multiple_data.forEach(item => {
          if (!item.filled_in_by_other) {
            // Passport image validation
            if (
              this.questions.find(
                el => el.system_name == "gov_id" && el.required == 1
              )
            ) {
              this.gov_id_companion.exist = true;
              this.gov_id_exist = true;
              if (!this.hasPassportImage(item.visitor_forms)) {
                this.gov_id_exist = false;
                this.submit_btn = "";
                return false;
              }

              item.companions.forEach((value, key) => {
                if (
                  !this.hasPassportImage(value.other_forms)
                ) {
                  this.gov_id_companion = {
                    exist: false,
                    key: key,
                  };
                  this.submit_btn = "";
                }
              });

              if (!this.gov_id_companion.exist) {
                this.submit_btn = "";
                return false;
              }
            }

            // Vaccine card validation
            if (
              this.questions.find(
                el => el.system_name == "vaccine_card" && el.required == 1
              )
            ) {
              this.vaccine_card_companion.exist = true;
              this.vaccine_card_exist = true;
              if (!this.hasVaccineCard(item.visitor_forms)) {
                this.vaccine_card_exist = false;
                this.submit_btn = "";
                return false;
              }

              item.companions.forEach((value, key) => {
                if (
                  !this.hasVaccineCard(value.other_forms)
                ) {
                  this.vaccine_card_companion = {
                    exist: false,
                    key: key,
                  };
                  this.submit_btn = "";
                }
              });

              if (!this.vaccine_card_companion.exist) {
                this.submit_btn = "";
                return false;
              }
            }

            // Signature validation
            if (this.questions.find((el) => el.system_name == "signature" && el.required == 1)) {
              this.signature_exist = true;
              if (!this.hasSignature(item.visitor_forms)) {
                this.signature_exist = false;
                this.submit_btn = "";
                return false;
              }
            }
          }
        });
      }

      if (this.submit_btn == "confirm" && this.email_exists == false) {

        // Stripe payment component
        const status = await this.$refs.gatewayPayment.doCall().createPaymentMethod();
        if (!status) {
          return status;
        }
        this.confirm = true;
        this.error_msg = "";
        setTimeout(() => window.scrollTo(0, 10), 300);

        // To show confirmation alert
        if (this.booking_details.total > 1) {
          // To check if all forms are filled up
          const all_filled_up = this.multiple_data.every(function (data) {
            return data.filled_in_by_other == false
          })

          if (this.selected_option == 'separately' && !all_filled_up) {
            this.$bvModal.show('modal-confirmation-separately');
          }
        }
      }

      if (this.submit_btn == "edit") {
        // Stripe payment component
        this.confirm = false;
        setTimeout(() => this.$refs.gatewayPayment.doCall().reInit(), 300);
        window.scrollTo(0, 10);
      }

      if (this.submit_btn == "submit") {

        this.noQuestions = true;

        const visitor = this.visitor;
        const user = this.user;

        let visitor_forms = _.cloneDeep(this.visitor_forms);
        let companions = _.cloneDeep(this.companions);

        // check if setting fix_guest_count is enabled

        if(await this.guestCountSetting?.fix_guest_count?.status === false) {
          if (this.checkin_form_type === 'reservation' && this.memorizedInputs) {
            visitor_forms = { ...visitor_forms, ...this.memorizedInputs }
          } else if (this.checkin_form_type === 'others' && this.memorizedWalkInInputs) {
            visitor_forms = { ...visitor_forms, ...this.memorizedWalkInInputs }
          } 
        }

        const dobObj = this.questions.find((obj) => {
          return obj.system_name == "dob";
        });

        const govidObj = this.questions.find((obj) => {
          return obj.system_name == "gov_id";
        });

        const vaccineCardObj = this.questions.find((obj) => {
          return obj.system_name == "vaccine_card";
        });

        let booking_details = this.booking_details.data;

        if (dobObj) {
          visitor_forms[dobObj.id] = moment(
            this.formatDate(visitor_forms[dobObj.id])
          ).format("YYYY-MM-DD");
        }

        if (govidObj) {
          if (visitor_forms[govidObj.id]) {
            visitor_forms[govidObj.id] = visitor_forms[govidObj.id].dataUrl;
          }
        }

        if (vaccineCardObj) {
          if (visitor_forms[vaccineCardObj.id]) {
            visitor_forms[vaccineCardObj.id] = visitor_forms[vaccineCardObj.id].dataUrl;
          }
        }

        companions = companions.map((obj) => {
          Object.entries(obj.other_forms).map((oc) =>
            oc[1] == null || undefined ? delete obj.other_forms[oc[0]] : ""
          );
          obj.other_forms = Object.entries(obj.other_forms).reduce(
            (a, [k, v]) => (v == "" ? a : ((a[k] = v), a)),
            {}
          );

          if (dobObj) {
            obj.other_forms[dobObj.id] = moment(
              this.formatDate(obj.other_forms[dobObj.id])
            ).format("YYYY-MM-DD");
          }

          if (govidObj) {
            if (obj.other_forms[govidObj.id]) {
              obj.other_forms[govidObj.id] =
                obj.other_forms[govidObj.id].dataUrl;
            }
          }

          if (vaccineCardObj) {
            if (obj.other_forms[vaccineCardObj.id]) {
              obj.other_forms[vaccineCardObj.id] =
                obj.other_forms[vaccineCardObj.id].dataUrl;
            }
          }

          return obj;
        });



        visitor_forms = Object.entries(visitor_forms).reduce(
          (a, [k, v]) => (v == "" ? a : ((a[k] = v), a)),
          {}
        );
      
        // gateway payment component
        const payment = await this.$refs.gatewayPayment.doCall().makePayment(true);
        if (!payment) return;

        let data = [];
        let shareableLink = false;
        if (this.booking_details.total > 1) {
          this.multiple_data.forEach((item, index) => {
            if (item.filled_in_by_other == false) {
              const booking = [];
              booking.push(booking_details[index]);

              if (govidObj) {
                if (govidObj.id in item.visitor_forms) {
                  item.visitor_forms[govidObj.id] = item.visitor_forms[govidObj.id].dataUrl;
                }
                item.companions.forEach((item, key) => {
                  if (govidObj.id in item.other_forms) {
                    item.other_forms[govidObj.id] = item.other_forms[govidObj.id].dataUrl;
                  }
                });
              }

              if (vaccineCardObj) {
                if (vaccineCardObj.id in item.visitor_forms) {
                  item.visitor_forms[vaccineCardObj.id] = item.visitor_forms[vaccineCardObj.id].dataUrl;
                }
                item.companions.forEach((item, key) => {
                  if (vaccineCardObj.id in item.other_forms) {
                    item.other_forms[vaccineCardObj.id] = item.other_forms[vaccineCardObj.id].dataUrl;
                  }
                });
              }

              data.push({
                facility_id: this.facility.id,
                timezone: moment.tz.guess(),
                companions: item.companions,
                ...visitor,
                ...user,
                email: item.email,
                other_form: item.visitor_forms,
                booking_details: booking,
                td: this.td,
              });
            }
          });

          // To check if all forms are filled up
          const all_filled_up = this.multiple_data.every(function (data) {
            return data.filled_in_by_other == false
          })

          if (this.selected_option == 'separately' && !all_filled_up) shareableLink = true;
        } else {
          // Normal checkin

          //check if there is memoized email

          if(this.memorizedEmail) {
            user['email'] = this.memorizedEmail;
          } 

          data = [
            {
              facility_id: this.facility.id,
              timezone: moment.tz.guess(),
              companions,
              ...visitor,
              ...user,
              other_form: visitor_forms,
              booking_details,
              td: this.td,
            },
          ];
        }

        // API call to save the checkin details
        const checkin = await this.$lionheart
          .post("/checkin", data, this.$bvModal.show('processing-modal'))
          .then((r) => {
            
            if (this.checkin_form_type === 'reservation') {
              this.clearLocalStorage()
            } else if (this.checkin_form_type === 'others') {
              this.clearWalkInLocalStorage();
            } else if (this.checkin_form_type === 'email') {
              this.clearLocalStorage()
              this.clearWalkInLocalStorage();
            }

            return this.catcher(r.data, "status", (r) => r.status);
          })
          .catch((e) => this.catcher(e));

        if (checkin.error.has_error) {
          this.$bvModal.hide('processing-modal')
          return this.errorDisplay(checkin);
        }
        this.error_msg = "";
        if (!checkin[0].booking) {
          this.$bvModal.hide('processing-modal')
          setTimeout(
            () => window.scrollTo(0, $("#checkin_msg").position().top - 10),
            300
          );
          this.clearLocalStorage(); // clear the memorization 
          return (this.checkin_msg = true);
        }

        const queryParam = this.td ? { ref: "td" } : {};
        // this will check if the guest if from tablet mode
        if (this.$route.query.tab && this.$route.query.tab == 1) queryParam.tab = 1;

        let queryEmail = '';
        let queryPass = '';

        if (checkin[0].custom === true) {
          queryEmail = this.typy(this.booking_details, 'data[0].parsed.email_address').isDefined ? this.booking_details.data[0].parsed.email_address : data[0].email;
          queryPass = this.typy(this.booking_details, 'data[0].pass').isDefined ? this.booking_details.data[0].pass : checkin[0].bookingDetails.pass;
        }

        const myPage = await this.$lionheart
          .get("/v2/facility-settings", {
            params: {
              name: 'my-page-setting',
              facility_id: this.facility.id,
              paginate: 0
            }
          })
          .then(res => res.data.data);

        let resultOfMyPage = myPage[0]?.value.active ?? false;

        let reservationNumberQuery = this.reservation_no_query ?? null
        if (resultOfMyPage) {
          queryParam.email = queryEmail;
          queryParam.pass = queryPass;
        }

        if (reservationNumberQuery) {
          queryParam.rsv_no = reservationNumberQuery;
          if(t(this.$route.query, 'tCode').isDefined) {
           queryParam.tCode = this.$route.query.tCode;
          }
        }


        // Store localStorage
        this.$bvModal.hide('processing-modal')
        this.$router.push({
          name: "checkin-facility-checkin-success",
          params: {
            slug_name: this.$route.params.slug_name,
            show_shareable_link: shareableLink, // This will pop up shareable link modal
            rsv_no: reservationNumberQuery,
            email: queryEmail,
            pass: queryPass,
          },
          query: queryParam,
        });


      }
    },

    // method to trigger change language also call custom text setting
    async reloadQuestion(lang) {
      this.form_language = lang;
      this.$i18n.locale = lang;
      this.companions = [];
      this.page = 1;
      this.confirm = false;
      await this.loadQuestions();
      this.visitor_forms = {};

      if (this.$refs.ddAge0 && this.$refs.ddAge0[0]) {
        this.loadAgeDropdown(
          this.visitor_forms,
          this.$refs.ddAge0[0].getAttribute("data-key")
        );
      }
      this.loadCustomTextSetting(lang, this.facility.id);

      if (this.$refs.bookingDetailsComponent)
        this.$refs.bookingDetailsComponent.populate();
    },
    onPrev() {
      const found = Object.keys(this.questions).find((item) =>
        sections.includes(item)
      );
      if (!found) return (this.page = 1);
      return (this.page -= 1);
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg;
      setTimeout(
        () => window.scrollTo(0, $("#error_msg").position().top - 10),
        300
      );
      return;
    },
    checkIfTAC() {
      var cond = {
        status: false,
      };

      if (this.questions) {
        this.questions.forEach((value, key) => {
          if (value.system_name == "terms_conditions") {
            cond.status = true;
            cond.id = value.id;
          }
        });
      }

      return cond;
    },
    setImage(data) {
      if (data == "main") {
        this.gov_id_exist = true;
      }
      if (data == "companion") {
        this.gov_id_companion.exist = true;
      }
    },
    //  loadcustom text
    async loadCustomTextSetting(lang, id) {
      let params;
      let response;
      this.custom_text_setting.title = '';
      this.custom_text_setting.header = '';

      if (this.is_custom && this.td == false) {
        params = {
          name: `message-precheckin-form-${lang}`,
          fallback: "message-precheckin-form-en",
        };
      }
      else {
        params = {
          name: `message-checkin-form-${lang}`,
          fallback: "message-checkin-form-en",
        };
      }

      response = await this.$lionheart.get("/custom-messages/" + id, {
        params: params,
      }).then(r => r.data.data);

      this.custom_text_setting = response.value ?? this.custom_text_setting;  //default value

      // Trigger if next page if is_localized = true
      if (this.next === 'localized-filters') {
        this.custom_text_setting.header = ''; //set header to null if localized select
        params = {
          name: `message-checkin-filter-form-${lang}`,
          fallback: "message-checkin-filter-form-en",
        };

        response = await this.$lionheart.get("/custom-messages/" + id, {
          params: params,
        }).then(r => r.data.data);

        this.custom_text_setting.header = response.value.header ?? '';
      }
    },
    async populate(obj) {
      obj.forms.forEach(
        (obj) => this.$set(this.visitor_forms, obj.id, obj.answer),
        this
      );
      this.user.email = obj.email;
      await this.validateEmail();
    },
    back() {
      return location.reload();
    },

    //  check if the facility is capable of filtering local or international
    async checkLocalizedFilter() {
      const response = await this.$lionheart.post("/facility-setting/list", {
        facility_id: this.facility.id,
        name: "localized-filter",
        paginate: false,
      });

      if (response.data.total != 0) {
        if (response.data.data[0].value.visibility == true) {
          const lf = await this.$lionheart.get(
            "localized-filters?facility_id=" + this.facility.id // "localized-filters?facility_id=" + this.facility.id cors issue in apache
          );
          if (lf.data.data.length > 0) {
            this.is_localized = true;
            this.localized_filters = lf.data.data;
          }
        }
      }
      // Load the initial display
      this.next =
        this.is_custom == true
          ? "pre-checkin-form"
          : this.is_localized == true
            ? "localized-filters"
            : "checkin-form";
    },

    //  function to get all questions based on facilityId
    async selectFilter(category, from) {
      this.selected_localized_category = category;
      const response = await this.$lionheart.post("/facility-question/list", {
        facility_id: this.facility.id,
        language: this.form_language,
        facilities_localized_category_id: category.id,
      });
      if (response.data.total != 0) {
        this.questions = response.data.data;
        if (!from) this.updateMultipleData()
      }
      // trigger when selecting filter tag to get custom text setting
      this.getCustomTextSetting();
    },

    setVisitorImage(images) {
      this.images = images;
    },

    saveImages(obj, moveToTopActivated = false) {
      this.visitor_forms = obj.visitor_forms;
      this.companions = obj.companions;
      if (moveToTopActivated && this.companions.length > 0) this.$refs.moveToTop.scrollIntoView({ behavior: 'smooth' });
    },

    retakeImage(obj) {
      this.images[obj.index].dataUrl = obj.dataUrl;
    },

    hasPassportImage(obj) {
      let question = this.questions.find(el => el.system_name == 'gov_id' && el.required == 1);
      return obj.hasOwnProperty(question.id);
    },

    retakeGovId(image) {
      if (image.index === 0) {
        this.visitor_forms[image.question_id] = {
          dataUrl: image.dataUrl,
        };
      } else {
        // if image.index is 0, it's for tour leader. Thus the minus one in index
        this.companions[image.index - 1].other_forms[image.question_id] = {
          dataUrl: image.dataUrl,
        };
      }
    },

    async updateMultipleData() {
      if (this.booking_details.total > 1) {
        this.next = "select-option";
        for (let i = 0; i < this.booking_details.total; i++) {
          this.multiple_data.push({
            visitor_forms: {},
            companions: [],
            guestCount: 1,
            email: '',
            filled_in_by_other: false,
            show: false,
          })
        }
      } else {
        this.next = "checkin-form";
      }
    },

    async updateMultipleFilledIn() {
      this.multiple_data.forEach((value, key) => {
        if (key != 0) value.filled_in_by_other = true
      });
    },

    async getCurrency() {
      try {
        const { data } = await this.$lionheart.get(
          `facility/${this.facility.id}/currency`
        );
        this.currency = data.value;
      } catch (error) {
        this.currency = "JPY";
        console.error(error.response.data.message);
      }

      this.showPaymentGateway = true;
    },

    hasSignature(obj) {
      let question = this.questions.find(el => el.system_name == 'signature' && el.required == 1);
      return obj.hasOwnProperty(question.id);
    },
    setVisitorVaccineCardImages(images) {
      this.vaccine_card_images = images;
    },
    retakeVisitorVaccineCardImage(obj) {
      this.vaccine_card_images[obj.index].dataUrl = obj.dataUrl;
    },
    hasVaccineCard(obj) {
      let question = this.questions.find(el => el.system_name == 'vaccine_card' && el.required == 1);
      return obj.hasOwnProperty(question.id);
    },
    clearSigError() {
      clearTimeout(this.timer);
      this.signature_exist = true;
      this.signature_written = false;
    },
    getCustomTextSetting() {
      this.loadCustomTextSetting(this.$i18n.locale, this.facility.id);
    },

    addNewCompanionEventBus(clickEvent) {
      this.$refs.defaultComponent[0].addCompanion(clickEvent);
      console.log('main form ', this.images.length);
      this.guestCount = this.images.length;
    },
    removeCompanionEventBus(companionIndex) {
      this.$refs.defaultComponent[0].removeCompanion(companionIndex);
      this.guestCount = this.images.length;
    },

    handleRemoveCompanionUsingGovId(companionIndex) {
      this.$refs.defaultComponent[0].removeCompanion(companionIndex);
      this.images.splice(companionIndex + 1, 1)
      this.guestCount = this.images.length;
    }
  },
  watch: {
    async next(val) {
      if (val) {
        setTimeout(() => {
          if (this.$refs.ddAge0 && this.$refs.ddAge0[0]) {
            this.loadAgeDropdown(
              this.visitor_forms,
              this.$refs.ddAge0[0].getAttribute("data-key")
            );
          }
        }, 100);
      }
    },
    signature_exist(yes) {
      if (!yes) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => { this.signature_exist = true; }, 4000);
      }
    },
    guestCountSetting() {},
    numberOfGuest() {},
  },
};
