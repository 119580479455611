<template>
  <div>
    <div v-if="!multiple && !isFixGuestEnabled" class="form-group row">
      <label class="col-sm-2 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-10 my-auto">
        <select
          v-if="!confirm"
          v-model="inputData[c.id]"
          ref="ddAge0"
          :data-key="c.id"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in ages" :value="n">{{ n }}</option>
        </select>
        <p v-if="confirm" class="mb-0">{{ inputData[c.id] }}</p>
      </div>
      <!-- For data aggregation -->
      <template v-if="companions.length > 0 && !confirm">
        <template v-for="(companion, index) in companions">
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-3"
            >
              <option value="">--</option>
              <option v-for="n in ages" :value="n">{{ n }}</option>
            </select>
          </div>
        </template>
      </template>
    </div>

    <div v-else-if="!multiple && isFixGuestEnabled" class="form-group row">
      <label class="col-sm-2 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>

      <div class="col-sm-10 my-auto">
        <select
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          ref="ddAge0"
          :data-key="c.id"
          :required="c.required ? true : false"
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in ages" :value="n">{{ n }}</option>
        </select>
        <p v-if="confirm" class="mb-0">{{ visitor_forms[c.id] }}</p>
      </div>

        <!-- For data aggregation -->
        <template v-if="fixGuestCount > 1 && !confirm">
        <template v-for="(companion, index) in companions">
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown"
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-3"
            >
              <option value="">--</option>
              <option v-for="n in ages" :value="n">{{ n }}</option>
            </select>
          </div>
        </template>
      </template>
    </div>

    <!-- Multiple Room -->
    <div v-else class="form-group row">
      <label class="col-sm-2 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-10 my-auto">
        <select
          v-if="!confirm"
          v-model="multiple_data[bd_index].visitor_forms[c.id]"
          ref="ddAge0"
          :data-key="c.id"
          :required="
            multiple_data[bd_index].filled_in_by_other
              ? false
              : c.required
              ? true
              : false
          "
          class="form-control"
        >
          <option value="">--</option>
          <option v-for="n in ages" :value="n">{{ n }}</option>
        </select>
        <p v-if="confirm" class="mb-0">
          {{ multiple_data[bd_index].visitor_forms[c.id] }}
        </p>
      </div>
      <!-- For data aggregation -->

      <template
        v-if="multiple_data[bd_index].companions.length > 0 && !confirm"
      >
        <template
          v-for="(companion, index) in multiple_data[bd_index].companions"
        >
          <label class="col-sm-2 col-form-label">
            {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
            <div>
              ({{
                showLabelOrPlaceholder(
                  $t("visit-information-form.companion"),
                  index,
                  "",
                  "dropdown",
                  true,
                  bd_index
                )
              }})
              <small v-if="c.required" class="text-danger">*</small>
            </div>
          </label>
          <div class="col-sm-10">
            <select
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              :required="c.required ? true : false"
              class="form-control mt-3"
            >
              <option value="">--</option>
              <option v-for="n in ages" :value="n">{{ n }}</option>
            </select>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { checkinFormTypeWithMemorization, loadMemorizedData } from "../../../../utils/helpers";
import { main_form_methods } from "../../../../utils/mockup";
import { mapState, mapActions } from "vuex";
import guestWatcherMixin from "../../../../../../mixins/guestWatcherMixin";

const { showLabelOrPlaceholder, populateCompanionsFields, isFixGuestEnabled } = main_form_methods;
export default {
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    companions: {
      type: Array,
      default: [],
    },
    different: {
      type: Array,
      default: [],
    },
    questions: {
      type: Array,
      default: [],
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room

    // checkin form type
    checkin_form_type: "",
    reservation_no: null,
    fixGuestCount: {
      type: Number,
      default: null
    },
    fixGuestSetting: {
      type: Object,
      default: {}
    }
  },
  mixins: [guestWatcherMixin],
  data() {
    return {
      ages: [...Array(121).keys()],
      inputData: {},
      formType: ""
    };
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
    isFixGuestEnabled,
  },
  updated() {
    this.handleFormType();
  },
  async mounted() {
    await this.initializeForm();
  },
  methods: {
    ...mapActions("memorizedInputs", [
              "saveInput", 
              "saveWalkInInputs",
              "saveReservationNumber",
              "checkIfCheckinFormType"
    ]),
    showLabelOrPlaceholder,
    populateCompanionsFields,
    handleFormType() {
      this.checkin_form_type === "others"
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },

    async initializeForm() {

    const rid = this.$route.query.rid
    checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);

    this.inputData = await loadMemorizedData(
      this,
      this.memorizedInputs,
      this.memorizedWalkInInputs,
      this.checkin_form_type,
      this.visitor_forms
    );

    this.populateCompanionsFields();
  },

  },
};
</script>