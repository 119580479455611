<template>
	<div id="dashboard_wrapper">
		<div class="card-body">
			<h4 class="card-title">{{ $t("account-page-facility-my-page-category.title") }}</h4>
			<p>{{ $t("account-page-facility-my-page-category.description") }}​</p>
			<button @click="openSample" :disabled="!facility_category.value.status" type="button"
				class="btn btn-outline-info btn-sm">
				{{ $t("account-page-facility-my-page-category.sample") }}
			</button>
			<div class="alert alert-success" role="alert" v-if="loading">
				{{ $t("account-page-facility-my-page-category.success") }}
			</div>
			<hr />
			<div class="row">
				<div class="col-lg-12">
					<div class="d-flex flex-row">
						<div>{{ $t("account-page-facility-my-page-category.switch") }}</div>
						<div class="pl-2">
							<toggle-button v-model="facility_category.value.status"
								:labels="{ checked: 'On', unchecked: 'Off' }" :value="facility_category.value.status"
								:sync="true" />
						</div>
					</div>

					<p class="small">
						{{ $t("account-page-facility-my-page-category.switch-description") }}
					</p>
				</div>
			</div>
			<hr />
			<form @submit.prevent="$bvModal.show('modal-confirmation')">
				<div class="row">
					<div class="col-lg-12">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" v-for="(value, key) in languages">
								<a class="nav-link" :class="{ active: value.code == config.active_language }"
									:href="`#tab-${value.code}`" @click="init_language(value.code)">{{
										$t(`account-page-facility-language-option.${value.name}`)
									}}</a>
							</li>
						</ul>
					</div>
					<div class="col-lg-12 my-2 text-right">
						<button class="btn-sm btn-primary btn" type="button" @click="add_new_category">{{
							$t("account-page-facility-my-page-category.add-new") }}</button>
						<button class="btn-sm btn ml-1"
							:class="{ 'btn-primary': !config.show_all, 'btn-secondary': config.show_all }" type="button"
							@click="show_all()">
							{{ config.show_all ? $t("account-page-facility-my-page-category.hide-all") :
								$t("account-page-facility-my-page-category.show-all") }}
						</button>
					</div>

					<div class="col-lg-12 text-center px-0" v-if="error.upload">
						<div class="alert alert-danger">{{ $t("account-page-facility-my-page-category.picture-max-size") }}
						</div>
					</div>
					<div class="col-lg-12">
						<div class="row item border" :data-order="(value.order = key + 1)"
							v-for="(value, key) in  groupBy(config.active_language) ">
							<div class="col-lg-12">
								<div class="row align-items-center h-100">
									<div class="col-sm-1 text-center">
										<div>
											<button class="p-0 border-0 handle" type="button" @click="up(key)"
												:disabled="key == 0">
												<i class="fas fa-caret-up fa-2x"></i>
											</button>
											<br />
											<button class="p-0 border-0 handle" type="button" @click="down(key)"
												:disabled="key + 1 == groupBy(config.active_language).length">
												<i class="fas fa-caret-down fa-2x"> </i>
											</button>
										</div>
									</div>
									<div class="col-sm-1 text-center align-middle">
										<toggle-button class="my-0" v-model="value.status"
											:labels="{ checked: 'On', unchecked: 'Off' }" :value="value.status"
											:sync="true" />
									</div>
									<div class="col-sm-2 text-center align-middle">
										<label class="my-0 w-100">
											<img style="cursor: pointer" v-if="value.metadata.icon_url"
												:src="value.metadata.icon_url" width="50" class="img-fluid" />
											<a v-else class="btn btn-sm btn-light border btn-block"> {{
												$t("account-page-facility-my-page-category.icon-upload") }}</a>
											<input :disabled="value.metadata.icon_fix" style="display: none" type="file"
												@change="cropLoad($event, { data: value, name: 'icon_name', url: 'icon_url' })"
												accept="image/png, image/gif, image/jpeg" />
										</label>
									</div>
									<div class="align-middle text-center col-sm-2">{{
										$t("account-page-facility-my-page-category.category-name") }}</div>
									<div class="align-middle col-sm-2 py-1 px-0"><input required type="text"
											class="form-control" v-model="value.label" /></div>
									<div class="col-sm-1 text-center">
										<a :ref="`collapse-${key}`" class="parent" aria-expanded="false"
											@click="check_toggle_status(`collapse-${key}`)" data-toggle="collapse"
											:href="`#collapse-${key}`">
											<i class="fa fa-caret-right fa-2x"></i>
										</a>
									</div>
									<div class="col-sm-2 text-center">
										<label class="my-0 w-100" v-if="!value.metadata.banner_url">
											<a class="btn btn-sm btn-light border btn-block"> {{
												$t("account-page-facility-my-page-category.pic-upload") }}</a>
											<input style="display: none" type="file"
												@change="cropLoad($event, { data: value, name: 'banner_name', url: 'banner_url' })"
												accept="image/png, image/gif, image/jpeg" />
										</label>
										<a @click="$bvModal.show('banner-view'), (selected_category = value)"
											class="btn btn-sm btn-light border btn-block" v-else>
											{{ $t("account-page-facility-my-page-category.view-pic") }}</a>
									</div>
									<div class="col-sm-1 text-center">
										<a v-if="!value.metadata.fix"
											@click="$bvModal.show('delete-modal'), (config.deletable_key = key)"
											type="button" class="text-danger"><i class="fa fa-minus-circle"></i></a>
									</div>
								</div>
							</div>

							<div class="col-lg-12 collapse multi-collapse" :id="`collapse-${key}`">
								<div class="row">
									<div class="col-sm-1 text-center"></div>
									<div class="col-sm-1 text-center align-middle"></div>
									<div class="col-sm-2 text-center align-middle"></div>
									<div class="align-middle text-center col-sm-2">{{
										$t("account-page-facility-my-page-category.content") }}</div>
									<div class="align-middle col-sm-2 py-1 px-0">
										<vue-editor v-model="value.content" :editor-toolbar="customToolbar" />
									</div>
									<div class="col-sm-1 text-center"></div>
									<div class="col-sm-2 text-center"></div>
									<div class="col-sm-1 text-center"></div>
								</div>
								<hr>
								<div v-for="(v, k) in sortedArray(value.my_page_facility_info_sub_categories)"
									:class="`item-${key}`">
									<div class="row align-items-center">
										<div class="col-sm-1 text-center"></div>
										<div class="col-sm-1 text-center align-middle"></div>
										<div class="col-sm-2 text-center align-middle">
											<div class="col-sm-1 text-center">
												<div>
													<button class="p-0 border-0 handle" type="button"
														@click="up_parent(key, k)" :disabled="k == 0">
														<i class="fas fa-caret-up fa-2x"></i>
													</button>
													<br />
													<button class="p-0 border-0 handle" type="button"
														@click="down_parent(key, k)"
														:disabled="k + 1 == sortedArray(value.my_page_facility_info_sub_categories).length">
														<i class="fas fa-caret-down fa-2x"> </i>
													</button>
												</div>
											</div>
										</div>
										<div class="align-middle text-center col-sm-2">{{
											$t("account-page-facility-my-page-category.sub-category") }}</div>
										<div class="align-middle col-sm-2 py-1 px-0"><input required type="text"
												class="form-control" v-model="v.label" /></div>
										<div class="col-sm-1 text-center">
											<a :ref="`collapse-${key}-${k}`"
												@click="check_toggle_status(`collapse-${key}-${k}`)" data-toggle="collapse"
												:href="`#collapse-${key}-${k}`"><i class="fa fa-caret-right fa-2x"></i></a>
										</div>
										<div class="col-sm-2 text-center">
											<label class="my-0 w-100" v-if="!v.metadata.banner_url">
												<a class="btn btn-sm btn-light border btn-block"> {{
													$t("account-page-facility-my-page-category.pic-upload") }}</a>
												<input style="display: none" type="file"
													@change="cropLoad($event, { data: v, name: 'banner_name', url: 'banner_url' })"
													accept="image/png, image/gif, image/jpeg" />
											</label>
											<a @click="$bvModal.show('banner-view'), (selected_category = v)"
												class="btn btn-sm btn-light border btn-block" v-else>{{
													$t("account-page-facility-my-page-category.view-pic")
												}}</a>
										</div>
										<div class="col-sm-1 text-center">
											<a @click="remove_category(value.my_page_facility_info_sub_categories, k)"
												type="button" class="text-danger"><i class="fa fa-minus-circle"></i></a>
										</div>
									</div>
									<div class="row align-items-center collapse multi-collapse"
										:id="`collapse-${key}-${k}`">
										<div class="col-sm-1 text-center"></div>
										<div class="col-sm-1 text-center align-middle"></div>
										<div class="col-sm-2 text-center align-middle"></div>
										<div class="align-middle text-center col-sm-2">{{
											$t("account-page-facility-my-page-category.content") }}</div>
										<div class="align-middle col-sm-2 py-1 px-0">
											<vue-editor v-model="v.content" :editor-toolbar="customToolbar" />
										</div>
										<div class="col-sm-1 text-center"></div>
										<div class="col-sm-2 text-center"></div>
										<div class="col-sm-1 text-center"></div>
									</div>
									<hr class="my-1" />
								</div>
								<div class="row">
									<div class="col-lg-12 text-center py-3">
										<button @click="add_child_category(value.my_page_facility_info_sub_categories)"
											type="button" class="btn btn-info btn-sm">
											+ {{ $t("account-page-facility-my-page-category.add-more") }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-4">
					<div class="col-lg-12" align="center">
						<button @click.prevent="
							$router.push({
								name: 'checkin-facility-dashboard-my-page',
							})
							" class="btn btn-danger ml-3">
							{{ $t("account-page-facility-my-page-message.back") }}
						</button>
						<!-- @click="$bvModal.show('modal-confirmation')" -->
						<button type="submit" class="btn btn-primary ml-3">
							{{ $t("account-page-facility-my-page-message.confirm") }}
						</button>
					</div>
				</div>
			</form>
		</div>
		<confirmation-modal-component @click-handler="submit"></confirmation-modal-component>

		<confirmation-modal-component id="delete-modal"
			@click-handler="remove_category(categories)"></confirmation-modal-component>

		<upload ref="crop_load" @event="cropLoad" />

		<view-banner :src="selected_category.metadata.banner_url"
			@event="remove_banner($event, 'banner_url', 'banner_name')" />
	</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ConfirmationModalComponent from "../../components/confirmation-modal.component";
import Upload from "./upload";
import ViewBanner from "./banner";
export default {
	components: { ConfirmationModalComponent, Upload, ViewBanner, VueEditor },
	name: "form",
	data() {
		return {
			categories: [],
			loading: false,
			deleted_categories: [],
			language_filter: {
				paginate: 0,
				name: "language",
				facility_id: window._app_facility.id,
			},
			facility: window._app_facility,
			facility_category: {
				name: "my-page-message-facility-info-category",
				value: {
					status: false,
				},
				facility_id: window._app_facility.id,
			},
			config: {
				active_language: null,
				deletable_key: null,
				upload: "pic_upload",
				show_all: false,
				sample_url: `${process.env.VUE_APP_MY_PAGE_BASE_URL}/${window._app_facility.slug_name}/my-page/guest/categories?sample=true`,
			},
			languages: [
				{ code: "en", name: "en" },
				{ code: "ja", name: "ja" },
			],
			error: {
				facility_category: false,
				upload: false,
			},
			filter: {
				page: 0,
				per_page: 0,
				sort_by: "order",
				sort_order: "asc",
				facility_id: window._app_facility.id,
				with_nested_category: 1,
			},
			default_category: {
				order: 100,
				content: null,
				label: null,
				metadata: {},
				status: false,
				parent_id: null,
				facility_id: window._app_facility.id,
			},
			default_metadata: {
				icon_fix: false,
				icon_url: null,
				icon_name: null,
				banner_url: null,
				banner_name: null,
				fix: false,
			},
			selected_category: {
				metadata: {},
			},
			customToolbar: [
				["bold", "italic", "underline", "link"],
				[{ list: "ordered" }, { list: "bullet" }],
			],
		};
	},
	async mounted() {
		await this.init_language(this.$i18n.locale);
		await this.show();
		await this.init_languages(this.language_filter);
	},
	methods: {
		show_all() {
			this.categories.forEach((value, key) => {
				if (this.$refs[`collapse-${key}`]) {
					if (this.config.show_all == JSON.parse(this.$refs[`collapse-${key}`][0].ariaExpanded)) {
						this.$refs[`collapse-${key}`][0].click();
					}
				}
			});
			this.config.show_all = !this.config.show_all;
		},
		check_toggle_status(key) {
			let status = this.$refs[key][0].getAttribute("aria-expanded");

			let cL = ["fa-caret-right", "fa-caret-down"];
			if (status == "true") {
				cL = ["fa-caret-down", "fa-caret-right"];
			}
			this.$refs[key][0].children[0].classList.remove(cL[0]);
			this.$refs[key][0].children[0].classList.add(cL[1]);
		},
		down(key) {
			this.groupBy(this.config.active_language)[key].order = key + 1;
			this.groupBy(this.config.active_language)[key + 1].order = key;
		},
		up(key) {
			let from = this.groupBy(this.config.active_language)[key];
			let next = this.groupBy(this.config.active_language)[key - 1];
			this.categories[this.categories.findIndex((el) => el.label == from.label)].order = key;
			this.categories[this.categories.findIndex((el) => el.label == next.label)].order = key + 1;
		},
		down_parent(key, child) {
			let nextKey = child + 1;
			let oldValue = this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child];
			let newValue = this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey];

			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child].order = nextKey;
			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey].order = child;


			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child] = newValue;
			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey] = oldValue;

			console.log(this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories);

		},
		up_parent(key, child) {
			let nextKey = child - 1;
			let oldValue = this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child];
			let newValue = this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey];

			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child].order = nextKey;
			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey].order = child;


			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[child] = newValue;
			this.groupBy(this.config.active_language)[key].my_page_facility_info_sub_categories[nextKey] = oldValue;
		},

		groupBy(lang) {
			let data = [];
			if (_.groupBy(this.categories, "language")[lang]) {
				data = _.orderBy(_.groupBy(this.categories, "language")[lang], "order", "asc");
			}

			return data;
		},
		async submit() {
			await this.$lionheart.post("/v2/facility-settings", { ...this.facility_category });

			let input = {
				facility_id: this.facility.id,
				slug: this.facility.slug_name,
				data: [...this.categories, ...this.deleted_categories],
			};
			const response = await this.$lionheart.post("/v2/my-page/facility-information/categories/create-update", input);
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 3000);

			await this.show();
		},
		add_child_category(data) {
			this.default_category.order = data.length;
			let input = {
				...this.default_category,
				language: this.config.active_language,
				metadata: {
					...this.default_metadata,
				},
			};
			data.push(input);
		},
		add_new_category() {
			let input = {
				...this.default_category,
				language: this.config.active_language,
				metadata: {
					...this.default_metadata,
				},
			};

			input.my_page_facility_info_sub_categories = [];
			this.categories.push(input);
		},
		remove_category(data, index = null) {
			let findIndex;
			if (index == null) {
				let group = JSON.stringify(this.groupBy(this.config.active_language)[this.config.deletable_key]);
				findIndex = data.findIndex((el) => JSON.stringify(el) == group);
			} else {
				findIndex = index;
			}
			if (data[findIndex].id) {
				let input = { ...data[findIndex], deleted: true };
				this.deleted_categories.push(input);
			}
			this.$delete(data, findIndex);
		},
		remove_banner(url = null, name = null) {
			this.selected_category.metadata[url] = null;
			this.selected_category.metadata[name] = null;
		},
		async init_languages(query) {
			const response = await this.$lionheart.get("/v2/facility-settings", { params: query });

			if (response.data.data && response.data.data[0]) {
				this.languages = response.data.data[0].value;
			}
		},
		async init_language(key) {
			this.config.active_language = key;
		},
		async show() {
			let result = await this.$lionheart
				.get("/v2/facility-settings", {
					params: {
						facility_id: this.facility.id,
						name: "my-page-message-facility-info-category",
						paginate: 0,
					},
				})
				.then((r) => r.data.data);
			if (result.length > 0) {
				this.facility_category = result[0];
			}

			this.categories = await this.$lionheart
				.get("/v2/my-page/facility-information/categories", {
					params: this.filter,
				})
				.then((r) => r.data.data);
			this.deleted_categories = [];
		},

		async cropLoad($event, metadata) {
			if ($event.is_cropped) {
				// metadata = $event.metadata;

				metadata.data.metadata[metadata.url] = $event.value;
				return;
			}

			let file = $event.target.files[0];
			if (file.size > 2000000) {
				this.error.upload = true;
				setTimeout(() => {
					this.error.upload = false;
				}, 5000);
				$event.target.value = null;
				return;
			}

			this.$refs.crop_load.init_upload(await this.toBase64(file), metadata);
			metadata.data.metadata[metadata.name] = file.name;
			$event.target.value = null;
		},
		sortedArray(data) {
			// Sort the array based on the 'order' key
			return data.slice().sort((a, b) => a.order - b.order);
		},
		openSample() {
			window.open(this.config.sample_url, "_blank").focus();
		},
	},
};
</script>

<style scoped>
a.parent[aria-expanded="true"] .fa-chevron-right {
	display: none;
}

a[aria-expanded="false"] .fa-chevron-down {
	display: none;
}

.handle {
	color: #858796 !important;
	background: transparent;
}

.handle:hover {
	color: #1cc88a !important;
}

.handle:disabled {
	color: #c4c4cb !important;
}
</style>
