<template>
  <div
    class="modal fade"
    id="finishPreCheckin"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-warning">
            {{ $t("touch-down.enter-pass") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- Form -->
        <form @submit.prevent="submit()">
          <!-- modal-body -->
          <div class="modal-body">
            <small class="form-text">
              {{ $t("touch-down.finished-pre-checkin-note") }}
            </small>

            <!-- Email input field -->
            <div class="form-group">
              <label>
                {{ $t("touch-down.email") }}
              </label>
              <input
                v-model="form.email"
                type="email"
                class="form-control border-top-0 border-left-0 border-right-0"
                placeholder="abcd@xxxxxxx.com"
                required
              />
            </div>

            <!-- Pass input field -->
            <div class="form-group">
              <label>
                {{ $t("touch-down.pass") }}
              </label>
              <input
                v-model="form.pass"
                type="password"
                class="form-control border-top-0 border-left-0 border-right-0"
                :placeholder="$t('touch-down.enter-pass')"
                required
              />
            </div>

            <div
              class="alert alert-danger"
              role="alert"
              v-for="(i, k) in invalid_code_error"
              :key="k"
            >
              {{ $t("touch-down.error_message") }}
            </div>
          </div>
          <!-- /.modal-body -->

          <!-- modal-footer -->
          <div class="modal-footer d-flex justify-content-center">
            <!-- Submit button -->
            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("touch-down.confirm") }}
            </button>
          </div>
          <!-- modal-footer -->
        </form>
        <!-- /.Form -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishPreCheckin",

  props: {
    facility: Object,
  },

  data() {
    return {
      form: {
        pass: "",
        email: "",
        mode: "pre-checkin",
      },
      invalid_code_error: [],
    };
  },
  mounted() {
    // Auto confirm
    if (this.$route.query.e && this.$route.query.k) {
      this.form.email = this.$route.query.e;
      this.form.pass = this.$route.query.k;
      this.submit();
    }
  },
  methods: {
    submit() {
      this.form.facility_id = this.facility.id;

      this.$lionheart
        .post("/v2/facility-booking/list", this.form)
        .then((response) => {
          const data = response.data;
          if (data.data.length > 0) {
            this.$emit("load-booking-details", data);
          } else {
            this.appendError();
          }
          this.form.pass = "";
          this.form.email = "";
        })
        .catch((error) => console.log(error));
    },
    appendError() {
      this.$emit("is-loading", false);
      this.invalid_code_error.push(
        "We could not find a reservation with this email and pass. Please try again"
      );
      setTimeout(() => {
        this.invalid_code_error.shift();
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: center;
  width: 100%;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 14px;
  color: #4d4d4d;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #4d4d4d;
}

$theme-colors: (
  "warning": #1a5dab,
  "primary": #1aa8de,
);
@import "~bootstrap/scss/bootstrap";
</style>