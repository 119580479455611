<template>
  <div id="dashboard_wrapper">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t("account-page-notif-control.schedule_pre_checkin_notice.title") }}
      </h4>
      <br />
      <div class="alert alert-success" role="alert" v-if="loading">
        {{
          $t(
            "account-page-notif-control.schedule_pre_checkin_notice.success_msg"
          )
        }}
      </div>
      <div class="d-flex flex-row">
        <div>
          {{
            $t(
              "account-page-notif-control.schedule_pre_checkin_notice.switch_btn_label"
            )
          }}
        </div>
        <div class="pl-2">
          <toggle-button
            v-model="data.enable"
            :labels="{ checked: 'On', unchecked: 'Off' }"
            :value="data.enable"
            :sync="true"
          />
        </div>
      </div>
      <br />
      <p>
        {{
          $t(
            "account-page-notif-control.schedule_pre_checkin_notice.first_paragraph"
          )
        }}
      </p>
      <hr />
      <p>
        {{
          $t(
            "account-page-notif-control.schedule_pre_checkin_notice.second_paragraph"
          )
        }}
      </p>
      <br />
      <br />
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group row">
            <label
              for="staticEmail"
              class="col-lg-3 col-sm-12 col-md-12 col-form-label"
            >
              <h5>
                {{
                  $t(
                    "account-page-notif-control.schedule_pre_checkin_notice.start_date_input_label"
                  )
                }}:
              </h5>
            </label>
            <div class="col-lg-9 col-sm-12 col-md-12">
              <select class="form-control" v-model="data.date">
                <option
                  v-for="(date, key) in settings.dates"
                  :value="date"
                  :key="key"
                >
                  {{
                    $t(
                      `account-page-notif-control.schedule_pre_checkin_notice.${date}`
                    )
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group row">
            <label
              for="staticEmail"
              class="col-lg-3 col-sm-12 col-md-12 col-form-label"
            >
              <h5>
                {{
                  $t(
                    "account-page-notif-control.schedule_pre_checkin_notice.start_time_input_label"
                  )
                }}:
              </h5>
            </label>
            <div class="col-lg-9 col-sm-12 col-md-12">
              <select class="form-control" v-model="data.time">
                <option v-for="(time, key) in settings.times" :key="key" :value="time">
                  {{ time }} {{data.timezone}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="row">
        <h5>
          {{
            $t(
              "account-page-notif-control.schedule_pre_checkin_notice.note_label"
            )
          }}:
        </h5>
        <ul>
          <li>
            {{
              $t(
                "account-page-notif-control.schedule_pre_checkin_notice.firt_note"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "account-page-notif-control.schedule_pre_checkin_notice.second_note"
              )
            }}
          </li>
        </ul>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12" align="center">
          <button
            @click.prevent="
              $router.push({
                name: 'checkin-facility-dashboard-notif-control',
              })
            "
            class="btn btn-danger ml-3"
          >
            {{
              $t(
                "account-page-notif-control.schedule_pre_checkin_notice.back_btn"
              )
            }}
          </button>
          <button
            @click="$bvModal.show('modal-confirmation')"
            type="button"
            class="btn btn-primary ml-3"
          >
            {{
              $t(
                "account-page-notif-control.schedule_pre_checkin_notice.confirm_btn"
              )
            }}
          </button>
        </div>
      </div>
    </div>
    <confirmation-modal-component
      @click-handler="submit"
    ></confirmation-modal-component>
  </div>
</template>
<script>
import ConfirmationModalComponent from "./../../components/confirmation-modal.component";
export default {
  components: {
    ConfirmationModalComponent,
  },
  data: () => ({
    settings: {},
    data: {},
    loading: false,
  }),
  async created() {
    const response = await this.$lionheart.get(
      "/v2/facility/notification-control/get-date-time-list"
    );
    const data = response.data.data;
    this.settings.dates = data.dates;
    this.settings.times = data.times;
    this.data = data.settings;
  },
  methods: {
    async submit() {
      this.loading = true;
      const response = await this.$lionheart.post(
        "/v2/facility/notification-control/set-pci-guide-email-schedule",
        this.data
      );
      location.href = "#dashboard_wrapper"
      setTimeout(() => {
        this.loading = false
      }, 3000);
    },
  },
};
</script>