<template>
    <div class="pb-4 px-4">
        <div class="container">
            <div class="row">
               <!-- v-if="lf.status == 1" -->
                <div class="col-md my-md-0 my-1" v-for="(lf, index) in filtered" @click="selectFilter(lf)" :key="index">
                    <div class="localized-filter-button">
                        {{ lf.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .localized-filter-button {
        font-family: "Poppins", sans-serif;
        background: #1aa8de;
        color: #fff;
        padding: 20px 0;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        cursor: pointer;
    }
</style>


<script>
export default {
    props: {
        localized_filters: {
            type: Array,
            required: true
        }
    },
    data() {
        return {};
    },
    mounted() {

    },
    computed: {
      filtered() {
         return this.localized_filters.map((item) => {
            if(item.status == 1){
               return item;
            }
         })
      }
    },
    methods: {
        async selectFilter(data) {
            this.$emit('select-filter', data);
        }
    },
};
</script>