<template>
    <div class="styled-form">

        <!-- ======= Header ======= -->
        <header id="header" class="d-flex justify-content-center align-items-center">

          <nav class="nav-menu">
            <ul>
              <li><a href="/" >{{ $t('landing-nav.home') }}</a></li>
            </ul>
          </nav>

        </header>

        <div class="mt-5">
          <div class="wrapper fadeInDown">
            <div class="form-content">
              <div id ="error_msg" v-if="error_msg" class="alert alert-warning" role="alert">{{ error_msg }}</div>
              <!-- Tabs Titles -->

              <!-- Icon -->
              <div class="fadeIn first p-3">
                <img src="../../assets/img/login.png" style="width: 30%" id="icon" alt="User Icon" />
              </div>

              <!-- Login Form -->
              <form @submit="submitForm">
                <input v-model="data.email" required type="email" id="login" class="fadeIn second" name="login" placeholder="login">
                <input v-model="data.password" required type="password" id="password" class="fadeIn third" name="login" placeholder="password">
                <input type="submit" class="fadeIn fourth" value="Log In" />
              </form>

              <!-- Remind Passowrd -->
              <div id="formFooter">
                <router-link link :to="{name: 'checkin-facility-password-reset'}" class="underlineHover" >{{ $t('landing.forgot-password') }}</router-link>
                <br />
                <router-link link :to="{name: 'checkin-facility-email-verify'}" class="underlineHover" >{{ $t('account-registration-form.create-new-account') }}</router-link>
              </div>

            </div>
          </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.nav-menu {
  ul {
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    list-style-type: none;
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>

<script>
export default {
  data() {
    return {
      error_msg: '',
      data: {}
    }
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()
      
      const login = await this.$lionheart.post('/auth/login', this.data)
              .then(r => this.catcher(r.data, 'token', r => r))
              .catch(e => this.catcher(e))

      if (login.error.has_error) return this.errorDisplay(login)
      if (!login.status.success) {
              login.error.msg = login.status.message
              return this.errorDisplay(login)
      }

      this.$storage.set('auth', login)

      return this.$router.push({name: 'checkin-facility-dashboard-home'})
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    }
  }
}
</script>
