export const countryList = [
  { name: "Afghanistan", code: "AF" },
  { name: "land Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

//Master lookup
const lookUpNationalities = [
  { NationalityID: 34, CountryCode: 'AF',    Nationality:      'Afghanistan' },
  { NationalityID: 35, CountryCode: 'AL',    Nationality:      'Albania' },
  { NationalityID: 36, CountryCode: 'DZ',    Nationality:      'Algeria' },
  { NationalityID: 38, CountryCode: 'AD',    Nationality:      'Andorra' },
  { NationalityID: 39, CountryCode: 'AO',    Nationality:      'Angola' },
  { NationalityID: 0, CountryCode: 'AG',    Nationality:       'Antigua and Barbuda' },
  { NationalityID: 2, CountryCode: 'AR',    Nationality:       'Argentina' },
  { NationalityID: 40, CountryCode: 'AM',    Nationality:      'Armenia' },
  { NationalityID: 3, CountryCode: 'AU',    Nationality:       'Australia' },
  { NationalityID: 41, CountryCode: 'AT',    Nationality:      'Austria' },
  { NationalityID: 42, CountryCode: 'AZ',    Nationality:      'Azerbaijan' },
  { NationalityID: 43, CountryCode: 'BH',    Nationality:      'Bahrain' },
  { NationalityID: 44, CountryCode: 'BD',    Nationality:      'Bangladesh' },
  { NationalityID: 45, CountryCode: 'BB',    Nationality:      'Barbados' },
  { NationalityID: 46, CountryCode: 'BY',    Nationality:      'Belarus' },
  { NationalityID: 0, CountryCode: 'BE',    Nationality:       'Belgium' },
  { NationalityID: 47, CountryCode: 'BZ',    Nationality:      'Belize' },
  { NationalityID: 48, CountryCode: 'BJ',    Nationality:      'Benin' },
  { NationalityID: 49, CountryCode: 'BM',    Nationality:      'Bermuda' },
  { NationalityID: 50, CountryCode: 'BT',    Nationality:      'Bhutan' },
  { NationalityID: 51, CountryCode: 'BO',    Nationality:      'Bolivia' },
  { NationalityID: 52, CountryCode: 'BA',    Nationality:      'Bosnia and Herzegovina' },
  { NationalityID: 53, CountryCode: 'BW',    Nationality:      'Botswana' },
  { NationalityID: 0 , CountryCode: 'BR',     Nationality:        'Brazil' },
  { NationalityID: 0, CountryCode: 'BN',    Nationality:       'Brunei' },
  { NationalityID: 54, CountryCode: 'BG',    Nationality:      'Bulgaria' },
  { NationalityID: 55, CountryCode: 'BF',    Nationality:      'Burkina Faso' },
  { NationalityID: 56, CountryCode: 'BI',    Nationality:      'Burundi' },
  { NationalityID: 0, CountryCode: 'CV1',    Nationality:      'Cabo Verde' },
  { NationalityID: 59, CountryCode: 'CV',    Nationality:      'Cabo Verde' },
  { NationalityID: 57, CountryCode: 'KH',    Nationality:      'Cambodia' },
  { NationalityID: 58, CountryCode: 'CM',    Nationality:      'Cameroon' },
  { NationalityID: 7, CountryCode: 'CA',    Nationality:       'Canada' },
  { NationalityID: 0, CountryCode: 'CF',    Nationality:       'Central African Republic' },
  { NationalityID: 60, CountryCode: 'TD',    Nationality:      'Chad' },
  { NationalityID: 0, CountryCode: 'CHI',    Nationality:       'Channel Islands' },
  { NationalityID: 61, CountryCode: 'CL',    Nationality:      'Chile' },
  { NationalityID: 8, CountryCode: 'CN',    Nationality:       'China' },
  { NationalityID: 9, CountryCode: 'CO',    Nationality:       'Colombia' },
  { NationalityID: 0, CountryCode: 'KM',    Nationality:       'Comoros' },
  { NationalityID: 62, CountryCode: 'CG',    Nationality:      'Congo' },
  { NationalityID: 63, CountryCode: 'CR',    Nationality:      'Costa Rica' },
  { NationalityID: 0, CountryCode: 'CI',    Nationality:       'Côte d\'Ivoire' },
  { NationalityID: 64, CountryCode: 'HR',    Nationality:      'Croatia' },
  { NationalityID: 10, CountryCode: 'CU',    Nationality:      'Cuba' },
  { NationalityID: 65, CountryCode: 'CY',    Nationality:      'Cyprus' },
  { NationalityID: 66, CountryCode: 'CZ',    Nationality:      'Czech Republic' },
  { NationalityID: 67, CountryCode: 'DK',    Nationality:      'Denmark' },
  { NationalityID: 68, CountryCode: 'DJ',    Nationality:      'Djibouti' },
  { NationalityID: 11, CountryCode: 'DO',    Nationality:      'Dominica' },
  { NationalityID: 69, CountryCode: 'DM',    Nationality:      'Dominican Republic' },
  { NationalityID: 0, CountryCode: 'CD',    Nationality:       'DR Congo' },
  { NationalityID: 12, CountryCode: 'EC',    Nationality:      'Ecuador' },
  { NationalityID: 70, CountryCode: 'EG',    Nationality:      'Egypt' },
  { NationalityID: 13, CountryCode: 'SV',    Nationality:      'El Salvador' },
  { NationalityID: 0, CountryCode: 'GQ',    Nationality:       'Equatorial Guinea' },
  { NationalityID: 71, CountryCode: 'ER',    Nationality:      'Eritrea' },
  { NationalityID: 72, CountryCode: 'EE',    Nationality:      'Estonia' },
  { NationalityID: 146, CountryCode: 'SZ'   , Nationality:     'Eswatini' },
  { NationalityID: 73, CountryCode: 'ET',    Nationality:      'Ethiopia' },
  { NationalityID: 74, CountryCode: 'FJ',    Nationality:      'Fiji' },
  { NationalityID: 75, CountryCode: 'FI',    Nationality:      'Finland' },
  { NationalityID: 14, CountryCode: 'FR',    Nationality:      'France' },
  { NationalityID: 0, CountryCode: 'GF',    Nationality:       'French Guiana' },
  { NationalityID: 76, CountryCode: 'PF',    Nationality:      'French Polynesia' },
  { NationalityID: 77, CountryCode: 'GA',    Nationality:      'Gabon' },
  { NationalityID: 78, CountryCode: 'GM',    Nationality:      'Gambia' },
  { NationalityID: 79, CountryCode: 'GE',    Nationality:      'Georgia' },
  { NationalityID: 15, CountryCode: 'DE',    Nationality:      'Germany' },
  { NationalityID: 80, CountryCode: 'GH',    Nationality:      'Ghana' },
  { NationalityID: 0, CountryCode: 'GI',    Nationality:       'Gibraltar' },
  { NationalityID: 81, CountryCode: 'GR',    Nationality:      'Greece' },
  { NationalityID: 82, CountryCode: 'GD',    Nationality:      'Grenada' },
  { NationalityID: 16, CountryCode: 'GT',    Nationality:      'Guatemala' },
  { NationalityID: 83, CountryCode: 'GN',    Nationality:      'Guinea' },
  { NationalityID: 0, CountryCode: 'GW',    Nationality:       'Guinea-Bissau' },
  { NationalityID: 84, CountryCode: 'GY',    Nationality:      'Guyana' },
  { NationalityID: 17, CountryCode: 'HT',    Nationality:      'Haiti' },
  { NationalityID: 18, CountryCode: 'HN',    Nationality:      'Honduras' },
  { NationalityID: 0, CountryCode: 'HK',       Nationality:      'Hong Kong' },
  { NationalityID: 85, CountryCode: 'HU',    Nationality:      'Hungary' },
  { NationalityID: 86, CountryCode: 'IS',    Nationality:      'Iceland' },
  { NationalityID: 19, CountryCode: 'IN',    Nationality:      'India' },
  { NationalityID: 87, CountryCode: 'ID',    Nationality:      'Indonesia' },
  { NationalityID: 88, CountryCode: 'IR',    Nationality:      'Iran' },
  { NationalityID: 89, CountryCode: 'IQ',    Nationality:      'Iraq' },
  { NationalityID: 20, CountryCode: 'IE',    Nationality:      'Ireland' },
  { NationalityID: 0, CountryCode: 'IM',    Nationality:       'Isle of Man' },
  { NationalityID: 21, CountryCode: 'IL',    Nationality:      'Israel' },
  { NationalityID: 22, CountryCode: 'IT',    Nationality:      'Italy' },
  { NationalityID: 90, CountryCode: 'JM',    Nationality:      'Jamaica' },
  { NationalityID: 23, CountryCode: 'JP',    Nationality:      'Japan' },
  { NationalityID: 91, CountryCode: 'JO',    Nationality:      'Jordan' },
  { NationalityID: 92, CountryCode: 'KZ',    Nationality:      'Kazakhstan' },
  { NationalityID: 93, CountryCode: 'KE',    Nationality:      'Kenya' },
  { NationalityID: 95, CountryCode: 'KW',    Nationality:      'Kuwait' },
  { NationalityID: 0, CountryCode: 'KG',    Nationality:       'Kyrgyzstan' },
  { NationalityID: 0, CountryCode: 'LA',    Nationality:       'Laos' },
  { NationalityID: 96, CountryCode: 'LV',    Nationality:      'Latvia' },
  { NationalityID: 97, CountryCode: 'LB',    Nationality:      'Lebanon' },
  { NationalityID: 0, CountryCode: 'LS',    Nationality:       'Lesotho' },
  { NationalityID: 98, CountryCode: 'LR',    Nationality:      'Liberia' },
  { NationalityID: 99, CountryCode: 'LY',    Nationality:      'Libya' },
  { NationalityID: 0, CountryCode: 'LI',    Nationality:       'Liechtenstein' },
  { NationalityID: 100, CountryCode: 'LT', Nationality:     'Lithuania' },
  { NationalityID: 101, CountryCode: 'LU', Nationality:     'Luxembourg' },
  { NationalityID: 0, CountryCode: 'MO',    Nationality:       'Macao' },
  { NationalityID: 102, CountryCode: 'MG',  Nationality:     'Madagascar' },
  { NationalityID: 103, CountryCode: 'MW',  Nationality:     'Malawi' },
  { NationalityID: 104, CountryCode: 'MY',  Nationality:     'Malaysia' },
  { NationalityID: 105, CountryCode: 'MV',  Nationality:     'Maldives' },
  { NationalityID: 106, CountryCode: 'ML',  Nationality:     'Mali' },
  { NationalityID: 107, CountryCode: 'MT',  Nationality:     'Malta' },
  { NationalityID: 108, CountryCode: 'MR',  Nationality:     'Mauritania' },
  { NationalityID: 109, CountryCode: 'MU',  Nationality:     'Mauritius' },
  { NationalityID: 0, CountryCode: 'YT',    Nationality:       'Mayotte' },
  { NationalityID: 25, CountryCode: 'MX',    Nationality:      'Mexico' },
  { NationalityID: 0, CountryCode: 'MD',    Nationality:       'Moldova' },
  { NationalityID: 110, CountryCode: 'MC'   , Nationality:     'Monaco' },
  { NationalityID: 111, CountryCode: 'MN'   , Nationality:     'Mongolia' },
  { NationalityID: 112, CountryCode: 'ME'   , Nationality:     'Montenegro' },
  { NationalityID: 113, CountryCode: 'MA'   , Nationality:     'Morocco' },
  { NationalityID: 114, CountryCode: 'MZ'   , Nationality:     'Mozambique' },
  { NationalityID: 0, CountryCode: 'MM',    Nationality:       'Myanmar' },
  { NationalityID: 115, CountryCode: 'NA'   , Nationality:     'Namibia' },
  { NationalityID: 116, CountryCode: 'NP'   , Nationality:     'Nepal' },
  { NationalityID: 26, CountryCode: 'NL',    Nationality:      'Netherlands' },
  { NationalityID: 117, CountryCode: 'NZ'   , Nationality:     'New Zealand' },
  { NationalityID: 118, CountryCode: 'NI'   , Nationality:     'Nicaragua' },
  { NationalityID: 120, CountryCode: 'NG'   , Nationality:     'Niger' },
  { NationalityID: 119, CountryCode: 'NE'   , Nationality:     'Nigeria' },
  { NationalityID: 94, CountryCode: 'KP',    Nationality:      'North Korea' },
  { NationalityID: 0, CountryCode: 'MK',    Nationality:       'North Macedonia' },
  { NationalityID: 121, CountryCode: 'NO'   , Nationality:     'Norway' },
  { NationalityID: 122, CountryCode: 'OM'   , Nationality:     'Oman' },
  { NationalityID: 123, CountryCode: 'PK'   , Nationality:     'Pakistan' },
  { NationalityID: 124, CountryCode: 'PA'   , Nationality:     'Panama' },
  { NationalityID: 125, CountryCode: 'PG'   , Nationality:     'Papua New Guinea' },
  { NationalityID: 126, CountryCode: 'PY'   , Nationality:     'Paraguay' },
  { NationalityID: 127, CountryCode: 'PE'   , Nationality:     'Peru' },
  { NationalityID: 27, CountryCode: 'PH',    Nationality:      'Philippines' },
  { NationalityID: 128, CountryCode: 'PL'   , Nationality:     'Poland' },
  { NationalityID: 129, CountryCode: 'PT'   , Nationality:     'Portugal' },
  { NationalityID: 130, CountryCode: 'QA'   , Nationality:     'Qatar' },
  { NationalityID: 0, CountryCode: 'RE',    Nationality:       'Réunion' },
  { NationalityID: 131, CountryCode: 'RO', Nationality:     'Romania' },
  { NationalityID: 0, CountryCode: 'RU',      Nationality:         'Russia' },
  { NationalityID: 132, CountryCode: 'RW', Nationality:     'Rwanda' },
  { NationalityID: 0, CountryCode: 'SH',    Nationality:       'Saint Helena' },
  { NationalityID: 0, CountryCode: 'KN',    Nationality:       'Saint Kitts and Nevis' },
  { NationalityID: 0, CountryCode: 'LC',    Nationality:       'Saint Lucia' },
  { NationalityID: 0, CountryCode: 'VC',    Nationality:       'Saint Vincent and the Grenadines' },
  { NationalityID: 37, CountryCode: 'AS',    Nationality:      'Samoa' },
  { NationalityID: 0, CountryCode: 'SM',    Nationality:       'San Marino' },
  { NationalityID: 0, CountryCode: 'ST',    Nationality:       'Sao Tome & Principe' },
  { NationalityID: 133, CountryCode: 'SA'   , Nationality:     'Saudi Arabia' },
  { NationalityID: 134, CountryCode: 'SN'   , Nationality:     'Senegal' },
  { NationalityID: 135, CountryCode: 'RS'   , Nationality:     'Serbia' },
  { NationalityID: 0, CountryCode: 'SC',    Nationality:       'Seychelles' },
  { NationalityID: 136, CountryCode: 'SL'   , Nationality:     'Sierra Leone' },
  { NationalityID: 137, CountryCode: 'SG'   , Nationality:     'Singapore' },
  { NationalityID: 138, CountryCode: 'SK'   , Nationality:     'Slovakia' },
  { NationalityID: 139, CountryCode: 'SI'   , Nationality:     'Slovenia' },
  { NationalityID: 140, CountryCode: 'SB'   , Nationality:     'Solomon Islands' },
  { NationalityID: 141, CountryCode: 'SO'   , Nationality:     'Somalia' },
  { NationalityID: 142, CountryCode: 'ZA'   , Nationality:     'South Africa' },
  { NationalityID: 24, CountryCode: 'KR',    Nationality:      'South Korea' },
  { NationalityID: 0, CountryCode: 'SS',    Nationality:       'South Sudan' },
  { NationalityID: 28, CountryCode: 'ES',    Nationality:      'Spain' },
  { NationalityID: 143, CountryCode: 'LK'   , Nationality:     'Sri Lanka' },
  { NationalityID: 0, CountryCode: 'PS',    Nationality:       'State of Palestine' },
  { NationalityID: 144, CountryCode: 'SD'   , Nationality:     'Sudan' },
  { NationalityID: 145, CountryCode: 'SR'   , Nationality:     'Suriname' },
  { NationalityID: 29, CountryCode: 'SE',    Nationality:      'Sweden' },
  { NationalityID: 30, CountryCode: 'CH',    Nationality:      'Switzerland' },
  { NationalityID: 0, CountryCode: 'SY',    Nationality:       'Syria' },
  { NationalityID: 31, CountryCode: 'TW',    Nationality:      'Taiwan' },
  { NationalityID: 147, CountryCode: 'TJ'   , Nationality:     'Tajikistan' },
  { NationalityID: 0, CountryCode: 'TZ',    Nationality:       'Tanzania' },
  { NationalityID: 148, CountryCode: 'TH'   , Nationality:     'Thailand' },
  { NationalityID: 0, CountryCode: 'BS',    Nationality:       'The Bahamas' },
  { NationalityID: 0, CountryCode: 'TL',    Nationality:       'Timor-Leste' },
  { NationalityID: 149, CountryCode: 'TG'   , Nationality:     'Togo' },
  { NationalityID: 150, CountryCode: 'TT'   , Nationality:     'Trinidad and Tobago' },
  { NationalityID: 151, CountryCode: 'TN'   , Nationality:     'Tunisia' },
  { NationalityID: 152, CountryCode: 'TR'   , Nationality:     'Turkey' },
  { NationalityID: 153, CountryCode: 'TM'   , Nationality:     'Turkmenistan' },
  { NationalityID: 154, CountryCode: 'TV'   , Nationality:     'Tuvalu' },
  { NationalityID: 155, CountryCode: 'UG'   , Nationality:     'Uganda' },
  { NationalityID: 156, CountryCode: 'UA'   , Nationality:     'Ukraine' },
  { NationalityID: 157, CountryCode: 'AE'   , Nationality:     'United Arab Emirates' },
  { NationalityID: 1, CountryCode: 'GB',    Nationality:       'United Kingdom' },
  { NationalityID: 158, CountryCode: 'US'   , Nationality:     'United States' },
  { NationalityID: 159, CountryCode: 'UY'   , Nationality:     'Uruguay' },
  { NationalityID: 160, CountryCode: 'UZ'   , Nationality:     'Uzbekistan' },
  { NationalityID: 161, CountryCode: 'VU'   , Nationality:     'Vanuatu' },
  { NationalityID: 32, CountryCode: 'VE',    Nationality:      'Venezuela' },
  { NationalityID: 33, CountryCode: 'VN',    Nationality:      'Vietnam' },
  { NationalityID: 0, CountryCode: 'EH',    Nationality:       'Western Sahara' },
  { NationalityID: 162, CountryCode: 'YE'   , Nationality:     'Yemen' },
  { NationalityID: 163, CountryCode: 'ZM'   , Nationality:     'Zambia' },
  { NationalityID: 0, CountryCode: 'ZW',    Nationality:       'Zimbabwe' },
  { NationalityID: 164, CountryCode: 'ONA   ',Nationality:     'Other Nationality'}
];

let nations = {
  en: [],
  ja: [
    { NationalityID: 23, CountryCode: 'JP', Nationality: '日本'},
    { NationalityID: 86, CountryCode: 'IS', Nationality: 'アイスランド'},
    { NationalityID: 20, CountryCode: 'IE', Nationality: 'アイルランド'},
    { NationalityID: 42, CountryCode: 'AZ', Nationality: 'アゼルバイジャン'},
    { NationalityID: 34, CountryCode: 'AF', Nationality: 'アフガニスタン'},
    { NationalityID: 157, CountryCode: 'AE', Nationality: 'UAE'},
    { NationalityID: 36, CountryCode: 'DZ', Nationality: 'アルジェリア'},
    { NationalityID: 2, CountryCode: 'AR', Nationality: 'アルゼンチン'},
    { NationalityID: 35, CountryCode: 'AL', Nationality: 'アルバニア'},
    { NationalityID: 40, CountryCode: 'AM', Nationality: 'アルメニア'},
    { NationalityID: 39, CountryCode: 'AO', Nationality: 'アンゴラ'},
    { NationalityID: 0, CountryCode: 'AG', Nationality: 'アンティグアバーブーダ' },
    { NationalityID: 38, CountryCode: 'AD', Nationality: 'アンドラ公国'},
    { NationalityID: 162, CountryCode: 'YE', Nationality: 'イェメン'},
    { NationalityID: 1, CountryCode: 'GB', Nationality: 'イギリス'},
    { NationalityID: 21, CountryCode: 'IL', Nationality: 'イスラエル'},
    { NationalityID: 22, CountryCode: 'IT', Nationality: 'イタリア'},
    { NationalityID: 69, CountryCode: 'DM', Nationality: 'ドミニカ国'},
    { NationalityID: 89, CountryCode: 'IQ', Nationality: 'イラク'},
    { NationalityID: 88, CountryCode: 'IR', Nationality: 'イラン'},
    { NationalityID: 19, CountryCode: 'IN', Nationality: 'インド'},
    { NationalityID: 87, CountryCode: 'ID', Nationality: 'インドネシア'},
    { NationalityID: 155, CountryCode: 'UG', Nationality: 'ウガンダ'},
    { NationalityID: 156, CountryCode: 'UA', Nationality: 'ウクライナ'},
    { NationalityID: 160, CountryCode: 'UZ', Nationality: 'ウズベキスタン'},
    { NationalityID: 159, CountryCode: 'UY', Nationality: 'ウルグアイ'},
    { NationalityID: 12, CountryCode: 'EC', Nationality: 'エクアドール'},
    { NationalityID: 70, CountryCode: 'EG', Nationality: 'エジプト'},
    { NationalityID: 72, CountryCode: 'EE', Nationality: 'エストニア'},
    { NationalityID: 146, CountryCode: 'SZ', Nationality: 'エスワティニ'},
    { NationalityID: 73, CountryCode: 'ET', Nationality: 'エチオピア'},
    { NationalityID: 71, CountryCode: 'ER', Nationality: 'エリトリア'},
    { NationalityID: 13, CountryCode: 'SV', Nationality: 'エルサルバドル'},
    { NationalityID: 3, CountryCode: 'AU', Nationality: 'オーストラリア'},
    { NationalityID: 41, CountryCode: 'AT', Nationality: 'オーストリア'},
    { NationalityID: 122, CountryCode: 'OM', Nationality: 'オマーン'},
    { NationalityID: 26, CountryCode: 'NL', Nationality: 'オランダ'},
    { NationalityID: 80, CountryCode: 'GH', Nationality: 'ガーナ'},
    { NationalityID: 59, CountryCode: 'CV', Nationality: 'カーボベルデ'},
    { NationalityID: 84, CountryCode: 'GY', Nationality: 'ガイアナ'},
    { NationalityID: 92, CountryCode: 'KZ', Nationality: 'カザフスタン'},
    { NationalityID: 130, CountryCode: 'QA', Nationality: 'カタール'},
    { NationalityID: 16, CountryCode: 'GT', Nationality: 'ガテマラ'},
    { NationalityID: 7, CountryCode: 'CA', Nationality: 'カナダ'},
    { NationalityID: 0, CountryCode: 'CV1', Nationality: 'カボベルデ' },
    { NationalityID: 77, CountryCode: 'GA', Nationality: 'ガボン'},
    { NationalityID: 58, CountryCode: 'CM', Nationality: 'カメルーン'},
    { NationalityID: 24, CountryCode: 'KR', Nationality: '韓国'},
    { NationalityID: 78, CountryCode: 'GM', Nationality: 'ガンビア'},
    { NationalityID: 57, CountryCode: 'KH', Nationality: 'カンボジア'},
    { NationalityID: 0, CountryCode: 'GF', Nationality: 'ギアナ' },
    { NationalityID: 0, CountryCode: 'MK', Nationality: '北マケドニア' },
    { NationalityID: 94, CountryCode: 'KP', Nationality: '北朝鮮'},
    { NationalityID: 83, CountryCode: 'GN', Nationality: 'ギニア'},
    { NationalityID: 0, CountryCode: 'GW', Nationality: 'ギニアビサウ' },
    { NationalityID: 65, CountryCode: 'CY', Nationality: 'キプロス'},
    { NationalityID: 10, CountryCode: 'CU', Nationality: 'キューバ'},
    { NationalityID: 81, CountryCode: 'GR', Nationality: 'ギリシャ'},
    { NationalityID: 0, CountryCode: 'KG', Nationality: 'キルギスタン' },
    { NationalityID: 95, CountryCode: 'KW', Nationality: 'クウェート'},
    { NationalityID: 82, CountryCode: 'GD', Nationality: 'グレナダ'},
    { NationalityID: 64, CountryCode: 'HR', Nationality: 'クロアチア'},
    { NationalityID: 93, CountryCode: 'KE', Nationality: 'ケニア'},
    { NationalityID: 0, CountryCode: 'CI', Nationality: 'コートジボワール' },
    { NationalityID: 63, CountryCode: 'CR', Nationality: 'コスタリカ'},
    { NationalityID: 0, CountryCode: 'KM', Nationality: 'コモロ' },
    { NationalityID: 9, CountryCode: 'CO', Nationality: 'コロンビア'},
    { NationalityID: 62, CountryCode: 'CG', Nationality: 'コンゴ'},
    { NationalityID: 0, CountryCode: 'CD', Nationality: 'コンゴ民主共和国' },
    { NationalityID: 133, CountryCode: 'SA', Nationality: 'サウジアラビア'},
    { NationalityID: 37, CountryCode: 'AS', Nationality: 'サモア'},
    { NationalityID: 0, CountryCode: 'ST', Nationality: 'サントメプリンシペ' },
    { NationalityID: 163, CountryCode: 'ZM', Nationality: 'ザンビア'},
    { NationalityID: 0, CountryCode: 'SM', Nationality: 'サンマリノ' },
    { NationalityID: 136, CountryCode: 'SL', Nationality: 'シエラレオネ'},
    { NationalityID: 68, CountryCode: 'DJ', Nationality: 'ジブチ'},
    { NationalityID: 0, CountryCode: 'GI', Nationality: 'ジブラルタル' },
    { NationalityID: 90, CountryCode: 'JM', Nationality: 'ジャマイカ'},
    { NationalityID: 79, CountryCode: 'GE', Nationality: 'ジョージア'},
    { NationalityID: 0, CountryCode: 'SY', Nationality: 'シリア' },
    { NationalityID: 137, CountryCode: 'SG', Nationality: 'シンガポール'},
    { NationalityID: 0, CountryCode: 'ZW', Nationality: 'ジンバブエ' },
    { NationalityID: 30, CountryCode: 'CH', Nationality: 'スイス'},
    { NationalityID: 29, CountryCode: 'SE', Nationality: 'スウェーデン'},
    { NationalityID: 144, CountryCode: 'SD', Nationality: 'スーダン'},
    { NationalityID: 28, CountryCode: 'ES', Nationality: 'スペイン'},
    { NationalityID: 145, CountryCode: 'SR', Nationality: 'スリナム'},
    { NationalityID: 143, CountryCode: 'LK', Nationality: 'スリランカ'},
    { NationalityID: 138, CountryCode: 'SK', Nationality: 'スロバキア'},
    { NationalityID: 139, CountryCode: 'SI', Nationality: 'スロベニア'},
    { NationalityID: 0, CountryCode: 'SC', Nationality: 'セイシェル' },
    { NationalityID: 134, CountryCode: 'SN', Nationality: 'セネガル'},
    { NationalityID: 135, CountryCode: 'RS', Nationality: 'セルビア'},
    { NationalityID: 0, CountryCode: 'KN', Nationality: 'セントクリストファーネビス' },
    { NationalityID: 0, CountryCode: 'VC', Nationality: 'セントビンセントおよびグレナディーン' },
    { NationalityID: 0, CountryCode: 'SH', Nationality: 'セントヘレナ' },
    { NationalityID: 0, CountryCode: 'LC', Nationality: 'セントルシア' },
    { NationalityID: 141, CountryCode: 'SO', Nationality: 'ソマリア'},
    { NationalityID: 140, CountryCode: 'SB', Nationality: 'ソロモン諸島'},
    { NationalityID: 148, CountryCode: 'TH', Nationality: 'タイ'},
    { NationalityID: 31, CountryCode: 'TW', Nationality: '台湾'},
    { NationalityID: 147, CountryCode: 'TJ', Nationality: 'タジキスタン'},
    { NationalityID: 0, CountryCode: 'TZ', Nationality: 'タンザニア' },
    { NationalityID: 66, CountryCode: 'CZ', Nationality: 'チェコ'},
    { NationalityID: 60, CountryCode: 'TD', Nationality: 'チャド'},
    { NationalityID: 0, CountryCode: 'CHI', Nationality: 'チャンネル諸島' },
    { NationalityID: 8, CountryCode: 'CN', Nationality: '中国'},
    { NationalityID: 151, CountryCode: 'TN', Nationality: 'チュニジア'},
    { NationalityID: 61, CountryCode: 'CL', Nationality: 'チリ'},
    { NationalityID: 154, CountryCode: 'TV', Nationality: 'ツバル'},
    { NationalityID: 67, CountryCode: 'DK', Nationality: 'デンマーク'},
    { NationalityID: 15, CountryCode: 'DE', Nationality: 'ドイツ'},
    { NationalityID: 149, CountryCode: 'TG', Nationality: 'トーゴ'},
    { NationalityID: 11, CountryCode: 'DO', Nationality: 'ドミニカ共和国'},
    { NationalityID: 150, CountryCode: 'TT', Nationality: 'トリニダードトバゴ'},
    { NationalityID: 153, CountryCode: 'TM', Nationality: 'トルクメニスタン'},
    { NationalityID: 152, CountryCode: 'TR', Nationality: 'トルコ'},
    { NationalityID: 120, CountryCode: 'NG', Nationality: 'ナイジェリア'},
    { NationalityID: 115, CountryCode: 'NA', Nationality: 'ナミビア'},
    { NationalityID: 118, CountryCode: 'NI', Nationality: 'ニカラグア'},
    { NationalityID: 0, CountryCode: 'EH', Nationality: '西サハラ' },
    { NationalityID: 119, CountryCode: 'NE', Nationality: 'ニジェール'},
    { NationalityID: 117, CountryCode: 'NZ', Nationality: 'ニュージーランド'},
    { NationalityID: 116, CountryCode: 'NP', Nationality: 'ネパール'},
    { NationalityID: 121, CountryCode: 'NO', Nationality: 'ノルウェー'},
    { NationalityID: 43, CountryCode: 'BH', Nationality: 'バーレーン'},
    { NationalityID: 17, CountryCode: 'HT', Nationality: 'ハイチ'},
    { NationalityID: 123, CountryCode: 'PK', Nationality: 'パキスタン'},
    { NationalityID: 124, CountryCode: 'PA', Nationality: 'パナマ'},
    { NationalityID: 161, CountryCode: 'VU', Nationality: 'バヌアツ'},
    { NationalityID: 0, CountryCode: 'BS', Nationality: 'バハマ' },
    { NationalityID: 125, CountryCode: 'PG', Nationality: 'パプアニューギニア'},
    { NationalityID: 49, CountryCode: 'BM', Nationality: 'バミューダ'},
    { NationalityID: 126, CountryCode: 'PY', Nationality: 'パラグアイ'},
    { NationalityID: 45, CountryCode: 'BB', Nationality: 'バルバドス'},
    { NationalityID: 0, CountryCode: 'PS', Nationality: 'パレスチナ' },
    { NationalityID: 85, CountryCode: 'HU', Nationality: 'ハンガリー'},
    { NationalityID: 44, CountryCode: 'BD', Nationality: 'バングラデシュ'},
    { NationalityID: 0, CountryCode: 'TL', Nationality: '東ティモール' },
    { NationalityID: 74, CountryCode: 'FJ', Nationality: 'フィジー'},
    { NationalityID: 27, CountryCode: 'PH', Nationality: 'フィリピン'},
    { NationalityID: 75, CountryCode: 'FI', Nationality: 'フィンランド'},
    { NationalityID: 50, CountryCode: 'BT', Nationality: 'ブータン'},
    { NationalityID: 0 , CountryCode: 'BR', Nationality: 'ブラジル'},
    { NationalityID: 14, CountryCode: 'FR', Nationality: 'フランス'},
    { NationalityID: 54, CountryCode: 'BG', Nationality: 'ブルガリア'},
    { NationalityID: 55, CountryCode: 'BF', Nationality: 'ブルキナファソ'},
    { NationalityID: 0, CountryCode: 'BN', Nationality: 'ブルネイ' },
    { NationalityID: 56, CountryCode: 'BI', Nationality: 'ブルンジ'},
    { NationalityID: 158, CountryCode: 'US', Nationality: '米国'},
    { NationalityID: 33, CountryCode: 'VN', Nationality: 'ベトナム'},
    { NationalityID: 48, CountryCode: 'BJ', Nationality: 'ベナン'},
    { NationalityID: 32, CountryCode: 'VE', Nationality: 'ベネズエラ'},
    { NationalityID: 46, CountryCode: 'BY', Nationality: 'ベラルーシ'},
    { NationalityID: 47, CountryCode: 'BZ', Nationality: 'ベリーズ'},
    { NationalityID: 127, CountryCode: 'PE', Nationality: 'ペルー'},
    { NationalityID: 0, CountryCode: 'BE', Nationality: 'ベルギー' },
    { NationalityID: 128, CountryCode: 'PL', Nationality: 'ポーランド'},
    { NationalityID: 52, CountryCode: 'BA', Nationality: 'ボスニア'},
    { NationalityID: 53, CountryCode: 'BW', Nationality: 'ボツワナ'},
    { NationalityID: 76, CountryCode: 'PF', Nationality: 'フレンチポリネシア'},
    { NationalityID: 51, CountryCode: 'BO', Nationality: 'ボリビア'},
    { NationalityID: 129, CountryCode: 'PT', Nationality: 'ポルトガル'},
    { NationalityID: 0, CountryCode: 'HK', Nationality: '香港'},
    { NationalityID: 18, CountryCode: 'HN', Nationality: 'ホンジュラス'},
    { NationalityID: 0, CountryCode: 'MO', Nationality: 'マカオ' },
    { NationalityID: 102, CountryCode: 'MG', Nationality: 'マダガスカル'},
    { NationalityID: 0, CountryCode: 'YT', Nationality: 'マヨット' },
    { NationalityID: 103, CountryCode: 'MW', Nationality: 'マラウイ'},
    { NationalityID: 106, CountryCode: 'ML', Nationality: 'マリ'},
    { NationalityID: 107, CountryCode: 'MT', Nationality: 'マルタ'},
    { NationalityID: 104, CountryCode: 'MY', Nationality: 'マレーシア'},
    { NationalityID: 0, CountryCode: 'IM', Nationality: 'マン島' },
    { NationalityID: 0, CountryCode: 'SS', Nationality: '南スーダン' },
    { NationalityID: 142, CountryCode: 'ZA', Nationality: '南アフリカ'},
    { NationalityID: 0, CountryCode: 'MM', Nationality: 'ミャンマー' },
    { NationalityID: 25, CountryCode: 'MX', Nationality: 'メキシコ'},
    { NationalityID: 109, CountryCode: 'MU', Nationality: 'モーリシャス'},
    { NationalityID: 108, CountryCode: 'MR', Nationality: 'モーリタニア'},
    { NationalityID: 114, CountryCode: 'MZ', Nationality: 'モザンビーク'},
    { NationalityID: 110, CountryCode: 'MC', Nationality: 'モナコ'},
    { NationalityID: 105, CountryCode: 'MV', Nationality: 'モルディブ'},
    { NationalityID: 0, CountryCode: 'MD', Nationality: 'モルドバ' },
    { NationalityID: 113, CountryCode: 'MA', Nationality: 'モロッコ'},
    { NationalityID: 111, CountryCode: 'MN', Nationality: 'モンゴル'},
    { NationalityID: 112, CountryCode: 'ME', Nationality: 'モンテネグロ'},
    { NationalityID: 91, CountryCode: 'JO', Nationality: 'ヨルダン'},
    { NationalityID: 0, CountryCode: 'LA', Nationality: 'ラオス' },
    { NationalityID: 96, CountryCode: 'LV', Nationality: 'ラトビア'},
    { NationalityID: 100, CountryCode: 'LT', Nationality: 'リトアニア'},
    { NationalityID: 99, CountryCode: 'LY', Nationality: 'リビア'},
    { NationalityID: 0, CountryCode: 'LI', Nationality: 'リヒテンシュタイン' },
    { NationalityID: 98, CountryCode: 'LR', Nationality: 'リベリア'},
    { NationalityID: 131, CountryCode: 'RO', Nationality: 'ルーマニア'},
    { NationalityID: 101, CountryCode: 'LU', Nationality: 'ルクセンブルク'},
    { NationalityID: 132, CountryCode: 'RW', Nationality: 'ルワンダ'},
    { NationalityID: 0, CountryCode: 'LS', Nationality: 'レソト' },
    { NationalityID: 97, CountryCode: 'LB', Nationality: 'レバノン'},
    { NationalityID: 0, CountryCode: 'RE', Nationality: 'レユニオン' },
    { NationalityID: 0, CountryCode: 'RU', Nationality: 'ロシア'},
    { NationalityID: 0, CountryCode: 'GQ', Nationality: '赤道ギニア共和国' },
    { NationalityID: 0, CountryCode: 'CF', Nationality: '中央アフリカ共和国' },
    { NationalityID: 164, CountryCode: 'ONA', Nationality: 'その他国籍'}
  ],
  ko: [],
  "zh-CN": [],
  "zh-TW": [],
};

//Loop thru to copy from the look up list
['en', 'ko', 'zh-CN', 'zh-TW'].forEach(function(k) {
  nations[k] = lookUpNationalities;
});

//Final nationality list
export const nationalities = nations;

export const sys_choices = {
  en: {
    yes_no: [
      {
        label: "yes",
        value: "Yes",
      },
      {
        label: "no",
        value: "No",
      },
    ],
    choices2: ["None", "10 Days", "2 Weeks", "3 Weeks", "4 Weeks", "1 Month"],
    relationships: ["Family", "Friend", "Coworkers", "Couple", "Others"],
    genders: ["Male", "Female", "Others"],
  },
  ja: {
    yes_no: [
      {
        label: "yes",
        value: "はい",
      },
      {
        label: "no",
        value: "いいえ",
      },
    ],
    choices2: ["無し", "10日", "2週間", "3週間", "4週間", "1か月"],
    relationships: ["家族", "友人", "同僚", "カップル", "その他"],
    genders: ["男性", "女性", "その他"],
  },
};

export const sys_languages = {
  ab: "Abkhazian",
  aa: "Afar",
  af: "Afrikaans",
  ak: "Akan",
  sq: "Albanian",
  am: "Amharic",
  ar: "Arabic",
  an: "Aragonese",
  hy: "Armenian",
  as: "Assamese",
  av: "Avaric",
  ae: "Avestan",
  ay: "Aymara",
  az: "Azerbaijani",
  bm: "Bambara",
  ba: "Bashkir",
  eu: "Basque",
  be: "Belarusian",
  bn: "Bengali",
  bh: "Bihari languages",
  bi: "Bislama",
  bs: "Bosnian",
  br: "Breton",
  bg: "Bulgarian",
  my: "Burmese",
  ca: "Catalan, Valencian",
  km: "Central Khmer",
  ch: "Chamorro",
  ce: "Chechen",
  ny: "Chichewa, Chewa, Nyanja",
  zh: "Chinese",
  cu: "Church Slavonic, Old Bulgarian, Old Church Slavonic",
  cv: "Chuvash",
  kw: "Cornish",
  co: "Corsican",
  cr: "Cree",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  dv: "Divehi, Dhivehi, Maldivian",
  nl: "Dutch, Flemish",
  dz: "Dzongkha",
  en: "English",
  eo: "Esperanto",
  et: "Estonian",
  ee: "Ewe",
  fo: "Faroese",
  fj: "Fijian",
  fi: "Finnish",
  fr: "French",
  ff: "Fulah",
  gd: "Gaelic, Scottish Gaelic",
  gl: "Galician",
  lg: "Ganda",
  ka: "Georgian",
  de: "German",
  ki: "Gikuyu, Kikuyu",
  el: "Greek (Modern)",
  kl: "Greenlandic, Kalaallisut",
  gn: "Guarani",
  gu: "Gujarati",
  ht: "Haitian, Haitian Creole",
  ha: "Hausa",
  he: "Hebrew",
  hz: "Herero",
  hi: "Hindi",
  ho: "Hiri Motu",
  hu: "Hungarian",
  is: "Icelandic",
  io: "Ido",
  ig: "Igbo",
  id: "Indonesian",
  ia: "Interlingua (International Auxiliary Language Association)",
  ie: "Interlingue",
  iu: "Inuktitut",
  ik: "Inupiaq",
  ga: "Irish",
  it: "Italian",
  ja: "日本",
  jv: "Javanese",
  kn: "Kannada",
  kr: "Kanuri",
  ks: "Kashmiri",
  kk: "Kazakh",
  rw: "Kinyarwanda",
  kv: "Komi",
  kg: "Kongo",
  ko: "Korean",
  kj: "Kwanyama, Kuanyama",
  ku: "Kurdish",
  ky: "Kyrgyz",
  lo: "Lao",
  la: "Latin",
  lv: "Latvian",
  lb: "Letzeburgesch, Luxembourgish",
  li: "Limburgish, Limburgan, Limburger",
  ln: "Lingala",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  mk: "Macedonian",
  mg: "Malagasy",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  gv: "Manx",
  mi: "Maori",
  mr: "Marathi",
  mh: "Marshallese",
  ro: "Moldovan, Moldavian, Romanian",
  mn: "Mongolian",
  na: "Nauru",
  nv: "Navajo, Navaho",
  nd: "Northern Ndebele",
  ng: "Ndonga",
  ne: "Nepali",
  se: "Northern Sami",
  no: "Norwegian",
  nb: "Norwegian Bokmål",
  nn: "Norwegian Nynorsk",
  ii: "Nuosu, Sichuan Yi",
  oc: "Occitan (post 1500)",
  oj: "Ojibwa",
  or: "Oriya",
  om: "Oromo",
  os: "Ossetian, Ossetic",
  pi: "Pali",
  pa: "Panjabi, Punjabi",
  ps: "Pashto, Pushto",
  fa: "Persian",
  pl: "Polish",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ru: "Russian",
  sm: "Samoan",
  sg: "Sango",
  sa: "Sanskrit",
  sc: "Sardinian",
  sr: "Serbian",
  sn: "Shona",
  sd: "Sindhi",
  si: "Sinhala, Sinhalese",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somali",
  st: "Sotho, Southern",
  nr: "South Ndebele",
  es: "Spanish, Castilian",
  su: "Sundanese",
  sw: "Swahili",
  ss: "Swati",
  sv: "Swedish",
  tl: "Tagalog",
  ty: "Tahitian",
  tg: "Tajik",
  ta: "Tamil",
  tt: "Tatar",
  te: "Telugu",
  th: "Thai",
  bo: "Tibetan",
  ti: "Tigrinya",
  to: "Tonga (Tonga Islands)",
  ts: "Tsonga",
  tn: "Tswana",
  tr: "Turkish",
  tk: "Turkmen",
  tw: "Twi",
  ug: "Uighur, Uyghur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volap_k",
  wa: "Walloon",
  cy: "Welsh",
  fy: "Western Frisian",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang, Chuang",
  zu: "Zulu",
};

export const core_questions = {
  en: {
    labels: {
      full_name: "Full Name",
      first_name: "First Name",
      last_name: "Last Name",
      address: "Address",
      phone: "Phone",
      nationality: "Nationality",
      email: "Email",
      age: "Age",
      dob: "Date of Birth",
      gender: "Gender",
      occupancy: "Occupancy",
      gov_id: "Passport / ID",
      gov_id_number: "Passport / ID Number",
      body_temperature: "Today's Body temperature",
      coughing: "Are you coughing now?",
      doctor_certificate: "Do you have Doctor Certificate?",
      pass_affection:
        "Do you have nCov-19 (corona virus) Affection History in Past",
      prev_destination: "Previous Destination",
      next_destination: "Next Destination",
      transportation: "Transportation",
      internation_travel: "Is this International Travel?",
      domestic_travel: "Is this Domestic Travel?",
      foreign_travel: "Foreign Travel Experience in",
      close_contact_corona_patient:
        "Are you a Close Contact with Corona patient in",
      relationship_with_main_visitor: "Relationship with main visitor",
      reservation_no: "Reservation #",
      signature: "Signature",
    },
  },
  ja: {
    labels: {
      full_name: "氏名",
      first_name: "名",
      last_name: "姓",
      address: "住所",
      phone: "電話",
      nationality: "国籍",
      email: "Email",
      age: "年齢",
      dob: "生年月日",
      gender: "性別",
      occupancy: "職業",
      gov_id: "身分証明書",
      gov_id_number: "身分証明書番号",
      body_temperature: "本日の体温",
      coughing: "咳をしていますか？",
      doctor_certificate: "医療施設証明書",
      pass_affection: "コロナ感染症に過去にかかったことがある",
      prev_destination: "前の立寄場所",
      next_destination: "次の目的地",
      transportation: "移動手段",
      internation_travel: "日本国外からの旅行ですか？",
      domestic_travel: "国内の旅行ですか？",
      foreign_travel: "過去の渡航履歴はありますか？",
      close_contact_corona_patient:
        "コロナ感染症患者との濃厚接触はありますか？",
      relationship_with_main_visitor: "メイン訪問者との関係",
      reservation_no: "予約番号",
      signature: "署名",
    },
  },
  ko: {
    labels: {
      full_name: "이름",
      first_name: "이름",
      last_name: "성",
      address: "주소",
      phone: "전화",
      nationality: "국적",
      email: "Email",
      age: "나이",
      dob: "생일",
      gender: "성별",
      occupancy: "직업",
      gov_id: "여권 / 신분증",
      gov_id_number: "여권 / 신분증 번호",
      body_temperature: "오늘의 체온",
      coughing: "기침을하고 있습니까?",
      doctor_certificate: "의료 인증서를 가지고 있습니까?",
      pass_affection:
        "코로나 감염에 과거에 앓은 적이 있습니까?",
      prev_destination: "출발지",
      next_destination: "다음 목적지",
      transportation: "교통 수단",
      internation_travel: "이번 여행은 국제 여행?",
      domestic_travel: "이번 여행은 국내 여행?",
      foreign_travel: "해외 여행 경험",
      close_contact_corona_patient:
        "코로나 감염 환자와의 농후 접촉자의 유무 및 기간",
      relationship_with_main_visitor: "메인 게스트와의 관계",
      reservation_no: "예약 번호",
      signature: "서명",
    },
  },
  "zh-CN": {
    labels: {
      full_name: "全名",
      first_name: "名稱 : 名字",
      last_name: "名稱 : 姓",
      address: "地址",
      phone: "电话",
      nationality: "国籍",
      email: "Email",
      age: "年龄",
      dob: "生日",
      gender: "性别",
      occupancy: "职业",
      gov_id: "护照/身份证明",
      gov_id_number: "護照/身份證號碼",
      body_temperature: "今天的體溫",
      coughing: "你现在咳嗽吗？",
      doctor_certificate: "你有医疗证明吗？",
      pass_affection:
        "您过去是否有Covid-19（冠状病毒）感染史？",
      prev_destination: "上一个目的地",
      next_destination: "下一个目的地",
      transportation: "运输",
      internation_travel: "这是国际旅行吗？",
      domestic_travel: "这是国内旅行吗？",
      foreign_travel: "国外旅行经验",
      close_contact_corona_patient:
        "与电晕感染患者密切接触的存在/不存在和持续时间",
      relationship_with_main_visitor: "与主要访客的关系",
      reservation_no: "预约号码",
      signature: "簽名",
    },
  },
  "zh-TW": {
    labels: {
      full_name: "全名",
      first_name: "名稱 : 名字",
      last_name: "名稱 : 姓",
      address: "地址",
      phone: "電話",
      nationality: "國籍",
      email: "Email",
      age: "年齡",
      dob: "生日",
      gender: "性別",
      occupancy: "職業",
      gov_id: "護照/身份證",
      gov_id_number: "護照/身份證號碼",
      body_temperature: "今天的體溫",
      coughing: "你咳嗽嗎?",
      doctor_certificate: "你有醫生證明嗎？",
      pass_affection:
        "您是否有過去的nCov-19（冠狀病毒）感染史?",
      prev_destination: "上一個目的地",
      next_destination: "下一個目的地",
      transportation: "運輸",
      internation_travel: "這是國際旅行嗎?",
      domestic_travel: "這是國內旅行嗎?",
      foreign_travel: "國外旅行經驗",
      close_contact_corona_patient:
        "與電暈感染患者密切接觸的存在/不存在和持續時間",
      relationship_with_main_visitor: "與主要訪客的關係",
      reservation_no: "預約號碼",
      signature: "签名",
    },
  },
};

export const months = [
  {
    long_name: "January",
    short_name: "Jan",
    number_name: "01",
  },
  {
    long_name: "February",
    short_name: "Feb",
    number_name: "02",
  },
  {
    long_name: "March",
    short_name: "Mar",
    number_name: "03",
  },
  {
    long_name: "April",
    short_name: "Apr",
    number_name: "04",
  },
  {
    long_name: "May",
    short_name: "May",
    number_name: "05",
  },
  {
    long_name: "June",
    short_name: "Jun",
    number_name: "06",
  },
  {
    long_name: "July",
    short_name: "Jul",
    number_name: "07",
  },
  {
    long_name: "August",
    short_name: "Aug",
    number_name: "08",
  },
  {
    long_name: "September",
    short_name: "Sept",
    number_name: "09",
  },
  {
    long_name: "October",
    short_name: "Oct",
    number_name: "10",
  },
  {
    long_name: "November",
    short_name: "Nov",
    number_name: "11",
  },
  {
    long_name: "December",
    short_name: "Dec",
    number_name: "12",
  },
];

export const payment_status = {
  not_paid: 1,
  paid: 2,
  refunded: 3,
  partly_paid: 4,
  partly_refunded: 5,
  cancelled: 6,
  paid_authorized: 7,
  paid_captured: 8,
  pay_at_reception: 9,
  credit_card_as_deposit: 10,
  paid_after_credit_card_deposit: 11
};

export const paid_payment_status = [
  payment_status.paid,
  payment_status.paid_authorized,
  payment_status.paid_captured,
  payment_status.paid_after_credit_card_deposit
];

export const payment_gateway = {
  stripe: "stripe",
  reception: "Pay at hotel reception",
  payLater: "Pay Later",
  fincode: "Fincode",
};

export const booking_status = {
  reserved: 1,
  checked_in: 2,
  checked_out: 3,
  cancelled: 4,
  reservation_changed: 5,
  confirm_before_check_in: 6,
  need_revise_before_checked_in: 7,
  hold_before_checkout: 8,
  ready_to_pay_checkout: 10,
  checkout_by_guest: 10,
  checkout_completed: 11,
  checkout_by_expiration: 12,
  pre_check_in: 13,
  check_in_hold: 14,
  check_out_hold: 15,
  check_out_payment: 16,
  check_out_completed_by_system: 17,
};

export const checkout_groups = [
  booking_status.checked_out,
  booking_status.hold_before_checkout,
  booking_status.ready_to_pay_checkout,
  booking_status.checkout_by_guest,
  booking_status.checkout_completed,
  booking_status.checkout_by_expiration,
  booking_status.check_in_hold,
  booking_status.check_out_hold,
  booking_status.check_out_payment,
  booking_status.check_out_completed_by_system
];

export const visitor_payments_date_filter = require("./json/visitor-payments-date-filter.json"),
visitor_payments_payment_statuses_filter = require("./json/visitor-payments-payment-statuses-filter.json"),
visitor_payments_booking_statuses_filter = require("./json/visitor-payments-booking-statuses-filter.json");

export const flags = {
  en:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAANCAYAAABcrsXuAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAFgSURBVDhPrZO9SgNBEMdnb4OVhSARSWE6IyJKFEUDGqysTeMreFa+g/E1BBEEC0sxQkTBlxDFNziiBlSIYceZyS53Ob2Dxfwu3O78mcxnovaPrhAgAIYuoFInIsLCzQnMRo9kZcP+v7Ci2j28xOLkuART9GitwNA9UORBn37fQGNtGuqLU4NveGKMARU2WxTddkKlc2zXCtucvPpwDnPvL+Lji4Q6OG5x3bHAp03GCfi+V5+B2nxRfHyRCYWUJHsn9BiApbtTqHSeSPFHlrATXmC5NEHBuHygnQSSXXZCGNIbm2XYXi6JnY8rMWbQSfMaUWmn0Ct25DdL1fszqLw+i+aLROy8faAu2CQZjJlv0EBzy8PVl4InpNor66hTLY4Syf3V7WKBO0nm4RlRVTI9x7BhTyIpOyOhyf/kdnUDg6EAFpb+aN8XCfMZRVjQ+Tv5D/zrVO3aFkKvZ6XRg0rBD8hLmei9hc7TAAAAAElFTkSuQmCCyRixTKNuqwGffNOK56sz8NEXzXhxRxbe/PgyQpRKqDc9IbkOLlwuWpHKw18LKpUCI2a7mPO9d8Ypyqr5aOvUMJpsMOhUXh4kTppnPfFtkZVQGttwYPKq5Dq4cNMahR6qP3oiRx8LZZQMRXkauCj3dpanYpCKvv7ZXHRSWr26Px9N7SM4frgQf1y/h7fqN+NKyzDeJv6d+NhLhei/3Axd5xW4xmxBlznbGLjahpNCtFqBIfpwPUW8s9eCDRlrcb17lDgWN29ZkJsegxs9FuRlxMzrBuLbxHpiqo8c0tfJgddrkqUYBRc8K/wMSiOVUoFIeZiYKlNUD/k0gT5KsYpNiVTYNmwr0qGtx4yaslSaoBm7tqShlSa6syIVLV2j2F6aIk5IYTBIroMLnnYvru6174SY6Cg6PyapBtTooEiz4m/p+gsFWWxlzKTHorXLjI3EbEJspdpumZGX7tVx7Sp2m85JroMLgWokJDlhDRI1CqTQdptErI2NBFulSPkqbM7RIDSUQ0l+vFg7lUVJmJnlsbVYB8eMC9uKSadte0thIgSnEy6L9T+RORKuYF+jsDE7nnYipRjlolwtLl27g6efTMEvl3qxp0qPny72orYyDafOG/HM1nScumBEXZUBp4n3EH97thuFBhVOHMzzhMj3IA4IzxB/Z8qis2IZfyy1QlhjZtaFAbpysHOjd2gcsy43BuiKYnfM4Z55GrYpJ8x0SI5NOGGdcMBK1xnrBAmxZdwhrk6IPAJyrXZe4uK8bVE8+mK7jHTZgr54DOtbsC34W8pe4d748JwQp1GJp3ZhtgYXmwbFYj55tgcHd63HVz934tDubDT+eBP1e/Pw2Q83aFvOw6ce/p5xLv788jS29/8mRijYcJOEarJrT4xYWYSddHLbxcujhaLPTne7YxZ9QxNwUl2w3ctNd5iOXqv443Yj5SUtN9OZ3d7RhUxTM/gpe9DFZZ8Gd9s0KshkMvHjPHjAFF+EcPccFLxD0hZl+BL8U9+/ATe//TocDkEmlwV2T0YW+Yf96+WmwhDQJ+vwDJLg8efPL083YO78lm0CuzqvZLAa4X7NzP1/TMQ2NLS4Rtik/M2L2ZcstxBgrGi/D2ys38FSYwkCjfVj5tnDyrdGGHzH+eajv9z0wF/fcv4YAvl8WLtYIxeqqim12OI8IJgnz1c+JhBT68z6DQJ7+65kiBOxdHcLsvDwecsKBXuPiDUil9OraAVDfLNfrK4ROPuUZFqZYG927mxJuYCJSckkwd/24GvztB8Tmxsc/gYk0JTPHQtbjwAAAABJRU5ErkJggg==",
  ja:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAANCAYAAABcrsXuAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAEmSURBVDhPY/wPBAw0BsRZ8u8/w8s1uxher93D8PPpSwY2SVEG0UBnBvEIDwZGJiaoItyAoCV/v3xjuBRUyPBu93GoCAIIOpgw6G2cxMDCxwMVwQ4IOuNGehNWC0Dg/YEzDNeT6qA83ACvJV9v3Gd4sXw7lIcdvAIG4ecLN6E87ACvJe/3nmRgICLKwOrwALyW/Pn4GcrCD/58+gJlYQd4LeFUloWy8ANOJRkoCzvAa4mIly0DiyAflIcdsPByM4j62kN52AFeS5iBBqhPrgQmdEaoCBoACqv2lzGwCPFDBbADvJaAgES0N4PO8k4GNnFhqAgEsIoIMmgvbGWQSg6EiuAGRBcr/37+Yvhw+Bwwx79iYAfmeAFbIwYmTnaoLH5Ah7KLgQEAY+dZLrBRQzUAAAAASUVORK5CYIJRqvhw38USifjnqeLza2ZBbETy6w5L5HcoTBUffTNNxGLUHR+WiJqmUcVHP7YoFvmjC0vEVpBLFZ+U/Gwoqkrd8WGJnL1RQhUf7rtYItn1d6jiw30XS8RR6kV6ZRl18jiuFyPjdjl1crBEdC53tUBNlT+Om6wWuF8+FrNdoUQOtkiq+yI83a1i1ZGYqCYF7tdPYC/xUCAPW0Qns7YK3v5OmJ1plByOqqWg+G0bchprKOFh6A1xayGAr4+eY+Xdx/3PYOIruKpvorCjGdqlCxTyMVRkl63FAIKDI+Lert/ZxRFf/MPund1Vc0vsP3n0pHGciMjfwJA58i9wSkSAHQBqsyjHZzkuAAAAAElFTkSuQmCC",
};

// Main form methods
import _ from "lodash";
import moment from "moment-timezone";
import t from "typy";

// TODO: Move these methods to Vue mixins
export const main_form_methods = {  

  isFixGuestEnabled() {
    return this.fixGuestSetting.fix_guest_count.status === true
  },
  async populateCompanionsFields() {
    if (await this.fixGuestSetting && await this.fixGuestSetting.fix_guest_count && await this.fixGuestSetting.fix_guest_count.status) {
        if (this.fixGuestSetting.fix_guest_count.status && this.fixGuestCount > 1) {
          // Only add companions if needed
          while (this.companions.length < this.fixGuestCount - 1) {
            this.companions.push({
              nationality: "",
              other_forms: {},
            });
          }
        }
      }
  },

  showLabelOrPlaceholder(
    translation,
    index,
    current_placeholder,
    position,
    multiple,
    multiple_index
  ) {
    const fname = this.questions.find((x) => x.system_name == "full_name");
    if (fname != undefined)
      if (!multiple) {
        if (t(this.companions[index].other_forms[fname.id]).isDefined)
          if (
            t(this.companions[index].other_forms[fname.id]).isEmptyString ==
            false
          )
            if (position == "placeholder")
              return `${_.truncate(
                this.companions[index].other_forms[fname.id],
                { length: 10 }
              )} ${current_placeholder}`;
            else if (position == "dropdown")
              return `${_.truncate(
                this.companions[index].other_forms[fname.id],
                { length: 10 }
              )}`;
      } else {
        if (
          t(
            this.multiple_data[multiple_index].companions[index].other_forms[
            fname.id
            ]
          ).isDefined
        )
          if (
            t(
              this.multiple_data[multiple_index].companions[index].other_forms[
              fname.id
              ]
            ).isEmptyString == false
          )
            if (position == "placeholder")
              return `${_.truncate(
                this.multiple_data[multiple_index].companions[index]
                  .other_forms[fname.id],
                { length: 10 }
              )} ${current_placeholder}`;
            else if (position == "dropdown")
              return `${_.truncate(
                this.multiple_data[multiple_index].companions[index]
                  .other_forms[fname.id],
                { length: 10 }
              )}`;
      }

    if (position == "placeholder")
      return `${translation} ${index + 1} ${current_placeholder}`;
    else if (position == "dropdown") return `${translation} ${index + 1}`;
  },
  changeChoices(choices) {
    if (t(sys_choices, this.$i18n.locale).isDefined)
      return sys_choices[this.$i18n.locale][choices];
    // this.$i18n.locale = "en";  <-- Finding this bug cost me some hairs. (Mike)
    return sys_choices["en"][choices];
  },
  getChoice(code, val) {
    const obj = sys_choices[this.$i18n.locale][code].find(
      (obj) => obj.value == val
    );
    if (obj == undefined)
      return sys_choices[this.$i18n.locale][code].find((obj) => obj == val);
    return obj.value;
  },
  copyFromMain(qid, obj, from, e) {
    if (from.type == "visitor") {
      return this.$set(obj, qid, from.obj);
    }
    this.$set(obj, qid, from[qid]);
  },
  formatDate(date) {
    if (date == null) return "";
    const [d, m, y] = date.split(".");
    date = `${m}/${d}/${y}`;
    const timezone = moment.tz.guess();
    return moment.tz(date, timezone).format("MM/DD/YYYY");
  },
  formatDateHMA(date, timezone = null) {
    if (date == null) return false;
    return moment.tz(date, timezone).format("MM/DD/YY hh:mm A");
  },
  formatDateFacilityTimezoned(date, timezone = null) {
    const hasTz = _.has(window._app_facility.timezone, 'value');
    const defaultLang = 'ja';
    const timeZone = (hasTz) ? window._app_facility.timezone.value : 'Asia/Tokyo';
    const formatter = this.$i18n.locale == defaultLang ? 'YYYY/MM/DD' : 'MM/DD/YYYY';

    return moment.tz(date.toString(), timeZone).format(formatter);
  },
  loadAgeDropdown(obj, key) {
    this.$set(obj, key, 35);
  },
};

export const startCameraStream = async (cameraId, constraints) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    window.stream = stream; // make stream available to browser console
    const videoScreen = document.getElementById(cameraId);
    videoScreen.srcObject = stream;
    videoScreen.onloadedmetadata = async (event) => {
      try {
        await event.target.play();
      } catch (err) {
        console.error(err);
      }
    };
  } catch (err) {
    console.log(err);
    alert("Can not get camera devices.");
  }
};

export const stopCameraStream = (cameraId) => {
  let tracks = document.getElementById(cameraId).srcObject.getTracks();

  tracks.forEach((track) => {
    if (track.readyState == "live") {
      track.stop();
    }
  });
};

export const captureImage = (canvasId, cameraId) => {
  const canvas = document.getElementById(canvasId);
  const camera = document.getElementById(cameraId);

  canvas.width = camera.videoWidth;
  canvas.height = camera.videoHeight;

  const context = canvas.getContext("2d");
  context.imageSmoothingEnabled = true;
  context.imageSmoothingQuality = "high";

  // capture image from camera
  context.drawImage(camera, 0, 0);

  return canvas.toDataURL("image/jpeg", 0.5);
};


export const convertImageToBase64 = (file) => {
   return new Promise((resolve,reject) => {
      let reader = new FileReader();
      reader.onload = (evt) => {
         resolve(evt.target.result);
      };
      reader.readAsDataURL(file)
   });
}
