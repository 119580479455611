<template>
  <div id="dashboard_wrapper">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t("account-page-notif-control.other_settings.title") }}
      </h4>
      <br />
      <p> {{ $t( "account-page-notif-control.other_settings.first_paragraph" ) }} </p>
      <hr />
      <p> {{ $t( "account-page-notif-control.other_settings.second_paragraph" ) }} </p>
      <div class="alert alert-success" role="alert" v-if="loading">
        {{ $t( "account-page-notif-control.other_settings.success_msg" ) }} </div>
      <div class="d-flex flex-row">
        <div>
          {{
            $t(
              "account-page-notif-control.other_settings.switch_btn_label"
            )
          }}
        </div>
        <div class="pl-2">
          <toggle-button
            v-model="display_pass_email.value.visibility"
            :labels="{ checked: 'On', unchecked: 'Off' }"
            :value="display_pass_email"
            :sync="true"
          
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-12" align="center">
          <button
            @click.prevent="
              $router.push({
                name: 'checkin-facility-dashboard-notif-control',
              })
            "
            class="btn btn-danger ml-3"
          >
            {{
              $t(
                "account-page-notif-control.other_settings.back_btn"
              )
            }}
          </button>
          <button
            @click="$bvModal.show('modal-confirmation')"
            type="button"
            class="btn btn-primary ml-3"
          >
            {{
              $t(
                "account-page-notif-control.other_settings.confirm_btn"
              )
            }}
          </button>
        </div>
      </div>
    </div>
    <confirmation-modal-component
      @click-handler="submit"
    ></confirmation-modal-component>
  </div>
</template>
<script>
  import _ from 'lodash'; 
  import ConfirmationModalComponent from "./../../components/confirmation-modal.component";

  export default {
    components: {
      ConfirmationModalComponent
    },
    data() {
      return {
        loading: false,
        facility: {},
        display_pass_email: {
          facility_id: 0,
          name: 'display_pass_email',
          value: {
            visibility: true
          }
        },
        error: false,
        success: false,
      }
    },

    async mounted() {
      this.facility = window._app_facility
      this.display_pass_email.facility_id = this.facility.id
      const search = {
        name: this.display_pass_email.name,
          facility_id: this.display_pass_email.facility_id,
          paginate: false
        }

      const displayPass = await this.$lionheart.post('/facility-setting/list', {
        ...search
      })
      .then(r => this.catcher(r, 'data.total', r => r.data.total > 0 ? r.data.data[0] : r.data))
      .catch(e => this.catcher(e));

      if(displayPass.error.has_error) return; // Silent API error catch

      if(displayPass.total == 0) return;

      this.display_pass_email.value.visibility = displayPass.value.visibility;
    },

    methods: {
      async submit() {
        this.loading = true;
        const data = {
          ...this.display_pass_email
        }
                
        const res = await this.$lionheart.post('/facility-setting/store', {...data})
        .then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
        .catch(e => this.catcher(e))

        this.success = res.error.has_error == false;

        location.href = "#dashboard_wrapper"
        setTimeout(() => {
          this.loading = false
        }, 3000);
      }
    }
  }
  </script>