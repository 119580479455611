import {Vue2Storage} from 'vue2-storage'
import Vue from 'vue'
import Store from '@/services/store'
Vue.use(Vue2Storage)

import { lionheart } from '@/modules/checkin-lionheart/module/client'
import mixin from '@/modules/checkin-lionheart/module/mixin'
const catcher = mixin.methods.catcher

import Login from './login/login.vue'
import VerifyEmail from './verify-email/verify-email.vue'
import Register from './register/register.vue'
import RegisterSuccess from './register/register_success.vue'
// import MainForm from './checkin/core/main_form.vue'
import SkipForm from './checkin/core/skip_form.vue'
// Hotel Form
import MainHotelForm from './checkin/hotel/main_form.vue'
import MainHotelCheckoutForm from './checkin/hotel/main_checkout_form.vue'

import CheckinSuccess from './checkin/core/success.vue'
import DashboardIndex from './dashboard/index.vue'
import DashboardRoutes from './dashboard/routes.js'
import PasswordReset from './password-reset/password-reset.vue'
import PasswordResetSuccess from './password-reset/password-reset-success.vue'
import NewPassword from './password-reset/new-password.vue'

import TouchDown from './checkin/components/TouchDown';
import QuickCheckout from './dashboard/quick-checkout-form/index.vue'
import QuickCheckin from './dashboard/quick-checkin-form/index.vue'
import QuickService from './dashboard/quick-service-form/index.vue'
import Maintenance from './maintenance/index.vue';
import FincodePaymentIframe from './checkin/components/fincodePaymentIframe.vue'

export const checkDashboardAuth = async (next) => {
	if (!Vue.$storage.has('auth')) return next({name: 'checkin-facility-login'})

	const user = Vue.$storage.get('auth')
	const facility = await lionheart.post('/facility/list', {user_id: user.id, my_page:true})
		.then(r => catcher(r.data, 'data[0].id', r => r.data[0]))
		.catch(e => catcher(e))

	if (facility.error.has_error) return next({name: 'checkin-facility-login'})

	window._app_facility = Object.assign({
		has_pms: (facility.facility_setting.length > 0) ? true: false				
	}, facility)

	Object.freeze(window._app_facility)
	Store.dispatch("checkin-facility/setFacility", window._app_facility);
	next()
}

export default [
	{  path: `login`, name: 'checkin-facility-login', component: Login, 
		beforeEnter: (to, from, next) => {
			if (Vue.$storage.has('auth')) return next({name: 'checkin-facility-dashboard-home'})
			next()
		}
	},
	{  path: `email-verification`, name: 'checkin-facility-email-verify', component: VerifyEmail },
	{  path: `register`, name: 'checkin-facility-register', component: Register },
	{  path: `register-success`, name: 'checkin-facility-register-sucess', component: RegisterSuccess },
	{  path: `:slug_name/checkin-core`, name: 'checkin-facility-checkin', component: MainHotelForm },
	{  path: `:slug_name/checkout-core`, name: 'checkin-facility-checkout', component: MainHotelCheckoutForm },
	{  
		path: `:slug_name/checkin-core/touch-down`, 
		name: 'checkin-facility-touch-down', 
		component: TouchDown 
	},
	{  path: `:slug_name/checkin-skip`, name: 'checkin-facility-checkin-skip', component: SkipForm },
	{  path: `:slug_name/checkin-success`, name: 'checkin-facility-checkin-success', component: CheckinSuccess },
	{  path: `password-reset`, name: 'checkin-facility-password-reset', component: PasswordReset },
	{  path: `password-reset-success`, name: 'checkin-facility-password-reset-success', component: PasswordResetSuccess },
	{  path: `new-password`, name: 'checkin-facility-new-password', component: NewPassword },
	{  path: `maintenance`, name: 'checkin-maintenance', component: Maintenance },
	{  path: `quick-checkout`, name: 'checkin-facility-quick-checkout', component: QuickCheckout,
		beforeEnter: async (to, from, next) => {
			await checkDashboardAuth(next)
      	}
	},
	{  path: `quick-checkin`, name: 'checkin-facility-quick-checkin', component: QuickCheckin,
		beforeEnter: async (to, from, next) => {
			await checkDashboardAuth(next)
      	}
	},
	{  path: `quick-service`, name: 'checkin-facility-quick-service', component: QuickService,
		beforeEnter: async (to, from, next) => {
			await checkDashboardAuth(next)
      	}
  	},
	// Dashboard
	{  path: `dashboard`, redirect: {name: 'checkin-facility-dashboard-home'}, component: DashboardIndex,
		children: DashboardRoutes,
		beforeEnter: async (to, from, next) => {
			await checkDashboardAuth(next)
      	}
  	},
	{ path: `:slug_name/fincode-iframe`, name: 'fincode-payment-iframe', component: FincodePaymentIframe }
]
