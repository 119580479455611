import Index from '../pages/index.vue'
import PagesRoutes from '../pages/routes'

const prefix = '/'

export default [
	{ 
		path: `/${prefix}/`, redirect: {name: 'checkin-facility-login'}, component: Index,
		children: PagesRoutes
	},
]