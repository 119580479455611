<template>
    <div class="mt-n2">
        <div class="panel-header">
            <b>{{ $t("checkout_details.title") }}</b>
            <div></div>
        </div>
        <div class="panel-body">
            <table class="table table-sm table-bordered w-100 mb-0">
                <tr>
                    <td>{{ $t("checkout_details.checkout-date") }}</td>
                    <td>{{ checkoutDate() }} <pms-badge v-if="isPmsCheckout" /></td>
                </tr>
                <tr>
                    <td>{{ $t("checkout_details.booking_status") }}</td>
                    <td>
                        {{ $t(`booking_statuses.${bookingDetails.booking_status.code}`) }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $t("checkout_details.receipt") }}</td>
                    <td>
                        <template v-if="showReceipt">
                            <a href="#" @click.prevent="viewReceipt">
                                {{ $t("checkout_details.view_receipt") }}
                            </a>
                        </template>
                        <template v-else>
                            {{ $t("checkout_details.not_available") }}
                        </template>
                    </td>
                </tr>
            </table>
        </div>
        <pdf-viewer v-if="receipt.src" :src="receipt.src" />
    </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { booking_status as bookingStatuses, payment_status as paymentStatuses, checkout_groups as checkoutGroups } from "../../../../utils/mockup";
import PmsBadge from "../PmsBadge";
import PdfViewer from "../PdfViewer";

export default {
    components: { PmsBadge, PdfViewer },
    props: {
        visitorLog: null,
        currentTab: { type: String, default: "reservation" },
    },
    data() {
        return {
            //Data
            facility: null,
            pms_code: null,
            visitor: null,
            paymentStatuses,
            bookingStatuses,
            booking: {
                booking_status: {
                    name: "--",
                },
                booking_status_id: 0,
                checkout_date: moment().format("YYYY/MM/DD"),
                checkout_time: moment().format("HH:mm:ss"),
            },
            //Form
            receipt: {
                src: "",
            },
        };
    },
    computed: {
        bookingDetails() {
            return this.visitorLog.booking_details;
        },
        bookingLabel() {
            if (this.booking.booking_status_id == 0) return "--";

            return this.$t(`booking_statuses.${this.booking.booking_status.code}`);
        },
        isPmsCheckout() {
            const bookingDetails = this.visitorLog.booking_details;
            return bookingDetails.hasOwnProperty("condition_metadata") && bookingDetails.condition_metadata !== null && bookingDetails.condition_metadata.is_pms_checkout === 1;
        },
        showReceipt() {
            const isPaid = [this.paymentStatuses.paid_authorized, this.paymentStatuses.paid_captured].includes(this.bookingDetails.payment_status.id);
            const isCheckout = checkoutGroups.includes(this.bookingDetails.booking_status.id);

            return isPaid && isCheckout;
        },
    },
    async mounted() {
        this.facility = window._app_facility;
    },
    methods: {
        async viewReceipt() {
            const data = {
                facility_id: this.facility.id,
                facility_visitor_log_id: this.bookingDetails.facility_visitor_log_id,
            };

            const receipt = await this.$lionheart.get("/facility-key/f/checkout/receipt", { params: data });
            if (receipt.data && receipt.data.data) {
                this.receipt.src = receipt.data.data;
                setTimeout(() => this.$bvModal.show("modal-pdf-viewer"), 300);
            }
        },
        checkoutDate() {
            if (checkoutGroups.includes(this.bookingDetails.booking_status.id)) {
                return this.formatDate(this.visitorLog.checkout_date);
            } else {
                return "--";
            }
        },
        formatDate(date) {
            return moment(date).format("MM/DD/YY hh:mm A");
        },
    },
};
</script>
