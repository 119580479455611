import moment from 'moment-timezone'
import { nationalities } from '../../../utils/mockup'

export default {
	data() {
		return {
			submit_btn: '',
			error_msg: '',
			confirm: false,
			email_exists: false,
			facility: {
				name: '...'
			},
			data: {
				nationality: '',
				email : ''
			},
			companions: [],
			form: [{
					label: 'purpose',
					value: '',
					page_info: 'visit-info',
				}, {
					label: 'transportation',
					value: '',
					page_info: 'visit-info',
				},{
					label: 'from-where',
					value: '',
					page_info: 'visit-info',
				},{
					label: 'to-where',
					value: '',
					page_info: 'visit-info',
				},{
					label: 'exp-travel-in-3weeks',
					value: 'No',
					page_info: 'visit-info',
				},{
					label: 'receive-email',
					value: 'Yes',
					page_info: 'visit-info',
				}]
			}
	},
	async mounted() {
		this.facility = await this.$lionheart.post('/facility/list', {slug_name: this.$route.params.slug_name})
			.then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
			.catch(e => this.catcher(e))
		if (this.facility.error.has_error) return this.$router.push('/404')

		if (!this.$storage.has('visitor')) {
			return this.$router.push({
				name: 'checkin-facility-checkin',
				params: {slug_name: this.$route.params.slug_name}
			})
		}
	},
	methods: {
		async submitForm(e) {
			e.preventDefault()
			
			if (this.submit_btn == 'confirm' 
				&& this.email_exists == false) {
				return this.confirm = true
			}

			if (this.submit_btn == 'edit') {
				return this.confirm = false
			}

			if (this.submit_btn == 'submit' ) {
				const visitor = this.$storage.get('visitor')

				//Visitor Log
				const visitor_log = await this.$lionheart.post('/log/facility-visitor', {
						facility_id: this.facility.id,
						visitor_id: visitor.id,
						timezone: moment.tz.guess()
					})
					.then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
					.catch(e => this.catcher(e))
				if (visitor_log.error.has_error) return this.errorDisplay(visitor_log)

				//Visitor Other Form Log
				const visitor_other_log = await this.$lionheart.post('/log/visitor-other-form', {
					data: this.form.map(d => Object.assign(d, { 
							facility_visitor_log_id	: visitor_log.id
						}))
					})
					.then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
					.catch(e => this.catcher(e))
				if (visitor_other_log.error.has_error) return this.errorDisplay(visitor_other_log)				

				// Companion Log
				if(this.companions.length > 0) {
					const companions = await this.$lionheart.post('/log/visitor-companion', { 
							data: this.companions.map(d => Object.assign(d, {
								contact_info: d.contact_info || 'n/a',
								facility_visitor_log_id: visitor_log.id
							})) 
						})
						.then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
						.catch(e => this.catcher(e))
					if (companions.error.has_error) return this.errorDisplay(companions)
				}

				this.$router.push({
					'name' : 'checkin-facility-checkin-success', 
					params: { slug_name: this.$route.params.slug_name}
				})
			}
		},
		addCompanion(e) {
			e.preventDefault()
			this.companions.push({
				nationality: ''
			})
		},
		removeCompanion(i) {
			this.companions.splice(i, 1)
		},
		changeNationality() {
			return nationalities[this.$i18n.locale]
		},
		errorDisplay(d) {
			this.error_msg = d.error.msg
			setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
			return
		}
	}
}