import t from "typy";
import moment from "moment-timezone";

export default {
  methods: {
    catcher(_data, objname, func) {
      objname = null || objname;
      let error = { has_error: false, msg: "" };

      try {
        if (objname != null) {
          if (t(_data, objname).isDefined == false) throw undefined;
        }

        const data = func(_data);
        return { error, ...data };
      } catch (e) {
        console.log(`Something went wrong (${e})`);
        error.has_error = true;
        error.msg = "An error occured. Please try again later.";

        return { error };
      }
    },
    formatNumber(data) {
      var number = Math.round(parseFloat(data) * 100) / 100;

      number = number.toFixed(2);
      return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    formatDateHMSA(date_time, format, tz = null) {
      const timezone = tz ? tz : moment.tz.guess();
      return moment.tz(date_time, timezone).format(format);
    },
    formatDate(date_time, format) {
      const dateHandle = moment(date_time);
      return dateHandle.isValid() ? dateHandle.format(format) : "--";
    },
    hasError(errors, key) {
      let error = "";
      if (errors[key] && errors[key][0]) {
        error = errors[key][0];
      }
      return error;
    },
    async toBase64(file) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      return await toBase64(file);
    },
    isValidFormat(date) {
      let regex = new RegExp("([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})");
      let okay = regex.test(date);
      let status = false;
      if (okay) {
        status = true;
      } else {
        status = false;
      }
      return status;
    },
  },
};
