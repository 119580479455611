<template>
    <b-modal
    size="md"
    id="modal-confirmation-separately"
    centered
    hide-footer
    hide-header
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop>
        <div class="row ">
            <div class="col-lg-12 text-center p-3">
                <h5>{{ $t('visit-information-form.you-did-not-check-in') }}</h5>
            </div>
            <div class="col-lg-12 mb-4">
                <div class="text-center mt-5">
                    <button
                    @click="confirm"
                    type="button"
                    class="btn btn-primary mr-3">
                        {{ $t('visit-information-form.confirm') }}
                    </button>
                    <button
                    @click="back"
                    type="button"
                    class="btn btn-danger">
                        {{ $t('visit-information-form.back') }}
                    </button>
                </div>
            </div>

        </div>
    </b-modal>
</template>

<script>
    export default {
        data(){
            return {
                facility: {},
            }
        },
        mounted() {
            this.facility = window._app_facility;
        },
        methods: {
            async confirm() {
                this.$bvModal.hide('modal-confirmation-separately');
            },
            async back() {
                this.$emit("update-confirm", false);
                this.$bvModal.hide('modal-confirmation-separately');
            }
        },
    }
</script>
