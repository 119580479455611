import t from 'typy'
import _ from 'lodash'; 
import moment from 'moment-timezone'
import { nationalities, sys_languages } from '../../../utils/mockup'
import Datepicker from 'vuejs-datepicker';

import facilityName from'../components/facilityNames'
import facilityOption from'../components/facilityOptions'
import facilityOtherEmail from'../components/facilityOtherEmails'

export default {
	components: {
		Datepicker,
		facilityName,
		facilityOption,
		facilityOtherEmail
	},
	data() {
		return {
			submit_btn: '',
			error_msg: '',
			updated: 0,
			confirm: false,
			email_exists: false,
			navs: {
				main: 1,
				facility_name: 0,
				facility_option: 0,
				facility_email: 0,

			},
			facility: {
				name: '...'
			},
			user: {
				email: ''
			},
			timezones: [],
			timezone: {
				name: "timezone",
			},
		}
	},
	async mounted() {

		const auth = this.$storage.get('auth')
		let facility = await this.$lionheart.get('/v2/facilities', { params: { user_id: auth.id, with: 'user' } })
			.then(r => r.data.data[0])
			.catch(e => console.log(e))
		
		let user = facility.user
		delete facility.user

		this.facility = facility
		this.user = user
		await this.init_timezones();
		if (t(this.$route.query, 'updated').isDefined) this.updated = 1
	},
	methods: {
		async checkEmail(e) {
			if (this.user.email == '') return

			const data = {
				email: this.user.email,
				id: this.facility.id,
				type: 'facility'
			}

			const email = await this.$lionheart.post('/user/exists', data)
				.then(r => this.catcher(r.data,'data.exist', r => r.data));

			this.email_exists = email.exist
		},
		async submitForm(e) {
			e.preventDefault()
			
			if (this.submit_btn == 'confirm' 
				&& this.email_exists == false) {

				this.confirm = true
				return
			}

			if (this.submit_btn == 'edit') {
				this.page = 1
				return this.confirm = false
			}

			if (this.submit_btn == 'submit') {

				await this.$lionheart.post('/v2/facility-settings',this.timezone);

				const fe = ['id', 'error', 'updated_at', 'created_at'] // To remove
				let data = _.cloneDeep(this.facility);
				data = Object.assign({ facility_id: data.id, user_id: this.user.id, email: this.user.email }, data);
				fe.map(obj => delete data[obj]);
				
				const facility = await this.$lionheart.put(`/v2/facilities/${this.facility.id}`, data)
					.then(r => r.data)

				window.location = window.location.origin + this.$route.path + '?updated=1'
			}
		},
		selectTab(name) {
			Object.keys(this.navs).map(obj => this.navs[obj] = 0)
			this.navs[name] = 1
		},
		changeNationality() {
			const country = this.facility.country;
		
			if (country != undefined) {
				// Translate country record into different language unpon language selection
				let item = Object.values(nationalities).find(obj => (obj.find(i => i.Nationality == country)) !=undefined).find(obj => obj.Nationality == country);
				this.facility.country = nationalities[this.$i18n.locale].find(obj => obj.CountryCode == item.CountryCode).Nationality;
			}
		
			return nationalities[this.$i18n.locale]
		},
		formatDate(date) {
			if (date == null) return ''
			const timezone = moment.tz.guess()
			return moment.tz(date, timezone).format('MM/DD/YYYY')
		},
		async init_timezones(){
			this.timezone = await this.$lionheart.get('/v2/facility-settings' ,{params: {facility_id: this.facility.id, name: 'timezone', paginate: 0}})
				.then(r => r.data.data.length > 0 ? r.data.data[0] : {...this.timezone, facility_id: this.facility.id} )

			this.timezones =	await this.$lionheart.get('/v2/lookup/timezones' )
				.then(r => r.data.data.data)
				.catch(e => this.catcher(e));
		}
	}
}