<!-- call message component -->
<template>
   <div>
      <div class="d-flex justify-content-between">
         <div>
            <router-link :to="{ name: 'checkin-facility-dashboard-my-page-message-board-index' }">
               <i class="fa fa-arrow-left"></i>
               {{ $t("facility_my_page_message_board.go_back_text_button") }}
            </router-link>
         </div>
         <div class="mb-1">
            <b-button squared variant="primary" @click.prevent="showMainDetailModal = true">{{ $t("facility_my_page_message_board.view_details_text_button") }}</b-button>
         </div>

         <GuestDetailsModal
            v-if="loadedState"
            :showModal="showMainDetailModal"
            :visitor_log_id="bookingDetails[0].facility_visitor_log_id"
            @toggle="(val) => (showMainDetailModal = val)"
         />
      </div>
      <b-card>
         <Messages :parameter="parameter" :visitor="visitor" v-if="loadedState" />
      </b-card>
   </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Messages from "@/modules/checkin-facility/pages/dashboard/components/messaging/content/Messages";
import GuestDetailsModal from "@/modules/checkin-facility/pages/dashboard/components/messaging/GuestDetailsModal";

export default {
   components: { Messages, GuestDetailsModal },
   data() {
      return {
         loadedState: false,
         parameter: {},
         showMainDetailModal: false,
         visitor: {},
      };
   },
   computed: {
      ...mapGetters("checkin-facility", {
         bookingDetails: "gettersBookingDetails",
      }),
   },
   async mounted() {
      this.parameter = this.$route.query;
      this.visitor = {
         full_name: this.$route.query.fname,
      };
      let params = {
         email: this.parameter.e ?? this.parameter.em,
         pass: this.parameter.p,
         facility_id: window._app_facility.id,
         mode: "pre-checkin",
      };
      let bookingDetails = await this.getBookingDetails(params);

      this.loadedState = true;
   },
   methods: {
      ...mapActions("checkin-facility", {
         getBookingDetails: "actionGetBookingDetails",
      }),
   },
};
</script>
