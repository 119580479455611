import { BootstrapVue } from 'bootstrap-vue'
import {Vue2Storage} from 'vue2-storage'
import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import Pagination from 'vue-pagination-2';
import VueGtag from "vue-gtag";
import ImageUploader from 'vue-image-upload-resize'
import ToggleButton from 'vue-js-toggle-button'
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import VueTelInput from 'vue-tel-input'
import VueSignaturePad from 'vue-signature-pad';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export const eventBus = new Vue();

Sentry.init({
  dsn: 'https://2bf101cbc3b34ffdbf7bee90b3fe98e8@o411423.ingest.sentry.io/5286678',
  integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
  environment: process.env.VUE_APP_SENTRY_ENV
});
 
Vue.use(BootstrapVue)
Vue.use(vueDebounce)
Vue.use(Vue2Storage)
Vue.use(ImageUploader)
Vue.use(ToggleButton)
Vue.use(VueTelInput)
Vue.use(VueSignaturePad);
Vue.use(VueToast);


Vue.component('pagination', Pagination);

import './assets/scss/custom.scss'

import Router from './services/router'
import Store from './services/store'
import App from './App.vue'
import system from './modules/system/module'
import memorizedInputs from './services/modules/memorizedInputs';
import i18n from './i18n'

/* Initialize System Module */
Store.registerModule('system', system.store)
Router.addRoutes(system.routes)
Store.dispatch('system/initialize', null, { root: true })

/* Initialize Memorization Module*/
Store.registerModule('memorized-inputs', memorizedInputs);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
}, Router)

Vue.config.productionTip = false

// New code for automatic 15-minute time limit
const startAutoClearTimer = () => {

  const timeoutId = setTimeout(() => {
    Store.dispatch('memorized-inputs/clearLocalStorage');
  }, 15 * 60 * 1000); // 15 minutes timeout for deleting local storage


  Store.commit('memorizedInputs/setTimeoutId', timeoutId);
};

const startWalkInAutoClearTimer = () => {

  const timeoutId = setTimeout(() => {
    Store.dispatch('memorized-inputs/clearWalkInLocalStorage');
  }, 15 * 60 * 1000); // 15 minutes timeout for deleting local storage


  Store.commit('memorizedInputs/setWalkInTimeoutId', timeoutId);
};

// Start the timer on application startup
startAutoClearTimer();
startWalkInAutoClearTimer();

new Vue({
    // eslint-disable-line no-new
    el: '#app',
    template: '<App/>',
    router: Router,
    store: Store,
    i18n,
    render: h => h(App)
})
