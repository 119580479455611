<template>
  <div id="dashboard_wrapper">
    <!-- Page Heading -->
    <h1 class="h3 mb-4 text-gray-800">
      {{ $t("account-page-form-control.title") }}
    </h1>

   <!-- Pre checkin message -->
    <div class="row">
      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/precheckin-message-form">
                    {{ $t("account-page-form-control.precheckin_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-check fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">
                {{ $t("account-page-form-control.precheckin_tip") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Hotel Checkin  -->
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/message-in-form">
                    {{ $t("account-page-form-control.checkin_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-building fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">
                {{ $t("account-page-form-control.checkin_tip") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Check in filter message -->
      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-secondary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/checkin-filter-message-form">
                    {{ $t("account-page-form-control.checkin_filter_message_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-filter fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">
                  {{ $t("account-page-form-control.checkin_filter_message_tip") }}
               </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Check out -->
      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <router-link to="/dashboard/checkout-message-form">
                    {{ $t("account-page-form-control.checkout_title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-sign-out-alt fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">
                {{ $t("account-page-form-control.checkout_tip") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      facility: window._app_facility,
    };
  },
};
</script>