export const getPaymentStatus = (paymentStatus, pmsRsvNo) => {
  return pmsRsvNo !== null ?
    paymentStatus :
    '--';
}

export const gallerySettings = [
    'gov_id',
    'vaccine_card',
    'signature'
];

export const galleryImages = {
  galleryImages(systemName) {
    let galleryImages = Object.keys(this.booking.gallery_images ?? {}).includes(systemName) ? this.booking.gallery_images[systemName] : null;
    galleryImages = galleryImages ?? [];
  
    galleryImages = galleryImages.map((value) => {
      return {
        src: value
      }
    });

    return galleryImages;
  }
};

export const isForGallery = {
  isForGallery(systemName) {
    return gallerySettings.includes(systemName)
  }
};

export const maskedDummyEmails = (email) => {
  if(email) {
    return email.replace(/(.+@dummy\.com.*)|(.+@dummy\.co\.jp)|(dummy@nta.*)/gi, '--');
  }
};


// memorization helpers

export const checkinFormTypeWithMemorization = (context, rid, reservation_no, chekin_form_type) => {
  if(rid) {
    context.saveReservationNumber(rid)
    context.checkIfCheckinFormType('email')
  } else if(chekin_form_type === "others"){
    context.checkIfCheckinFormType('others')
  } 
  else if(reservation_no !== null) {
    context.saveReservationNumber(reservation_no)
    context.checkIfCheckinFormType('reservation')
  } 
} 

export const loadMemorizedData = async (context, memorizedInputs, memorizedWalkInInputs, checkin_form_type, visitor_forms) => {
  const memorizedData = await memorizedInputs;
  const memorizedWalkInData = await memorizedWalkInInputs;

  if (checkin_form_type === "others") {
    return Object.keys(memorizedWalkInData).length > 0 ? memorizedWalkInData : visitor_forms;
  } else {
    return Object.keys(memorizedData).length > 0 ? memorizedData : visitor_forms;
  }
}