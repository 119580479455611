<template>
  <b-badge 
    pill
    v-b-tooltip.hover
    :title="tooltip"
    variant="success"
  >
    PMS 
    <i class="fas fa-info-circle" />
  </b-badge>
</template>

<script>
import { eventBus } from "@/main";

export default {
  props: {
    isPmsCheckin: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tooltip: ''
  }),
  mounted () {
    this.setTooltip()
    eventBus.$on("changeLang", (data) => {
      this.setTooltip()
    });
  },
  methods: {
    setTooltip () {
      this.tooltip = this.isPmsCheckin ?
        this.$t('account-page-visitor.checkin_pms') :
        this.$t('account-page-visitor.checkout_pms')
    }
  }
}
</script>