<template>
  <div id="fincode-iframe">
    <b-card v-if="!authorized">
      <!-- Credit Card Details Form -->
      <b-form @submit.prevent="submitForm">
        <!-- Card Number Input -->
        <label>
          <b>
            <!-- Card Information -->
            {{ $t("visit-information-form.card_information") }}
          </b>
        </label>
        <b-form-group
          label-for="cardNumber"
          label-cols-sm="3"
          label-size="sm"
          label-class="font-weight-medium"
          :label='$t("visit-information-form.card_number")'
        >
          <b-input
            id="cardNumber"
            v-model="formData.cardNumber"
            @input="handleInput('cardNumber')"
            @keypress="validateKeyPress($event, 'cardNumber')"
            :class="{'is-invalid': inputHasError('cardNumber')}"
            placeholder='0000 0000 0000 0000'
            type="text"
          ></b-input>
        </b-form-group>

        <!-- Expiry Date and CVC Inputs -->
        <b-row>
          <!-- Expiry Date Input -->
          <b-col cols="6">
            <b-form-group
              label-for="expiryDate"
              label-size="sm"
              label-class="font-weight-medium"
              :label='$t("visit-information-form.card_expiry_date")'
            >
              <b-input
                id="expiryDate"
                v-model="formData.expiryDate"
                @input="handleInput('expiryDate')"
                @keypress="validateKeyPress($event, 'expiryDate')"
                :class="{'is-invalid': inputHasError('expiryDate')}"
                placeholder="00/00"
                type="text"
                maxlength="4"
                 v-b-tooltip.hover.invalid="tooltipContent"
              ></b-input>
            </b-form-group>
          </b-col>

          <!-- CVC Input -->
          <b-col cols="6">
            <b-form-group
              label-for="cvc"
              label-size="sm"
              label-class="font-weight-medium"
              :label='$t("visit-information-form.card_cvc")'
            >
              <b-input
                id="cvc"
                v-model="formData.cvc"
                @input="handleInput('cvc')"
                @keypress="validateKeyPress($event, 'cvc')"
                :class="{'is-invalid': inputHasError('cvc')}"
                placeholder="000"
                type="text"
                maxlength="3"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <div class="d-flex justify-content-between align-items-center py-3 border-bottom">
                <span class="mr-2">{{ $t("visit-information-form.amount") }}:</span>
                <span>
                  <b class="mr-2">{{ totalAmount }} {{ configs.currency }}</b>
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Error message -->
        <div v-if="errors" class="pb-4 text-center">
          <span class="text-danger w-full mx-auto">{{ errors }}</span>
        </div>

        <div class="w-full text-center">
          <button type="submit" class="btn btn-primary btn-w-lg ml-3 mx-auto" :disabled="!isFormValid">{{ $t("visit-information-form.authorized") }}</button>
        </div>
      </b-form>
    </b-card>

    <!-- Authorized message in iframe -->
    <template v-else>
      <b-row>
        <b-col>
          <div class="d-flex flex-column align-items-center justify-content-center py-4">
            <div class="processing-text"><b>{{ $t("visit-information-form.card_info_authorized") }}</b></div>
            <div class="mx-auto">
              <i class="fas fa-check-circle text-primary py-4" style="font-size: 8rem;"></i>
            </div>
            <button type="button" class="btn btn-primary btn-w-lg ml-3 mx-auto" @click="backToForm">{{ $t("visit-information-form.back") }}</button>
          </div>
        </b-col>
      </b-row>
    </template>

    <b-modal
      id="processing-modal"
      ref="loadingModal"
      hide-header hide-footer no-close-on-esc no-close-on-backdrop centered>
      <div class="text-center">
        <div class="processing-text py-2">
          <b>{{ $t("visit-information-form.processing_msg") }}</b>
        </div>
        <i class="fas fa-spinner fa-spin processing-icon py-3" style="font-size: 3rem;"></i>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'FincodePaymentIframe',
  data() {
    return {
      formData: {
        cardNumber: '',
        expiryDate: '',
        cvc: '',
      },
      totalAmount: '',
      errors: '',
      authorized: false,
      configs: {},
      token: process.env.VUE_APP_FINCODE_PUB_KEY,
      fincode: null,
      fincodeObj: {
        tokens: null
      }
    };
  },
  computed: {
    isFormValid() {
      return this.formData.cardNumber && this.formData.expiryDate && this.formData.cvc;
    },
    tooltipContent() {
      if (this.inputHasError('expiryDate')) {
        return this.$t('visit-information-form.card_expiry_date_error');
      }
      return '';
    }
  },
  async mounted() {
    console.log(this.$route.fullPath);
    const configs = this.$route.query?.configs ?? '';
    const returnedError = this.$route.query?.showError ?? '';
    const accessId = this.$route.query?.md ?? '';

    this.configs = JSON.parse(atob(configs));

    this.totalAmount = (this.configs.net_charge > 0) ? this.configs.net_charge : '';

    const isTest = /test/.test(this.token);
    const fincodeEP = (isTest) ? 'js.test.fincode.jp/v1/fincode.js' : 'js.fincode.jp/v1/fincode.js';

    this.includeFincode(
      `${fincodeEP}`,
      function () {
        // Initialize
        this.fincode = Fincode(this.token);
        this.fincode.setTenantShopId(this.configs.account_id);
      }.bind(this)
    );

    if (returnedError) {
      this.authorized = false;
      this.errors = this.$t('visit-information-form.error_occur');
      window.parent.postMessage({ action: 'endProcessing' }, '*');
    }

    if (accessId) {
      this.authorized = true;
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (lang) {
      this.$i18n.locale = lang;
    }
  },
  methods: {
    async includeFincode(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "https://" + URL;

      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }

      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    submitForm() {
      this.tokenize(this.formData)
      window.parent.postMessage({ action: 'startProcessing' }, '*');
    },
    async tokenize(data) {
      this.errors = '';
      const uuid = uuidv4();
      this.fincode.setIdempotentKey(uuid);
      this.$refs.loadingModal.show();

      const card = {
        card_no: data.cardNumber,
        expire: data.expiryDate,
        security_code: data.cvc,
        number: "2",
      };

      let vm = this;
      // call fincode function to create token
      this.fincode.tokens(card,
        async function (status, response) {
          if (200 === status) {
            vm.fincodeObj.tokens = response.list;

            await vm.registerPayment();

            const exec = await vm.executePayment();

            if (exec.data?.status === 'error') {
              window.parent.postMessage({ action: 'endProcessing' }, '*');
              vm.errors = vm.$t('visit-information-form.error_occur');
            } else {
              vm.authorized = true;
            }

            vm.$refs.loadingModal.hide();
          }

          if (400 === status) {
            window.parent.postMessage({ action: 'endProcessing' }, '*');
            vm.errors = vm.$t('visit-information-form.error_occur');
            vm.$refs.loadingModal.hide();
          }
        },

        function (response) {
          vm.errors = vm.$t('visit-information-form.error_occur');
          vm.$refs.loadingModal.hide();
        }
      );
    },
    async registerPayment() {
      const params = {
        pay_type: "Card",
        job_code: "CAPTURE",
        amount: "100",
        tenantShopId: this.configs.account_id,
        facility_id: this.configs.facility_id,
        pms_code: this.configs.pms_code,
        rsv_no: this.configs.rsv_no,
      };

      await this.$lionheart.post('v2/fincode/payment-registration', params)
        .then(response => {
          return response;
        })
        .catch(error => {
          this.errors = this.$t('visit-information-form.error_occur');
          this.$refs.loadingModal.hide();
        });
    },
    async executePayment() {
      const params = {
        pay_type: "Card",
        tenantShopId: this.configs.account_id,
        facility_id: this.configs.facility_id,
        pms_code: this.configs.pms_code,
        rsv_no: this.configs.rsv_no,
        tokens: this.fincodeObj.tokens,
        return_url : this.$route.fullPath
      };

       const res = await this.$lionheart.post('v2/fincode/payment-execution', params)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          this.errors = this.$t('visit-information-form.error_occur');
          this.$refs.loadingModal.hide();
        });

      const acs_url = res.data?.acs_url ?? null;

      if (acs_url) {
        return window.location.href = acs_url;
      } else {
        return res;
      }
    },
    handleInput(field) {
      this.formData[field] = this.formData[field].replace(/\D/g, '').trim();

      if (field === 'expiryDate') {
        if (this.formData.expiryDate.length > 4) {
          this.formData.expiryDate = this.formData.expiryDate.slice(0, 4);
        }
      }
    },
    validateKeyPress(event, field) {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      if (!/^\d+$/.test(keyValue)) {
        event.preventDefault();
      }
    },
    inputHasError(field) {
      if (field === 'expiryDate') {
        const expiryDate = this.formData.expiryDate.trim();
        const currentYear = new Date().getFullYear() % 100;
        const year = parseInt(expiryDate.substring(0, 2), 10);
        const month = parseInt(expiryDate.substring(2, 4), 10);

        if (year < currentYear || month < 1 || month > 12) {
          return true;
        }
      }

      return false;
    },
    backToForm() {
      // window.parent.postMessage({ action: 'closeModal', tokenizeCard: this.fincodeObj.tokens[1].token }, '*'); // Close the entire modal
      window.parent.postMessage({ action: 'closeModal', tokenizeCard: '' }, '*'); // Close the entire modal
    }
  }
};
</script>

<style lang="scss" scoped>
$theme-colors: (
  "primary": #1A91D5,
);

#fincode-iframe {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

button {
  background-color: #1A91D5;
  border: none;
}

.processing-text {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.is-invalid {
  border-color: red !important;
}

.card > .card-body > form > .form-row > .card-label {
  color: #aaa !important;
  font-weight: 800 !important;
}

.tooltip {
  background-color: #fff !important;
  color: #333333 !important;
  font-size: 12px !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  font-family: Poppins;
}

.tooltip-inner {
  background-color: #fff !important;
  color: #333333 !important;
}
.tooltip-arrow::before {
  border-top-color: #fff !important;
}

.tooltip-arrow::before {
  border-top-color: #fff !important;
}
</style>
