<template>
  <div class="checkin">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="wrapper p-3">
            <div class="d-flex">
              <div class="d-flex border align-items-center p-2 text-muted">
                <div>{{ $t("quick_checkout.note_1") }}</div>
              </div>
              <nav class="nav-menu ml-auto">
                <ul>
                  <li class="dropdown">
                    <language-button-component
                      :facility_id="facility_custom.id"
                      :checkin="true"
                      @select-lang="(lang) => ($i18n.locale = lang)"
                    />
                  </li>
                </ul>
              </nav>
            </div>

            <div class="form-content">
              <div style="padding-bottom: 0px !important">
                <div
                  id="error_msg"
                  v-if="error_msg"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ error_msg }}
                </div>
                <div class="hotel-title text-center">
                  <h2>{{ $t("quick_checkout.header") }}</h2>
                </div>
              </div>
              <hr />

              <form class="p-2" @submit.prevent="submit">
                <div class="form-group row justify-content-between">
                  <label
                    for="colFormLabelSm"
                    class="col-sm-2 col-md-2 col-lg-2 col-form-label"
                    >{{ $t("quick_checkout.code") }}</label
                  >
                  <div class="col-sm-6 col-md-7">
                    <input
                      ref="ccode"
                      type="input"
                      :class="{
                        'is-invalid': invalid_code_error.length > 0,
                        'form-control': true,
                        codeStatus: true,
                      }"
                      :placeholder="$t(`quick_checkout.code`)"
                      required
                      v-model.trim="ccode"
                      style="ime-mode: disabled"
                      onKeyUp="this.value=this.value.replace(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g,'')"
                    />
                    <p
                      style="font-size: 13px"
                      class="text-danger"
                      v-for="(i, k) in invalid_code_error"
                      :key="k"
                    >
                      {{ $t("quick_checkout.error_message") }}
                    </p>
                  </div>
                  <div class="col-sm-2 col-md-2">
                    <button
                      type="submit"
                      class="btn btn-primary rounded-pill btn-block"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div class="form-group row justify-content-end">
                  <div class="col-sm-auto col-md-auto">
                    <button
                      @click.prevent="clear"
                      type="button"
                      class="btn btn-danger"
                    >
                      {{ $t("quick_checkout.clear") }}
                    </button>
                  </div>
                  <div class="col-sm-auto col-md-auto">
                    <button
                      @click.prevent="refresh"
                      type="button"
                      class="btn btn-primary"
                    >
                      {{ $t("quick_checkout.refresh") }}
                    </button>
                  </div>
                </div>
              </form>

              <div class="row">
                <div class="col">{{ $t("quick_checkout.note_2") }}</div>
              </div>
              <hr />
              <div style="min-height: 500px; overflow-y: auto">
                <quick-checkout-widget
                  v-for="c in codes"
                  :key="c.id"
                  :data="c"
                  :data_map="data_map"
                  :ref="`queue-${c.id}`"
                  @invalidCode="invalidCode"
                  @removeMe="
                    (id) =>
                      codes.splice(
                        codes.findIndex((e) => e.id == id),
                        1
                      )
                  "
                ></quick-checkout-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#login_wrapper h2 {
  margin: 0;
  text-align: left !important;
  text-transform: unset;
  display: block;
}
#login_wrapper input[type="email"],
#login_wrapper input[type="text"] {
  width: 100%;
}
</style>

<style lang="scss" scoped>
// New UI
$theme-colors: (
  "primary": #1aa8de,
);
@import "~bootstrap/scss/bootstrap";

.checkin {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #dff5ff;

  .wrapper {
    margin: 3rem 0;
    background: #fff;
    height: auto;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 3px 10px 6px #65656552; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 3px 10px 6px #65656552; /* Firefox 3.5 - 3.6 */
    box-shadow: 3px 10px 6px #65656552; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
    @media (max-width: 425px) {
      width: 100%;
    }
    @media (max-width: 375px) {
      width: 100%;
    }
    @media (max-width: 320px) {
      width: 100%;
    }

    .hotel-title h2 {
      color: #1a5dab;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 25px;
      margin-top: 20px;
    }
  }
}
// NEW UI END
</style>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import QuickCheckoutWidget from "../../dashboard/components/quickCheckoutWidget.vue";

export default {
  components: {
    LanguageButtonComponent,
    QuickCheckoutWidget,
  },
  data() {
    return {
      error_msg: "",
      facility_custom: {
        id: 0,
      },
      invalid_code_error: [],
      ccode: null,
      codes: [],
      canScan: true,
      queueKey: 0,
      codeStatus: "",
      data_map: [],
    };
  },
  async beforeMount() {
    let qcSwitch = false;
    const quickCheckoutSetting = await this.$lionheart
      .post("facility-setting/f/list", {
        facility_id: this.facility.id,
        name: "quick_checkout_setting",
        paginate: false,
      })
      .then((r) => this.catcher(r.data, "data", (r) => r))
      .catch((e) => this.catcher(e));

    if (quickCheckoutSetting.error.has_error) {
      this.errorDisplay(quickCheckoutSetting.error.msg);
      throw "Error on Quick Checkout switch fetch";
    }

    if (quickCheckoutSetting.total > 0) {
      qcSwitch = quickCheckoutSetting.data[0].value.visibility;
    }

    if (!qcSwitch)
      return this.$router.push({ name: "checkin-facility-dashboard-home" });
  },
  async mounted() {
    this.$refs.ccode.focus();
    const pms_settings = await this.$lionheart.post("/facility-setting/list", {
      facility_id: this.facility.id,
      name: "pms_settings",
      paginate: false,
    });

    if (pms_settings.data.total != 0)
      this.facility_custom = Object.assign({
        pms_code: pms_settings.data.data[0].value.pms_code,
        ...this.facility,
      });
    else return this.$router.push("/404");

    this.$store.commit("checkin-site/disableLoader", true);
    await this.fetchDataTemplate();
  },
  computed: {
    ...mapState("checkin-facility", ["facility"]),
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    submit() {
      // Prevent if code is already in the entries
      if (this.codes.length > 0) {
        if (this.codes.find((e) => e.code == this.ccode)) {
          this.ccode = null;
          return (this.codeStatus = "border border-danger");
        }
      }
      this.codes.unshift({
        pms_code: this.facility_custom.pms_code,
        code: this.ccode,
        id: this.queueKey++,
      });

      this.codeStatus = "";
      this.ccode = null;
    },
    invalidCode(e) {
      if (e) {
        this.invalid_code_error.push(e);
        setTimeout(() => {
          this.invalid_code_error.shift();
        }, 5000);
      }
    },
    clear() {
      this.$refs.ccode.focus();
      this.ccode = null;
      this.codeStatus = "";
      this.codes = [];
      this.queueKey = 0;
    },
    refresh() {
      this.codes.forEach((element) => {
        // trigger update
        this.$refs[`queue-${element.id}`][0].getUpdate();
      });
    },
    errorDisplay(d) {
      this.error_msg = d;
      return;
    },
    async fetchDataTemplate() {
      const response = await this.$lionheart.get(
        `/facility-setting/data-template/${this.facility.id}/guest_quick_checkout`,
        { params: { parse: true } }
      );
      if (response.data.hasOwnProperty("value")) {
        const value = response.data.value;
        if (value.hasOwnProperty("specs")) {
          this.data_map = value.specs.list;
        }
      }
    },
  },
  watch: {
    async locale(v) {
      await this.fetchDataTemplate();
    },
  },
};
</script>
