<template>
  <div>
  	<div class="card-header py-3">
    <p>  {{ $t('account-page-facility-name.intro') }}</p>
    <p>  {{ $t('account-page-facility-name.sub-intro') }}</p>
    </div>
    <div class="card-body">
      	<b-modal id="fn-modal-form" title="BootstrapVue" scrollable centered :hide-header="true" :hide-footer="true">
    		<form class="p-4" @submit="submitForm">
	            <div id ="error_msg" v-if="error_msg" class="alert alert-danger" role="alert">{{ error_msg }}</div>

	            <div class="form-group row">
	              <label for="exampleInputEmail1" class="col-sm-4 col-form-label">{{ $t('account-page-facility-name.name') }}</label>
	              <div class="col-sm-8">
	                <input v-model="facilityName.name" type="text" required class="form-control" placeholder="Name">
	              </div>
	            </div>

	            <div class="form-group row">
	              <label for="exampleInputEmail1" class="col-sm-4 col-form-label">{{ $t('account-page-facility-name.language') }}</label>
	              <div class="col-sm-8" v-if="mode == 'update'">
	              	<input :value="$t(`language-button.${facilityName.language.toLowerCase()}`)" type="text" readonly class="form-control">
	              </div>
	              <div class="col-sm-8" v-if="mode == 'create'">
	                <select v-model="facilityName.language" required class="form-control">
	                  <option value ="">--</option>
	                  <option v-for="el in enabled_languages" :value="el.code">{{ $t(`language-button.${el.name}`) }}</option>
	                </select>
	              </div>
            	</div>

            	<div class="row">
	              <div class="col-lg-12" align="center">
                  	<button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t('account-registration-form.submit')}}</button>
	              </div>
            	</div>
	        </form>
		</b-modal>

		<div id ="add_msg" v-if="add_msg" class="alert alert-success" role="alert">{{ $t('account-page.add-msg') }}</div>
		<div id ="updated_msg" v-if="updated_msg" class="alert alert-success" role="alert">{{ $t('account-page.update-msg') }}</div>
		<div id ="remove_msg" v-if="remove_msg" class="alert alert-danger" role="alert">{{ $t('account-page.remove-msg') }}</div>

		<div class="row mb-2">
			<button @click="showForm('create')" class="btn btn-primary ml-3">{{ $t('account-page-facility-name.add') }}</button>
		</div>

		<div class="table-responsive">
          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>{{$t('account-page-facility-name.name')}}</th>
                <th>{{$t('account-page-facility-name.language')}}</th>
                <th>{{$t('account-page-facility-name.operation')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fn in facilityNames">
                <td>{{ fn.name }}</td>
                <td>{{ $t(`account-page-facility-language-option.${fn.language.toLowerCase()}`) }}</td>
                <td align="center">
                  <button @click.prevent="removeName(fn.id)" class="btn btn-danger">{{ $t('account-page-facility-name.remove') }}</button>
                  <button @click.prevent="showForm('update', fn)" class="btn btn-primary ml-3">{{ $t('account-page-facility-name.edit') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
</template>

<script >
	import _ from 'lodash'; 

	export default {
		data() {
			return {
				submit_btn: '',
				error_msg: '',
				add_msg: '',
				updated_msg: '',
				remove_msg: '',
				mode: 'create',
				facilityNames: [],
				facilityName: {},
				enabled_languages: []
			}
		},
		async mounted() {
			await this.loadNames()
		},
		methods: {
			async loadNames() {
				const facility = window._app_facility
				const facilityNames = await this.$lionheart.get('/v2/facility-names', { params: {facility_id: facility.id} })
					.then(r => r.data.data)
				this.facilityNames = facilityNames

				const el = await this.$lionheart.get('/v2/facility-settings', { params: {
					facility_id: facility.id,
					name: 'language',
					paginate: 0					
				}})
				.then(r => r.data.data)

				if(el.length != 0) {
					this.enabled_languages = _.clone(el[0].value);
				}
				else {
					await this.processInsertDefaultLanguages(facility);
				}

				this.facilityNames.forEach((value, key) => {
					var index = this.enabled_languages.findIndex(x => x.code == value.language);
					if(index != -1)
						this.enabled_languages.splice(index, 1)
				});
			},

         async processInsertDefaultLanguages(facility){
            let params = {
               facility_id: facility.id,
               name: "language",
               value: [{code: 'en', name: 'en'}, {code: 'ja', name: 'ja'}]
            };

            this.enabled_languages = await this.$lionheart.
				post('v2/facility-settings', {...params})
				.then((r) => r.data.data.value);
			
         },
         
			showForm(mode, obj) {
				this.facilityName = {}
				this.mode = mode
				this.$bvModal.show('fn-modal-form')

				if (mode == 'update') {
					this.facilityName = _.clone(obj)
				}
			},
			async removeName(id) {
				await this.$lionheart.delete(`/v2/facility-names/${id}`, { 
					data: { facility_id: window._app_facility.id }
				})

				this.error_msg = ''
				this.add_msg = 0
				this.updated_msg = 0
				this.remove_msg = 1
				await this.loadNames()
			},
			async submitForm(e) {
				e.preventDefault()

				if (this.submit_btn == 'submit') {
					this.facilityName.facility_id = window._app_facility.id

					if (this.mode == 'create') {
						await this.$lionheart.post('/v2/facility-names', this.facilityName )

						this.add_msg = 1
						this.updated_msg = 0
						this.remove_msg = 0
					}

					if (this.mode == 'update') {
						const data = {
							facility_id: this.facilityName.facility_id,
							name: this.facilityName.name,
							id: this.facilityName.id
						}

						await this.$lionheart.put(`/v2/facility-names/${data.id}`, data )

						this.add_msg = 0
						this.updated_msg = 1
						this.remove_msg = 0
					}

					this.error_msg = ''
					await this.loadNames()
					this.$bvModal.hide('fn-modal-form')
				}
			}
		}
	}
</script>