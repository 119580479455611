import t from "typy";
import _ from "lodash";
import moment from "moment-timezone";
import {
  core_questions,
  nationalities,
  main_form_methods,
} from "@/modules/checkin-facility/utils/mockup";
const {
  changeChoices,
  getChoice,
  formatDate,
  formatDateHMA
} = main_form_methods;

import loadImage from "../../components/loadImage";
import reservationDetail from "../../components/reservationDetail";
import reservationQrCode from "../../components/reservationQrCode";
import paymentDetail from "../../components/paymentDetail";
import checkoutDetail from "../../components/checkoutDetail";
import DateDropdown from "vue-date-dropdown";
import PmsBadge from "./../../components/PmsBadge";

export default {
  components: {
    loadImage,
    reservationDetail,
    paymentDetail,
    checkoutDetail,
    reservationQrCode,
    DateDropdown,
    PmsBadge
  },
  data() {
    return {
      tp: t,
      navs: {
        main: true,
        reservation: false,
        payment: false,
        checkout: false,
        qr_code:false
      },
      facility: {},
      vl: {
        visitor: {
          user: {},
        },
      },
      vlo: {
        data: [],
      },
      vlc: {
        data: [],
      },
      edit_form: [],
      email: '',
      email_exists: false,
      ages: [...Array(121).keys()],
      submitted: false,
      specials: ['age', 'dob', 'gender', 'nationality', 'gov_id'],
      temp_date: null,
      success: false,
    };
  },
  async mounted() {
    this.facility = window._app_facility;

    await this.getVl()
    await this.getVlo()

    let vlc = await this.$lionheart
      .post("/log/get/facility-visitor-companion", {
        paginate: false,
        facility_visitor_log_id: this.vl.id,
        facility_id: this.facility.id,
      })
      .then((r) => this.catcher(r.data, "data", (r) => r))
      .catch((e) => this.catcher(e));
    vlc = _.cloneDeep(vlc.data);

    vlc.map((obj) => {
      obj.fco = _.groupBy(
        obj.facility_companion_other_form_log,
        (obj) => obj.facility_questionnaire.section
      );
    });

    this.vlc = vlc;

    this.$root.$on('image-updated', (status) => {
      if (status) this.showSuccess()
    })
  },
  methods: {
    changeChoices,
    getChoice,
    formatDate,
    formatDateHMA,
    async getVl() {
      const vl = await this.$lionheart
        .post("/log/get/facility-visitor", {
          paginate: false,
          id: this.$route.params.id,
          facility_id: this.facility.id,
          timezone: moment.tz.guess()
        })
        .then((r) => this.catcher(r.data, "data[0]", (r) => r.data[0]))
        .catch((e) => this.catcher(e));

      if (vl.error.has_error) return;

      this.vl = vl;
    },

    async getVlo() {
      this.vlo = await this.$lionheart
        .post("/log/get/facility-visitor-other-form", {
          paginate: false,
          facility_visitor_log_id: this.vl.id,
          facility_id: this.facility.id,
          version: 2,
        })
        .then((r) => this.catcher(r.data, "data[0].id", (r) => r))
        .catch((e) => this.catcher(e));

      let vlo = _.cloneDeep(this.vlo.data);
      this.vlo = _.groupBy(vlo, (obj) => obj.facility_questionnaire[0].section);

      this.populateEditData()
    },

    getLabel(obj) {
      if (t(core_questions, this.$i18n.locale).isDefined == false)
        return obj.label;

      return t(core_questions[this.$i18n.locale].labels, obj.system_name)
        .isDefined
        ? core_questions[this.$i18n.locale].labels[obj.system_name]
        : obj.label;
    },
    getNationality(code) {
      let label = nationalities[this.$i18n.locale].find(
        (obj) => obj.CountryCode == code
      );

      if (label) {
        label = label.Nationality;
        return label;
      } else {
        label = nationalities[this.$i18n.locale].find(
          (obj) => obj.Nationality == code
        );
        if (label) return label.CountryCode;
      }

      return "";
    },

    printUrl() {
      return {
        name: "checkin-facility-dashboard-print-detail",
        params: {
          id: this.$route.params.id,
          lang: this.$i18n.locale,
        },
      };
    },
    selectTab(name) {
      Object.keys(this.navs).map((obj) => (this.navs[obj] = 0));
      this.navs[name] = 1;
    },
    getEditLabel(system_name) {
      if (t(core_questions, this.$i18n.locale).isDefined == false)
        return system_name;

      return core_questions[this.$i18n.locale].labels[system_name];
    },
    async checkEmail(e) {
      if (this.edit_form.email == "") return;

      await this.validateEmail();
    },
    async validateEmail() {
      const data = {
        email: this.email,
        id: this.vl.visitor_id,
        type: "visitor",
        module: "checkin",
      };
      const email = await this.$lionheart
        .post("/user/exists", data)
        .then((r) => this.catcher(r.data, "data.exist", (r) => r.data))
        .catch((e) => this.catcher(e));

      this.email_exists = email.exist;
    },
    async submitForm(e) {
      e.preventDefault()
      if(!this.email_exists) {
        this.submitted = true
        this.$bvModal.hide('edit-contact-details-modal')

        const formData = _.cloneDeep(this.edit_form)
        let govIdChanged = false

        const gov_id_index = formData.findIndex(x => x.system_name === 'gov_id');
        if(gov_id_index != -1) {
          if (this.tp(formData[gov_id_index].value, 'dataUrl').isDefined) {
            govIdChanged = true
            formData[gov_id_index].value = formData[gov_id_index].value.dataUrl
          }
        }

        const dob_index = formData.findIndex(x => x.system_name === 'dob');
        if(dob_index != -1) {
          formData[dob_index].value = moment(this.formatDate(formData[dob_index].value)).format("YYYY-MM-DD")
        }
        const response = await this.$lionheart.put(`/log/visitor/${this.vl.id}`, {
          other_forms: formData,
          email: this.email,
          facility_id: this.facility.id
        });

        if(response.data.success) {
          this.submitted = false
          await this.getVl()
          await this.getVlo()
          if(!govIdChanged) this.showSuccess()
        }
      }
    },
    async back() {
      this.submitted = false
      await this.populateEditData()
      this.$bvModal.hide('edit-contact-details-modal')
    },
    async populateEditData() {
      this.edit_form = []
      this.email = this.vl.visitor.user.email
      const x = ['reservation_no', 'occupancy']
      this.vlo.contact.forEach((data, key) => {
        if (!x.includes(data.facility_questionnaire[0].system_name)) {
          this.edit_form.push({
            system_name: data.facility_questionnaire[0].system_name,
            rank: data.facility_questionnaire[0].rank,
            required: data.facility_questionnaire[0].required,
            id: data.id,
            value: data.value,
          })
          if (data.facility_questionnaire[0].system_name == 'dob') this.temp_date = data.value
        }
      });
    },
    changeNationality() {
      if (t(nationalities, this.$i18n.locale).isDefined)
        return nationalities[this.$i18n.locale];
      this.$i18n.locale = "en";
      return nationalities[this.$i18n.locale];
    },
    showSuccess() {
      this.success = true
      setTimeout(() => {
        this.success = false
      }, 5000);
    },
    formatFormDate(date) {
        if (date == null) return "";
        const [y, m, d] = date.split(".");
        date = `${d}.${m}.${y}`;
        const timezone = moment.tz.guess();
        return moment.tz(date, timezone).format("MM/DD/YYYY");
    },

    print(){
      if(this.navs.qr_code && this.vl.booking_details){
        this.$refs.qr_code.get_canvas()
        return;
      }
      let routeData = this.$router.resolve(this.printUrl());
      window.open(routeData.href, '_blank');
    }
  },
  computed: {
    editFormByRank: function () {
      return _.orderBy(this.edit_form, 'rank')
    },
    isPmsCheckin () {
      return this.vl.booking_details !== null &&
        this.vl.booking_details.hasOwnProperty('condition_metadata') &&
        this.vl.booking_details.condition_metadata !== null &&
        this.vl.booking_details.condition_metadata.is_pms_checkin === 1
    }
  }
};
