<template>
    <div>
        <div class="card-header py-3">
            <p v-if="!confirm">{{ $t("account-page-facility-email.intro") }}</p>
            <p v-if="confirm">{{ $t("account-page-facility-email.please-confirm") }}</p>
        </div>
        <div class="card-body">
            <div id="add_msg" v-if="add_msg" class="alert alert-success" role="alert">{{ $t("account-page-facility-email.add-msg") }}</div>
            
            <form @submit="submitForm">
                <b>{{ $t("account-page-facility-email.other_email_title") }}</b>
                <p>{{ $t("account-page-facility-email.other_email_message") }}</p>

                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>{{ $t("account-page-facility-email.email") }}</th>
                                <th width="60" v-if="!confirm">{{ $t("account-page-facility-email.operation") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(foe, i) in facilityEmails.others" :key="i.id">
                                <td>
                                    <div class="form-group">
                                        <input v-if="!confirm" v-model="facilityEmails.others[i].email" v-debounce:1000ms="checkEmail" :data-idx="i" :data-type="emailTypes.other" required type="email" class="form-control" :placeholder="$t('account-registration-form.email')" />
                                        <p v-if="confirm">{{ facilityEmails.others[i].email }}</p>
                                        <span v-if="facilityEmails.others[i].error" class="text-danger">{{ $t("account-page-facility-email.email-define") }}</span>
                                        <br v-if="facilityEmails.others[i].error" />
                                        <span v-if="facilityEmails.others[i].cannot_main" class="text-danger">{{ $t("account-page-facility-email.email-cannot-main") }}</span>
                                    </div>
                                </td>
                                <td align="center" v-if="!confirm">
                                    <button @click.prevent="removeEmail(i, emailTypes.other)" class="btn btn-outline-danger"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" align="center">
                                    <button v-if="!confirm" @click.prevent="addEmail(emailTypes.other)" class="btn btn-outline-primary btn-block">{{ $t("account-page-facility-email.add-btn") }} <i class="fa fa-plus" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <b>{{ $t("account-page-facility-email.payout_email_title") }}</b>
                <p>{{ $t("account-page-facility-email.payout_email_message") }}</p>
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>{{ $t("account-page-facility-email.email") }}</th>
                                <th width="60" v-if="!confirm">{{ $t("account-page-facility-email.operation") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(foe, i) in facilityEmails.payouts" :key="i.id">
                                <td>
                                    <div class="form-group">
                                        <input v-if="!confirm" v-model="facilityEmails.payouts[i].email" v-debounce:1000ms="checkEmail" :data-idx="i" :data-type="emailTypes.payout" required type="email" class="form-control" :placeholder="$t('account-registration-form.email')" />
                                        <p v-if="confirm">{{ facilityEmails.payouts[i].email }}</p>
                                        <span v-if="facilityEmails.payouts[i].error" class="text-danger">{{ $t("account-page-facility-email.email-define") }}</span>
                                    </div>
                                </td>
                                <td align="center" v-if="!confirm">
                                    <button @click.prevent="removeEmail(i, emailTypes.payout)" class="btn btn-outline-danger"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" align="center">
                                    <button v-if="!confirm" @click.prevent="addEmail(emailTypes.payout)" class="btn btn-outline-primary btn-block">{{ $t("account-page-facility-email.add-btn") }} <i class="fa fa-plus" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-12" align="center">
                        <button v-if="!confirm" :disabled="email_error" @click="submit_btn = 'confirm'" class="btn btn-primary">{{ $t("account-page-facility-email.confirm") }}</button>
                        <template v-if="confirm">
                            <button @click="submit_btn = 'edit'" class="btn btn-danger ml-3">{{ $t("account-page-facility-email.back") }}</button>
                            <button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t("account-page-facility-email.submit") }}</button>
                        </template>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import t from "typy";

export default {
    data() {
        return {
            submit_btn: "",
            add_msg: "",
            facilityEmails: {
                others: [],
                payouts: []
            },
            main_email: null,
            confirm: false,
            facility: window._app_facility,
            emailTypes: {
                'other' : null,
                'payout' : 'payout'
            }
        };
    },
    async mounted() {
        await this.loadOtherEmails();
    },
    computed: {
        email_error() {
            const isEmailPayoutError = this.facilityEmails.others.find((obj) => obj["error"] == true || obj["cannot_main"] == true) !== undefined;
            const isEmailOtherError = this.facilityEmails.payouts.find((obj) => obj["error"] == true) !== undefined;

            return  isEmailPayoutError || isEmailOtherError;
        }
    },
    methods: {
        async loadOtherEmails() {
            const facilityOtherEmails = await this.$lionheart.get("/v2/facility-other-emails", { 
                params: { facility_id: this.facility.id } 
            }).then((r) => r.data);

            this.facilityEmails.others = facilityOtherEmails.data.filter(obj => obj.type === this.emailTypes.other);
            this.facilityEmails.payouts = facilityOtherEmails.data.filter(obj => obj.type === this.emailTypes.payout);

            this.main_email = this.facility.user.email;
        },
        emailSwitch(type) {
            let emails = [];

            switch(type) {
                case this.emailTypes.other:
                    emails = this.facilityEmails.others
                    break;
                case this.emailTypes.payout:
                    emails = this.facilityEmails.payouts
                    break;
            }

            return emails;
        },
        removeEmail(i, type) {
            let emails = this.emailSwitch(type);
            
            emails.splice(i, 1);

            this.reCheckEmail(type);
        },
        addEmail(type) {
            let emails = this.emailSwitch(type);

            emails.push({
                email: "",
                facility_id: this.facility.id,
                type: type
            });
        },
        checkEmail(val, e) {
            const target = e.target.dataset.idx;
            const type = e.target.dataset.type ?? null;
            
            let emails = this.emailSwitch(type);
            let cloneEmails = _.cloneDeep(emails);

            cloneEmails.splice(target, 1); // Clone then remove the target from the checking
            
            let obj = cloneEmails.find((obj) => obj.email == val);
            
            this.$set(emails[target], "cannot_main", false); // same as main email error to false 
            this.$set(emails[target], "error", false); // duplicate error to false

            if (obj) {
                this.$set(emails[target], "error", true);
            }

            if (val == this.main_email && type == this.emailTypes.others) {
                this.$set(emails[target], "cannot_main", true);
            }

            this.reCheckEmail(type);
        },
        reCheckEmail(type) {
            let otherEmails = this.emailSwitch(type);

            otherEmails.map((obj, idx) => {
                const targetEmail = obj.email;
                let copy = _.cloneDeep(otherEmails);
                copy.splice(idx, 1); // Clone array then remove "entry"

                const emails = copy.map((obj) => obj.email); // flatten get emails without the entry

                // Check emails if there are no duplicate (target is the entry)
                if (!emails.includes(targetEmail)) {
                    this.$set(otherEmails[idx], "error", false);
                }
            }, this);
        },
        async submitForm(e) {
            e.preventDefault();

            if (this.submit_btn == "confirm") {
                this.confirm = true;
            }

            if (this.submit_btn == "edit") {
                this.confirm = false;
                this.error_msg = "";
                this.add_msg = 0;
            }

            if (this.submit_btn == "submit") {
                const removeFields = ["created_at", "error", "id", "updated_at", "cannot_main"];
                const otherEmails = this.facilityEmails.others.concat(this.facilityEmails.payouts);

                const data = otherEmails.map((obj) => {
                    removeFields.forEach((i) => delete obj[i]);
                    return obj;
                });

                await this.$lionheart
                    .post("v2/facility-other-emails/multiple/store", {
                        facility_id: window._app_facility.id,
                        data: data,
                    });

                await this.loadOtherEmails();
                this.add_msg = 1;
            }
        },
    },
};
</script>
