<template>
    <div>
        <div class="mt-3 start-guide">
            <div class="wrapper mx-5" id="top">
                <template v-if="$i18n.locale == 'ja'">
                    <h3 class="text-center">タッチレスチェックインスタートガイド</h3>
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">1. 初回で設定が必要な項目：</h6>
                            <ul>
                                <li><a href="#setting">設定</a></li>
                                <li><a href="#check-in-filters">チェックインフィルタ</a></li>
                                <li><a href="#questions">質問</a></li>
                                <li><a href="#payment">支払</a></li>
                                <li><a href="#terms">規約</a></li>
                                <li><a href="#form-control">フォーム管理</a></li>
                                <li><a href="#notif-control">通知管理</a></li>
                                <li><a href="#quick-check-in-and-out">クイックチェックイン/アウト</a></li>
                                <li><a href="#quick-pass">クイックパス</a></li>
                                <li><a href="#my-page">マイページ</a></li>
                                <li><a href="#qr-code">QRコード</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">2. 便利な機能：</h6>
                            <ul>
                                <li><a href="#list">チェックインリスト</a></li>
                                <li><a href="#reservation-list">予約リスト</a></li>
                                <li><a href="#analytics">アナリティクス</a></li>
                                <li><a href="#settlement">決済リスト </a></li>
                                <li><a href="#download">ダウンロード</a></li>
                                <li><a href="#receipt">領収書発行機能</a></li>
                                <li><a href="#status-display">ステータス表示機能</a></li>
                                <li><a href="#digital-key">デジタル鍵</a></li>
                                <li><a href="#timing-email">メール送信のタイミング</a></li>
                                <li><a href="#multiple-room">複数客室チェックイン</a></li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <p class="text-justify">
                        このガイドはタッチレスチェックインシステムをPMSに連携などカスタマイズを実装したユーザーと、
                        カスタマイズを実装していないユーザーの利用両方を対象に記載しております。
                        カスタマイズを実装されたユーザーだけが利用できる機能の説明もありますので、ご了承ください。
                        </p>
                    </div>

                    <!-- Items that need to be set for the first time START -->
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">1. 初回で設定が必要な項目：</h6>

                            <!-- Setting START -->
                            <div id="setting">
                                <p class="text-justify">
                                <span class="font-weight-bold">設定</span>
                                <br />
                                画面上に「メイン」、「設定名称設定」、「言語オプション」、「メールアドレスの追加」のタブがあります。
                                </p>
                                <ul>
                                <li>
                                    『メイン』
                                    <ul>
                                    <li>
                                        登録時に設定した初期名称、メールアドレスや施設住所の修正ができます。修正が必要な場合はこちらで行ってください。
                                    </li>
                                        <li>
                                            なお、この施設名は事前チェックイン案内メールの送信者名としてゲストに表示されます。また、他言語で施設名に入力が無い場合にこの名称が表示されます。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    『施設名称設定」
                                    <ul>
                                    <li>
                                        ゲストのブラウザの言語設定に合わせて、表示される施設名を追加できます。
                                    </li>
                                        <li>
                                            施設名が言語によって異なる場合は、「追加」ボタンをクリックし、言語に該当する施設名を入力してください。各言語に入力された施設名は、それぞれの言語のEmail（チェックイン案内メール、確認メール、支払リクエストメール、領収書メール）のEmail 送信者として自動的に表示されます。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    『言語オプション』
                                    <ul>
                                    <li>
                                        システム内の日本語、英語、韓国語、簡体字中国語、繁体字中国語表示の選択と設定ができます。
                                        この設定により各言語別にチェックインフォームの項目の作成ができるようになります。
                                        メールやメッセージ、フォーム、規約の文章は各言語での入力が必要です。
                                    </li>
                                    </ul>
                                </li>
                                <!-- Additional Emails START -->
                                <li>
                                    『メールアドレスの追加』
                                    <ul>
                                       <li>
                                          ゲスト情報登録の通知および送金に関する通知が、現在登録しているメールアドレス以外の複数のアドレスへ到着するように設定できます。
                                       </li>
                                       <li>
                                          ゲストのチェックイン とチェックアウトの通知メール 
                                          <ul>
                                             <li>
                                                ゲストの支払決済を含む、チェックイン・チェックアウトに関する通知メールです。この通知は主に予約担当およびフロントデスクのスタッフ向けの通知です。
                                             </li>
                                          </ul>
                                       </li>
                                       <li>
                                          入金の通知メール 
                                          <ul>
                                             <li>
                                                入金に関する通知メールです。入金が実行されると、この通知メールが送信されます。この通知は主に経理担当者向けです。
                                             </li>
                                          </ul>
                                       </li>
                                    </ul>
                                </li>
                                <!-- Additional Emails START -->

                                </ul>
                            </div>
                            <!-- Setting END -->

                            <!-- Check-in Filters START -->
                            <div id="check-in-filters">
                                <p class="text-justify">
                                <span class="font-weight-bold">チェックインフィルタ</span>
                                <br />
                                ゲストの振り分けをしたい場合、チェックインフォームの質問事項を最大5件まで設定することができます。
                                フィルタ対象を追加する場合は『追加する』ボタンを押しフィルタ名を入力して登録してください。
                                </p>
                            </div>
                            <!-- Check-in Filters END -->

                            <!-- Question START -->
                            <div id="questions">
                                <p class="text-justify">
                                <span class="font-weight-bold">質問</span>
                                <br />
                                ゲストのチェックイン時にチェックインフォームに表示させる質問、追加質問の設定することができます。
                                初期設定では質問事項の全てが表示されるように選択されています。多言語設定をする場合、設定者が必要言語で入力しください。
                                </p>
                            </div>
                            <!-- Question END -->

                            <!-- Payment START -->
                            <div id="payment">
                                <p class="text-justify">
                                <span class="font-weight-bold">支払い</span>
                                <br />
                                お払いのタイミングや通貨を設定できます。
                                この設定はすべてのゲストに適用され、
                                個別に設定することはできません。
                                支払いのタイミングと支払い通貨を変更すると、
                                すぐに適用されますのでご注意ください。
                                </p>
                                <ul>
                                <li>
                                    支払いタイミング
                                    <ul>
                                    <li>
                                        支払のタイミングをチェックインまたはチェックアウトのどちらかに設定できます。
                                        この設定は全てのゲストに適応となりゲスト個々での設定はできません。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    通貨設定
                                    <ul>
                                    <li>
                                        チェックインや他のフォーム等のゲストとダッシュボードに表示される支払い通貨の設定が出来ます。
                                        &#40;&#42;注：現在は日本円のみ対応しています。将来的には他の通貨も追加する予定です&#41;
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    デポジット
                                    <ul>
                                    <li>
                                        ゲストのチェックイン時にクレジットカード情報を取得し、チェックアウトの際や事後に宿泊代金や支払い残金がある場合、ゲストのクレジットカード情報から残金を自動的に精算する機能です。 
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- Payment END -->

                            <!-- Terms START -->
                            <div id="terms">
                                <p>
                                <span class="font-weight-bold">規約</span>
                                <br />
                                約款や規約をチェックイン時にゲストに提示をすることができます。
                                ゲストはチェックインフォーム入力後に規約に合意します。規約の設定は任意です。
                                宿泊約款などゲストがチェックインする前に目を通していただきたい事項があれば、このフォームに記載してください。
                                言語別に作成をすることも可能です。言語はそれぞれの言語で入力をしてください。
                                </p>
                            </div>
                            <!-- Terms END -->

                            <!-- Form Management START -->
                            <div id="form-control">
                                <p class="text-justify">
                                <span class="font-weight-bold">フォーム管理</span>
                                <br />
                                ゲストのスマートフォンや現地設置端末用のチェックイン画面に表示されるタイトルや文言の設定を行います。
                                文章はデフォルトで設定されています。施設に合わせて文章を変更、追加することができます。
                                </p>

                                <!-- Text at local check-in START -->
                                <div>
                                <span>現地チェックイン時の文章</span>
                                <ul>
                                    <li>
                                    到着/チェックインフォームタイトル
                                    <ul>
                                        <li>
                                        ゲストがチェックインをする際に最初に表示される画面タイトルです。
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    到着/チェックインフォーム案内文
                                    <ul>
                                        <li>ゲストに情報入力を依頼する文章を記載します。</li>
                                    </ul>
                                    </li>
                                    <li>
                                    到着/チェックインフォーム完了文
                                    <ul>
                                        <li>
                                        ゲストが入力を完了し『送信』ボタンを押した後に画面表示される文章です。
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Text at local check-in END -->

                                <!-- Checkout text START -->
                                <div>
                                <span>チェックアウト文章</span>
                                <ul>
                                    <li>
                                    チェックアウトフォームタイトル
                                    <ul>
                                        <li>
                                        ゲストがチェックアウトをする際に最初に表示される画面タイトルです。
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    チェックアウトフォーム案内文
                                    <ul>
                                        <li>ゲストに情報入力を依頼する文章を記載します。</li>
                                    </ul>
                                    </li>
                                    <li>
                                    チェックアウトフォーム完了文
                                    <ul>
                                        <li>
                                        ゲストが入力を完了し『送信』ボタンを押した後に画面表示される文章です。
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Checkout text END -->

                                <!-- Pre-check-in text START -->
                                <div>
                                <span>事前チェックイン文章</span>
                                <ul>
                                    <li>
                                    事前チェックインフォームタイトル
                                    <ul>
                                        <li>
                                        ゲストがスマートフォンで事前にチェックインをする際に最初に表示される画面タイトルです
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    事前チェックインフォーム案内文
                                    <ul>
                                        <li>ゲストに情報入力を依頼する文章を記載します。</li>
                                    </ul>
                                    </li>
                                    <li>
                                    事前チェックインフォーム完了文
                                    <ul>
                                        <li>
                                        ゲストが入力を完了し『送信』ボタンを押した後に画面に表示される文章です。
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Pre-check-in text END -->

                                <!-- Check-in Filter Message text START -->
                                <div>
                                    <span>チェックインフィルター文章 </span>
                                    <ul>
                                       <li>チェックイン フィルターページに表示される説明文章を編集することが可能です。ゲストの事前チェックインおよびチェックイン フォームのフィルター ページに表示されます。</li>
                                    </ul>
                                </div>
                                <!-- Check-in Filter Message text END -->
                            </div>
                            <!-- Form Management END -->

                            <!-- Notification Management START -->
                            <div id="notif-control">
                                <p class="text-justify">
                                <span class="font-weight-bold">通知管理</span>
                                <br />
                                施設からゲストへお送りする案内メールを作成することができます。
                                メールの内容は既にテンプレートとして設定されており、
                                当該メールの中に加えてご案内する内容を記載することが可能です。
                                ゲスト情報案内メールの内容を確認したい場合は最大3か所までのメールアドレスへサンプルを送信することができます。
                                </p>
                                <ul>
                                 <!-- Pre-check-in email START -->
                                <li>
                                    確認メール
                                    <ul>
                                    <li>
                                        ゲストの到着時やチェックイン・アウトフォーム登録後、
                                        施設からゲスト端末への案内メールが作成できます。
                                        このメッセージはゲスト、施設両方へ送信されます。
                                    </li>
                                    </ul>
                                </li>
                                <!-- Pre-check-in email END -->

                                <!-- Confirmation mail START -->
                                <li>
                                    事前チェックインメール
                                    <ul>
                                    <li>
                                        予約が完了したゲストへ事前チェックイン案内、事前チェックイン後の案内メールが作成できます。
                                    </li>
                                    </ul>
                                </li>
                                <!-- Confirmation mail END -->

                                <!-- Schedule Pre Check-in Notice text START -->
                                <li>
                                    事前チェックイン通知のスケジュール 
                                    <ul>
                                       <li>ゲストに対して送信する事前チェックインの案内をスイッチオン・オフすることができます。この機能をオンにすると、設定されたタイミングで、連携された予約に対して事前チェックインの案内が自動的に送信されます。 </li>
                                    </ul>
                                </li>
                                <!-- Schedule Pre Check-in Notice text END -->
                                </ul>
                            </div>
                            <!-- Notification Management END -->

                            <!-- Quick Check in/out START -->
                            <div id="quick-check-in-and-out">
                                <p class="text-justify">
                                <span class="font-weight-bold">
                                    クイックチェックアイン/アウト
                                </span>
                                <br />
                                ゲストがQRコードを使用して、チェックインとチェックアウトすることができます。
                                この機能により迅速なチェックインとチェックアウトが可能です。
                                この機能を利用するにはスキャナーとコンピューターをご準備いただき、
                                ゲストがクイックチェックインとアウトを行えるように切り替えを行ってください。
                                詳細についてはお問い合わせください。
                                </p>
                                <p class="text-justify">注：​</p>
                                <ul>
                                <li>
                                    この機能をオンにすると、クイックチェックインとアウトに利用できるQRコードがゲストは事前チェックイン、チェックイン完了時にメールで受け取ることができます。
                                </li>
                                <li>
                                    QRコード読み込み用のスキャナーをご準備いただき、クイックチェックインとアウトを行う場所に設置してください。
                                </li>
                                <li>
                                    スキャナーとは別に、パソコン、タブレット、スマートフォンなどをご用意し、必ず上記のスキャナーに接続してください。
                                </li>
                                <li>
                                    本システムダッシュボードのクイックチェックイン/アウトの下に表示されているリンクのウェブサイトページを、常にパソコン、タブレット、スマートフォンにて開いておいてください。
                                </li>
                                <li>スイッチのオンとオフの条件をよくお読みください。</li>
                                </ul>
                                <p class="text-justify">
                                スイッチ：On​
                                <br />
                                ・ゲストは事前チェックイン完了時にはチェックイン用QRコード、チェックイン完了時にはチェックアウト用QRコードをメールにてパスコードと一緒に受け取ることができ,
                                クイックチェックインとアウトを行うことが可能となります。
                                <br />
                                ・通常のプロセスと同様にパスとメールでもチェックインとアウトすることが可能です。ゲストはどちらの方法でもチェックインとアウトをスムーズに行うことができます。
                                </p>
                                <p class="text-justify">
                                スイッチ：Off
                                <br />
                                ゲストは、チェックインとアウトフォームのパスとメールアドレスを使用してのみチェックインとアウトできます（通常のチェックインとアウト）。
                                </p>
                                <p class="text-justify">なお、QRコードの内容を予約番号に変更することが可能です。パターンのタブにてスイッチをONにすることでゲストに送付されるQRコードパターンが予約番号に変わります。 
                                  QR コードの初期設定はゲストのEメールとパスワードで構成されています。 この形式は運用に応じていつでも変更できます。
                                  （PMSなどの基幹システム企業向けの機能です） 
                                </p>
                            </div>
                            <!-- Quick Check in/out END -->

                            <!-- Quick Pass START -->
                            <div id="quick-pass">
                                <p class="text-justify">
                                <span class="font-weight-bold">クイックパス</span>
                                <br />
                                館内の食事やサービスなどで、ゲストがQRコードをスキャンさせるだけで、
                                迅速にゲストの予約内容を確認することができます。スキャナーとコンピューターをご準備いただき、
                                ゲストがQRコードを使用してクイックパスを行えるように切り替えを行ってください。
                                詳細についてはお問い合わせください。
                                </p>
                                <p class="text-justify ml-3">注：​</p>
                                <ul>
                                <li>
                                    この機能をオンにするとクイックパスに利用できるQRコードが事前チェックインもしくはチェックイン完了時にゲスト向けに送信されます。
                                </li>
                                <li>
                                    QRコード読み込み用のスキャナーをご準備いただき、食事会場やサービス提供場所などクイックパスを行う場所に配置してください。
                                </li>
                                <li>
                                    スキャナーとは別に、パソコン、タブレット、スマートフォンなどをご用意し、必ず上記のスキャナーに接続してください。
                                </li>
                                <li>
                                    施設用システムのダッシュボード「クイックパス」画面に表示されているリンクのウェブサイトをパソコン、タブレット、スマートフォンで開いておいてください。
                                </li>
                                <li>スイッチのオンとオフの諸条件を必ずお読みください。</li>
                                </ul>
                                <br />
                                <p class="text-justify ml-3">スイッチ：On</p>
                                <ul>
                                <li>
                                    事前チェックインおよびチェックイン完了後、ゲストはQRコードをメールで受け取ることができます。ゲストはこのQRコードをスキャンすることで、予約内容をすぐに表示できます。
                                </li>
                                </ul>
                                <p class="text-justify ml-3">スイッチ：Off</p>
                                <ul>
                                <li>
                                    ゲストはクイックパスを使用することができません。
                                    QRコードはクイックチェックインとチェックアウトにのみ使用できます。
                                </li>
                                </ul>
                            </div>
                            <!-- Quick Pass END -->

                            <!-- myPage START -->
                            <div id="my-page">
                                <p class="text-justify">
                                <span class="font-weight-bold">マイページ</span>
                                <br />
                                マイページとは、ゲストのチェックイン処理や滞在を支援する機能で、主にスマートフォンで閲覧することを目的に設計されています。この機能を利用することで、ゲストに対してチェックインおよびチェックアウト処理を迅速に処理することが可能となり、滞在中の館内の情報や個別のメッセージなどを素早く共有することが可能となります。
                                </p>
                                <p class="text-justify">設定</p>
                                <ul>
                                <li>
                                    マイページ機能のスイッチ
                                    <ul>
                                        <li>この機能をオンにすることでゲストにマイページ機能を提供することが可能となり、ゲストに対して案内を開始します。QRコードをゲストに対して提供する場合は、必ずクイックチェックインやチェックアウトの機能もオンにしてください。</li>
                                    </ul>
                                </li>
                                <li>
                                    エクスプレスチェックアウト
                                    <ul>
                                        <li>エクスプレスチェックアウトをオンにすることで、マイページから直接チェックアウトが可能となります。なお、エクスプレスチェックアウトはチェックアウト日のみ有効になり、チェックアウト日以外ではゲストはエクスプレスチェックアウトを行うことはできません。</li>
                                    </ul>
                                </li>
                                <li>
                                    マップ設定
                                    <ul>
                                        <li>ゲストに対してマイページで宿泊施設の地図座標やロケーションを表示させます。Google Mapの組み込み用URLを添付してください。ダッシュボードにてURLがきちんと機能しているかどうかを確認することも可能です。なお、マップの設定は必須ではありません。</li>
                                    </ul>
                                </li>
                                <li>
                                    背景画像
                                    <ul>
                                        <li>ゲストのマイページの背景画像として表示させる画像をアップロードすることができます。なお、背景画像設定は必須ではありません。</li>
                                    </ul>
                                </li>
                                </ul>
                                <p class="text-justify">メッセージ</p>
                                <ul>
                                <li>
                                    メッセージとは
                                    <ul>
                                        <li>ゲストに対してメッセージを直接送ることが可能です。ゲストの詳細ページのメッセージアイコンからメッセージの送信が可能です。 
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    メッセージ顔アイコン、サンプル
                                    <ul>
                                        <li>ゲストのメッセージに表示させる顔アイコンです。ダッシュボードからサンプルも確認することができます。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    メッセージテンプレートとは
                                    <ul>
                                        <li>頻繁に送るメッセージの内容を、あらかじめテンプレートとしてダッシュボードに保存し、メッセージを送信する際に参照できる機能です。
                                        </li>
                                        <li>テンプレート名はメッセージの件名で、メッセージを参照させる際に表示および選択されるものです。メッセージはテンプレートに紐づくメッセージのテキストです。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    メッセージの送信に関して
                                    <ul>
                                        <li>メッセージの送信は、チェックインリストに表示されているゲストの詳細からメッセージアイコンにアクセスして、メッセージを送信することが可能です。
                                        </li>
                                    </ul>
                                </li>
                                </ul>
                                <p class="text-justify">施設情報 </p>
                                <ul>
                                  <li>
                                    編集
                                    <ul>
                                      <li>ダッシュボードで施設内の情報を設定および編集し、施設内の案内項目をゲストのスマートフォン上で表示させることが可能です。
                                      </li>
                                      <li>スイッチをオンにすることで、マイページ上でゲストに施設情報が表示されます。 スイッチをオフにすると、施設情報がゲストに表示されなくなります。
                                      </li>
                                      <li>ダッシュボード上で設定後に、客室や施設内の最適な場所に指定のQRコードを設置してください。ゲストが自身のスマートフォンで設置したQRコードを読み取ることで、管理画面上で設定した施設内の情報を表示させることが可能です。 
                                      </li>
                                      <li>情報の追加編集などはダッシュボードからいつでも実施できます。
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    サンプル 
                                    <ul>
                                      <li>サンプルの画面表示をダッシュボード上で確認することも可能です。 
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <p class="text-justify">おしらせ機能</p>
                                <ul>
                                  <li>
                                    おしらせ作成 
                                    <ul>
                                      <li class="text-justify">施設内のイベントやエレベーターの故障など、ゲスト向けのお知らせ内容を作成および既存内容を編集することができます。
                                        おしらせ内容はゲストのマイページ上に表示されます。お知らせ内容を作成し、対象期間を設定してください。
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                            </div>
                            <!-- myPage END -->

                            <!-- QR Code START -->
                            <div id="qr-code">
                                <p>
                                <span class="font-weight-bold">QRコード</span>
                                <br />
                                これまでの設定が済みましたらQRコードを印刷し、施設必要箇所にご提示ください。
                                施設で使用される端末へ専用QRコードをダウンロードしてください。
                                </p>
                                <ul>
                                <li>
                                    到着フォーム用QRコード
                                    <ul>
                                    <li>
                                        事前チェックインがお済みのゲスト、お済みでないゲストとも施設到着時にご自身の端末で読み込んでいただくコードです。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    到着フォーム用QRコード(タブレット)
                                    <ul>
                                    <li>
                                        到着フォームをタブレットで表示させるQRコードです。タブレット向けに最適化がされています。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    チェックアウト用QRコード
                                    <ul>
                                    <li>
                                        ゲストがチェックアウト時に読み込んでいただきチェックアウトをしていただくコードです。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    事前チェックイン用QRコード
                                    <ul>
                                    <li>
                                        通常使用することはありません。ユーザー施設の担当者がゲスト用フォームの表示や入力方法を確認する際に読み込むコードです。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                  マイページ 
                                  <ul>
                                    <li class="text-justify">ゲストの客室や施設内に設置するためのQRコードです。予約者向けのログインページと、施設情報を参照するだけのオプションがあります。 </li>
                                  </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- QR Code END -->
                        </div>
                    </div>
                    <!-- Items that need to be set for the first time END -->

                    <!-- Useful Functions START -->
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">2. 便利な機能：</h6>

                            <!-- List START -->
                            <div id="list">
                                <p class="text-justify">
                                <span class="font-weight-bold">チェックインリスト</span>
                                <br />
                                チェックインリストはゲストの予約のチェックイン状況や詳細を確認することができるリストです。検索ツールを使用してゲスト情報を細かく分けて検索することも可能です。
                                </p>
                                <ul>
                                <li>
                                    検索機能
                                    <ul>
                                        <li>
                                            検索機能ではゲストの予約を簡易検索や詳細検索することができます。 
                                        </li>
                                        <li>
                                            検索フィルタには「全日付」と「ステータス」でソートすることが可能です。 
                                        </li>
                                        <li>
                                            「全日付」ではチェックインの期間を設定することで該当するゲストの情報を表示します。 
                                        </li>
                                        <li>
                                            「ステータス」では、確認したいゲストの支払い状況や予約状況の設定ができ、該当するゲスト情報を表示します。 
                                        </li>
                                        <li>
                                            検索フィルタを組み合わせて設定することで、より詳細なゲスト情報を検索することができます。 
                                        </li>
                                        <li>
                                            本システムは設定した検索フィルタを自動的に保存します。次の検索時に別の条件で検索する際は「フィルタクリア」で全ての設定をクリアしデフォルトに戻すか、保存されている設定の一部を変更してください。 
                                        </li>
                                        <li>
                                            特定のゲストを検索したい場合は、予約番号、ゲスト氏名、電話番号を直接入力することで検索ができます。 
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    閲覧
                                    <ul>
                                    <li>ゲストの登録情報の詳細を見ることができます。</li>
                                    <li>
                                        ゲスト情報詳細ページでは「メイン」「予約」「支払」「チェックアウト」のタブが表示されます。
                                    </li>
                                    <li>
                                        タブを切り替えることでゲスト予約情報、お支払い状況、チェックアウト状況の詳細を見ることができます。閲覧は1チェックイン毎になっています
                                        (1チェックインで複数名登録の場合は同じ画面で確認できます)。
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    「▼」ボタン
                                    <ul>
                                    <li>
                                        ゲスト情報のリストダウンロード、チェックイン記録の消去、ゲストリスト表示方法を変更できます。
                                    </li>
                                    <li>
                                        Excelへエクスポート
                                        <ul>
                                        <li>
                                            リスト画面のゲスト情報がダウンロードできます。ダウンロードされたデータはExcelデータです。データをクラウド上だけでなく台帳として保存したい場合に便利な機能です。
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        チェックイン記録の消去
                                        <ul>
                                        <li>
                                            通常チェックインフォームから登録された情報はクラウド上に全て保存されています。
                                            導入時のテストのために登録した情報などを削除したい場合に削除することができます。
                                            ただしゲスト登録情報を削除する際は十分ご注意ください。復元はできません。
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        表示編集
                                        <ul>
                                        <li>
                                            リスト画面で表示するゲスト情報が「名前」「電話」「住所」「Email」「国籍」「支払ステータス」「予約ステータス」の8つから選択できます。
                                        </li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- List END -->

                            <!-- Reservation List START -->
                            <div id="reservation-list">
                              <p class="text-justify">
                                <span class="font-weight-bold">予約リスト</span>
                                <br />
                                 予約リストでは、PMSなどのホテルシステムからインポートしたチェックイン前のデータをリストアップしています。これらの予約がゲストもしくはPMSのいずれかによって事前チェックインもしくはチェックインされるとチェックインリストに表示される形になります。このリストに表示されている予約がキャンセルをされた場合、このリストからは削除されます。
                              </p>
                            </div>
                            <!-- Reservation List END -->

                            <!-- Analytics START -->
                            <div id="analytics">
                                <p class="text-justify">
                                <span class="font-weight-bold">アナリスティック</span>
                                <br />
                                    アナリスティックではチェックインシステムを利用して行われたゲストのチェックイン記録をリアルタイムで確認することができます。 
                                </p>
                                <ul>
                                    <li>
                                        累計チェックイン・チェックアウト 
                                        <ul>
                                            <li>
                                                それぞれの状況で行われたチェックイン・アウトの合計が表示されます。
                                                <ul>
                                                    <li>
                                                        事前チェックイン 
                                                        <ul>
                                                            <li>
                                                                事前チェックイン回数には、事前チェックインを行ったすべての予約数が含まれます。 
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        チェックイン 
                                                        <ul>
                                                            <li>
                                                                チェックイン回数は以下のように分類しています。 
                                                                <ul>
                                                                    <li>
                                                                        ゲストによるチェックイン 
                                                                        <ul>
                                                                            <li>
                                                                                チェックインリストに登録されたゲストが、チェックインしたすべての予約数。 
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        PMSによるチェックイン 
                                                                        <ul>
                                                                            <li>
                                                                                施設がPMSを通じてチェックインしたすべての予約数。 
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        同行者によるチェックイン 
                                                                        <ul>
                                                                            <li>
                                                                                チェックインリストに登録のあるゲストの同行者が、チェックインしたすべての数。 
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                    <li>
                                                                        チェックイン後のキャンセル 
                                                                        <ul>
                                                                            <li>
                                                                                チェックインリストに登録のあるゲストが、チェックイン後に予約をキャンセルした数。 
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        チェックアウト 
                                                        <ul>
                                                            <li>
                                                                チェックアウト回数は、ゲストによるチェックアウト、PMSによるチェックアウト、同行者によるチェックアウトが含まれます。 
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        最新チェックイン・チェックアウト 
                                        <ul>
                                            <li>
                                                それぞれの状況で行われたチェックイン・チェックアウトの最新状況をリアルタイムに表示します。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        累積と最新の違いについて 
                                        <ul>
                                            <li>
                                                累積数はそれぞれのステータスの総数を表示します。そのため、数値は積み上がるだけです。 
                                            </li>
                                            <li>
                                                最新数はチェックインインからチェックアウトまでの各予約の実際の動きを表示します。そのためゲストのステータスに応じて数値が上下します。 
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <!-- Analytics END -->

                            <!-- Settlement START -->
                            <div id="settlement">
                                <p class="text-justify">
                                <span class="font-weight-bold">決済リスト</span>
                                <br />
                                    決済リストはチェックインシステムを通して行われたゲストの決済状況の詳細を確認することができます。詳細情報の内容は取引額、ゲスト名、予約内容、支払い状況、支払い内容などです。それぞれのゲストの取引ごとに閲覧から情報を見ることができます。
                                </p>
                                <ul>
                                    <li>
                                        額 (JPY) 
                                        <ul>
                                            <li>
                                                ゲストのクレジットカードへ請求された施設の設定通貨での合計金額です。これには、Stripeから請求された手数料が含まれます。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        チェックイン日 
                                        <ul>
                                            <li>
                                                ゲストが予約したチェックイン日です。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        チェックアウト日 
                                        <ul>
                                            <li>
                                                ゲストが予約したチェックアウト日です。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        決済日 
                                        <ul>
                                            <li>
                                                支払いが承認された日、またはゲストが支払いを行った日です。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        予約 
                                        <ul>
                                            <li>
                                                ゲストの予約または予約番号です。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        予約ステータス 
                                        <ul>
                                            <li>
                                                最新の予約状況を示します。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        支払いステータス 
                                        <ul>
                                            <li>
                                                予約の最新支払い状況を示します
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        入金 
                                        <ul>
                                            <li>
                                                ホテルへの支払いが決済された日です。 
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        詳細 
                                        <ul>
                                            <li>
                                                閲覧で使用されたクレジットカードの種類や支払い参照番号など、取引の詳細がポップアップで表示されます。 
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <!-- Settlement END -->

                            <!-- Download START -->
                            <div id="download">
                                <p class="text-justify">
                                <span class="font-weight-bold">ダウンロード</span>
                                <br />
                                ゲストが送信した身分証明書写真やパスポート写真をダウンロードすることができます。「日付を選択」を設定してダウンロードしてください。ダウンロードは一日分ずつとなります。
                                </p>
                            </div>
                            <!-- Download END -->

                            <!-- Receipt issuing function START -->
                            <div id="receipt">
                                <p class="text-justify">
                                <span class="font-weight-bold">領収書発行機能</span>
                                <br />
                                支払い方法がクレジットカードの場合のみゲストはチェックアウト完了メールとともにPDFファイルでの領収書受領が可能です。
                                <br />
                                施設がゲストに発行した領収書を見る場合はリスト閲覧、チェックアウトタブから閲覧することが可能です。
                                </p>
                            </div>
                            <!-- Receipt issuing function END -->

                            <!-- Status display function START -->
                            <div id="status-display">
                                <p class="text-justify">
                                <span class="font-weight-bold">ステータス表示機能</span>
                                <br />
                                ゲストの予約、支払いステータスをリストから見ることが可能です。ステータス表示は以下の通りです。
                                </p>
                                <ul>
                                <li>
                                    支払ステータス
                                    <ul>
                                    <li>
                                        未払い
                                        <ul>
                                        <li>支払いがまだの状態</li>
                                        </ul>
                                    </li>
                                    <li>
                                        支払済み
                                        <ul>
                                        <li>
                                            ゲストが既に予約サイトで予約時にクレジット、または他の方法で支払い済
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        支払い承認済み
                                        <ul>
                                        <li>
                                            ゲストがクレジットカードで施設への支払いを選択し承認が済んでいる状態
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        支払い確定済み
                                        <ul>
                                        <li>
                                            ゲストがクレジットカードで施設への支払いを選択し承認が確定している状態
                                            <br />
                                            (承認から確定に移行する期間は5日程になります。)
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        現地フロントで支払う
                                        <ul>
                                        <li>
                                            ゲストがお支払い方法を現地支払に選択している状態
                                            <br />
                                            (支払いのタイミングはチェックイン、チェックアウト時が選択可能です。施設ごとに設定できます。)
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        返金済み
                                        <ul>
                                        <li>ゲストへの返金は済んでいる状態</li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    予約ステータス
                                    <ul>
                                    <li>
                                        事前チェックイン
                                        <ul>
                                        <li>ゲストが既に事前チェックインをしている状態</li>
                                        </ul>
                                    </li>
                                    <li>
                                        チェックイン済
                                        <ul>
                                        <li>
                                            ゲストが施設に到着しチェックインが完了している状態
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        チェックアウト完了
                                        <ul>
                                        <li>ゲストがチェックアウトしている状態</li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- Status display function END -->

                            <!-- Digital Key START -->
                            <div id="digital-key">
                              <p class="text-justify">
                                <span class="font-weight-bold">デジタル鍵</span>
                                <br />
                                タッチレスチェックインシステムはデジタル鍵に対応しており、管理画面のチェックイン情報のタブから鍵番号などの詳細情報を閲覧することができます。加えて、デジタル鍵に対応している場合、ゲストはメールで個別に鍵番号もしくは入室コードを通知され、マイページ上にもデジタル鍵もしくは入室コードの情報が表示されます。
                              </p>
                              <p class="text-justify">
                                 ゲストが鍵番号もしくは入室コードを通知されるタイミングは到着時にチェックインが確認された時点で通知を行なっています。事前チェックインが完了したタイミングでは通知しないのでご注意ください。加えて、鍵システムとの連携具合により、ゲストにメールで通知に数分を要する場合もありますので、その旨もご認識ください。  
                              </p>
                            </div>
                            <!-- Digital Key END -->

                            <!-- Timing for sending email START -->
                            <div id="timing-email">
                                <p>
                                <span class="font-weight-bold">メール送信のタイミング</span>
                                <br />
                                本システムからゲストへのメール送信のタイミングです。ゲスト向けのメール内容の修正はダッシュボードから行えます。
                                </p>
                                <div class="table-responsive">
                                <table class="table table-bordered text-black">
                                    <thead class="table-primary">
                                    <tr class="text-center">
                                        <th class="align-middle" rowspan="2" style="width: 20%">
                                        メッセージの種類
                                        </th>
                                        <th class="align-middle" rowspan="2" style="width: 20%">
                                        送信タイミング
                                        </th>
                                        <th colspan="2">受信者</th>
                                        <th colspan="2">備考</th>
                                    </tr>
                                    <tr class="text-center">
                                        <th class="align-middle" style="width: 10%">訪問者</th>
                                        <th class="align-middle" style="width: 10%">施設</th>
                                        <th class="align-middle" style="width: 20%">訪問者</th>
                                        <th class="align-middle" style="width: 20%">施設</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>事前チェックイン案内 メール</td>
                                        <td>チェックイン前</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">-</td>
                                        <td>
                                        メールに記載されているリンクから、ゲストが事前にチェックインすることができます。
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>事前チェックイン完了 メール</td>
                                        <td>事前チェックイン後</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">-</td>
                                        <td>
                                        事前チェックイン完了のご案内とチェックインやチェックアウトの際に使用できるパスコードをメールでお送りします。
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>チェックイン完了メール</td>
                                        <td>チェックイン後</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">〇</td>
                                        <td>
                                        事前チェックインを行わなかった場合でも、チェックアウト時に使用できるパスコードが記載されたメールを受け取ることができます。
                                        </td>
                                        <td>
                                        ゲストのチェックイン/到着後の予約情報が受け取れます。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>チェックアウト完了メール</td>
                                        <td>チェックアウト後</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">〇</td>
                                        <td>
                                        チェックアウトの確認メールを受け取ることができます。
                                        </td>
                                        <td>
                                        ゲストのチェックアウト時間、お名前、Eメール情報が受け取れます
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="text-justify">
                                    <span>
                                    ※メール内容の編集は施設のみでしか行えません。
                                    </span>
                                </p>
                                </div>
                            </div>
                            <!-- Timing for sending email END -->

                            <!-- Multiple room check-in START -->
                            <div id="multiple-room">
                                <p class="text-justify">
                                <span class="font-weight-bold">複数客室チェックイン</span>
                                <br />
                                一括で複数の客室を予約した場合、各客室ごとのゲストが個別にチェックインできる機能です。この機能は既存のホテルシステムとの連携が必要になる場合がありますので設定前に詳細はお問い合わせください。
                                </p>
                            </div>
                            <!-- Multiple room check-in END -->
                        </div>
                    </div>
                    <!-- Useful Functions END -->
                </template>

                <template v-else>
                    <h3 class="text-center">Touchless Check-in Start Guide</h3>
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">
                                1. Items that need to be set for the first time:
                            </h6>
                            <ul>
                                <li><a href="#setting">Setting</a></li>
                                <li><a href="#check-in-filters">Check-in Filters</a></li>
                                <li><a href="#questions">Questions</a></li>
                                <li><a href="#payment">Payment</a></li>
                                <li><a href="#terms">Terms</a></li>
                                <li><a href="#form-control">Form Control</a></li>
                                <li><a href="#notif-control">Notification Control</a></li>
                                <li><a href="#quick-check-in-and-out">Quick Checkin/out</a></li>
                                <li><a href="#quick-pass">Quick Pass</a></li>
                                <li><a href="#my-page">MyPage</a></li>
                                <li><a href="#qr-code">QR Code</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">2. Useful Functions:</h6>
                            <ul>
                                <li><a href="#list">Check-in List</a></li>
                                <li><a href="#reservation-list">Reservation List</a></li>
                                <li><a href="#analytics">Analytics</a></li>
                                <li><a href="#settlement">Settlement</a></li>
                                <li><a href="#download">Download</a></li>
                                <li><a href="#receipt">Receipt issuing function</a></li>
                                <li><a href="#status-display">Status display function</a></li>
                                <li><a href="#digital-key">Digital Key</a></li>
                                <li><a href="#timing-email">Timing for sending email</a></li>
                                <li><a href="#multiple-room">Multiple room check-in</a></li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <p class="text-justify">
                        This guide is intended for both users who have implemented
                        customization, such as linking the touchless check-in system to a PMS,
                        and users who have not implemented customization. Please note that
                        some features are only available to users who have implemented the
                        customization.
                        </p>
                    </div>

                    <!-- Items that need to be set for the first time START -->
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">
                                1. Items that need to be set for the first time:
                            </h6>

                            <!-- Setting START -->
                            <div id="setting">
                                <p class="text-justify">
                                <span class="font-weight-bold">Setting</span>
                                <br />
                                There are tabs, namely Main, Manage Facility Name, Language
                                Options, and Additional Emails.
                                </p>
                                <ul>
                                <li>
                                    Main
                                    <ul>
                                    <li>
                                        You can modify the default facility name, email address, and
                                        facility address you previously set during registration. If
                                        you need to make corrections, please do so here.
                                    </li>
                                        <li>
                                            The default facility name will be displayed as the default email sender for the precheck-in guide. This will also be the default email sender for all other emails that the guest will be receiving if there are no available facility names for other languages.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Manage Facility Name
                                    <ul>
                                    <li>
                                        You can add the facility name displayed according to the
                                        language setting of the guest's smartphone browser.
                                    </li>
                                    <li>
                                        If the facility name is different in languages, click the "Add" button and enter the name in the foreign language. The facility name input in each language field will be automatically applied as the email sender for all guest emails (check-in guide, confirmation, request payment, receipt) in other languages.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    Language Options
                                    <ul>
                                    <li>
                                        You can select the language that guests will see on the
                                        check-in form. This setting will allow you to create
                                        check-in form items for each language.
                                    </li>
                                    <li>
                                        Available languages in the system are Japanese, English,
                                        Korean, Simplified Chinese, and Traditional Chinese.
                                    </li>
                                    </ul>
                                </li>
                                <!-- Additional Emails START -->
                                <li>
                                    Additional Emails
                                    <ul>
                                       <li>
                                          You can set multiple emails, other than the currently registered email address 
                                          to receive notifications for guest check-in/check-out information and payout details. 
                                       </li>
                                       <li>
                                          Guest Check-in and Check-out Emails 
                                          <ul>
                                             <li>
                                                This is an email notification for all guest-related activities during check-in/check-out, 
                                                including payment settlement. 
                                             </li>
                                          </ul>
                                       </li>
                                       <li>
                                          Payout Email 
                                          <ul>
                                             <li>
                                                This is an email notification when the payout has been executed. 
                                                This is a notification mostly for accounting staff. 
                                             </li>
                                          </ul>
                                       </li>
                                    </ul>
                                </li>
                                <!-- Additional Emails END -->

                                </ul>
                            </div>
                            <!-- Setting END -->

                            <!-- Check-in Filters START -->
                            <div id="check-in-filters">
                                <p class="text-justify">
                                <span class="font-weight-bold">Check-in Filters</span>
                                <br />
                                If you want to sort your guests, you can set up to five
                                filters/categories for the check-in form. To add a filter target,
                                click the "Add" button and enter a filter name to register it.
                                </p>
                            </div>
                            <!-- Check-in Filters END -->

                            <!-- Question START -->
                            <div id="questions">
                                <p class="text-justify">
                                <span class="font-weight-bold">Question</span>
                                <br />
                                Questions to be displayed in the check-in form can be set here. By
                                default, system-generated questions are already set and are
                                selected to be displayed. You can include additional questions and
                                adjust the visibility of the system-generated question in here.
                                When making multilingual settings, the user must enter in the
                                required language.
                                </p>
                            </div>
                            <!-- Question END -->

                            <!-- Payment START -->
                            <div id="payment">
                                <p class="text-justify">
                                <span class="font-weight-bold">Payment</span>
                                <br />
                                Facility can set the payment timing and the currency in this
                                section. This setting applies to all guests and cannot be set
                                individually. Please note that once you change the timing of all
                                payments and the payment currency, it will be applied immediately.
                                </p>
                                <ul>
                                <li>
                                    Timing of Payment
                                    <ul>
                                    <li>
                                        You can choose when the guest can pay.
                                        <ul>
                                        <li>
                                            <strong>Check-in. </strong> If guests need to pay before
                                            or at the check-in, please select this option. The
                                            payment function shows up in check-in form and guest
                                            pays while pre-check-in/check-in.
                                        </li>
                                        <li>
                                            <strong>Check-out. </strong> If guests need to pay at
                                            the checkout, please select this option. The payment
                                            function shows up in check-out form and guest pays while
                                            check-out.
                                        </li>
                                        <li>
                                            <strong>Deposit. </strong> If guests are required to provide 
                                            guest's credit card information to be saved on the check-in 
                                            system and automatically charged to their credit card after 
                                            check-out, please select this option. The payment function 
                                            shows up in check-in form and guest pays while pre-check-in/check-in. 
                                        </li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    Currency
                                    <ul>
                                    <li>
                                        You can set the payment currency that guests will see on
                                        check-in and other forms. &#40;&#42;NOTE:
                                        <em>
                                        Currently, only JPY is available. We will be adding other
                                        currencies in the future.</em
                                        >&#41;
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- Payment END -->

                            <!-- Terms START -->
                            <div id="terms">
                                <p class="text-justify">
                                <span class="font-weight-bold">Terms</span>
                                <br />
                                Terms and conditions can be presented to guests at check-in. The
                                guest agrees to the terms after completing the check-in form.
                                Setting the terms is optional and can be adjusted for each
                                language as well. If you have any questions, such as accommodation
                                terms, that you would like us to read before checking in, please
                                fill out this form. It is also possible to create by language by
                                writing the content in the designated language.
                                </p>
                            </div>
                            <!-- Terms END -->

                            <!-- Form Management START -->
                            <div id="form-control">
                                <p class="text-justify">
                                <span class="font-weight-bold">Form Management</span>
                                <br />
                                Set the title and text to be displayed on the check-in screen for
                                guests' smartphones and locally installed terminals. The text is
                                set by default. You can change or add text to suit your facility.
                                </p>

                                <!-- Text at local check-in START -->
                                <div>
                                <span>Text at local check-in.</span>
                                <ul>
                                    <li>
                                    Arrival/Check-in Form Title.
                                    <ul>
                                        <li>
                                        This is the first screen title that a guest will see when
                                        checking in.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Arrival/Check-in form information text.
                                    <ul>
                                        <li>
                                        This is displayed as the instruction in the form and asks
                                        the Guest to enter information.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Arrival / check-in form completion statement.
                                    <ul>
                                        <li>
                                        This is the text that is displayed on the screen after the
                                        Guest completes the form and presses the "Submit" button.
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Text at local check-in END -->

                                <!-- Checkout text START -->
                                <div>
                                <span>Checkout text</span>
                                <ul>
                                    <li>
                                    Checkout Form Title.
                                    <ul>
                                        <li>
                                        This is the first screen title that a guest will see when
                                        checking out.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Checkout form guidance text.
                                    <ul>
                                        <li>
                                        This is displayed as the instruction in the form and asks
                                        the guest to enter information.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Checkout form completion statement.
                                    <ul>
                                        <li>
                                        This is the text that is displayed on the screen after the
                                        guest completes the form and presses the "Submit" button.
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Checkout text END -->

                                <!-- Pre-check-in text START -->
                                <div>
                                <span>Pre-check-in text</span>
                                <ul>
                                    <li>
                                    Pre-check-in form Title
                                    <ul>
                                        <li>
                                        This is the screen title that is first displayed when a
                                        guest pre-checks in on their smartphone.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Pre-check-in form information text.
                                    <ul>
                                        <li>
                                        This is displayed as the instruction in the form and asks
                                        the guest to enter information.
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    Pre-check-in form completion statement
                                    <ul>
                                        <li>
                                        This is the text that is displayed on the screen after the
                                        guest has completed the form and clicked the "Submit"
                                        button.
                                        </li>
                                    </ul>
                                    </li>
                                </ul>
                                </div>
                                <!-- Pre-check-in text END -->

                                 <!-- Check-in Filter Message START -->
                                 <div>
                                    <span>Check-in Filter Message</span>
                                    <ul>
                                       <li>Edit the message for guest to select filters. 
                                          These messages are displayed at the upper side of the check-in filter section page in the guests' 
                                          pre-check-in and check-in form.
                                       </li>
                                    </ul>
                                 </div>
                                 <!-- Check-in Filter Message END -->
                            </div>
                            <!-- Form Management END -->

                            <!-- Notification Management START -->
                            <div id="notif-control">
                                <p class="text-justify">
                                <span class="font-weight-bold">Notification Management</span>
                                <br />
                                You can create a guide/instruction email to the guest once boking
                                has been confirmed. By default, the email contains the complete
                                booking reservation details of the guest. Click "Send sample" and
                                enter up to three (3) email addresses at the same time to receive
                                a sample of the actual email. If there are other facility names or
                                additional items, please enter them.
                                </p>
                                <ul>
                                 <!-- Pre-check-in email START -->
                                <li>
                                    Pre-check-in email
                                    <ul>
                                    <li>
                                        Pre-check-in guidance and post-check-in guidance emails can
                                        be created for guests who have completed their reservations.
                                    </li>
                                    </ul>
                                </li>
                                <!-- Pre-check-in email END -->

                                <!-- Confirmation mail START -->
                                <li>
                                    Confirmation mail
                                    <ul>
                                    <li>
                                        You can create a guidance email from the facility to the
                                        guest's mobile phone/tablet when the guest arrives or after
                                        registering for the check-in/out form. This message will be
                                        sent to both the guest and the facility. 
                                    </li>
                                    </ul>
                                </li>
                                <!-- Confirmation mail END -->

                                <!-- Schedule Pre Check-in Notice START -->
                                <li>
                                    Schedule Pre Check-in Notice
                                    <ul>
                                       <li>This function allows you to switch on or off the sending of pre-check-in guide to the guests. 
                                          By turning on this function, pre-check-in guide will automatically be sent to all confirmed reservations synched 
                                          to the system based on the timing set. 
                                       </li>
                                    </ul> 
                                </li>
                                <!-- Schedule Pre Check-in Notice END -->
                                </ul>
                            </div>
                            <!-- Notification Management END -->

                            <!-- Quick Check in/out START -->
                            <div id="quick-check-in-and-out">
                                <p class="text-justify">
                                <span class="font-weight-bold">Quick Check In/Out</span>
                                <br />
                                This feature allows the facility to let the guests use quick
                                check-in and out using QR code. This function provides quick and
                                alternative way to check-in and check-out. To use this feature,
                                you need to prepare a scanner connected to a computer. Ask us for
                                more information.
                                </p>
                                <p class="text-justify">Notes:​</p>
                                <ul>
                                <li>
                                    When this feature is turned on, the guests will receive a QR
                                    code by email upon precheck-in and check-in completion, which
                                    that they can use for quick check-in and out.
                                </li>
                                <li>
                                    You need to prepare the scanner for QR code from guest email and
                                    place it where the guests check-in and out.​​
                                </li>
                                <li>
                                    Prepare the computer, tablet, or smartphone, aside scanner and
                                    make sure to connect to scanner described above.
                                </li>
                                <li>
                                    Always keep the linked website page, the one displayed under
                                    Quick Check-in / Out on the System Dashboard, open on your
                                    computer, tablet or smartphone.
                                </li>
                                <br />
                                <em>Read carefully the condition for switch on and off.​</em>
                                <li>
                                    Condition: ON​
                                    <br />
                                    - Guests will receive QR code together with pass code after
                                    completing 1&#41;pre-check-in for Quick Check-in and 2&#41;
                                    check-in for Quick Check-out.
                                    <br />
                                    - Even on, guests can also check-in/out with pass and email as
                                    what we currently have. Guests will have the options on how to
                                    do check-in and out.
                                </li>
                                <li>
                                    Condition: OFF
                                    <br />
                                    - Guests can only check-in and out by using pass and email
                                    address in the check-in and out form (regular check-in and
                                    out).
                                </li>
                                </ul>
                                <p>In addition, it is possible to change the contents of the QR code 
                                  to the reservation number. By turning on the switch on the pattern tab, 
                                  the QR code pattern sent to the guest will change to the reservation number. 
                                  The default QR code consists of the guest's email and password. 
                                  This format can be changed at any time depending on the operation. 
                                  (This is a function for core system companies such as PMS) 
                                </p>
                            </div>
                            <!-- Quick Check in/out END -->

                            <!-- Quick Pass START -->
                            <div id="quick-pass">
                                <p class="text-justify">
                                <span class="font-weight-bold">Quick Pass</span>
                                <br />
                                This feature allows the facility to let the guests use quick pass
                                for the facility services using QR code. This function provides
                                quick and alternative way to check the guest’s booking and plan.
                                Switch feature to allow the guests to use QR code as service
                                coupon and prepare scanner and computer. Ask us for more
                                information.
                                </p>
                                <p class="text-justify">Notes:​</p>
                                <ul>
                                <li>
                                    By turning on this function, guests who have successfully
                                    prechecked-in, and checked-in will receive a QR code.
                                </li>
                                <li>
                                    Prepare the scanner for scanning QR code from guest email and
                                    place it at the place where guest needs to prove booking such as
                                    restaurants.
                                </li>
                                <li>
                                    Prepare the computer, tablet, or smartphone, and make sure to
                                    connect to scanner described above.
                                </li>
                                <li>
                                    Always keep the linked website page, the one displayed under
                                    Quick Pass on the System Dashboard, open on your computer,
                                    tablet or smartphone.​
                                </li>
                                <br />
                                <em>Read carefully the condition for switch on and off.​</em>
                                <li>
                                    Condition: ON​
                                    <br />
                                    - Guest can use the QR code received after completing
                                    precheck-in and check-in. This QR code can be scanned for
                                    display booking record of guest.
                                </li>
                                <li>
                                    Condition: OFF
                                    <br />
                                    - Guest cannot use Quick Pass. QR code can be used only 
                                    for quick check-in and check-out.
                                </li>
                                </ul>
                            </div>
                            <!-- Quick Pass END -->

                            <!-- myPage START -->
                            <div id="my-page">
                                <p class="text-justify">
                                <span class="font-weight-bold">MyPage</span>
                                <br />
                                MyPage is a dedicated function that supports the check-in process and stay of guests, designed mainly for browsing on smartphones. 
                                By using this function, it is possible to quickly process the check-in and check-out procedure for guests while quickly sharing information on the hotel during their stay and individual messages. 
                                </p>
                                <p class="text-justify">Setting​</p>
                                <ul>
                                <li>
                                    MyPage function switch
                                    <ul>
                                        <li>By turning on this function, it enables providing guests with the MyPage function and start sending a notice to guests. Please make sure to also turn on quick check-in and check-out features to provide QR codes for guests to check in or check out. </li>
                                    </ul>
                                </li>
                                <li>
                                    Express checkout
                                    <ul>
                                        <li>By turning on Express Checkout, guests will be able to check out directly from MyPage. Please note that express check-out is valid only on the check-out date, and guests cannot use express check-out prior to the check-out date. </li>
                                    </ul>
                                </li>
                                <li>
                                    Map Settings
                                    <ul>
                                        <li>Display the map coordinates and location of the hotel on MyPage for guests. Please attach the URL for embedding Google Maps. You can also check if the URL is working properly in the dashboard. Note that map settings are not required.</li>
                                    </ul>
                                </li>
                                <li>
                                    Background Image
                                    <ul>
                                        <li>You can upload an image to be displayed as the background image of the guest's MyPage. Note that the background image setting is not required.</li>
                                    </ul>
                                </li>
                                </ul>
                                <p class="text-justify">Message</p>
                                <ul>
                                  <li>
                                      Message Function
                                      <ul>
                                          <li>You can send messages directly to guests. 
                                              You can send a message from the message icon on the guest's detail page. 
                                          </li>
                                      </ul>
                                  </li>
                                  <li>
                                      Message face icon, Sample
                                      <ul>
                                          <li>The face icon to be displayed in the guest's message. 
                                              You can also check the samples from the dashboard. 
                                          </li>
                                      </ul>
                                  </li>
                                  <li>
                                      Message Template
                                      <ul>
                                          <li>With this function, you can save the contents and sentences of frequently sent messages as a template on the dashboard in advance and refer to them when sending messages.
                                          </li>
                                          <li>The template name is the subject of the message, which is displayed as the label and selected when referencing the message, while message is the text of the message associated with the template.
                                          </li>
                                      </ul>
                                  </li>
                                  <li>
                                      Send message
                                      <ul>
                                          <li>You can send a message by accessing the message icon from the guest details displayed in the check-in list.
                                          </li>
                                      </ul>
                                  </li>
                                </ul>
                                <p class="text-justify">Facility Info</p>
                                <ul>
                                  <li>
                                    Edit
                                    <ul>
                                      <li>Set and edit information of the internal facility on the dashboard, and display guidance items on the guest's smartphone. 
                                      </li>
                                      <li>By turning on the switch, facility information will be displayed to guests on MyPage. When switched off, facility information will not be visible to guests. 
                                      </li>
                                      <li>After setting on the dashboard, place the MyPage QR code in the most suitable place in the guest room or facility. By scanning the QR code by the guest own smartphone, 
                                        it displays the information in the facility set on the dashboard.  
                                      </li>
                                      <li>Add or edit information at any time from the dashboard. 
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    Sample
                                    <ul>
                                      <li>Check the sample screen display on the dashboard. 
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <p class="text-justify">Announcement Function</p>
                                <ul>
                                  <li>Announcement</li>
                                  <ul>
                                    <li>You can create and edit existing announcements for guests, such as facility events and elevator failures. 
                                      The contents of the announcement will be displayed on the guest's My Page. 
                                      Create the content of the announcement and set the valid period. 
                                    </li>
                                  </ul>
                                </ul>
                            </div>
                            <!-- myPage END -->

                            <!-- QR Code START -->
                            <div id="qr-code">
                                <p class="text-justify">
                                <span class="font-weight-bold">QR Code</span>
                                <br />
                                After completing the settings, please print out the QR code and
                                present it at the required location of the facility. Kindly
                                download the dedicated QR code to the terminal used at the
                                facility.
                                </p>
                                <ul>
                                <li>
                                    QR code for arrival form
                                    <ul>
                                    <li>
                                        This code is scanned when you arrive at the facility for
                                        both guests who have already checked in in advance and those
                                        who have not.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for arrival form (tablet)
                                    <ul>
                                    <li>
                                        This is a QR code that displays the arrival form on a
                                        tablet. Optimized for tablets.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for checkout
                                    <ul>
                                    <li>This code is scanned by the guest at checkout.</li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for pre-check-in
                                    <ul>
                                    <li>
                                        This QR code is rarely used and the facility can skip on
                                        displaying this in the reception area. This is the code that
                                        the person-in-charge of the user facility scans when
                                        checking the display and input method of the guest form.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                  MyPage
                                  <ul>
                                    <li>
                                      A QR code for installation in guest rooms and facilities. There is a login page for bookers and an option to just view property information. 
                                    </li>
                                  </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- QR Code END -->

                            <!-- QR Code START -->
                            <div id="qr-code">
                                <p class="text-justify">
                                <span class="font-weight-bold">QR Code</span>
                                <br />
                                After completing the settings, please print out the QR code and
                                present it at the required location of the facility. Kindly
                                download the dedicated QR code to the terminal used at the
                                facility.
                                </p>
                                <ul>
                                <li>
                                    QR code for arrival form
                                    <ul>
                                    <li>
                                        This code is scanned when you arrive at the facility for
                                        both guests who have already checked in in advance and those
                                        who have not.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for arrival form (tablet)
                                    <ul>
                                    <li>
                                        This is a QR code that displays the arrival form on a
                                        tablet. Optimized for tablets.
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for checkout
                                    <ul>
                                    <li>This code is scanned by the guest at checkout.</li>
                                    </ul>
                                </li>
                                <li>
                                    QR code for pre-check-in
                                    <ul>
                                    <li>
                                        This QR code is rarely used and the facility can skip on
                                        displaying this in the reception area. This is the code that
                                        the person-in-charge of the user facility scans when
                                        checking the display and input method of the guest form.
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- QR Code END -->
                        </div>
                    </div>
                    <!-- Items that need to be set for the first time END -->

                    <!-- Useful Functions START -->
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h6 class="font-weight-bold">2. Useful Functions:</h6>

                            <!-- List START -->
                            <div id="list">
                                <p class="text-justify">
                                <span class="font-weight-bold">Check-in List</span>
                                <br />
                                The list allows you to see the registration status and details of
                                the guest. You can use the search tool to search for any guest
                                information in detail.
                                </p>
                                <ul>
                                <li>
                                    Search function
                                    <ul>
                                    <li>
                                            You can use this to easily search a single or several reservations in the check-in list dashboard through simple search or advanced search. 
                                    </li>
                                    <li>
                                        Search Filters
                                        <ul>
                                            <li>
                                                To do a simple search on any reservation, you can navigate the "Customize" and “Conditions”. This will filter reservations based on check-in time and dates to and from, payment status, and booking status. 
                                            </li>
                                            <li>
                                                Filter conditions are automatically saved so you can display the information with the same condition when you access the page again. 
                                            </li>
                                            <li>
                                                You can easily choose “Clear Filter” if you wish to choose different conditions in searching. 
                                            </li>
                                            <li>
                                                If you want to do an advanced search for a specific guest, you can search using reservation ID, name, and phone. 
                                            </li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    ▼ button
                                    <ul>
                                    <li>
                                        You can download a list of guest information, clear check-in
                                        records, and change the way the guest list is displayed.
                                    </li>
                                    <li>
                                        Export Excel
                                        <ul>
                                        <li>
                                            You can download the complete guest information of the
                                            listed guests. The downloaded data is exported into an
                                            Excel data. This is a convenient function when you want
                                            to save data not only on the cloud but also as a ledger.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Clear check-in record
                                        <ul>
                                        <li>
                                            Normally, all the information registered from the
                                            check-in form is stored in the cloud. You can delete it
                                            if you want to delete the information registered for the
                                            test at the time of installation. However, please be
                                            careful when deleting the guest registration
                                            information. It cannot be restored.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Edit Column View
                                        <ul>
                                        <li>
                                            You can select from seven details to be displayed on the
                                            list screen: Full Name, Phone, Address, Email,
                                            Nationality, Payment Status, and Booking Status.
                                        </li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    View
                                    <ul>
                                    <li>
                                        You can see the complete details of the guest's registration
                                        information.
                                    </li>
                                    <li>
                                        On the guest information details page, the "Main",
                                        “QR/Pass", "Reservation", "Payment", and "Checkout" tabs are displayed.
                                    </li>
                                    <li>
                                        You can see details of guest reservation information,
                                        payment status, and checkout status by switching tabs.
                                        Viewing is per check-in (you can check on the same screen if
                                        you register multiple people at one check-in).
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- List END -->

                            <!-- Reservation List START -->
                            <div id="reservation-list">
                              <p class="text-justify">
                                <span class="font-weight-bold">Reservation List</span>
                                <br />
                                 The reservation list displays pre-check-in data imported from hotel systems such as PMS. When these reservations are pre-checked in or checked in either by guest or PMS, 
                                 those will be displayed on the check-in list. If a reservation displayed on this list is cancelled, it will be removed from this list. 
                              </p>
                            </div>
                            <!-- Reservation List END -->
                            
                            <!-- Analytics START -->
                            <div id="analytics">
                                <p class="text-justify">
                                <span class="font-weight-bold">Analytics</span>
                                <br />
                                    The analytics lets you monitor the daily real-time count of reservations made through the check-in system. You can navigate through each month. 
                                </p>
                                <ul>
                                    <li>
                                        Accumulated Check-in and Check-out
                                        <ul>
                                            <li>
                                                This list shows the total reservations made under each booking status.
                                                <ul>
                                                    <li>
                                                        Pre-check-in counts include all reservations who did pre-check-in. 
                                                    </li>
                                                    <li>
                                                        Check-in counts are categorized by the following: 
                                                        <ul>
                                                            <li>
                                                                Check-in made by guest. This includes all reservations who was checked-in by the guest named in the reservation. 
                                                            </li>
                                                            <li>
                                                                Check-in made by PMS. This includes all reservations checked-in by the hotel through the PMS.
                                                            </li>
                                                            <li>
                                                                Check-in made by companion. This includes all check-ins for the guest's companion.
                                                            </li>
                                                            <li>
                                                                Cancel after check-in. This includes all reservations which was cancelled after the guest has checked in. 
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        Check-out counts include the check-out made by guest, check-out made by PMS, and check-out made by companion. 
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Latest Check-in and Check-out
                                        <ul>
                                            <li>This list shows the live counts of the reservations under each booking status.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Difference in “Accumulated” and “Latest”
                                        <ul>
                                            <li>Accumulated counts show the total counts of status, thus the number goes up, only.</li>
                                            <li>Latest counts show the live movement of each reservation, changing from check-in to check-out, thus the number goes up and down up to the status.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <!-- Analytics END -->

                            <!-- Settlement START -->
                            <div id="settlement">
                                <p class="text-justify">
                                <span class="font-weight-bold">Settlement</span>
                                <br />
                                    This feature allows you to track all credit card payments made through the check-in system. You can see the amount of transaction, guest name, booking details, payment status, and payment details. Viewing is per transaction. 
                                </p>
                                <ul>
                                    <li>
                                        <span class="font-weight-bold">Amount. </span>This is the total amount in the facility's set currency that was charged to the guest's       credit card. This includes the fee charged by Stripe.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Check in date. </span>This is the check-in date of the guest reservation.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Check out date. </span>This is the check-out date of the guest reservation.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Settlement Date. </span>This is when the payment was authorized or the date the guest made the payment.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Reservation. </span>This is the reservation or booking number of the guest.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Booking Status. </span>This shows the latest booking status of the reservation.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Payment Status. </span>This shows the latest payment status of the reservation.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Payout. </span>This is when the payment was settled to the hotel.
                                    </li>
                                    <li>
                                        <span class="font-weight-bold">Detail. </span>This shows a pop-up with the complete details of the transaction, including type of credit card used and payment reference number.
                                    </li>
                                </ul>
                            </div>
                            <!-- Settlement END -->

                            <!-- Download START -->
                            <div id="download">
                                <p class="text-justify">
                                <span class="font-weight-bold">Download</span>
                                <br />
                                You can download the ID and passport photos sent by the guests.
                                Please set "Select date" and download. The downloaded pictures
                                will be for all the reservations made in the selected day.
                                </p>
                            </div>
                            <!-- Download END -->

                            <!-- Receipt issuing function START -->
                            <div id="receipt">
                                <p class="text-justify">
                                <span class="font-weight-bold">Receipt issuing function</span>
                                <br />
                                Only if the payment method is credit card and paid thru the
                                system, guest can receive the receipt as a PDF file with the
                                checkout completion email.
                                <br />
                                If you wish to view the receipts issued by the facility to guests,
                                you can do so from the guest List. In the guest's name, click View
                                and then look under the check out tab.
                                </p>
                            </div>
                            <!-- Receipt issuing function END -->

                            <!-- Status display function START -->
                            <div id="status-display">
                                <p class="text-justify">
                                <span class="font-weight-bold">Status display function</span>
                                <br />
                                It is possible to view the guest's reservation and payment status
                                from the list.
                                <br />
                                The status display is as follows
                                </p>
                                <ul>
                                <li>
                                    Payment Status
                                    <ul>
                                    <li>
                                        Not paid
                                        <ul>
                                        <li>Payment has not yet been made</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Paid
                                        <ul>
                                        <li>
                                            Guest has already paid by credit card or other means at
                                            the time of booking on the reservation site.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Paid Authorized
                                        <ul>
                                        <li>
                                            Guest has paid for the accommodation with a credit card
                                            and has been authenticated.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Paid Captured
                                        <ul>
                                        <li>
                                            Guest has paid for the accommodation by credit card and
                                            the payment has been confirmed.
                                            <br />
                                            (The transition period from approval to finalization
                                            will be 5 days.)
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Pay at Hotel Reception
                                        <ul>
                                        <li>
                                            Guest has selected the payment method as local payment.
                                            <br />
                                            (The timing of local payment varies depending on the
                                            facility's setup.)
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Refunded
                                        <ul>
                                        <li>Guest’s payment has been refunded.</li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                <li>
                                    Reservation Status
                                    <ul>
                                    <li>
                                        Pre Checked-in
                                        <ul>
                                        <li>
                                            Status that guest has already checked in in advance.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Checked in
                                        <ul>
                                        <li>
                                            Status that guest has already arrived at the facility
                                            and is checking in.
                                        </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Checkout completed
                                        <ul>
                                        <li>State that the guest is checking out.</li>
                                        </ul>
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </div>
                            <!-- Status display function END -->

                            <!-- Digital Key START -->
                            <div id="digital-key">
                              <p class="text-justify">
                                <span class="font-weight-bold">Digital Key</span>
                                <br />
                                 We support digital keys, and detailed information such as the key number can be viewed from the check-in information tab on the dashboard. 
                                 In addition, in the case that digital keys are supported, guests will be individually notified of their key number or entry code via email, 
                                 and the digital key or entry code information will also be displayed on their MyPage. 
                              </p>
                              <p class="text-justify">
                                 Guests will be notified of their key number or entry code when their check-in is confirmed at the time of arrival. Note that guests will not be notified when "pre-check-in" is completed. 
                                 In addition, please be aware that depending on the connectivity with the key system, it may take several minutes for the guest to be notified via email.  
                              </p>
                            </div>
                            <!-- Digital Key END -->

                            <!-- Timing for sending email START -->
                            <div id="timing-email">
                                <p class="text-justify">
                                <span class="font-weight-bold">Timing for sending email </span>
                                <br />
                                This is the timing for sending emails from this system to guests.
                                You can modify the contents of the emails for guests from the
                                dashboard.
                                </p>
                                <div class="table-responsive">
                                <table class="table table-bordered text-black">
                                    <thead class="table-primary">
                                    <tr class="text-center">
                                        <th class="align-middle" rowspan="2">Kind of message</th>
                                        <th class="align-middle" rowspan="2">When</th>
                                        <th colspan="2">Receiver (Who)</th>
                                        <th colspan="2">Remarks</th>
                                    </tr>
                                    <tr class="text-center">
                                        <th>Guest</th>
                                        <th>Facility</th>
                                        <th>Guest</th>
                                        <th>Facility</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Precheck-in Guide Email</td>
                                        <td>Before Check-in</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">-</td>
                                        <td>
                                        Guest can do pre check-in through the link in the email
                                        message
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Precheck-in Confirmation Email</td>
                                        <td>After Pre check-in</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">-</td>
                                        <td>
                                        Guest will receive email with the pass code, which can be
                                        used for checking in and checking out.
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Check-in/Facility Arrival Confirmation Email</td>
                                        <td>after check-in</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">〇</td>
                                        <td>
                                        If guest did not do precheck-in, guest can still receive
                                        the email with pass code, which can be used for checking
                                        out.
                                        </td>
                                        <td>
                                        Facility will receive the complete booking information of
                                        the guest after check-in/arrival at the facility.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Check-out Confirmation Email</td>
                                        <td>after check-out</td>
                                        <td class="text-center">〇</td>
                                        <td class="text-center">〇</td>
                                        <td>Guest can receive check-out confirmation.</td>
                                        <td>
                                        Facility will receive the guest's check-out time, name,
                                        and email after check-out.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p class="text-justify">
                                    <span>
                                    ※Editing can only be done by the facility.
                                    </span>
                                </p>
                                </div>
                            </div>
                            <!-- Timing for sending email END -->

                            <!-- Multiple room check-in START -->
                            <div id="multiple-room">
                                <p class="text-justify">
                                <span class="font-weight-bold">Multiple room check-in</span>
                                <br />
                                This is a feature that allows the guests to check-in individually
                                for each room when multiple rooms are reserved in bulk. This
                                feature may require integration with existing hotel systems, so
                                please contact us for details before setting up.
                                </p>
                            </div>
                            <!-- Multiple room check-in END -->
                        </div>
                    </div>
                    <!-- Useful Functions END -->
                </template>
            </div>
            <a href="javascript:void(0)" class="back-to-top-icon" @click="scrollToTop('top')">
                <i class="fa fa-chevron-up"></i>
            </a>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12 text-center">
                <router-link to="/dashboard" class="btn btn-danger">{{ $t("help.back") }}</router-link>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
            scrollToTop(id) {
               window.scrollTo(0,0);
               setTimeout(() => {
                  document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
                  setTimeout(() => {
                     window.scrollTo(0,0);
                  },1000);
               },100);
            }
        },
        mounted() {

        },
        created() {

        }
    }
</script>
<style lang="scss" scoped>
.start-guide {
    overflow: hidden scroll;
   //  height: 680px !important;
    height: 560px !important;
    position: relative;

    .text-black {
        color: #000000 !important;
    }
    .back-to-top-icon {
        right: 15px;
        bottom: 15px;
        position: sticky;
        float: right;
        text-decoration: none !important;
        i {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background: #4e73df96;
            color: #fff;
            transition: all 0.4s;
            &:hover {
                background: #4e73df;
            }
        }
    }
}
</style>