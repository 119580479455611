<template>
    <div>
        <div class="form-group row" v-if="!multiple">
            <template v-if="companions.length > 0 && !confirm">
                <template v-for="(companion, index) in companions">
                    <label class="col-sm-4 col-form-label">
                        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
                        <div>
                            ({{ showLabelOrPlaceholder($t('visit-information-form.companion'), index, '', 'dropdown') }})
                            <small v-if="c.required" class="text-danger">*</small>
                        </div>
                    </label>
                    <div class="col-sm-8 my-auto">
                        <select
                        v-if="!confirm"
                        v-model="companion.other_forms[c.id]"
                        :required="c.required ? true : false"
                        class="form-control mt-1">
                            <option value="">--</option>
                            <option v-for="n in changeChoices('relationships')" :value="n">{{ n }}</option>
                        </select>
                    </div>
                </template>
            </template>
        </div>
        <!-- Multiple Room -->
        <div class="form-group row" v-else>
            <template v-if="multiple_data[bd_index].companions.length > 0 && !confirm">
                <template v-for="(companion, index) in multiple_data[bd_index].companions">
                    <label class="col-sm-4 col-form-label">
                        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
                        <div>
                            ({{
                                showLabelOrPlaceholder(
                                    $t('visit-information-form.companion'),
                                    index,
                                    '',
                                    'dropdown',
                                    true,
                                    bd_index
                                )
                            }})
                            <small v-if="c.required" class="text-danger">*</small>
                        </div>
                    </label>
                    <div class="col-sm-8 my-auto">
                        <select
                        v-if="!confirm"
                        v-model="companion.other_forms[c.id]"
                        :required="c.required ? true : false"
                        class="form-control mt-1">
                            <option value="">--</option>
                            <option v-for="n in changeChoices('relationships')" :value="n">{{ n }}</option>
                        </select>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
    import { main_form_methods } from "../../../../../utils/mockup";
    const { showLabelOrPlaceholder, changeChoices } = main_form_methods;
    export default {
        props: {
            c: null,
            confirm: false,
            visitor_forms: {
                type: Object,
                default: {}
            },
            companions: {
                type: Array,
                default: []
            },
            different: {
                type: Array,
                default: []
            },
            questions: {
                type: Array,
                default: []
            },
            // Multiple room
            multiple: false,
            multiple_data: {
                type: Array,
                default: []
            },
            bd_index: null,
            // End of multiple room
        },
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            showLabelOrPlaceholder,
            changeChoices
        }
    }
</script>
