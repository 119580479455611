<template>
   <b-modal id="banner-view" centered hide-footer>
      <div class="row">
         <div class="col-lg-12">
            <img :src="src" class="img-fluid" />
         </div>
      </div>
      <div class="row mt-3">
         <div class="col-sm-6">
            <button class="btn btn-block btn-outline-danger" @click="$bvModal.hide('banner-view')">{{ $t("account-page-facility-my-page-category.cancel") }}</button>
         </div>
         <div class="col-sm-6">
            <button class="btn btn-block btn-outline-primary" @click="remove">{{ $t("account-page-facility-my-page-category.remove") }}</button>
         </div>
      </div>
   </b-modal>
</template>

<script>
export default {
   props: ["src"],
   data() {
      return {
         data: {
            src: null,
         },
      };
   },
   methods: {
      remove() {
         this.$emit("event", null);
         this.$bvModal.hide("banner-view");
      },
   },
};
</script>
