<template>
  <div>
    <div class="form-group row" v-if="!multiple && !isFixGuestEnabled">
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-8 my-auto">
        <input
          v-if="!confirm"
          v-model="inputData[c.id]"
          type="number"
          step="0.1"
          :required="c.required ? true : false"
          class="form-control"
          :placeholder="c.placeholder"
        />
        <p v-if="confirm" class="mb-0">{{ inputData[c.id] }}</p>

        <!-- For data aggregation -->
        <template v-if="companions.length > 0 && !confirm">
          <template v-for="(companion, index) in companions">
            <input
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              type="number"
              step="0.1"
              :required="c.required ? true : false"
              class="form-control mt-3"
              :placeholder="
                showLabelOrPlaceholder(
                  $t('visit-information-form.companion'),
                  index,
                  c.placeholder,
                  'placeholder'
                )
              "
            />
          </template>
        </template>
      </div>
    </div>

    <div  class="form-group row"  v-else-if="!multiple && isFixGuestEnabled">
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>

      <div class="col-sm-8 my-auto">
        <input
          v-if="!confirm"
          v-model="visitor_forms[c.id]"
          type="number"
          step="0.1"
          :required="c.required ? true : false"
          class="form-control"
          :placeholder="c.placeholder"
        />
        <p v-if="confirm" class="mb-0">{{ visitor_forms[c.id] }}</p>

        <!-- For data aggregation -->
        <template v-if="fixGuestCount > 1 && !confirm">
          <template v-for="(companion, index) in companions">
            <input
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              type="number"
              step="0.1"
              :required="c.required ? true : false"
              class="form-control mt-3"
              :placeholder="
                showLabelOrPlaceholder(
                  $t('visit-information-form.companion'),
                  index,
                  c.placeholder,
                  'placeholder'
                )
              "
            />
          </template>
        </template>
      </div>
      

    </div>

    <!-- Multiple Room -->
    <div class="form-group row" v-else>
      <label class="col-sm-4 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-8">
        <input
          v-if="!confirm"
          v-model="multiple_data[bd_index].visitor_forms[c.id]"
          type="number"
          step="0.1"
          :required="
            multiple_data[bd_index].filled_in_by_other
              ? false
              : c.required
              ? true
              : false
          "
          class="form-control"
          :placeholder="c.placeholder"
        />
        <p v-if="confirm">{{ multiple_data[bd_index].visitor_forms[c.id] }}</p>

        <!-- For data aggregation -->

        <template
          v-if="multiple_data[bd_index].companions.length > 0 && !confirm"
        >
          <template
            v-for="(companion, index) in multiple_data[bd_index].companions"
          >
            <input
              v-if="!confirm && different.includes(c.system_name)"
              v-model="companion.other_forms[c.id]"
              type="number"
              step="0.1"
              :required="c.required ? true : false"
              class="form-control mt-3"
              :placeholder="
                showLabelOrPlaceholder(
                  $t('visit-information-form.companion'),
                  index,
                  c.placeholder,
                  'placeholder',
                  true,
                  bd_index
                )
              "
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { checkinFormTypeWithMemorization, loadMemorizedData } from "../../../../utils/helpers";
import { main_form_methods } from "../../../../utils/mockup";
const { showLabelOrPlaceholder, populateCompanionsFields, isFixGuestEnabled } = main_form_methods;
import { mapState, mapActions } from "vuex";
import guestWatcherMixin from "../../../../../../mixins/guestWatcherMixin";

export default {
  props: {
    c: null,
    confirm: false,
    visitor_forms: {
      type: Object,
      default: {},
    },
    companions: {
      type: Array,
      default: [],
    },
    different: {
      type: Array,
      default: [],
    },
    questions: {
      type: Array,
      default: [],
    },
    // Multiple room
    multiple: false,
    multiple_data: {
      type: Array,
      default: [],
    },
    bd_index: null,
    // End of multiple room
    // checkin form type
    checkin_form_type: "",
    reservation_no: null,
    fixGuestCount: {
      type: Number,
      default: null
    },
    fixGuestSetting: {
      type: Object,
      default: {}
    }
  },
  mixins: [guestWatcherMixin],
  data() {
    return {
      inputData: {},
      formType: "",
    }
  },
  computed: {
    ...mapState("memorizedInputs", [
      "memorizedInputs",
      "memorizedWalkInInputs",
      "rid"
    ]),
    isFixGuestEnabled
  },
  updated() {
    this.handleFormType();
  },
  async mounted() {
    await this.initializeForm();
  },
  methods: {
    ...mapActions("memorizedInputs", [
                  "saveInput", 
                  "saveWalkInInputs",
                  "saveReservationNumber",
                  "checkIfCheckinFormType"
                ]),

    handleFormType() {
      this.checkin_form_type === "others"
        ? this.saveWalkInInputs(this.inputData)
        : this.saveInput(this.inputData);
    },
     async initializeForm() {

      const rid = this.$route.query.rid
      const guestCountSetting = await this.guestCountSetting
      checkinFormTypeWithMemorization(this, rid, this.reservation_no, this.checkin_form_type);
      
      this.inputData = await loadMemorizedData(
        this,
        this.memorizedInputs,
        this.memorizedWalkInInputs,
        this.checkin_form_type,
        this.visitor_forms
      );

      this.populateCompanionsFields();
    },
    showLabelOrPlaceholder,
    populateCompanionsFields,
  },
};
</script>