<template>
  <div id="dashboard_wrapper">
    <!-- Page Heading -->
    <h1 class="h3 mb-4 text-gray-800">
      {{ $t("account-page.dashboard-title") }}
    </h1>

    <div class="row">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  <a href="/dashboard/qrcode">{{
                    $t("account-page.qr-code")
                  }}</a>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-qrcode fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.qr-code-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/visitors">
                    {{ $t("account-page.visitor-list") }} ({{
                      visitor_logs.total || "..."
                    }})
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-chart-bar fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.visitor-list-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/questions">
                    {{ $t("account-page.questions-title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-question-circle fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.questions-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/downloads">
                    {{ $t("account-page-downloads.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-download fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.downloads-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/notif-control">
                    {{ $t("account-page-notif-control.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-envelope fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-notif-control.tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/form-control">
                    {{ $t("account-page-form-control.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fas fa-edit fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-form-control.tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/filter">
                    {{ $t("account-page.filter-title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-filter fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.filter-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/mr-setting">
                    {{ $t("account-page.mr-title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-door-open fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.mr-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/tac">
                    {{ $t("account-page.terms") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-info-circle fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.terms_tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/payment">
                    {{ $t("account-page-payment.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-cash-register fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page-payment.tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/quick-checkout-in-setting">
                    {{ $t("account-page.quick-checkout-in-switch-title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-sign-out-alt fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.quick-checkout-in-switch-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="facility.has_pms" class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/quick-service-setting">
                    {{ $t("quick_service_setting.title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-ticket-alt fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("quick_service_setting.tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 col-lg-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  <router-link to="/dashboard/setting">
                    {{ $t("account-page.setting-title") }}
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users-cog fa-2x text-gray-300"></i>
              </div>
            </div>
            <div class="row no-gutters">
              <span class="text-muted small">{{
                $t("account-page.setting-tip")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <help-component></help-component>
      <check-component />
    </div>
  </div>
</template>

<script>
import HelpComponent from "./../../../components/help.component";
export default {
  components: {
    HelpComponent
  },
  data() {
    return {
      facility: {},
      visitor_logs: {
        total: 0,
        data: [],
      },
    };
  },
  async mounted() {
    this.facility = window._app_facility;

    const visitors = await this.$lionheart
      .post("/log/get/facility-visitor", {
        paginate: false,
        facility_id: this.facility.id,
      })
      .then((r) => this.catcher(r.data, "data", (r) => r))
      .catch((e) => this.catcher(e));

    this.visitor_logs = visitors;
    this.visitor_logs.total = visitors.total == 0 ? "0" : visitors.total;
  },
};
</script>
