<template>
  <div class="mt-n2">
    <div class="panel-header">
      <b>{{ $t("visitor_log_detail_qr_pass.sub_heading_qr_pass") }}</b>
      <div>
        <b-button
            variant="primary"
            size="sm"
            class="btn-w-md"
            @click.prevent="openSend"
          v-if="!isCheckout">
            {{ $t("visitor_log_detail_qr_pass.button_send_to") }}
          </b-button>
      </div>
    </div>
    <div class="panel-body">
      <b-alert
        variant="success" :show.sync="showSuccess" class="mb-1 p-2" fade
      >
        <small>{{ $t("visitor_log_detail_qr_pass.message_email_success") }}</small>
      </b-alert>
      <b-alert
        variant="danger" :show.sync="showError" class="mb-1 p-2" fade
      >
        <small>{{ $t("visitor_log_detail_qr_pass.message_email_error") }}</small>
      </b-alert>
      <table class="table table-sm table-bordered w-100 mb-0">
        <tr>
          <td>{{ $t("visitor_log_detail_qr_pass.pass") }}</td>
          <td><code>{{ visitorLog.booking_details.pass }}</code></td>
        </tr>
        <tr>
          <td>{{ $t("visitor_log_detail_qr_pass.qr_code") }}</td>
          <td>
            <div class="qr-wrapper" id="qr_for_print">
              <qrcode-vue :value="qrData" :size="150" level="H" class="p-2" render-as="svg" />
            </div>
            <small>{{ $t("visitor_log_detail_qr_pass.note") }}</small>
          </td>
        </tr>
      </table>
    </div>

    <!-- Send to modal -->
    <b-modal v-model="showSendTo" dialog-class="modal-mod modal-fluid-md" centered>
      <template #modal-header>
        <div class="d-flex justify-content-between w-100">
          <b>{{ $t("visitor_log_detail_qr_pass.modal_title_send_to") }}</b>
          <div class="controls">
            <button class="ml-1" @click="close"><i class="fas fa-times" /></button>
          </div>
        </div>
      </template>
      <input type="email" class="form-control" v-model="sendToData.email" placeholder="email" />
      <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <div />
        <div>
          <b-button variant="primary" class="btn-w-md" @click="send">
            {{ $t("visitor_log_detail_qr_pass.modal_button_send") }}
          </b-button>
        </div>
      </div>
    </template>
    </b-modal>
  </div>
</template>

<style scoped>
  td:first-child { min-width: 100px; }
  .qr-wrapper {
    border: 1px solid #dee2e6;
    float: left;
    margin-right: 5px;
    clear: both;
  }
</style>

<script>
import QrcodeVue from "qrcode.vue";
import _ from "lodash";
import { checkout_groups as checkoutGroups } from "../../../../utils/mockup";

export default {
  components: { QrcodeVue },
  props: {
    visitorLog: {
      type: Object,
      default: () => {}
    },
  },
  mounted() {
    const visitor_log_id = this.visitorLog.id;
    const email = this.visitorLog.visitor.user.email ?? '';
    const pass = this.visitorLog.booking_details.pass ?? '';
    const pms_code = this.visitorLog.booking_details.pms_code;
    const facility_id = window._app_facility.id;
    this.qrData = `${email}|${pass}`;
    this.defaultSendData = { email, facility_id, visitor_log_id, pms_code }
  },
  data() { return {
    qrData: 'na',
    showSendTo: false,
    showSuccess: false,
    showError: false,
    defaultSendData: {
      facility_id: null,
      email: '',
      pass: '',
      pms_code: '',
    },
    sendToData: {
      facility_id: null,
      email: '',
      pass: '',
      pms_code: '',
    }
  }},
  methods: {
    async send() {
      this.showSuccess = false;
      this.close();
      try {
        const endpoint = 'v2/facility-bookings/send-checkin-details';
        await this.$lionheart.post(endpoint, this.sendToData);
        this.showSuccess = true;
      } catch(e) {
        this.showError = true;
        console.error(e);
      } finally {
        setTimeout(() => {
          this.showSuccess = false;
          this.showError = false;
        }, 3000);
      }
    },
    openSend() {
      this.sendToData = _.cloneDeep(this.defaultSendData);
      this.showSendTo = true;
    },
    close() {
      this.showSendTo = false;
    }
  },
  computed: {
      isCheckout() {
          const isCheckout = checkoutGroups.includes(this.visitorLog.booking_details.booking_status_id);
          return isCheckout;
      },
  },
};
</script>