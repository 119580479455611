<template>
    <div>
        <!-- Button trigger modal -->
        <a @click.prevent="toggleTac(0)" href="#" class="text text-dark">
            {{ $t('visit-information-form.terms_and_condition') }}
            <i class="fa " :class="{'fa-plus': config.plus == 1 , 'fa-minus': config.plus == 0 }"></i>
        </a>

        <b-modal size="md" id="modal-tac" centered title="BootstrapVue" hide-footer hide-header no-close-on-esc
                 hide-header-close no-close-on-backdrop>

            <div class="row ">
                <div class="col-lg-12">
                    <h5 class="modal-title" id="exampleModalCenterTitle">{{ $t('visit-information-form.terms_and_condition') }}</h5>
                </div>
                <hr>
            </div>
            <div class="row">
                <div class="col-lg-12 text-break" v-html="data">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <button @click.prevent="toggleTac(1)" type="button" class="btn btn-primary btn-block">{{ $t('visitor-info-thank-you.close') }}</button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>


    export default {
        props: ['data'],
        data() {
            return {
                config: {
                    plus: 1
                }
            }
        },
        mounted() {
            console.log(this.data);
        },
        methods: {
            toggleTac(cond) {
                this.config.plus = cond;
                this.$bvModal.show('modal-tac');
                if (cond == 0) {
                    this.$bvModal.show('modal-tac');
                } else {
                    this.$bvModal.hide('modal-tac');
                }
            }
        }
    }
</script>
