import _ from "lodash";
import { VueEditor } from "vue2-editor";
import ConfirmationModalComponent from "../components/confirmation-modal.component";
import preCheckinPreview from "../components/preCheckinPreview";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import sendTestEmail from "../components/sendTestEmail";

const sys_languages = {
  ja: "日本",
  en: "English",
};

export default {
  components: {
    VueEditor,
    ConfirmationModalComponent,
    preCheckinPreview,
    LanguageButtonComponent,
    sendTestEmail
  },
  data() {
    return {
      error_msg: "",
      data: {
        value: "",
      },
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      availableLanguages: [],
      config: {
        saved: false,
      },
    };
  },
  async mounted() {
    if (!window._app_facility.has_pms)
      return this.$router.push({ name: "checkin-facility-dashboard-home" });

    this.availableLanguages = _.clone(sys_languages);
    this.data.facility_id = window._app_facility.id;
    this.show(this.$i18n.locale);
  },
  methods: {
    async show(lang) {
      this.data.name = `pre-email-en`;
      this.data.value = "";

      const response = await this.$lionheart
        .get(`/custom-messages/${this.data.facility_id}`, {
          params: {
            name: this.data.name,
            hide_default: true,
          },
        })
        .then((r) => this.catcher(r.data, "data", (r) => r))
        .catch((e) => this.catcher(e));

      if (response.error.has_error) return this.errorDisplay(response);

      this.data = Object.assign(this.data, response.data);
      this.config.saved = false;
    },
    async submit(data) {
      if (data) {
        const response = await this.$lionheart
          .post("/custom-messages", { ...this.data })
          .then((r) => this.catcher(r.data, "data", (r) => r))
          .catch((e) => this.catcher(e));

        if (response.error.has_error) return this.errorDisplay(response);

        this.config.saved = true;
        this.data = response.data;

        window.scrollTo(0, 0);
      }
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg;
      setTimeout(
        () => window.scrollTo(0, $("#error_msg").position().top - 10),
        300
      );
      return;
    },
  },
};
