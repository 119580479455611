<template>
    <div>
        <div class="card-header py-3">
            <h6>{{ $t('filter.header') }}</h6>
            <h6 class="mb-0" v-html="$t('filter.description')">{{  }}</h6>
        </div>
        <div class="card-body mt-2">
            <form @submit-prevent="submit">
                <div v-if="success" class="alert alert-success" role="alert">
                    {{ $t('filter.success') }}
                </div>
                <div class="d-flex mb-3">
                    <div class="mr-5">
                        {{ $t('filter.filter') }} On/Off
                    </div>
                    <toggle-button
                    v-model="filter.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="filter"
                    :sync="true"/>
                </div>
                <div class="row justify-content-center">
                    <div v-if="error" class="alert alert-danger" role="alert">
                        {{ $t('filter.please-add-value') }} {{ error }}
                    </div>
                </div>
                <div class="row justify-content-center mb-3" v-for="(f, index) in filter.facility_localized_category" v-if="f.status == 1">
                    <div class="col-lg-1">
                        <div class="mb-2">{{ $t('filter.filter-name') }} {{ index + 1 }}</div>
                    </div>
                    <div class="col-lg-5">
                        <input v-model="filter.facility_localized_category[index].name" required type="text" class="form-control"/>
                    </div>
                    <div class="col-lg-1">
                        <a href="javascript:void" class="mt-2 ml-1 d-block text-danger" @click="removeRow(index)" v-if="index != 0 && filter.facility_localized_category[index].name != ''"><i class="fa fa-minus-circle"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3 text-center">
                        <button
                        class="btn"
                        type="button"
                        @click="addMore(filter.facility_localized_category.length)"
                        v-if="filter.facility_localized_category.length <= 4"
                        :class="filter.facility_localized_category[0].name == '' ? 'btn-dark' : 'btn-info'"
                        :disabled="filter.facility_localized_category[0].name == '' ? true : false">
                            + {{ $t('filter.add-more') }}
                        </button>
                    </div>
                    <div class="col-lg-12 mt-5" align="center">
                        <button
                        @click.prevent="$router.push({name: 'checkin-facility-dashboard-home'})"
                        class="btn btn-danger ml-3">
                            {{ $t('filter.back') }}
                        </button>
                        <button
                        :disabled="filter.facility_localized_category[0].name == '' ? true : false"
                        :class="filter.facility_localized_category[0].name == '' ? 'btn-dark' : 'btn-primary'"
                        @click="$bvModal.show('modal-confirmation')"
                        type="button"
                        class="btn ml-3">
                            {{ $t('filter.confirm') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <confirmation-modal-component @click-handler="submit"></confirmation-modal-component>
        <b-modal
        size="md"
        id="modal-error"
        centered
        hide-footer
        hide-header
        no-close-on-esc
        hide-header-close
        no-close-on-backdrop>
            <div class="row">
                <div class="col-md-12 my-5 text-center">
                    <span class="h5">{{ $t('filter.unsuccessful') }}</span>
                </div>
                <div class="col-md-12 text-center">
                    <button
                    @click="$bvModal.hide('modal-error')"
                    class="btn btn-danger px-5">
                        {{ $t('filter.back') }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import _ from 'lodash'; 
    import t from 'typy';
    import ConfirmationModalComponent from "../components/confirmation-modal.component";

    export default {
        components: {
            ConfirmationModalComponent
        },
        data() {
            return {
                facility: {},
                filter: {
                    facility_id: 0,
                    value: {
                        visibility: false
                    },
                    facility_localized_category: [
                        {
                            name: '',
                            status: 1,
                        }
                    ]
                },
                error: false,
                sendForm: false,
                success: false,
            }
        },

        async mounted() {
            this.facility = window._app_facility
            this.filter.facility_id = this.facility.id
            const response = await this.$lionheart.get('/localized-filters/' + this.facility.id);
            if (response.data.data != null) {
                this.filter.value.visibility = response.data.data.value.visibility
                this.filter.facility_localized_category = response.data.data.facility_localized_category
            }
        },

        methods: {
            async addMore(index) {
                this.error = false
                if(this.filter.facility_localized_category[index - 1].name != '') {
                    this.filter.facility_localized_category.push(
                        {
                            name: '',
                            status: 1,
                        }
                    )
                } else {
                    this.error = index
                }
            },

            async removeRow(index) {
                this.error = false
                if(this.filter.facility_localized_category[index].id) {
                    this.filter.facility_localized_category[index].status = 0
                } else {
                    this.filter.facility_localized_category.splice(index, 1)
                }
            },

            async submit() {
                this.error = false
                this.sendForm = false
                this.success = false
                this.filter.facility_localized_category.forEach((value, key) => {
                    this.sendForm = true
                    if(value.name == '' && value.id) {
                        this.sendForm = false
                        this.$bvModal.show('modal-error');
                    } else if (value.name == '') {
                        this.filter.facility_localized_category.splice(key, 1)
                    }
                });
                if(this.sendForm) {
                    const response = await this.$lionheart.put('/localized-filters/' + this.facility.id, this.filter);
                    if (response.data.data) {
                        this.filter = response.data.data
                        this.success = true
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .text-nowrap {
        white-space: nowrap;
    }
</style>