import i18n from "@/i18n";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    booking: {},
    disableLoader: false,
    confirmCardResponse: null
  },
  getters: {
    booking: (state) => state.booking,
    confirmCardResponse: (state) => state.confirmCardResponse,
  },
  mutations: {
    loading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setBooking(state, payload) {
      state.booking = payload;
    },
    disableLoader(state, payload) {
      state.disableLoader = payload
    },
    confirmCardResponse(state, payload) {
      state.confirmCardResponse = payload
    }
  },
  actions: {
    initialize({ dispatch }) {
      console.info("Site initializing...");
      console.info("Site initialized.");
      const fallback = "en";
      const langEn = /en-.*/;
      const langJP = /ja-.*/;
      const languages = ["ja", "en"];

      let language = langEn.test(navigator.language)
        ? "en"
        : navigator.language;

      language = langJP.test(navigator.language) ? "ja" : language;

      if (!languages.includes(language)) {
        language = fallback;
      }

      i18n.locale = language || "en";
      // i18n.locale= 'ja'
    },
    setBooking({ commit }, payload) {
      commit("setBooking", payload);
    },
  },
};
