<template>
  <div class="form-group row">
    <template v-if="!multiple">
      <label v-if="confirm && imageData()" class="col-md-2 col-form-label">
        {{ $t("visit-information-form.signature_provide") }}
        <small class="text-danger" v-if="c.required">*</small>
      </label>
      <label v-if="!confirm" class="col-md-2 col-form-label">
        {{ $t("visit-information-form.signature_provide") }}
        <small class="text-danger" v-if="c.required">*</small>
      </label>
      <div class="col-md-10">
        <b-alert show variant="danger" v-if="error_message">
          {{ $t("visit-information-form.signature_draw") }}
        </b-alert>
        <div v-show="!confirm" class="img-thumbnail">
          <vue-signature-pad
            class="sig bg-white"
            width="100%"
            height="250px"
            :ref="`signature-pad-${c.id}`"
            :options="{ onEnd }"
          />
          <div class="text-center py-2">
            <b-button
              variant="outline-danger"
              size="sm"
              class="btn-w-md mx-1"
              @click="clearSignature(c.id)"
            >
              {{ $t("visit-information-form.clear") }}
            </b-button>
            <!-- <b-button
              variant="primary"
              size="sm"
              class="btn-w-md mx-1"
              @click="insertSignature(c.id)"
              :id="`insert-signature-${c.id}`"
            >
              {{ $t("visit-information-form.set") }}
            </b-button> -->
          </div>
        </div>
        <div v-show="confirm && imageData()" class="img-thumbnail">
          <img :src="imageData()" class="img-responsive" />
        </div>
      </div>
    </template>

    <template v-else>
      <label class="col-md-2 col-form-label">
        {{ $t("visit-information-form.signature_provide") }}
        <small class="text-danger" v-if="c.required">*</small>
      </label>
      <div class="col-md-10">
        <b-alert show variant="danger" v-if="error_message">
          {{ $t("visit-information-form.signature_draw") }}
        </b-alert>
        <div class="img-thumbnail">
          <vue-signature-pad
            class="sig bg-white"
            width="100%"
            height="250px"
            :ref="`signature-pad-${c.id}-${bd_index}`"
          />
          <div class="text-center py-2" v-if="!confirm">
            <b-button
              variant="outline-danger"
              size="sm"
              class="btn-w-md mx-1"
              @click="clearSignature(c.id, bd_index)"
            >
              {{ $t("visit-information-form.clear") }}
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="btn-w-md mx-1"
              @click="insertSignature(c.id, bd_index)"
              :id="`insert-signature-${c.id}-${bd_index}`"
            >
              {{ $t("visit-information-form.set") }}
            </b-button>

          </div>
        </div>
      </div>
    </template>
  
  </div>
</template>

<style lang="scss" scoped>
.img-thumbnail {
  background: rgba(0, 0, 0, 0.03);
}
.sig {
  border: 1px solid #dee2e6;
}
</style>

<script>
  import _ from "lodash";
  export default {
    props: {
      c: null,
      confirm: false,
      visitor_forms: {
        type: Object,
        default: {}
      },
      // Multiple room
      multiple: false,
      multiple_data: {
        type: Array,
        default: []
      },
      bd_index: null,
      filled_in_by_other: null,
      // End of multiple room
    },
    data() {
      return {
        error_message: false
      }
    },
    watch: {
      filled_in_by_other: async function(status) {
        if (status == false)  {
          setTimeout(()=>{
            this.$refs[`signature-pad-${this.c.id}-${this.bd_index}`].resizeCanvas()
          }, 10)
        }
      }
    },
    methods: {
      insertSignature(id, bd_index) {
        if(!this.multiple) {
          var ref = 'signature-pad-' + id;
          const { isEmpty, data } = this.$refs[ref].saveSignature()
          if(!isEmpty) {
            // const sigId = `insert-signature-${id}`;
            this.visitor_forms[id] = data
          } else {
            this.error_message = true
            setTimeout(()=> { this.error_message = false }, 3000)
          }
        } else {
          var ref = `signature-pad-${id}-${bd_index}`;
          const { isEmpty, data } = this.$refs[ref].saveSignature()
          if(!isEmpty) {
            // const sigId = `insert-signature-${id}-${bd_index}`;
            this.multiple_data[this.bd_index].visitor_forms[id] = data
          } else {
            this.error_message = true
            setTimeout(()=> { this.error_message = false }, 3000)
          }
        }
        this.$emit('set');
      },
      clearSignature(id, bd_index) {
        if(!this.multiple) {
          const sigId = `insert-signature-${id}`;
          const ref = 'signature-pad-' + id;
          delete this.visitor_forms[id];
          this.$refs[ref].clearSignature()
          // this.$refs[ref].openSignaturePad()
  
          // document.getElementById(sigId).innerHTML  = this.$t("visit-information-form.set");
          // document.getElementById(sigId).disabled  = false;
          
        } else {
          const sigId = `insert-signature-${id}-${bd_index}`;
          const ref = `signature-pad-${id}-${bd_index}`;
          this.multiple_data[this.bd_index].visitor_forms[id] = ''
          this.$refs[ref].clearSignature()
          // this.$refs[ref].openSignaturePad()
  
          // document.getElementById(sigId).innerHTML = this.$t("visit-information-form.set");
          // document.getElementById(sigId).disabled  = false;
        }
        this.$emit('clear');
      },
      onEnd() {
        // Auto save
        this.insertSignature(this.c.id, this.bd_index);
        this.$emit('written');
      },
      imageData() {
        let data = '';
        if (!this.multiple) {
          data = this.visitor_forms[this.c.id];
        } else {
          data = this.multiple_data[this.bd_index].visitor_forms[this.c.id];
        }
        return data;
      },
    }
  }
</script>

<style lang="scss" scoped>
.img-responsive {
  width: 100%;
  background-color: white;
}
</style>