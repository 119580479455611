<template>
  <div class="mt-n2">
    <div class="panel-header">
      <b>{{ $t("reservation_details.booking_details_title") }}</b>
      <div></div>
    </div>
    <div class="panel-body">
      <table v-if="bookingDetails.total > 0" class="table table-sm table-bordered w-100 mb-0">
        <tr v-for="(map, index) in dataMap" :key="`m_${index}`">
          <td>{{ map.key }}</td>
          <td>{{ extractValue(map, bookingDetails.data) || '--' }}</td>
        </tr>
      </table>
      <div v-else>
        {{ $t("reservation_details.other_reservation_note") }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import t from 'typy';

export default {
  props: {
    logId: { type: Number, default: 0 },
    currentTab: { type: String, default: 'reservation' },
  },
  data() {
    return {
      dataMap: [],
      bookingDetails: {
        total: 0,
      },
      moment,
      currency: "",
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      const facility = window._app_facility;
      // Fetch data template first.
      if (this.dataMap.length < 1) await this.fetchDataTemplate();
      // Fetch associated booking details.

      const bookingDetails = await this.$lionheart
        .post('/v2/facility-bookings/f/list', {
          facility_id:              facility.id,
          facility_visitor_log_id:  this.logId,
        })
        .then(({data}) => {
          return {
            data: data.data.data,
            total: data.data.total
          }
        })

      this.bookingDetails = bookingDetails;
      this.currency = t(bookingDetails.data.payment_metadata, 'currency').isDefined
                    ? bookingDetails.data.payment_metadata.currency
                    : 'JPY';
    },
    async fetchDataTemplate() {
      const facility = window._app_facility;
      const response = await this.$lionheart
        .get(
          `/facility-setting/data-template/${facility.id}/guest_reservation_detail`,
          { params: { parse: true } }
        );
      if(response.data.hasOwnProperty('value')) {
        const value = response.data.value;
        if (value.hasOwnProperty('specs')) {
          this.dataMap = value.specs.list;
        }
      }
    },
    extractValue(map, obj) {
      try {
        const objValue = map.value.split('.').reduce(
          (a, i) => a[i], 
          obj
        );
        if (!objValue) return false;
        if (['checkin_date', 'checkout_date'].includes(map.system_name)) {
          if (!this.isValidFormat(objValue)) {
            return this.formatCSVDate(objValue) 
          }
          return objValue
        }
        return objValue;
      } catch(e) { 
        return false; 
      }
    },
    formatCSVDate(date) {
      let d = date.toString().split('');
      const year = `${d[0]}${d[1]}${d[2]}${d[3]}`;
      const mm = `${d[4]}${d[5]}`
      const dd = `${d[6]}${d[7]}`
      return `${year}-${mm}-${dd}`;
    },
  },
  watch: {
    logId() {
      this.refresh();
    },
    currentTab (tabname) {
      if (tabname == 'reservation') {
        this.refresh();;
      }
    }
  }
};
</script>