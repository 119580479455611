var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkin"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex justify-content-center"},[_c('div',{staticClass:"wrapper p-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex border align-items-center p-2 text-muted"},[_c('div',[_vm._v(_vm._s(_vm.$t("quick_checkout.note_1")))])]),_c('nav',{staticClass:"nav-menu ml-auto"},[_c('ul',[_c('li',{staticClass:"dropdown"},[_c('language-button-component',{attrs:{"facility_id":_vm.facility_custom.id,"checkin":true},on:{"select-lang":(lang) => (_vm.$i18n.locale = lang)}})],1)])])]),_c('div',{staticClass:"form-content"},[_c('div',{staticStyle:{"padding-bottom":"0px !important"}},[(_vm.error_msg)?_c('div',{staticClass:"alert alert-danger",attrs:{"id":"error_msg","role":"alert"}},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")]):_vm._e(),_c('div',{staticClass:"hotel-title text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("quick_checkout.header")))])])]),_c('hr'),_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group row justify-content-between"},[_c('label',{staticClass:"col-sm-2 col-md-2 col-lg-2 col-form-label",attrs:{"for":"colFormLabelSm"}},[_vm._v(_vm._s(_vm.$t("quick_checkout.code")))]),_c('div',{staticClass:"col-sm-6 col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.ccode),expression:"ccode",modifiers:{"trim":true}}],ref:"ccode",class:{
                      'is-invalid': _vm.invalid_code_error.length > 0,
                      'form-control': true,
                      codeStatus: true,
                    },staticStyle:{"ime-mode":"disabled"},attrs:{"type":"input","placeholder":_vm.$t(`quick_checkout.code`),"required":"","onKeyUp":"this.value=this.value.replace(/[\\u3000-\\u303f\\u3040-\\u309f\\u30a0-\\u30ff\\uff00-\\uff9f\\u4e00-\\u9faf\\u3400-\\u4dbf]/g,'')"},domProps:{"value":(_vm.ccode)},on:{"input":function($event){if($event.target.composing)return;_vm.ccode=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._l((_vm.invalid_code_error),function(i,k){return _c('p',{key:k,staticClass:"text-danger",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t("quick_checkout.error_message"))+" ")])})],2),_vm._m(0)]),_c('div',{staticClass:"form-group row justify-content-end"},[_c('div',{staticClass:"col-sm-auto col-md-auto"},[_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clear.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("quick_checkout.clear"))+" ")])]),_c('div',{staticClass:"col-sm-auto col-md-auto"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.refresh.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("quick_checkout.refresh"))+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$t("quick_checkout.note_2")))])]),_c('hr'),_c('div',{staticStyle:{"min-height":"500px","overflow-y":"auto"}},_vm._l((_vm.codes),function(c){return _c('quick-checkout-widget',{key:c.id,ref:`queue-${c.id}`,refInFor:true,attrs:{"data":c,"data_map":_vm.data_map},on:{"invalidCode":_vm.invalidCode,"removeMe":(id) =>
                    _vm.codes.splice(
                      _vm.codes.findIndex((e) => e.id == id),
                      1
                    )}})}),1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-2 col-md-2"},[_c('button',{staticClass:"btn btn-primary rounded-pill btn-block",attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}})])])
}]

export { render, staticRenderFns }