import {months} from '../../../utils/mockup'

export default {
    components: {},
    data() {
        return {
            data: {
                current_page: 1,
                per_page: 10,
                total: 10
            },
            months: [],
            years: [],
            filter: {
                year: new Date().getFullYear(),
                month: ('0' + (new Date().getUTCMonth() + 1)).slice(-2),
                per_page: 7,
                mode: 'd'
            },
            days: [],

            facility: {},
            engagement_snapshot: {

                total_pre_check_in_registered: {},
                total_check_in_registered: {},
                total_check_in_registered_by_tour_leader: {},
                total_check_in_registered_by_pms: {},
                total_check_in_registered_by_companion: {},
                total_check_in_registered_cancelled: {},

                total_check_out_registered: {},
                total_check_out_registered_by_pms: {},
                total_check_out_registered_by_tour_leader: {},
                total_check_out_registered_by_companion: {},

            },
            engagement_latest: {
                total_check_out_registered: {},
                total_pre_check_in_registered: {},
                total_check_in_registered: {},
                total_cancelled_registered: {},

            },
            totals: {

                snapshot_total_pre_check_in_registered: 0,


                snapshot_total_check_in_registered: 0,
                snapshot_total_check_in_registered_by_tour_leader: 0,
                snapshot_total_check_in_registered_by_pms: 0,
                snapshot_total_check_in_registered_by_companion: 0,
                snapshot_total_check_in_registered_cancelled: 0,

                snapshot_total_check_out_registered: 0,
                snapshot_total_check_out_registered_by_pms: 0,
                snapshot_total_check_out_registered_by_tour_leader: 0,
                snapshot_total_check_out_registered_by_companion: 0,


                latest_total_pre_check_in_registered: 0,
                latest_total_check_in_registered: 0,
                latest_total_check_out_registered: 0,
                latest_total_cancelled_registered: 0,
            },
            config: {
                facility_name: 'All',
                checked_in_collapsed: 0,
                checked_out_collapsed: 0
            }
        }
    },
    async mounted() {


        this.facility = window._app_facility;

        this.filter = {
            ...this.filter,
            ...{
                facility_id:  this.facility.id,
                user_id: this.facility.user_id
            }
        }
        this.months = months

        await this.snapshot_engagement(this.filter);
        await this.latest_engagement(this.filter);
        await this.populate_years()
        await this.populate_dates(this.filter.year,this.filter.month)
        await this.get_total_rates();

    },
    methods: {
        async paginate(page) {

            var filter = {
                page: page,
                ...this.filter
            }
            await this.list(filter)
        },

        async snapshot_engagement(data) {

            const response = await this.$lionheart.get('/traction/snapshot', {params: data})

            this.engagement_snapshot = response.data;

        },
        async latest_engagement(data) {

            const response = await this.$lionheart.get('/traction/latest', {params: data})

            this.engagement_latest = response.data;

        },
        async populate_years() {
            var date = new Date();
            for (var i = 2019; i <= date.getFullYear(); i++) {
                this.years.push(i);
            }
        },
        async $select_date() {

            await this.populate_dates(this.filter.year,this.filter.month);
            await this.snapshot_engagement(this.filter);
            await this.latest_engagement(this.filter);
            await this.get_total_rates();
        },
        async $search_engagement(data,name) {
            this.config.facility_name = name;

            await this.snapshot_engagement({...this.filter, ...data})
            await this.latest_engagement({...this.filter, ...data})
            await this.get_total_rates();
        },
        async populate_dates(year, month) {
            this.days = [];
            month = parseInt(month) - 1;
            var date = new Date(year, month, 1)
            while (date.getMonth() == month) {
                this.days.push(('0' + (new Date(date).getDate())).slice(-2))
                date.setDate(date.getDate() + 1)
            }
        },
        async get_total_rates(){



            this.totals.latest_total_pre_check_in_registered = await this.populate_total_rate(this.engagement_latest.total_pre_check_in_registered);
            this.totals.latest_total_check_in_registered = await this.populate_total_rate(this.engagement_latest.total_check_in_registered);
            this.totals.latest_total_check_out_registered = await this.populate_total_rate(this.engagement_latest.total_check_out_registered);
            this.totals.latest_total_cancelled_registered = await this.populate_total_rate(this.engagement_latest.total_check_out_cancelled);



            this.totals.snapshot_total_pre_check_in_registered = await this.populate_total_rate(this.engagement_snapshot.total_pre_check_in_registered);
            this.totals.snapshot_total_check_in_registered = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered);
            this.totals.snapshot_total_check_in_registered_by_tour_leader = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_tour_leader);
            this.totals.snapshot_total_check_in_registered_by_pms = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_pms);
            this.totals.snapshot_total_check_in_registered_by_companion = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_companion);
            this.totals.snapshot_total_check_in_registered_cancelled = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_cancelled);

            this.totals.snapshot_total_check_out_registered = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered);
            this.totals.snapshot_total_check_out_registered_by_pms = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_pms);
            this.totals.snapshot_total_check_out_registered_by_tour_leader = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_tour_leader);
            this.totals.snapshot_total_check_out_registered_by_companion = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_companion);


        },
        async populate_total_rate(data){

            var total = 0;
            for (var key in data) {
                total = data[key].total + total;
            }

            return total;
        }

    }
}
