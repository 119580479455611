import { render, staticRenderFns } from "./new-password.vue?vue&type=template&id=0343c932&scoped=true&"
import script from "./new-password.vue?vue&type=script&lang=js&"
export * from "./new-password.vue?vue&type=script&lang=js&"
import style0 from "./new-password.vue?vue&type=style&index=0&id=0343c932&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0343c932",
  null
  
)

export default component.exports