<template>
    <div>
        <div class="card-header py-3">
            {{ $t('mr_setting.header') }}
        </div>
        <div class="card-body mt-2">
            <form @submit-prevent="submit">
                <div v-if="success" class="alert alert-success" role="alert">
                    {{ $t('mr_setting.success_msg') }}
                </div>
                <div class="d-flex mb-3">
                    <div class="mr-5">
                        {{ $t('mr_setting.switch_label') }}
                    </div>
                    <toggle-button
                    v-model="mr_setting.value.visibility"
                    :labels="{ checked: 'On', unchecked: 'Off' }"
                    :value="mr_setting"
                    :sync="true"/>
                </div>
                <div class="justify-content-left mb-3">
                    {{ $t('mr_setting.note_1') }}
                </div>
                <div class="justify-content-left font-weight-bold mb-3">
                    {{ $t('mr_setting.note_2') }}
                </div>
                <div class="justify-content-left font-weight-bold">
                    {{ $t('mr_setting.note_3') }}
                </div>

                <div class="row">
                    <div class="col-lg-12 mt-5" align="center">
                        <button
                        @click.prevent="$router.push({name: 'checkin-facility-dashboard-home'})"
                        class="btn btn-danger ml-3">
                            {{ $t('mr_setting.back') }}
                        </button>
                        <button
                        class="btn-primary btn ml-3"
                        @click="$bvModal.show('modal-confirmation')"
                        type="button"
                        >
                            {{ $t('mr_setting.confirm') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        
        <confirmation-modal-component :title="this.mr_setting.value.visibility ? $t('mr_setting.confirmation_note') : null" @click-handler="submit"></confirmation-modal-component>
    </div>
</template>

<script>
    import _ from 'lodash'; 
    import ConfirmationModalComponent from "../components/confirmation-modal.component";

    export default {
        components: {
            ConfirmationModalComponent
        },
        data() {
            return {
                facility: {},
                mr_setting: {
                    facility_id: 0,
                    name: 'mr_setting',
                    value: {
                        visibility: false
                    }
                },
                error: false,
                sendForm: false,
                success: false,
            }
        },

        async mounted() {
            this.facility = window._app_facility
            this.mr_setting.facility_id = this.facility.id
            const search = {
                name: this.mr_setting.name,
                facility_id: this.mr_setting.facility_id,
                paginate: false
            }

            const mrSetting = await this.$lionheart.post('/facility-setting/list', {
                ...search
            })
            .then(r => this.catcher(r, 'data.total', r => r.data.total > 0 ? r.data.data[0] : r.data))
            .catch(e => this.catcher(e));

            if(mrSetting.error.has_error) return; // Silent API error catch

            if(mrSetting.total == 0) return;

            this.mr_setting.value.visibility = mrSetting.value.visibility;
        },

        methods: {
            async submit() {
                const data = {
                    ...this.mr_setting
                }
                
                const res = await this.$lionheart.post('/facility-setting/store', {...data})
                    .then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
                    .catch(e => this.catcher(e))

                this.success = res.error.has_error == false;
            }
        }
    }
</script>
<style scoped>
    .text-nowrap {
        white-space: nowrap;
    }
</style>