<script>
export default {
  name: "ButtonOfBrowseFileUploadComponent",
  emits: ["clickToShowModal"],
   props: {
      mini: {
         type: Boolean,
         default: false,
      },
      showSelection: {
         type: Boolean,
         default: true,
      },
   },
};
</script>
<template>
  <div v-if="showSelection" :class="{ 'img-thumbnail': !mini }" @click="$emit('clickToShowModal')">
    <!-- show big camera icon -->
    <div v-if="!mini" class="media-placeholder">
      <div class="center-icon">
        <i class="icon fa fa-folder" /><br />
        <small class="label">
            {{ $t("visit-information-form.browse_file_picture_large_text") }}
        </small>
      </div>
    </div>

    <!-- else show small icon with text only when images has values -->
    <b-button v-else variant="primary" size="sm" pill>
      <span class="px-1">
        <i class="icon fa fa-folder mr-1" />
        {{ $t("visit-information-form.browse_file_picture_small_text") }}
      </span>
    </b-button>
  </div>
</template>
<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
.media-placeholder {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  text-align: center;
  cursor: pointer;
  .center-icon {
    color: $secondary;
    opacity: 0.8;
    .icon {
      font-size: 2rem;
    }
  }
  &:hover {
    .center-icon {
      opacity: 1;
      .icon {
        color: $primary;
        transform: scale(1.15);
      }
    }
  }
}
</style>