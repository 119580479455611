<template>
  <div class="styled-form">
    <div class="mt-3">
      <div class="wrapper fadeInDown">
        <div class="form-content">
          <form @submit="submitForm">
            <div id ="error_msg" v-if="error_msg" class="alert alert-danger" role="alert">{{ error_msg }}</div>
            <h2>{{ $t('account-registration-form.account-registration-form')}}</h2>
            <p>{{ $t('account-registration-form.please-enter-following-information')}}</p>
            <p>{{ $t('account-registration-form.thank-you-very-much')}}</p>
            <hr >
            <div class="form-group row">
              <div class="col">
                <h2>{{ $t('account-registration-form.profile')}}</h2>
                <small class="form-text text-muted">
                  {{ $t('account-registration-form.visitor-will-name-checkin')}}
                </small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.facility-name')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.name" type="text" required class="form-control" :placeholder="$t('account-registration-form.facility-name')">
                <p v-if="confirm">{{data.name}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.email')}}</label>
              <div class="col-sm-9">
                <p>{{data.email}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.password')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.password" type="password" minlength="4" required class="form-control" :placeholder="$t('account-registration-form.password-4-placeholder')">
                <p v-if="confirm">----</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.personal-url')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.slug_name" v-debounce:700ms="checkSlugName" type="text" required class="form-control" :placeholder="$t('account-registration-form.personal-url-placeholder')">
                <p v-if="confirm">{{data.slug_name}}</p>
                <small style="font-size: 15px;" v-if="slugname_error" class="form-text text-danger">{{ slugname_error_msg }}</small>
              </div>
            </div>

            
            <div class="form-group row">
              <div class="col">
                <h2>{{ $t('account-registration-form.location')}}</h2>
                <small class="form-text text-muted">
                  {{ $t('account-registration-form.visitor-will-name-checkin')}}
                </small>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.country')}}</label>
              <div class="col-sm-9">
                <select v-if="!confirm" v-model="data.country" required class="form-control">
                  <option value ="">--</option>
                  <option v-for="(cname, index) in changeNationality()" :key="`op_${index}`">
                    {{ cname.Nationality }}
                  </option>
                </select>
                <p v-if="confirm">{{data.country}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.state-province')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.state" required type="text" class="form-control" :placeholder="$t('account-registration-form.state-province')">
                <p v-if="confirm">{{data.state}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.city')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.city" required type="text" class="form-control" :placeholder="$t('account-registration-form.city')">
                <p v-if="confirm">{{data.city}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.address')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.address" required type="text" class="form-control" :placeholder="$t('account-registration-form.address')">
                <p v-if="confirm">{{data.address}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.building')}}</label>
              <div class="col-sm-9">
                <input v-if="!confirm" v-model="data.building" type="text" class="form-control" :placeholder="$t('account-registration-form.building')">
                <p v-if="confirm">{{data.building}}</p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">{{ $t('account-registration-form.receive-visitor-email')}}</label>
              <div class="col-sm-9">
                <template v-if="!confirm">
                  <div class="form-check form-check-inline">
                    <input v-model="data.receive_checkin_email" required class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Yes">
                    <label class="form-check-label" for="inlineRadio1">{{ $t('account-registration-form.yes')}}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="data.receive_checkin_email" required class="form-check-input" checked type="radio" name="inlineRadioOptions" id="inlineRadio2" value="No">
                    <label class="form-check-label" for="inlineRadio2">{{ $t('account-registration-form.no')}}</label>
                  </div>
                </template>
                <p v-if="confirm">{{data.receive_checkin_email}}</p>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-lg-12" align="center">
                <button v-if="!confirm" @click="submit_btn = 'confirm'" class="btn btn-primary">{{ $t('account-registration-form.confirm')}}</button>
                <template v-if="confirm">
                  <button @click="submit_btn = 'submit'" class="btn btn-primary ml-3">{{ $t('account-registration-form.submit')}}</button>
                  <button @click="submit_btn = 'edit'" class="btn btn-primary ml-3">{{ $t('account-registration-form.edit')}}</button>
                </template>
              </div>
            </div>
          </form>
          <div id="formFooter">
            <router-link link :to="{name: 'checkin-facility-login'}" class="underlineHover" >{{ $t('account-registration-form.return-to-signin')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .form-content {
    h2 {
      margin-left: 0;
      margin-right: 0;
    }
    input[type="text"],
    input[type="password"] {
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
      border: 1px solid transparent;
    }
    max-width: 720px;
    .form-group {
      text-align: left;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
	}
</style>

<script>
import { nationalities } from '../../utils/mockup'

export default {
  data() {
    return {
      submit_btn: '',
      error_msg: '',
      confirm: false,
      slugname_error: false,
      slugname_error_msg: '',
      data: {
        country: '',
        building: '',
        receive_checkin_email: 'Yes',
        email : '',
        slug_name: ''
      }
    }
  },
  mounted() {
    this.data.email = this.$route.query.email || ''
  },
  methods: {
    async checkSlugName(e) {
      if (this.data.slug_name == '') return

      let formatted  = this.data.slug_name.replace(/ |[^a-z0-9]|[-$&+,:;=?@#|'<>*()%!/]/g,'')
      this.data.slug_name = formatted.substring(0, 20).toLowerCase()

      if (this.data.slug_name.length < 3) {
        this.slugname_error = true
        this.slugname_error_msg = 'Minimum of 3 characters'
        return;
      }

      const slug = await this.$lionheart.post('/facility/exists', { slug_name: this.data.slug_name })
        .then(r => this.catcher(r.data, 'data.exist', r => r.data))
        .catch(e => this.catcher(e))
      
      if (slug.error.has_error) return this.errorDisplay(slug)

      if (slug.exist) {
        this.slugname_error = true
        this.slugname_error_msg = "Already taken. Please choose another one."
        return
      }

      this.slugname_error = false
      this.slugname_error_msg = ""
    },
    async submitForm(e) {
      e.preventDefault()
      
      if (this.submit_btn == 'confirm' 
        && this.slugname_error == false
        && this.data.email) {
        return this.confirm = true
      }

      if (this.submit_btn == 'edit') {
        return this.confirm = false
      }

      if (this.submit_btn == 'submit'
        && this.data.email ) {

        const data = this.sanitizeData()
        const user = await this.$lionheart.post('/user/store', data.user )
          .then(r => this.catcher(r.data, 'data[0].id', r => r.data[0]))
          .catch(e => this.catcher(e))
        
        if (user.error.has_error) return this.errorDisplay(user)

        this.error_msg = ''
        Object.assign(data.facility, { user_id: user.id})

        const facility = await this.$lionheart.post('/facility/store',data.facility)
          .then(r => this.catcher(r.data, 'data[0].id', r => r))
          .catch(e => this.catcher(e))
        if (facility.error.has_error) return this.errorDisplay(facility)

        this.$router.push({'name' : 'checkin-facility-register-sucess'})
      }
    },
    sanitizeData() {
      const user = {
        email: this.data.email,
        password: this.data.password
      }

      let facility = Object.assign({}, this.data)
      delete facility.email
      delete facility.password

      return {
        user,
        facility
      }
    },
    changeNationality() {
      return nationalities[this.$i18n.locale]
    },
    errorDisplay(d) {
      this.error_msg = d.error.msg
      setTimeout(() => window.scrollTo(0, $('#error_msg').position().top - 10), 300)
      return
    }
  }
}
</script>