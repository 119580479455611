<template>
  <b-card v-if="initialize">
    <Payment ref="payment" :currency="currency" />
    <template #header>
      <b>{{ $t("visit-information-form.payment") }}</b>
    </template>

    <div class="form-group row" v-if="!confirm">
      <div class="col-sm-12 pl-3 ml-1">
        <input
          v-model="payment.gateway"
          :value="payment_gateway.fincode"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payFincode"
          name="payment_method"
        />
        <label class="form-check-label ml-2 text-decoration-none" for="payFincode">
          {{ $t("visit-information-form.card_payment") }}
        </label>
      </div>
    </div>
  
    <div class="form-group row" v-if="payment.gateway === payment_gateway.fincode && !confirm">
      <button
        v-if="!payment_authorized"
        type="button"
        class="btn btn-primary btn-w-lg mx-auto payment-btn"
        @click="showModal"
      >
        {{ $t("visit-information-form.start_payment") }}
      </button>

      <button v-else
        type="button"
        class="btn btn-primary btn-w-lg mx-auto authorized-btn"
        disabled
      >
        {{ $t("visit-information-form.card_authorized") }}
      </button>
    </div>

    <div class="form-group row" v-if="!confirm">
      <div class="col-sm-12 pl-3 ml-1" v-if="payment_timing_opt.payHotelVisibility">
        <input
          v-model="payment.gateway"
          :value="payment_gateway.reception"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payHotel"
          name="payment_method"
        />
        <label class="form-check-label ml-2 text-decoration-none" for="payHotel">
          {{ $t("visit-information-form.pay_at_reception") }}
        </label>
        <p v-if="payment.gateway == payment_gateway.reception" class="mb-0" style="color: #1a5dab">
          {{ $t("visit-information-form.pay_at_reception_note") }}
        </p>
      </div>
    </div>

    <div class="form-group row" v-if="!confirm">
      <div class="col-sm-12 pl-3 ml-1" v-if="isPayLater">
        <input
          v-model="payment.gateway"
          :value="payment_gateway.payLater"
          type="radio"
          @change="changePaymentMethod($event)"
          id="payLater"
          name="payment_method"
        />
        <label class="form-check-label ml-2 text-decoration-none" for="payLater">
          {{ $t("visit-information-form.pay_later") }}
        </label>
        <p v-if="payment.gateway == payment_gateway.payLater" style="color: #1a5dab">
          {{ $t("visit-information-form.pay_later_note") }}
        </p>
      </div>
    </div>

    <template v-if="confirm">
      <template v-if="payment.gateway == payment_gateway.fincode">
        <div class="form-group row">
          <button type="button" class="btn btn-primary btn-w-lg mx-auto authorized-btn" disabled>
            <template v-if="!challengeError">{{ $t("visit-information-form.card_authorized") }}</template>
            <template v-if="challengeError"><span class="text-danger">{{ $t("visit-information-form.card_payment_error") }}</span></template>
          </button>
        </div>
      </template>
      <div v-if="payment.gateway == payment_gateway.reception" class="form-group row">
        <label for="exampleInputEmail1" class="col-12 col-form-label">
          {{ $t("visit-information-form.pay_at_reception") }}
        </label>
      </div>
      <div v-if="payment.gateway == payment_gateway.payLater" class="form-group row">
        <label for="exampleInputEmail1" class="col-12 col-form-label">
          {{ $t("visit-information-form.pay_later") }}
        </label>
      </div>
    </template>

    <!-- Modal component -->
    <b-modal
      centered
      id="payment-modal"
      ref="paymentModal"
      :no-close-on-backdrop="true"
      :no-fade="true"
      :static="true"
      hide-footer
      :hide-header-close="isProcessing"
    >
      <iframe
        :src="`${iframeUrl}`"
        width="100%"
        height="500px"
        frameborder="0"
        allowfullscreen
        @load="iframeLoaded"
      ></iframe>
    </b-modal>
  </b-card>
</template>

<script>
import _typy from "typy";
import { payment_status, payment_gateway } from "../../../utils/mockup";
import Payment from '../components/Payment.vue';

const pStatus = {
  error: {},
  status: true,
};

export default {
  components: {
    Payment,
  },
  name: 'FincodePayment',
  props: [
    "booking_details",
    "facility",
    "confirm",
    "page",
    "currency",
    "touchdown",
    "settings"
  ],
  data() {
    return {
      initialize: false,
      tokenizeCard: '',
      selectedPaymentMethod: null,
      slug_name: '',
      payment_authorized: false,
      show_payment: true,
      pStatus,
      payment: {
        gateway: payment_gateway.fincode,
        type: "card",
      },
      challengeError: false,
      payment_gateway,
      fincode_config: {
        token: process.env.VUE_APP_FINCODE_PUB_KEY,
        name: payment_gateway.fincode,
        initialize: false,
        error: {},
      },
      payment_timing_opt: {
        payHotelVisibility: true,
        payLaterVisibility: false,
        waivePay: false,
      },
      isDeposit: false,
      isProcessing: false,
    };
  },
  async mounted() {
    await this.showPaymentTimingOption();
    await this.showPayment();

    if (!this.show_payment) return;

    this.validatePayment();
  },
  methods: {
    showModal() {
      this.$refs.paymentModal.show();
    },
    iframeLoaded() {
      window.addEventListener('message', this.handleMessageFromIframe);
    },
    handleMessageFromIframe(event) {
      if (event.data.action === 'closeModal') {
        this.payment_authorized = true;
        this.isProcessing = false;
        this.$refs.paymentModal.hide();
        this.tokenizeCard = event.data.tokenizeCard;
      } else if (event.data.action === 'startProcessing') {
        this.isProcessing = true;
      } else if (event.data.action === 'endProcessing'){
        this.isProcessing = false;
      }
    },
    async makePayment(messageToComponent) {
      let res;
      
      if (this.payment_gateway.payLater == this.payment.gateway) return true; // For pay later

      if (this.initialize) {
        if (this.payment.gateway == this.payment_gateway.fincode && this.payment_authorized) {
          const params = {
            tenantShopId: this.settings.account_id,
            facility_id: this.settings.facility_id,
            pms_code: this.settings.pms_code,
            rsv_no: this.settings.rsv_no,
            token: this.tokenizeCard,
            currency: this.currency,
          };

          res = await this.$lionheart.post("/v2/fincode/payment-pms", {
            ...params,
            ...this.payment,
          });

          if (res.data?.success === false) {
            this.challengeError = true;

            return false;
          }

          return true;
        } else {
          res = await this.$refs.payment.createPayment({
            ...this.payment,
            pms_reservation_no: this.booking_details.data[0].pms_reservation_no,
            facility_id: this.facility.id,
          });
        }

        return res.status;
      }

      return true;
    },
    createPaymentMethod() {
      if (!this.initialize) return true;
      
      const paymentOtherOptions = [
        payment_gateway.reception,
        payment_gateway.payLater,
      ];

      if (paymentOtherOptions.includes(this.payment.gateway)) return true;

      //Check if already authorized and payment gateway is fincode
      return (this.payment.gateway == this.payment_gateway.fincode && this.payment_authorized);
    },
    async showPaymentTimingOption() {
      const optSettings = await this.$lionheart
        .post("/facility-setting/list", {
          name: "payment_timing_opts",
          facility_id: this.facility.id,
          paginate: false,
        })
        .then((r) => this.catcher(r, "data.total", (r) => (r.data.total > 0 ? r.data.data[0] : r.data)))
        .catch((e) => this.catcher(e));

      if (optSettings.error.has_error) return;

      if (optSettings.total == 0) return;

      this.payment_timing_opt = optSettings.value;
    },
    async showPayment() {
      if (this.payment_timing_opt.waivePay) return (this.show_payment = false);

      const res = await this.$lionheart
        .post("/facility-setting/list", {
          facility_id: this.facility.id,
          name: "payment_option",
          paginate: false,
        })
        .then((r) => this.catcher(r, "data.total", (r) => (r.data.total > 0 ? r.data.data[0] : r.data)))
        .catch((e) => this.catcher(e));

      if (res.error.has_error) {
        if (this.page != "checkin") return (this.show_payment = false);
        return;
      }

      if (res.total == 0) {
        if (this.page != "checkin") return (this.show_payment = false);
        return;
      }

      let showPayment = _.find(res.value, (val, key) => {
        return ["checkin", "deposit"].includes(key) && val == 1;
      });

      if (this.page == "checkout") showPayment = res.value[this.page];

      return (this.show_payment = showPayment);
    },
    validatePayment() {
      const paid_statuses = [
        payment_status.pay_at_reception,
        payment_status.paid_authorized,
        payment_status.paid_captured,
        payment_status.paid,
      ];

      if (this.booking_details.total == 0) {
        this.initialize = false;
      } else {
        if (paid_statuses.includes(this.booking_details.data[0].payment_status_id)) {
          this.initialize = false;
        } else {
          this.initialize = true;
        }
      }
    },
    reInit() {
      if (!this.show_payment) return;
    },
    async changePaymentMethod($event) {},
  },
  computed: {
    iframeUrl() {
      const rsv_no = this.booking_details.data[0]?.pms_reservation_no ?? '<>';
      const pms_code = this.booking_details.data[0]?.pms_code ?? '<>';
      const net_charge = this.booking_details.data[0]?.parsed.net_charge ?? '<>';

      Object.assign(this.settings, { 
        rsv_no: rsv_no, 
        pms_code: pms_code, 
        facility_id: this.facility.id,
        net_charge: net_charge, 
        currency: this.currency 
      });

      const configs = btoa(JSON.stringify(this.settings));
      const slugName = this.facility?.slug_name ?? '';

      return this.$router.resolve({
        name: 'fincode-payment-iframe',
        params: { slug_name: slugName },
        query: { lang: this.$i18n.locale, configs: configs },
      }).href;
    },
    isPayLater() {
      return (
        this.payment_timing_opt.payLaterVisibility &&
        this.page == "checkin" &&
        this.touchdown === undefined
      );
    },
  },
  watch: {
    show_payment(val) {
      this.$emit("updateShowPayment", val);
    },
    confirm(val) {
      if (val == false) {
        this.payment_authorized = false;
        this.challengeError = false;
      }
    }
  },
};
</script>

<style scoped>
.custom-dialog-class {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.processing-text {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

#payment-modal {
  min-height: 80vh;
}

.payment-btn {
  background-color: #72d1fc;
  border: none;
}

.authorized-btn {
  background-color: #eee;
  opacity: 0.5;
  color: #999;
  border: none;
}
</style>
