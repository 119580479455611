<template>
    <div>
        <b-modal
            size="md"
            id="modal-archive-restore-confirmation"
            ref="modal-archive-restore-confirmation"
            centered
            hide-footer
            hide-header
            no-close-on-esc
            hide-header-close
            no-close-on-backdrop
        >
            <div class="row" v-if="!config.success">
                <div class="col-lg-12 text-center pb-3">
                    <span>{{ $t("account-page-visitor."+mode+"_are_you_sure_title") }}</span>
                    <br/>
                    <span>{{ $t("account-page-visitor."+mode+"_are_you_sure_subtitle") }}</span>
                </div>
                <div
                    class="col-lg-12 table-responsive"
                    style="overflow-y: auto; height: 300px"
                >
                    <table class="table table-bordered" width="100%" cellspacing="0">
                        <thead>
                        <tr>
                            <th>{{ $t("account-page-visitor.email") }}</th>
                            <th>{{ $t("account-page-visitor.checkin-date") }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="vl in data" :key="vl.id">
                            <td>{{ maskedEmail(vl.parsed.email_address) }}</td>
                            <td>
                                {{ formatDateFacilityTimezoned(vl.parsed.checkin_date) ?? '--' }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-12 text-center pb-3">
                    <div class="row">
                        <div class="col-6">
                            <button
                                type="button" @click="$bvModal.hide('modal-archive-restore-confirmation')"
                                class="btn btn-secondary mb-2 btn-block"
                            >
                                {{ $t("account-page-visitor."+mode+"_cancel") }}
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                type="button"
                                @click="submit"
                                class="btn btn-primary mb-2 btn-block"
                            >
                                {{ $t("account-page-visitor."+mode+"_confirm") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

                  <div  class="row" v-if="config.success">
                    <div class="col-lg-12 text-center pb-3">
                      {{ $t("account-page-visitor."+mode+"_success") }}
                    </div>

                    <div class="col-lg-12 text-center pb-3">
                      <button
                          class="btn btn-secondary mb-2 btn-block"
                          @click="back"
                      >
                        {{ $t("visit-information-form.ok") }}
                      </button>
                    </div>
                  </div>
        </b-modal>
    </div>

</template>

<script>

import {main_form_methods} from "../../../utils/mockup";
import { maskedDummyEmails } from "../../../utils/helpers";

const {
    formatDateFacilityTimezoned,
} = main_form_methods
export default {
    name: "reservation-archive-restore",
    props: ['data','mode'],

    data() {
        return {
            config: {
                success: false
            }
        }
    },
    mounted() {
    },
    methods: {
        formatDateFacilityTimezoned,
        async submit(){
            this.config.success = false;
            await this.$lionheart.post('/v2/facility-booking/reservation-list/multiple/'+this.mode,{ids: this.data.map((item) => item.id),facility_id:window._app_facility.id});
            this.$emit('mode',this.mode);
            this.$emit('list',1);

            this.config.success = true;
        },
        async back(){
            this.$bvModal.hide('modal-archive-restore-confirmation');

            setTimeout(() => {
                this.config.success = false;
            }, 1000)
        },
        maskedEmail(email) {
            return maskedDummyEmails(email)
        }
    }
}
</script>

<style scoped>

</style>