<template>
  <div>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

      <!-- Sidebar Toggle (Topbar) -->
      <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
        <i class="fa fa-bars"></i>
      </button>

      <!-- Topbar Navbar -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="" @click.prevent="$i18n.locale = 'en'" role="button" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2 d-lg-inline text-gray-600 small font-weight-bold">English</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="" @click.prevent="$i18n.locale = 'ja'" role="button" aria-haspopup="true" aria-expanded="false">
            |<span class="mr-2 d-lg-inline text-gray-600 small font-weight-bold">日本語</span>
          </a>
        </li>

          <template v-if="$storage.has('auth_admin')">
              <div class="topbar-divider d-none d-sm-block"></div>

              <!-- Nav Item - User Information -->
              <li class="nav-item dropdown">
                  <a  class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span class="mr-2 d-none d-lg-inline text-gray-600 small font-weight-bold">Internal</span>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in " style="cursor: pointer" aria-labelledby="userDropdown">
                      <a @click.prevent="$bvModal.show('bv-modal-update-password')" class="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                          <i class="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                          Update Password
                      </a>
                      <a @click.prevent="logout" class="dropdown-item" data-toggle="modal" style="cursor: pointer" data-target="#logoutModal">
                          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                      </a>
                  </div>
              </li>
          </template>



      </ul>
      
    </nav>
      <update-password/>
  </div>
</template>

<script>
import UpdatePassword from "./update-password";
  export default {
      components: {UpdatePassword},
    data() {
      return {
        facility: {}
      }
    },
    mounted() {
      this.facility = window._app_facility
    },
    methods: {
      logout() {
        this.$storage.remove('auth_admin')
        this.$router.push({ name: 'checkin-admin-login'})
      }
    }
  }
</script>
