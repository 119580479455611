<template>
  <div v-if="!checkinInfoIsEmpty()">
    <b-card no-body>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b> {{ $t("touch-down.checkin-information") }}: </b>
      </b-card-header>

      <div class="container mt-3">
        <contact
          v-if="checkin_info.contact"
          :contacts="checkin_info.contact"
          :facility_id="facility_id"
        />
        <sanitation
          v-if="checkin_info.sanitation"
          :sanitations="checkin_info.sanitation"
          class="mt-3"
        />
        <visit-info
          v-if="checkin_info.visit_info"
          :visit_infos="checkin_info.visit_info"
          class="mt-3"
        />
      </div>
    </b-card>

    <other-companion
      v-if="hasOtherCompanion"
      :other_companions="other_companions"
      :facility_id="facility_id"
      class="mt-4"
    />
  </div>
</template>

<script>
import Contact from "./Contact";
import Sanitation from "./Sanitation";
import VisitInfo from "./VisitInfo";
import OtherCompanion from "./OtherCompanion";
import _ from "lodash";

export default {
  name: "CheckinInformation",

  components: {
    Contact,
    Sanitation,
    VisitInfo,
    OtherCompanion,
  },

  props: {
    facility_visitor_log_id: {
      type: Number,
      required: true,
    },
    facility_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      visitor: {},
      checkin_info: {
        contact: [],
        sanitation: [],
        visit_info: [],
      },
      other_companions: [],
    };
  },

  computed: {
    hasOtherCompanion() {
      return this.other_companions.length > 0;
    },
  },

  mounted() {
    this.loadCheckinInformation();
  },

  methods: {
    async loadCheckinInformation() {
      let res = await this.$lionheart.get(
        `/log/get/checkin-information/${this.facility_visitor_log_id}`
      );
      this.visitor = res.data.visitor;
      this.checkin_info = _.groupBy(
        res.data.other_forms,
        (obj) => obj.facility_questionnaire[0].section
      );
      this.checkin_info.contact.splice(
        4,
        0,
        this.formatData(this.visitor.user.email, "email")
      );
      this.other_companions = res.data.facility_visitor_companion;
    },

    formatData(val, label) {
      let data = {
        value: val,
        facility_questionnaire: [
          {
            label: label,
            system_name: "email",
          },
        ],
      };
      return data;
    },

    checkinInfoIsEmpty() {
      return (
        this.checkin_info.contact.length === 0 &&
        this.checkin_info.sanitation.length === 0 &&
        this.checkin_info.visit_info.length === 0
      );
    },
  },
};
</script>

<style lang="sass" scoped>
@import '~bootstrap/scss/bootstrap'
@import '~bootstrap/scss/_variables'

$enable-responsive-font-sizes: true
</style>