<template>
  <div class="mt-n2">
    <!-- Payment Details -->
    <div class="panel-header">
      <b>{{ $t("payment_details.payment_details_title") }}</b>
    </div>
    <div class="panel-body">
      <table class="table table-sm table-bordered w-100 mb-0">
        <template v-if="paymentDetails.payment_status.id">
          <tr>
            <td>{{ $t("payment_details.paid_date_and_time") }}</td>
            <td>
              {{ isPaid ? formatDate(paymentDetails.payment_date,'YYYY-MM-DD hh:mm:ss A') || '--' : '--' }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("payment_details.amount") }}</td>
            <td>
              {{ isPaid ? `${paymentDetails.total_price} ${currency}` : '--' }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("payment_details.payment_status") }}</td>
            <td>{{ paymentStatusText || '--' }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>{{ $t("payment_details.paid_description") }}</td>
          </tr>
        </template>
        <tr
          v-if="paymentDetails.payment_status.id && (isCardDeposit || isPaidAuthorized) && !isCancelled"
          class="buttons-row"
        >
          <td colspan="2" class="text-right">
            <div class="d-inline-block mx-n1">
              <b-button
                v-if="isCardDeposit"
                :disabled="grandTotal > 0"
                variant="outline-danger"
                size="sm"
                class="btn-w-md mx-1"
                @click="showConfirm.discard = true"
              >
                {{ $t("payment_details.cad_discard_btn") }}
              </b-button>
              <b-button
                :disabled="!isPaidAuthorized"
                variant="primary"
                size="sm"
                class="btn-w-md mx-1"
                @click="showConfirm.refund = true"
              >
                {{ $t("payment_details.refund") }}
              </b-button>
              <b-button
                v-if="isCardDeposit"
                :disabled="grandTotal == 0 || isCancelled"
                variant="primary"
                size="sm"
                class="btn-w-md mx-1"
                @click="showConfirm.authorize = true"
              >
                {{ $t("payment_details.cad_authorize_btn") }}
              </b-button>
            </div>
          </td>
        </tr>
      </table>
    </div>  

    <!-- Current Charges -->
    <template v-if="isCardDeposit && t(visitorLog.booking_details, 'parsed').isDefined && !isCancelled">
      <div class="panel-header mt-2">
        <b>{{ $t("payment_details.cad_current_charge_title") }}</b>
      </div>
      <div class="panel-body">
        <table class="table table-sm table-bordered w-100 mb-0">
          <template v-if="paymentDetails.payment_status.id">
            <tr>
              <td>{{ $t("payment_details.cad_room") }}</td>
              <td>{{ booking.room_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_meal") }}</td>
              <td>{{ booking.meal_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_other") }}</td>
              <td>{{ booking.other_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_service") }}</td>
              <td>{{ booking.service_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_vat") }}</td>
              <td>{{ booking.vat_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_city_tax") }}</td>
              <td>{{ booking.city_tax || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_deduct_amount") }}</td>
              <td>{{ booking.deduct_charge || '--' }}</td>
            </tr>
            <tr>
              <td>{{ $t("payment_details.cad_net_charge") }}</td>
              <td>{{ booking.net_charge || '0' }}</td>
            </tr>
          </template>
        </table>
      </div>
    </template>
    
    <!-- Additional Charge -->
    <template v-if="isCardDeposit && !isCancelled">
      <div class="panel-header mt-2">
        <b>{{ $t("payment_details.cad_additional_charge_title") }}</b>
        <div>
          <b-button
            v-if="!additionalCharges.editing"
            variant="primary"
            size="sm"
            class="btn-w-md"
            @click.prevent="editaAdditionalCharges"
          >
            {{ $t("payment_details.button_edit") }}
          </b-button>
        </div>
      </div>
      <div class="panel-body">
        <b-alert
          variant="success" :show.sync="additionalCharges.showSucess" class="mb-1 p-2" fade
        >
          <small>{{ $t("account_page_details.success_msg") }}</small>
        </b-alert>
        <b-alert
          variant="danger" :show.sync="additionalCharges.showFailed" class="mb-1 p-2" fade
        >
          <small>{{ $t("account_page_details.error_msg") }}</small>
        </b-alert>
        <table class="table table-sm table-bordered w-100 mb-0">
          <tr>
            <td>{{ $t("payment_details.cad_charges") }}
              <span v-show="additionalCharges.editing" class="text-danger">*</span>
            </td>
            <td>
              <template v-if="additionalCharges.editing">
                <input
                  type="number"
                  class="form-control"
                  :class="{'is-invalid': additionalCharges.edited && isInvalidPaymentCharges}"
                  v-model="additionalCharges.editData.total_price"
                  @input="editChargeStart"
                />
                <div
                  v-show="additionalCharges.edited && isInvalidPaymentCharges"
                  class="input-feedback error"
                >
                  {{ $t('generic-validations.required') }}
                </div>
              </template>
              <template v-else>
                {{ additionalCharges.data.total_price }}
              </template>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("payment_details.cad_vat") }}
              <span v-show="additionalCharges.editing" class="text-danger">*</span>
            </td>
            <td>
              <template v-if="additionalCharges.editing">
                <input
                  type="number"
                  class="form-control"
                  :class="{'is-invalid': additionalCharges.edited && isInvalidPaymentVat}"
                  v-model="additionalCharges.editData.vat"
                  @input="editChargeStart"
                />
                <div
                  v-show="additionalCharges.edited && isInvalidPaymentVat"
                  class="input-feedback error"
                >
                  {{ $t('generic-validations.required') }}
                </div>
              </template>
              <template v-else>
                {{ additionalCharges.data.vat }}
              </template>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t("payment_details.cad_note_to_guest") }}
              <span v-show="additionalCharges.editing" class="text-danger">*</span>
            </td>
            <td>
              <template v-if="additionalCharges.editing">
                <textarea
                  class="form-control"
                  :class="{'is-invalid': additionalCharges.edited && isInvalidPaymentNotes}"
                  v-model="additionalCharges.editData.notes"
                  @input="editChargeStart"
                />
                <div
                  v-show="additionalCharges.edited && isInvalidPaymentNotes"
                  class="input-feedback error"
                >
                  {{ $t('generic-validations.required') }}
                </div>
              </template>
              <template v-else>
                {{ additionalCharges.data.notes }}
              </template>
            </td>
          </tr>
          <tr>
            <td>{{ $t("payment_details.cad_grand_total") }}</td>
            <td>{{ grandTotal }}</td>
          </tr>
          <tr v-if="additionalCharges.editing" class="buttons-row">
            <td colspan="2" align="right" class="p-2">
              <b-button
                variant="outline-danger"
                size="sm"
                class="btn-w-md"
                @click.prevent="additionalCharges.editing=false"
              >
                {{ $t("payment_details.button_cancel") }}
              </b-button>
              &nbsp;
              <b-button
                variant="primary"
                size="sm"
                class="btn-w-md"
                :disabled="isInvalidPaymentCharges || isInvalidPaymentVat || isInvalidPaymentNotes"
                @click.prevent="saveAdditionalCharges"
              >
                {{ $t("payment_details.button_save") }}
              </b-button>
            </td>
          </tr>
        </table>
      </div>
    </template>

    <!-- Confirmation Modals -->
    <confirm-dialog
      :show.sync="showConfirm.authorize"
      :message="$t('payment_details.cad_authorize_note')"
      @confirm="authorizeAmount"
    />
    <confirm-dialog
      :show.sync="showConfirm.refund"
      :message="$t('payment_details.are_you_sure')"
      @confirm="refund"
    />
    <confirm-dialog
      :show.sync="showConfirm.discard"
      :message="$t('payment_details.cad_discard_note')"
      @confirm="discardAmount"
    />

    <!-- Stripe Error -->
    <b-modal
      size="md"
      id="stripe-error-modal"
      centered
      hide-footer
      hide-header
      no-close-on-esc
      hide-header-close
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col-md-12 my-5 text-center">
          <span class="h4" v-html="$t('payment_details.cad_error_note')"></span>
        </div>
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-danger" @click="$bvModal.hide('stripe-error-modal')">
            {{ $t('confirmation_modal.cancel') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import t from "typy";
import _ from "lodash";
import {
  payment_status as paymentStatuses,
  paid_payment_status as paidStatuses,
  booking_status as bookingStatuses,
} from "../../../../utils/mockup";
import paymentAdditionalModal from "../payment_additional/paymentAdditionalModal.vue";
import ConfirmationModal from "../confirmation-modal.component";
import ConfirmDialog from "../../../../components/ConfirmDialog.vue";

export default {
  props: {
    logId: { type: Number, default: 0 },
    visitorLog: { type: Object, default: null },
    currentTab: { type: String, default: 'payment' }
  },
  components: {
    paymentAdditionalModal,
    ConfirmationModal,
    ConfirmDialog,
  },
  computed: {
    ...mapState("checkin-facility", ["facility"]),
    paymentStatusText() {
      if (this.paymentDetails.pms_reservation_no !== null) {
        return this.$t(`payment_statuses.${this.paymentDetails.payment_status.code}`);
      }
      return false;
    },
    isCardDeposit() {
      return this.paymentStatusId == this.paymentStatuses.credit_card_as_deposit;
    },
    isPaidAuthorized() {
      return this.paymentStatusId == this.paymentStatuses.paid_authorized
    },
    isPaidCaptured() {
      return this.paymentStatusId == this.paymentStatuses.paid_captured
    },
    isPaid() {
      return paidStatuses.includes(this.paymentStatusId);
    },
    isCancelled() {
      return this.bookingStatusId == bookingStatuses.cancelled;
    },
    booking() {
      return this.visitorLog.booking_details.parsed;
    },
    grandTotal() {
      const bookingData = this.visitorLog.booking_details.parsed;
      const chargeData = this.additionalCharges.editing ?
                         this.additionalCharges.editData :
                         this.additionalCharges.data;
      const total = parseFloat(chargeData.vat) + 
                    parseFloat(chargeData.total_price) +
                    parseFloat(bookingData["net_charge"]);

      if (Number.isNaN(total)) return bookingData["net_charge"];
      return total;
    },
    isInvalidPaymentCharges() {
      const totalPrice = this.additionalCharges.editData.total_price;
      if (totalPrice === null) return true;
      return String(totalPrice).trim().length === 0;
    },
    isInvalidPaymentVat() {
      const vat = this.additionalCharges.editData.vat;
      if (vat === null) return true;
      return String(vat).trim().length === 0;
    },
    isInvalidPaymentNotes() {
      const notes = this.additionalCharges.editData.notes;
      if (notes === null) return true;
      return notes.trim().length === 0;
    }
  },
  data() { return {
    t,
    timer: null,
    paymentDetails: {
      payment_status: {},
    },
    paymentStatuses,
    paymentStatusId: null,
    bookingStatusId: null,
    additionalCharges: {
      editing: false,
      edited: false,
      data: {
        id: null,
        facility_id: 0,
        facility_visitor_log_id: 0,
        charge_type: "Other",
        currency: "JPY",
        pass: "",
        total_price: 0,
        vat: 0,
        notes: "",
      },
      editData: {
        id: null,
        facility_id: 0,
        facility_visitor_log_id: 0,
        charge_type: "Other",
        currency: "JPY",
        pass: "",
        total_price: 0,
        vat: 0,
        notes: "",
      },
      showSucess: false,
      showFailed: false,
    },
    showConfirm: {
      authorize: false,
      refund: false,
      discard: false,
    }
  }},
  async mounted() {
    this.bookingStatusId = this.visitorLog.booking_details.booking_status.id;
    await this.getPayment();
  },
  methods: {
    async refund() {
      let result = await this.$lionheart.post(
        "/payment/stripe/cancel-payment-intent",
        {
          transaction_id: this.paymentDetails.transaction_id,
          stripe_account: this.paymentDetails.payment_metadata.stripe_account,
          pms_reservation_no: this.paymentDetails.pms_reservation_no,
          facility_id: this.facility.id,
        }
      );
      if (result.data.success) {
        await this.getPayment();
      }
    },
    async createBookingLog(data) {
      return await this.$lionheart.post("/payment/pms", data);
    },
    editChargeStart() {
      if (!this.additionalCharges.edited) this.additionalCharges.edited = true;
    },
    async getPayment() {
      const paymentDetails = await this.$lionheart
      .get("/payment/pms", { params: { facility_visitor_log_id: this.logId }, })
      .then((r) => this.catcher(r.data, "data", (r) => r))
      .catch((e) => this.catcher(e));

      if (paymentDetails.status) {
        this.paymentDetails = paymentDetails.data;
        this.paymentStatusId = paymentDetails.data.payment_status.id ?? null;
      }

      if (paymentDetails.data.payment_metadata) {
        this.currency = paymentDetails.data.payment_metadata.currency ?? "JPY";
      }
    },
    async getAdditionalCharges() {
      this.additionalCharges.editing = false;
      const additionalCharges = await this.$lionheart
        .get(`payment-additional`, {
          params: {
            facility_id: this.facility.id,
            log_id: this.logId,
          },
        })
        .then((r) => this.catcher(r, "data.total", (r) => r.data))
        .catch((e) => this.catcher(e));

      let data = {
        id: null,
        facility_visitor_log_id: null,
        facility_id: null,
        charge_type: "Other",
        pass: "",
        total_price: 0,
        currency: "JPY",
        notes: "",
        vat: 0
      }

      if (additionalCharges.total > 0) {
        const { id, charge_type, total_price, metadata } = additionalCharges.data[0];
        const { currency, notes, vat_amount } = metadata;
        data = {
          id,
          facility_visitor_log_id: this.logId,
          facility_id: this.facility.id,
          charge_type: charge_type,
          pass: this.visitorLog.booking_details.pass,
          total_price: total_price,
          currency,
          notes,
          vat: vat_amount
        }
      }
      
      this.additionalCharges.data = data;
    },
    convertTimeZone(date_time) {
      if (!date_time) return null;
      const timezone = moment.utc(date_time).tz(moment.tz.guess());
      return timezone.format("YYYY-MM-DD hh:mm:ss A");
    },
    async authorizeAmount() {
      const response = await this.$lionheart
        .post(`payment/pms/manual-payment-authorize`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.logId,
        })
        .then((r) => this.catcher(r, "data", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (!response.error.has_error) {
        this.getPayment()
      } else {
        this.$bvModal.show('stripe-error-modal');
      }
    },
    async discardAmount() {
      const response = await this.$lionheart
        .post(`payment/pms/discard-payment`, {
          facility_id: this.facility.id,
          facility_visitor_log_id: this.logId,
        })
        .then((r) => this.catcher(r, "data", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (!response.error.has_error) {
        this.$bvModal.hide('discard-modal')
        this.getPayment()
      } else {
        this.$bvModal.hide('discard-modal')
        this.$bvModal.show('stripe-error-modal')
      }
    },
    async editaAdditionalCharges() {
      this.additionalCharges.edited = false;
      clearTimeout(this.timer);
      this.additionalCharges.data.facility_id = this.facility.id;
      this.additionalCharges.data.pass = this.visitorLog.booking_details.pass;
      this.additionalCharges.data.facility_visitor_log_id = this.logId;
      this.additionalCharges.showSucess = false;
      this.additionalCharges.showFailed = false;
      this.additionalCharges.editData = _.cloneDeep(this.additionalCharges.data);
      this.additionalCharges.editing = true;
    },
    async saveAdditionalCharges() {
      try {
        const data = this.additionalCharges.editData;
        await this.$lionheart.post(`payment-additional`, data);
        this.additionalCharges.data = _.cloneDeep(this.additionalCharges.editData);
        this.additionalCharges.showSucess = true;
      } catch(e) {
        this.additionalCharges.showFailed = true;
        console.error(e);
      } finally {
        this.additionalCharges.editing = false;
        this.timer = setTimeout(() => {
          this.additionalCharges.showSucess = false;
        }, 3000);
      }
    },
  },
  watch: {
    async currentTab(tabname) {
      if (tabname == 'payment') {
        this.bookingStatusId = this.visitorLog.booking_details.booking_status.id;
        this.additionalCharges.edited = false;
        this.getPayment();
        this.getAdditionalCharges();
      }
    },
    async logId(v) {
      this.additionalCharges.edited = false;
      this.getPayment();
      this.getAdditionalCharges();
    },
  }
};
</script>