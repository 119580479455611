<template>
    <div>
        <b-modal id="bv-modal-update-password" hide-footer centered  >
            <template #modal-title>
     Update Password
            </template>
            <div class="d-block ">
                <form @submit.prevent="submit">
               <div class="row mb-2">
                  <div class="col-lg-12 mb-2" v-if="config.success">
                      <div class="alert alert-success" role="alert">
                        Password Update Success!
                          <button type="button" class="close" @click="config.success = false">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                  </div>
                   <div class="col-lg-12 mb-2">
                       <label>Old Password*</label>

                       <input v-model="data.old_password" type="password" class="form-control " :class="{'border-danger':hasError(errors,'old_password')}" required>
                       <small class="text-danger" v-if="hasError(errors,'old_password')">
                           {{hasError(errors,'old_password') }}
                       </small>
                   </div>
                   <div class="col-lg-12 mb-2">
                       <label>New Password*</label>
                       <input v-model="data.password" type="password" class="form-control " :class="{'border-danger':hasError(errors,'password')}" required>
                       <small class="text-danger" v-if="hasError(errors,'password')">
                           {{hasError(errors,'password') }}
                       </small>
                   </div>
                   <div class="col-lg-12 mb-2">
                       <label>Confirm Password*</label>
                       <input v-model="data.confirm_password" type="password" class="form-control " :class="{'border-danger':hasError(errors,'confirm_password')}" required>
                       <small class="text-danger" v-if="hasError(errors,'confirm_password')">
                           {{hasError(errors,'confirm_password') }}
                       </small>
                   </div>
               </div>
                <div class="row mb-2">
                    <div class="col-sm-6">
                  <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-danger btn-block" @click="$bvModal.hide('bv-modal-update-password')">Close</button>
                    </div>

                </div>
                </form>
            </div>

        </b-modal>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            data: {},
            errors: {},
            config: {
                success: false
            }
        }
    },
    async mounted() {


    },
    methods: {
        async submit() {

            await this.$lionheart.post('v2/auth/update-password', this.data)
                .then(async (response) => {
                    this.errors = {}
                    this.data = {}
                    this.config.success = true;
                }).catch((error) => {

                    this.errors = error.response.data.errors;
                    console.log(this.error);
                });


        }
    }
}

</script>

<style scoped>

</style>