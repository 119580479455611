<template>
  <b-modal
    body-class="p-0"
    size="md"
    :id="id"
    centered
    :title="$t('send_test_email.header')"
    hide-footer
  >
    <form @submit="submitForm">
      <div slot="modal-body" class="modal-body">
        <div v-if="success" class="alert alert-success" role="alert">
          {{ $t("send_test_email.email-sent") }}
        </div>
        <div class="form-group row">
          <label class="col-12 col-form-label">
            {{ $t("send_test_email.email") }}
            <i
              class="far fa-question-circle text-primary"
              v-b-tooltip.top
              :title="$t('send_test_email.tooltip')"
            ></i>
          </label>
          <div class="col-12">
            <input
              type="text"
              v-model="email"
              class="form-control"
              placeholder="email1@site.com,email2@site.com,email3@site.com"
              required="true"
            />
          </div>
        </div>
      </div>
      <!-- custom buttons -->
      <div slot="modal-footer" class="modal-footer">
        <button class="btn btn-primary mr-3">
          {{ $t("send_test_email.submit") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<style>
</style>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    facility_id: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: "",
      success: false,
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      let arr = this.email.split(",");
      const response = await this.$lionheart.post(
        `v2/email/test/${this.type}/${this.facility_id}`,
        {
          emails: arr,
          facility_id: this.facility_id,
          lang: this.lang,
        }
      );
      
      if (response.data.success) {
        this.success = true;
        this.email = "";
        setTimeout(() => {
          this.success = false;
        }, 5000);
      }
    },
  },
};
</script>