import { lionheart } from '@/modules/checkin-lionheart/module/client';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		imageLoaderCounter: 0,
		facility: null,
      facilityListOfMessages: [],
      guestListOfMessages: [],
      bookingDetails: {},
  	},
	getters: {
      gettersFacilityListOfMessages(state) {
         return state.facilityListOfMessages.sort(function(a, b) {
            var c = new Date(a.created_at);
            var d = new Date(b.created_at);
            return d-c;
        });
      },
      gettersGuestListOfMessages(state) {
         return state.guestListOfMessages;
      },
      gettersBookingDetails(state){
         return state.bookingDetails
      }
   },
	mutations: {
		imageLoaderIncrement(state) {
			state.imageLoaderCounter++
		},
		imageLoaderDecrement(state) {
			state.imageLoaderCounter--
		},
      SET_FACILITY_LIST_OF_MESSAGES(state, payload) {
         state.facilityListOfMessages = payload
		},
      SET_GUEST_LIST_OF_MESSAGES(state, payload) {
         state.guestListOfMessages = payload
      },
      SET_BOOKING_DETAILS(state, payload) {
         state.bookingDetails = payload
      }
    },
	actions: {
		initialize ({ dispatch }) {
			console.info('Site initializing...')
			console.info('Site initialized.')
		},
		setFacility({commit, state}, payload){
			state.facility = payload
		},

      // FACILITY
      async actionGetFacilityListOfMessages({commit}, payload) {
         let result = await lionheart.get('/v2/my-page/message/reservation', {
            params: { ...payload }
         })
         .then(r => r.data.data)

         commit('SET_FACILITY_LIST_OF_MESSAGES',result);
      },

      // GUEST
      async actionGetGuestListOfMessages({commit}, payload) {
         let result = await lionheart.get('/v2/my-page/messages', {
            params: { ...payload  }
         })
         .then(r => r.data.data)

         commit('SET_GUEST_LIST_OF_MESSAGES',result);
      },
      
      // BOOKING
      async actionGetBookingDetails({commit}, payload) {
         let result = await lionheart.post('/v2/facility-booking/list', { ...payload })
                           .then(r => r.data.data);

         commit('SET_BOOKING_DETAILS',result);
      },
	}
}