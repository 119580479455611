<template>
  <select class="form-control" :value="value" @change="changeNationality">
    <option value="--">--</option>
    <option
      v-for="(option, index) in options"
      :key="`opt_${index}`"
      :value="option.CountryCode"
      :selected="option.CountryCode == value"
    >
      {{ option.Nationality }}
    </option>
  </select>
</template>

<script>
import t from "typy";
import { nationalities } from "../../../../utils/mockup";
export default {
  data () { return {
    lang: "en",
    selected: null,
    options: []
  }},
  mounted() {
    let lang = "en";
    if (t(nationalities, this.$i18n.locale).isDefined) lang = this.$i18n.locale;
    this.options = nationalities[lang];
  },
  methods: {
    changeNationality(e) {
      this.$emit("input", e.target.value);
    },
  },
  props: {
    value: {
      type: String,
      default: "--"
    }
  }
}
</script>