<template>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">
        {{ c.label }}<small v-if="c.required" class="text-danger">*</small>
      </label>
      <div class="col-sm-10 my-auto" v-if="!multiple">
        <input
          v-if="!confirm"
          v-model="inputEmail"
          type="email"
          v-debounce:500ms="checkEmail"
          :required="c.required ? true : false"
          class="form-control w-100"
          :placeholder="c.placeholder"
        />
        <p v-if="confirm" class="mb-0">{{ inputEmail }}</p>
        <small style="font-size: 15px" v-if="email_exists" class="form-text text-danger">
          {{ $t("visit-information-form.email_exist_by_facility") }}
        </small>
      </div>
  
      <!-- Multiple Room -->
      <div class="col-sm-10 my-auto" v-else>
        <input
          v-if="!confirm"
          v-model="multiple_data[bd_index].email"
          type="email"
          v-debounce:500ms="checkEmail"
          :required="multiple_data[bd_index].filled_in_by_other ? false : c.required ? true : false"
          class="form-control w-100"
          :placeholder="c.placeholder"
        />
        <p v-if="confirm" class="mb-0">{{ multiple_data[bd_index].email }}</p>
        <small style="font-size: 15px" v-if="email_exists" class="form-text text-danger">
          {{ $t("visit-information-form.email_exist_by_facility") }}
        </small>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    props: {
      c: null,
      confirm: false,
      visitor: {
        type: Object,
        default: {}
      },
      user: {
        type: Object,
        default: {}
      },
      // Multiple room
      multiple: false,
      multiple_data: {
        type: Array,
        default: () => []
      },
      bd_index: null,
      // End of multiple room
  
      // checkin form type
      checkin_form_type: ""
    },
    data() {
      return {
        email_exists: false,
        inputEmail: '' // Initialize inputEmail with an empty string
      };
    },
    watch: {
      'user.email': {
        handler(newVal) {
              this.loadEmail(newVal)
        },
      },
      inputEmail: {
        handler(newVal) {
          const maskedEmail = this.removeDummyEmail(newVal)
          this.saveEmail(maskedEmail);
        }
      }
    },
    computed: {
      ...mapState('memorizedInputs', ['memorizedEmail']),
    },
    async mounted() {

      this.loadEmail(this.inputEmail);

      this.$root.$on('email_exists', (status) => {
        // Check mail form JS validateEmail method
        this.email_exists = status;
      });
    },
    methods: {
      ...mapActions('memorizedInputs', ['saveEmail']),
  
      async loadEmail(newVal) {
        const memorizedEmail = await this.memorizedEmail;
        let newEmail;

        if(this.checkin_form_type !== 'others') {
          newEmail = memorizedEmail ? memorizedEmail : newVal;
        } else {
          newEmail = memorizedEmail ? memorizedEmail : '';
        } 
      
        this.inputEmail = newEmail;
      },
      removeDummyEmail(val) {
        const dummyEmailPattern = /(.+@dummy\.com.*)|(.+@dummy\.co\.jp)|(dummy@nta.*)/i;

            if (dummyEmailPattern.test(val)) {
              this.saveEmail('')
              this.inputEmail = ''
            } 
             return val
      },
      async checkEmail() {
        if (!this.multiple) {
          if (this.inputEmail == "") return;
        } else {
          if (this.multiple_data[this.bd_index].email == "") return;
        }

        await this.validateEmail();
      },
      async validateEmail() {
        const data = {
          email: this.inputEmail,
          id: this.visitor.visitor_id,
          type: "visitor",
          module: "checkin",
        };
        const email = await this.$lionheart
          .post("/user/exists", data)
          .then((r) => this.catcher(r.data, "data.exist", (r) => r.data))
          .catch((e) => this.catcher(e));
        if (email.error.has_error) return this.errorDisplay(email);
        this.email_exists = email.exist;
        // Update grand parent
        this.$root.$emit('update_email_exist', this.email_exists);
      },
      errorDisplay(d) {
        // this.error_msg = d.error.msg;
        setTimeout(
          () => window.scrollTo(0, $("#error_msg").position().top - 10),
          300
        );
        return;
      },
    }
  };
  </script>
  