<template>
   <div class="container">
      <div class="vh-100 d-flex flex-column justify-content-center align-items-center">
         <img width="500" src="../../assets/img/maintenece_mood.png" class="img-fluid" alt="Responsive image" />

         <p class="py-4 h1">{{ $t("maintenance.title") }}</p>
         <p>{{ $t("maintenance.description") }}</p>
      </div>
   </div>
</template>

<script>
export default {
   name: "index",
};
</script>

<style scoped></style>
