<template>
    <b-modal id="message-upload" centered hide-footer>
        <div class="row ">

            <div class=" col-lg-12  ">
                <cropper
                    ref="cropper"
                    :src="data.src"
                    backgroundClass="bg-white"
                    class="cropper"
                />
            </div>


        </div>
        <div class="row mt-3">

            <div class="col-sm-6">
                <button class="btn btn-block btn-outline-danger" @click="$bvModal.hide('message-upload')">{{ $t("account-page-facility-my-page-message.cancel") }}</button>
            </div>
            <div class="col-sm-6">
                <button class="btn btn-block btn-outline-primary" @click="crop">{{ $t("account-page-facility-my-page-message.crop") }}</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {Cropper},
    data() {
        return {
            data: {
                src: null
            }
        }
    },
    methods: {
        crop(){
            const result = this.$refs.cropper.getResult();

            let input = {
                value: result.canvas.toDataURL("image/jpeg"),
                is_cropped: true
            }
            this.$emit("event",input);

            this.$bvModal.hide("message-upload")
        },
        init_upload(data) {
            this.$bvModal.show("message-upload")
            this.data.src = data;
        }
    }
}
</script>
