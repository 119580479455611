<template>
   <div class="td">
      <div class="container-md">
         <!-- <section></section> -->
         <div id="login_wrapper" class="mt-3">
            <section class="wrapper">
               <div class="box-content">
               <!-- Topbar and language -->
               <div class="row">
                  <div class="col-4">
                     <template
                     v-if="
                        booking_details.total &&
                        isPreCheckin() &&
                        !showCheckinCompletion
                     "
                     >
                     <span
                        @click="back()"
                        alt="Back"
                        title="Back"
                        style="font-size: 30px; cursor: pointer"
                        class="fa fa-arrow-circle-left"
                     />
                     </template>
                  </div>
                  <div class="col-4"></div>
                  <div class="col-4">
                     <nav class="nav-menu">
                     <ul class="float-right">
                        <li class="dropdown">
                           <language-button-component
                           :facility_id="facility.id"
                           :checkin="true"
                           @select-lang="selectLang($event)"
                           />
                        </li>
                     </ul>
                     </nav>
                  </div>
               </div>
               <div :class="(is_loading ? 'd-none' : '')">
                  <div :class="(template_is_loading ? 'd-none' : '')">
                     <!-- Choose facility options -->
                     <div class="row">
                     <div class="col-12">
                        <div class="hotel-title text-center">
                           <template v-if="!showCheckinCompletion">
                              <template v-if="facility.facility_name.length">
                                 <p
                                    class="h2"
                                    v-for="facility in facility.facility_name"
                                    :key="facility.id"
                                 >
                                    <span v-if="$i18n.locale == facility.language">
                                       {{ facility.name }}
                                    </span>
                                 </p>
                                 <p class="h2" v-if="!langAvailable()">
                                    {{ facility.name }}
                                 </p>
                              </template>
                              <p class="h2" v-else>
                                 {{ facility.name }}
                              </p>
                           </template>
                           <!-- show checkin complete -->
                           <p class="h2" v-if="showCheckinCompletion">
                           {{ $t("touch-down.checkin-completion") }}
                           </p>
                        </div>
                     </div>

                     <div class="col-12">
                        <ul v-if="!isPreCheckin() && error_message.show">
                           <li class="text-danger">
                              {{ $t("touch-down.not-precheckin") }}
                           </li>
                        </ul>
                        <div v-if="show()">
                           <div class="row">
                              <div class="col-lg-12 text-center">
                                 <p class="py-2"> {{ $t('touch-down.sub_title') }}</p>
                                 <div class="col-lg-6 mx-auto">
                                    <b-button
                                       block
                                       squared
                                       variant="success"
                                       class="button-responsive shade d-inline-flex align-items-center justify-content-center"
                                       data-toggle="modal"
                                       data-target="#finishPreCheckin"
                                    >
                                       <svg xmlns="http://www.w3.org/2000/svg" width="2rem" viewBox="0 0 24 24"><path fill="currentColor" d="M10.5 15.25A.74.74 0 0 1 10 15l-3-3a.75.75 0 0 1 1-1l2.47 2.47L19 5a.75.75 0 0 1 1 1l-9 9a.74.74 0 0 1-.5.25"/><path fill="currentColor" d="M12 21a9 9 0 0 1-7.87-4.66a8.7 8.7 0 0 1-1.07-3.41a9 9 0 0 1 4.6-8.81a8.7 8.7 0 0 1 3.41-1.07a8.9 8.9 0 0 1 3.55.34a.75.75 0 1 1-.43 1.43a7.6 7.6 0 0 0-3-.28a7.4 7.4 0 0 0-2.84.89a7.5 7.5 0 0 0-2.2 1.84a7.42 7.42 0 0 0-1.64 5.51a7.4 7.4 0 0 0 .89 2.84a7.5 7.5 0 0 0 1.84 2.2a7.42 7.42 0 0 0 5.51 1.64a7.4 7.4 0 0 0 2.84-.89a7.5 7.5 0 0 0 2.2-1.84a7.42 7.42 0 0 0 1.64-5.51a.75.75 0 1 1 1.57-.15a9 9 0 0 1-4.61 8.81A8.7 8.7 0 0 1 12.93 21z"/></svg>
                                       <p>{{ $t("touch-down.finished-pre-checkin") }}</p>
                                    </b-button>
                                    <b-button
                                       block
                                       squared
                                       variant="primary"
                                       class="button-responsive shade d-inline-flex align-items-center justify-content-center"
                                       @click="showCheckinForm"
                                    >
                                       <svg xmlns="http://www.w3.org/2000/svg" width="2rem" class="mr-2" viewBox="0 0 1024 1024"><path fill="currentColor" d="M532.528 661.408c-12.512 12.496-12.513 32.752-.001 45.248c6.256 6.256 14.432 9.376 22.624 9.376s16.368-3.12 22.624-9.376l189.008-194L577.775 318.64c-12.496-12.496-32.752-12.496-45.248 0c-12.512 12.496-12.512 32.752 0 45.248l115.744 115.76H31.839c-17.68 0-32 14.336-32 32s14.32 32 32 32h618.448zM960.159 0h-576c-35.36 0-64.017 28.656-64.017 64v288h64.432V103.024c0-21.376 17.344-38.72 38.72-38.72h496.704c21.408 0 38.72 17.344 38.72 38.72l1.007 818.288c0 21.376-17.311 38.72-38.72 38.72H423.31c-21.376 0-38.72-17.344-38.72-38.72V670.944l-64.432.08V960c0 35.344 28.656 64 64.017 64h576c35.344 0 64-28.656 64-64V64c-.016-35.344-28.672-64-64.016-64"/></svg>
                                       <p>{{ $t("touch-down.check-in-now") }}</p>
                                    </b-button>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     </div>

                     <!-- success button after checkin -->
                     <div
                        v-if="showCheckinCompletion"
                        class="row h-100 justify-content-center align-items-center mt-3"
                     >
                     <div class="col-12 mb-2">
                        <div class="row">
                           <div class="col-lg-3 col-md-2 col-sm-2"></div>
                           <div class="col-lg-6 col-md-8 col-sm-8">
                              <CheckinDetails v-if="checkIfTabIsActivated()" :bookingDetails="reservationDetails" class="mt-2"/>
                           </div>
                           <div class="col-lg-3 col-md-2 col-sm-2"></div>

                        </div>
                     </div>
                     <div class="col-12">
                        <div class="row">
                           <div class="col-lg-3 col-md-2 col-sm-2"></div>
                           <div class="col-lg-6 col-md-8 col-sm-8">
                              <button
                                 @click="back()"
                                 type="button"
                                 class="btn btn-primary btn-block button-responsive"
                              >
                                 <!-- style="font-size: 0.9rem" -->
                                 <p>{{ $t("touch-down.back-to-checkin") }}</p>
                              </button>
                           </div>
                           <div class="col-lg-3 col-md-2 col-sm-2"></div>
                        </div>
                     </div>
                     <!-- <div class="col-2"></div> -->
                     </div>

                     <!-- Check-in information -->
                     <div
                        v-if="isPreCheckin() && !showCheckinCompletion"
                        class="container-md"
                     >
                     <reservation
                        :facility="facility"
                        :booking_details="booking_details"
                        :currency="this.currency"
                        @checkin-completion="getBookingDetailsWithLock"
                        @template-loaded="template_is_loading = $event"
                     />
                     </div>
                  </div>
               </div>
               </div>
            </section>
         </div>

         <!-- Modal: Prompt for email & pass -->
         <finish-pre-checkin
            :facility="facility"
            v-if="show() && facility.id !== undefined"
            @load-booking-details="loadBookingDetails"
            @is-loading="is_loading = $event"
         />
      </div>
   </div>
</template>

<script>
import CheckinDetails from "@/modules/checkin-facility/pages/checkin/components/CheckinInformation/CheckinDetails";
import { booking_status } from "@/modules/checkin-facility/utils/mockup";
import _ from "lodash";
import t from 'typy';
import LanguageButtonComponent from "./../../../dashboard/components/language-button.component";
import FinishPreCheckin from "./FinishPreCheckin";
import Reservation from "./Reservation";

export default {
  name: "TouchDown",

  components: {
    FinishPreCheckin,
    Reservation,
    LanguageButtonComponent,
    CheckinDetails
  },

  data() {
    return {
      slug_name: this.$route.params.slug_name,
      facility: {
        facility_name: [],
      },
      booking_details: {},
      showCheckinCompletion: false,
      booking_status: booking_status,
      error_message: {
        show: false,
      },
      currency: "",
      is_loading: false,
      template_is_loading: false,
      reservationDetails: ''
    };
  },

  async mounted() {
    if (this.$route.query.e && this.$route.query.k) this.is_loading = true
    await this.loadFacility(this.slug_name);

  },

  methods: {
   checkIfTabIsActivated(){
      return t(this.$route.query,'tab').isUndefined
   },
    async loadFacility(slug_name) {
      try {
        let res = await this.$lionheart.post("/facility/list", {
          slug_name: slug_name,
        });

        if (res.data.total === 0) {
          return this.$router.push("/404");
        }

        this.facility = res.data.data[0];
        this.facility.has_pms =
          this.facility.facility_setting.length > 0 ? true : false;

        if (!this.facility.has_pms) {
          return this.$router.push("/404");
        }
      } catch (error) {
        console.log(error);
      }
    },

    loadBookingDetails(obj) {
      this.booking_details = obj;
      if (obj.data[0].payment_metadata) {
        this.currency = obj.data[0].payment_metadata.hasOwnProperty("currency")
          ? obj.data[0].payment_metadata.currency
          : "JPY";
      }
      if (!this.isPreCheckin(this.booking_details)) {
        this.error_message.show = true;
      }
      $("#finishPreCheckin").modal("hide");

      this.is_loading = false
    },

    async getBookingDetailsWithLock(data) {
      // check if the route query param has tab=1
      if(!this.checkIfTabIsActivated()) return this.showCheckinCompletion = true;
      await this.$lionheart.get('/v2/facility-bookings/f/get-booking-lock', {
            params: {
               facility_id: data.facilityId,
               reservation_number: data.reservationNo
            }
      })
      .then((res) => {
         this.reservationDetails = res.data
         this.showCheckinCompletion = true;
      })
   },

    show() {
      return !this.isPreCheckin() && !this.showCheckinCompletion;
    },

    showCheckinForm() {
      if (this.$route.query.ref && this.$route.query.ref == "no-others") {
        return (location.href = `/${this.slug_name}/checkin-core?ref=tdf`);
      } else if(this.$route.query.tab && this.$route.query.tab == 1) {
          return (location.href = `/${this.slug_name}/checkin-core?ref=td&tab=1`);
      }
      return (location.href = `/${this.slug_name}/checkin-core?ref=td`);
    },

    back() {
      const main = this.$router.resolve({
        name: "checkin-facility-touch-down",
      }).href;
      return (location.href = main);
    },

    isPreCheckin() {
      if (_.isEmpty(this.booking_details)) return false;
      const bookingStatusId = this.booking_details.data[0].booking_status_id;
      return bookingStatusId == booking_status.pre_check_in;
    },

    async selectLang(lang) {
      this.$i18n.locale = lang;
    },

    langAvailable() {
      let lang = this.facility.facility_name.filter((item) => {
        return item.language == this.$i18n.locale;
      });
      if (lang.length > 0) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

$theme-colors: (
  "primary": #1aa8de,
);
@import "~bootstrap/scss/bootstrap";
.shade {
   border-width: 3px;
}
@media (max-width: 576px) {
   .button-responsive {
      padding: .7rem;

      p {
         text-align: center;
         font-size: .9rem;
         margin-bottom: 0;
         font-weight: 600;
      }
   }
   .h2 {
      font-size: 2rem !important;
   }
   .box-content {
      padding: 3rem 1rem 3rem 1rem;
   }
}
@media (min-width: 576px) and (max-width: 767.98px) {
   .button-responsive {
      padding: 1rem;
      p {
         text-align: center;
         font-size: 1.5rem;
         margin-bottom: 0;
      }
   }
   .h2 {
      font-size: 3rem !important;
   }

   .box-content {
      padding: 3rem 1rem 3rem 1rem;
   }
}
@media (min-width: 768px) and (max-width: 991.98px) {
   .button-responsive {
      padding: 1rem;

      p {
         text-align: center;
         font-size: 1.5rem;
         margin-bottom: 0;
      }
   }
   .h2 {
      font-size: 3rem !important;
   }

   .box-content {
      padding: 3rem 1rem 3rem 1rem;
   }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .button-responsive {
    padding: 1rem;

    p {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
  .h2 {
    font-size: 3rem !important;
  }

  .box-content {
    padding: 3rem 1rem 3rem 1rem;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
   .button-responsive {
    padding: 1rem;

    p {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
  .h2 {
    font-size: 3rem !important;
  }

  .box-content {
    padding: 3rem 1rem 3rem 1rem;
  }
}

.td {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background: #dff5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.box-content {
  background: #fff;
  height: auto;
  // min-height: 16rem;
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 3px 10px 6px #65656552;
  -moz-box-shadow: 3px 10px 6px #65656552;
  box-shadow: 3px 10px 6px #65656552;
   //  padding: 20px;
}

.hotel-title .h2 {
  color: #1a5dab;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 25px;
  margin-top: 20px;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 425px) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 375px) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

@media (max-width: 375px) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

@media (max-width: 320px) {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}
</style>