<template>
  <b-modal
    ref="confirmModal"
    dialog-class="modal-fluid-md"
    @hide="$emit('update:show', false)"
    centered
  >
    <template #modal-header>
      <b>{{ $t('confirmation_modal.confirm') }}</b>
      <button class="close" @click="$emit('update:show', false)">×</button>
    </template>
    <div>{{ message || $t('confirmation_modal.are_you_sure') }}</div>
    <template #modal-footer>
      <div class="mx-n1">
        <button class="btn btn-outline-danger btn-w-md mx-1" @click="$emit('update:show', false)">
          {{ no || $t('confirmation_modal.cancel') }}
        </button>
        <button class="btn btn-primary btn-w-md mx-1" @click="confirm()">
          {{ yes || $t('confirmation_modal.confirm') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
    message: { type: String, default: '' },
    no: { type: String, default: '' },
    yes: { type: String, default: '' },
  },
  methods: {
    confirm() {
      this.$emit('update:show', false);
      this.$emit('confirm', true);
    },
  },
  watch: {
    show(yes) {
      if (yes) this.$refs['confirmModal'].show();
      else this.$refs['confirmModal'].hide();
    },
  }
}
</script>