import {
   booking_status,
   payment_status,
} from "@/modules/checkin-facility/utils/mockup";
import _ from "lodash";
import moment from "moment-timezone";
import t from "typy";
import { mapActions, mapGetters } from "vuex";
import LanguageButtonComponent from "../../dashboard/components/language-button.component";
import CheckoutDetails from "../components/checkout/CheckoutDetails.vue";
import GatewayPayment from "../components/gatewayPayment.vue";
import CheckoutGuideComponent from "./../../../components/checkout-guide.component";
import TermsComponent from "./../../../components/terms.component";

export default {
  components: {
    TermsComponent,
    CheckoutGuideComponent,
    LanguageButtonComponent,
    CheckoutDetails,
    GatewayPayment,
  },
  data() {
    return {
      typy: t,
      submit_btn: "",
      error_msg: "",
      success_msg: "",
      confirm: false,
      facility: {
        name: "...",
      },
      user: {
        email: "",
        key: "",
      },
      form_language: "",
      enabled_languages: [],
      is_custom: false,
      custom_text_setting: {},
      step: "prematch",
      paid_statuses: [
        payment_status.pay_at_reception,
        payment_status.paid_authorized,
        payment_status.paid_captured,
        payment_status.paid,
      ],
      paid: true,
      currency: "",
      invalid_code_error: [],
      is_loading: true,
      checkout_is_loading: false,
    };
  },
  async mounted() {
    this.facility = await this.$lionheart
      .post("/facility/list", { slug_name: this.$route.params.slug_name })
      .then((r) => this.catcher(r.data, "data[0].id", (r) => r.data[0]))
      .catch((e) => this.catcher(e));
    if (this.facility.error.has_error) return this.$router.push("/404");

    const el = await this.$lionheart.post("/facility-setting/list", {
      facility_id: this.facility.id,
      name: "language",
      paginate: false,
    });

    if (el.data.total != 0)
      el.data.data[0].value.forEach((value, key) => {
        this.enabled_languages.push(value.code);
      });
    else this.enabled_languages.push("en", "ja");

    const pms_settings = await this.$lionheart.post("/facility-setting/list", {
      facility_id: this.facility.id,
      name: "pms_settings",
      paginate: false,
    });

    if (pms_settings.data.total != 0)
      this.facility.pms_code = pms_settings.data.data[0].value.pms_code;
    else return this.$router.push("/404");

    this.is_custom = this.facility.facility_setting.length > 0 ? true : false;

    this.loadCustomTextSetting(this.$i18n.locale, this.facility.id);
    this.is_loading = false
    // Auto confirm
    if (this.$route.query.e && this.$route.query.k) {
      this.is_loading = true
      this.user.email = this.$route.query.e
      this.user.key = this.$route.query.k

      setTimeout(() => this.submit('confirm'), 500);
    }

  },
  computed: {
    ...mapGetters("checkin-site", ["booking"]),
  },
  methods: {
    ...mapActions("checkin-site", ["setBooking"]),
    submit(action) {
      this.submit_btn = action;
      setTimeout(() => this.$refs.checkoutForm.click(), 500);
    },
    async submitForm(e) {
      e.preventDefault();
      this.error_msg = "";
      this.success_msg = "";

      if (this.submit_btn == "confirm") {
        if (this.step == "prematch") return this.confirmation();
        if (this.step == "match") return this.paymentConfirmation();
      }

      if (this.submit_btn == "close") {
        this.step = "prematch";
        this.confirm = false;
        return;
      }
    },

    async confirmation() {
      const user = this.user;

      const response = await this.$lionheart
        .post("/v2/facility-booking/list", {
          facility_id: this.facility.id,
          pass: user.key,
          email: user.email,
          mode: "pre-checkin",
        })
        .catch((e) => this.catcher(e));

      const booking = response.data

      if (booking.data.length == 0) {
        this.is_loading = false
        this.invalid_code_error.push(
          "We could not find a reservation with this email and pass. Please try again"
        );
        setTimeout(() => {
          this.invalid_code_error.shift();
        }, 5000);
        return;
      }

      if (booking.data[0].payment_metadata) {
        this.currency = booking.data[0].payment_metadata.hasOwnProperty("currency")
          ? booking.data[0].payment_metadata.currency
          : "JPY";
      }

      if (!booking.success) return this.errorDisplay(booking.message);
      if (
        booking.data.length == 0 ||
        booking.data[0].booking_status_id != booking_status.checked_in
      )
        return this.errorDisplay(
          this.$t("visit-information-form.no_reservation_msg")
        );

      this.setBooking(booking);

      if (booking.data[0].payment_metadata == null) return this.checkout(false);

      if (this.isPaid()) {
        return (this.step = "confirmation");
      }

      this.step = "match";
    },

    async paymentConfirmation() {
      const status = await this.$refs.gatewayPayment.doCall().createPaymentMethod();
      if (status) {
        this.confirm = true;
        this.step = "confirmation";
        window.scrollTo(0, 10);
      }
    },

    async checkout(doPayment = true) {
      const data = {
        key: this.user.key,
        email: this.user.email,
        pms_code: this.facility.pms_code,
        timezone: moment.tz.guess(),
        facility_id: this.facility.id // added to fixed qr switch issue
      };

      if (doPayment) {
        const payment = await this.$refs.gatewayPayment.doCall().makePayment(true);
        if (!payment) return;
      }

      const checkout = await this.$lionheart
        .post("/facility-key/g/checkout", data)
        .then((r) => this.catcher(r.data, "data.id", (r) => r.data))
        .catch((e) => this.catcher(e));

      if (checkout.error.has_error) {
        this.confirm = false;
        return this.errorDisplay(checkout.error.msg);
      } else {
        this.user.key = "";
        this.user.email = "";
        this.confirm = null;
        this.step = "done";
        this.setBooking({});
      }
    },
    back() {
      if (this.step == "match") {
        this.step = "prematch";
        this.confirm = false;
        this.setBooking({});
      }

      if (this.step == "confirmation") {
        if (this.paid) {
          this.step = "prematch";
        } else {
          this.step = "match";
          setTimeout(() => this.$refs.gatewayPayment.doCall().reInit(), 300);
        }

        window.scrollTo(0, 10);
        this.confirm = false;
      }
    },

    isPaid(showPayment) {
      if (showPayment == 0) {
        this.step = "confirmation";
        return (this.paid = true);
      }

      // Show the form again if not yet paid
      this.is_loading = false
      return (this.paid = this.paid_statuses.includes(
        this.booking.data[0].payment_status_id
      ));
    },

    errorDisplay(d) {
      this.is_loading = false;
      this.error_msg = d;
      return;
    },

    successDisplay(d) {
      this.success_msg = d;
      return;
    },

    async reloadQuestion(lang) {
      this.form_language = lang;
      this.$i18n.locale = lang;
      this.confirm = false;
      this.loadCustomTextSetting(lang, this.facility.id);
    },
    async loadCustomTextSetting(lang, id) {
      let params;

      if (this.is_custom) {
        params = {
          name: `message-checkout-form-${lang}`,
          fallback: "message-checkout-form-en",
        };
      } else {
        params = {
          name: `message-checkout-form-${lang}`,
          fallback: "message-checkout-form-en",
        };
      }

      const response = await this.$lionheart.get("/custom-messages/" + id, {
        params: params,
      });
      this.custom_text_setting = response.data.data.value;
    },
  },
};
