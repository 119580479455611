<template>
    <div id="dashboard_wrapper">
        <!-- Page Wrapper -->
        <div id="wrapper" v-if="show">

            <!-- Sidebar -->
            <side-nav />

            <!-- Content Wrapper -->
            <div id="content-wrapper" class="d-flex flex-column">

                <!-- Main Content -->
                <div id="content">

                    <!-- Topbar -->
                    <top-nav />
                    <!-- End of Topbar -->

                    <!-- Begin Page Content -->
                    <div class="container-fluid">

                        <router-view  />

                    </div>
                    <!-- /.container-fluid -->

                </div>
                <!-- End of Main Content -->

                <!-- Footer -->
                <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                            <span>Copyright &copy; {{ url }}</span>
                        </div>
                    </div>
                </footer>
                <!-- End of Footer -->

            </div>
            <!-- End of Content Wrapper -->

        </div>
        <div class="container" v-if="!show">
<!--            <router-view  />-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#dashboard_wrapper {
    @import './../assets/scss/sb-admin-2.scss';
}

#dashboard_wrapper {
    li span {
        position: inherit;
    }
}
</style>

<script>
import { init } from './../assets/js/sb-admin';
import sideNav from'./../components/sideNav'
import topNav from'./../components/topNav'

export default {
    components: {
        sideNav,
        topNav
    },
    data() {
        return {
            url: window.location.origin,
            show: true
        }
    },
    mounted() {
        this.show = (this.$route.meta.show == undefined) ? true : this.$route.meta.show
        init()
    }
}
</script>